/* eslint-env browser */
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Message,
  Table,
  Button,
  Icon,
  Menu,
  Pagination,
} from "semantic-ui-react";
import { toast } from "react-semantic-toasts";
import {
  fetchPersons,
  resetFetchPersons,
  sendConfirmationEmail,
} from "../../Redux/actions";
import { Spinner } from "../Common/Spinner";
import { T, StatusIcon, getErrorMessage } from "../Common/Helpers";

class PersonList extends Component {
  constructor(props) {
    super(props);
    this.state = { sortByColumn: "pe_name", direction: "asc" };
    this.sortByColumnName = this.sortByColumnName.bind(this);
    this.sendEmailConfirmation = this.sendEmailConfirmation.bind(this);
  }

  componentDidMount() {
    //console.log(this.props);
    const ws_id = this.props.workspace.activeWorkspace.id;
    // localStorage.setItem('route', this.props.history.location.pathname);
    const authKey = localStorage.getItem("x-auth-key");
    this.props.fetchPersons(authKey, ws_id, 1, null, "person_id", "asc");
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.emailStatus !== "success" &&
      this.props.emailStatus === "success"
    ) {
      toast({
        type: "success",
        title: T("Success"),
        description: T("Confirmation email sent"),
        time: 5000,
      });
    }
    if (
      prevProps.emailStatus !== "error" &&
      this.props.emailStatus === "error"
    ) {
      const errorObj = getErrorMessage(
        this.props.error,
        "Error sending confirmation email"
      );
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  getDirection(dirn) {
    if (dirn === "asc") {
      return "ascending";
    }
    return "descending";
  }

  sortByColumnName(columnName) {
    const ws_id = this.props.workspace.activeWorkspace.id;
    const { direction, sortByColumn } = this.state;
    const { headers } = this.props;
    const authKey = localStorage.getItem("x-auth-key");
    if (sortByColumn === columnName) {
      const modifiedDirn = direction === "asc" ? "desc" : "asc";
      this.props.fetchPersons(
        authKey,
        ws_id,
        1,
        headers.searchterm,
        columnName,
        modifiedDirn
      );
      this.setState({ direction: modifiedDirn });
    } else {
      const modifiedDirn = "asc";
      this.props.fetchPersons(
        authKey,
        ws_id,
        1,
        headers.searchterm,
        columnName,
        modifiedDirn
      );
      this.setState({ sortByColumn: columnName, direction: modifiedDirn });
    }
  }

  navigateToLink = (person) => {
    this.props.history.push({
      pathname: `/app/persons/${person.pe_public_id}/edit`,
      state: person,
    });
  };

  handlePaginationChange(e, { activePage }) {
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem("x-auth-key");
    const { headers } = this.props;
    this.props.fetchPersons(
      authKey,
      ws_id,
      activePage,
      headers.searchterm,
      headers.sort
    );
  }

  sendEmailConfirmation(pe_public_id) {
    this.props.sendConfirmationEmail({ pe_public_id });
  }

  renderPersons() {
    return _.map(this.props.persons, (person) => (
      <Table.Row
        key={person.pe_public_id}
        onClick={() => this.navigateToLink(person)}
        style={{ cursor: "pointer" }}
        className="tablerow"
      >
        <Table.Cell>{person.pe_login}</Table.Cell>
        <Table.Cell>{person.pe_name}</Table.Cell>
        <Table.Cell>{person.pe_email}</Table.Cell>
        <Table.Cell>
          <StatusIcon status={person.pe_is_active} />
        </Table.Cell>
        <Table.Cell>
          <StatusIcon status={person.pe_is_admin} />
        </Table.Cell>
        <Table.Cell
          onClick={(e) => e.stopPropagation()}
          style={{ cursor: "default" }}
          textAlign="center"
        >
          {this.props.auth.isSuperAdmin === true ? (
            <Button
              primary
              content={T("Confirmation email")}
              onClick={() => this.sendEmailConfirmation(person.pe_public_id)}
            />
          ) : null}
        </Table.Cell>
      </Table.Row>
    ));
  }

  render() {
    //console.log(this.props.persons);
    if (!this.props.auth.isSuperAdmin) {
      return (
        <Message
          header={T("Error")}
          error
          icon="exclamation triangle"
          content={T("Unauthorized access")}
        />
      );
    } else if (
      this.props.fetchAll !== "success" ||
      this.props.emailStatus === "waiting"
    ) {
      return <Spinner />;
    }

    const column = this.state.sortByColumn;
    const dirn = this.state.direction;

    return (
      <Table sortable compact celled padded stackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === "pe_login" ? this.getDirection(dirn) : null}
              onClick={() => this.sortByColumnName("pe_login")}
            >
              {T("User Name")}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "pe_name" ? this.getDirection(dirn) : null}
              onClick={() => this.sortByColumnName("pe_name")}
            >
              {T("Name")}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "pe_email" ? this.getDirection(dirn) : null}
              onClick={() => this.sortByColumnName("pe_email")}
            >
              {T("Email")}
            </Table.HeaderCell>
            <Table.HeaderCell>{T("Active")}</Table.HeaderCell>
            <Table.HeaderCell>{T("Admin")}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderPersons()}</Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="6">
              <Link to="/app/persons/add">
                <Button primary content={T("Add")} />
              </Link>
              <Menu floated="right" pagination>
                {this.props.headers && (
                  <Pagination
                    onPageChange={this.handlePaginationChange.bind(this)}
                    defaultActivePage={this.props.headers.current_page}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={this.props.headers.total_pages}
                  />
                )}
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}

function mapStateToProps({ persons, auth, workspace }) {
  return {
    persons: persons.persons,
    headers: persons.headers,
    fetchAll: persons.operationState.fetchAll,
    emailStatus: persons.operationState.emailStatus,
    error: persons.error,
    workspace,
    auth,
  };
}

export default connect(mapStateToProps, {
  fetchPersons,
  resetFetchPersons,
  sendConfirmationEmail,
})(PersonList);

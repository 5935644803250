import { mapKeys } from 'lodash';
import {
  FETCH_CATEGORIES_WAITING,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_SUCCESS_NEW,
  FETCH_CATEGORIES_ERROR,
  FETCH_CATEGORIES_RESET,
  FETCH_SEASONCATEGORY_WAITING,
  FETCH_SEASONCATEGORY_SUCCESS,
  FETCH_SEASONCATEGORY_ERROR,
  FETCH_SEASONCATEGORY_RESET,
  FETCH_MATCHCATEGORY_WAITING,
  FETCH_MATCHCATEGORY_SUCCESS,
  FETCH_MATCHCATEGORY_ERROR,
  FETCH_MATCHCATEGORY_RESET,
  FETCH_CATEGORY_ERROR,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  eventcategories: {},
  status: 'init',
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_CATEGORIES_WAITING:
      return { ...state, status: 'waiting' };
    case FETCH_CATEGORIES_SUCCESS: {
      const eventcategories = mapKeys(action.payload.data, 'eventcategory_id');
      return {
        ...state,
        eventcategories,
        status: 'success',
        estatus: 'success',
      };
    }
    case FETCH_CATEGORIES_SUCCESS_NEW: {
      return {
        ...state,
        eventcategories: action.payload.data,
        status: 'success',
        estatus: 'success',
      };
    }
    case FETCH_CATEGORIES_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    case FETCH_CATEGORIES_RESET:
      return { ...state, status: 'init', error: undefined };

    case FETCH_SEASONCATEGORY_WAITING:
      return { ...state, sstatus: 'waiting' };
    case FETCH_SEASONCATEGORY_SUCCESS: {
      const seasoncategories = mapKeys(action.payload.data, 'eventcategory_id');
      return { ...state, seasoncategories, sstatus: 'success' };
    }
    case FETCH_SEASONCATEGORY_ERROR:
      return { ...state, error: action.payload.error, sstatus: 'error' };
    case FETCH_SEASONCATEGORY_RESET:
      return { ...state, error: undefined, sstatus: 'init' };

    case FETCH_MATCHCATEGORY_WAITING:
      return { ...state, mstatus: 'waiting' };
    case FETCH_MATCHCATEGORY_SUCCESS: {
      const matchcategories = mapKeys(action.payload.data, 'eventcategory_id');
      return { ...state, matchcategories, mstatus: 'success' };
    }
    case FETCH_MATCHCATEGORY_ERROR:
      return { ...state, mstatus: 'error', error: action.payload.error };
    case FETCH_MATCHCATEGORY_RESET:
      return { ...state, mstatus: 'init', error: undefined };

    case FETCH_CATEGORY_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    default:
      return state;
  }
}

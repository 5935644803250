import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import _ from 'lodash';
import { fetchShops, fetchAppShops } from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import { T, getErrorMessage } from '../Common/Helpers';
import ManageAppShops from './ManageAppShops';

class AppShopsParent extends Component {
  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.ws_id;
    const appId = this.props.workspace.workspaces[ws_id].cnc_application;
    this.props.fetchShops({ authKey, ws_id });
    this.props.fetchAppShops({ authKey, appId, ws_id });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.shop.shopFetchStatus !== 'error' &&
      this.props.shop.shopFetchStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.shop.error,
        'Error in fetching shops'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      prevProps.shop.shopsAppFetch !== 'error' &&
      this.props.shop.shopsAppFetch === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.shop.error,
        'Error in fetching app shops'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      prevProps.addStatus !== 'error' &&
      this.props.addStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.shop.error,
        'Error in adding shop(s) to app '
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      prevProps.addStatus !== 'success' &&
      this.props.addStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Shop(s) added to app successfully'),
        time: 5000,
      });
      const authKey = localStorage.getItem('x-auth-key');
      const ws_id = this.props.workspace.ws_id;
      const appId = this.props.workspace.workspaces[ws_id].cnc_application;
      this.props.fetchAppShops({ authKey, appId, ws_id });
    }
  }
  render() {
    const { shopsFetchStatus, shopsAppFetch, appShops, shops, addStatus } =
      this.props.shop;
    if (
      shopsFetchStatus === 'waiting' ||
      shopsFetchStatus === 'init' ||
      shopsAppFetch === 'waiting' ||
      shopsAppFetch === 'init' ||
      addStatus === 'waiting'
    ) {
      return <Spinner />;
    } else if (shopsFetchStatus === 'error' || shopsAppFetch === 'error') {
      return <p> {T('Error in Fetching shops')} </p>;
    }
    // debugger;
    const appShopKeys = Object.keys(appShops);
    let allShops = shops;
    if (appShopKeys.length > 0) {
      allShops = _.filter(
        shops,
        shop => appShopKeys.indexOf(shop.shop_id.toString()) === -1
      );
    }
    return <ManageAppShops appShops={appShops} allShops={allShops} />;
  }
}
function mapStateToProps({ workspace, shop, applications }) {
  return {
    workspace,
    shop,
    addStatus: applications.operationState.addShop,
    appShopError: applications.error,
  };
}
export default connect(mapStateToProps, { fetchShops, fetchAppShops })(
  AppShopsParent
);

import {
  FETCH_EVENT_CONTENT_TYPE_WAITING,
  FETCH_EVENT_CONTENT_TYPE_SUCCESS,
  FETCH_EVENT_CONTENT_TYPE_ERROR,
  FETCH_EVENT_CONTENT_TYPE_RESET,
  FETCH_KEY_VALUES_WAITING,
  FETCH_KEY_VALUES_SUCCESS,
  FETCH_KEY_VALUES_ERROR,
  FETCH_KEY_VALUES_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  status: 'init',
};

const KeyValueSettingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_EVENT_CONTENT_TYPE_WAITING:
      return { ...state, status: 'waiting' };
    case FETCH_EVENT_CONTENT_TYPE_SUCCESS:
      return {
        ...state,
        eventcontenttypes: action.payload.data,
        status: 'success',
      };
    case FETCH_EVENT_CONTENT_TYPE_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    case FETCH_EVENT_CONTENT_TYPE_RESET:
      return { ...state, status: 'init' };
    case FETCH_KEY_VALUES_WAITING:
      return { ...state, status: 'waiting' };
    case FETCH_KEY_VALUES_SUCCESS:
      return { ...state, contenttypes: action.payload.data, status: 'success' };
    case FETCH_KEY_VALUES_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    case FETCH_KEY_VALUES_RESET:
      return { ...state, status: 'init' };
    default:
      return state;
  }
};

export default KeyValueSettingReducer;

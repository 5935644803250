// to be done later
/* eslint-env browser */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { fetchProducts, resetProducts } from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import { T, getErrorMessage } from '../Common/Helpers';
import ListTickets from './ListTickets';
// Todo: remove hardcoded values ...
const pr_type = 'entry';
// const pp_currency = 'pts';
// const pp_price_list = 27;

class ManageTicket extends Component {
  componentDidMount() {
    // console.log('opening with props ', this.props);
    const ws_id = this.props.workspace.activeWorkspace.id;
    // localStorage.setItem('route', this.props.history.location.pathname);
    const authKey = localStorage.getItem('x-auth-key');
    this.props.fetchProducts({
      authKey,
      sortByColumn: 'product_id',
      direction: 'asc',
      start: 1,
      searchterm: null,
      pr_type,
      ws_id,
      module_id: 522,
    });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (
      nextProps.product.fetchStatus === 'error' &&
      this.props.product.fetchStatus !== 'error'
    ) {
      this.props.resetProducts();
      const errorObj = getErrorMessage(
        nextProps.product.error,
        'Could not fetch products'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  render() {
    if (this.props.product.fetchStatus !== 'success') {
      return <Spinner />;
    }
    return <ListTickets productsList={this.props.product.products} />;
  }
}

function mapStateToProps({ product, workspace }) {
  return { product, workspace };
}

export default connect(mapStateToProps, { fetchProducts, resetProducts })(
  ManageTicket
);

/* eslint-env browser */
import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  FETCH_COMPANIES_WAITING,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_ERROR,
  FETCH_COMPANIES_RESET,
  FETCH_COMPANY_WAITING,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  FETCH_COMPANY_RESET,
  ADD_COMPANY_WAITING,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_ERROR,
  ADD_COMPANY_RESET,
  EDIT_COMPANY_WAITING,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_ERROR,
  EDIT_COMPANY_RESET,
  DELETE_COMPANY_WAITING,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,
  DELETE_COMPANY_RESET,
  UPLOAD_COMPANY_LOGO_WAITING,
  UPLOAD_COMPANY_LOGO_SUCCESS,
  UPLOAD_COMPANY_LOGO_ERROR,
  UPLOAD_COMPANY_LOGO_RESET,
  DELETE_COMPANY_LOGO_WAITING,
  DELETE_COMPANY_LOGO_SUCCESS,
  DELETE_COMPANY_LOGO_ERROR,
  DELETE_COMPANY_LOGO_RESET,
  // ERROR_GATEWAY_TIMEOUT,
} from '../Types';
import ErrorAction from './ErrorAction';

const MODULE_ID = 12;
const LOGO_UPLOAD_MODULE_ID = 4;

export const fetchCompanies = (authKey, ws_id) => dispatch => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  const url = `${config.BASE_URL}/companies?module_id=${MODULE_ID}&ws_id=${ws_id}`;
  dispatch({ type: FETCH_COMPANIES_WAITING });
  axios
    .get(url)
    .then(response => {
      dispatch({ type: FETCH_COMPANIES_SUCCESS, payload: response });
    })
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status === 504, FETCH_COMPANIES_ERROR)
        : FETCH_COMPANIES_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetFetchCompanies = () => ({ type: FETCH_COMPANIES_RESET });

export const fetchCompany = (id, authKey, ws_id) => dispatch => {
  dispatch({ type: FETCH_COMPANY_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .get(
      `${config.BASE_URL}/company/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`
    )
    .then(response =>
      dispatch({ type: FETCH_COMPANY_SUCCESS, payload: response })
    )
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status === 504, FETCH_COMPANY_ERROR)
        : FETCH_COMPANY_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetFetchCompany = () => ({ type: FETCH_COMPANY_RESET });

export const editCompany = (id, data, authKey, ws_id) => dispatch => {
  dispatch({ type: EDIT_COMPANY_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .put(
      `${config.BASE_URL}/company/${id}?ws_id=${ws_id}&module_id=${MODULE_ID}`,
      data
    )
    .then(response =>
      dispatch({ type: EDIT_COMPANY_SUCCESS, payload: response })
    )
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status === 504, EDIT_COMPANY_ERROR)
        : EDIT_COMPANY_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetEditCompany = () => ({ type: EDIT_COMPANY_RESET });

export const addCompany = (data, authKey) => dispatch => {
  dispatch({ type: ADD_COMPANY_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .post(`${config.BASE_URL}/company?module_id=${MODULE_ID}`, data)
    .then(response =>
      dispatch({ type: ADD_COMPANY_SUCCESS, payload: response })
    )
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status === 504, ADD_COMPANY_ERROR)
        : ADD_COMPANY_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetAddCompany = () => ({ type: ADD_COMPANY_RESET });

export const deleteCompany = (id, authKey) => dispatch => {
  dispatch({ type: DELETE_COMPANY_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .delete(`${config.BASE_URL}/company/${id}?module_id=${MODULE_ID}`)
    .then(response =>
      dispatch({ type: DELETE_COMPANY_SUCCESS, payload: response })
    )
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status === 504, DELETE_COMPANY_ERROR)
        : DELETE_COMPANY_ERROR;
      dispatch({ type, payload: error });
    });
};

export const deleteCompanyLogo =
  (
    authKey,
    companyId,
    url //PLACEHOLDER NEEDS TO BE REPLACED
  ) =>
  dispatch => {
    dispatch({ type: DELETE_COMPANY_LOGO_WAITING });
    const data = { url, target: 'company' };
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .delete(
        `${config.BASE_URL}/file/${companyId}?module_id=${LOGO_UPLOAD_MODULE_ID}`,
        { data }
      )
      .then(response =>
        dispatch({ type: DELETE_COMPANY_LOGO_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(
              error.response.status === 504,
              DELETE_COMPANY_LOGO_ERROR
            )
          : DELETE_COMPANY_LOGO_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const uploadCompanyLogo =
  (
    authKey,
    companyData //PLACEHOLDER NEEDS TO BE REPLACED
  ) =>
  dispatch => {
    dispatch({ type: UPLOAD_COMPANY_LOGO_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .post(
        `${config.BASE_URL}/upload?module_id=${LOGO_UPLOAD_MODULE_ID}`,
        companyData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      )
      .then(response =>
        dispatch({ type: UPLOAD_COMPANY_LOGO_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(
              error.response.status === 504,
              UPLOAD_COMPANY_LOGO_ERROR
            )
          : UPLOAD_COMPANY_LOGO_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetDeleteCompany = () => ({ type: DELETE_COMPANY_RESET });
export const resetUploadCompanyLogo = () => ({
  type: UPLOAD_COMPANY_LOGO_RESET,
});
export const resetDeleteCompanyLogo = () => ({
  type: DELETE_COMPANY_LOGO_RESET,
});

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchShopSettings,
  fetchShopSettingsKeywords,
} from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import AppShopSettingsManager from './AppShopSettingsManager';

class AppShopSettingsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { apiFailed: false };
  }
  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id, appId } = this.props;
    if (appId) {
      this.props.fetchShopSettings({ ws_id, appId, authKey });
      this.props.fetchShopSettingsKeywords({ ws_id, appId, authKey });
      this.timeOut = setTimeout(this.checkWaitingTimeout, 3500);
    }
  }
  UNSAFE_componentWillUpdate(nextProps) {
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id, appId } = this.props;
    const newAppId = nextProps.appId;
    if (appId !== nextProps.appId) {
      this.props.fetchShopSettings({ ws_id, appId: newAppId, authKey });
      this.props.fetchShopSettingsKeywords({ ws_id, appId: newAppId, authKey });
      this.timeOut = setTimeout(this.checkWaitingTimeout, 3500);
    }
  }
  checkWaitingTimeout = () => {
    if (
      this.props.shop.fetchSettingsStatus !== 'success' ||
      this.props.shop.fetchKeywordsStatus !== 'success'
    ) {
      this.setState({ apiFailed: true });
    }
  };
  render() {
    const { fetchSettingsStatus, fetchKeywordsStatus } = this.props.shop;
    if (
      (fetchSettingsStatus !== 'success' ||
        fetchKeywordsStatus !== 'success') &&
      this.state.apiFailed === false
    ) {
      return <Spinner />;
    } else if (this.state.apiFailed === true) {
      return (
        <div style={{ color: 'red' }}>
          Error in fetching application settings, please refresh the page
        </div>
      );
    }
    clearTimeout(this.timeOut);
    const { shopSettings, shopKeywords } = this.props.shop;
    return (
      <AppShopSettingsManager
        shopSettings={shopSettings}
        shopKeywords={shopKeywords}
      />
    );
  }
}

function mapStateToProps({ shop, workspace }) {
  return { shop, ws_id: workspace.ws_id };
}
export default connect(mapStateToProps, {
  fetchShopSettings,
  fetchShopSettingsKeywords,
})(AppShopSettingsContainer);

import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Spinner } from '../../Common/Spinner';
import { T } from '../../Common/Helpers';
import EventStatisticManager from './EventStatisticManager';
import { fetchMomentsOptions } from '../../../Redux/actions';

class EventStatisticContainer extends Component {
  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.ws_id;
    const plParent = this.props.activeWorkspace.cnc_place;
    if (this.props.eventReport.momentOptions.status === 'init') {
      this.props.fetchMomentsOptions(authKey, ws_id, plParent);
    }
  }

  render() {
    const { momentOptions, langStatus } = this.props.eventReport;

    if (
      momentOptions.status === 'waiting' ||
      momentOptions.status === 'init' ||
      langStatus === 'waiting'
    ) {
      return <Spinner />;
    }

    if (momentOptions.status === 'error' || langStatus === 'error') {
      return (
        <Message error>
          <Message.Header>{T('Error')}</Message.Header>
          <p>{T('Error in fetching data for options.')}</p>
        </Message>
      );
    }
    return <EventStatisticManager />;
  }
}

function mapStateToProps({ workspace, languages, eventReport }) {
  return {
    ws_id: workspace.ws_id,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    langStatus: languages.status,
    languages: languages.languages,
    eventReport,
  };
}
export default connect(mapStateToProps, { fetchMomentsOptions })(
  EventStatisticContainer
);

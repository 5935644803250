import React from 'react';
import { Container } from 'semantic-ui-react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Nav from '../layout/Nav';

import ListLotteries from '../components/Lottery/ListLotteries';
import ManageLottery from '../components/Lottery/ManageLottery';

const ManageSubroute = () => {
  const { params } = useRouteMatch();
  return <ManageLottery key={`manage_lottery_${params.id}`} />;
};

const LotteryRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: '150px' }}>
      <Nav />
      <Switch>
        <Route path={`${path}/list`} exact>
          <ListLotteries />
        </Route>
        <Route path={`${path}/manage/:id`} exact>
          <ManageSubroute />
        </Route>
        <Route path={`${path}/manage`} exact>
          <ManageLottery key={Math.random()} />
        </Route>
      </Switch>
    </Container>
  );
};

export default LotteryRoute;

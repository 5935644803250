import React, { Component } from 'react';
import { Table, Button, Modal, Icon } from 'semantic-ui-react';
import _ from 'lodash';

export const ModalContext = React.createContext();

class ModalWindow extends Component {
  constructor(props) {
    super(props);
    this.state = { openModal: false };
    this.closeModal = this.closeModal.bind(this);
    this.onOpen = this.onOpen.bind(this);
  }

  onOpen(e) {
    e.preventDefault();
    this.setState({ openModal: true });
  }

  closeModal(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ openModal: false });
  }

  renderTrigger = buttonProp => {
    if (this.props.tableRowTrigger) {
      const length = this.props.tableRowTrigger.length;
      const columnItems = this.props.tableRowTrigger.slice();
      let lastColumn;
      if (!this.props.noDeleteCol) {
        lastColumn = columnItems.splice(length - 1, 1);
      }
      // console.log('Table Rows: ', this.props.tableRowTrigger);
      return (
        <Table.Row
          key={Math.random()}
          onClick={this.onOpen}
          className="tablerow"
          style={{ cursor: 'pointer' }}
        >
          {_.map(columnItems, item => (
            <Table.Cell key={Math.random()}>{item}</Table.Cell>
          ))}
          <Table.Cell
            onClick={e => e.stopPropagation()}
            style={{ cursor: 'default', textAlign: 'right' }}
          >
            {!this.props.noDeleteCol && lastColumn}
          </Table.Cell>
        </Table.Row>
      );
    } else if (!this.props.customTrigger && !this.props.iconName) {
      return (
        <Button
          // style={{backgroundColor:this.props.buttonColor || "steelblue" }}
          // color={this.props.buttonColor}
          {...(buttonProp.settings || {})}
          content={this.props.buttonLabel}
          basic={this.props.basic}
          onClick={this.onOpen}
          size={this.props.buttonSize || 'small'}
        />
      );
    } else if (!this.props.customTrigger && this.props.iconName) {
      return (
        <Icon
          name={this.props.iconName}
          // color={this.props.buttonColor}
          {...(buttonProp.settings || {})}
          onClick={this.onOpen}
          link
        />
      );
    }
    return <p style={{ display: 'inline' }}>{this.props.customTrigger}</p>;
  };

  render() {
    let buttonProp = {};
    if (this.props.buttonColor) {
      buttonProp = { settings: { color: this.props.buttonColor } };
    }
    if (this.props.buttonStyle) {
      buttonProp = { ...buttonProp, styles: { ...this.props.buttonStyle } };
    }
    if (this.props.buttonIcon) {
      const settings = {
        ...(buttonProp.settings || {}),
        icon: this.props.buttonIcon,
      };
      buttonProp = { ...buttonProp, settings };
    }
    return (
      <ModalContext.Provider value={{ closeModal: this.closeModal }}>
        <Modal
          key={Math.random()}
          open={this.state.openModal}
          onOpen={this.onOpen}
          closeIcon
          onClose={this.closeModal}
          closeOnDimmerClick={false}
          size={this.props.modalSize || 'small'}
          trigger={this.renderTrigger(buttonProp)}
        >
          {this.props.header && (
            <Modal.Header>{this.props.header}</Modal.Header>
          )}
          <Modal.Content>
            {/* {React.Children.map(this.props.children, (child, props) =>
                React.cloneElement(child, { ...props, closeModal: this.closeModal}))}; */}
            {this.props.children}
          </Modal.Content>
        </Modal>
      </ModalContext.Provider>
    );
  }
}

export default ModalWindow;

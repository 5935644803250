import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Form,
  Input,
  Checkbox,
  Table,
  Button,
  Segment,
} from 'semantic-ui-react';
import { T } from '../Common/Helpers';
import ModalWindow, { ModalContext } from '../Common/ModalWindow';
import PickupPointAdd from './PickupPointAdd';
import { fetchPickupPoints } from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';

class PickupPointsList extends Component {
  constructor(props) {
    super(props);
    const ids = this.props.selectedPickups.map(ele => ele.place_id);
    this.state = {
      selectedLocationId: ids,
      selectedLocation: this.props.selectedPickups,
      disableSelectedButton: null,
    };
  }
  componentDidMount() {
    const ws_id = this.props.workspace.ws_id;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.fetchPickupPoints(authKey, ws_id);
  }
  getDirection(dirn) {
    if (dirn === 'asc') {
      return 'ascending';
    }
    return 'descending';
  }

  // searchLocations(e, { value }) {
  //   const ws_id = this.props.workspace.activeWorkspace.id;
  //   if (value) {
  //     const authKey = localStorage.getItem('x-auth-key');
  //     this.props.fetchPickupPoints(authKey, ws_id);
  //   }
  // }

  handleLocationChange(e, pickup) {
    const id = pickup.place_id;
    if (this.state.selectedLocationId.indexOf(id) === -1) {
      this.setState({
        selectedLocationId: [...this.state.selectedLocationId, pickup.place_id],
        selectedLocation: [...this.state.selectedLocation, pickup],
        disableSelectedButton: false,
      });
    } else {
      const selectedLocationId = this.state.selectedLocationId.filter(
        pickup_id => pickup_id !== id
      );
      const selectedLocation = this.state.selectedLocation.filter(
        ele => ele.place_id !== id
      );
      this.setState({
        selectedLocationId,
        selectedLocation,
        disableSelectedButton: false,
      });
    }
  }

  handleSelectedLocation(pickup) {
    this.props.setLocation(pickup);
    // this.handleLocationChange(null, pickup);
    this.props.closeModal();
  }

  // handlePaginationChange(e, { activePage }) {
  //   const authKey = localStorage.getItem('x-auth-key');
  //   const ws_id = this.props.workspace.activeWorkspace.id;
  //   const { headers } = this.props;
  //   this.props.fetchLocations(authKey, ws_id, true, activePage, headers.searchterm, headers.sort);
  // }

  // sortLocationsByColumnName(columnName) {
  //   const { direction, sortByColumn } = this.state;
  //   const { headers } = this.props;
  //   const ws_id = this.props.workspace.activeWorkspace.id;
  //   const authKey = localStorage.getItem('x-auth-key');
  //   if (sortByColumn === columnName) {
  //     const modifiedDirn = (direction === 'asc') ? 'desc' : 'asc';
  //     this.props.fetchLocations(authKey, ws_id, true, 1, headers.searchterm, columnName, modifiedDirn);
  //     this.setState({ direction: modifiedDirn });
  //   } else {
  //     const modifiedDirn = 'asc';
  //     this.props.fetchLocations(authKey, ws_id, true, 1, headers.searchterm, columnName, modifiedDirn);
  //     this.setState({ sortByColumn: columnName, direction: modifiedDirn });
  //   }
  // }
  renderLocations() {
    const pickups = this.props.pickups;
    if (pickups.length === 0) return <Table.Row />;
    return _.map(pickups, pickup => (
      <Table.Row
        key={pickup.place_id}
        onClick={e => this.handleLocationChange(e, pickup)}
      >
        <Table.Cell key={`${pickup.place_id}-id`}>
          <Form.Field>
            <Checkbox
              label={pickup.place_id}
              name="radioGroup"
              value={pickup.place_id}
              checked={
                this.state.selectedLocationId.indexOf(pickup.place_id) !== -1
              }
              onChange={e => this.handleLocationChange(e, pickup)}
            />
          </Form.Field>

          {/* </Link> */}
        </Table.Cell>
        <Table.Cell key={`${pickup.place_id}-name`}>
          {pickup.pl_name}
        </Table.Cell>
        <Table.Cell key={`${pickup.place_id}-address`}>
          {pickup.pl_address}
        </Table.Cell>
        <Table.Cell key={`${pickup.place_id}-Longitude`}>
          {pickup.pl_zip}
        </Table.Cell>
        <Table.Cell key={`${pickup.place_id}-city`}>
          {pickup.pl_city}
        </Table.Cell>
      </Table.Row>
    ));
  }

  render() {
    if (this.props.fetchPickups !== 'success') {
      return <Spinner />;
    }

    // const column = this.state.sortByColumn;
    // const dirn = this.state.direction;
    const ws_id = this.props.workspace.ws_id;
    const pl_company = this.props.workspace.workspaces[ws_id].cnc_company;
    return (
      <Segment>
        <Input
          onChange={this.searchLocations}
          placeholder={T('search location')}
        />
        <Table compact celled padded selectable striped sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {/*sorted={column === 'location_id' ? this.getDirection(dirn) : null}
                            onClick={() => this.sortLocationsByColumnName('location_id')}*/}
                {T('ID')}
              </Table.HeaderCell>
              <Table.HeaderCell>{T('Name')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Address')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Zip')}</Table.HeaderCell>
              <Table.HeaderCell>{T('City')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderLocations()}</Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="6">
                <ModalWindow
                  buttonColor="blue"
                  buttonSize="small"
                  modalSize="large"
                  buttonLabel={T('Add new pickup')}
                  header={T('Add pickup point')}
                >
                  <ModalContext.Consumer>
                    {({ closeModal }) => (
                      <PickupPointAdd
                        closeModal={closeModal}
                        ws_id={ws_id}
                        pl_company={pl_company}
                        price_list_id={this.props.price_list_id}
                      />
                    )}
                  </ModalContext.Consumer>
                </ModalWindow>
                <Button
                  color="green"
                  content={T('Add selected pickup points')}
                  onClick={() =>
                    this.handleSelectedLocation(this.state.selectedLocation)
                  }
                  style={{ marginLeft: '10px' }}
                />
                <Button
                  color="black"
                  content={T('Cancel')}
                  onClick={this.props.closeModal}
                  style={{ marginLeft: '10px' }}
                />
                {/*<Menu
                      floated='right'
                      pagination
                      style={{ marginTop: '20px' }}
                    >
                  <Pagination
                          onPageChange={this.handlePaginationChange}
                          defaultActivePage={this.props.headers ? this.props.headers.current_page : 1}
                          ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                          firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                          lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                          prevItem={{ content: <Icon name='angle left' />, icon: true }}
                          nextItem={{ content: <Icon name='angle right' />, icon: true }}
                          totalPages={this.props.headers ? this.props.headers.total_pages : 1}
                  />
                    </Menu>*/}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
    );
  }
}

function mapStateToProps({ locations, workspace }) {
  return {
    fetchPickups: locations.operationState.fetchAllPickups,
    pickups: locations.pickupPoints,
    workspace,
  };
}
export default connect(mapStateToProps, { fetchPickupPoints })(
  PickupPointsList
);

import React, { Component } from 'react';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import {
  Form,
  Message,
  Icon,
  Button,
  Segment,
  Confirm,
} from 'semantic-ui-react';
import { Form as Formsy } from 'formsy-semantic-ui-react';
import _ from 'lodash';
import { toast } from 'react-semantic-toasts';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fi';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  addPerformerCMSContent,
  editPerformerCMSContent,
  deletePerformerCMSContent,
  resetPerformerCMSContent,
  //fetchPerformerCMSContentTypes,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';
import PlayerCMSContentController from './PlayerCMSContentController';
import { Spinner } from '../Common/Spinner';

const errorStyle = {
  backgroundColor: '#FFF6F6',
  border: ' solid 1px #E0B4B4',
  color: '#9F3A38',
  WebkitBoxShadow: 'none',
  boxShadow: 'none',
};

class PlayerCMSParentEditor extends Component {
  constructor(props) {
    super(props);
    const { cmsProp } = this.props;
    let validFrom =
      cmsProp && cmsProp.cm_valid_from
        ? moment(cmsProp.cm_valid_from).toISOString()
        : moment().toISOString();
    let validTo =
      cmsProp && cmsProp.cm_valid_to
        ? moment(cmsProp.cm_valid_to).toISOString()
        : '';
    this.state = {
      selectedType: cmsProp ? cmsProp.cm_content_type : '',
      validFrom,
      validTo,
      selectedLanguage:
        cmsProp && cmsProp.cm_language ? cmsProp.cm_language : 'en',
      validFromStyle: '',
      validToStyle: '',
      newCmsParent: false,
      openDeleteConfirmation: false,
    };
    this.onContentTypeChange = this.onContentTypeChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.handleCMSDelete = this.handleCMSDelete.bind(this);
    this.onValidFromChange = this.onValidFromChange.bind(this);
    this.onValidToChange = this.onValidToChange.bind(this);
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }

  // componentDidMount() {
  //     this.props.resetPerformerCMSContent();
  // }

  componentDidUpdate(prevProps) {
    if (
      prevProps.cmsStatus !== 'success' &&
      this.props.cmsStatus === 'success'
    ) {
      // window.scroll(50, 50);
      // this.props.closeModal();
      toast({
        type: 'success',
        title: T('Success'),
        description: T('CMS content updated successfully'),
        time: 5000,
      });
      this.props.resetPerformerCMSContent();
      // this.props.onResetEditAndAddPlayer();
    } else if (
      prevProps.cmsStatus !== 'error' &&
      this.props.cmsStatus === 'error'
    ) {
      // window.scroll(50, 50);
      // this.props.closeModal();
      const errorObj = getErrorMessage(
        this.props.error,
        T('Error while adding cms')
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetPerformerCMSContent();
    }
  }

  onDelete() {
    this.setState({ openDeleteConfirmation: true });
  }

  onCancel() {
    this.props.closeModal();
  }
  onContentTypeChange(e, { value }) {
    this.setState({ selectedType: value });
  }
  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }
  onConfirmDelete() {
    if (!this.props.newCMS && this.props.contentId) {
      const authKey = localStorage.getItem('x-auth-key');
      this.props.deletePerformerCMSContent(
        this.props.contentId,
        authKey,
        this.props.ws_id
      );
    }
    this.setState({ openDeleteConfirmation: false });
  }
  onValidFromChange(event, { value }) {
    this.setState({ validFrom: moment(value, 'l HH:mm').toISOString() });
  }
  onValidToChange(event, { value }) {
    this.setState({ validTo: moment(value, 'l HH:mm').toISOString() });
  }
  getContentTypes(types) {
    return _.map(types, type => ({
      text: type.dk_description,
      value: type.dk_key,
    }));
  }

  getContentTypeOptionsList() {
    return _.map(
      this.props.contenttypes.filter(
        contenttype => contenttype.dk_ref_row_type === this.props.content_type
      ),
      contenttype => ({
        text: contenttype.dk_values,
        value: contenttype.dk_key,
      })
    );
  }
  editParentCMSContent(contentId, inputValues) {
    const authKey = localStorage.getItem('x-auth-key');
    this.props.editPerformerCMSContent(
      contentId,
      inputValues,
      authKey,
      this.props.ws_id
    );
  }
  addNewCMSParentWithContent(inputValues) {
    const authKey = localStorage.getItem('x-auth-key');
    this.props.addPerformerCMSContent(inputValues, authKey, this.props.ws_id);
  }
  handleValidSubmit(formdata) {
    let inputValues = {};
    const { cmsProp } = this.props;
    const language = this.state.selectedLanguage;
    if (cmsProp) {
      // edit parent
      inputValues = {
        cm_content_type: this.state.selectedType,
        cm_short_header: formdata.cm_short_header,
        cm_valid_from: moment(this.state.validFrom).toISOString(),
      };
      if (this.state.validTo) {
        if (Date.parse(this.state.validTo) < Date.parse(this.state.validFrom)) {
          this.setState({
            validToStyle: errorStyle,
            validFromStyle: errorStyle,
          });
          return false;
        }
        inputValues = {
          ...inputValues,
          cm_valid_to: moment(this.state.validTo).toISOString(),
        };
      }
      inputValues = { ...inputValues };
      this.editParentCMSContent(cmsProp.cms_content_id, inputValues);
      this.props.closemodal();
    } else {
      inputValues = {
        cm_content_type: this.state.selectedType,
        cm_short_header: formdata.cm_short_header,
        cm_valid_from: moment(this.state.validFrom).toISOString(),
        cm_language: language,
        cm_performer: this.props.playerId,
      };
      if (this.state.validTo) {
        if (Date.parse(this.state.validTo) < Date.parse(this.state.validFrom)) {
          this.setState({
            validToStyle: errorStyle,
            validFromStyle: errorStyle,
          });
          return false;
        }
        inputValues = {
          ...inputValues,
          cm_valid_to: moment(this.state.validTo).toISOString(),
        };
      }
      // console.log('Input values before adding new: ', inputValues);
      this.addNewCMSParentWithContent(inputValues);
      this.setState({ newCmsParent: true });
    }
    this.props.closeModal();
  }
  handleCMSDelete() {
    this.setState({ openDeleteConfirmation: true });
  }
  render() {
    // console.log('Player CMS Editor Props: ', this.props);
    if (
      (!this.props.newCMS && !this.props.cmsProp) ||
      !this.props.contenttypes ||
      this.props.cmsStatus === 'waiting'
    ) {
      return <Spinner />;
    } else if (this.props.cmsStatus === 'error') {
      return (
        <Message
          error
          header={T('Error')}
          lists={[T('Error while adding cms')]}
        />
      );
    }
    const noBorder = { border: 'none', boxShadow: 'none', textAlign: 'center' };
    return (
      <Segment style={noBorder}>
        <Segment textAlign="left">
          {this.state.newCmsParent ? (
            <PlayerCMSContentController
              parentId={this.props.newlyCreatedId}
              onResetEditAndAddPlayer={this.props.onResetEditAndAddPlayer}
              closemodal={this.props.closemodal}
            />
          ) : (
            <Formsy onValidSubmit={this.handleValidSubmit.bind(this)}>
              <Form.Field>
                <label htmlFor="selectedContentType">
                  {T('Content Types')}
                </label>
                <Formsy.Select
                  required
                  search
                  name="selectedContentType"
                  onChange={this.onContentTypeChange}
                  style={{ height: '30px', width: '70%' }}
                  //options={this.getContentTypes(this.props.cmsTypes)}
                  options={this.getContentTypeOptionsList()}
                  value={this.state.selectedType}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="cm_short_header"> {T('Title')} </label>
                <Formsy.Input
                  type="text"
                  name="cm_short_header"
                  style={{ width: '70%' }}
                  defaultValue={
                    this.props.cmsProp ? this.props.cmsProp.cm_short_header : ''
                  }
                  required
                />
              </Form.Field>
              <DateTimeInput
                required
                animation="off"
                name="cm_valid_from"
                label={T('Valid from')}
                closable
                startMode="year"
                onChange={this.onValidFromChange}
                value={
                  this.state.validFrom
                    ? moment(this.state.validFrom).format('l HH:mm')
                    : ''
                }
                dateTimeFormat="l HH:mm"
                minDate={moment(new Date(), 'l HH:mm')}
              />
              <DateTimeInput
                name="cm_valid_to"
                label={T('Valid to')}
                closable
                startMode="year"
                animation="off"
                onChange={this.onValidToChange}
                value={
                  this.state.validTo
                    ? moment(this.state.validTo).format('l HH:mm')
                    : ''
                }
                dateTimeFormat="l HH:mm"
                minDate={this.state.validFrom}
              />
              <Form.Field>
                <Button color="green" type="submit">
                  <Icon name="save" /> {T('Save')}
                </Button>
                <Button color="black" type="button" onClick={this.onCancel}>
                  <Icon name="ban" /> {T('Cancel')}
                </Button>
                {this.props.cmsProp && (
                  <Button
                    color="red"
                    type="button"
                    onClick={this.handleCMSDelete}
                  >
                    <Icon name="delete" /> {T('Delete')}
                  </Button>
                )}
                <Confirm
                  header={T('Delete CMS')}
                  content={T('Are you sure you want to delete the CMS?')}
                  confirmButton={T('Delete')}
                  open={this.state.openDeleteConfirmation}
                  onCancel={this.oncancelDeleteConfirmation}
                  onConfirm={this.onConfirmDelete}
                  size="tiny"
                />
              </Form.Field>
            </Formsy>
          )}
        </Segment>
      </Segment>
    );
  }
}

function mapStateToProps({ performerPlayers, workspace, keyValueSettings }) {
  return {
    cmsStatus: performerPlayers.cmsStatus,
    cmsTypes: performerPlayers.cmsTypes,
    childCms: performerPlayers.childCms,
    childCmsStatus: performerPlayers.childCmsStatus,
    newlyCreatedId: performerPlayers.newlyCreatedId,
    error: performerPlayers.error,
    ws_id: workspace.ws_id,
    keyValueSettings,
  };
}
export default connect(mapStateToProps, {
  addPerformerCMSContent,
  editPerformerCMSContent,
  deletePerformerCMSContent,
  resetPerformerCMSContent,
})(PlayerCMSParentEditor);

export const FETCH_PRODUCT_ATTRIBUTES_LIST_WAITING =
  "fetch_product_attributes_list_waiting";
export const FETCH_PRODUCT_ATTRIBUTES_LIST_SUCCESS =
  "fetch_product_attributes_list_success";
export const FETCH_PRODUCT_ATTRIBUTES_LIST_ERROR =
  "fetch_product_attributes_list_error";
export const FETCH_PRODUCT_ATTRIBUTES_LIST_RESET =
  "fetch_product_attributes_list_reset";

export const EDIT_PRODUCT_ATTRIBUTES_WAITING =
  "edit_product_attributes_waiting";
export const EDIT_PRODUCT_ATTRIBUTES_SUCCESS =
  "edit_product_attributes_success";
export const EDIT_PRODUCT_ATTRIBUTES_ERROR = "edit_product_attributes_error";
export const EDIT_PRODUCT_ATTRIBUTES_RESET = "edit_product_attributes_reset";

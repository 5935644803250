import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { withRouter } from 'react-router';
import {
  fetchShopConfigKeywords,
  listShopConfigs,
  manageShopConfig,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';
import ShopConfig from './ShopConfig';

class ShopConfigContainer extends Component {
  state = { sh_merchant: '', sh_place: '' };
  componentDidMount() {
    // console.log('ShopConfigContainer');
    // console.log(this.props);
    const ws_id = this.props.workspace.ws_id;
    const authKey = localStorage.getItem('x-auth-key');
    const shopId = this.props.match.params.shopId;
    const appId = this.props.workspace.workspaces[ws_id].cnc_application;
    //console.log('Shop Id: ', shopId);
    if (shopId) {
      this.props.fetchShopConfigKeywords(authKey, shopId, ws_id);
      this.props.listShopConfigs(authKey, shopId, appId, ws_id);
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.configStatus !== 'success' &&
      this.props.configStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Shop configs updated successfully'),
      });
      const ws_id = this.props.workspace.ws_id;
      const authKey = localStorage.getItem('x-auth-key');
      const shopId = this.props.match.params.shopId;
      const appId = this.props.workspace.workspaces[ws_id].cnc_application;
      this.props.listShopConfigs(authKey, shopId, appId, ws_id);
    } else if (
      prevProps.configStatus !== 'error' &&
      this.props.configStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.shopError,
        'Error in editing shop'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
      });
    }
  }

  handleInputChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  submitShopConfig = shopConfig => {
    if (Object.keys(shopConfig).length === 0) {
      toast({
        type: 'error',
        title: T('Error'),
        description: T('No configuration value entered'),
      });
    } else {
      // console.log(this.props);
      const authKey = localStorage.getItem('x-auth-key');
      const shopId = this.props.match.params.shopId;
      const ws_id = this.props.workspace.ws_id;
      const appId = this.props.workspace.workspaces[ws_id].cnc_application;
      this.props.manageShopConfig({
        authKey,
        shopId,
        shopConfig,
        appId,
        ws_id,
      });
    }
  };

  render() {
    const shopId = this.props.match.params.shopId;
    if (
      this.props.configKeysStatus === 'waiting' ||
      this.props.configListStatus === 'waiting' ||
      this.props.configStatus === 'waiting'
    ) {
      return <Spinner />;
    } else if (
      this.props.configKeysStatus === 'error' ||
      this.props.configListStatus === 'error'
    ) {
      return (
        <Message
          error
          icon="exclamation triangle"
          header={T('Error')}
          content={`${T('Error has occured, while fetching data.')} \n${
            this.props.shopError
          }`}
        />
      );
    }
    //console.log('Shop Config configKeysStatus: ', this.props.configKeysStatus, '\n List Status:',
    // this.props.configListStatus);
    return (
      <ShopConfig
        shopConfigs={this.props.configList}
        configKeywords={this.props.configKeys}
        editShop={!!shopId}
        submitShopConfig={this.submitShopConfig}
        history={this.props.history}
      />
    );
  }
}
function mapStateToProps({ workspace, shop }) {
  return {
    workspace,
    configKeys: shop.configKeys,
    shopError: shop.error,
    configKeysStatus: shop.configKeysStatus,
    configListStatus: shop.configListStatus,
    configList: shop.configList,
    configStatus: shop.configStatus,
  };
}
export default connect(mapStateToProps, {
  fetchShopConfigKeywords,
  listShopConfigs,
  manageShopConfig,
})(withRouter(ShopConfigContainer));

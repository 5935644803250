export const FETCH_PERMISSIONS_WAITING = "fetch-permissions-waiting";
export const FETCH_PERMISSIONS_SUCCESS = "fetch-permissions-success";
export const FETCH_PERMISSIONS_ERROR = "fetch-permissions-error";
export const FETCH_PERMISSIONS_RESET = "fetch-permissions-reset";

export const FETCH_PERMISSION_WAITING = "fetch-permission-waiting";
export const FETCH_PERMISSION_SUCCESS = "fetch-permission-success";
export const FETCH_PERMISSION_ERROR = "fetch-permission-error";
export const FETCH_PERMISSION_RESET = "fetch-permission-reset";

export const ADD_PERMISSION_WAITING = "add-permission-waiting";
export const ADD_PERMISSION_SUCCESS = "add-permission-success";
export const ADD_PERMISSION_ERROR = "add-permission-error";
export const ADD_PERMISSION_RESET = "add-permission-reset";

export const EDIT_PERMISSION_WAITING = "edit-permission-waiting";
export const EDIT_PERMISSION_SUCCESS = "edit-permission-success";
export const EDIT_PERMISSION_ERROR = "edit-permission-error";
export const EDIT_PERMISSION_RESET = "edit-permission-reset";

export const DELETE_PERMISSION_WAITING = "delete-permission-waiting";
export const DELETE_PERMISSION_SUCCESS = "delete-permission-success";
export const DELETE_PERMISSION_ERROR = "delete-permission-error";
export const DELETE_PERMISSION_RESET = "delete-permission-reset";

export const FETCH_ROLES_WAITING = "fetch-roles-waiting";
export const FETCH_ROLES_SUCCESS = "fetch-roles-success";
export const FETCH_ROLES_ERROR = "fetch-roles-error";
export const FETCH_ROLES_RESET = "fetch-roles-reset";

import {
  MODIFY_PRODUCT_STOCK_WAITING,
  MODIFY_PRODUCT_STOCK_SUCCESS,
  MODIFY_PRODUCT_STOCK_ERROR,
  MODIFY_PRODUCT_STOCK_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = { status: 'init' };
export default function productStockReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case MODIFY_PRODUCT_STOCK_WAITING:
      return { ...state, status: 'waiting' };
    case MODIFY_PRODUCT_STOCK_SUCCESS:
      return { ...state, status: 'success' };
    case MODIFY_PRODUCT_STOCK_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    case MODIFY_PRODUCT_STOCK_RESET:
      return { ...state, status: 'init' };
    default:
      return state;
  }
}

import React, { Component } from 'react';
import {
  Form,
  Button,
  Confirm,
  Header,
  Segment,
  Label,
  Message,
} from 'semantic-ui-react';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import { withRouter } from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
//import { Prompt } from 'react-router';
import { EditorState } from 'draft-js';
import '../../Style/wysiwyg.css';
import { Spinner } from '../Common/Spinner';
import PollOptionContainer from './PollOptionContainer';
import TranslationList from './TranslationList';
import { PopupContent } from '../Common/PopupContent';
import {
  addVoting,
  editVoting,
  fetchVoting,
  resetAddVoting,
  resetEditVoting,
  resetFetchVoting,
  resetDeleteVoting,
  addMorePollOption,
  editPollOptionsInit,
  deletePollOption,
  resetPollOptions,
  updatePollOptionImage,
  fetchEventsByType,
} from '../../Redux/actions';
import { T, getErrorMessage, getLanguageObject } from '../Common/Helpers';
import FileUpload from '../Common/FileUpload';

const PRIMARY_LANGUAGE = 15; // Finnish and can be change later according to user pref
const DATE_TIME_FORMAT = 'l HH:mm';
const CONTENT_TYPE = 'vote';
const CM_FORMAT = 'text/html';
const IMAGE_URL = 'cm_main_image';
const TRANSLATION_ERROR_MESSAGE = 'Translations missing: ';
const VOTING_TRANSLATIONS = [
  'editorState',
  'translationCache',
  'showConfirm',
  'confirmType',
  'is_dirty',
];

const POPUP_SIZE = 'big';
const POPUP_COLOR = 'grey';
const POPUP_WIDE = 'very';
const POPUP_CONTENT_1 =
  'List of translations. You can edit the translation by selecting the language from the list or List-dropdown.';
const POPUP_CONTENT_2 =
  'Fill in the voting name and description. Voting type is not editable';
const POPUP_CONTENT_3 =
  'Date and time when users are able to see the voting in app';
const POPUP_CONTENT_4 = 'Date and time when users are able to start voting';
const POPUP_CONTENT_5 = 'Date and time when users are no more able to vote';
const POPUP_CONTENT_6 =
  'Date and time when users are no more able to see the voting in app';
const POPUP_CONTENT_7 =
  'Date and time when users are able to see the results in app.';
//const MODULE_ID = 25;
const MODULE_ID = 1001;
const GAME_UPLOAD_TARGET = 'game_sponsor';

const imageStyle = {
  height: 200,
  width: 200,
  minHeight: 193.5,
  minWidth: 193.5,
};

class ManageVoting extends Component {
  constructor(props) {
    super(props);
    const workSpace = this.props.workspace.workspaces[this.props.ws_id];
    //const application = this.props.workspace.workspaces[this.props.ws_id].cnc_application;
    const application = workSpace.cnc_application;
    const primary_language_object = getLanguageObject(
      props.languages,
      props.auth.apps[0]
        ? props.auth.apps[0].ap_primary_language
        : PRIMARY_LANGUAGE
    );
    this.primary_language_id = primary_language_object.language_id;
    this.primary_alpha_2 = primary_language_object.ln_alpha_2;

    this.defaultParent = {
      cm_short_header: '',
      //cm_order: 1, // init option sequence
      cm_content_type: CONTENT_TYPE,
      cm_format: CM_FORMAT,
      cm_application: application,
      cm_children: [],
    };

    this.defaultOption = {
      cm_short_header: '',
      //cm_order: 1, // init option sequence
      cm_main_image: '',
      cm_content_type: CONTENT_TYPE,
      cm_format: CM_FORMAT,
      cm_application: application,
    };
    this.state = {
      editorState: EditorState.createEmpty(),
      translationState: EditorState.createEmpty(),
      showConfirm: false,
      is_dirty: false,
      editorContentCache: [],
      translationCache: [],
      pollOptionCache: [],
      polloptions: [],
      gtr_name: '',
      gtr_description: '',
      ga_name: '',
      ga_game_start: null,
      ga_game_stop: '', // when game stops
      ga_visibility_start: null, // visible for user start date
      ga_visibility_stop: null, // visible for user end date
      ga_disclosure_time: null, // date for showing the results
      ga_user_max_stakes: 1,
      ga_user_max_stake_updates: 50,
      ga_determine_winners: true,
      ga_event: null, // event id
      ga_type: 'vote',
      ga_performer_type: null,
      ga_performer_parent: null,
      ga_description: null,
      ga_icon_type: null,
      ga_header_image: null,
      ga_header_image_file_id: null,
      metadatas: [],
      cms_content_type: 'vote',
      ga_language: this.primary_language_id,
      ga_alpha_2: this.primary_alpha_2,
      application_id: application,
      translatedName: null,
      translatedDesc: null,
      pollOptionErrorMessages: [],
      pollOptionError: false,
      translationitems: [],
      errorObj: null,
      showTeams: false,
      workSpace: workSpace,
    };
    this.handleTranslatedFieldChange =
      this.handleTranslatedFieldChange.bind(this);
    this.handleMetadataFieldChange = this.handleMetadataFieldChange.bind(this);
    this.handleOptionFieldsChange = this.handleOptionFieldsChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancelConfirm = this.onCancelConfirm.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onResetPollOptions = this.onResetPollOptions.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onGotoLanguage = this.onGotoLanguage.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onAddPollOption = this.onAddPollOption.bind(this);
    this.onUpdatePollOption = this.onUpdatePollOption.bind(this);
    this.onRemoveTranslation = this.onRemoveTranslation.bind(this);
    this.onTranslationDeleteConfirm =
      this.onTranslationDeleteConfirm.bind(this);
    this.onPostData = this.postData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.postData = this.postData.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
    this.saveVoting = this.saveVoting.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.onAddPollOption = this.onAddPollOption.bind(this);
    this.onUpdatePollOption = this.onUpdatePollOption.bind(this);
    this.getLanguageList = this.getLanguageList.bind(this);
    this.getLanguageAlpha2 = this.getLanguageAlpha2.bind(this);
    this.getEventList = this.getEventList.bind(this);
    this.getTeamList = this.getTeamList.bind(this);
    this.listEventsNotInUse = this.listEventsNotInUse.bind(this);
    this.listPollOptions = this.listPollOptions.bind(this);
    this.listTranslations = this.listTranslations.bind(this);
    this.getPollOptionCache = this.getPollOptionCache.bind(this);
    this.validateDateTime = this.validateDateTime.bind(this);
    this.validateTranslations = this.validateTranslations.bind(this);
    this.updatePollOptionCache = this.updatePollOptionCache.bind(this);
    this.getDefaultOption = this.getDefaultOption.bind(this);
    this.getDefaultParent = this.getDefaultParent.bind(this);
    this.handleEventChange = this.handleEventChange.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.imageUpdate = this.imageUpdate.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.setSponsorImageDeleted = this.setSponsorImageDeleted.bind(this);
    this.getMetadataValue = this.getMetadataValue.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
  }

  // this is called only once, when the component is loaded

  componentDidMount() {
    const { id } = this.props.match.params;
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.ws_id;
    const { workSpace } = this.state;
    const gameId = id;

    if (workSpace && workSpace.cnc_event !== null) {
      this.props.fetchEventsByType({
        authKey,
        ws_id,
        module_id: MODULE_ID,
        type: 'match',
        performer: true,
        gameId,
      });
      this.setState({ showEvents: true });
    }

    this.initializeState(id);
    if (id) {
      this.props.fetchVoting(id, authKey, MODULE_ID, ws_id);
    } else {
      this.onAddPollOption(true);
    }
  }

  // this called, when setState is called
  componentDidUpdate(prevProps) {
    if (
      prevProps.voting.status.fetch !== 'success' &&
      this.props.voting.status.fetch === 'success'
    ) {
      this.props.resetFetchVoting();
      this.initializeState(this.props.match.params.id);
    }

    if (
      prevProps.voting.status.add !== 'success' &&
      this.props.voting.status.add === 'success'
    ) {
      this.props.resetAddVoting();
      this.props.history.goBack();
    }

    if (
      prevProps.voting.status.add !== 'error' &&
      this.props.voting.status.add === 'error'
    ) {
      this.props.resetAddVoting();
      const errorObj = getErrorMessage(
        this.props.voting.error,
        'Error adding voting'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }

    if (
      prevProps.voting.status.edit !== 'success' &&
      this.props.voting.status.edit === 'success'
    ) {
      this.props.resetEditVoting();
      this.props.history.goBack();
    }

    if (
      prevProps.voting.status.edit !== 'error' &&
      this.props.voting.status.edit === 'error'
    ) {
      this.props.resetEditVoting();
      const errorObj = getErrorMessage(
        this.props.voting.error,
        'Error editing voting'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }

    if (
      prevProps.voting.status.delete !== 'error' &&
      this.props.voting.status.delete === 'error'
    ) {
      this.props.resetDeleteVoting();
      const errorObj = getErrorMessage(
        this.props.voting.error,
        'Error deleting voting'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  onCancel() {
    // Todo: goBack may result in bad user experience, should be changed at other places
    // this.props.history.goBack();
    this.props.history.push({ pathname: '/app/voting/list' });
  }

  onDeleteConfirm() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.ws_id;
    let game_id;
    try {
      game_id = this.props.voting.voting[0].game_id;
    } catch (e) {
      console.log('Failed to get voting id ', e);
    }
    const { selectedOptionId, selectedParentKey, pollOptionCache } = this.state;
    this.deleteParentId(pollOptionCache, selectedParentKey);
    this.setState({ showConfirm: false }, () =>
      this.props.deletePollOption(
        authKey,
        game_id,
        selectedOptionId,
        MODULE_ID,
        ws_id,
        selectedParentKey
      )
    );
  }

  onResetPollOptions() {
    this.props.resetPollOptions();
  }

  // remove translations from poll options and game translations
  onTranslationDeleteConfirm() {
    try {
      const { pollOptionCache, translationCache, selectedLanguageId } =
        this.state;
      const p_pollOptionCache = pollOptionCache;
      let p_translationCache = translationCache;
      let children = [];

      p_pollOptionCache.forEach(p => {
        children = p.cm_children;
        const index = _.findIndex(
          children,
          item => item.cm_lang_id === selectedLanguageId
        );
        if (index !== -1) {
          children.splice(index, 1);
        }
      });
      p_translationCache = p_translationCache.filter(
        item => item.gtr_language !== selectedLanguageId
      ); // fetch all exept the deleted transaltions
      this.setState({
        showConfirm: false,
        pollOptionCache: p_pollOptionCache,
        translationCache: p_translationCache,
        //translationitems: this.listTranslations(p_translationCache),
        polloptions: this.listPollOptions(
          p_pollOptionCache,
          selectedLanguageId,
          this.getLanguageAlpha2(selectedLanguageId).ln_alpha_2
        ),
        gtr_name: '',
        gtr_description: '',
      });

      //this.onGotoLanguage(DEFAULT_LANG);
    } catch (e) {
      console.log('Failed to get voting id ', e);
    }
    //const { language_id, translationCache, pollOptionCache } = this.state;
    //this.setState({ showConfirm: false }, () =>
    //this.props.deleteTranslations(authKey, language_id, translationCache, ws_id, pollOptionCache));
  }

  onCancelConfirm() {
    this.setState({ showConfirm: false });
  }

  onConfirm() {
    const confirmtype = this.state.confirmType;
    switch (confirmtype) {
      case 'Delete':
        this.onDeleteConfirm();
        break;
      case 'Remove translations':
        this.onTranslationDeleteConfirm();
        break;
      default:
    }
  }

  // when changing the language
  onLanguageChange(e, { value }) {
    this.onGotoLanguage(value);
  }

  // add poll option. Adds the parent to pollOptionCache but not the child yet.
  // This will cause the problem if you remove a option all the content from nearly added other poll options will also be remove
  onAddPollOption(reset = false) {
    const defaultOption = this.getDefaultOption(
      this.state.ga_language,
      this.state.ga_alpha_2
    );
    const defaultParent = this.getDefaultParent();

    Object.assign(defaultOption, { parentkey: defaultParent.parentkey });
    defaultParent.cm_children = [defaultOption];
    const { polloptions, pollOptionCache } = this.state;
    this.setState({ polloptions: polloptions.concat(defaultOption) });
    this.setState({ pollOptionCache: pollOptionCache.concat(defaultParent) });
    this.props.addMorePollOption(pollOptionCache, reset); // TODO en tiedä pitääkö olla defaultParent vai defaultOption
    //this.props.addMorePollOption(defaultOption, reset); // TODO en tiedä pitääkö olla defaultParent vai defaultOption
  }

  // update the polloption and set new state to polloption
  onUpdatePollOption(key, name, val) {
    const updated = this.state.polloptions.map(polloption => {
      if (polloption.key === key) {
        return { ...polloption, [name]: val };
      }
      return polloption;
    });
    this.props.editPollOptionsInit(updated);
    this.setState({ polloptions: updated });
  }

  onRemove(parentkey) {
    const parentId = this.getParentId(parentkey);
    this.setState({
      showConfirm: true,
      confirmType: 'Delete',
      selectedOptionId: parentId,
      selectedParentKey: parentkey,
    });
  }

  onGotoLanguage(language_id) {
    this.setState({
      gtr_name: '',
      gtr_description: '',
    });
    const { translationCache, polloptions } = this.state;
    const index = _.findIndex(
      translationCache,
      item => item.gtr_language === language_id
    );
    const alpha_2 = this.getLanguageAlpha2(language_id).ln_alpha_2;
    let newPollOptionCache = [];
    if (index !== -1) {
      // there is allready translations
      translationCache.forEach(tc => {
        if (tc.gtr_language === language_id) {
          this.setState({
            game_translation_id: tc.game_translation_id,
            [tc.gtr_code]: tc.gtr_text,
            gtr_application: tc.gtr_application,
            gtr_code: tc.gtr_code,
            gtr_alpha_2: alpha_2,
          });
        }
      });
      this.setState({ ga_language: language_id, ga_alpha_2: alpha_2 });
    } else {
      // there is no translations
      this.setState({
        ga_language: language_id,
        ga_alpha_2: alpha_2,
        gtr_alpha_2: alpha_2,
      });
    }

    newPollOptionCache = this.updatePollOptionCache(polloptions); // add new translations into pollOptionCache
    this.setState({
      polloptions: this.listPollOptions(
        newPollOptionCache,
        language_id,
        alpha_2
      ),
      pollOptionCache: newPollOptionCache,
      //translationitems: this.listTranslations(translationCache)
    });
  }

  onRemoveTranslation(language_id, alpha_2) {
    this.setState({
      showConfirm: true,
      confirmType: 'Remove translations',
      selectedLanguageId: language_id,
      selectedAlpha2: alpha_2,
    });
  }

  getLanguageList() {
    return _.map(this.props.languages, language => ({
      text: T(language.ln_name),
      value: language.language_id,
    }));
  }

  getLanguageAlpha2(language_id) {
    return _.find(
      this.props.languages,
      element => element.language_id === language_id
    );
  }

  getDate(dateObject) {
    return dateObject ? moment(dateObject).format(DATE_TIME_FORMAT) : null;
  }

  // list all events with event type match

  listEventsNotInUse() {
    let eventList = this.getEventList();
    if (this.state.ga_event) {
      const index = _.findIndex(
        eventList,
        item => item.value === this.state.ga_event
      );
      if (index === -1 && this.state.event) {
        // this event is not in list but it's in voting state
        const eventObject = this.state.event; // add this object into event list
        eventList.push({
          key: eventObject.event_id,
          text: `${eventObject.ev_name}: ${this.getDate(
            eventObject.ev_event_start
          )} - ${this.getDate(eventObject.ev_event_stop)}`,
          value: eventObject.event_id,
        });
      }
    }
    return eventList;
  }

  getEventList() {
    return _.map(this.props.parents.parents, event => ({
      key: event.event_id,
      text: `${event.ev_name}: ${this.getDate(
        event.ev_event_start
      )} - ${this.getDate(event.ev_event_stop)}`,
      value: event.event_id,
    }));
  }

  // list all teams with from selected event
  getTeamList(eventId) {
    let teams = [];
    const parents = this.props.parents.parents;
    if (parents && parents.length > 0) {
      const events = parents.filter(ev => ev.event_id === eventId);
      if (events && events.length > 0) {
        const event = events[0];
        teams = event.event_performers.map(team => ({
          key: team.performer_id,
          text: team.pf_name,
          value: team.performer_id,
        }));
      }
    }
    return teams;
  }

  // loop the pollOptionCache and add the selected languages into array
  getPollOptionCache(cms_contents) {
    try {
      cms_contents.map(content =>
        Object.assign(content, { parentkey: Math.random() })
      ); // add parentkey to parents
      cms_contents.forEach(parent => {
        // loop the children
        parent.cm_children.map(tmp =>
          Object.assign(tmp, {
            key: Math.random(),
            parentkey: parent.parentkey,
          })
        ); // add child to array
      });
      this.setState({ pollOptionCache: cms_contents });
      return cms_contents;
    } catch (e) {
      console.error('Error ', e);
    }
  }

  getParentId(parentkey) {
    const { pollOptionCache } = this.state;
    const index = _.findIndex(
      pollOptionCache,
      item => item.parentkey === parentkey
    );
    let parent = [];
    if (index !== -1) {
      parent = pollOptionCache[index];
    }
    return parent.cms_content_id;
  }

  getDefaultOption(language, alpha_2) {
    const defaultOption = {
      ...this.defaultOption,
      key: Math.random(),
      cm_lang_id: language,
      cm_language: alpha_2,
    };
    return defaultOption;
  }

  getDefaultParent() {
    const defaultParent = {
      ...this.defaultParent,
      parentkey: Math.random(),
      cm_lang_id: this.state.ga_language,
    };
    return defaultParent;
  }

  deleteParentId(tmpCache, parentkey) {
    const { ga_language, ga_alpha_2 } = this.state;
    const index = _.findIndex(tmpCache, item => item.parentkey === parentkey);
    if (index !== -1) {
      tmpCache.splice(index, 1);
      this.setState({ pollOptionCache: tmpCache });
      this.setState({
        polloptions: this.listPollOptions(tmpCache, ga_language, ga_alpha_2),
      });
    }
  }

  updatePollOptionCache(polloptions) {
    try {
      const { pollOptionCache } = this.state;
      let children = [];

      pollOptionCache.forEach(p => {
        children = p.cm_children;
        polloptions.forEach(polloption => {
          const index = _.findIndex(
            children,
            item => item.key === polloption.key
          );
          if (index !== -1) {
            // this poll option was a new one with default values
            // if the default polloption has a short header we add into a parent
            if (
              polloption.cm_lang_id === this.primary_language_id &&
              polloption.cm_short_header !== '' &&
              p.cm_short_header === ''
            ) {
              Object.assign(p, { cm_short_header: polloption.cm_short_header });
            }
            children[index] = polloption; // we update the values into default child
          } else if (polloption.parentkey === p.parentkey) {
            // this was a new translation and we add it into parent
            children.push(polloption);
          }
        });
      });
      return pollOptionCache;
    } catch (e) {
      console.error('Error ', e);
    }
  }

  // list all the options by current language
  listPollOptions(pollOptionCache, language, alpha_2) {
    try {
      const tmp = [];
      let children = [];
      let defaultOption = [];
      pollOptionCache.forEach(p => {
        children = p.cm_children;
        const index = _.findIndex(
          children,
          item => item.cm_lang_id === language
        );
        if (index !== -1) {
          tmp.push(children[index]);
        } else {
          defaultOption = this.getDefaultOption(language, alpha_2);
          Object.assign(defaultOption, { parentkey: p.parentkey });
          tmp.push(defaultOption);
        }
      });
      return tmp;
    } catch (e) {
      console.error('Error ', e);
    }
  }

  /*
   List all the translated names and descriptions
  */

  listTranslations(translationCache) {
    try {
      const translationitems = [];

      translationCache.forEach(item => {
        const index = _.findIndex(
          translationitems,
          tr => tr.gtr_language === item.gtr_language
        );
        if (index !== -1) {
          translationitems[index] = {
            ...translationitems[index],
            [item.gtr_code]: item.gtr_text,
          };
        } else {
          translationitems.push({
            //we save the previous item items
            [item.gtr_code]: item.gtr_text,
            gtr_language: item.gtr_language,
            gtr_alpha_2: this.getLanguageAlpha2(item.gtr_language).ln_alpha_2,
          });
        }
      });

      return translationitems;
    } catch (e) {
      console.error('Error ', e);
    }
  }

  /** editor state **/

  handleOptionFieldsChange(e, { name, value }) {
    const { cm_language, files, editorContentCache } = this.state;
    const index = _.findIndex(
      editorContentCache,
      item => item.cm_language === cm_language
    );
    if (index !== -1) {
      editorContentCache[index] = {
        ...editorContentCache[index],
        [name]: value,
      };
      this.setState({ editorContentCache, [name]: value });
    } else {
      this.setState({
        editorContentCache: editorContentCache.concat({
          cms_content_id: null,
          cm_language,
          cm_parent: null,
          [name]: value,
        }),
        [name]: value,
        files,
        editorState: EditorState.createEmpty(),
      });
    }
  }

  // handle translated fields

  handleTranslatedFieldChange(e, { name, value }) {
    const { ga_language, ga_alpha_2, translationCache, application_id } =
      this.state;
    //const {game_translation_id, gtr_tex, gtr_application, gtr_code, gtr_language };
    const index = _.findIndex(
      translationCache,
      item => item.gtr_language === ga_language && item.gtr_code === name
    );
    if (ga_language === this.primary_language_id) {
      this.setState({
        [name === 'gtr_name' ? 'ga_name' : 'ga_description']: value,
      });
    }
    if (index !== -1) {
      //translationCache[index] = { ...translationCache[index], [translation_code]: value };
      translationCache[index] = { ...translationCache[index], gtr_text: value };
      this.setState({ translationCache, [name]: value });
    } else {
      this.setState({
        translationCache: translationCache.concat({
          game_translation_id: null,
          gtr_text: value,
          gtr_application: application_id,
          gtr_code: name,
          gtr_language: ga_language,
          gtr_language_alpha_2: ga_alpha_2,
        }),
        [name]: value,
      });
    }
  }

  // handle meta data fields
  handleMetadataFieldChange(e, { name, value }) {
    const { metadatas } = this.state;
    // console.log('name ', name, ' value ', value);
    const index = _.findIndex(metadatas, item => item.gm_key === name);
    if (index !== -1) {
      metadatas[index] = { ...metadatas[index], gm_value: value };
      this.setState({ metadatas });
    } else {
      this.setState({
        metadatas: metadatas.concat({
          gm_key: name,
          gm_value: value,
        }),
      });
    }
  }

  validateInputs() {
    const requiredFields = ['ga_name', 'ga_game_stop'];
    const invalidFields = _.filter(
      requiredFields,
      field => this.state[field] === null || this.state[field].trim() === ''
    );
    if (invalidFields.length) {
      const errorFields = _.reduce(
        invalidFields,
        (acc, field) => ({ ...acc, [`${field}Error`]: true }),
        {}
      );
      this.setState({ ...errorFields });
      return false;
    }
    return true;
  }

  validateDateTime() {
    const requiredFields = [
      'ga_game_start',
      'ga_game_stop',
      'ga_visibility_start',
      'ga_visibility_stop',
      'ga_disclosure_time',
    ];
    const invalidFields = _.filter(
      requiredFields,
      field => this.state[field].trim() === ''
    );
    if (invalidFields.length) {
      const errorFields = _.reduce(
        invalidFields,
        (acc, field) => ({ ...acc, [`${field}Error`]: true }),
        {}
      );
      this.setState({ ...errorFields });
      return false;
    }
    return true;
  }

  validateTranslations(translationitems, isPollOption) {
    try {
      const errors = [T(TRANSLATION_ERROR_MESSAGE)];
      let children = [];
      let error = false;
      let child = {};
      const { pollOptionCache } = this.state;

      translationitems.forEach(translation => {
        // loop all the translations
        if (!translation.gtr_name) {
          errors.push(`${translation.gtr_alpha_2} name, `);
          error = true;
        }
        if (!translation.gtr_description) {
          errors.push(`${translation.gtr_alpha_2} description, `);
          error = true;
        }

        if (isPollOption) {
          pollOptionCache.forEach(p => {
            children = p.cm_children;
            const index = _.findIndex(
              children,
              item => item.cm_lang_id === translation.gtr_language
            );
            if (index === -1) {
              // theres no translation at all
              error = true;
              errors.push(`${translation.gtr_alpha_2} poll option, `);
            } else {
              child = children[index];
              if (child.cm_short_header === '' && child.cm_main_image === '') {
                // there is translation but it's empty
                error = true;
                errors.push(`${translation.gtr_alpha_2} poll option, `);
              }
            }
          });
        }
      });
      if (error) {
        const errorObj = getErrorMessage(this.props.voting.error, errors);
        this.setState({ errorObj });
      }
      return error === false;
    } catch (e) {
      console.error('Error ', e);
    }
  }

  postData() {
    const formIsValid = this.validateInputs();
    let cms_contents = null;
    let isPollOption = false;
    const translated = this.listTranslations(this.state.translationCache);

    if (
      this.state.ga_performer_parent === null ||
      this.ga_performer_parent < 0
    ) {
      isPollOption = true;
      cms_contents = this.updatePollOptionCache(this.state.polloptions);
    }

    const translationsIsValid = this.validateTranslations(
      translated,
      isPollOption
    );
    this.onGotoLanguage(this.primary_language_id);

    if (formIsValid && translationsIsValid) {
      this.setState({ is_dirty: false });
      // create games object
      const { id } = this.props.match.params;

      //    let updated;
      let voting = this.state;
      const {
        ga_created,
        ga_disclosure_time,
        ga_game_start,
        ga_game_stop,
        ga_visibility_start,
        ga_visibility_stop,
      } = voting;

      const number_of_options = this.state.polloptions.length;

      voting = {
        ...voting,
        ga_user_max_stakes: this.state.isMultipleStakes ? number_of_options : 1,
        ga_created: ga_created ? moment(ga_created).toISOString() : null,
        ga_modified: moment().toISOString(),
        ga_disclosure_time: ga_disclosure_time
          ? moment(ga_disclosure_time, DATE_TIME_FORMAT).toISOString()
          : null,
        ga_game_start: ga_game_start
          ? moment(ga_game_start, DATE_TIME_FORMAT).toISOString()
          : null,
        ga_game_stop: ga_game_stop
          ? moment(ga_game_stop, DATE_TIME_FORMAT).toISOString()
          : null,
        ga_visibility_start: ga_visibility_start
          ? moment(ga_visibility_start, DATE_TIME_FORMAT).toISOString()
          : null,
        ga_visibility_stop: ga_visibility_stop
          ? moment(ga_visibility_stop, DATE_TIME_FORMAT).toISOString()
          : null,
      };
      const authKey = localStorage.getItem('x-auth-key');
      const ws_id = this.props.ws_id;
      //const appliation_id= this.props.workspace.workspaces[this.props.ws_id].cnc_company;
      const application_id =
        this.props.workspace.workspaces[this.props.ws_id].cnc_application;
      const translations = this.state.translationCache;
      const votingTranslations = { ...voting, translations, cms_contents };
      //    const pollOptionTranslations = { ...voting, polloptions };
      const prunedVoting = _.omit(votingTranslations, VOTING_TRANSLATIONS); //prune json*/
      if (id) {
        // edit voting
        this.props.editVoting(
          authKey,
          id,
          prunedVoting,
          MODULE_ID,
          ws_id,
          application_id
        );
      } else {
        const updatedVoting = { ...prunedVoting, application_id };
        // add voting
        this.props.addVoting(
          authKey,
          updatedVoting,
          MODULE_ID,
          ws_id,
          application_id
        );
      }
    }
  }

  initializeState(edit) {
    let voting;
    try {
      if (this.props.voting.voting) {
        voting = this.props.voting.voting[0];
      }
    } catch (e) {
      console.log('Fetch voting in progress', e);
    }
    if (edit && voting) {
      //let editorState = EditorState.createEmpty();
      // 1. matching content for the switch

      const translationCache = voting.translations;
      const gtr_name = voting.ga_name;
      const gtr_description = voting.ga_description;
      const ga_game_start = voting.ga_game_start
        ? moment(voting.ga_game_start).format(DATE_TIME_FORMAT)
        : null;
      const ga_game_stop = voting.ga_game_stop
        ? moment(voting.ga_game_stop).format(DATE_TIME_FORMAT)
        : null;
      const ga_visibility_start = voting.ga_visibility_start
        ? moment(voting.ga_visibility_start).format(DATE_TIME_FORMAT)
        : null;
      const ga_visibility_stop = voting.ga_visibility_stop
        ? moment(voting.ga_visibility_stop).format(DATE_TIME_FORMAT)
        : null;
      const ga_disclosure_time = voting.ga_disclosure_time
        ? moment(voting.ga_disclosure_time).format(DATE_TIME_FORMAT)
        : null;
      const pollOptionCache = this.getPollOptionCache(voting.cms_contents);
      const polloptions = this.listPollOptions(
        pollOptionCache,
        this.primary_language_id,
        this.primary_alpha_2
      );
      const showTeams =
        voting.event_performers_count && voting.event_performers_count > 0
          ? true
          : false;

      const metadatas = voting.metadatas;

      this.props.editPollOptionsInit(polloptions);
      this.setState({
        ...this.state,
        ...voting,
        translationCache,
        ga_language: this.primary_language_id,
        gtr_name,
        gtr_description,
        ga_visibility_start,
        ga_game_start,
        ga_game_stop,
        ga_visibility_stop,
        ga_disclosure_time,
        pollOptionCache,
        polloptions,
        showTeams,
        metadatas,
      });
    }
  }

  handleDismiss() {
    this.setState({ errorObj: null });
  }

  handleInputChange(name, value) {
    if (name === 'startImmediately' && value === true) {
      this.setState({
        [name]: value,
        ga_game_start: moment().format(DATE_TIME_FORMAT),
      });
    } else if (name === 'stopImmediately' && value === true) {
      this.setState({
        [name]: value,
        ga_game_stop: moment().format(DATE_TIME_FORMAT),
      });
    } else if (name === 'visibleImmediately' && value === true) {
      this.setState({
        [name]: value,
        ga_visibility_start: moment().format(DATE_TIME_FORMAT),
      });
    } else if (name === 'disclosureTime' && value === true) {
      //this.setState({ [name]: value, ga_disclosure_time: moment().format(DATE_TIME_FORMAT) });
      this.setState({
        [name]: value,
        ga_disclosure_time: this.state.ga_game_start,
      });
    } else if (name === 'keepVisible' && value === true) {
      this.setState({
        [name]: value,
        ga_visibility_stop: this.state.ga_game_stop,
      });
    } else {
      this.setState({ [name]: value === '' ? null : value });
    }
  }

  handleEventChange(value) {
    let ga_event = null;
    let ga_performer_type = null;
    let ga_performer_parent = null;
    let teams = [];
    let showTeams = false;
    if (value !== '') {
      const events = this.props.parents.parents.filter(
        ev => ev.event_id === value
      );
      if (events && events.length > 0) {
        const event = events[0];
        ga_event = event.event_id;
        teams = this.getTeamList(event.event_id);
        if (teams && teams.length > 0) {
          showTeams = true;
          ga_performer_type = 'player';
        }
      }
    }
    this.setState({
      ga_event,
      ga_performer_type,
      teams,
      showTeams,
      ga_performer_parent,
    });
  }

  handleTeamChange(value) {
    let ga_performer_type = null;
    let ga_performer_parent = null;
    if (value !== '') {
      ga_performer_type = 'player';
      ga_performer_parent = value;
    }
    this.setState({ ga_performer_type, ga_performer_parent });
  }

  promptError() {
    if (this.state.pollOptionError) {
      return (
        <div className="ui error message">
          <div className="header">{this.state.pollOptionErrorMessages}</div>
        </div>
      );
    }
  }
  saveVoting() {
    this.postData();
  }

  imageUpdate(urlSmall, urlLarge, key, fileId, target) {
    if (target === GAME_UPLOAD_TARGET) {
      this.setState({
        ga_header_image: urlLarge,
        ga_header_image_file_id: fileId,
      });
    } else {
      this.onUpdatePollOption(key, IMAGE_URL, urlSmall);
    }
  }

  deleteImage(e, { file }) {
    const files = this.state.files;
    const index = _.findIndex(files, item => item.preview === file.preview);
    if (index !== -1) {
      files.splice(index, 1);
    }
    this.setState({ ga_header_image_deleted: true, deleted_file: file, files });
    //this.props.deleteImage(file);
  }

  setSponsorImageDeleted() {
    this.setState({ ga_header_image_file_id: null, ga_header_image: null });
  }

  dialogueText(type) {
    switch (type) {
      case 'Remove translations':
        return `${T('Are you sure you want to remove the translation?')} ${
          this.state.selectedAlpha2
        }`;
      default:
        return T('Are you sure you want to delete the selected poll option?');
    }
  }

  getMetadataValue(key, defaultValue = '') {
    const filtered = this.state.metadatas.filter(item => item.gm_key === key);
    if (filtered.length > 0) {
      return filtered[0].gm_value;
    }
    return defaultValue;
  }

  handleClick(picker) {
    this.setState({
      displayColorPicker:
        this.state.displayColorPicker === picker ? false : picker,
    });
  }

  handleClose() {
    this.setState({ displayColorPicker: false });
  }

  handleColorChange(metadataKey, color) {
    this.setState({
      metadatas: [
        ...this.state.metadatas.filter(item => item.gm_key !== metadataKey),
        { gm_key: metadataKey, gm_value: color.hex },
      ],
    });
  }
  render() {
    if (
      this.state.workSpace.cnc_event !== null &&
      (this.props.parents.statusParents === 'waiting' ||
        this.props.parents.statusParents === 'init')
    ) {
      return <Spinner />;
    }
    const confirmationText = this.dialogueText(this.state.confirmType);
    const { id } = this.props.match.params;
    const errorObj = this.state.errorObj;
    const { showEvents, showTeams, edit_button_color } = this.state;
    const styles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '36px',
          borderRadius: '2px',
        },
        swatch: {
          padding: '5px',
          marginTop: '10px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    /*
    <Form.Input
        name='ga_type'
        label={T('Voting type')}
        value={this.state.ga_type}
        readOnly
    />*/
    return (
      <Segment>
        <Form className="ui form error">
          <Header> {id ? T('Edit voting') : T('Add voting')} </Header>
          <Segment>
            <Label attached="top">
              {T('Translations')}&nbsp;
              <PopupContent content={POPUP_CONTENT_1} color={POPUP_COLOR} />
            </Label>
            <Form.Field>
              <TranslationList
                ws_id={this.props.ws_id}
                workspace={this.props.workspace}
                onGotoLanguage={this.onGotoLanguage.bind(this)}
                onRemoveTranslation={this.onRemoveTranslation.bind(this)}
                //translationitems={this.state.translationitems}
                translationitems={this.listTranslations(
                  this.state.translationCache
                )}
              />
            </Form.Field>
          </Segment>
          <Segment>
            <Label attached="top">
              {T('Name and description')}&nbsp;
              <PopupContent content={POPUP_CONTENT_2} color={POPUP_COLOR} />
            </Label>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Select
                  required
                  search
                  name="ga_language"
                  label={T('Language')}
                  value={this.state.ga_language}
                  options={this.getLanguageList()} // onChange={(e, { name, value }) => this.handleInputChange(name, value)}
                  onChange={this.onLanguageChange.bind(this)}
                  //onChange={(e, { value, alpha2 }) => this.onLanguageChange(value, alpha2)}
                />
              </Form.Field>
              <Form.Field />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                name="gtr_name"
                label={T('Name')}
                placeholder={T('voting name')}
                value={this.state.gtr_name}
                onChange={this.handleTranslatedFieldChange}
                //onChange={(e, { name, value }) => this.handleFieldChange(name, value)}
                //onChange={(e, { name, value }) => this.handleInputChange(name, value)}
              />
              <Form.Input
                required
                name="gtr_description"
                label={T('Description')}
                placeholder={T('voting description')}
                value={this.state.gtr_description}
                onChange={this.handleTranslatedFieldChange}
                //onChange={(e, { name, value }) => this.handleInputChange(name, value)}
              />
            </Form.Group>
            {showEvents ? (
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Select
                    placeholder={T('Select Event')}
                    label={T('Event')}
                    name="ga_event"
                    options={this.getEventList()}
                    onChange={(e, { value }) => this.handleEventChange(value)}
                    value={this.state.ga_event}
                    clearable
                    search
                  />
                </Form.Field>
                {showTeams ? (
                  <Form.Field>
                    <Form.Select
                      placeholder={T('Select Team')}
                      label={T('Teams')}
                      name="ga_performer_parent"
                      options={this.getTeamList(this.state.ga_event)}
                      onChange={(e, { value }) => this.handleTeamChange(value)}
                      value={this.state.ga_performer_parent}
                      clearable
                      search
                    />
                  </Form.Field>
                ) : (
                  <Form.Field />
                )}
              </Form.Group>
            ) : null}
            <Form.Group>
              <Form.Field>
                <label>{T('Sponsor Image')}</label>
                <FileUpload
                  id={this.state.workSpace.cnc_application}
                  file_key={this.state.key}
                  file_url={this.state.ga_header_image || undefined}
                  file_id={this.state.ga_header_image_file_id}
                  ws_id={this.props.ws_id}
                  target={GAME_UPLOAD_TARGET}
                  module_id={MODULE_ID}
                  callback={this.imageUpdate.bind(this)}
                  deletecallback={this.setSponsorImageDeleted.bind(this)}
                  style={imageStyle}
                />
              </Form.Field>
            </Form.Group>
          </Segment>
          <Segment>
            <Label attached="top">{T('Vote Settings')}&nbsp;</Label>
          </Segment>
          <Segment>
            <Label attached="top">
              {T('User interface customizations')}&nbsp;
              <PopupContent
                content={T(
                  'How the poll is advertised e.g. on the app front page.'
                )}
                color={POPUP_COLOR}
              />
            </Label>
            <Form.Group widths="equal">
              <Form.Input
                required
                name="buttonText"
                label={T('Button Text')}
                placeholder={T('Vote')}
                value={this.getMetadataValue('buttonText')}
                onChange={this.handleMetadataFieldChange}
              />
              <Form.Field>
                <div>
                  <Form.Input
                    onClick={() => this.handleClick(1)}
                    label={T('Button Color')}
                    name="buttonColor"
                    value={this.getMetadataValue('buttonColor')}
                    placeholder={T('Oletusväri')}
                    onFocus={() => this.handleClick(1)}
                  />
                  {this.state.displayColorPicker === 1 ? (
                    <div style={styles.popover}>
                      <div style={styles.cover} onClick={this.handleClose} />
                      <SketchPicker
                        color={this.getMetadataValue('buttonColor')}
                        onChange={color => {
                          this.handleColorChange('buttonColor', color);
                        }}
                        onChangeComplete={color => {
                          this.handleClick(0);
                        }}
                      />
                    </div>
                  ) : null}
                  {this.getMetadataValue('buttonColor') && (
                    <div
                      style={styles.swatch}
                      onClick={() => this.handleClick(1)}
                    >
                      <div
                        style={{
                          ...styles.color,
                          backgroundColor: this.getMetadataValue('buttonColor'),
                        }}
                      />
                    </div>
                  )}
                </div>
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                name="gameTitle"
                label={T('Game Title')}
                placeholder={T('Vote')}
                value={this.getMetadataValue('gameTitle')}
                onChange={this.handleMetadataFieldChange}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Checkbox
                toggle
                name={'showTitle'}
                checked={this.getMetadataValue('showTitle') == 'true'}
                label={T('Show game title next to button')}
                onChange={(_, { checked }) =>
                  this.handleMetadataFieldChange(null, {
                    name: 'showTitle',
                    value: checked ? 'true' : 'false',
                  })
                }
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.TextArea
                fluid="true"
                required
                rows={4}
                name="gameInfo"
                label={T('Game Info')}
                placeholder={T('Syötä kyselyn lisätiedot tähän')}
                value={this.getMetadataValue('gameInfo')}
                onChange={this.handleMetadataFieldChange}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Checkbox
                toggle
                name={'isMultipleChoice'}
                checked={this.getMetadataValue('isMultipleChoice') == 'true'}
                label={T('Multiple Choice Questionnaire')}
                onChange={(_, { checked }) =>
                  this.handleMetadataFieldChange(null, {
                    name: 'isMultipleChoice',
                    value: checked ? 'true' : 'false',
                  })
                }
              />
            </Form.Group>
          </Segment>
          {this.state.ga_performer_parent &&
          this.state.ga_performer_parent > 0 ? null : (
            <Segment>
              <Label attached="top">
                {T('Targets')}
                &nbsp;
                <PopupContent
                  content={
                    'Define the poll options. You have to translate also the poll options'
                  }
                  color={POPUP_COLOR}
                />
              </Label>

              <Form.Field>
                <PollOptionContainer
                  onSavePollOption={this.postData.bind(this)}
                  ws_id={this.props.ws_id}
                  workspace={this.props.workspace}
                  onAddPollOption={this.onAddPollOption.bind(this)}
                  onRemove={this.onRemove.bind(this)}
                  onUpdatePollOption={this.onUpdatePollOption.bind(this)}
                  polloptions={this.state.polloptions} // TODO tämä toimii listauksessa muttei enää image updatess joten yritä saada tuo props:iin
                  //polloptions={this.props.polloptions}
                  language={this.state.ga_language}
                  game_id={Number(id)}
                  imageUpdate={this.imageUpdate.bind(this)}
                  popupcolor={POPUP_COLOR}
                  popupsize={POPUP_SIZE}
                  popupwide={POPUP_WIDE}
                />
              </Form.Field>
            </Segment>
          )}
          <Segment>
            <Label attached="top">
              {T('Dates and times')}&nbsp;
              <PopupContent content={POPUP_CONTENT_3} color={POPUP_COLOR} />
            </Label>
            <Form.Group widths={4}>
              <PopupContent
                wide={POPUP_WIDE}
                content={POPUP_CONTENT_3}
                size={POPUP_SIZE}
                color={POPUP_COLOR}
              />
              <Form.Field>
                <DateTimeInput
                  width={10}
                  closable
                  name="ga_visibility_start"
                  disabled={this.state.visibleImmediately}
                  dateTimeFormat={DATE_TIME_FORMAT}
                  label={T('Visible in apps')}
                  value={this.state.ga_visibility_start || ''}
                  iconPosition="left"
                  onChange={(e, { name, value }) =>
                    this.handleInputChange(name, value)
                  }
                  timeFormat="24"
                  autoComplete="off"
                  history="off"
                  animation="off"
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  toggle
                  width={2}
                  style={{ marginTop: '25px' }}
                  name="visibleImmediately"
                  label={T('Visible immediately')}
                  checked={this.state.visbleImmediately}
                  onChange={(e, { name, checked }) =>
                    this.handleInputChange(name, checked)
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={4}>
              <PopupContent
                content={POPUP_CONTENT_4}
                size={POPUP_SIZE}
                color={POPUP_COLOR}
              />
              <Form.Field>
                <DateTimeInput
                  width={10}
                  closable
                  name="ga_game_start"
                  disabled={this.state.startImmediately}
                  dateTimeFormat={DATE_TIME_FORMAT}
                  label={T('Voting starts')}
                  value={this.state.ga_game_start || ''}
                  iconPosition="left"
                  onChange={(e, { name, value }) =>
                    this.handleInputChange(name, value)
                  }
                  timeFormat="24"
                  autoComplete="off"
                  history="off"
                  animation="off"
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  toggle
                  width={2}
                  style={{ marginTop: '25px' }}
                  name="startImmediately"
                  label={T('Start immediately')}
                  checked={this.state.startImmediately}
                  onChange={(e, { name, checked }) =>
                    this.handleInputChange(name, checked)
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={4}>
              <PopupContent
                content={POPUP_CONTENT_5}
                size={POPUP_SIZE}
                color={POPUP_COLOR}
              />
              <Form.Field>
                <DateTimeInput
                  width={10}
                  closable
                  name="ga_game_stop"
                  disabled={this.state.stopImmediately}
                  dateTimeFormat={DATE_TIME_FORMAT}
                  label={T('Voting ends')}
                  value={this.state.ga_game_stop}
                  iconPosition="left"
                  onChange={(e, { name, value }) =>
                    this.handleInputChange(name, value)
                  }
                  timeFormat="24"
                  autoComplete="off"
                  history="off"
                  animation="off"
                  required
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  toggle
                  width={2}
                  style={{ marginTop: '25px' }}
                  name="stopImmediately"
                  label={T('End immediately')}
                  checked={this.state.stopImmediately}
                  onChange={(e, { name, checked }) =>
                    this.handleInputChange(name, checked)
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={4}>
              <PopupContent
                content={POPUP_CONTENT_6}
                size={POPUP_SIZE}
                color={POPUP_COLOR}
              />
              <Form.Field>
                <DateTimeInput
                  width={10}
                  closable
                  name="ga_visibility_stop"
                  disabled={this.state.keepVisible}
                  dateTimeFormat={DATE_TIME_FORMAT}
                  label={T('Invisible in apps')}
                  value={this.state.ga_visibility_stop || ''}
                  iconPosition="left"
                  onChange={(e, { name, value }) =>
                    this.handleInputChange(name, value)
                  }
                  timeFormat="24"
                  autoComplete="off"
                  history="off"
                  animation="off"
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  toggle
                  width={2}
                  style={{ marginTop: '25px' }}
                  name="keepVisible"
                  label={T('Keep visible until voting ends')}
                  checked={this.state.keepVisible}
                  onChange={(e, { name, checked }) =>
                    this.handleInputChange(name, checked)
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={4}>
              <PopupContent
                content={POPUP_CONTENT_7}
                size={POPUP_SIZE}
                color={POPUP_COLOR}
              />
              <Form.Field>
                <DateTimeInput
                  width={10}
                  closable
                  name="ga_disclosure_time"
                  disabled={this.state.disclosureTime}
                  dateTimeFormat={DATE_TIME_FORMAT}
                  label={T('Show results')}
                  value={this.state.ga_disclosure_time || ''}
                  iconPosition="left"
                  onChange={(e, { name, value }) =>
                    this.handleInputChange(name, value)
                  }
                  timeFormat="24"
                  autoComplete="off"
                  history="off"
                  animation="off"
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  toggle
                  width={2}
                  style={{ marginTop: '25px' }}
                  name="disclosureTime"
                  label={T('Show results immediately')}
                  checked={this.state.disclosureTime}
                  onChange={(e, { name, checked }) =>
                    this.handleInputChange(name, checked)
                  }
                />
              </Form.Field>
            </Form.Group>
          </Segment>
          {errorObj ? (
            <Message
              onDismiss={this.handleDismiss}
              error
              header={T(errorObj.error)}
              list={[T(errorObj.message)]}
              key={T(errorObj.error)}
            />
          ) : null}
          <Button.Group>
            <Button
              color="teal"
              content={T('Save')}
              icon="save"
              onClick={this.saveVoting}
            />
            <Button
              secondary
              icon="undo"
              content={T('Cancel')}
              onClick={this.onCancel}
            />
          </Button.Group>
          <Confirm
            header={T('Are you sure?')}
            content={confirmationText}
            cancelButton={T('Cancel')}
            confirmButton={T(this.state.confirmType)}
            open={this.state.showConfirm}
            onCancel={this.onCancelConfirm}
            onConfirm={this.onConfirm}
            size="tiny"
          />
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({ auth, workspace, languages, voting, parents }) {
  return {
    auth,
    ws_id: workspace.ws_id,
    workspace,
    languages: languages.languages,
    voting,
    parents,
  };
}

export default connect(mapStateToProps, {
  addVoting,
  editVoting,
  fetchVoting,
  resetAddVoting,
  resetEditVoting,
  resetFetchVoting,
  resetDeleteVoting,
  addMorePollOption,
  editPollOptionsInit,
  deletePollOption,
  resetPollOptions,
  updatePollOptionImage,
  fetchEventsByType,
})(withRouter(ManageVoting));

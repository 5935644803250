import _ from 'lodash';
import {
  FETCH_CURRENCIES_WAITING,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_ERROR,
  FETCH_CURRENCIES_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  status: 'init',
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_CURRENCIES_WAITING:
      return { ...state, status: 'waiting' };
    case FETCH_CURRENCIES_SUCCESS:
      return {
        data: _.mapKeys(action.payload.data.items, 'currency_iso_id'),
        headers: action.payload.headers,
        status: 'success',
      };
    case FETCH_CURRENCIES_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    case FETCH_CURRENCIES_RESET:
      return { ...state, status: 'init' };
    default:
      return state;
  }
}

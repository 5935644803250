import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import Nav from '../layout/Nav';

import SalesReportContainer from '../components/Report/Sales/SalesReportContainer';
import EventReportContainer from '../components/Report/Event/EventReportContainer';
import EventStatisticContainer from '../components/Report/Event/EventStatisticContainer';
import AppStatisticContainer from '../components/Report/App/AppStatisticContainer';

const ReportRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: '150px' }}>
      <Nav />
      <Switch>
        <Route path={`${path}/event/list`} exact>
          <EventReportContainer />
        </Route>
        <Route path={`${path}/event/moment/statistic`} exact>
          <EventStatisticContainer />
        </Route>
        <Route path={`${path}/sales/list`} exact>
          <SalesReportContainer />
        </Route>
        <Route path={`${path}/app/list`} exact>
          <AppStatisticContainer />
        </Route>
      </Switch>
    </Container>
  );
};

export default ReportRoute;

/* eslint-env browser */
import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { Table, Button, Confirm } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import {
  //fetchProducts,
  // resetProducts,
  // addProductPrice,
  editProductPrice,
  deleteProductPrice,
  // resetAddProductPrice,
  resetEditProductPrice,
  deleteProductPriceReset,
  fetchProducts,
} from '../../Redux/actions';
import {
  T,
  StatusIcon,
  getErrorMessage,
  convertPrice,
} from '../Common/Helpers';

//const pp_currency = 'pts';
// const pp_price_list = 27;

class RenderPrices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceLists: this.props.priceLists,
      listing: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.product.priceEditStatus !== 'error' &&
      this.props.product.priceEditStatus === 'error'
    ) {
      this.props.resetEditProductPrice();
      const errorObj = getErrorMessage(
        this.props.product.error,
        'Could not edit ticket price'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      prevProps.product.priceEditStatus !== 'success' &&
      this.props.product.priceEditStatus === 'success'
    ) {
      this.props.resetEditProductPrice();
      toast({
        type: 'success',
        animation: 'scale',
        title: T('Success'),
        description: T('Ticket price edited successfully'),
        time: 5000,
      });
    } else if (
      prevProps.product.deletePPStatus !== 'error' &&
      this.props.product.deletePPStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.product.error,
        'Could not delete ticket price'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.deleteProductPriceReset();
    } else if (
      prevProps.product.deletePPStatus !== 'success' &&
      this.props.product.deletePPStatus === 'success'
    ) {
      this.props.deleteProductPriceReset();
      toast({
        type: 'success',
        animation: 'scale',
        title: T('Success'),
        description: T('Ticket price deleted successfully'),
        time: 5000,
      });
      const ws_id = this.props.workspace.activeWorkspace.id;
      const authKey = localStorage.getItem('x-auth-key');
      this.props.fetchProducts({
        authKey,
        sortByColumn: 'product_id',
        direction: 'asc',
        start:
          (this.props.product.headers &&
            this.props.product.headers.current_page) ||
          1,
        searchterm: null,
        pr_type: 'entry',
        ws_id,
        module_id: 522,
      });
    }
  }

  onConfirmDelete = () => {
    const authKey = localStorage.getItem('x-auth-key');
    const { productPriceId, productId } = this.state;
    const ws_id = this.props.workspace.activeWorkspace.id;
    this.props.deleteProductPrice(
      productPriceId,
      authKey,
      productId,
      ws_id,
      522
    );
    this.setState({ openDeleteConfirmation: false });
  };

  onDeleteClick = (e, productPriceId, productId) => {
    e.stopPropagation();
    this.setState({ openDeleteConfirmation: true, productPriceId, productId });
  };

  oncancelDeleteConfirmation = () => {
    this.setState({ openDeleteConfirmation: false });
  };

  setPrice = prices => this.setState({ prices });

  handlePriceChange = (e, product_price_id) => {
    if (this.state.listing === true) {
      this.setState({ listing: false });
    }
    e.stopPropagation();
    const price = e.target.value;
    // console.log('updated Price: ', price);
    const priceList = { ...this.state.priceLists[product_price_id] };
    priceList.price = price;
    const products = {
      ...this.state.priceLists,
      [product_price_id]: priceList,
    };
    this.setState({ products });
  };

  savePrice = (e, product_price_id, pp_price_list) => {
    e.stopPropagation();
    if (this.state.products !== undefined) {
      const authKey = localStorage.getItem('x-auth-key');
      const product = this.state.products[product_price_id];
      const pp_product = product.pr_id;
      const pp_price = product.price;
      const pp_currency = product.pp_currency;
      const ws_id = this.props.workspace.activeWorkspace.id;
      this.props.editProductPrice({
        authKey,
        data: {
          pp_product,
          pp_price_list,
          pp_price: parseFloat(pp_price.replace(',', '.'), 10),
          pp_currency,
          product_price_id,
        },
        id: product_price_id,
        ws_id,
        module_id: 522,
      });
    }
  };

  navigateToLink = productId => {
    this.props.history.push(`/app/product/manage/${productId}`);
  };

  renderProducts = () => {
    // const tableBody = [];
    const { priceLists } = this.state;
    return _.map(priceLists, (pricelist, key) => (
      // tableBody.push(
      <Table.Row
        key={key}
        className="tablerow"
        onClick={() => this.navigateToLink(pricelist.pr_id)}
      >
        <Table.Cell>{pricelist.pr_id}</Table.Cell>
        <Table.Cell>{pricelist.pr_name}</Table.Cell>
        <Table.Cell>{pricelist.pr_code}</Table.Cell>
        <Table.Cell>{pricelist.pr_short_description} </Table.Cell>
        <Table.Cell>{pricelist.pp_price_list} </Table.Cell>
        <Table.Cell>{pricelist.product_price_id} </Table.Cell>
        <Table.Cell>{pricelist.pp_currency} </Table.Cell>
        <Table.Cell>
          <StatusIcon status={pricelist.pr_active} />
        </Table.Cell>
        <Table.Cell
          textAlign="right"
          style={{ cursor: 'default' }}
          onClick={e => e.stopPropagation()}
        >
          <Form>
            <Form.Group key={`price-${pricelist.pr_id}`}>
              <Form.Input
                id={pricelist.pr_id.toString()}
                pp_product={pricelist.pr_id}
                product_price_id={pricelist.product_price_id}
                price_list={pricelist.pp_price_list}
                name="product_price"
                defaultValue={
                  this.state.listing
                    ? convertPrice(pricelist.pp_currency, pricelist.price)
                    : pricelist.price
                }
                value={
                  this.state.priceLists[pricelist.product_price_id].price || ''
                }
                type="number"
                step={0.05}
                onChange={e =>
                  this.handlePriceChange(e, pricelist.product_price_id)
                }
              />
              <Button
                color="green"
                icon="save"
                size="mini"
                onClick={e =>
                  this.savePrice(
                    e,
                    pricelist.product_price_id,
                    pricelist.pp_price_list
                  )
                }
              />
            </Form.Group>
          </Form>
        </Table.Cell>
        <Table.Cell>
          <Button
            color="red"
            icon="trash"
            size="mini"
            onClick={e =>
              this.onDeleteClick(e, pricelist.product_price_id, pricelist.pr_id)
            }
          />
        </Table.Cell>
      </Table.Row>
    ));

    // console.log(tableBody.length);
    // return tableBody;
  };
  render() {
    return (
      <>
        {this.renderProducts()}
        <Confirm
          header={T('Delete Ticket')}
          content={T('Are you sure you want to delete the ticket?')}
          confirmButton={T('Delete')}
          cancelButton={T('Cancel')}
          open={this.state.openDeleteConfirmation}
          onCancel={this.oncancelDeleteConfirmation}
          onConfirm={this.onConfirmDelete}
          size="tiny"
        />
      </>
    );
  }
}

function mapStateToProps({ auth, product, workspace }) {
  return { auth, product, workspace };
}

export default connect(mapStateToProps, {
  //fetchProducts,
  //resetProducts,
  // addProductPrice,
  editProductPrice,
  deleteProductPrice,
  // resetAddProductPrice,
  resetEditProductPrice,
  deleteProductPriceReset,
  fetchProducts,
})(withRouter(RenderPrices));

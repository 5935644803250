import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Icon, Pagination } from "semantic-ui-react";
import { withRouter } from "react-router";
import moment from "moment";
import { Spinner } from "../Common/Spinner";
import { T } from "../Common/Helpers";
import {
  getEventState,
  getMetadataValue,
  getEventPerformerByPosition,
} from "./WorkCaseUtil";

const DATETIMEFORMAT = "l HH:mm";

class WorkCaseList extends Component {
  getPlaceName(place_id) {
    const place = this.props.momentOptions.event_places.find(
      (pl) => pl.place_id === place_id
    );
    return place ? place.pl_name : place_id;
  }

  getMomentERC(moments) {
    const tmp = moments.find((moment) => moment.mt_key === "ERC");
    return tmp ? new moment(tmp.mt_timestamp).format(DATETIMEFORMAT) : null;
  }

  renderHeader() {
    return (
      <Table.Row>
        <Table.HeaderCell>{T("Tapaht.k.")}</Table.HeaderCell>
        <Table.HeaderCell>{T("Syy")}</Table.HeaderCell>
        <Table.HeaderCell>{T("Kiir.")}</Table.HeaderCell>
        <Table.HeaderCell>{T("Saap.")}</Table.HeaderCell>
        <Table.HeaderCell>{T("Lisätiedot")}</Table.HeaderCell>
        <Table.HeaderCell>{T("Työparit")}</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Row>
    );
  }

  renderEvents() {
    const { selectedEvent } = this.props;
    return this.props.events.events.map((e, i) => {
      // console.log('EVENT ', e);
      const eventState = getEventState({ event: e, moments: e.moments });
      const source = getEventPerformerByPosition({
        eventPerformers: e.event_performers,
        position: "source",
      });
      const { isOnBreak, isArchived } = eventState;
      // let status;
      let statusCol = "default";
      if (isOnBreak) {
        // if(!this.props.showOnBreak) {
        //   return null;
        // }
        // status = STATUS_ENDED;
        statusCol = i & (1 === 1) ? "#ffff00" : "#f0f000";
      } else if (isArchived) {
        // if(!this.props.showArchived) {
        //   return null;
        // }
        // status = STATUS_ARCHIVED;
        statusCol = i & (1 === 1) ? "#fafafa" : "#e0e0e0";
      } else {
        // if(!this.props.showRunning) {
        //   return null;
        // }
        // status = STATUS_STARTED;
        statusCol = i & (1 === 1) ? "#ff9090" : "#f07a7a";
      }
      return (
        <Table.Row
          key={`wle${i}`}
          style={{
            backgroundColor: statusCol,
            borderWidth: selectedEvent === e.event_id ? 5 : 0,
          }}
          onClick={() => {
            this.props.onSelectChange(e.event_id);
          }}
        >
          <Table.Cell>{this.getPlaceName(e.ev_place)}</Table.Cell>
          <Table.Cell>{e.ev_name}</Table.Cell>
          <Table.Cell>
            {getMetadataValue({ event: e, emKey: "triage" })}
          </Table.Cell>
          <Table.Cell textAlign="left">
            {this.getMomentERC(e.moments)}
          </Table.Cell>
          <Table.Cell>
            {e.ev_description &&
              (e.ev_description.length > 100
                ? e.ev_description.substring(0, 100) + "..."
                : e.ev_description)}
          </Table.Cell>
          <Table.Cell>
            {getMetadataValue({ event: e, emKey: "working_couple" })}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {selectedEvent === e.event_id && (
              <Icon name="long arrow alternate right" />
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  }
  renderPagination() {
    const { total, total_pages, current_page } = this.props.events.headers;

    const rows_per_page = 100;

    const firstItem = (current_page - 1) * rows_per_page + 1;
    let lastItem = current_page * rows_per_page;
    if (lastItem > total) {
      lastItem = total;
    }

    //const { headers } = this.props.eventReport.report;
    return (
      <Table.Row>
        <Table.HeaderCell colSpan="8">
          {`${firstItem} - ${lastItem} / ${total}`}
          <Pagination
            floated="right"
            onPageChange={this.props.handlePaginationChange}
            activePage={current_page}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={total_pages}
          />
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  render() {
    // console.log('WORK CASE LIST RENDER', this.props);
    if (
      this.props.events.fetchesStatus === "waiting" ||
      !this.props.events.events
    ) {
      return <Spinner />;
    }
    return (
      <Table celled striped selectable>
        <Table.Header>{this.renderHeader()}</Table.Header>
        <Table.Body>{this.renderEvents()}</Table.Body>
        <Table.Footer>{this.renderPagination()}</Table.Footer>
      </Table>
    );
  }
}

function mapStateToProps({ events, eventReport }) {
  return {
    events,
    momentOptions: eventReport.momentOptions,
  };
}
export default connect(mapStateToProps, {})(withRouter(WorkCaseList));

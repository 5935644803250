import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  FETCH_MESSAGE_TEMPLATE_WAITING,
  FETCH_MESSAGE_TEMPLATE_SUCCESS,
  FETCH_MESSAGE_TEMPLATE_ERROR,
  FETCH_MESSAGE_TEMPLATE_RESET,
} from '../Types';
import ErrorAction from './ErrorAction';
// templates?module_id=13&message_type=private_message&application_id=200
export function fetchMessageTemplates(
  authKey,
  ws_id,
  module_id,
  message_type,
  application_id,
  limit
) {
  const url = `${config.BASE_URL}/advertising/message/templates?ws_id=${ws_id}&module_id=${module_id}&message_type=${message_type}&application_id=${application_id}`;
  return dispatch => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_MESSAGE_TEMPLATE_WAITING });
    axios
      .get(url, { params: { limit } })
      .then(response => {
        dispatch({ type: FETCH_MESSAGE_TEMPLATE_SUCCESS, payload: response });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_MESSAGE_TEMPLATE_ERROR)
          : FETCH_MESSAGE_TEMPLATE_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetFetchMessageTemplates() {
  return { type: FETCH_MESSAGE_TEMPLATE_RESET };
}

export const FETCH_DEFAULT_SETTINGS_WAITING = "fetch_default_settings_waiting";
export const FETCH_DEFAULT_SETTINGS_SUCCESS = "fetch_default_settings_success";
export const FETCH_DEFAULT_SETTINGS_ERROR = "fetch_default_settings_error";
export const FETCH_DEFAULT_SETTINGS_RESET = "fetch_default_settings_reset";

export const ADD_DEFAULT_SETTINGS_WAITING = "add_default_settings_waiting";
export const ADD_DEFAULT_SETTINGS_SUCCESS = "add_default_settings_success";
export const ADD_DEFAULT_SETTINGS_ERROR = "add_default_settings_error";
export const ADD_DEFAULT_SETTINGS_RESET = "add_default_settings_reset";

export const EDIT_DEFAULT_SETTINGS_WAITING = "edit_default_settings_waiting";
export const EDIT_DEFAULT_SETTINGS_SUCCESS = "edit_default_settings_success";
export const EDIT_DEFAULT_SETTINGS_ERROR = "edit_default_settings_error";
export const EDIT_DEFAULT_SETTINGS_RESET = "edit_default_settings_reset";

export const DELETE_DEFAULT_SETTINGS_WAITING =
  "delete_default_settings_waiting";
export const DELETE_DEFAULT_SETTINGS_SUCCESS =
  "delete_default_settings_success";
export const DELETE_DEFAULT_SETTINGS_ERROR = "delete_default_settings_error";
export const DELETE_DEFAULT_SETTINGS_RESET = "delete_default_settings_reset";

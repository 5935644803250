import React, { Component } from 'react';
import { Table, Segment, Header, Icon, Confirm } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import { T, StatusIcon } from '../Common/Helpers';

export default class SpecialOpeningHoursList extends Component {
  state = { openDeleteConfirmation: false, index: null };

  oncancelDeleteConfirmation = () =>
    this.setState({ openDeleteConfirmation: false });
  onConfirmDelete = () => {
    this.props.deleteExistingSpecialOpeningHour(this.state.index);
    this.setState({ openDeleteConfirmation: false });
  };
  openDeleteConfirmation = index =>
    this.setState({ openDeleteConfirmation: true, index });

  renderTableBody = (rows, filteredKey) =>
    _.map(
      rows,
      (row, index) =>
        //console.log('Row: ', row);
        index !== filteredKey && (
          <Table.Row
            key={index}
            className="tablerow"
            onClick={() => this.props.chooseSpecialOpeningToDisplay(index)}
          >
            <Table.Cell>
              <Icon name="calendar alternate" />
            </Table.Cell>
            <Table.Cell> {row.shop_alt_opening_hours_id} </Table.Cell>
            <Table.Cell>
              <StatusIcon status={!row.saoh_closed} />
            </Table.Cell>
            <Table.Cell>
              {moment(row.saoh_valid_from).format('l HH:mm')}
            </Table.Cell>
            <Table.Cell>
              {moment(row.saoh_valid_to).format('l HH:mm')}
            </Table.Cell>
            <Table.Cell
              style={{ cursor: 'default' }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <Icon
                style={{ cursor: 'pointer' }}
                name="trash"
                color="red"
                onClick={() => this.openDeleteConfirmation(index)}
              />
            </Table.Cell>
          </Table.Row>
        )
    );

  render() {
    const filteredKey = this.props.filteredKey;
    //console.log('Props: ', this.props);
    return (
      <Segment>
        <Header> {T('Existing special opening hours')} </Header>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell> {T('ID')} </Table.HeaderCell>
              <Table.HeaderCell>{T('Open')} </Table.HeaderCell>
              <Table.HeaderCell> {T('Valid from')} </Table.HeaderCell>
              <Table.HeaderCell> {T('Valid to')} </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderTableBody(this.props.specialOpenings, filteredKey)}
          </Table.Body>
        </Table>
        <Confirm
          header={T('Delete Special Opening Hours')}
          content={T(
            'Are you sure you want to delete the special opening hours?'
          )}
          confirmButton={T('Delete')}
          cancelButton={T('Cancel')}
          open={this.state.openDeleteConfirmation}
          onCancel={this.oncancelDeleteConfirmation}
          onConfirm={this.onConfirmDelete}
          size="tiny"
        />
      </Segment>
    );
  }
}

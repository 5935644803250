import React, { Component } from "react";
// import { connect } from 'react-redux';
import { map } from "lodash";
import PropTypes from "prop-types";
import { T } from "../Common/Helpers";
//import { Icon } from 'semantic-ui-react';
import ModuleItem from "./ModuleItem";
// import { Spinner } from '../Common/Spinner';
// import {
//   fetchApplicationModules,
//   // setApplicationModules,
//        } from '../../Redux/actions';

// const CONTEXT_ID = 102;

class WizardModules extends Component {
  constructor(props) {
    super(props);
    // this.updateModules = this.updateModules.bind(this);
    // this.updateStatus = this.updateStatus.bind(this);
    this.state = {
      status: null,
    };
  }

  //  componentDidMount() {
  //    const ws_id = this.props.workspace.activeWorkspace.id;
  //    const authKey = localStorage.getItem('x-auth-key');
  //    this.props.fetchApplicationModules(CONTEXT_ID, authKey, this.props.application_id, ws_id);
  //  }
  //
  // componentDidUpdate(prevProps) {
  //   const authKey = localStorage.getItem('x-auth-key');
  //   const ws_id = this.props.workspace.activeWorkspace.id;
  //   // console.log('WMODULES', prevProps, this.props);
  //   // const isStepCompleted = this.props.modules.map(module => module.aam_active === true).some(ele => ele === true);
  //   // const isCompProp = this.props.stepCompleted;
  //   if (prevProps.setStatus !== 'success' && this.props.setStatus === 'success') {
  //     this.props.fetchApplicationModules(CONTEXT_ID, authKey, this.props.application_id, ws_id);
  //   }
  // }

  //  componentDidUpdate(prevProps) {
  //    const authKey = localStorage.getItem('x-auth-key');
  //    // console.log('WMODULES', prevProps, this.props);
  //    if (prevProps.fetchAll !== this.props.fetchAll && this.props.fetchAll !== 'success') {
  //      this.props.fetchApplicationModules(CONTEXT_ID, authKey, this.props.application_id);
  //    }
  //    if (prevProps.setStatus === 'waiting' && this.props.setStatus === 'success') {
  //      this.props.fetchApplicationModules(CONTEXT_ID, authKey, this.props.application_id);
  //    }
  //  }
  //
  //
  // updateModules(id, modules, value) {
  //   const authKey = localStorage.getItem('x-auth-key');
  //   const updatedModules = {
  //     ...modules,
  //     [id]: {
  //       ...modules[id],
  //       aam_active: value,
  //     }
  //   };
  //   // console.log('UPDATE', updatedModules);
  //   this.props.setApplicationModules(authKey, this.props.application_id, updatedModules);
  //   this.props.onChange(true, null, null, null);
  // }
  //
  // updateStatus(activity) {
  //   if (!this.state.status) {
  //     this.props.onChange(true);
  //     this.setState({ status: true });
  //   }
  //   return activity;
  // }

  render() {
    const { modules } = this.props;
    // console.log(modules);
    // if (this.props.fetchAll !== 'success') {
    //   return <Spinner />;
    // }
    // const list = mapKeys(map(modules,
    //   module => ({
    //     appmodule_id: module.appmodule_id,
    //     aam_active: module.aam_active ? this.updateStatus(module.aam_active) : false
    //   })), 'appmodule_id');

    //console.log('MODULES.PROPS', this.props);
    return (
      <div>
        <div style={{ marginBottom: "30px", fontSize: "16px" }}>
          {T("Select modules")}
        </div>
        {/* SEARCH * <div style={{width: '100%'}}>
            <input name="username"
                       type="text"
                       className="loginInput wizardInput"
                       placeholder= {T('Search')}
                       style={{width: '300px', paddingBottom: '10px'}}
               />
               <Icon name='search' size="big" />
               <button style={styles.buttonStyle}>
                {T('SEARCH')}
               </button>
        </div>
        <p style={{fontFamily: 'Nunito', fontWeight: '400px', fontSize: '16px'}} >
              {T('Search')}
        </p>*/}
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginRight: "50px",
          }}
        >
          {map(modules, (module) => (
            <ModuleItem
              key={`module_${module.appmodule_id}`}
              moduleId={module.appmodule_id}
              // onChange={(checked) => this.updateModules(module.appmodule_id, list, checked)}
              onSelectedModuleChange={this.props.onComponentValueUpdate}
              moduleName={T(module.am_name)}
              moduleDescription={T(module.am_description)}
              // checked={module.aam_active}
              // checked={this.props.selectedModules.indexOf(module.appmodule_id) !== -1}
              isChecked={module.aam_active}
              iconName={module.am_image ? module.am_image : "block layout"}
            />
          ))}
        </div>
      </div>
    );
  }
}

WizardModules.propTypes = {
  onComponentValueUpdate: PropTypes.func,
  modules: PropTypes.object,
};

// function mapStateToProps({ applicationModules, workspace }) {
//   return {
//     fetchAll: applicationModules.operationState.fetchAll,
//     modules: applicationModules.modules,
//     setStatus: applicationModules.operationState.set,
//     workspace
//   };
// }
// export default connect(mapStateToProps,
//       { fetchApplicationModules,
//         // setApplicationModules,
//      }
//      )(WizardModules);

export default WizardModules;

import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Segment, Checkbox } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { T, getErrorMessage } from '../Common/Helpers';
import { listCategoryTypes } from './Common';
import { withRouter } from 'react-router-dom';

import {
  addNotificationCategory,
  resetAddNotificationCategory,
  fetchMessageCategoryTypes,
} from '../../Redux/actions';

class AddNotificationCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appSelectStyle: {},
      mc_active_by_default: true,
    };
  }

  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    this.props.fetchMessageCategoryTypes(authKey);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.addStatus !== 'success' &&
      this.props.addStatus === 'success'
    ) {
      this.props.resetAddNotificationCategory();
      // nextProps.history.push(`/app/advertising/list`);
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Category successfully added'),
        time: 5000,
      });
      // console.log('this.props', this.props);
      this.props.history.push('/app/advertising/categories/list');
    } else if (
      prevProps.addStatus !== 'error' &&
      this.props.addStatus === 'error'
    ) {
      const errorObj = getErrorMessage(this.props.error);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetAddNotificationCategory();
    }
  }

  onValidSubmit(formData) {
    const inputValues = {
      ...formData,
      mc_active_by_default: this.state.mc_active_by_default,
    };
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    this.props.addNotificationCategory({
      category: inputValues,
      authKey,
      ws_id,
    });
  }

  getApplicationsList() {
    const apps = this.props.auth.apps;
    return _.map(apps, option => ({
      text: option.ap_name,
      value: option.application_id,
    }));
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Segment style={{ width: '500px' }}>
        <Form
          onValidSubmit={this.onValidSubmit.bind(this)}
          ref={ref => (this.form = ref)}
        >
          <Form.Group>
            <Form.Input
              required
              name="mc_name"
              label={T('Category Name')}
              placeholder={T('Category Name')}
              width={16}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.Select
                required
                placeholder={T('Select Category Type')}
                label={T('Category Type')}
                name="mc_type"
                options={listCategoryTypes(this.props.categoryTypes)}
                onChange={(e, { name, value }) =>
                  this.handleInputChange(name, value)
                }
                value={this.state.mc_type}
                search
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <Checkbox
                toggle
                width={2}
                name="mc_active_by_default"
                label={T('Selected by Default in App')}
                checked={this.state.mc_active_by_default}
                onChange={(e, { name, checked }) =>
                  this.handleInputChange(name, checked)
                }
              />
            </Form.Field>
          </Form.Group>
          <Button content={T('Save')} color="green" />
          <Button
            type="button"
            color="black"
            content={T('Cancel')}
            onClick={() =>
              this.props.history.push('/app/advertising/categories/list')
            }
          />
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({ notifications, auth, workspace }) {
  return {
    addStatus: notifications.operationState.addCat,
    error: notifications.error,
    auth,
    categoryTypes: notifications.categoryTypes,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
  };
}

export default connect(mapStateToProps, {
  addNotificationCategory,
  resetAddNotificationCategory,
  fetchMessageCategoryTypes,
})(withRouter(AddNotificationCategory));

import React, { Component } from 'react';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import {
  //Grid,
  Segment,
  Button,
  Icon,
  Checkbox,
  // Header
} from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import moment from 'moment';
import 'moment/locale/fi';
import _ from 'lodash';
import { toast } from 'react-semantic-toasts';
import { connect } from 'react-redux';
import {
  fetchAllTeams,
  fetchPerformerPlayer,
  transferPlayerToAnotherTeam,
  resetTransferPlayerToAnotherTeam,
  fetchPerformerPlayersList,
  editTransferConnection,
  resetEditTransferConnection,
  fetchPerformerPlayerMetadata,
  fetchPerformerPlayerTransferList,
  fetchPerformerTeamTransferList,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';
import defaultImage from '../../layout/images/images.png';
import PlayerTranferImage from './PlayerTranferImage';
// import UploadPerformerImage from '../UploadPerformerImage';

const errorStyle = {
  backgroundColor: '#FFF6F6',
  border: ' solid 1px #E0B4B4',
  color: '#9F3A38',
  WebkitBoxShadow: 'none',
  boxShadow: 'none',
};
class PlayerTransferControl extends Component {
  constructor(props) {
    super(props);
    const { connection } = props;
    let moveBegins = connection
      ? moment(connection.pc_valid_from).toISOString()
      : moment().toISOString();
    let moveEnds =
      connection && connection.pc_valid_to
        ? moment(connection.pc_valid_to).toISOString()
        : '';
    this.state = {
      moveToTeam: connection ? connection.pc_parent : '',
      moveBegins,
      moveEnds,
      isPermanent: connection ? Boolean(!connection.pc_valid_to) : false,
      moveEndsStyle: '',
      moveAndAccept: false,
      transferConnectionId: '',
    };
    this.onChangeDestinationTeam = this.onChangeDestinationTeam.bind(this);
    this.onChangeMoveBegins = this.onChangeMoveBegins.bind(this);
    this.onChangeMoveEnds = this.onChangeMoveEnds.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.listDestinationTeams = this.listDestinationTeams.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.setTranferConnectionId = this.setTranferConnectionId.bind(this);
  }

  componentDidMount() {
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.fetchAllTeams(authKey, ws_id);
    this.props.fetchPerformerPlayer(authKey, this.props.playerId, ws_id);
  }
  UNSAFE_componentWillUpdate(nextProps) {
    // console.log('TRANSFER : ', nextProps);
    const ws_id = this.props.workspace.activeWorkspace.id;
    if (
      this.props.transferredStatus !== 'success' &&
      nextProps.transferredStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Player Transfer connection successfully updated'),
        time: 5000,
      });
      window.scroll(20, 20);
      // console.log('Next Props: ', nextProps);
      const authKey = localStorage.getItem('x-auth-key');
      this.props.fetchPerformerPlayersList(
        authKey,
        ws_id,
        nextProps.currentTeamId,
        nextProps.headers.current_page
      );
      // this.props.onResetEditAndAddPlayer();
      this.props.fetchPerformerPlayerTransferList(
        nextProps.player.performer_id,
        authKey,
        ws_id
      );
      this.props.fetchPerformerTeamTransferList(
        nextProps.currentTeamId,
        ws_id,
        authKey
      );
      this.props.resetTransferPlayerToAnotherTeam();
      this.setTranferConnectionId(nextProps.transferId);
    } else if (
      this.props.editTransferStatus !== 'success' &&
      nextProps.editTransferStatus === 'success'
    ) {
      window.scroll(20, 20);
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Player Transfer connection successfully updated'),
        time: 5000,
      });
      const activePlayerPage = this.props.playerHeader
        ? this.props.playerHeader.current_page
        : 1;
      const activeTeamPage = this.props.teamHeader
        ? this.props.teamHeader.current_page
        : 1;
      // this.props.onResetEditAndAddPlayer();
      this.props.closemodal();
      this.props.resetEditTransferConnection();
      const authKey = localStorage.getItem('x-auth-key');
      this.props.fetchPerformerPlayersList(
        authKey,
        ws_id,
        nextProps.currentTeamId,
        nextProps.headers.current_page
      );
      this.props.fetchPerformerPlayerTransferList(
        nextProps.player.performer_id,
        authKey,
        ws_id,
        false,
        activePlayerPage
      );
      this.props.fetchPerformerTeamTransferList(
        nextProps.currentTeamId,
        ws_id,
        authKey,
        true,
        activeTeamPage
      );
    } else if (
      this.props.transferredStatus !== 'error' &&
      nextProps.transferredStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        nextProps.error,
        'Error in transferring a player'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetTransferPlayerToAnotherTeam();
    }
  }
  onChangeDestinationTeam(event, { value }) {
    this.setState({ moveToTeam: value });
  }
  onChangeMoveBegins(event, { value }) {
    this.setState({
      moveBegins: moment(value, 'l HH:mm').toISOString(),
      moveEndsStyle: '',
    });
  }
  onChangeMoveEnds(event, { value }) {
    this.setState({
      moveEnds: moment(value, 'l HH:mm').toISOString(),
      moveEndsStyle: '',
    });
  }
  onCancel() {
    //this.props.onResetEditAndAddPlayer();
    this.props.closeModal();
  }
  onToggle() {
    if (this.state.isPermanent) {
      this.setState({
        isPermanent: !this.state.isPermanent,
        moveEnds: this.state.moveBegins,
      });
    } else {
      this.setState({
        isPermanent: !this.state.isPermanent,
        moveEnds: '',
        moveEndsStyle: '',
      });
    }
  }
  getPlayerImage(player) {
    const metadata = player.performer_metadata;
    let imageSrc = '';
    if (metadata && metadata.length > 0) {
      const pictMeta = metadata.filter(meta => meta.pm_key === 'imageurl');
      if (pictMeta.length !== 0) {
        imageSrc = pictMeta[0].pm_value;
      }
    }
    return imageSrc || defaultImage;
  }
  setTranferConnectionId(id) {
    this.setState({ transferConnectionId: id });
  }
  handleFormSubmit(moveAndAccept) {
    let inputValues;
    const authKey = localStorage.getItem('x-auth-key');
    if (!this.state.isPermanent && this.state.moveEnds === '') {
      this.setState({ moveEndsStyle: errorStyle });
      return false;
    }
    if (moveAndAccept) {
      inputValues = { pc_accepted: moment(new Date()).toISOString() };
    }
    inputValues = {
      ...inputValues,
      pc_valid_from: this.state.moveBegins,
      pc_parent: this.state.moveToTeam,
    };
    if (!this.state.isPermanent) {
      if (Date.parse(this.state.moveEnds) < Date.parse(this.state.moveBegins)) {
        this.setState({ moveEndsStyle: errorStyle });
        return false;
      }
      inputValues = { ...inputValues, pc_valid_to: this.state.moveEnds };
    } else {
      inputValues = { ...inputValues, pc_valid_to: null };
    }
    if (
      this.props.connection &&
      this.props.connection.performer_connection_id
    ) {
      const ws_id = this.props.workspace.activeWorkspace.id;
      this.props.editTransferConnection(
        this.props.player.performer_id,
        this.props.connection.performer_connection_id,
        inputValues,
        authKey,
        ws_id
      );
    } else {
      const ws_id = this.props.workspace.activeWorkspace.id;
      this.props.transferPlayerToAnotherTeam(
        this.props.player.performer_id,
        inputValues,
        authKey,
        ws_id
      );
    }
  }
  listDestinationTeams() {
    const destinationTeams = this.props.teams.filter(
      team => team.performer_id !== this.props.currentTeamId
    );
    // console.log('List of Teams: ', destinationTeams);
    return _.map(destinationTeams, team => ({
      text: team.pf_name,
      value: team.performer_id,
    }));
  }
  render() {
    const noBorder = { border: 'none', boxShadow: 'none', textAlign: 'center' };
    if (!this.props.teams || !this.props.player) {
      return <Spinner />;
    }
    if (this.state.transferConnectionId) {
      return (
        <PlayerTranferImage
          {...this.props}
          setTranferConnectionId={this.setTranferConnectionId}
          playerImage={this.getPlayerImage(this.props.player)}
          onResetEditAndAddPlayer={this.props.closemodal}
          performerId={this.props.player.performer_id}
          tranferId={this.state.transferConnectionId}
        />
      );
    }
    return (
      <Segment style={noBorder}>
        <Form size="mini" onValidSubmit={this.handleFormSubmit}>
          <Segment textAlign="left">
            <Form.Field>
              <label htmlFor="selectedContentType">{T('Teams')} </label>
              <Form.Select
                required
                search
                name="pc_parent"
                onChange={this.onChangeDestinationTeam}
                style={{ height: '30px', width: '80%' }}
                options={this.listDestinationTeams()}
                value={
                  this.props.connection
                    ? this.props.connection.pc_parent
                    : this.state.moveToTeam
                }
              />
            </Form.Field>

            <DateTimeInput
              required
              name="pc_valid_from"
              label={T('Starting from')}
              closable
              onChange={this.onChangeMoveBegins}
              value={moment(this.state.moveBegins).format('l HH:mm')}
              dateTimeFormat="l HH:mm"
              minDate={moment(new Date(), 'l HH:mm')}
            />
            <Checkbox
              toggle
              name="isPermanent"
              label={T('Permanent Move')}
              onChange={this.onToggle}
              checked={this.state.isPermanent}
            />
            <DateTimeInput
              required
              name="pc_valid_to"
              label={T('Coming back')}
              closable
              disabled={this.state.isPermanent}
              onChange={this.onChangeMoveEnds}
              value={
                this.state.moveEnds
                  ? moment(this.state.moveEnds).format('l HH:mm')
                  : ''
              }
              dateTimeFormat="l HH:mm"
              minDate={moment(this.state.moveBegins).format('l HH:mm')}
            />
          </Segment>
          <Segment textAlign="center">
            <Button color="green" name="move" type="submit">
              <Icon name="arrows alternate" />
              {T(this.props.connection ? 'Edit Move' : 'Move')}
            </Button>
            {!this.props.connection && (
              <Button
                color="green"
                name="move_and_accept"
                type="button"
                onClick={() => this.handleFormSubmit(true)}
              >
                <Icon name="arrows alternate" />
                {T('Move and accept')}
              </Button>
            )}

            <Button color="black" type="button" onClick={this.onCancel}>
              <Icon name="ban" /> {T('Cancel')}
            </Button>
          </Segment>
        </Form>
      </Segment>
    );
  }
}
function mapStateToProps({ performers, performerPlayers, workspace }) {
  return {
    teams: performers.teams,
    transferredStatus: performerPlayers.transferredStatus,
    editTransferStatus: performerPlayers.editTransferStatus,
    players: performerPlayers.players,
    player: performerPlayers.player,
    headers: performerPlayers.headers,
    transferId: performerPlayers.tranferId,
    playerHeader: performerPlayers.transferHeader,
    teamHeader: performers.teamHeader,
    error: performers.error,
    workspace,
  };
}

export default connect(mapStateToProps, {
  fetchAllTeams,
  fetchPerformerPlayer,
  transferPlayerToAnotherTeam,
  resetTransferPlayerToAnotherTeam,
  fetchPerformerPlayersList,
  editTransferConnection,
  resetEditTransferConnection,
  fetchPerformerPlayerMetadata,
  fetchPerformerPlayerTransferList,
  fetchPerformerTeamTransferList,
})(PlayerTransferControl);

import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import PropTypes from 'prop-types';
import { Button, Confirm } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import config from '../../Config/AEConfig';
//import logoPlaceHolder from '../../layout/images/logo_placeholder.png';
import { T } from '../Common/Helpers';

class ManageLogo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openDeleteConfirmation: false,
      src: null,
      uploadStatus: null,
      crop: {
        unit: '%',
        x: 0,
        y: 0,
        aspect: 1,
        width: 100,
        height: 10,
      },
      imgContainerWidth: '200',
      imgContainerHeight: '200',
    };
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  onSelectFile = files => {
    if (files && files.length > 0) {
      // const crop = { x: 0, y: 0, width: 90, height: 90 };
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({ src: reader.result });
      });
      const img = new Image();
      img.onload = () => {
        // console.log("Img Dimensions: ", this.height, this.width);
        this.setState({
          imgContainerWidth: this.width,
          imgContainerHeight: this.height,
        });
      };
      this.imageFileName = files[0].name;
      img.src = window.URL.createObjectURL(files[0]);
      reader.readAsDataURL(files[0]);
    }
  };

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
    // Make the library regenerate aspect crops if loading new images.
    const { crop } = this.state;
    if (crop.aspect && crop.height && crop.width) {
      this.setState({
        crop: { ...crop, height: null },
      });
    } else {
      this.makeClientCrop(crop, pixelCrop);
    }
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }
  onConfirmDelete() {
    const authKey = localStorage.getItem('x-auth-key');
    const url = this.props.image;
    this.props.onDeleteImage(authKey, this.props.imageId, url);
    this.setState({ openDeleteConfirmation: false });
  }

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = fileName;
        blob.lastModifiedDate = new Date();
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({ url: this.fileUrl, blob });
        reject('Error');
      }, 'image/png');
    });
  }

  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const { url, blob } = await this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        'newFile.png'
      );
      this.setState({ croppedImageUrl: url, blob });
    }
  }

  uploadImage = blob => {
    const authKey = localStorage.getItem('x-auth-key');
    const inputValues = { id: this.props.imageId, target: 'company' };
    const inputForm = new FormData();
    const file = new File([blob], this.imageFileName);
    // console.log('Image file name: ', this.imageFileName);
    inputForm.append('file', file);
    Object.keys(inputValues).forEach(key =>
      inputForm.append(key, inputValues[key])
    );
    this.props.uploadImage(authKey, inputForm);
    this.setState({
      src: null,
      croppedImageUrl: null,
      uploadStatus: 'waiting',
    });
  };

  isValidURL(string) {
    // const validUrl = String.raw`(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)`;
    // const res = string.match(`/${validUrl}/g`);
    const res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    );
    if (res) {
      return true;
    }
    return false;
  }

  imageExists(image_url) {
    const http = new XMLHttpRequest();
    http.open('HEAD', image_url, false);
    http.send();
    return http.status !== 404;
  }
  deleteImage() {
    this.setState({ openDeleteConfirmation: true });
  }

  render() {
    const { croppedImageUrl, blob } = this.state;
    //console.log(this.props.src);
    return (
      <div>
        <div
          style={{
            fontFamily: 'sans-serif',
            textAlign: 'left',
            display: 'inline-block',
            minHeight: '200px',
          }}
        >
          {!this.state.src && (
            <img
              style={{
                //width: '200px',
                //height: '200px',
                border: '1px dashed #CBA84A',
              }}
              src={
                this.isValidURL(this.props.image)
                  ? this.props.image
                  : `${config.IMAGE_BASE_URL}images.png`
              }
              alt="logo"
            />
          )}
          {this.state.src && (
            <ReactCrop
              style={{
                border: '1px dashed #707070',
                display: 'inline-block',
                // width: '200px',
                // height: '200px'
              }}
              src={this.state.src}
              crop={this.state.crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              keepSelection
            />
          )}
          {this.state.src && !croppedImageUrl ? (
            <p
              style={{
                display: 'inline-block',
                backgroundColor: '#CBA84A',
                color: 'white',
              }}
            >
              {T('click on the logo to crop')}
            </p>
          ) : (
            ''
          )}
          {croppedImageUrl && (
            <img
              style={{
                //width: '175px',
                //height: '200px',
                maxHeight: '250px',
                border: '1px dashed #CBA84A',
                display: 'inline-flex',
                marginLeft: '10px',
              }}
              alt="Crop"
              src={croppedImageUrl}
            />
          )}

          <div>
            <Dropzone
              multiple={false}
              accept="image/png"
              style={{ marginBottom: '20px' }}
              onDrop={this.onSelectFile.bind(this)}
              // style={{ display: 'flex', justifyContent: 'center' }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  style={{
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    justifyContent: 'center',
                    // alignItems: 'center'
                  }}
                >
                  <input {...getInputProps()} />
                  <Button
                    style={{
                      padding: '5px',
                      backgroundColor: '#CBA84A',
                      borderRadius: '0px',
                      width: '150px',
                    }}
                    content={T('Select Image')}
                    primary
                    onClick={e => e.preventDefault()}
                  />
                  <p
                    style={{
                      textDecoration: 'underline',
                      display: 'inline-block',
                      width: '300px',
                      overflow: 'ellipsis',
                    }}
                  >
                    {this.imageFileName || ''}
                  </p>
                  {/** bugfix in the package dropzone otherwise
                         ** use this otherwise
                         <Label size='large' color='blue' width={6} style={{ fontSize: 16, letterSpacing: 3 }}>
                           <Icon name='upload' />{T('Select image')}
                         </Label>  png, jpg, jpeg
                        */}
                </div>
              )}
            </Dropzone>
            {/*<input
                       className="wizardInput" style={{ width: '150px', overflow: 'ellipsis', marginBottom: '10px' }}
                       type="file" accept="image/png" onChange={this.onSelectFile.bind(this)}
                />*/}
          </div>
        </div>
        <div style={{ display: 'block', marginTop: '20px' }}>
          {croppedImageUrl && (
            <button
              style={{
                display: 'inline-block',
                backgroundColor: '#CBA84A',
                width: '138px',
                height: '32px',
                color: 'white',
                fontFamily: 'Nunito',
                fontWeight: '600',
                fontSize: '12px',
                lineHeight: '32px',
                verticalAlign: 'middle',
                letterSpacing: '2.5px',
                marginRight: '5px',
              }}
              onClick={() => this.uploadImage(blob)}
            >
              {T('UPLOAD LOGO')}
            </button>
          )}
          {!this.props.hideCancelButton && (
            <Button
              type="button"
              color="black"
              content={T('Cancel')}
              onClick={() => this.onCancel()}
            />
          )}
          {this.props.showDeleteButton === true && (
            <Button
              type="button"
              color="red"
              content={T('Delete')}
              onClick={this.deleteImage}
            />
          )}
          <Confirm
            header={T('Delete Image')}
            content={T('Are you sure you want to delete the image?')}
            confirmButton={T('Delete')}
            cancelButton={T('Cancel')}
            open={this.state.openDeleteConfirmation}
            onCancel={this.oncancelDeleteConfirmation}
            onConfirm={this.onConfirmDelete}
            size="tiny"
          />
        </div>
      </div>
    );
  }
}

ManageLogo.propTypes = {
  hideCancelButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  onDeleteImage: PropTypes.func.isRequired,
  imageId: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  uploadImage: PropTypes.func.isRequired,
};

export default ManageLogo;

import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withRouter } from "react-router";
import { Message } from "semantic-ui-react";
import { toast } from "react-semantic-toasts";
import { slide as Menu } from "react-burger-menu";
import AppName from "./AppName";
import { T, getErrorMessage } from "../Common/Helpers";
import ImageCrop from "./ImageCrop";
import StickyMenu from "./StickyMenu";
import MenuItemBlock from "./MenuItemBlock";
// import InfoBar from './InfoBar';
import ColorPicker from "./ColorPicker";
import { Spinner } from "../Common/Spinner";
import WizardTemplateContainer from "./WizardTemplateContainer";
import WizardModules from "./WizardModules";
import AppQRCode from "./AppQRCode";
//import PremiumPackageInfo from './PremiumPackageInfo';
import logoPlaceHolder from "../../layout/images/logo_placeholder.png";
import "../../Style/Wizard.css";
import {
  setApplicationModules,
  modifyConfig,
  modifyConfigLogo,
  editApplication,
  fetchApplications,
  fetchAppConfig,
  resetAppConfig,
  logoutUser,
  fetchApplication,
  fetchApplicationModules,
} from "../../Redux/actions";
import config from "../../Config/AEConfig";

const CONTEXT_ID = 102;

class Wizard extends Component {
  constructor(props) {
    super(props);
    // this.state = { ap_name: '',
    //           topNavLogo: '',
    //           brandColor1: '',
    //           brandColor2: '',
    //           brandColor3: '',
    //           modules: [],
    //           ap_template: ''
    //           };
    this.state = {
      connected: false, // is websocket connected to the update channel
      // completedSections: [],
      menuOpen: false,
      moduleSelected: true,
    };
  }

  componentDidMount() {
    const { ap_key } = this.props.match.params;
    if (ap_key) {
      const { ws_id } = this.props.workspace;
      const authKey = localStorage.getItem("x-auth-key");
      this.props.fetchApplication({ authKey, ap_key, ws_id });
      this.initWebSocket(ap_key);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const authKey = localStorage.getItem("x-auth-key");
    const ws_id = this.props.workspace.activeWorkspace.id;
    if (
      this.props.fetchModules !== "success" &&
      nextProps.fetchModules === "success"
    ) {
      const isStepCompleted = _.map(
        nextProps.modules,
        (module) => module.aam_active === true
      ).some((ele) => ele === true);
      // console.log('Modules: ', nextProps.modules, '\n Is Completed: ', isStepCompleted);

      if (nextState.moduleSelected !== isStepCompleted) {
        this.setState({ moduleSelected: isStepCompleted });
      }
    }
    if (
      this.props.fetchAppStatus !== "success" &&
      nextProps.fetchAppStatus === "success"
    ) {
      const { application_id } = nextProps.application;
      this.props.fetchApplicationModules(
        CONTEXT_ID,
        authKey,
        application_id,
        ws_id
      );
      this.props.fetchAppConfig({ authKey, application_id, ws_id });
    }
    if (
      this.props.appConfig.modifyAppConfigStatus !== "success" &&
      nextProps.appConfig.modifyAppConfigStatus === "success"
    ) {
      toast({
        type: "success",
        title: T("Success"),
        description: T("App modified successfully"),
        time: 5000,
      });
    } else if (
      this.props.appConfig.modifyAppConfigStatus !== "error" &&
      nextProps.appConfig.modifyAppConfigStatus === "error"
    ) {
      const errorObj = getErrorMessage(
        nextProps.appConfig.error,
        "Failed to update the app"
      );
      toast({
        type: "error",
        animation: "scale",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.webSocketTimer);
  }

  onWizardSectionUpdated = (keyword, value) => {
    const authKey = localStorage.getItem("x-auth-key");
    const { ws_id } = this.props.workspace;
    const { application_id } = this.props.application;
    switch (keyword) {
      case "ap_name":
      case "brandColor1":
      case "brandColor2":
      case "brandColor3":
      case "ap_template":
        this.props.modifyConfig({
          authKey,
          keyword,
          value,
          application_id,
          ws_id,
          callback: () => {
            this.sendWebsocketUpdateRequest();
            // this.setState({ completedSections: [...this.state.completedSections, keyword] });
          },
        });
        break;
      case "modules":
        this.props.setApplicationModules(
          authKey,
          application_id,
          value,
          ws_id,
          () => {
            this.sendWebsocketUpdateRequest();
            if (this.state.moduleSelected === false) {
              // all were Unchecked and now at least one checked
              this.setState({ moduleSelected: true });
            } else if (
              this.state.moduleSelected === true &&
              value[0].aam_active === false
            ) {
              //condition if all modules are unchecked
              const mappedModules = _.map(
                this.props.modules,
                (module, key) =>
                  parseInt(key, 10) !== value[0].appmodule_id &&
                  module.aam_active === true
              );
              const isStepCompleted = mappedModules.some((ele) => ele === true); //if all modules other than the current one are unchecked
              if (isStepCompleted !== this.state.moduleSelected) {
                this.setState({ moduleSelected: false });
              }
            }
          }
        );

        break;
      default:
        console.log("unknown keyword, ", keyword);
    }
  };
  setMenuItemIconProps(stepCompleted) {
    if (stepCompleted) {
      return { color: "green", iconName: "check", disabled: false };
    }
    return {
      color: "grey",
      iconName: "question circle outline",
      disabled: true,
    };
  }
  getConfigValueFromRedux = (keyword) => {
    // console.log('Keyword: ', keyword, '\nValues: ', this.props.appConfig);
    const { appConfig } = this.props.appConfig;
    if (appConfig && appConfig[keyword]) {
      return appConfig[keyword].ac_value;
    }
    return null;
  };
  getApplicationName = (keyword) => {
    const { application } = this.props.applications;
    if (application) {
      return application[keyword];
    }
    return null;
  };

  handleStateChange = (state) => {
    this.setState({ menuOpen: state.isOpen });
  };

  handleComponentValueChange = (updatedObj) => {
    this.setState({ ...updatedObj });
  };
  hideBurgerMenu = () => {
    this.setState({ menuOpen: false });
  };
  goToSectionLink(e, id) {
    e.preventDefault();
    const element = document.querySelector(`#${id}`);
    // const wrapper = document.querySelector('#page-wrap');
    if (element) {
      // const headerOffset = 45;
      // const elementPosition = element.getBoundingClientRect().top;
      // const offsetPosition = elementPosition - headerOffset;
      // element.scrollTop = 45;
      // window.scrollTo({
      //     top: offsetPosition,
      //     behavior: 'smooth'
      // });
      element.scrollIntoView({ block: "start", behavior: "smooth" });
      // const count = element.offsetTop - wrapper.scrollTop - 70; // xx = any extra distance from top ex. 60
      // wrapper.scrollBy(10, count, { top: count, left: 0, behavior: 'smooth' });
      // window.scrollTo(0, 100);
      // element.scrollIntoView(true);
      // window.scroll(0, 68);
      // element.scrollTo(0, 70);
      this.hideBurgerMenu();
    } else {
      console.log(`Element with Id: ${id} not found`);
    }
  }
  initWebSocket(updateChannelName) {
    this.ws = new WebSocket(`${config.WS_BASE_URL}mobileapi/updates`);

    this.ws.onopen = () => {
      console.log("Update Channel WebSocket connection opened");
      this.setState({ connected: true });
      this.ws.send(JSON.stringify({ action: "join", updateChannelName }));
    };

    this.ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      console.log("WebSocket Message: ", data);
    };

    this.ws.onerror = (e) => {
      console.log("Error from WebSocket: ", e.message);
    };

    this.ws.onclose = (e) => {
      console.log("websocket closing");
      console.log(e.code, e.reason);
      if (!this.unmounting) {
        this.setState({ connected: false });
        this.webSocketTimer = setTimeout(() => {
          this.initWebSocket();
        }, 2000);
      }
    };
  }

  sendWebsocketUpdateRequest = () => {
    // const { ap_key } = this.getApplication();
    const { ap_key } = this.props.match.params;
    //Todo: remove this ugly fix and do it properly
    if (this.ws) {
      this.ws.send(
        JSON.stringify({
          action: "message",
          updateChannelName: ap_key,
          message: "p1:full_update",
        })
      );
    }
  };

  mapDataFromReduxByKeyword(dataKeys) {
    let mapped;
    _.map(
      dataKeys,
      (data) =>
        (mapped = { ...mapped, [data]: this.props.appConfig.appConfig[data] })
    );
    return _.mapKeys(mapped, "ac_keyword");
  }

  updateAppLogo = (formData, authKey) => {
    const { ws_id } = this.props.workspace;
    this.props.modifyConfigLogo(
      formData,
      authKey,
      this.props.application.application_id,
      this.props.modifyConfig,
      ws_id,
      () => {
        this.sendWebsocketUpdateRequest();
        // this.setState({ completedSections: [...this.state.completedSections, 'topNavLogo'] });
      }
    );
  };
  render() {
    // console.log('New Wizard props: ', this.props);
    const { ap_key } = this.props.match.params;
    if (!ap_key) {
      return (
        <Message
          negative
          icon="exclamation triangle"
          header={T("Error")}
          content={T("The workspace has no application")}
        />
      );
    } else if (this.props.fetchAppStatus === "error") {
      const errorObj = getErrorMessage(this.props.applications.error);
      return (
        <Message
          negative
          icon="exclamation triangle"
          header={T("Error")}
          content={`${T("Status")}: ${errorObj.status}, ${T("Message")}: ${T(
            errorObj.message
          )}`}
        />
      );
    }
    if (
      this.props.appConfig.fetchStatus !== "success" ||
      this.props.fetchAppStatus !== "success" ||
      this.props.fetchModules !== "success"
    ) {
      return <Spinner />;
    }
    // console.log('Wizard State: ', this.state, '\nModules: ', this.props.modules);
    // console.log('APPlication: ', this.props.application);
    const { ap_default_dir } = this.props.application;
    // const { completedSections } = this.state;
    return (
      <div id="outer-container" className="page-container">
        <Menu
          isOpen={this.state.menuOpen}
          left
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
          onStateChange={(state) => this.handleStateChange(state)}
        >
          <StickyMenu>
            <MenuItemBlock
              iconProps={this.setMenuItemIconProps(
                this.getApplicationName("ap_name") !== null
              )}
              labelText="App Name"
              id="step-name-1"
              linkId="step-1"
              containerId="page-wrap"
              onClick={(e) => this.goToSectionLink(e, "step-1")}
            />
            <MenuItemBlock
              iconProps={this.setMenuItemIconProps(
                this.getConfigValueFromRedux("topNavLogo") !== null
              )}
              labelText="App Logo"
              linkId="step-2"
              id="step-name-2"
              containerId="page-wrap"
              onClick={(e) => this.goToSectionLink(e, "step-2")}
            />
            <MenuItemBlock
              iconProps={this.setMenuItemIconProps(
                this.getConfigValueFromRedux("brandColor1") !== null &&
                  this.getConfigValueFromRedux("brandColor2") !== null &&
                  this.getConfigValueFromRedux("brandColor3") !== null
              )}
              labelText="Color Picker"
              linkId="step-3"
              id="step-name-3"
              containerId="page-wrap"
              onClick={(e) => this.goToSectionLink(e, "step-3")}
            />
            <MenuItemBlock
              iconProps={this.setMenuItemIconProps(
                this.getConfigValueFromRedux("ap_template") !== null
              )}
              labelText="Templates"
              linkId="step-4"
              id="step-name-4"
              containerId="page-wrap"
              onClick={(e) => this.goToSectionLink(e, "step-4")}
            />
            <MenuItemBlock
              iconProps={this.setMenuItemIconProps(this.state.moduleSelected)}
              labelText="Modules"
              linkId="step-5"
              id="step-name-5"
              containerId="page-wrap"
              onClick={(e) => this.goToSectionLink(e, "step-5")}
            />
            <MenuItemBlock
              iconProps={this.setMenuItemIconProps(true)}
              labelText="View App"
              linkId="step-6"
              id="step-name-6"
              containerId="page-wrap"
              onClick={(e) => this.goToSectionLink(e, "step-6")}
            />
          </StickyMenu>
        </Menu>
        <div id="page-wrap" className="main">
          {/*<InfoBar />*/}
          <div id="step-1">
            <div style={{ marginTop: "50px", marginBottom: "50px" }}>
              <AppName
                appName={this.getApplicationName("ap_name")}
                onComponentValueUpdate={this.onWizardSectionUpdated}
              />
            </div>
          </div>
          <div id="step-2">
            <div style={{ marginBottom: "50px" }}>
              <p
                style={{
                  fontFamily: "Nunito",
                  fontWeight: "400px",
                  fontSize: "16px",
                  padding: "20px 0px",
                }}
              >
                {T("App Logo")}
              </p>
              <ImageCrop
                logoPlaceHolder={logoPlaceHolder}
                src={this.getConfigValueFromRedux("topNavLogo")}
                onLogoUploaded={this.updateAppLogo}
                applicationId={this.props.application.application_id}
                status={this.props.appConfig.modifyLogoStatus}
              />
            </div>
          </div>
          <div id="step-3">
            <div style={{ marginBottom: "50px" }}>
              <p
                style={{
                  fontFamily: "Nunito",
                  fontWeight: "400px",
                  fontSize: "16px",
                  padding: "20px 0px",
                }}
              >
                {T("App Color")}
              </p>
              <ColorPicker
                brandColor1={this.getConfigValueFromRedux("brandColor1")}
                brandColor2={this.getConfigValueFromRedux("brandColor2")}
                brandColor3={this.getConfigValueFromRedux("brandColor3")}
                // {... mappedColor}
                onComponentValueUpdate={this.onWizardSectionUpdated}
              />
            </div>
          </div>
          <div id="step-4">
            <div style={{ marginBottom: "50px" }}>
              <WizardTemplateContainer
                apTemplate={this.getConfigValueFromRedux("ap_template")}
                onComponentValueUpdate={this.onWizardSectionUpdated}
              />
            </div>
          </div>
          <div id="step-5">
            <WizardModules
              modules={this.props.modules}
              onComponentValueUpdate={this.onWizardSectionUpdated}
              // selectedModules={this.state.modules}
            />
          </div>
          <div id="step-6">
            <AppQRCode value={`prometheusone://${ap_key}/${ap_default_dir}`} />
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps({
  appConfig,
  applications,
  applicationModules,
  workspace,
}) {
  return {
    appConfig,
    applications,
    fetchModules: applicationModules.operationState.fetchAll,
    modules: applicationModules.modules,
    fetchAppStatus: applications.operationState.fetchApp,
    application: applications.application,
    workspace,
  };
}
export default connect(mapStateToProps, {
  // ,
  setApplicationModules,
  modifyConfig,
  modifyConfigLogo,
  editApplication,
  fetchApplications,
  fetchAppConfig,
  resetAppConfig,
  logoutUser,
  fetchApplication,
  fetchApplicationModules,
})(withRouter(Wizard));

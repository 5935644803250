import React, { Component } from 'react';
import {
  Card,
  Segment,
  Image,
  Pagination,
  Icon,
  Loader,
} from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  resetFetchPerformerPlayersList,
  fetchPerformerPlayersList,
  resetFetchPerformerPlayers,
} from '../../Redux/actions';
import PlaceHolderImage from '../../layout/images/images.png';
import { T } from '../Common/Helpers';
import addPlayerImg from '../../layout/images/addPlayer.png';

class PlayersList extends Component {
  constructor(props) {
    super(props);
    this.renderPlayersCard = this.renderPlayersCard.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }

  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const performerId = this.props.performerId;
    const ws_id = this.props.workspace.activeWorkspace.id;
    this.props.resetFetchPerformerPlayers();
    this.props.resetFetchPerformerPlayersList();
    this.props.fetchPerformerPlayersList(
      authKey,
      ws_id,
      performerId,
      1,
      null,
      'pf_first_name',
      'asc'
    );
  }

  componentWillUnmount() {
    this.props.resetFetchPerformerPlayers();
  }

  getPlayerImage(player) {
    const metadata = player.performer_metadata;
    let imageSrc = '';
    if (metadata && metadata.length > 0) {
      const pictMeta = metadata.filter(
        meta => meta.pm_key === 'imageurl' || meta.pm_key === 'imageurlSmall'
      );
      if (pictMeta.length !== 0) {
        imageSrc = pictMeta[0].pm_value;
      }
    }
    return imageSrc || PlaceHolderImage;
  }

  handlePaginationChange(e, { activePage }) {
    const performerId = this.props.performerId;
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.onResetEditAndAddPlayer();
    this.props.fetchPerformerPlayersList(
      authKey,
      ws_id,
      performerId,
      activePage,
      this.props.headers.searchterm,
      'pf_first_name',
      'asc'
    );
  }

  renderPlayersCard() {
    const players = _.omit(this.props.players, [
      'createStatus',
      'selectStatus',
      'updateStatus',
      'deleteStatus',
    ]);
    return _.map(players, player => (
      <Card
        key={player.performer_id}
        link
        raised
        onClick={() => {
          this.props.onChangeActivePlayer(player.performer_id);
        }}
      >
        <Image
          wrapped
          className="player-image"
          src={this.getPlayerImage(player)}
        />
        <Card.Content>
          <Card.Header>{player.pf_name}</Card.Header>
        </Card.Content>
      </Card>
    ));
  }

  render() {
    const { players, selectStatus } = this.props;
    if (!players || selectStatus === 'waiting') {
      return <Loader inverted active inline="centered" />;
    }
    return (
      <Segment fluid>
        <Card.Group itemsPerRow="8" stackable>
          {this.renderPlayersCard()}
          <Card
            key="add-player"
            color="blue"
            link
            raised
            onClick={() => this.props.onChangeAddPlayer()}
          >
            <Image wrapped className="player-image" src={addPlayerImg} />
            <Card.Content textAlign="center">
              <Card.Header>{T('Add Player')}</Card.Header>
            </Card.Content>
          </Card>
        </Card.Group>
        {this.props.headers && this.props.headers.total_pages > 1 && (
          <Pagination
            onPageChange={this.handlePaginationChange}
            defaultActivePage={
              this.props.headers ? this.props.headers.current_page : 1
            }
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={this.props.headers ? this.props.headers.total_pages : 1}
          />
        )}
      </Segment>
    );
  }
}

function mapStateToProps({ performerPlayers, workspace }) {
  return {
    players: performerPlayers.players,
    headers: performerPlayers.headers,
    selectStatus: performerPlayers.selectStatus,
    workspace,
  };
}

export default connect(mapStateToProps, {
  resetFetchPerformerPlayersList,
  fetchPerformerPlayersList,
  resetFetchPerformerPlayers,
})(PlayersList);

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Nav from "../layout/Nav";
import VenueList from "../components/Venues/VenueList";
import VenueEditInput from "../components/Venues/VenueEditInput";
import VenueAdd from "../components/Venues/VenueAdd";
import LocationManager from "../components/Venues/Locations/LocationManager";

const VenueRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: "150px" }}>
      <Nav />
      <Switch>
        <Route path={`${path}/list`} exact>
          <VenueList />
        </Route>
        <Route path={`${path}/add`} exact>
          <VenueAdd />
        </Route>
        <Route path={`${path}/:venueId/edit`} exact>
          <VenueEditInput />
        </Route>
        <Route path={`${path}/locations/add`} exact>
          <LocationManager />
        </Route>
      </Switch>
    </Container>
  );
};

export default VenueRoute;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import { Button, Icon } from "semantic-ui-react";
import Dropzone from "react-dropzone";
// import config from '../../Config/AEConfig';
//import logoPlaceHolder from '../../layout/images/logo_placeholder.png';
import {} from "../../Redux/actions";
import { T } from "../Common/Helpers";

class ImageCrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      crop: {
        unit: "%",
        x: 0,
        y: 0,
        width: 50,
        aspect: 1,
      },
      imgContainerWidth: "250",
      imgContainerHeight: "250",
      testClicked: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.setState({ croppedImageUrl: false, src: null });
    }
  }

  onSelectFile = (files) => {
    if (files && files.length > 0) {
      const crop = { x: 0, y: 0, width: 90, height: 90 };
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ src: reader.result, crop });
      });
      const img = new Image();
      // img.onload = () => {
      //     // console.log("Img Dimensions: ", this.height, this.width);
      //     this.setState({
      //       imgContainerWidth: this.width,
      //       imgContainerHeight: this.height
      //     });
      // };
      this.imageFileName = files[0].name;
      img.src = window.URL.createObjectURL(files[0]);
      reader.readAsDataURL(files[0]);
    }
  };

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
    // Make the library regenerate aspect crops if loading new images.
    const { crop } = this.state;
    if (crop.aspect && crop.height && crop.width) {
      this.setState({
        crop: { ...crop, height: null },
      });
    } else {
      this.makeClientCrop(crop, pixelCrop);
    }
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL("image/jpeg");
    // return base64Image;

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          blob.name = fileName;
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  }

  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const { url, blob } = await this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        "newFile.png"
      );
      this.setState({ croppedImageUrl: url, blob });
      // this.props.onLogoUploaded({ topNavLogo: true });
    }
  }

  uploadImage = (blob) => {
    // console.log('Upload image button clicked');
    const authKey = localStorage.getItem("x-auth-key");
    //console.log("Id: ", performerId, "\nImage: ", imageUrl, "\nBlob: ", blob);
    const inputValues = {
      target: "application",
      id: this.props.applicationId,
    };

    const inputForm = new FormData();
    Object.keys(inputValues).forEach((key) => {
      inputForm.append(key, inputValues[key]);
    });
    const file = new File([blob], this.imageFileName);
    // inputForm.append('file', file);
    inputForm.append("file", file);
    this.props.onLogoUploaded(inputForm, authKey);
  };

  isValidURL(string) {
    // const validFormat = String.raw`(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)`;
    // const res = string.match(`/${validFormat}/g`);
    // console.log('Valid Url: ', res);
    const res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    );
    if (res) {
      return true;
    }
    return false;
  }

  imageExists(image_url) {
    const http = new XMLHttpRequest();
    http.open("HEAD", image_url, false);
    http.send();
    return http.status !== 404;
  }

  render() {
    // console.log('Image upload states: ', this.state);
    if (this.props.status === "waiting") {
      return (
        <Icon
          name="circle notched"
          size="big"
          loading
          style={{ backgroundColor: "transparent" }}
        />
      );
    }
    const imageUrl = this.props.src || this.props.logoPlaceHolder;
    const { croppedImageUrl, blob } = this.state;
    //console.log(this.props.src);
    return (
      <div
        style={{ display: "flex", flexDirection: "row", marginBottom: "50px" }}
      >
        <div
          style={{
            fontFamily: "sans-serif",
            textAlign: "left",
            display: "inline-block",
            minHeight: "200px",
          }}
        >
          {!this.state.src && (
            <img
              style={{
                //width: '150px',
                height: "150px",
                // maxWidth: '300px',
                // maxHeight: '300px',
                border: "1px solid #CBA84A",
              }}
              // src={this.isValidURL(this.props.src) ? this.props.src : `${config.IMAGE_BASE_URL}${this.props.src}`}
              src={imageUrl}
              alt="logo"
            />
          )}
          {this.state.src && (
            <ReactCrop
              src={this.state.src}
              crop={this.state.crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          )}
          {this.state.src && !croppedImageUrl ? (
            <p
              style={{
                display: "inline-block",
                backgroundColor: "#CBA84A",
                color: "white",
              }}
            >
              {" "}
              {T("click on the logo to crop")}{" "}
            </p>
          ) : (
            ""
          )}
          {croppedImageUrl && (
            <img
              style={{
                //width: '150px',
                //height: '200px',
                // maxWidth: '250px',
                width: "250px",
                aspectRatio: 1,
                border: "1px solid #CBA84A",
                display: "inline-flex",
                marginLeft: "10px",
              }}
              alt="Crop"
              src={croppedImageUrl}
            />
          )}
          <Dropzone
            multiple={false}
            accept="image/png"
            style={{ marginBottom: "20px" }}
            onDrop={this.onSelectFile.bind(this)}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                  style={{
                    backgroundColor: "#CBA84A",
                    borderRadius: "0px",
                    width: "200px",
                  }}
                  content={T("SELECT LOGO")}
                  primary
                />
                <p
                  style={{
                    display: "inline-block",
                    width: "300px",
                    overflow: "ellipsis",
                  }}
                >
                  {this.imageFileName || ""}{" "}
                </p>
                {/** bugfix in the package dropzone otherwise
                   ** use this otherwise
                   <Label size='large' color='blue' width={6} style={{ fontSize: 16, letterSpacing: 3 }}>
                     <Icon name='upload' />{T('Select image')}
                   </Label>  png, jpg, jpeg
                  */}
              </div>
            )}
          </Dropzone>
          {/*<input
                   className="wizardInput" style={{ width: '200px', overflow: 'ellipsis' }}
                   type="file" accept="image/png" onChange={this.onSelectFile.bind(this)}
            />*/}
        </div>
        <div>
          {croppedImageUrl && (
            <Button
              icon="upload"
              style={{
                display: "block",
                marginLeft: "20px",
                marginTop: "100px",
                backgroundColor: "#CBA84A",
                borderRadius: "0px",
                // width: '138px',
                // height: '32px',
                width: "200px",
                color: "white",
                // fontFamily: 'Nunito',
                // fontWeight: '600',
                // fontSize: '12px',
                // lineHeight: '32px',
                // verticalAlign: 'middle',
                // letterSpacing: '2.5px'
              }}
              onClick={() => this.uploadImage(blob)}
              content={T("UPLOAD")}
            />
          )}
        </div>
      </div>
    );
  }
}

ImageCrop.propTypes = {
  logoPlaceHolder: PropTypes.string,
  src: PropTypes.string,
  onLogoUploaded: PropTypes.func,
  applicationId: PropTypes.number,
};

function mapStateToProps({ appConfig }) {
  return { appConfig };
}

export default connect(mapStateToProps, {})(ImageCrop);

/* eslint-env browser */
import axios from "axios";
import config from "../../Config/AEConfig";
import ErrorAction from "./ErrorAction";

import {
  FETCH_VENUES_WAITING,
  FETCH_VENUES_SUCCESS,
  FETCH_VENUES_ERROR,
  FETCH_VENUES_RESET,

  // FETCH_SUB_VENUES_WAITING,
  // FETCH_SUB_VENUES_SUCCESS,
  // FETCH_SUB_VENUES_ERROR,
  // FETCH_SUB_VENUES_RESET,
  FETCH_VENUE_WAITING,
  FETCH_VENUE_SUCCESS,
  FETCH_VENUE_ERROR,
  FETCH_VENUE_RESET,
  EDIT_VENUE_WAITING,
  EDIT_VENUE_SUCCESS,
  EDIT_VENUE_ERROR,
  EDIT_VENUE_RESET,
  ADD_VENUE_WAITING,
  ADD_VENUE_SUCCESS,
  ADD_VENUE_ERROR,
  ADD_VENUE_RESET,
  DELETE_VENUE_WAITING,
  DELETE_VENUE_SUCCESS,
  DELETE_VENUE_ERROR,
  DELETE_VENUE_RESET,

  // FETCH_SUB_VENUE_WAITING,
  // FETCH_SUB_VENUE_SUCCESS,
  // FETCH_SUB_VENUE_ERROR,
  // FETCH_SUB_VENUE_RESET,
} from "../Types";

// import ErrorAction from './ErrorAction';
const MODULE_ID = 9;
//const ADD_MODULE_ID = 29;
export const fetchVenues =
  (authKey, ws_id, paging, start, searchterm, sort, sortDirection) =>
  (dispatch) => {
    axios.defaults.headers.common["x-auth-key"] = authKey;
    let url = `${config.BASE_URL}/places?ws_id=${ws_id}`;
    if (paging) {
      const page = start || 1;
      url += `&paging=true&start=${page}`;
    } else {
      url += "&paging=false";
    }
    if (searchterm) {
      url += `&searchterm=${searchterm}`;
    }
    if (sort && sortDirection) {
      url += `&sort=${sort}:${sortDirection}`;
    } else if (sort) {
      url += `&sort=${sort}`;
    }
    url += `&module_id=${MODULE_ID}`;
    dispatch({ type: FETCH_VENUES_WAITING });
    axios
      .get(url)
      .then((response) =>
        dispatch({ type: FETCH_VENUES_SUCCESS, payload: response })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_VENUES_ERROR)
          : FETCH_VENUES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetFetchVenues = () => ({ type: FETCH_VENUES_RESET });

// export const fetchSubVenues = (id, authKey, paging, start, searchterm, sort, asc) => (dispatch) => {
//            axios.defaults.headers.common['x-auth-key'] = authKey;
//            if (!id) {
//              dispatch({ type: FETCH_SUB_VENUES_ERROR, payload: {} });
//              return false;
//            }
//            let url = `${config.BASE_URL}/places?place_id=${id}`;
//            if (paging) {
//              const page = start || 1;
//              url += `&paging=true&start=${page}`;
//            } else {
//              url += '&paging=false';
//            }
//            if (searchterm) {
//              url += `&searchterm=${searchterm}`;
//            }
//            if (sort) {
//              const sortDirection = (asc ? ':asc' : ':desc');
//              url += `&sort=${sort}${sortDirection}`;
//            }
//            url += `&module_id=${MODULE_ID}`;
//            dispatch({ type: FETCH_SUB_VENUES_WAITING });
//            axios.get(url)
//            .then(response => {
//              dispatch({ type: FETCH_SUB_VENUES_SUCCESS, payload: response });
//            }).catch(error => {
//              dispatch({ type: FETCH_SUB_VENUES_ERROR, payload: error });
//            });
//      };

// export const resetSubVenues = () =>
//           ({ type: FETCH_SUB_VENUES_RESET });

export const fetchVenue = (id, authKey, ws_id) => (dispatch) => {
  dispatch({ type: FETCH_VENUE_WAITING });
  axios.defaults.headers.common["x-auth-key"] = authKey;
  axios
    .get(`${config.BASE_URL}/place/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`)
    .then((response) =>
      dispatch({ type: FETCH_VENUE_SUCCESS, payload: response })
    )
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_VENUE_ERROR)
        : FETCH_VENUE_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetFetchVenue = () => ({ type: FETCH_VENUE_RESET });

// export const fetchSubVenue = (id, authKey) =>
//   (dispatch) => {
//      dispatch({ type: FETCH_SUB_VENUE_WAITING });
//      axios.defaults.headers.common['x-auth-key'] = authKey;
//      axios.get(`${config.BASE_URL}/place/${id}?module_id=${MODULE_ID}`).then(response =>
//      dispatch({ type: FETCH_SUB_VENUE_SUCCESS, payload: response }))
//      .catch(error => dispatch({ type: FETCH_SUB_VENUE_ERROR, payload: error }));
//   };

// export const resetFetchSubVenue = () => ({ type: FETCH_SUB_VENUE_RESET });

export const editVenue = (id, data, authKey, ws_id) => (dispatch) => {
  dispatch({ type: EDIT_VENUE_WAITING });
  axios.defaults.headers.common["x-auth-key"] = authKey;
  axios
    .put(
      `${config.BASE_URL}/place/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`,
      data
    )
    .then((response) =>
      dispatch({ type: EDIT_VENUE_SUCCESS, payload: response })
    )
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, EDIT_VENUE_ERROR)
        : EDIT_VENUE_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetEditVenue = () => ({ type: EDIT_VENUE_RESET });

export const addVenue = (data, authKey, ws_id) => (dispatch) => {
  dispatch({ type: ADD_VENUE_WAITING });
  axios.defaults.headers.common["x-auth-key"] = authKey;
  axios
    .post(
      `${config.BASE_URL}/place?module_id=${MODULE_ID}&ws_id=${ws_id}`,
      data
    )
    .then((response) =>
      dispatch({ type: ADD_VENUE_SUCCESS, payload: response })
    )
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, ADD_VENUE_ERROR)
        : ADD_VENUE_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetAddVenue = () => ({ type: ADD_VENUE_RESET });

export const deleteVenue = (id, authKey, ws_id) => (dispatch) => {
  dispatch({ type: DELETE_VENUE_WAITING });
  axios.defaults.headers.common["x-auth-key"] = authKey;
  axios
    .delete(
      `${config.BASE_URL}/place/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`
    )
    .then((response) =>
      dispatch({
        type: DELETE_VENUE_SUCCESS,
        payload: { ...response, place_id: id },
      })
    )
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, DELETE_VENUE_ERROR)
        : DELETE_VENUE_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetDeleteVenue = () => ({ type: DELETE_VENUE_RESET });

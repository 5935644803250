/* eslint-env browser */
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Table, Icon, Menu, Pagination } from 'semantic-ui-react';
import QRCode from 'qrcode.react';
import { T } from '../Common/Helpers';
import { resetAppConfig } from '../../Redux/actions';

class ApplicationList extends Component {
  componentDidMount() {
    // localStorage.setItem('route', this.props.history.location.pathname);
    this.props.resetAppConfig();
  }

  gotoWizard(appId) {
    const selectedApp = this.props.auth.apps.filter(
      app => app.application_id === appId
    );
    const { ap_key } = selectedApp[0];
    this.props.history.push({
      pathname: `/app/prometheusone/wizard/${ap_key}`,
    });
  }

  renderApplications() {
    return _.map(this.props.auth.apps, application => (
      <Table.Row
        key={application.application_id}
        onClick={this.gotoWizard.bind(this, application.application_id)}
        style={{ cursor: 'pointer' }}
        className="tablerow"
      >
        <Table.Cell>{application.ap_name}</Table.Cell>
        <Table.Cell>{application.ap_android_id}</Table.Cell>
        <Table.Cell>{application.ap_ios_id}</Table.Cell>
        <Table.Cell>
          <QRCode value={application.ap_key} size={50} />
        </Table.Cell>
        <Table.Cell>{application.ap_default_dir}</Table.Cell>
        <Table.Cell>{application.ap_status}</Table.Cell>
      </Table.Row>
    ));
  }

  render() {
    // console.log('app  list pros ', this.props);
    return (
      <Table compact celled padded stackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{T('Application')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Android')}</Table.HeaderCell>
            <Table.HeaderCell>{T('iOS')}</Table.HeaderCell>
            <Table.HeaderCell>{T('QR Code')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Assets directory')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Status')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderApplications()}</Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="7">
              {this.props.headers && (
                <Menu floated="right" pagination>
                  <Pagination
                    onPageChange={this.handlePaginationChange.bind(this)}
                    defaultActivePage={this.props.headers.current_page}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={this.props.headers.total_pages}
                  />
                </Menu>
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}

function mapStateToProps({ auth, applications }) {
  return { auth, applications };
}

export default connect(mapStateToProps, { resetAppConfig })(ApplicationList);

import {
  FETCH_CARD_INSTANCES_WAITING,
  FETCH_CARD_INSTANCES_SUCCESS,
  FETCH_CARD_INSTANCES_ERROR,
  FETCH_CARD_INSTANCES_RESET,
  FETCH_CARD_INSTANCE_WAITING,
  FETCH_CARD_INSTANCE_SUCCESS,
  FETCH_CARD_INSTANCE_ERROR,
  ADD_CARD_INSTANCE_WAITING,
  ADD_CARD_INSTANCE_SUCCESS,
  ADD_CARD_INSTANCE_ERROR,
  ADD_CARD_INSTANCE_RESET,
  EDIT_CARD_INSTANCE_WAITING,
  EDIT_CARD_INSTANCE_SUCCESS,
  EDIT_CARD_INSTANCE_ERROR,
  EDIT_CARD_INSTANCE_RESET,
  DELETE_CARD_INSTANCE_WAITING,
  DELETE_CARD_INSTANCE_SUCCESS,
  DELETE_CARD_INSTANCE_ERROR,
  DELETE_CARD_INSTANCE_RESET,
  FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_WAITING,
  FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_SUCCESS,
  FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_ERROR,
  FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  cardInstancesStatus: 'init',
  cardInstanceStatus: 'init',
  addCardInstanceStatus: 'init',
  editCardInstanceStatus: 'init',
  cardInstancesReportStatus: 'init',
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_CARD_INSTANCES_WAITING:
      return { ...state, cardInstancesStatus: 'waiting' };
    case FETCH_CARD_INSTANCES_SUCCESS:
      return {
        ...state,
        data: action.payload.data.items,
        cardInstancesStatus: 'success',
        searchTerm: action.payload.data.searchterm,
      };
    case FETCH_CARD_INSTANCES_ERROR:
      return {
        ...state,
        cardInstancesStatus: 'error',
        error: action.payload.error,
      };
    case FETCH_CARD_INSTANCES_RESET:
      return { ...state, cardInstancesStatus: 'init', error: null };
    case FETCH_CARD_INSTANCE_WAITING:
      return { ...state, cardInstanceStatus: 'waiting' };
    case FETCH_CARD_INSTANCE_SUCCESS:
      return {
        ...state,
        cardinstance: action.payload.data,
        cardInstanceStatus: 'success',
      };
    case FETCH_CARD_INSTANCE_ERROR:
      return {
        ...state,
        cardInstanceStatus: 'error',
        error: action.payload.error,
      };
    case ADD_CARD_INSTANCE_WAITING:
      return { ...state, addCardInstanceStatus: 'waiting' };
    case ADD_CARD_INSTANCE_SUCCESS:
      return {
        ...state,
        cardinstance: action.payload.data,
        addCardInstanceStatus: 'success',
      };
    case ADD_CARD_INSTANCE_ERROR:
      return {
        ...state,
        addCardInstanceStatus: 'error',
        error: action.payload.error,
      };
    case ADD_CARD_INSTANCE_RESET:
      return { ...state, addCardInstanceStatus: 'init', error: undefined };
    case EDIT_CARD_INSTANCE_WAITING:
      return { ...state, editCardInstanceStatus: 'waiting' };
    case EDIT_CARD_INSTANCE_SUCCESS:
      return {
        ...state,
        cardinstance: action.payload.data,
        editCardInstanceStatus: 'success',
      };
    case EDIT_CARD_INSTANCE_ERROR:
      return {
        ...state,
        editCardInstanceStatus: 'error',
        error: action.payload.error,
      };
    case EDIT_CARD_INSTANCE_RESET:
      return { ...state, editCardInstanceStatus: 'init' };
    case DELETE_CARD_INSTANCE_WAITING:
      return { ...state, deleteCardInstanceStatus: 'waiting' };
    case DELETE_CARD_INSTANCE_SUCCESS:
      return {
        ...state,
        deleteCardInstanceStatus: 'success',
        message: action.payload.data.message,
      };
    case DELETE_CARD_INSTANCE_ERROR:
      return {
        ...state,
        deleteCardInstanceStatus: 'error',
        error: action.payload.error,
      };
    case DELETE_CARD_INSTANCE_RESET:
      return { ...state, deleteCardInstanceStatus: 'init' };
    case FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_WAITING:
      return { ...state, cardInstancesReportStatus: 'waiting' };
    case FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_SUCCESS:
      return { ...state, cardInstancesReportStatus: 'success' };
    case FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_ERROR:
      return {
        ...state,
        cardInstancesReportStatus: 'error',
        error: action.payload.error,
      };
    case FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_RESET:
      return { ...state, cardInstancesReportStatus: 'init' };
    default:
      return state;
  }
}

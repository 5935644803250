import React, { Component } from 'react';
import { Form, Menu, Icon } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import moment from 'moment';
import { T } from '../../Common/Helpers';

const styles = {
  angleArrow: {
    height: 'inherit',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: '40px',
  },
};

const DATE_FORMAT = 'l';
const MONTH = 'month';
const IS_WEEK = 'isoweek';
const CUSTOM = 'custom';

class DateRangeSelection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { activeSubItem } = this.props;
    return (
      <Form.Group>
        <Menu compact style={{ marginLeft: '5px' }}>
          <Menu.Item
            name="Month"
            content={T('Month')}
            active={activeSubItem === MONTH}
            onClick={() => this.props.setActiveMenu('activeSubItem', MONTH)}
          />
          <Menu.Item
            name="Week"
            content={T('Week')}
            active={activeSubItem === IS_WEEK}
            onClick={() => this.props.setActiveMenu('activeSubItem', IS_WEEK)}
          />
          <Menu.Item
            name="Day"
            content={T('Day')}
            active={activeSubItem === 'day'}
            onClick={() => this.props.setActiveMenu('activeSubItem', 'day')}
          />
          <Menu.Item
            name="Custom"
            content={T('Custom')}
            active={activeSubItem === CUSTOM}
            onClick={() => this.props.setActiveMenu('activeSubItem', CUSTOM)}
          />
        </Menu>
        <Icon
          style={styles.angleArrow}
          size="large"
          name="angle left"
          onClick={() => this.props.changeRange('subtract', activeSubItem)}
        />
        <DateInput
          disabled={activeSubItem !== CUSTOM}
          style={{ minWidth: '180px', ...this.props.startError }}
          fluid
          closable
          name="start"
          label={T('Statistic from')}
          onChange={(e, { name, value }) =>
            this.props.handleDateChange(name, value)
          }
          value={this.props.reportRange[activeSubItem].start}
          dateFormat={DATE_FORMAT}
          maxDate={moment().format(DATE_FORMAT)}
        />
        <DateInput
          disabled={activeSubItem !== CUSTOM}
          style={{ minWidth: '180px', ...this.props.endError }}
          fluid
          closable
          name="end"
          label={T('Statistic to')}
          onChange={(e, { name, value }) =>
            this.props.handleDateChange(name, value)
          }
          value={this.props.reportRange[activeSubItem].end}
          dateFormat={DATE_FORMAT}
          maxDate={moment().format(DATE_FORMAT)}
        />
        <Icon
          style={styles.angleArrow}
          size="large"
          name="angle right"
          onClick={() => this.props.changeRange('add', activeSubItem)}
        />
      </Form.Group>
    );
  }
}

export default DateRangeSelection;

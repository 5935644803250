export const FETCH_CAMPAIGNCODES_WAITING = 'fetch-campaigncodes-waiting';
export const FETCH_CAMPAIGNCODES_SUCCESS = 'fetch-campaigncodes-success';
export const FETCH_CAMPAIGNCODES_ERROR = 'fetch-campaigncodes-error';
export const FETCH_CAMPAIGNCODES_RESET = 'fetch-campaigncodes-reset';

export const ADD_CAMPAIGNCODE_WAITING = 'add-campaigncode-waiting';
export const ADD_CAMPAIGNCODE_SUCCESS = 'add-campaigncode-success';
export const ADD_CAMPAIGNCODE_ERROR = 'add_campaigncode_error';
export const ADD_CAMPAIGNCODE_RESET = 'add-campaigncode-reset';

export const EDIT_CAMPAIGNCODE_WAITING = 'edit-campaigncode-waiting';
export const EDIT_CAMPAIGNCODE_SUCCESS = 'edit-campaigncode-success';
export const EDIT_CAMPAIGNCODE_ERROR = 'edit_campaigncode_error';
export const EDIT_CAMPAIGNCODE_RESET = 'edit-campaigncode-reset';

export const DELETE_CAMPAIGNCODE_WAITING = 'delete-campaigncode-waiting';
export const DELETE_CAMPAIGNCODE_SUCCESS = 'delete-campaigncode-success';
export const DELETE_CAMPAIGNCODE_ERROR = 'delete_campaigncode_error';
export const DELETE_CAMPAIGNCODE_RESET = 'delete-campaigncode-reset';

export const FETCH_CAMPAIGNS_WAITING = 'fetch-campaigns-waiting';
export const FETCH_CAMPAIGNS_SUCCESS = 'fetch-campaigns-success';
export const FETCH_CAMPAIGNS_ERROR = 'fetch-campaigns-error';
export const FETCH_CAMPAIGNS_RESET = 'fetch-campaigns-reset';

export const FETCH_CAMPAIGN_COUPON_CODES_WAITING =
  'fetch-campaign_coupon_codes-waiting';
export const FETCH_CAMPAIGN_COUPON_CODES_SUCCESS =
  'fetch-campaign_coupon_codes-success';
export const FETCH_CAMPAIGN_COUPON_CODES_ERROR =
  'fetch-campaign_coupon_codes-error';
export const FETCH_CAMPAIGN_COUPON_CODES_RESET =
  'fetch-campaign_coupon_codes-reset';

export const GENERATE_CAMPAIGN_COUPON_CODES_WAITING =
  'generate-campaign_coupon_codes-waiting';
export const GENERATE_CAMPAIGN_COUPON_CODES_SUCCESS =
  'generate-campaign_coupon_codes-success';
export const GENERATE_CAMPAIGN_COUPON_CODES_ERROR =
  'generate-campaign_coupon_codes-error';
export const GENERATE_CAMPAIGN_COUPON_CODES_RESET =
  'generate-campaign_coupon_codes-reset';

export const DOWNLOAD_CAMPAIGN_COUPON_CODES_WAITING =
  'download-campaign_coupon_codes-waiting';
export const DOWNLOAD_CAMPAIGN_COUPON_CODES_SUCCESS =
  'download-campaign_coupon_codes-success';
export const DOWNLOAD_CAMPAIGN_COUPON_CODES_ERROR =
  'download-campaign_coupon_codes-error';
export const DOWNLOAD_CAMPAIGN_COUPON_CODES_RESET =
  'download-campaign_coupon_codes-reset';

export const DELETE_CAMPAIGN_COUPON_CODES_WAITING =
  'delete-campaign_coupon_codes-waiting';
export const DELETE_CAMPAIGN_COUPON_CODES_SUCCESS =
  'delete-campaign_coupon_codes-success';
export const DELETE_CAMPAIGN_COUPON_CODES_ERROR =
  'delete-campaign_coupon_codes-error';
export const DELETE_CAMPAIGN_COUPON_CODES_RESET =
  'delete-campaign_coupon_codes-reset';

export const FETCH_CAMPAIGN_WAITING = 'fetch-campaign-waiting';
export const FETCH_CAMPAIGN_SUCCESS = 'fetch-campaign-success';
export const FETCH_CAMPAIGN_ERROR = 'fetch-campaign-error';
export const FETCH_CAMPAIGN_RESET = 'fetch-campaign-reset';

export const ADD_CAMPAIGN_WAITING = 'add-campaign-waiting';
export const ADD_CAMPAIGN_SUCCESS = 'add-campaign-success';
export const ADD_CAMPAIGN_ERROR = 'add_campaign_error';
export const ADD_CAMPAIGN_RESET = 'add-campaign-reset';

export const EDIT_CAMPAIGN_WAITING = 'edit-campaign-waiting';
export const EDIT_CAMPAIGN_SUCCESS = 'edit-campaign-success';
export const EDIT_CAMPAIGN_ERROR = 'edit_campaign_error';
export const EDIT_CAMPAIGN_RESET = 'edit-campaign-reset';

export const DELETE_CAMPAIGN_WAITING = 'delete-campaign-waiting';
export const DELETE_CAMPAIGN_SUCCESS = 'delete-campaign-success';
export const DELETE_CAMPAIGN_ERROR = 'delete_campaign_error';
export const DELETE_CAMPAIGN_RESET = 'delete-campaign-reset';

export const FETCH_CAMPAIGNCATEGORIES_WAITING =
  'fetch-campaigncategories-waiting';
export const FETCH_CAMPAIGNCATEGORIES_SUCCESS =
  'fetch-campaigncategories-success';
export const FETCH_CAMPAIGNCATEGORIES_ERROR = 'fetch-campaigncategories-error';
export const FETCH_CAMPAIGNCATEGORIES_RESET = 'fetch-campaigncategories-reset';

export const FETCH_MOMENT_OPTIONS_WAITING = "fetch_moment_options_waiting";
export const FETCH_MOMENT_OPTIONS_SUCCESS = "fetch_moment_options_success";
export const FETCH_MOMENT_OPTIONS_ERROR = "fetch_moment_options_error";
export const FETCH_MOMENT_OPTIONS_RESET = "fetch_moment_options_reset";
export const FETCH_EVENT_REPORT_WAITING = "fetch_event_report_waiting";
export const FETCH_EVENT_REPORT_SUCCESS = "fetch_event_report_success";
export const FETCH_EVENT_REPORT_ERROR = "fetch_event_report_error";
export const FETCH_EVENT_REPORT_RESET = "fetch_event_report_reset";
export const DOWNLOAD_EVENT_REPORT_WAITING = "download_event_report_waiting";
export const DOWNLOAD_EVENT_REPORT_SUCCESS = "download_event_report_success";
export const DOWNLOAD_EVENT_REPORT_ERROR = "download_event_report_error";
export const DOWNLOAD_EVENT_REPORT_RESET = "download_event_report_reset";
export const FETCH_EVENT_STATISTIC_WAITING = "fetch_event_statistic_waiting";
export const FETCH_EVENT_STATISTIC_SUCCESS = "fetch_event_statistic_success";
export const FETCH_EVENT_STATISTIC_ERROR = "fetch_event_statistic_error";
export const FETCH_EVENT_STATISTIC_RESET = "fetch_event_statistic_reset";
export const DOWNLOAD_EVENT_STATISTIC_WAITING =
  "download_event_statistic_waiting";
export const DOWNLOAD_EVENT_STATISTIC_SUCCESS =
  "download_event_statistic_success";
export const DOWNLOAD_EVENT_STATISTIC_ERROR = "download_event_statistic_error";
export const DOWNLOAD_EVENT_STATISTIC_RESET = "download_event_statistic_reset";

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Segment, Header } from 'semantic-ui-react';

import _ from 'lodash';
import PlayerMetadataFormInput from './PlayerMetadataFormInput';
import { T } from '../Common/Helpers';
import {
  addPerformerMetadata,
  deletePerformerMetadata,
  editPerformerMetadata,
  fetchPerformerPlayerMetadata,
} from '../../Redux/actions';
import PlayerMetadataList from './PlayerMetadataList';

class PlayerMetadata extends Component {
  constructor(props) {
    super(props);
    this.state = { metadataSelectedValue: 'DOB' };
    this.popupWindow = React.createRef();
    this.handleMetadataDelete = this.handleMetadataDelete.bind(this);
    this.handleMetadataEdit = this.handleMetadataEdit.bind(this);
    this.setSelectedMetadatakey = this.setSelectedMetadatakey.bind(this);
    this.displayMetadataFormControls =
      this.displayMetadataFormControls.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.props.metadata) {
      const authKey = localStorage.getItem('x-auth-key');
      this.props.fetchPerformerPlayerMetadata(
        this.props.playerId,
        authKey,
        this.props.workspace.ws_id
      );
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.playerId !== nextProps.playerId) {
      const authKey = localStorage.getItem('x-auth-key');
      this.props.fetchPerformerPlayerMetadata(
        this.props.playerId,
        authKey,
        this.props.workspace.ws_id
      );
    }
  }
  setSelectedMetadatakey(key) {
    this.setState({ metadataSelectedValue: key, selectStyle: '' });
  }

  handleMetadataDelete(value) {
    const performerId = this.props.performer.performer_id;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.deletePerformerMetadata(
      performerId,
      value,
      authKey,
      this.props.workspace.ws_id
    );
  }

  handleMetadataEdit(value) {
    this.setSelectedMetadatakey(value);
  }
  handleFormSubmit(formdata, dob, role, gender) {
    const pmKey = this.state.metadataSelectedValue;
    const filteredKey = _.filter(
      this.props.metadata,
      elt => elt.pm_key === pmKey
    );
    let value = '';
    if (pmKey === 'DOB') {
      value = dob;
    } else if (pmKey === 'fieldRold') {
      value = role;
    } else if (pmKey === 'gender') {
      value = gender;
    } else {
      value = formdata[pmKey];
    }
    const isEditMetadata = filteredKey.length > 0;
    const metadataValues = { pm_key: pmKey, pm_value: value };
    const performerId = this.props.performer.performer_id;
    const authKey = localStorage.getItem('x-auth-key');
    if (isEditMetadata === true) {
      const metadataId = filteredKey[0].performer_metadata_id;
      this.props.editPerformerMetadata(
        performerId,
        metadataId,
        metadataValues,
        authKey,
        this.props.workspace.ws_id
      );
    } else {
      this.props.addPerformerMetadata(
        performerId,
        metadataValues,
        authKey,
        this.props.workspace.ws_id
      );
    }
  }
  displayMetadataFormControls() {
    const performerId = this.props.performer.performer_id;
    return (
      <PlayerMetadataFormInput
        metadataKeys={this.props.metadatakeys}
        performerMetadataList={this.props.metadata}
        onCancelClick={this.handleModalClose}
        selectedValueChanged={this.setSelectedMetadatakey}
        selectedValue={this.state.metadataSelectedValue}
        performerId={performerId}
        closemodal={this.handleModalClose}
        onResetEditAndAddPlayer={this.props.onResetEditAndAddPlayer}
        handleFormSubmit={this.handleFormSubmit}
      />
    );
  }

  render() {
    const performerMetadata = this.props.metadata;
    if (
      !this.props.performer ||
      !this.props.metadata ||
      !this.props.metadatakeys
    ) {
      return <div />;
    }
    return (
      <Segment>
        <Header as="h3"> {T('Attributes')}: </Header>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={4}>
              {this.displayMetadataFormControls()}
            </Grid.Column>
            <Grid.Column width={12}>
              <PlayerMetadataList
                metadataKeys={this.props.metadatakeys}
                performerMetadata={performerMetadata}
                handleMetadataEdit={this.handleMetadataEdit}
                handleMetadataDelete={this.handleMetadataDelete}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

function mapStateToProps({ workspace, performerPlayers }) {
  return {
    metadataStatus: performerPlayers.metadataStatus,
    metadata: performerPlayers.metadata,
    workspace,
  };
}
export default connect(mapStateToProps, {
  addPerformerMetadata,
  deletePerformerMetadata,
  editPerformerMetadata,
  fetchPerformerPlayerMetadata,
})(PlayerMetadata);

import {
  FETCH_LINEUP_SUCCESS,
  FETCH_LINEUP_WAITING,
  FETCH_LINEUP_ERROR,
  FETCH_LINEUP_RESET,
  CREATE_LINEUP_SUCCESS,
  CREATE_LINEUP_WAITING,
  CREATE_LINEUP_ERROR,
  CREATE_LINEUP_RESET,
  EDIT_LINEUP_SUCCESS,
  EDIT_LINEUP_WAITING,
  EDIT_LINEUP_ERROR,
  EDIT_LINEUP_RESET,
  DELETE_LINEUP_SUCCESS,
  DELETE_LINEUP_WAITING,
  DELETE_LINEUP_ERROR,
  DELETE_LINEUP_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  operationState: {
    add: 'init',
    delete: 'init',
    update: 'init',
    fetch: 'init',
  },
  lineup: {},
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_LINEUP_SUCCESS: {
      const operationState = { ...state.operationState, fetch: 'success' };
      return { ...state, operationState, lineup: action.payload.data.items };
    }
    case FETCH_LINEUP_WAITING: {
      const operationState = { ...state.operationState, fetch: 'waiting' };
      return { ...state, operationState };
    }
    case FETCH_LINEUP_ERROR: {
      const operationState = { ...state.operationState, fetch: 'error' };
      return {
        ...state,
        operationState,
        lineup: {},
        error: action.payload.error,
      };
    }
    case FETCH_LINEUP_RESET: {
      const operationState = { ...state.operationState, fetch: 'init' };
      return { ...state, operationState, lineup: {} };
    }

    case CREATE_LINEUP_WAITING: {
      const operationState = { ...state.operationState, add: 'waiting' };
      return { ...state, operationState };
    }
    case CREATE_LINEUP_SUCCESS: {
      const operationState = { ...state.operationState, add: 'success' };
      return { ...state, lineup: action.payload.data, operationState };
    }
    case CREATE_LINEUP_ERROR: {
      const operationState = { ...state.operationState, add: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case CREATE_LINEUP_RESET: {
      const operationState = { ...state.operationState, add: 'init' };
      return { ...state, operationState, lineup: {} };
    }

    case EDIT_LINEUP_WAITING: {
      const operationState = { ...state.operationState, update: 'waiting' };
      return { ...state, operationState };
    }
    case EDIT_LINEUP_SUCCESS: {
      const operationState = { ...state.operationState, update: 'success' };
      return { ...state, lineup: action.payload.data, operationState };
    }
    case EDIT_LINEUP_ERROR: {
      const operationState = { ...state.operationState, update: 'error' };
      return {
        ...state,
        operationState,
        lineup: {},
        error: action.payload.error,
      };
    }
    case EDIT_LINEUP_RESET: {
      const operationState = { ...state.operationState, update: 'init' };
      return { ...state, operationState, lineup: {} };
    }

    case DELETE_LINEUP_WAITING: {
      const operationState = { ...state.operationState, delete: 'waiting' };
      return { ...state, ...operationState };
    }
    case DELETE_LINEUP_SUCCESS: {
      const operationState = { ...state.operationState, delete: 'success' };
      return { ...state, lineup: action.payload.data, operationState };
    }
    case DELETE_LINEUP_ERROR: {
      const operationState = { ...state.operationState, delete: 'error' };
      return {
        ...state,
        operationState,
        lineup: {},
        error: action.payload.error,
      };
    }
    case DELETE_LINEUP_RESET: {
      const operationState = { ...state.operationState, delete: 'init' };
      return { ...state, operationState, lineup: {} };
    }
    default:
      return state;
  }
}

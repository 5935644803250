import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Nav from "../layout/Nav";

import PermissionControl from "../components/Persons/PermissionControl";
import PersonList from "../components/Persons/PersonList";
import AddCompany from "../components/Companies/AddCompany";
import PersonRegister from "../components/Persons/PersonRegister";

const PersonRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: "150px" }}>
      <Nav />
      <Switch>
        <Route path={`${path}/list`} exact>
          <PersonList />
        </Route>
        <Route path={`${path}/add`} exact>
          <PersonRegister />
        </Route>
        <Route path={`${path}/add/company`} exact>
          <AddCompany />
        </Route>
        <Route path={`${path}/:personId/edit`} exact>
          <PermissionControl />
        </Route>
      </Switch>
    </Container>
  );
};
export default PersonRoute;

import _ from 'lodash';
import {
  // this reducer should be splitted in to three reducers: shop, product & pricelist
  FETCH_SHOP_PRODUCTS_WAITING,
  FETCH_SHOP_PRODUCTS_SUCCESS,
  FETCH_SHOP_PRODUCTS_ERROR,
  FETCH_SHOP_PRODUCTS_RESET,
  FETCH_SHOP_PRODUCT_WAITING,
  FETCH_SHOP_PRODUCT_SUCCESS,
  FETCH_SHOP_PRODUCT_ERROR,
  FETCH_SHOP_PRODUCT_RESET,
  ADD_SHOP_PRODUCT_WAITING,
  ADD_SHOP_PRODUCT_SUCCESS,
  ADD_SHOP_PRODUCT_ERROR,
  ADD_SHOP_PRODUCT_RESET,
  EDIT_SHOP_PRODUCT_WAITING,
  EDIT_SHOP_PRODUCT_SUCCESS,
  EDIT_SHOP_PRODUCT_ERROR,
  EDIT_SHOP_PRODUCT_RESET,
  DELETE_SHOP_PRODUCT_WAITING,
  DELETE_SHOP_PRODUCT_SUCCESS,
  DELETE_SHOP_PRODUCT_ERROR,
  DELETE_SHOP_PRODUCT_RESET,
  FETCH_SHOP_PRODUCT_UNIT_WAITING,
  FETCH_SHOP_PRODUCT_UNIT_SUCCESS,
  FETCH_SHOP_PRODUCT_UNIT_ERROR,
  FETCH_SHOP_PRODUCT_UNIT_RESET,
  DELETE_PRODUCT_PRICELIST_WAITING,
  DELETE_PRODUCT_PRICELIST_SUCCESS,
  DELETE_PRODUCT_PRICELIST_ERROR,
  DELETE_PRODUCT_PRICELIST_RESET,
  ADD_SHOP_PRODUCT_PRICE_WAITING,
  ADD_SHOP_PRODUCT_PRICE_SUCCESS,
  ADD_SHOP_PRODUCT_PRICE_ERROR,
  ADD_SHOP_PRODUCT_PRICE_RESET,
  EDIT_SHOP_PRODUCT_PRICE_WAITING,
  EDIT_SHOP_PRODUCT_PRICE_SUCCESS,
  EDIT_SHOP_PRODUCT_PRICE_ERROR,
  EDIT_SHOP_PRODUCT_PRICE_RESET,
  FETCH_PRODUCT_TYPES_WAITING,
  FETCH_PRODUCT_TYPES_SUCCESS,
  FETCH_PRODUCT_TYPES_ERROR,
  FETCH_PRODUCT_TYPES_RESET,
  EDIT_MULTIPLE_PRODUCT_PRICES_WAITING,
  EDIT_MULTIPLE_PRODUCT_PRICES_SUCCESS,
  EDIT_MULTIPLE_PRODUCT_PRICES_ERROR,
  EDIT_MULTIPLE_PRODUCT_PRICES_RESET,
  EDIT_PRODUCT_ATTRIBUTES_WAITING,
  EDIT_PRODUCT_ATTRIBUTES_SUCCESS,
  EDIT_PRODUCT_ATTRIBUTES_ERROR,
  EDIT_PRODUCT_ATTRIBUTES_RESET,
  EDIT_SHOP_PRODUCT_PRICELISTS_WAITING,
  EDIT_SHOP_PRODUCT_PRICELISTS_SUCCESS,
  EDIT_SHOP_PRODUCT_PRICELISTS_ERROR,
  EDIT_SHOP_PRODUCT_PRICELISTS_RESET,
  FETCH_OUT_OF_STOCK_MESSAGE_WAITING,
  FETCH_OUT_OF_STOCK_MESSAGE_SUCCESS,
  FETCH_OUT_OF_STOCK_MESSAGE_ERROR,
  FETCH_OUT_OF_STOCK_MESSAGE_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  fetchStatus: 'init',
  productStatus: 'init',
  addStatus: 'init',
  editStatus: 'init',
  deleteStatus: 'init',
  editMulti: 'init',
  deletePPStatus: 'init',
  unitStatus: 'init',
  priceAddStatus: 'init',
  priceEditStatus: 'init',
  pricelists: '',
  units: '',
  fetchProductTypesStatus: 'init',
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_SHOP_PRODUCTS_WAITING:
      return { ...state, fetchStatus: 'waiting' };
    case FETCH_SHOP_PRODUCTS_SUCCESS: {
      const products = _.mapKeys(action.payload.data, 'product_id');
      return {
        ...state,
        // data: _.mapKeys(action.payload.data, 'product_id'),
        products,
        headers: action.payload.headers,
        fetchStatus: 'success',
      };
    }
    case FETCH_SHOP_PRODUCTS_ERROR:
      return { ...state, fetchStatus: 'error', error: action.payload.error };
    case FETCH_SHOP_PRODUCTS_RESET:
      return { ...INIT_STATE };

    case FETCH_SHOP_PRODUCT_UNIT_WAITING: {
      return { ...state, unitStatus: 'waiting' };
    }
    case FETCH_SHOP_PRODUCT_UNIT_SUCCESS:
      return {
        ...state,
        units: action.payload.data.items,
        unitStatus: 'success',
      };
    case FETCH_SHOP_PRODUCT_UNIT_ERROR:
      return { ...state, unitStatus: 'error', error: action.payload.error };
    case FETCH_SHOP_PRODUCT_UNIT_RESET:
      return { ...state, unitStatus: 'init' };

    case FETCH_SHOP_PRODUCT_WAITING:
      return { ...state, product: undefined, productStatus: 'waiting' };
    case FETCH_SHOP_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload.data,
        productStatus: 'success',
      };
    case FETCH_SHOP_PRODUCT_ERROR:
      return {
        ...state,
        product: undefined,
        productStatus: 'error',
        error: action.payload.error,
      };
    case FETCH_SHOP_PRODUCT_RESET:
      return { ...state, product: undefined, productStatus: 'init' };

    case ADD_SHOP_PRODUCT_WAITING:
      return { ...state, addStatus: 'waiting', newlyCreatedProduct: null };
    case ADD_SHOP_PRODUCT_SUCCESS: {
      const updatedState = { ...state, addStatus: 'success' };
      const products = {
        ...state.products,
        [action.payload.data.product_id]: action.payload.data,
      };
      return {
        ...updatedState,
        products,
        newlyCreatedProduct: action.payload.data.product_id,
      };
    }
    case ADD_SHOP_PRODUCT_ERROR:
      return { ...state, addStatus: 'error', error: action.payload.error };
    case ADD_SHOP_PRODUCT_RESET:
      return { ...state, addStatus: 'init' };

    case DELETE_SHOP_PRODUCT_WAITING:
      return { ...state, deleteStatus: 'waiting' };
    case DELETE_SHOP_PRODUCT_SUCCESS: {
      const updatedState = { ...state, deleteStatus: 'success' };
      const products = _.filter(
        state.products,
        item => item.product_id !== action.payload.data.product_id
      );
      // const dataState = _.omit(updatedState.data, action.payload.data.product_id);
      return { ...updatedState, products };
    }
    case DELETE_SHOP_PRODUCT_ERROR:
      return { ...state, deleteStatus: 'error', error: action.payload.error };
    case DELETE_SHOP_PRODUCT_RESET:
      return { ...state, deleteStatus: 'init' };

    case DELETE_PRODUCT_PRICELIST_WAITING:
      return { ...state, deletePPStatus: 'waiting' };
    case DELETE_PRODUCT_PRICELIST_SUCCESS: {
      let pricelists = [...state.products[action.payload.prodId].pricelists];
      // console.log('Deleted Product price id: ', action.payload.data.product_price_id);
      pricelists = pricelists.filter(
        pricelist =>
          pricelist.product_price_id !== action.payload.data.product_price_id
      );
      const product = { ...state.products[action.payload.prodId], pricelists };
      // console.log('Updated pricelists: ', product);
      const products = { ...state.products, [action.payload.prodId]: product };
      return { ...state, deletePPStatus: 'success', products, product };
    }
    case DELETE_PRODUCT_PRICELIST_ERROR:
      return { ...state, deletePPStatus: 'error', error: action.payload.error };
    case DELETE_PRODUCT_PRICELIST_RESET:
      return { ...state, deletePPStatus: 'init' };

    case EDIT_SHOP_PRODUCT_WAITING:
      return { ...state, editStatus: 'waiting' };
    case EDIT_SHOP_PRODUCT_SUCCESS: {
      const products = {
        ...state.products,
        [action.payload.data.product_id]: action.payload.data,
      };
      const product = { ...action.payload.data };
      return { ...state, products, product, editStatus: 'success' };
    }
    case EDIT_SHOP_PRODUCT_ERROR:
      return { ...state, editStatus: 'error', error: action.payload.error };
    case EDIT_SHOP_PRODUCT_RESET:
      return { ...state, editStatus: 'init' };

    case ADD_SHOP_PRODUCT_PRICE_WAITING:
      return { ...state, priceAddStatus: 'waiting' };
    case ADD_SHOP_PRODUCT_PRICE_SUCCESS: {
      return {
        ...state,
        priceAddStatus: 'success',
        data: {
          ...state.data,
          [action.payload.data.pp_product]: {
            ...state.data[action.payload.data.pp_product],
            pricelists: [action.payload.data],
          },
        },
      };
    }
    case ADD_SHOP_PRODUCT_PRICE_ERROR:
      return { ...state, priceAddStatus: 'error', error: action.payload.error };
    case ADD_SHOP_PRODUCT_PRICE_RESET:
      return { ...state, priceAddStatus: 'init' };

    case EDIT_SHOP_PRODUCT_PRICE_WAITING:
      return { ...state, priceEditStatus: 'waiting' };
    case EDIT_SHOP_PRODUCT_PRICE_SUCCESS:
      return { ...state, priceEditStatus: 'success' };
    case EDIT_SHOP_PRODUCT_PRICE_ERROR:
      return {
        ...state,
        priceEditStatus: 'error',
        error: action.payload.error,
      };
    case EDIT_SHOP_PRODUCT_PRICE_RESET:
      return { ...state, priceEditStatus: 'init' };

    case FETCH_PRODUCT_TYPES_WAITING:
      return { ...state, fetchProductTypesStatus: 'waiting' };
    case FETCH_PRODUCT_TYPES_SUCCESS: {
      const productTypes = action.payload.data.items;
      return { ...state, productTypes, fetchProductTypesStatus: 'success' };
    }
    case FETCH_PRODUCT_TYPES_ERROR:
      return {
        ...state,
        fetchProductTypesStatus: 'error',
        error: action.payload.error,
      };
    case FETCH_PRODUCT_TYPES_RESET:
      return { ...state, fetchProductTypesStatus: 'init' };

    case EDIT_MULTIPLE_PRODUCT_PRICES_WAITING:
      return { ...state, editMulti: 'waiting' };
    case EDIT_MULTIPLE_PRODUCT_PRICES_SUCCESS:
      return { ...state, editMulti: 'success' };
    case EDIT_MULTIPLE_PRODUCT_PRICES_ERROR:
      return { ...state, editMulti: 'error', error: action.payload.error };
    case EDIT_MULTIPLE_PRODUCT_PRICES_RESET:
      return { ...state, editMulti: 'init' };

    case EDIT_PRODUCT_ATTRIBUTES_WAITING: {
      return { ...state, attributeStatus: 'waiting' };
    }
    case EDIT_PRODUCT_ATTRIBUTES_SUCCESS: {
      return { ...state, attributeStatus: 'success' };
    }
    case EDIT_PRODUCT_ATTRIBUTES_ERROR: {
      return {
        ...state,
        attributeStatus: 'error',
        error: action.payload.error,
      };
    }
    case EDIT_PRODUCT_ATTRIBUTES_RESET: {
      return { ...state, attributeStatus: 'init' };
    }

    case EDIT_SHOP_PRODUCT_PRICELISTS_WAITING:
      return { ...state, productPl: 'waiting' };
    case EDIT_SHOP_PRODUCT_PRICELISTS_SUCCESS:
      return { ...state, productPl: 'success' };
    case EDIT_SHOP_PRODUCT_PRICELISTS_ERROR:
      return { ...state, productPl: 'error' };
    case EDIT_SHOP_PRODUCT_PRICELISTS_RESET:
      return { ...state, productPl: 'init' };

    case FETCH_OUT_OF_STOCK_MESSAGE_WAITING:
      return { ...state, fetchOutOfStockMessageStatus: 'waiting' };
    case FETCH_OUT_OF_STOCK_MESSAGE_SUCCESS: {
      const stockMessages = action.payload.data;
      return {
        ...state,
        stockMessages,
        fetchOutOfStockMessageStatus: 'success',
      };
    }
    case FETCH_OUT_OF_STOCK_MESSAGE_ERROR:
      return {
        ...state,
        fetchOutOfStockMessageStatus: 'error',
        error: action.payload.error,
      };
    case FETCH_OUT_OF_STOCK_MESSAGE_RESET:
      return { ...state, fetchOutOfStockMessageStatus: 'init' };

    default:
      return state;
  }
}

import React, { Component } from 'react';
import { Form, Button, Header } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { toast } from 'react-semantic-toasts';
import { connect } from 'react-redux';
import {
  addDefaultTicketSettings,
  fetchDefaultTicketSettings,
  editDefaultTicketSettings,
} from '../../Redux/actions';
import { T, getErrorMessage, convertPrice } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';
import { getPopupContent } from '../Common/PopupContent';

class DefaultTicketSettingsManager extends Component {
  constructor(props) {
    super(props);
    if (!this.props.location.state || !this.props.location.state.languages) {
      this.props.history.push('/app/ticketing/default/list');
    }
    const ws_id = this.props.workspace.ws_id;
    const pt_application =
      this.props.workspace.workspaces[ws_id].cnc_application;
    const pt_merchant = this.props.workspace.workspaces[ws_id].cnc_company;
    let tempState = {};
    const languages = this.props.location.state.languages;
    const currencies = this.props.location.state.currencies;
    const pricelists = this.props.location.state.pricelists;
    const taxList = this.props.location.state.taxList;
    const cards = this.props.location.state.cards;
    if (this.props.location.state.defaultTicket) {
      //pr_tax_class_id needs to be removed, only for testing
      tempState = {
        ...this.props.location.state.defaultTicket,
        pt_merchant,
        pt_application,
        editDefault: true,
      };
    } else {
      tempState = {
        pl_name: '',
        pt_saldo: '',
        pt_active: true,
        pt_currency: '',
        pt_language: '',
        pt_long_description: '',
        pt_name: '',
        pt_price: '',
        pt_merchant,
        pt_application,
        pt_price_list: '',
        pt_tax_class_id: '',
        pt_max_person_qty: '',
        pt_max_purchase_qty: '',
        pt_separate_ticket: false,
        editDefault: false,
        ptc_card_id: '',
      };
    }
    this.state = {
      ...tempState,
      languages,
      currencies,
      pricelists,
      taxList,
      cards,
      convert: true,
      error: {
        pt_currencyError: false,
        pt_languageError: false,
        pt_long_descriptionError: false,
        pt_nameError: false,
        pt_priceError: false,
        pt_price_listError: false,
        pt_tax_class_idError: false,
        pt_saldoError: false,
        pt_max_purchase_qtyError: false,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.addStatus !== 'success' &&
      this.props.addStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Default Ticket added successfully'),
        time: 5000,
      });
      this.props.history.push('/app/ticketing/default/list');
    } else if (
      prevProps.addStatus !== 'error' &&
      this.props.addStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.error,
        'Error, adding default ticket'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      prevProps.updateStatus !== 'success' &&
      this.props.updateStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Default Ticket updated successfully'),
        time: 5000,
      });
      this.props.history.push('/app/ticketing/default/list');
    } else if (
      prevProps.updateStatus !== 'error' &&
      this.props.updateStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.error,
        'Error, updating default ticket'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }
  getLanguageList() {
    return _.map(this.state.languages, language => ({
      text: T(language.ln_name),
      value: language.ln_alpha_2,
    }));
  }

  getCurrencyList() {
    return _.map(this.state.currencies, currency => ({
      text: T(currency.cu_name),
      value: currency.currency_iso_id,
    }));
  }

  getPriceList() {
    if (this.state.pricelists) {
      return _.map(this.state.pricelists, pricelist =>
        // ({ text: pricelist.pl_name, value: parseInt(pricelist.price_list_id, 10) })
        ({ text: pricelist.pl_name, value: pricelist.price_list_id })
      );
    }
    return [];
  }

  getTaxList() {
    if (this.state.taxList) {
      return _.map(this.state.taxList, tax => ({
        text: tax.tc_description,
        value: tax.tax_class_id,
      }));
    }
    return [];
  }

  getMembershipCardList() {
    if (this.state.cards) {
      return _.map(this.state.cards, card => ({
        text: card.cd_name,
        value: card.card_id,
      }));
    }
    return [];
  }

  handleInputChange = (e, { name, value }) => {
    const error = this.state.error;
    if (name === 'pt_price') {
      this.setState({ convert: false });
    }
    this.setState({
      [name]: value,
      error: { ...error, [`${name}Error`]: false },
    });
  };
  validateLimitPerPersonAndLimitPerSale = () => {
    const { pt_max_person_qty, pt_max_purchase_qty } = this.state;
    // If there's a limit per person, then limit per sale can not be empty
    // If both fields are not empty, then limit per sale should not be greater than limit per person
    if (
      (!pt_max_person_qty && !pt_max_purchase_qty) ||
      (!pt_max_person_qty && pt_max_purchase_qty)
    ) {
      return {};
    } else if (pt_max_person_qty && !pt_max_purchase_qty) {
      return { pt_max_purchase_qtyError: true };
    } else if (
      parseInt(pt_max_purchase_qty, 10) > parseInt(pt_max_person_qty, 10)
    ) {
      return { pt_max_purchase_qtyError: true };
    }
  };
  checkIfFiedlsArePositive = fields => {
    const error = {};
    _.forEach(fields, field => {
      if (this.state[field] !== '' && parseInt(this.state[field], 10) < 1) {
        error[`${field}Error`] = true;
      }
    });
    return error;
  };
  checkValidityOfRequiredFields = ommitedFields => {
    const errorFields = {};
    let data;
    _.forEach(this.state, (v, k) => {
      if (ommitedFields.indexOf(k) === -1) {
        if (
          (typeof this.state[k] === 'string' && this.state[k].trim() === '') ||
          this.state[k] === null
        ) {
          errorFields[`${k}Error`] = true;
        }
        data = { ...data, [k]: v };
      }
    });
    return { errorFields, data };
  };
  submitDefaultTickets = () => {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.ws_id;
    const appId = this.props.workspace.workspaces[ws_id].cnc_application;
    const merchantId = this.props.workspace.workspaces[ws_id].cnc_company;
    const { price_list_id } = this.state;
    let data = {};
    let errorFields = {};
    // debugger;
    const ommitedFields = [
      'languages',
      'pt_sequence',
      'currencies',
      'pricelists',
      'editDefault',
      'product_template_id',
      'taxList',
      'pl_name',
      'tc_description',
      'error',
      'pt_max_person_qty',
      'pt_max_purchase_qty',
      'pt_saldo',
      'cards',
      'ptc_card_id',
    ];
    const temp = this.checkValidityOfRequiredFields(ommitedFields);
    data = temp.data;
    errorFields = temp.errorFields;
    errorFields = {
      ...errorFields,
      ...this.validateLimitPerPersonAndLimitPerSale(),
    };
    const optionalFields = [
      'pt_max_person_qty',
      'pt_max_purchase_qty',
      'pt_saldo',
      'ptc_card_id',
    ];
    const validFields = this.checkIfFiedlsArePositive(optionalFields);
    if (Object.keys(validFields).length !== 0) {
      _.forEach(validFields, field => {
        errorFields[field] = true;
      });
    } else {
      _.forEach(optionalFields, field => {
        data[field] = this.state[field] || null;
      });
    }

    if (Object.keys(errorFields).length) {
      this.setState(prevState => ({
        error: { ...prevState.error, ...errorFields },
      }));
      return;
    }
    data = {
      ...data,
      pt_price: parseFloat(this.state.pt_price, 10),
      pl_name: this.state.pricelists[price_list_id],
    };
    if (this.state.editDefault) {
      const defaultId = this.state.product_template_id;
      this.props.editDefaultTicketSettings(
        authKey,
        defaultId,
        data,
        appId,
        ws_id
      );
    } else {
      this.props.addDefaultTicketSettings(
        authKey,
        data,
        appId,
        merchantId,
        ws_id
      );
    }
  };
  render() {
    if (
      this.state.addStatus === 'waiting' ||
      this.props.updateStatus === 'waiting'
    ) {
      return <Spinner />;
    }
    const {
      pt_active,
      pt_currency,
      pt_language,
      pt_long_description,
      pt_name,
      pt_price,
      pt_price_list,
      pt_tax_class_id,
      pt_saldo,
      pt_max_person_qty,
      pt_max_purchase_qty,
      pt_separate_ticket,
      ptc_card_id,
    } = this.state;
    //console.log('Default Ticket props: ', this.props, '\nState: ', this.state);
    const header = this.state.editDefault
      ? T('Edit Default Ticket Settings')
      : T('Add Default Ticket');
    const price = this.state.convert
      ? convertPrice(pt_currency, pt_price || null)
      : pt_price;
    return (
      <Form style={{ marginTop: '50px' }}>
        <Header> {header} </Header>
        <Form.Group widths="equal">
          <Form.Input
            required
            error={this.state.error.pt_nameError}
            label={T('Name')}
            name="pt_name"
            value={pt_name}
            type="text"
            onChange={this.handleInputChange}
          />
          <Form.TextArea
            required
            error={this.state.error.pt_long_descriptionError}
            label={T('Ticket Description in Ticket Shop Listing')}
            name="pt_long_description"
            value={pt_long_description}
            type="text"
            onChange={this.handleInputChange}
            rows="3"
          />
          <Form.Input
            required
            error={this.state.error.pt_priceError}
            label={T('Price')}
            name="pt_price"
            value={price}
            step={0.5}
            min={0}
            type="number"
            onChange={this.handleInputChange}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Select
            required
            search
            error={this.state.error.pt_price_listError}
            name="pt_price_list"
            label={T('Price List')}
            value={pt_price_list}
            options={this.getPriceList()}
            onChange={this.handleInputChange}
          />
          <Form.Select
            required
            search
            error={this.state.error.pt_currencyError}
            name="pt_currency"
            label={T('Currency')}
            value={pt_currency}
            options={this.getCurrencyList()}
            onChange={this.handleInputChange}
          />
          <Form.Select
            required
            search
            error={this.state.error.pt_languageError}
            name="pt_language"
            label={T('Language')}
            value={pt_language}
            options={this.getLanguageList()}
            onChange={this.handleInputChange}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            required
            search
            error={this.state.error.pt_tax_class_idError}
            name="pt_tax_class_id"
            label={T('Tax class')}
            placeholder={T('Tax class')}
            fluid
            selection
            // clearable
            selectOnNavigation
            noResultsMessage={T('No result')}
            onChange={this.handleInputChange}
            options={this.getTaxList()}
            value={pt_tax_class_id}
            // style={this.state.pr_tax_class_idErrorStyle || {}}
          />
          <Form.Input
            error={this.state.error.pt_saldoError}
            label={T('Saldo')}
            name="pt_saldo"
            value={pt_saldo || ''}
            type="number"
            min={0}
            step={1}
            onChange={this.handleInputChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            error={this.state.error.pt_max_person_qtyError}
            width={5}
            min={1}
            step={1}
            type="number"
            name="pt_max_person_qty"
            label={T('Purchase limit per person')}
            value={pt_max_person_qty || ''}
            onChange={this.handleInputChange}
          />
          <Form.Input
            error={this.state.error.pt_max_purchase_qtyError}
            width={5}
            min={1}
            type="number"
            name="pt_max_purchase_qty"
            step={1}
            label={T('Purchase limit per sale')}
            value={pt_max_purchase_qty || ''}
            onChange={this.handleInputChange}
          />
        </Form.Group>
        {this.state.cards && this.state.cards.length > 0 ? (
          <Form.Group>
            <Form.Dropdown
              search
              //error={this.state.error.card_idError}
              name="ptc_card_id"
              label={
                <>
                  <Header as="label">
                    {T('Ticket available only for membership card holders')}
                    {getPopupContent(
                      T('DEFAULT_TICKET_HELP1'),
                      'normal',
                      'grey'
                    )}
                  </Header>
                </>
              }
              placeholder={T('Select a Membership Card')}
              fluid
              selection
              // clearable
              selectOnNavigation
              noResultsMessage={T('No result')}
              onChange={this.handleInputChange}
              options={this.getMembershipCardList()}
              value={ptc_card_id}
              clearable
              // style={this.state.pr_tax_class_idErrorStyle || {}}
            />
          </Form.Group>
        ) : null}
        <Form.Group>
          <Form.Checkbox
            style={{ marginTop: '25px' }}
            toggle
            name="pt_separate_ticket"
            label={T('Create a separate ticket for each product purchased')}
            checked={pt_separate_ticket}
            onChange={(e, { name, checked }) =>
              this.handleInputChange(e, { name, value: checked })
            }
          />
          <Form.Checkbox
            style={{ marginTop: '25px', float: 'right' }}
            toggle
            name="pt_active"
            label={T('Show this template when creating event tickets')}
            checked={pt_active}
            onChange={(e, { name, checked }) =>
              this.handleInputChange(e, { name, value: checked })
            }
          />
        </Form.Group>
        <Form.Group>
          <Button
            name="Save"
            type="submit"
            icon="save"
            primary
            content={T('Save')}
            onClick={this.submitDefaultTickets}
          />
          <Button
            name="cancel"
            icon="ban"
            secondary
            content={T('Cancel')}
            onClick={e => {
              e.preventDefault();
              this.props.history.push('/app/ticketing/default/list');
            }}
          />
        </Form.Group>
      </Form>
    );
  }
}

function mapStateToProps({ workspace, defaultTickets }) {
  return {
    workspace,
    addStatus: defaultTickets.opStatus.add,
    updateStatus: defaultTickets.opStatus.update,
    error: defaultTickets.error,
  };
}
export default connect(mapStateToProps, {
  addDefaultTicketSettings,
  fetchDefaultTicketSettings,
  editDefaultTicketSettings,
})(withRouter(DefaultTicketSettingsManager));

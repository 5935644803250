import React, { Component } from 'react';
import { Table, Button, Confirm, Header } from 'semantic-ui-react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  addDefaultTicketSettings,
  fetchDefaultTicketSettings,
  editDefaultTicketSettings,
  deleteDefaultTicketSettings,
} from '../../Redux/actions';
import { T, StatusIcon, convertPriceWithSymbol } from '../Common/Helpers';

class DefaultTicketSettingsList extends Component {
  state = { confirmDelete: false };
  onDelete = (e, value) => {
    e.stopPropagation();
    this.setState({ confirmDelete: true, selectedId: value });
  };

  onDeleteCancel = () => {
    this.setState({ confirmDelete: false });
  };

  onDeleteConfirm = () => {
    const { selectedId } = this.state;
    const ws_id = this.props.workspace.ws_id;
    if (selectedId) {
      const authKey = localStorage.getItem('x-auth-key');
      this.props.deleteDefaultTicketSettings(authKey, selectedId, ws_id);
    }
    this.setState({ confirmDelete: false });
  };
  navigateToLink = obj => {
    const tempObj = {
      languages: this.props.languages,
      currencies: this.props.currencies,
      pricelists: this.props.pricelists,
      taxList: this.props.taxList,
      cards: this.props.cards,
      defaultTicket: obj,
    };
    const pathname = obj
      ? `/app/ticketing/default/manage/${obj.product_template_id}`
      : '/app/ticketing/default/manage';
    this.props.history.push({ pathname, state: { ...tempObj } });
  };

  renderDefaultList = defaultTickets =>
    _.map(defaultTickets, ticket => (
      <Table.Row
        key={ticket.product_template_id}
        className="tablerow"
        onClick={() => this.navigateToLink(ticket)}
      >
        <Table.Cell>{ticket.pt_name}</Table.Cell>
        <Table.Cell>{ticket.pl_name}</Table.Cell>
        <Table.Cell>
          {ticket.pt_price
            ? convertPriceWithSymbol(
                ticket.pt_currency,
                ticket.pt_price ? ticket.pt_price : null
              )
            : ticket.pt_price}
        </Table.Cell>
        <Table.Cell>
          <StatusIcon status={ticket.pt_active} />
        </Table.Cell>
        <Table.Cell>
          <Button
            color="red"
            icon="trash"
            size="mini"
            onClick={e => this.onDelete(e, ticket.product_template_id)}
          />
        </Table.Cell>
      </Table.Row>
    ));

  render() {
    //console.log('Default List Props: ', this.props);
    return (
      <React.Fragment>
        <Header> {T('Default tickets list')} </Header>
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{T('Ticket Name')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Pricelist Name')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Price')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Active')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderDefaultList(this.props.defaultSettings)}
          </Table.Body>
          <Table.Footer>
            <Table.Row colSpan="4" textAlign="left">
              <Table.HeaderCell colSpan="8">
                <Button
                  icon="plus"
                  content={T('Add')}
                  primary
                  onClick={() => this.navigateToLink()}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
          <Confirm
            header={T('Delete Default Ticket')}
            content={T(
              'Are you sure you want to delete the selected default ticket?'
            )}
            confirmButton={T('Delete')}
            cancelButton={T('Cancel')}
            open={this.state.confirmDelete}
            onCancel={this.onDeleteCancel}
            onConfirm={this.onDeleteConfirm}
            size="tiny"
          />
        </Table>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ workspace }) {
  return { workspace };
}
export default connect(mapStateToProps, {
  addDefaultTicketSettings,
  fetchDefaultTicketSettings,
  editDefaultTicketSettings,
  deleteDefaultTicketSettings,
})(withRouter(DefaultTicketSettingsList));

import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { toast } from 'react-semantic-toasts';
import {
  fetchVenues,
  fetchShop,
  resetFetchShop,
  addShop,
  editShop,
  fetchPriceLists,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';
import ShopManage from './ShopManage';

class ShopContainer extends Component {
  state = { sh_merchant: '', sh_place: '' };
  componentDidMount() {
    const ws_id = this.props.workspace.ws_id;
    const authKey = localStorage.getItem('x-auth-key');
    const shopId = this.props.match.params.shopId;
    // console.log('Shop Id: ', this.props.match);
    if (shopId) {
      this.props.fetchShop({ authKey, shopId: parseInt(shopId, 10), ws_id });
      // } else {
      //   this.props.resetFetchShop();
    }
    this.props.fetchVenues(authKey, ws_id);
    this.props.fetchPriceLists({ authKey, ws_id });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.shopFetchStatus !== 'error' &&
      this.props.shopFetchStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.shopError,
        'Error in fetching shop'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      prevProps.venues.fetchAll !== 'error' &&
      this.props.venues.fetchAll === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.venues.error,
        'Error in fetching venues'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      prevProps.shopAddStatus !== 'success' &&
      this.props.shopAddStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Shop added successfully'),
      });
      this.props.history.push('/app/shop/list');
    } else if (
      prevProps.shopEditStatus !== 'success' &&
      this.props.shopEditStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Shop edited successfully'),
      });
      if (this.props.editShopSender === 'edit') {
        //   console.log('Shop Props: ', this.props);
        //   this.props.history.push(`/app/shop/manageshop/${this.props.shop.shop_id}`);
        // } else {
        this.props.history.push('/app/shop/list');
      }
    } else if (
      prevProps.shopAddStatus !== 'error' &&
      this.props.shopAddStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.shopError,
        'Error in adding shop'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
      });
    } else if (
      prevProps.shopEditStatus !== 'error' &&
      this.props.shopEditStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.shopError,
        'Error in editing shop'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
      });
    }
  }

  handleInputChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  submitShopConfig = shopConfigs => {
    if (Object.keys(shopConfigs).length === 0) {
      toast({
        type: 'error',
        title: T('Error'),
        description: T('No configuration value entered'),
      });
    } else {
      const authKey = localStorage.getItem('x-auth-key');
      const shopId = this.props.match.params.shopId;
      const ws_id = this.props.workspace.ws_id;
      const appId = this.props.workspace.workspaces[ws_id].cnc_application;
      this.props.manageShopConfig(authKey, shopId, shopConfigs, appId, ws_id);
    }
  };

  renderPlaces = () =>
    _.map(this.props.places, place => ({
      text: place.pl_name,
      value: place.place_id,
    }));

  render() {
    //console.log('Shop Props: ', this.props.shop);
    const shopId = this.props.match.params.shopId;

    if (
      this.props.venues.fetchAll === 'waiting' ||
      this.props.shopEditStatus === 'waiting' ||
      (this.props.shopFetchStatus === 'waiting' && shopId)
    ) {
      return <Spinner />;
    } else if (
      this.props.venues.fetchAll === 'error' ||
      (shopId && this.props.shopFetchStatus === 'error')
    ) {
      return (
        <Message
          error
          icon="exclamation triangle"
          header={T('Error')}
          content={`${T('Error has occured, while fetching data.')} \n${
            this.props.error
          }`}
        />
      );
    }
    const { ws_id } = this.props.workspace;
    return (
      <ShopManage
        places={this.props.venues.venues}
        ws_id={ws_id}
        merchantId={this.props.workspace.workspaces[ws_id].cnc_company}
        addShop={this.props.addShop}
        editShop={this.props.editShop}
        shopId={shopId}
        shop={this.props.shop}
        history={this.props.history}
        configKeys={this.props.configKeys}
      />
    );
  }
}
function mapStateToProps({ venues, workspace, shop }) {
  return {
    venues,
    workspace,
    shopFetchStatus: shop.shopFetchStatus,
    shop: shop.shop,
    shopAddStatus: shop.shopAddStatus,
    shopEditStatus: shop.shopEditStatus,
    editShopSender: shop.sender,
    shopError: shop.error,
  };
}
export default connect(mapStateToProps, {
  fetchVenues,
  resetFetchShop,
  addShop,
  editShop,
  fetchShop,
  fetchPriceLists,
})(withRouter(ShopContainer));

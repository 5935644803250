export const FETCH_VOTINGS_WAITING = "fetch_votings_waiting";
export const FETCH_VOTINGS_SUCCESS = "fetch_votings_success";
export const FETCH_VOTINGS_ERROR = "fetch_votings_error";
export const FETCH_VOTINGS_RESET = "fetch_votings_reset";

export const FETCH_VOTING_WAITING = "fetch_voting_waiting";
export const FETCH_VOTING_SUCCESS = "fetch_voting_success";
export const FETCH_VOTING_ERROR = "fetch_voting_error";
export const FETCH_VOTING_RESET = "fetch_voting_reset";

export const FETCH_VOTING_REPORT_WAITING = "fetch_voting_report_waiting";
export const FETCH_VOTING_REPORT_SUCCESS = "fetch_voting_report_success";
export const FETCH_VOTING_REPORT_ERROR = "fetch_voting_report_error";
export const FETCH_VOTING_REPORT_RESET = "fetch_voting_report_reset";

export const ADD_VOTING_WAITING = "add_voting_waiting";
export const ADD_VOTING_SUCCESS = "add_voting_success";
export const ADD_VOTING_ERROR = "add_voting_error";
export const ADD_VOTING_RESET = "add_voting_reset";

export const EDIT_VOTING_WAITING = "edit_voting_waiting";
export const EDIT_VOTING_SUCCESS = "edit_voting_success";
export const EDIT_VOTING_ERROR = "edit_voting_error";
export const EDIT_VOTING_RESET = "edit_voting_reset";

export const DELETE_VOTING_WAITING = "delete_voting_waiting";
export const DELETE_VOTING_SUCCESS = "delete_voting_success";
export const DELETE_VOTING_ERROR = "delete_voting_error";
export const DELETE_VOTING_RESET = "delete_voting_reset";

export const FETCH_VOTINGS_REPORT_WAITING = "fetch_votings_report_waiting";
export const FETCH_VOTINGS_REPORT_SUCCESS = "fetch_votings_report_success";
export const FETCH_VOTINGS_REPORT_ERROR = "fetch_votings_report_error";
export const FETCH_VOTINGS_REPORT_RESET = "fetch_votings_report_reset";

export const CANCEL_VOTING_WAITING = "cancel_voting_waiting";
export const CANCEL_VOTING_SUCCESS = "cancel_voting_success";
export const CANCEL_VOTING_ERROR = "cancel_voting_error";
export const CANCEL_VOTING_RESET = "cancel_voting_reset";

export const FETCH_VOTING_CONFIG_WAITING = "fetch_voting_config_waiting";
export const FETCH_VOTING_CONFIG_SUCCESS = "fetch_voting_config_success";
export const FETCH_VOTING_CONFIG_ERROR = "fetch_voting_config_error";

export const PREVIEW_VOTING_WAITING = "preview_voting_waiting";
export const PREVIEW_VOTING_SUCCESS = "preview_voting_success";
export const PREVIEW_VOTING_ERROR = "preview_voting_error";

export const ADD_MORE_POLL_OPTION = "add_more_poll_option";
export const ADD_MORE_POLL_OPTION_RESET = "add_more_poll_option_reset";

export const EDIT_POLL_OPTIONS_INIT = "edit_poll_options_init";
export const RESET_ALL_POLL_OPTIONS = "reset_all_poll_options";
export const UPDATE_POLL_OPTION_IMAGE = "update_poll_option_image";

export const DELETE_POLL_OPTION_WAITING = "delete_poll_option_waiting";
export const DELETE_POLL_OPTION_SUCCESS = "delete_poll_option_success";
export const DELETE_POLL_OPTION_ERROR = "delete_poll_option_error";
export const DELETE_POLL_OPTION_RESET = "delete_poll_option_reset";

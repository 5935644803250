import React, { Component } from "react";
import { Button, Segment, Confirm } from "semantic-ui-react";
import _ from "lodash";
import { connect } from "react-redux";
import { Form } from "formsy-semantic-ui-react";
import { Spinner } from "../Common/Spinner";
import {
  editVenue,
  resetEditVenue,
  setSelectedLocationById,
  deleteVenue,
  resetDeleteVenue,
  fetchSubVenue,
  fetchSubVenues,
  selectedEditSubVenueLocation,
  resetselectedEditSubVenueLocation,
} from "../../Redux/actions";
import { T } from "../Common/Helpers";

import LocationList from "./Locations/LocationList";
import ModalWindow, { ModalContext } from "../Common/ModalWindow";

class SubVenueEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDeleteConfirmation: false,
      isLocationPristin: true,
      selectedLocation: "",
    };
    this.onCancel = this.onCancel.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onLocationChange = _.debounce(this.onLocationChange.bind(this), 100);
  }

  componentDidMount() {
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem("x-auth-key");
    this.props.fetchSubVenue(this.props.venueId, authKey, ws_id);
    this.props.resetselectedEditSubVenueLocation();
  }

  onLocationChange() {
    this.setState({
      selectedLocation: this.props.selectedLocation.location_id,
      isLocationPristin: false,
    });
  }
  onCancel() {
    this.props.closeModal();
  }
  onValidSubmit(data) {
    this.props.closeModal();

    const authKey = localStorage.getItem("x-auth-key");
    const ws_id = this.props.workspace.activeWorkspace.id;
    const pl_company = this.props.workspace.workspaces[ws_id].cnc_company;
    const { venue } = this.props;
    const updatedData = { ...data, pl_company };

    this.props.editVenue(venue.place_id, updatedData, authKey);
  }
  onDeleteClick() {
    this.setState({ openDeleteConfirmation: true });
  }
  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }
  onConfirmDelete() {
    const authKey = localStorage.getItem("x-auth-key");
    const ws_id = this.props.workspace.activeWorkspace.id;

    this.props.deleteVenue(this.props.venue.place_id, authKey);
    this.setState({ openDeleteConfirmation: false });
    this.props.fetchSubVenues(this.props.venue.pl_parent, ws_id, authKey);
  }
  render() {
    const inputStyle = { minWidth: "250px" };
    if (this.props.fetchStatus !== "success") {
      return <Spinner />;
    }
    // if (this.props.venue.pl_location && !this.state.selectedLocation){
    //       this.setState({ selectedLocation: this.props.venue.pl_location});
    // }
    return (
      <Segment>
        <h3>{T("Edit Subvenue")}</h3>
        <Form
          onValidSubmit={this.onValidSubmit.bind(this)}
          ref={(ref) => (this.form = ref)}
        >
          <Form.Group>
            <Form.Input
              required
              value={this.props.venue.pl_name}
              name="pl_name"
              label={T("Name")}
              width={6}
              style={{ ...inputStyle }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              // value={performer.pf_name}
              name="pl_location"
              label={T("Location")}
              width={6}
              style={{ ...inputStyle }}
              readOnly
              onChange={this.onLocationChange}
              value={
                this.props.selectedLocation
                  ? this.props.selectedLocation.location_id
                  : this.props.venue.pl_location
              }
              // value={this.state.selectedLocation}
              action={
                <ModalWindow
                  buttonColor="blue"
                  buttonSize="tiny"
                  buttonLabel={T("Select Location")}
                  header={T("Select Location")}
                >
                  <ModalContext.Consumer>
                    {({ closeModal }) => (
                      <LocationList
                        closeModal={closeModal}
                        setLocation={this.props.selectedEditSubVenueLocation}
                      />
                    )}
                  </ModalContext.Consumer>
                </ModalWindow>
              }
            />
          </Form.Group>
          <Form.Group widths="16">
            <Form.Checkbox
              toggle
              name="pl_public"
              label={T("Public")}
              defaultChecked={this.props.venue.pl_public}
            />
          </Form.Group>
          <Button content={T("Save")} color="green" icon="save" />
          <Button
            type="button"
            color="red"
            content={T("Delete Subvenue")}
            onClick={this.onDeleteClick.bind(this)}
          />
          <Confirm
            header={T("Delete Venue")}
            content={T("Are you sure you want to delete the venue?")}
            confirmButton={T("Delete")}
            cancelButton={T("Cancel")}
            open={this.state.openDeleteConfirmation}
            onCancel={this.oncancelDeleteConfirmation}
            onConfirm={this.onConfirmDelete}
            size="tiny"
          />
          <Button
            type="button"
            color="black"
            content={T("Cancel")}
            onClick={() => this.onCancel()}
          />
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({ venues, locations, workspace }) {
  return {
    venues,
    editStaus: venues.operationState.update,
    selectedLocation: locations.selectedEditSubVenueLocation,
    venue: venues.subvenue,
    fetchStatus: venues.operationState.fetchSubvenue,
    workspace,
  };
}

export default connect(mapStateToProps, {
  editVenue,
  resetEditVenue,
  deleteVenue,
  resetDeleteVenue,
  setSelectedLocationById,
  fetchSubVenue,
  fetchSubVenues,
  selectedEditSubVenueLocation,
  resetselectedEditSubVenueLocation,
})(SubVenueEdit);

import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  FETCH_USERS_SUCCESS,
  FETCH_USERS_RESET,
  FETCH_USERS_ERROR,
  FETCH_USERS_WAITING,
  FETCH_USER_WAITING,
  FETCH_USER_ERROR,
  FETCH_USER_SUCCESS,
  FETCH_USER_RESET,
  CREATE_USER_WAITING,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  // CREATE_USER_RESET,
  DELETE_USER_WAITING,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  // DELETE_USER_RESET,
  EDIT_USER_WAITING,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  // EDIT_USER_RESET,
} from '../Types';

import ErrorAction from './ErrorAction';
// export function fetchUsers(authKey) {
//   axios.defaults.headers.common['x-auth-key'] = authKey;
//   const request = axios.get(`${config.BASE_URL}/users`).then((data) => {
//     console.log('data: ', data);
//   });
//   console.log('Request: ', request);
//   return {
//     type: FETCH_USERS,
//     payload: request
//   };
// }

// TODO module id is wrong at the moment waiting for the right one

const module_id = 37;

export const fetchUsers =
  (authKey, ws_id, application_id, startPage, searchKey, searchTerm) =>
  dispatch => {
    dispatch({ type: FETCH_USERS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url = `${config.BASE_URL}/users/${application_id}?module_id=${module_id}&start=${startPage}&ws_id=${ws_id}`;
    if (searchTerm) url += `&searchkey=${searchKey}&searchterm=${searchTerm}`;
    axios
      .get(url)
      .then(response =>
        dispatch({ type: FETCH_USERS_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_USERS_ERROR)
          : FETCH_USERS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export function resetUsers() {
  return {
    type: FETCH_USERS_RESET,
  };
}

export const fetchUser = (authKey, publicid, ws_id) => dispatch => {
  dispatch({ type: FETCH_USER_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .get(`${config.BASE_URL}/user/${publicid}&ws_id=${ws_id}`)
    .then(response => dispatch({ type: FETCH_USER_SUCCESS, payload: response }))
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_USER_ERROR)
        : FETCH_USER_ERROR;
      dispatch({ type, payload: error });
    });
};

export function resetUser() {
  return {
    type: FETCH_USER_RESET,
  };
}

// export const createUser = (values, authKey, callback) => (dispatch) => {
//       console.log(authKey);
//       axios.defaults.headers.common['x-auth-key'] = authKey;
//       dispatch({ type: CREATE_USER_WAITING });
//       const request = axios.post(`${config.BASE_URL}/users`, values).then(() => {
//         callback();
//       });
//       axios.get(`${config.BASE_URL}/users`)
//       .then(response => {
//         console.log(response.data);
//         dispatch({ type: FETCH_USERS_SUCCESS, payload: response.data });
//       }).catch(error => {
//         console.error(error);
//         dispatch({ type: FETCH_USERS_ERROR, payload: error });
//       });
//     };
//
// export function resetUsers() {
//   return ({
//       type: FETCH_USERS_RESET
//     });
// }

export const createUser = (values, authKey, callback) => dispatch => {
  dispatch({ type: CREATE_USER_WAITING });
  const inputValues = { ...values, company: 8 };
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .post(`${config.BASE_URL}/user`, inputValues)
    .then(response => {
      callback();
      dispatch({ type: CREATE_USER_SUCCESS, payload: response });
    })
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, CREATE_USER_ERROR)
        : CREATE_USER_ERROR;
      dispatch({ type, payload: error });
    });
};

export const editUser = (publicid, authKey, values, callback) => dispatch => {
  dispatch({ type: EDIT_USER_WAITING });
  const inputValues = { ...values, company: 8 };
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .put(`${config.BASE_URL}/user/${publicid}`, inputValues)
    .then(response => {
      callback();
      dispatch({ type: EDIT_USER_SUCCESS, payload: response });
    })
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, EDIT_USER_ERROR)
        : EDIT_USER_ERROR;
      dispatch({ type, payload: error });
    });
};

// export function fetchUser(publicid, authKey) {
//   axios.defaults.headers.common['x-auth-key'] = authKey;
//   const request = axios.get(`${config.BASE_URL}/users/${publicid}`);
//
//   return {
//     type: FETCH_USER_SUCCESS,
//     payload: request
//   };
// }

export const deleteUser = (publicid, authKey, callback) => dispatch => {
  dispatch({ type: DELETE_USER_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .delete(`${config.BASE_URL}/user/${publicid}`)
    .then(response => {
      callback();
      dispatch({ type: DELETE_USER_SUCCESS, payload: response });
    })
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, DELETE_USER_ERROR)
        : DELETE_USER_ERROR;
      dispatch({ type, payload: error });
    });
};

import _ from 'lodash';
import {
  FETCH_USERS_WAITING,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  FETCH_USERS_RESET,
  FETCH_USER_ERROR,
  FETCH_USER_SUCCESS,
  FETCH_USER_RESET,
  DELETE_USER_SUCCESS,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  status: 'init',
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    // const post = action.payload.data;
    // const newState = { ...state };
    // newState[user.id] = user;
    // return newState;
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        [action.payload.data.publicid]: action.payload.data,
        status: 'success',
      };
    case FETCH_USER_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    case FETCH_USER_RESET:
      return { ...INIT_STATE };
    case FETCH_USERS_WAITING:
      return { ...state, status: 'waiting' };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
        headers: action.payload.headers,
        status: 'success',
      };
    case FETCH_USERS_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    case FETCH_USERS_RESET:
      return { ...INIT_STATE };
    case DELETE_USER_SUCCESS:
      // action.payload = id of the User
      return _.omit(state, action.payload);
    default:
      return state;
  }
}

import {
  FETCH_CAMPAIGNCODES_WAITING,
  FETCH_CAMPAIGNCODES_SUCCESS,
  FETCH_CAMPAIGNCODES_ERROR,
  FETCH_CAMPAIGNCODES_RESET,
  ADD_CAMPAIGNCODE_WAITING,
  ADD_CAMPAIGNCODE_SUCCESS,
  ADD_CAMPAIGNCODE_ERROR,
  ADD_CAMPAIGNCODE_RESET,
  EDIT_CAMPAIGNCODE_WAITING,
  EDIT_CAMPAIGNCODE_SUCCESS,
  EDIT_CAMPAIGNCODE_ERROR,
  EDIT_CAMPAIGNCODE_RESET,
  DELETE_CAMPAIGNCODE_WAITING,
  DELETE_CAMPAIGNCODE_SUCCESS,
  DELETE_CAMPAIGNCODE_ERROR,
  DELETE_CAMPAIGNCODE_RESET,
  FETCH_CAMPAIGNS_WAITING,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_ERROR,
  FETCH_CAMPAIGNS_RESET,
  FETCH_CAMPAIGN_WAITING,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_ERROR,
  FETCH_CAMPAIGN_RESET,
  ADD_CAMPAIGN_WAITING,
  ADD_CAMPAIGN_SUCCESS,
  ADD_CAMPAIGN_ERROR,
  ADD_CAMPAIGN_RESET,
  EDIT_CAMPAIGN_WAITING,
  EDIT_CAMPAIGN_SUCCESS,
  EDIT_CAMPAIGN_ERROR,
  EDIT_CAMPAIGN_RESET,
  DELETE_CAMPAIGN_WAITING,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_ERROR,
  DELETE_CAMPAIGN_RESET,
  FETCH_CAMPAIGNCATEGORIES_WAITING,
  FETCH_CAMPAIGNCATEGORIES_SUCCESS,
  FETCH_CAMPAIGNCATEGORIES_ERROR,
  FETCH_CAMPAIGNCATEGORIES_RESET,
  FETCH_CAMPAIGN_COUPON_CODES_WAITING,
  FETCH_CAMPAIGN_COUPON_CODES_SUCCESS,
  FETCH_CAMPAIGN_COUPON_CODES_ERROR,
  FETCH_CAMPAIGN_COUPON_CODES_RESET,
  AUTH_RESET,
  GENERATE_CAMPAIGN_COUPON_CODES_WAITING,
  GENERATE_CAMPAIGN_COUPON_CODES_SUCCESS,
  GENERATE_CAMPAIGN_COUPON_CODES_ERROR,
  GENERATE_CAMPAIGN_COUPON_CODES_RESET,
  DOWNLOAD_CAMPAIGN_COUPON_CODES_WAITING,
  DOWNLOAD_CAMPAIGN_COUPON_CODES_SUCCESS,
  DOWNLOAD_CAMPAIGN_COUPON_CODES_ERROR,
  DOWNLOAD_CAMPAIGN_COUPON_CODES_RESET,
  DELETE_CAMPAIGN_COUPON_CODES_WAITING,
  DELETE_CAMPAIGN_COUPON_CODES_SUCCESS,
  DELETE_CAMPAIGN_COUPON_CODES_ERROR,
  DELETE_CAMPAIGN_COUPON_CODES_RESET,
} from '../Types';

const INIT_STATE = {
  campaigncodes: [],
  campaigncodesStatus: 'init',
  addCampaigncodeStatus: 'init',
  editCampaigncodeStatus: 'init',
  deleteCampaigncodeStatus: 'init',
  campaigns: [],
  campaignsStatus: 'init',
  campaign: {},
  campaignStatus: 'init',
  addCampaignStatus: 'init',
  editCampaignStatus: 'init',
  deleteCampaignStatus: 'init',
  error: '',
  campaigncategoriesStatus: 'init',
  campaigncategories: [],
  campaigncategoriesError: '',
  campaignCouponCodes: [],
  campaignCouponCodesStatus: 'init',
  campaignCouponCodesError: '',
  generateCampaignCouponCodes: [],
  generateCampaignCouponCodesStatus: 'init',
  generateCampaignCouponCodesError: '',
  deleteCampaignCouponCodes: [],
  deleteCampaignCouponCodesStatus: 'init',
  deleteCampaignCouponCodesError: '',
  campaignCouponCodeDownload: 'init',
};

export default function campaignReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_CAMPAIGNCODES_WAITING: {
      return { ...state, error: '', campaigncodesStatus: 'waiting' };
    }
    case FETCH_CAMPAIGNCODES_SUCCESS: {
      return {
        ...state,
        campaigncodesStatus: 'success',
        campaigncodes: action.payload.data,
      };
    }
    case FETCH_CAMPAIGNCODES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        campaigncodesStatus: 'error',
      };
    }
    case FETCH_CAMPAIGNCODES_RESET: {
      return { ...state, campaigncodesStatus: 'init', error: '' };
    }

    case ADD_CAMPAIGNCODE_WAITING: {
      return { ...state, error: '', addCampaigncodeStatus: 'waiting' };
    }
    case ADD_CAMPAIGNCODE_SUCCESS: {
      return { ...state, addCampaigncodeStatus: 'success' };
    }
    case ADD_CAMPAIGNCODE_ERROR: {
      return {
        ...state,
        addCampaigncodeStatus: 'error',
        error: action.payload.error,
      };
    }
    case ADD_CAMPAIGNCODE_RESET: {
      return { ...state, addCampaigncodeStatus: 'init', error: '' };
    }

    case EDIT_CAMPAIGNCODE_WAITING: {
      return { ...state, error: '', editCampaigncodeStatus: 'waiting' };
    }
    case EDIT_CAMPAIGNCODE_SUCCESS: {
      return { ...state, editCampaigncodeStatus: 'success' };
    }
    case EDIT_CAMPAIGNCODE_ERROR: {
      return {
        ...state,
        editCampaigncodeStatus: 'error',
        error: action.payload.error,
      };
    }
    case EDIT_CAMPAIGNCODE_RESET: {
      const operationState = {
        ...state.operationState,
        editCampaigncodeStatus: 'init',
        error: '',
      };
      return { ...state, operationState };
    }

    case DELETE_CAMPAIGNCODE_WAITING: {
      return { ...state, error: '', deleteCampaigncodeStatus: 'waiting' };
    }
    case DELETE_CAMPAIGNCODE_SUCCESS: {
      return { ...state, deleteCampaigncodeStatus: 'success' };
    }
    case DELETE_CAMPAIGNCODE_ERROR: {
      return {
        ...state,
        deleteCampaigncodeStatus: 'error',
        error: action.payload.error,
      };
    }
    case DELETE_CAMPAIGNCODE_RESET: {
      return { ...state, deleteCampaigncodeStatus: 'init', error: '' };
    }

    case FETCH_CAMPAIGNS_WAITING: {
      return { ...state, error: '', campaignsStatus: 'waiting' };
    }
    case FETCH_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        campaignsStatus: 'success',
        campaigns: action.payload.data,
      };
    }
    case FETCH_CAMPAIGNS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        campaignsStatus: 'error',
      };
    }
    case FETCH_CAMPAIGNS_RESET: {
      return { ...state, campaignsStatus: 'init', error: '' };
    }

    case FETCH_CAMPAIGN_WAITING: {
      return { ...state, error: '', campaignStatus: 'waiting' };
    }
    case FETCH_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        campaignStatus: 'success',
        campaign: action.payload.data,
      };
    }
    case FETCH_CAMPAIGN_ERROR: {
      return { ...state, error: action.payload.error, campaignStatus: 'error' };
    }
    case FETCH_CAMPAIGN_RESET: {
      return { ...state, campaignStatus: 'init', error: '' };
    }

    case ADD_CAMPAIGN_WAITING: {
      return { ...state, error: '', addCampaignStatus: 'waiting' };
    }
    case ADD_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        addCampaign: action.payload.data,
        addCampaignStatus: 'success',
      };
    }
    case ADD_CAMPAIGN_ERROR: {
      return {
        ...state,
        addCampaignStatus: 'error',
        error: action.payload.error,
      };
    }
    case ADD_CAMPAIGN_RESET: {
      return { ...state, addCampaignStatus: 'init', error: '' };
    }

    case EDIT_CAMPAIGN_WAITING: {
      return { ...state, error: '', editCampaignStatus: 'waiting' };
    }
    case EDIT_CAMPAIGN_SUCCESS: {
      return { ...state, editCampaignStatus: 'success' };
    }
    case EDIT_CAMPAIGN_ERROR: {
      return {
        ...state,
        editCampaignStatus: 'error',
        error: action.payload.error,
      };
    }
    case EDIT_CAMPAIGN_RESET: {
      return { ...state, editCampaignStatus: 'init', error: '' };
    }

    case DELETE_CAMPAIGN_WAITING: {
      return { ...state, error: '', deleteCampaignStatus: 'waiting' };
    }
    case DELETE_CAMPAIGN_SUCCESS: {
      return { ...state, deleteCampaignStatus: 'success' };
    }
    case DELETE_CAMPAIGN_ERROR: {
      return {
        ...state,
        deleteCampaignStatus: 'error',
        error: action.payload.error,
      };
    }
    case DELETE_CAMPAIGN_RESET: {
      return { ...state, deleteCampaignStatus: 'init', error: '' };
    }

    case FETCH_CAMPAIGNCATEGORIES_WAITING: {
      return {
        ...state,
        campaigncategoriesError: '',
        campaigncategoriesStatus: 'waiting',
      };
    }
    case FETCH_CAMPAIGNCATEGORIES_SUCCESS: {
      return {
        ...state,
        campaigncategoriesError: '',
        campaigncategoriesStatus: 'success',
        campaigncategories: action.payload.data,
      };
    }
    case FETCH_CAMPAIGNCATEGORIES_ERROR: {
      return {
        ...state,
        campaigncategoriesError: action.payload.error,
        campaigncategoriesStatus: 'error',
      };
    }
    case FETCH_CAMPAIGNCATEGORIES_RESET: {
      return {
        ...state,
        campaigncategoriesStatus: 'init',
        campaigncategories: [],
        campaigncategoriesError: '',
      };
    }

    case FETCH_CAMPAIGN_COUPON_CODES_WAITING: {
      return {
        ...state,
        campaignCouponCodesError: '',
        campaignCouponCodesStatus: 'waiting',
      };
    }
    case FETCH_CAMPAIGN_COUPON_CODES_SUCCESS: {
      return {
        ...state,
        campaignCouponCodesError: '',
        campaignCouponCodesStatus: 'success',
        campaignCouponCodes: action.payload.data,
      };
    }
    case FETCH_CAMPAIGN_COUPON_CODES_ERROR: {
      return {
        ...state,
        campaignCouponCodesError: action.payload.error,
        campaignCouponCodesStatus: 'error',
      };
    }
    case FETCH_CAMPAIGN_COUPON_CODES_RESET: {
      return {
        ...state,
        campaignCouponCodesStatus: 'init',
        campaignCouponCodes: [],
        campaignCouponCodesError: '',
      };
    }

    case GENERATE_CAMPAIGN_COUPON_CODES_WAITING: {
      return {
        ...state,
        generateCampaignCouponCodesError: '',
        generateCampaignCouponCodesStatus: 'waiting',
      };
    }
    case GENERATE_CAMPAIGN_COUPON_CODES_SUCCESS: {
      return {
        ...state,
        generateCampaignCouponCodesError: '',
        generateCampaignCouponCodesStatus: 'success',
        generateCampaignCouponCodes: action.payload.data,
      };
    }
    case GENERATE_CAMPAIGN_COUPON_CODES_ERROR: {
      return {
        ...state,
        generateCampaignCouponCodesError: action.payload.error,
        generateCampaignCouponCodesStatus: 'error',
      };
    }
    case GENERATE_CAMPAIGN_COUPON_CODES_RESET: {
      return {
        ...state,
        generateCampaignCouponCodesStatus: 'init',
        generateCampaignCouponCodes: [],
        generateCampaignCouponCodesError: '',
      };
    }

    case DOWNLOAD_CAMPAIGN_COUPON_CODES_WAITING: {
      const status = { ...state.status };
      status.campaignCouponCodeDownload = 'waiting';
      return { ...state, status };
    }
    case DOWNLOAD_CAMPAIGN_COUPON_CODES_SUCCESS: {
      const status = { ...state.status };
      status.campaignCouponCodeDownload = 'success';
      return { ...state, status };
    }
    case DOWNLOAD_CAMPAIGN_COUPON_CODES_ERROR: {
      const status = { ...state.status };
      status.campaignCouponCodeDownload = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case DOWNLOAD_CAMPAIGN_COUPON_CODES_RESET: {
      const status = { ...state.status };
      status.campaignCouponCodeDownload = 'init';
      return { ...state, status, error: undefined };
    }

    case DELETE_CAMPAIGN_COUPON_CODES_WAITING: {
      return {
        ...state,
        deleteCampaignCouponCodesError: '',
        deleteCampaignCouponCodesStatus: 'waiting',
      };
    }
    case DELETE_CAMPAIGN_COUPON_CODES_SUCCESS: {
      return {
        ...state,
        deleteCampaignCouponCodesError: '',
        deleteCampaignCouponCodesStatus: 'success',
        deleteCampaignCouponCodes: action.payload.data,
      };
    }
    case DELETE_CAMPAIGN_COUPON_CODES_ERROR: {
      return {
        ...state,
        deleteCampaignCouponCodesError: action.payload.error,
        deleteCampaignCouponCodesStatus: 'error',
      };
    }
    case DELETE_CAMPAIGN_COUPON_CODES_RESET: {
      return {
        ...state,
        deleteCampaignCouponCodesStatus: 'init',
        deleteCampaignCouponCodes: [],
        deleteCampaignCouponCodesError: '',
      };
    }

    default:
      return state;
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { withRouter } from 'react-router';
// import { toast } from 'react-semantic-toasts';
import { fetchProduct } from '../../Redux/actions';
import ManageProduct from './ManageProduct';
import {
  T,
  // getErrorMessage
} from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';

class ProductParent extends Component {
  componentDidMount() {
    const productId = this.props.match.params.productId;
    if (productId) {
      const authKey = localStorage.getItem('x-auth-key');
      const ws_id = this.props.ws_id;
      this.props.fetchProduct({ id: productId, authKey, ws_id });
    }
  }

  render() {
    const productId = this.props.match.params.productId;
    if (productId && this.props.status === 'error') {
      return (
        <Message error>
          <Message.Title> {T('Error')} </Message.Title>
          <Message.Content>
            {T(`Unable to fetch product, ${this.props.productError}`)}
          </Message.Content>
        </Message>
      );
    } else if (productId && this.props.status === 'waiting') {
      return <Spinner />;
    }
    const product = productId ? this.props.product : undefined;
    return <ManageProduct history={this.props.history} productProp={product} />;
  }
}

function mapStateToProps({ product, workspace }) {
  return {
    productError: product.error,
    product: product.product,
    status: product.productStatus,
    ws_id: workspace.ws_id,
  };
}

export default connect(mapStateToProps, { fetchProduct })(
  withRouter(ProductParent)
);

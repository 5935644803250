import React, { Component } from 'react';
import {
  Form,
  // Segment,
  Button,
  Icon,
  // Header
  Dropdown,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { fetchPerformers, resetFetchPerformers } from '../../Redux/actions';
import { T } from '../Common/Helpers';

class TeamListSeasonFilter extends Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.cancelFilter = this.cancelFilter.bind(this);
  }
  cancelFilter(e) {
    e.preventDefault();
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    const { header } = this.props;
    if (header && header.event_id) {
      this.props.resetInputOptions();
      this.props.fetchPerformers(
        authKey,
        ws_id,
        1,
        header.searchterm,
        header.sort,
        '',
        null
      );
    }
  }
  handleFormSubmit() {
    const { header } = this.props;
    const seasonId = this.props.seasonValue;
    const categoryId = this.props.categoryValue;
    const seasonNotChanged = header && header.event_id === seasonId;
    const ws_id = this.props.workspace.activeWorkspace.id;
    if (!seasonId && !categoryId) {
      this.props.setErrorStyle();
    } else if (!seasonId) {
      this.props.setSeasonErrorStyle();
    } else if (!categoryId) {
      this.props.setCategoryErrorStyle();
    } else if (!seasonNotChanged) {
      const authKey = localStorage.getItem('x-auth-key');
      // const { header } = this.props;
      this.props.resetFetchPerformers();
      this.props.fetchPerformers(
        authKey,
        ws_id,
        1,
        header.searchterm,
        header.sort,
        '',
        seasonId
      );
    }
  }

  render() {
    const { header, seasonValue } = this.props;
    const disableCancel = header && header.event_id ? {} : { disabled: true };
    const disableFilter = seasonValue ? {} : { disabled: true };
    return (
      // <Segment.Group style={{ minWidth: '210px', zIndex: 2 }}>
      <Form onSubmit={this.handleFormSubmit} style={{ marginLeft: '10px' }}>
        <Form.Group widths={16} inline={this.props.isInline}>
          <span style={{ margin: '0 10px' }} className="hideWhenMinimized">
            {T('Filter')}
          </span>
          <Form.Field inline={false} width={6}>
            <Dropdown
              width={16}
              required
              placeholder={T('Category')}
              // labelPosition='left corner'
              name="ev_eventcategory"
              selection
              options={this.props.categoryList}
              value={this.props.categoryValue}
              onChange={this.props.onChangeCategory}
              style={{ ...this.props.categoryStyle }}
            />
          </Form.Field>
          <Form.Field width={6}>
            <Dropdown
              width={16}
              required
              placeholder={T('Season')}
              selection
              // labelPosition='left corner'
              name="ev_parent"
              options={this.props.seasonList}
              value={this.props.seasonValue}
              onChange={this.props.onChangeSeason}
              style={{ ...this.props.seasonStyle }}
            />
          </Form.Field>
          {/*  <div id='filterButtons'> */}
          <Form.Field width={4}>
            <Button.Group>
              <Button
                color="green"
                type="submit"
                {...disableFilter}
                size="tiny"
                style={{ height: '30px' }}
                // floated='right'
              >
                <Icon name="filter" />
                {
                  //T('Filter')
                }
              </Button>
              <Button
                {...disableCancel}
                color="black"
                onClick={this.cancelFilter}
                size="tiny"
                style={{ height: '30px' }}
                // floated='right'
              >
                <Icon name="ban" />
                {
                  //T('Cancel Filtering')
                }
              </Button>
            </Button.Group>
          </Form.Field>
          {/*</div>*/}
        </Form.Group>
      </Form>
      // </Segment.Group>
    );
  }
}
function mapStateToProps({ performers, workspace }) {
  return { header: performers.headers, workspace };
}
export default connect(mapStateToProps, {
  fetchPerformers,
  resetFetchPerformers,
})(TeamListSeasonFilter);

import React, { Component } from 'react';
import { Grid, Segment, Button, Icon, Image, Header } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { connect } from 'react-redux';
import {
  resetTransferPlayerToAnotherTeam,
  fetchPerformerPlayersList,
  editTransferConnection,
  resetEditTransferConnection,
  fetchPerformerPlayer,
  fetchPerformerPlayerMetadata,
  fetchPerformerTeamTransferList,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';
import defaultImage from '../../layout/images/images.png';

const formatDate = (d, today) => {
  const temp = d.toString().split(' ');
  if (today) {
    return `${temp[2]} ${temp[1]} ${temp[3]} ${temp[4]}`;
  }
  return `${temp[1]} ${temp[2]} ${temp[3]} ${temp[4]}`;
};
class PlayerTransferApproval extends Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.rejectPlayerTransfer = this.rejectPlayerTransfer.bind(this);
    this.acceptPlayerTransfer = this.acceptPlayerTransfer.bind(this);
  }

  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    this.props.fetchPerformerPlayer(authKey, this.props.playerId, ws_id);
    this.props.fetchPerformerPlayerMetadata(
      this.props.playerId,
      authKey,
      ws_id
    );
  }

  UNSAFE_componentWillUpdate(nextProps) {
    // console.log('TRANSFER : ', nextProps);
    const ws_id = this.props.workspace.activeWorkspace.id;
    if (
      this.props.editTransferStatus !== 'success' &&
      nextProps.editTransferStatus === 'success'
    ) {
      window.scroll(20, 20);
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Player Transfer connection successfully updated'),
        time: 5000,
      });
      // this.props.onResetEditAndAddPlayer();
      this.props.resetEditTransferConnection();
      const authKey = localStorage.getItem('x-auth-key');
      this.props.fetchPerformerPlayersList(
        authKey,
        ws_id,
        nextProps.currentTeamId,
        nextProps.headers ? nextProps.headers.current_page : 1
      );
      this.props.fetchPerformerTeamTransferList(
        nextProps.currentTeamId,
        ws_id,
        authKey
      );
    } else if (
      this.props.transferredStatus !== 'error' &&
      nextProps.transferredStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        nextProps.error,
        'Error to transfer a player'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.messsage),
        time: 5000,
      });
      this.props.resetTransferPlayerToAnotherTeam();
    }
  }

  onCancel() {
    //this.props.onResetEditAndAddPlayer();
    this.props.closeModal();
  }
  getPlayerImage(metadata) {
    // const metadata = player.performer_metadata;
    let imageSrc = '';
    if (metadata && metadata.length > 0) {
      const pictMeta = metadata.filter(meta => meta.pm_key === 'imageurl');
      if (pictMeta.length !== 0) {
        imageSrc = pictMeta[0].pm_value;
      }
    }
    return imageSrc || defaultImage;
  }
  rejectPlayerTransfer() {
    const inputValues = { pc_rejected: formatDate(new Date()) };
    this.handleFormSubmit(inputValues);
  }
  acceptPlayerTransfer() {
    const inputValues = { pc_accepted: formatDate(new Date()) };
    this.handleFormSubmit(inputValues);
  }
  handleFormSubmit(inputValues) {
    const authKey = localStorage.getItem('x-auth-key');
    const APPROVE = true;
    const values = {
      ...inputValues,
      pc_parent: this.props.connection.pc_parent,
    };
    const ws_id = this.props.workspace.activeWorkspace.id;
    this.props.editTransferConnection(
      this.props.playerId,
      this.props.connection.performer_connection_id,
      values,
      authKey,
      APPROVE,
      ws_id
    );
  }
  render() {
    return (
      <div>
        <Grid columns="2" stackable>
          <Grid.Column width="6">
            <Segment>
              <Header
                as="h4"
                color="blue"
                content={this.props.connection.pf_name}
              />
              <Image
                src={this.getPlayerImage(this.props.metadata)}
                size="small"
                alt={T('Player Picture')}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width="10">
            <Segment>
              <Form>
                <p>
                  <strong> {T('Name')}: </strong>
                  {this.props.connection.pf_name}
                </p>
                <p>
                  <strong> {T('Starting from')}: </strong>
                  {this.props.connection.pc_valid_from}
                </p>
                <p>
                  <strong> {T('Going back')}: </strong>
                  {this.props.connection.pc_valid_to}
                </p>
                {/* <p> <strong> {T('Shirt Number')} </strong> {this.props.metadata ?
                        this.props.metadata.shirtnumber : '' } </p>
                    <p> <strong> {T('Role')} </strong> {this.props.metadata ?
                        this.props.metadata.fieldRold : '' } </p> */}
                <Button.Group>
                  <Button
                    color="green"
                    name="accept"
                    type="button"
                    size="tiny"
                    onClick={this.acceptPlayerTransfer}
                  >
                    <Icon name="check" /> {T('Accept')}
                  </Button>
                  <Button
                    color="red"
                    name="reject"
                    type="button"
                    size="tiny"
                    onClick={this.rejectPlayerTransfer}
                  >
                    <Icon name="close" /> {T('Reject')}
                  </Button>
                  <Button
                    color="black"
                    type="button"
                    onClick={this.onCancel}
                    size="tiny"
                  >
                    <Icon name="ban" /> {T('Cancel')}
                  </Button>
                </Button.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
function mapStateToProps({ performerPlayers, workspace }) {
  return {
    editTransferStatus: performerPlayers.editTransferStatus,
    players: performerPlayers.players,
    player: performerPlayers.player,
    metadata: performerPlayers.metadata,
    error: performerPlayers.error,
    workspace,
  };
}

export default connect(mapStateToProps, {
  resetTransferPlayerToAnotherTeam,
  fetchPerformerPlayersList,
  editTransferConnection,
  resetEditTransferConnection,
  fetchPerformerPlayer,
  fetchPerformerPlayerMetadata,
  fetchPerformerTeamTransferList,
})(PlayerTransferApproval);

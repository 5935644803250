import React, { Component } from 'react';
import { Form, Button, Label, Message } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
import {
  uploadFile,
  addCmsContent,
  saveCmsContents,
  resetSaveCmsContents,
  addCmsContentReset,
  fetchEvent,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';

class EventFileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event_id: this.props.event_id,
      cm_language: this.props.primaryLanguage.ln_alpha_2,
      cm_parent_short_header: '',
      ev_hand_program: undefined,
      cm_short_header: `${this.props.matchToEdit.ev_name} ${T(
        'Program Leaflet'
      )} (${this.props.primaryLanguage.ln_alpha_2})`,
      cm_application: this.props.appId,
      cm_valid_from: moment().toISOString(),
      ev_hand_programError: false,
      cm_languageError: false,
      cm_parent_short_headerError: false,
      cm_short_headerError: false,
      fileName: '',
    };
    this.languages = _.map(this.props.languages, lang => ({
      text: lang.ln_name,
      value: lang.ln_alpha_2,
    }));
  }

  componentDidMount() {
    this.props.resetSaveCmsContents();
    this.props.addCmsContentReset();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.file.uploadStatus !== 'success' &&
      this.props.file.uploadStatus === 'success'
    ) {
      const authKey = localStorage.getItem('x-auth-key');
      let data;
      if (this.props.cms_content_id) {
        const url = this.props.file.data.urlOriginal;
        // const { cm_application, cm_short_header, cm_language } = this.state;
        const { cm_short_header, cm_language, cm_valid_from } = this.state; // removed cm_application
        const { cmsChildren } = this.props;
        if (cmsChildren[cm_language]) {
          data = { cms_content_id: cmsChildren[cm_language].id };
        } else {
          data = { cms_content_id: null };
        }
        data = {
          ...data,
          // cm_application, // removed cm_application to comply with permissions
          cm_short_header,
          cm_content_type: 'hand_program',
          cm_language,
          cm_content: '<p> PDF hand written program </p>',
          cm_parent: this.props.cms_content_id,
          cm_url: url,
          cm_event: this.state.event_id,
          cm_format: 'application/pdf',
          cm_valid_from,
        };
        this.props.saveCmsContents(
          authKey,
          data,
          'handprogram',
          this.props.ws_id
        );
      } else {
        const url = this.props.file.data.urlOriginal;
        // const { cm_application, cms_content_id, cm_content_type } = this.props.cmsContent.data;
        const { cm_language, cm_short_header, cm_valid_from } = this.state;
        data = {
          cms_content_id: null,
          // cm_application, // removed cm_application to comply with permissions
          cm_short_header,
          cm_content_type: 'hand_program',
          cm_language,
          cm_content: '<p> PDF hand written program </p>',
          cm_parent: null,
          cm_url: url,
          cm_event: this.state.event_id,
          cm_format: 'application/pdf',
          cm_valid_from,
        };
        this.props.saveCmsContents(
          authKey,
          data,
          'handprogram',
          this.props.ws_id
        );
        // this.props.setCmsContentId([{ cms_content_id }]);
      }
    } else if (
      prevProps.file.uploadStatus !== 'error' &&
      this.props.file.uploadStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.file.error,
        'Could not upload file'
      );
      toast({
        type: 'error',
        title: 'Error',
        description: T(errorObj.message),
        time: 500,
      });
    } else if (
      prevProps.cmsContent.addStatus !== 'error' &&
      this.props.cmsContent.addStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.cmsContent.error,
        'Could not upload content'
      );
      toast({
        type: 'error',
        title: 'Error',
        description: T(errorObj.message),
        time: 500,
      });
    } else if (
      prevProps.cmsContent.saveStatus !== 'success' &&
      this.props.cmsContent.saveStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('File uploded successfully'),
        time: 500,
      });
      this.props.closeModal();
      const authKey = localStorage.getItem('x-auth-key');
      const ws_id = this.props.ws_id;
      const event_id = this.state.event_id;
      this.props.fetchEvent(authKey, event_id, ws_id);
    } else if (
      prevProps.cmsContent.saveStatus !== 'error' &&
      this.props.cmsContent.saveStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.cmsContent.error,
        'Could not upload content'
      );
      toast({
        type: 'error',
        title: 'Error',
        description: T(errorObj.message),
        time: 500,
      });
    }
  }
  setChange = (e, name, value) => {
    // e.preventDefault();
    if (name === 'cm_valid_from') {
      const dateTime = moment(value, 'l HH:mm');
      if (dateTime.isValid()) {
        this.setState({ cm_valid_from: dateTime.toISOString() });
      }
    } else if (name === 'ev_hand_program') {
      const oneMB = 1048576;
      const limit = 11 * oneMB;
      const file = e.target.files[0];
      if (file && file.size <= limit) {
        this.setState({
          [name]: file,
          ev_hand_programError: false,
          fileName: file.name,
        });
      } else if (file) {
        this.setState({
          [name]: undefined,
          fileName: '',
          v_hand_programError: T('PDF size exceeds the allowed limit'),
        });
      }
    } else {
      this.setState({ [name]: value, [`${name}Error`]: false });
    }
  };
  submitForm = e => {
    e.preventDefault();
    const { event_id, ev_hand_program } = this.state;
    const requiredFields = [
      'cm_language',
      'cm_short_header',
      'ev_hand_program',
    ];
    const errorObj = {};
    /** update: 05.02.2020
     * removed cm_parent_short_header, no need to validate
     **/
    // if (!this.props.cms_content_id) {
    //   requiredFields.push('cm_parent_short_header');
    // }
    _.forEach(requiredFields, field => {
      if (this.state[field] === '' || this.state[field] === undefined) {
        errorObj[`${field}Error`] = true;
      }
    });
    if (Object.keys(errorObj).length) {
      this.setState({ ...errorObj });
      return;
    }
    const { appId } = this.props;
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    const formData = new FormData();
    formData.append('target', 'hand_program');
    formData.append('id', appId);
    formData.append('event_id', event_id);
    formData.append('file', ev_hand_program);
    this.props.uploadFile({ authKey, formData, module_id: 15, ws_id });
  };

  render() {
    if (
      this.props.file.uploadStatus === 'waiting' ||
      this.props.cmsContent.addStatus === 'waiting' ||
      this.props.cmsContent.saveStatus === 'waiting'
    ) {
      return <Spinner />;
    }
    return (
      <Form>
        <Message
          info
          icon="info"
          list={[
            T('Recommended PDF size: 1MB'),
            T('Maximum allowed PDF size: 11MB'),
            T(
              'Please note that, large PDF file takes time when displaying in the mobile app'
            ),
          ]}
        />
        <Form.Input
          required
          error={this.state.cm_short_headerError}
          name="cm_short_header"
          placeholder={T('Content short header')}
          value={this.state.cm_short_header}
          onChange={(e, { name, value }) => this.setChange(e, name, value)}
          label={T('Content short header')}
        />
        <Form.Group widths="equal">
          <Form.Select
            required
            error={this.state.cm_languageError}
            search
            options={this.languages}
            value={this.state.cm_language}
            placeholder={T('Language')}
            label={T('Language')}
            name="cm_language"
            onChange={(e, { name, value }) => this.setChange(e, name, value)}
          />
          <DateTimeInput
            style={{ minWidth: '200px' }}
            closable
            required
            name="cm_valid_from"
            label={T('Valid From')}
            value={
              this.state.cm_valid_from
                ? moment(this.state.cm_valid_from).format('l HH:mm')
                : ''
            }
            onChange={(e, { name, value }) => this.setChange(e, name, value)}
            dateTimeFormat="l HH:mm"
            autoComplete="off"
            history="off"
          />
        </Form.Group>
        <Form.Field error={!!this.state.ev_hand_programError}>
          {this.state.ev_hand_programError &&
            typeof this.state.ev_hand_programError === 'string' && (
              <Label pointing="below" color="red">
                {this.state.ev_hand_programError}
              </Label>
            )}
          <Form.Input
            required
            error={!!this.state.ev_hand_programError}
            type="file"
            accept="application/pdf"
            label={T('Program Leaflet')}
            name="ev_hand_program"
            onChange={(e, { name, value }) => this.setChange(e, name, value)}
          />
        </Form.Field>
        <Button
          content={T('Upload file')}
          positive
          onClick={e => this.submitForm(e)}
        />
        <Button
          content={T('Cancel')}
          secondary
          onClick={e => this.props.closeModal(e)}
        />
      </Form>
    );
  }
}

function mapStateToProps({ workspace, file, cmsContent, languages }) {
  return {
    ws_id: workspace.ws_id,
    file,
    cmsContent,
    primaryLanguage: languages.primaryLanguage,
  };
}
export default connect(mapStateToProps, {
  uploadFile,
  addCmsContent,
  saveCmsContents,
  resetSaveCmsContents,
  addCmsContentReset,
  fetchEvent,
})(withRouter(EventFileUpload));

import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap as Map,
  Marker,
} from "react-google-maps";

// const URL = "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places";
const API_KEY = "AIzaSyB5Aeun4laFvqX6g2mOYwutE97BEuYiTX8";
const GoogleMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: "100%" }} />,
    containerElement: <div style={{ height: "504px", width: "514px" }} />,
    mapElement: <div style={{ height: "100%" }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <Map
    defaultZoom={12}
    defaultCenter={{ lat: props.lat, lng: props.lng }}
    options={{
      minZoom: "1",
      maxZoom: "21",
      rotateControl: "true",
      gestureHandling: "greedy",
      fullscreenControl: false,
      restriction: {
        latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
      },
    }}
    onClick={(e) => {
      props.setLocation(e);
    }}
  >
    {props.isMarkerShown && (
      <Marker
        position={{ lat: -34.397, lng: 150.644 }}
        onClick={(e) => {
          e.stopPropagation();
          props.onMarkerClick(e);
        }}
      />
    )}
  </Map>
));

export default GoogleMap;

/* It renders a table with the totals of the sales or refunds */
import React, { Component } from 'react';
import { Table, Segment, Label } from 'semantic-ui-react';
import { T, convertPriceWithSymbol } from '../../Common/Helpers';

class SalesSummary extends Component {
  render() {
    const { totals, activeItem } = this.props;
    const {
      rows,
      sl_currency,
      sl_total_price_without_tax,
      sl_total_price,
      sl_engage_comission,
      sl_payment_processor_comission,
      sr_row_price,
      sr_row_price_without_tax,
      sr_row_tax,
      sr_engage_comission,
    } = totals;
    return (
      <Segment>
        <Label attached="top">{T('Summary')}</Label>
        <Table>
          <Table.Header>
            {activeItem === 'sales' ? (
              <Table.Row>
                <Table.HeaderCell width={3}>
                  {T('Number of Rows')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {T('Total Ex. VAT')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {T('Total Inc. VAT')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {T('Engage Commission')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {T('Payment Processor Commission')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign="right">
                  {T('Total')}
                </Table.HeaderCell>
              </Table.Row>
            ) : (
              <Table.Row>
                <Table.HeaderCell width={3}>
                  {T('Number of Rows')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {T('Total Ex. VAT')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}> {T('VAT')} </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {T('Engage Commission')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign="right">
                  {T('Total Inc. VAT')}
                </Table.HeaderCell>
              </Table.Row>
            )}
          </Table.Header>
          <Table.Body>
            {activeItem === 'sales' ? (
              <Table.Row>
                <Table.Cell width={3}>{rows}</Table.Cell>
                <Table.Cell width={3}>
                  {convertPriceWithSymbol(
                    sl_currency,
                    sl_total_price_without_tax || 0
                  )}
                </Table.Cell>
                <Table.Cell width={3}>
                  {convertPriceWithSymbol(sl_currency, sl_total_price || 0)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {convertPriceWithSymbol(
                    sl_currency,
                    sl_engage_comission || 0
                  )}
                </Table.Cell>
                <Table.Cell width={3}>
                  {convertPriceWithSymbol(
                    sl_currency,
                    sl_payment_processor_comission || 0
                  )}
                </Table.Cell>
                <Table.Cell width={3} textAlign="right">
                  {convertPriceWithSymbol(sl_currency, sl_total_price || 0)}
                </Table.Cell>
              </Table.Row>
            ) : (
              <Table.Row>
                <Table.Cell width={3}> {rows}</Table.Cell>
                <Table.Cell width={3}>
                  {convertPriceWithSymbol(
                    sl_currency,
                    sr_row_price_without_tax || 0
                  )}
                </Table.Cell>
                <Table.Cell width={3}>
                  {convertPriceWithSymbol(sl_currency, sr_row_tax || 0)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {convertPriceWithSymbol(
                    sl_currency,
                    sr_engage_comission || 0
                  )}
                </Table.Cell>
                <Table.Cell width={3} textAlign="right">
                  {convertPriceWithSymbol(sl_currency, sr_row_price || 0)}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default SalesSummary;

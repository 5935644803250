import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Message } from 'semantic-ui-react';
import { T } from '../Common/Helpers';
import {
  fetchPerformerCMSChildContent,
  fetchPerformerCMSChildContentList,
  resetFetchCMSContentList,
  // fetchLanguages,
  resetFetchLanguages,
} from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import PlayerCMSContentEditor from './PlayerCMSContentEditor';

class PlayerCMSContentController extends Component {
  constructor(props) {
    super(props);
    const { cmsProp } = this.props;
    this.state = {
      selectedLanguage:
        cmsProp && cmsProp.cm_language ? cmsProp.cm_language : 'en',
    };
    this.onContentLanguageChange = this.onContentLanguageChange.bind(this);
  }

  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    // this.props.resetFetchCMSContentList();
    this.props.fetchPerformerCMSChildContentList(
      this.props.parentId,
      authKey,
      ws_id
    );
    // this.props.fetchLanguages(authKey, ws_id);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const authKey = localStorage.getItem('x-auth-key');
    if (this.props.parentId !== nextProps.parentId) {
      const ws_id = this.props.workspace.ws_id;
      this.props.resetFetchCMSContentList();
      this.props.fetchPerformerCMSChildContentList(
        nextProps.parentId,
        authKey,
        ws_id
      );
    }
  }

  onContentLanguageChange(e, { value }) {
    this.setState({ selectedLanguage: value });
  }

  getLanguageList() {
    return _.map(this.props.languages, language => ({
      text: T(language.ln_name),
      value: language.ln_alpha_2,
    }));
  }
  render() {
    if (
      this.props.childCmsListStatus === 'waiting' ||
      this.props.childCmsListStatus === 'init'
      // || this.props.languesStatus !== 'success'
    ) {
      return <Spinner />;
    } else if (this.props.childCmsListStatus === 'error') {
      return (
        <Message error>
          <p> {T('Error while fetching cms child content')} </p>
        </Message>
      );
    }
    return (
      <div>
        <Form.Field>
          <label htmlFor="selectedContentType"> {T('Language')} </label>
          <Form.Select
            required
            search
            name="cm_language"
            onChange={this.onContentLanguageChange}
            style={{ height: '30px', width: '200px' }}
            options={this.getLanguageList()}
            value={this.state.selectedLanguage}
          />
        </Form.Field>
        <PlayerCMSContentEditor
          {...this.props}
          onResetEditAndAddPlayer={this.props.onResetEditAndAddPlayer}
          childCmsList={this.props.childCmsList}
          language={this.state.selectedLanguage}
          closeModal={this.props.closeModal}
        />
      </div>
    );
  }
}
function mapStateToProps({ performerPlayers, languages, workspace }) {
  return {
    childCmsList: performerPlayers.childCmsList,
    childCmsListStatus: performerPlayers.childCmsListStatus,
    // languesStatus: languages.status,
    languages: languages.languages,
    workspace,
  };
}
export default connect(mapStateToProps, {
  fetchPerformerCMSChildContent,
  fetchPerformerCMSChildContentList,
  resetFetchCMSContentList,
  // fetchLanguages,
  resetFetchLanguages,
})(PlayerCMSContentController);

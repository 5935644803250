import _ from 'lodash';
import {
  FETCH_VOTINGS_WAITING,
  FETCH_VOTINGS_SUCCESS,
  FETCH_VOTINGS_ERROR,
  FETCH_VOTINGS_RESET,
  ADD_VOTING_WAITING,
  ADD_VOTING_SUCCESS,
  ADD_VOTING_ERROR,
  ADD_VOTING_RESET,
  FETCH_VOTING_WAITING,
  FETCH_VOTING_SUCCESS,
  FETCH_VOTING_ERROR,
  FETCH_VOTING_RESET,
  FETCH_VOTING_REPORT_WAITING,
  FETCH_VOTING_REPORT_SUCCESS,
  FETCH_VOTING_REPORT_ERROR,
  FETCH_VOTING_REPORT_RESET,
  EDIT_VOTING_WAITING,
  EDIT_VOTING_SUCCESS,
  EDIT_VOTING_ERROR,
  EDIT_VOTING_RESET,
  CANCEL_VOTING_WAITING,
  CANCEL_VOTING_SUCCESS,
  CANCEL_VOTING_ERROR,
  CANCEL_VOTING_RESET,
  DELETE_VOTING_WAITING,
  DELETE_VOTING_SUCCESS,
  DELETE_VOTING_ERROR,
  DELETE_VOTING_RESET,
  FETCH_VOTING_CONFIG_WAITING,
  FETCH_VOTING_CONFIG_SUCCESS,
  FETCH_VOTING_CONFIG_ERROR,
  ADD_MORE_POLL_OPTION,
  ADD_MORE_POLL_OPTION_RESET,
  EDIT_POLL_OPTIONS_INIT,
  UPDATE_POLL_OPTION_IMAGE,
  RESET_ALL_POLL_OPTIONS,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  status: {
    add: 'init',
    edit: 'init',
    cancel: 'init',
    fetch: 'init',
    fetchAll: 'init',
    report: 'init',
    delete: 'init',
    config: 'init',
  },
  error: undefined,
  optionError: undefined,
  polloptions: [],
  data: [],
  config: [],
};

export default function votingReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_VOTINGS_WAITING: {
      const status = { ...state.status };
      status.fetchAll = 'waiting';
      return { ...state, status };
    }
    case FETCH_VOTINGS_SUCCESS: {
      const status = { ...state.status };
      status.fetchAll = 'success';
      return {
        ...state,
        data: action.payload.data,
        headers: action.payload.headers,
        status,
      };
    }
    case FETCH_VOTINGS_ERROR: {
      const status = { ...state.status };
      status.fetchAll = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case FETCH_VOTINGS_RESET: {
      const status = { ...state.status };
      status.fetchAll = 'init';
      return { ...state, status, error: undefined };
    }

    case ADD_VOTING_WAITING: {
      const status = { ...state.status };
      status.add = 'waiting';
      return { ...state, status };
    }
    case ADD_VOTING_SUCCESS: {
      const status = { ...state.status };
      status.add = 'success';
      return { ...state, status, voting: action.payload.data };
    }
    case ADD_VOTING_ERROR: {
      const status = { ...state.status };
      status.add = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case ADD_VOTING_RESET: {
      const status = { ...state.status };
      status.add = 'init';
      return { ...state, status, error: undefined };
    }

    case FETCH_VOTING_WAITING: {
      const status = { ...state.status };
      status.fetch = 'waiting';
      return { ...state, status };
    }
    case FETCH_VOTING_SUCCESS: {
      const status = { ...state.status };
      status.fetch = 'success';
      return { ...state, status, voting: action.payload.data };
    }
    case FETCH_VOTING_ERROR: {
      const status = { ...state.status };
      status.fetch = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case FETCH_VOTING_RESET: {
      const status = { ...state.status };
      status.fetch = 'init';
      return { ...state, status, error: undefined };
    }

    case EDIT_VOTING_WAITING: {
      const status = { ...state.status };
      status.edit = 'waiting';
      return { ...state, status };
    }
    case EDIT_VOTING_SUCCESS: {
      const status = { ...state.status };
      status.edit = 'success';
      return { ...state, status, voting: action.payload.data };
    }
    case EDIT_VOTING_ERROR: {
      const status = { ...state.status };
      status.edit = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case EDIT_VOTING_RESET: {
      const status = { ...state.status };
      status.edit = 'init';
      return { ...state, status, error: undefined };
    }

    case DELETE_VOTING_WAITING: {
      const status = { ...state.status };
      status.delete = 'waiting';
      return { ...state, status };
    }
    case DELETE_VOTING_SUCCESS: {
      const { id } = action;
      const status = { ...state.status };
      status.delete = 'success';
      const updatedData = state.data.filter(item => item.game_id !== id);
      return { ...state, data: updatedData, status };
    }
    case DELETE_VOTING_ERROR: {
      const status = { ...state.status };
      status.delete = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case DELETE_VOTING_RESET: {
      const status = { ...state.status };
      status.delete = 'init';
      return { ...state, status, error: undefined };
    }

    case CANCEL_VOTING_WAITING: {
      const status = { ...state.status };
      status.cancel = 'waiting';
      return { ...state, status };
    }
    case CANCEL_VOTING_SUCCESS: {
      const status = { ...state.status };
      const { game_id, ga_status } = action.payload.data;
      status.cancel = 'success';
      return {
        ...state,
        data: _.map(state.data, g =>
          g.game_id === game_id ? { ...g, ga_status } : g
        ),
        status,
      };
    }
    case CANCEL_VOTING_ERROR: {
      const status = { ...state.status };
      status.cancel = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case CANCEL_VOTING_RESET: {
      const status = { ...state.status };
      status.cancel = 'init';
      return { ...state, status, error: undefined };
    }

    case FETCH_VOTING_REPORT_WAITING: {
      const status = { ...state.status };
      status.report = 'waiting';
      return { ...state, status };
    }
    case FETCH_VOTING_REPORT_SUCCESS: {
      const status = { ...state.status };
      status.report = 'success';
      return { ...state, status, votings: action.payload.data };
    }
    case FETCH_VOTING_REPORT_ERROR: {
      const status = { ...state.status };
      status.report = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case FETCH_VOTING_REPORT_RESET: {
      const status = { ...state.status };
      status.report = 'init';
      return { ...state, status, error: undefined };
    }

    case FETCH_VOTING_CONFIG_WAITING: {
      const status = { ...state.status };
      status.config = 'waiting';
      return { ...state, status };
    }
    case FETCH_VOTING_CONFIG_SUCCESS: {
      const status = { ...state.status };
      status.config = 'success';
      return { ...state, status, config: action.payload.data };
    }
    case FETCH_VOTING_CONFIG_ERROR: {
      const status = { ...state.status };
      status.config = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case ADD_MORE_POLL_OPTION:
      return { ...state, polloptions: [...state.polloptions, action.payload] };

    case ADD_MORE_POLL_OPTION_RESET:
      return { ...state, polloptions: [action.payload] };

    case EDIT_POLL_OPTIONS_INIT:
      return { ...state, polloptions: action.payload };

    case UPDATE_POLL_OPTION_IMAGE:
      return {
        ...state,
        polloptions: state.polloptions.map(polloption =>
          polloption.key === action.payload.data.key
            ? { ...polloption, cm_main_image: action.payload.data.url }
            : polloption
        ),
      };

    case RESET_ALL_POLL_OPTIONS:
      return { ...state, polloptions: [] };
    default:
      return state;
  }
}

import React, { Component } from "react";
import { Button, Segment, Dropdown } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import _ from "lodash";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import { T, getErrorMessage } from "../Common/Helpers";
import { addCompany, resetAddCompany } from "../../Redux/actions";

const errorStyle = {
  backgroundColor: "#FFF6F6",
  borderColor: "#E0B4B4",
  color: "#9F3A38",
  WebkitBoxShadow: "none",
  boxShadow: "none",
  outline: "1px solid #FFF6F6",
};

class AddCompany extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedCountry: "", selectStyle: {} };
    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.getCountryList = this.getCountryList.bind(this);
    this.setSelectedCountry = this.setSelectedCountry.bind(this);
  }

  componentDidMount() {
    // localStorage.setItem('route', this.props.history.location.pathname);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (
      this.props.addStatus !== "success" &&
      nextProps.addStatus === "success"
    ) {
      this.props.resetAddCompany();
      nextProps.history.push("/app/companies/list");
    } else if (nextProps.addStatus === "error") {
      const errorObj = getErrorMessage(nextProps.companies.error);
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetAddCompany();
    }
  }

  onValidSubmit(formData) {
    const inputValues = { ...formData, co_country: this.state.selectedCountry };
    if (!inputValues.co_country) {
      this.setState({ selectStyle: errorStyle });
      return;
    }
    const authKey = localStorage.getItem("x-auth-key");
    this.props.addCompany(inputValues, authKey);
    this.props.closeModal();
  }
  onCancel() {
    this.props.closeModal();
  }
  onChangeCountry(event) {
    this.setState({ selectedCountry: event.target.value, countryError: {} });
  }

  setSelectedCountry(e, { value }) {
    this.setState({ selectedCountry: value, selectStyle: {} });
  }

  getCountryList() {
    const selectLabel = {
      country_iso2: "",
      value: "",
      country_name: T("Select a Country"),
    };
    const countriesProp = this.props.countries.countries;
    if (typeof countriesProp === "object" && countriesProp.length > 0) {
      const countries = this.props.countries.countries.slice();
      countries.unshift(selectLabel);
      return _.map(countries, (option) => ({
        flag: option.country_iso2.toLowerCase(),
        text: option.country_name,
        value: option.country_iso2,
      }));
    }
    return selectLabel;
  }

  render() {
    return (
      <Segment>
        <Form
          onValidSubmit={this.onValidSubmit.bind(this)}
          ref={(ref) => (this.form = ref)}
        >
          <Form.Group style={{ width: "290px" }}>
            <Form.Input
              required
              name="co_name"
              label={T("Company Name")}
              placeholder={T("Company Name")}
              width={16}
              autoFocus
            />
          </Form.Group>
          <Form.Group style={{ width: "290px" }}>
            <Dropdown
              required
              name="co_country"
              label={T("Country")}
              placeholder={T("Country")}
              search
              selection
              selectOnNavigation
              noResultsMessage={T("No result")}
              style={this.state.selectStyle}
              fluid
              onChange={this.setSelectedCountry}
              options={this.getCountryList()}
              width={10}
            />
          </Form.Group>
          <Button content={T("Save")} color="green" />
          <Button
            type="button"
            color="black"
            content={T("Cancel")}
            onClick={() => this.onCancel()}
          />
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({ companies, countries }) {
  return {
    companies,
    countries,
    addStatus: companies.operationState.add,
  };
}

export default connect(mapStateToProps, { addCompany, resetAddCompany })(
  AddCompany
);

export const FETCH_EVENT_CONTENT_TYPE_WAITING =
  "fetch_event_content_type_waiting";
export const FETCH_EVENT_CONTENT_TYPE_SUCCESS =
  "fetch_event_content_type_success";
export const FETCH_EVENT_CONTENT_TYPE_ERROR = "fetch_event_content_type_error";
export const FETCH_EVENT_CONTENT_TYPE_RESET = "fetch_event_content_type_reset";

export const FETCH_KEY_VALUES_WAITING = "fetch_key_values_waiting";
export const FETCH_KEY_VALUES_SUCCESS = "fetch_key_values_success";
export const FETCH_KEY_VALUES_ERROR = "fetch_key_values_error";
export const FETCH_KEY_VALUES_RESET = "fetch_key_values_reset";

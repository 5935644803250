import React, { Component } from "react";
import { Button, Segment } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import {
  addSubVenue,
  resetAddVenue,
  fetchSubVenues,
  selectedAddSubVenueLocation,
} from "../../Redux/actions";
import { T } from "../Common/Helpers";

import LocationList from "./Locations/LocationList";
import ModalWindow, { ModalContext } from "../Common/ModalWindow";

class SubVenueAdd extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
  }
  onCancel() {
    this.props.closeModal();
  }
  onValidSubmit(data) {
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem("x-auth-key");
    const { parentVenue } = this.props;
    const pl_company = this.props.workspace.workspaces[ws_id].cnc_company;
    const inputData = { ...data, pl_parent: parentVenue.place_id, pl_company };
    this.props.addSubVenue(inputData, authKey, ws_id);
    this.props.fetchSubVenues(parentVenue.place_id, ws_id, authKey);
    this.props.closeModal();
  }
  render() {
    const inputStyle = { minWidth: "250px" };
    return (
      <Segment>
        <h3>{T("Add Subvenue")}</h3>
        <Form
          onValidSubmit={this.onValidSubmit.bind(this)}
          ref={(ref) => (this.form = ref)}
        >
          <Form.Group>
            <Form.Input
              value={`${this.props.parentVenue.place_id}: ${this.props.parentVenue.pl_name}`}
              name="parent"
              label={T("Parent Venue")}
              width={6}
              style={{ ...inputStyle }}
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              required
              // value={this.props.parentId}
              name="pl_name"
              label={T("Name")}
              width={6}
              style={{ ...inputStyle }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              // value={performer.pf_name}
              name="pl_location"
              label={T("Location")}
              width={6}
              style={{ ...inputStyle }}
              readOnly
              value={
                this.props.selectedLocation
                  ? this.props.selectedLocation.location_id
                  : null
              }
              action={
                <ModalWindow
                  buttonColor="blue"
                  buttonSize="tiny"
                  buttonLabel={T("Select Location")}
                  header={T("Select Location")}
                >
                  <ModalContext.Consumer>
                    {({ closeModal }) => (
                      <LocationList
                        closeModal={closeModal}
                        setLocation={this.props.selectedAddSubVenueLocation}
                      />
                    )}
                  </ModalContext.Consumer>
                </ModalWindow>
              }
            />
          </Form.Group>
          <Form.Group widths="16">
            <Form.Checkbox
              toggle
              name="pl_public"
              label={T("Public")}
              // defaultChecked={venue.pl_public}
            />
          </Form.Group>
          <Button content={T("Save")} color="green" icon="save" />
          <Button
            type="button"
            color="black"
            content={T("Cancel")}
            onClick={() => this.onCancel()}
          />
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({ venues, locations, workspace }) {
  return {
    venues,
    addStatus: venues.operationState.add,
    selectedLocation: locations.selectedAddSubVenueLocation,
    workspace,
  };
}

export default connect(mapStateToProps, {
  addSubVenue,
  resetAddVenue,
  fetchSubVenues,
  selectedAddSubVenueLocation,
})(SubVenueAdd);

import axios from 'axios';
import config from '../../Config/AEConfig';
import {
  createEventByEventType,
  editEventByType,
  deleteEventByType,
} from './EventBaseFunctions';
import {
  FETCH_EVENTS_BY_CATEGORY_WAITING,
  FETCH_EVENTS_BY_CATEGORY_SUCCESS,
  FETCH_EVENTS_BY_CATEGORY_RESET,
  FETCH_EVENTS_BY_TYPE_WAITING,
  FETCH_EVENTS_BY_TYPE_SUCCESS,
  FETCH_SEASONS_WAITING,
  FETCH_SEASONS_SUCCESS,
  FETCH_SEASONS_ERROR,
  FETCH_SERIES_WAITING,
  FETCH_SERIES_SUCCESS,
  FETCH_SERIES_ERROR,
  FETCH_MATCHES_SUCCESS,
  FETCH_SEASONS_RESET,
  FETCH_SERIES_RESET,
  FETCH_MATCHES_RESET,
  UPDATE_PARENTS,
} from '../Types';

import ErrorAction from './ErrorAction';

const MODULE_ID = 3;

export const createSeries = (values, authKey, wsId) =>
  createEventByEventType(values, authKey, wsId, 'series');

export const createSeason = (values, authKey, wsId) =>
  createEventByEventType(values, authKey, wsId, 'season');

export const deleteSeason = (id, authKey, parentId, ws_id) =>
  deleteEventByType(id, authKey, 'season', parentId, ws_id);

export const deleteSeries = (id, authKey, ws_id) =>
  deleteEventByType(id, authKey, 'series', id, ws_id);

export const editSeason = (id, authKey, wsId, values) =>
  editEventByType(id, authKey, wsId, values, 'season');

export const editSeries = (id, authKey, wsId, values) =>
  editEventByType(id, authKey, wsId, values, 'series');

export const fetchEventsByCategory =
  (authKey, ev_eventcategory, type, ws_id, module_id = MODULE_ID) =>
  dispatch => {
    dispatch({ type: FETCH_EVENTS_BY_CATEGORY_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .get(
        `${config.BASE_URL}/events/type/${type}?category_id=${ev_eventcategory}&module_id=${module_id}&ws_id=${ws_id}`
      )
      .then(response => {
        dispatch({ type: FETCH_EVENTS_BY_CATEGORY_SUCCESS, payload: response });
      })
      .catch(error => {
        const errorType = error.response
          ? ErrorAction(error.response.status, null)
          : Error(404);
        dispatch({ type: errorType, payload: error });
      });
  };

export const fetchEventsByType =
  ({
    authKey,
    ws_id,
    module_id,
    type,
    products = false,
    performer = false,
    all = false,
    searchTerm = '',
    sortByColumn = 'ev_name',
    sortDirection = 'asc',
    gameId,
  }) =>
  dispatch => {
    dispatch({ type: FETCH_EVENTS_BY_TYPE_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const paramObjs = {
      ws_id,
      searchterm: searchTerm,
      products,
      performer,
      all,
      module_id,
      sort: sortByColumn,
      game_id: gameId,
    };
    let apiUrl = `/events/type/${type}?`;

    Object.keys(paramObjs).forEach(param => {
      if (paramObjs[param] && param === 'sort') {
        apiUrl += `${param}=${paramObjs[param]}:${sortDirection || 'asc'}&`;
      } else if (paramObjs[param]) {
        apiUrl += `${param}=${paramObjs[param]}&`;
      }
    });

    //axios.get(`${config.BASE_URL}/events/type/${type}?&module_id=${MODULE_ID}&ws_id=${ws_id}&products=${products}&performer=${performer}&all=${all}`)
    axios
      .get(`${config.BASE_URL}${apiUrl}`)
      .then(response => {
        dispatch({ type: FETCH_EVENTS_BY_TYPE_SUCCESS, payload: response });
      })
      .catch(error => {
        const errorType = error.response
          ? ErrorAction(error.response.status, null)
          : Error(404);
        dispatch({ type: errorType, payload: error });
      });
  };

export const fetchSeasons = (authKey, parent, ws_id) => dispatch => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  //axios.get(`${config.BASE_URL}/events/type/season?parent_id=${parent}`)
  dispatch({ type: FETCH_SEASONS_WAITING, payload: { id: parent } });
  axios
    .get(
      `${config.BASE_URL}/events/type/season?parent_id=${parent}&ws_id=${ws_id}&all=true&module_id=${MODULE_ID}&products=false`
    )
    .then(response => {
      dispatch({
        type: FETCH_SEASONS_SUCCESS,
        payload: { ...response, id: parent },
      });
    })
    .catch(error => {
      // console.log('Fetch Season Error: ', error);
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_SEASONS_ERROR)
        : FETCH_SEASONS_ERROR;
      dispatch({ type, payload: { error, id: parent } });
    });
};

export const fetchSeries = (authKey, ws_id) => dispatch => {
  dispatch({ type: FETCH_SERIES_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  //axios.get(`${config.BASE_URL}/events/type/season?parent_id=${parent}`)
  axios
    .get(
      `${config.BASE_URL}/events/type/series?ws_id=${ws_id}&performer=true&module_id=${MODULE_ID}&products=false`
    )
    .then(response => {
      dispatch({ type: FETCH_SERIES_SUCCESS, payload: response });
    })
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_SERIES_ERROR)
        : FETCH_SERIES_ERROR;
      dispatch({ type, payload: error });
    });
};
export const fetchMatches = (authKey, parent, ws_id) => dispatch => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  //axios.get(`${config.BASE_URL}/events/type/season?parent_id=${parent}`)
  axios
    .get(
      `${config.BASE_URL}/events/type/season?ws_id=${ws_id}&parent_id=${parent}&performer=true&module_id=${MODULE_ID}`
    )
    .then(response => {
      dispatch({ type: FETCH_MATCHES_SUCCESS, payload: response });
    })
    .catch(error => {
      const errorType = error.response
        ? ErrorAction(error.response.status, null)
        : Error(404);
      dispatch({ type: errorType, payload: error });
    });
};
export function updateParents() {
  return {
    type: UPDATE_PARENTS,
  };
}

export function resetEventsByCategory() {
  return {
    type: FETCH_EVENTS_BY_CATEGORY_RESET,
  };
}
export function resetSeasons() {
  return {
    type: FETCH_SEASONS_RESET,
  };
}

export function resetSeries() {
  return {
    type: FETCH_SERIES_RESET,
  };
}

export function resetMatches() {
  return {
    type: FETCH_MATCHES_RESET,
  };
}

import React, { Component } from 'react';
import { Table, Header, Segment } from 'semantic-ui-react';
import moment from 'moment';
import { T } from '../../Common/Helpers';
import { getPopupContent } from '../../Common/PopupContent';

const UNIQUE_USERS = 'Unique Users';
const NBR_IOS = 'Number of iOS';
const NBR_ANDROID = 'Number of Android';
const POPUP_CONTENT_1 = 'How many times the app has been dowloaded';
const POPUP_CONTENT_2 = 'Number of unique app users';
const POPUP_CONTENT_3 = 'How many times the app is dowloaded into iOS device';
const POPUP_CONTENT_4 =
  'How many times the app is dowloaded into android device';
const POPUP_CONTENT_5 = 'How many times the app has been launched';
const POPUP_CONTENT_6 = 'How many times launched from iOS device';
const POPUP_CONTENT_7 = 'How many times launched from android device';
const POPUP_CONTENT_8 = 'Number of devices receiving push messages';

const POPUP_ICON_SIZE = 'large';
const POPUP_COLOR = 'lightgrey';

class AppStatisticSummary extends Component {
  render() {
    const { totals, date_range_totals } = this.props;
    return (
      <Segment>
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Header> {T('All-time Stats')} </Header>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>
                  {T('Downloads')}
                  {getPopupContent(
                    POPUP_CONTENT_1,
                    POPUP_ICON_SIZE,
                    POPUP_COLOR
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {T(NBR_IOS)}
                  {getPopupContent(
                    POPUP_CONTENT_3,
                    POPUP_ICON_SIZE,
                    POPUP_COLOR
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {T(NBR_ANDROID)}
                  {getPopupContent(
                    POPUP_CONTENT_4,
                    POPUP_ICON_SIZE,
                    POPUP_COLOR
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={3}> {totals.total_downloads}</Table.Cell>
                <Table.Cell width={3}>{totals.total_os.iOS}</Table.Cell>
                <Table.Cell width={3}>{totals.total_os.Android}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Header>
            {T('Totals in Date Range') +
              ': ' +
              moment(date_range_totals.start_date, 'YYYY-MM-DD').format(
                'DD.MM.YYYY'
              ) +
              ' - ' +
              moment(date_range_totals.end_date, 'YYYY-MM-DD').format(
                'DD.MM.YYYY'
              )}
          </Header>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>
                  {T('App Openings')}
                  {getPopupContent(
                    POPUP_CONTENT_5,
                    POPUP_ICON_SIZE,
                    POPUP_COLOR
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {T(NBR_IOS)}
                  {getPopupContent(
                    POPUP_CONTENT_6,
                    POPUP_ICON_SIZE,
                    POPUP_COLOR
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {T(NBR_ANDROID)}
                  {getPopupContent(
                    POPUP_CONTENT_7,
                    POPUP_ICON_SIZE,
                    POPUP_COLOR
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={3}>
                  {date_range_totals.total_openings}
                </Table.Cell>
                <Table.Cell width={3}>{date_range_totals.total_iOS}</Table.Cell>
                <Table.Cell width={3}>
                  {date_range_totals.total_android}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </Segment>
    );
  }
}

export default AppStatisticSummary;

import React from "react";
import { Container } from "semantic-ui-react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Nav from "../layout/Nav";
import Campaigncodes from "../components/Campaign/Campaigncodes";
import ListCampaigns from "../components/Campaign/ListCampaigns";
import Campaign from "../components/Campaign/Campaign";

const CampaignRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: "150px" }}>
      <Nav />
      <Switch>
        <Route path={`${path}/manage/:campaignId`} exact>
          <Campaign />
        </Route>
        <Route path={`${path}/campaigns`} exact>
          <ListCampaigns />
        </Route>
        <Route path={`${path}/campaigncodes`} exact>
          <Campaigncodes />
        </Route>
      </Switch>
    </Container>
  );
};

export default CampaignRoute;

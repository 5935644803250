export const FETCH_CARD_INSTANCES_WAITING = "fetch_card_instances_waiting";
export const FETCH_CARD_INSTANCES_SUCCESS = "fetch_card_instances_success";
export const FETCH_CARD_INSTANCES_ERROR = "fetch_card_instances_error";
export const FETCH_CARD_INSTANCES_RESET = "fetch_card_instances_reset";

export const FETCH_CARD_INSTANCE_WAITING = "fetch_card_instance_waiting";
export const FETCH_CARD_INSTANCE_SUCCESS = "fetch_card_instance_success";
export const FETCH_CARD_INSTANCE_ERROR = "fetch_card_instance_error";

export const ADD_CARD_INSTANCE_WAITING = "add_card_instance_waiting";
export const ADD_CARD_INSTANCE_SUCCESS = "add_card_instance_success";
export const ADD_CARD_INSTANCE_ERROR = "add_card_instance_error";
export const ADD_CARD_INSTANCE_RESET = "add_card_instance_reset";

export const EDIT_CARD_INSTANCE_WAITING = "edit_card_instance_waiting";
export const EDIT_CARD_INSTANCE_SUCCESS = "edit_card_instance_success";
export const EDIT_CARD_INSTANCE_ERROR = "edit_card_instance_error";
export const EDIT_CARD_INSTANCE_RESET = "edit_card_instance_reset";

export const DELETE_CARD_INSTANCE_WAITING = "delete_card_instance_waiting";
export const DELETE_CARD_INSTANCE_SUCCESS = "delete_card_instance_success";
export const DELETE_CARD_INSTANCE_ERROR = "delete_card_instance_error";
export const DELETE_CARD_INSTANCE_RESET = "delete_card_instance_reset";

export const FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_WAITING =
  "fetch_card_instances_report_download_waiting";
export const FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_SUCCESS =
  "fetch_card_instances_report_download_success";
export const FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_ERROR =
  "fetch_card_instances_report_download_error";
export const FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_RESET =
  "fetch_card_instances_report_download_reset";

import {
  FETCH_LOCATIONS_WAITING,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_ERROR,
  FETCH_LOCATIONS_RESET,
  FETCH_LOCATION_WAITING,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_ERROR,
  FETCH_LOCATION_RESET,
  EDIT_LOCATION_WAITING,
  EDIT_LOCATION_SUCCESS,
  EDIT_LOCATION_ERROR,
  EDIT_LOCATION_RESET,
  ADD_LOCATION_WAITING,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_ERROR,
  ADD_LOCATION_RESET,
  DELETE_LOCATION_WAITING,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_ERROR,
  DELETE_LOCATION_RESET,
  SELECTED_EDIT_VENUE_LOCATION,
  SELECTED_ADD_VENUE_LOCATION,
  SELECTED_EDIT_SUB_VENUE_LOCATION,
  SELECTED_ADD_SUB_VENUE_LOCATION,
  RESET_SELECTED_EDIT_VENUE_LOCATION,
  RESET_SELECTED_ADD_VENUE_LOCATION,
  RESET_SELECTED_EDIT_SUB_VENUE_LOCATION,
  RESET_SELECTED_ADD_SUB_VENUE_LOCATION,
  FETCH_PICKUP_POINTS_WAITING,
  FETCH_PICKUP_POINTS_SUCCESS,
  FETCH_PICKUP_POINTS_ERROR,
  FETCH_PICKUP_POINTS_RESET,
  ADD_PICKUP_POINT_WAITING,
  ADD_PICKUP_POINT_SUCCESS,
  ADD_PICKUP_POINT_ERROR,
  ADD_PICKUP_POINT_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  operationState: {
    add: 'init',
    delete: 'init',
    update: 'init',
    fetch: 'init',
    locationsFetch: 'init',
    fetchAllPickups: 'init',
  },
  locations: [],
  pickupPoints: [],
  pickup: {},
  selectedEditVenueLocation: '',
  selectedAddVenueLocation: '',
  selectedEditSubVenueLocation: '',
  selectedAddSubVenueLocation: '',
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_LOCATIONS_WAITING: {
      const operationState = {
        ...state.operationState,
        locationsFetch: 'waiting',
      };
      return { ...state, operationState };
    }
    case FETCH_LOCATIONS_SUCCESS: {
      // let locationList = {};
      // action.payload.data.forEach(location =>{
      //   if (location.location_id) {
      //     locationList[location.location_id] = location;
      //   }
      // });
      const operationState = {
        ...state.operationState,
        locationsFetch: 'success',
      };
      return {
        ...state,
        locations: action.payload.data,
        operationState,
        headers: action.payload.headers,
      };
    }
    case FETCH_LOCATIONS_ERROR: {
      const operationState = {
        ...state.operationState,
        locationsFetch: 'error',
      };
      return { ...state, operationState, error: action.payload.error };
    }
    case FETCH_LOCATIONS_RESET: {
      const operationState = {
        ...state.operationState,
        locationsFetch: 'init',
      };
      return { ...state, operationState, headers: null };
    }

    case FETCH_LOCATION_SUCCESS: {
      const operationState = { ...state.operationState, fetch: 'success' };
      return { ...state, location: action.payload.data, operationState };
    }
    case FETCH_LOCATION_WAITING: {
      const operationState = { ...state.operationState, fetch: 'waiting' };
      return { ...state, operationState };
    }
    case FETCH_LOCATION_ERROR: {
      const operationState = { ...state.operationState, fetch: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case FETCH_LOCATION_RESET: {
      const operationState = { ...state.operationState, fetch: 'init' };
      return { ...state, operationState };
    }

    case ADD_LOCATION_SUCCESS: {
      let locationList = {};
      state.locations.forEach(location => {
        if (location.location_id) {
          locationList[location.location_id] = location;
        }
      });
      locationList = {
        ...locationList,
        [action.payload.data.location_id]: action.payload.data,
      };
      locationList = Object.keys(locationList).map(key => locationList[key]);
      const operationState = { ...state.operationState, add: 'success' };
      return {
        ...state,
        locations: locationList,
        location: action.payload.data,
        operationState,
      };
    }
    case ADD_LOCATION_WAITING: {
      const operationState = { ...state.operationState, add: 'waiting' };
      return { ...state, operationState };
    }
    case ADD_LOCATION_ERROR: {
      const operationState = { ...state.operationState, add: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case ADD_LOCATION_RESET: {
      const operationState = { ...state.operationState, add: 'init' };
      return { ...state, operationState };
    }

    case ADD_PICKUP_POINT_WAITING: {
      const operationState = { ...state.operationState, addPickup: 'waiting' };
      return { ...state, operationState };
    }
    case ADD_PICKUP_POINT_SUCCESS: {
      const operationState = {
        ...state.operationState,
        addPickup: 'success',
      };
      const pickup = action.payload.data;
      const pickupPoints = [...state.pickupPoints, pickup];
      return { ...state, operationState, pickup, pickupPoints };
    }
    case ADD_PICKUP_POINT_ERROR: {
      const operationState = { ...state.operationState };
      return {
        ...state,
        operationState,
        addPickup: 'error',
        error: action.payload.error,
      };
    }
    case ADD_PICKUP_POINT_RESET: {
      const operationState = { ...state.operationState, addPickup: 'init' };
      return { ...state, operationState };
    }

    case EDIT_LOCATION_SUCCESS: {
      let locationList = {};
      state.locations.forEach(location => {
        if (location.location_id) {
          locationList[location.location_id] = location;
        }
      });
      locationList = {
        ...locationList,
        [action.payload.data.location_id]: action.payload.data,
      };
      locationList = Object.keys(locationList).map(key => locationList[key]);
      const operationState = { ...state.operationState, update: 'success' };
      return {
        ...state,
        locations: locationList,
        location: action.payload.data,
        operationState,
      };
    }

    case EDIT_LOCATION_WAITING: {
      const operationState = { ...state.operationState, update: 'waiting' };
      return { ...state, operationState };
    }
    case EDIT_LOCATION_ERROR: {
      const operationState = { ...state.operationState, update: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case EDIT_LOCATION_RESET: {
      const operationState = { ...state.operationState, update: 'init' };
      return { ...state, operationState };
    }

    case DELETE_LOCATION_SUCCESS: {
      let locationList = {};
      state.locations.forEach(location => {
        if (location.location_id) {
          locationList[location.location_id] = location;
        }
      });
      delete locationList[action.payload.data.location_id];
      locationList = Object.keys(locationList).map(key => locationList[key]);
      const operationState = { ...state.operationState, delete: 'success' };
      return { ...state, locations: locationList, operationState };
    }
    case DELETE_LOCATION_WAITING: {
      const operationState = { ...state.operationState, delete: 'waiting' };
      return { ...state, operationState };
    }
    case DELETE_LOCATION_ERROR: {
      const operationState = { ...state.operationState, delete: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case DELETE_LOCATION_RESET: {
      const operationState = { ...state.operationState, delete: 'init' };
      return { ...state, operationState };
    }

    case FETCH_PICKUP_POINTS_WAITING: {
      const operationState = {
        ...state.operationState,
        fetchAllPickups: 'waiting',
      };
      return { ...state, operationState };
    }
    case FETCH_PICKUP_POINTS_SUCCESS: {
      const operationState = {
        ...state.operationState,
        fetchAllPickups: 'success',
      };
      return { ...state, pickupPoints: action.payload.data, operationState };
    }
    case FETCH_PICKUP_POINTS_ERROR: {
      const operationState = {
        ...state.operationState,
        fetchAllPickups: 'error',
      };
      return { ...state, error: action.payload.error, operationState };
    }
    case FETCH_PICKUP_POINTS_RESET: {
      const operationState = {
        ...state.operationState,
        fetchAllPickups: 'init',
      };
      return { ...state, operationState };
    }

    case SELECTED_EDIT_VENUE_LOCATION:
      return { ...state, selectedEditVenueLocation: action.payload };
    case RESET_SELECTED_EDIT_VENUE_LOCATION:
      return { ...state, selectedEditVenueLocation: null };
    case SELECTED_ADD_VENUE_LOCATION:
      return { ...state, selectedAddVenueLocation: action.payload };
    case RESET_SELECTED_ADD_VENUE_LOCATION:
      return { ...state, selectedAddVenueLocation: null };
    case SELECTED_EDIT_SUB_VENUE_LOCATION:
      return { ...state, selectedEditSubVenueLocation: action.payload };
    case RESET_SELECTED_EDIT_SUB_VENUE_LOCATION:
      return { ...state, selectedEditSubVenueLocation: null };
    case SELECTED_ADD_SUB_VENUE_LOCATION:
      return { ...state, selectedAddSubVenueLocation: action.payload };
    case RESET_SELECTED_ADD_SUB_VENUE_LOCATION:
      return { ...state, selectedAddSubVenueLocation: null };
    default:
      return state;
  }
}

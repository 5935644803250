import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Table,
  Button,
  Icon,
  Pagination,
  Confirm,
  Menu,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { toast } from 'react-semantic-toasts';
import LotteryPreview from './LotteryPreview';
import { Spinner } from '../Common/Spinner';
import {
  fetchLotteries,
  deleteLottery,
  cancelLottery,
  resetCancelLottery,
  resetDeleteLottery,
  resetFetchLotteries,
  fetchLotteriesReport,
} from '../../Redux/actions';
import { T, getErrorMessage, localDateTime } from '../Common/Helpers';
import ModalWindow, { ModalContext } from '../Common/ModalWindow';

/*const VOTINGS_ALL = true;
const VOTING_PAGING = false;
const VOTING_DETAILS = false;*/

class ListLotteries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmAction: false,
      confirmTitle: '',
      confirmText: '',
    };
    this.deleteLottery = this.deleteLottery.bind(this);
    this.cancelLottery = this.cancelLottery.bind(this);
    this.onCancelConfirm = this.onCancelConfirm.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.sortLotteries = this.sortLotteries.bind(this);
  }

  componentDidMount() {
    // to come back to this after login
    // localStorage.setItem('route', this.props.history.location.pathname);
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.ws_id;
    /*const lang = this.props.auth.lang;
    const all = VOTINGS_ALL;
    const paging = VOTING_PAGING;
    const details = VOTING_DETAILS;*/

    this.props.fetchLotteries({
      authKey,
      ws_id,
      start: 1,
    });

    /*this.props.fetchVotings({
      authKey, ws_id, start: 1, lang, all, paging, details });*/
  }

  componentDidUpdate(prevProps) {
    const { fetchAll } = this.props.lottery.status;
    const prevFetchAll = prevProps.lottery.status.fetchAll;
    if (prevFetchAll !== 'error' && fetchAll === 'error') {
      const errorObj = getErrorMessage(
        this.props.lottery.error,
        T('Error, fetching lotteries')
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }

    // delete lottery
    if (
      this.props.lottery.status.delete === 'success' &&
      prevProps.lottery.status.delete !== 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Lottery deleted successfully'),
        time: 5000,
      });
      this.props.resetDeleteLottery();
    } else if (
      this.props.lottery.status.delete === 'error' &&
      prevProps.lottery.status.delete !== 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.lottery.error,
        T('Error while deleting lottery')
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetDeleteLottery();
    }
    // cancel lottery
    if (
      this.props.lottery.status.cancel === 'success' &&
      prevProps.lottery.status.cancel !== 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Lottery status changed to draft'),
        time: 5000,
      });
      this.props.resetCancelLottery();
    } else if (
      this.props.lottery.status.cancel === 'error' &&
      prevProps.lottery.status.cancel !== 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.lottery.error,
        T('Error while cancelling lottery')
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetCancelLottery();
    }
  }

  onCancelConfirm() {
    this.setState({ confirmAction: false });
  }

  onConfirm() {
    const { type, selectedLottery } = this.state;
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.ws_id;
    if (type === 'delete' && selectedLottery) {
      this.props.deleteLottery(authKey, selectedLottery, ws_id);
    } else if (type === 'cancel' && selectedLottery) {
      this.props.cancelLottery(authKey, selectedLottery, ws_id);
    }
    this.setState({ confirmAction: false });
  }

  getDirection(dirn) {
    return dirn === 'asc' ? 'ascending' : 'descending';
  }

  sortLotteries(column) {
    const { direction, colName } = this.state;
    const authKey = localStorage.getItem('x-auth-key');
    const { searchterm } = this.props.lottery.headers;
    const ws_id = this.props.ws_id;
    if (colName === column) {
      const sortOrder = direction === 'asc' ? 'desc' : 'asc';
      this.props.fetchLotteries({
        authKey,
        ws_id,
        start: 1,
        searchterm,
        column,
        sortOrder,
      });
      this.setState({ direction: sortOrder });
    } else {
      const sortOrder = 'asc';
      this.props.fetchLotteries({
        authKey,
        ws_id,
        start: 1,
        searchterm,
        column,
        sortOrder,
      });
      this.setState({ colName: column, direction: sortOrder });
    }
  }

  handlePaginationChange(e, { activePage }) {
    const authKey = localStorage.getItem('x-auth-key');
    const { searchterm } = this.props.lottery.headers;
    const ws_id = this.props.ws_id;
    this.props.fetchLotteries({
      authKey,
      ws_id,
      start: activePage,
      searchterm,
    });
  }

  navigateToLink = lottery_id => {
    this.props.history.push({ pathname: `/app/lottery/manage/${lottery_id}` });
  };

  downloadReport = (id, type) => {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.ws_id;
    this.props.fetchLotteriesReport(authKey, ws_id, id, type);
  };

  deleteLottery = id => {
    this.setState({
      confirmAction: true,
      selectedLottery: id,
      type: 'delete',
      confirmTitle: 'Delete',
      confirmText: 'Are you sure you want to delete the selected lottery?',
    });
  };

  cancelLottery = id => {
    this.setState({
      confirmAction: true,
      selectedLottery: id,
      type: 'cancel',
      confirmTitle: 'Cancel Draw',
      confirmText: 'Are you sure you want to cancel the selected lottery?',
    });
  };

  renderLotteriesList() {
    const { data } = this.props.lottery;
    const userLang = this.props.auth.lang;
    const ws_id = this.props.ws_id;
    // some locale formatting might be good
    return _.map(data, lo => {
      //const isEditable = lo.lo_status !== 'draw' || lo.lo_status !== 'finished';
      const isEditable = lo.lo_status !== 'finished';
      const canCancel = lo.lo_status !== 'draft';
      //const cursor = isEditable ? { cursor: 'pointer' } : { cursor: 'default' };
      const cursor = { cursor: 'pointer' };
      return (
        <Table.Row
          key={lo.lottery_id}
          onClick={() => this.navigateToLink(lo.lottery_id)}
          style={cursor}
          warning={isEditable}
        >
          <Table.Cell>{T(lo.lottery_id)}</Table.Cell>
          <Table.Cell>{T(lo.lo_name)}</Table.Cell>
          <Table.Cell>
            {lo.lo_draw ? localDateTime(lo.lo_draw, userLang) : ''}
          </Table.Cell>
          <Table.Cell>{T(lo.lo_status)}</Table.Cell>
          <Table.Cell textAlign="right">{lo.participants}</Table.Cell>
          <Table.Cell textAlign="right">{lo.winners}</Table.Cell>
          <Table.Cell textAlign="right">{lo.claimed}</Table.Cell>
          <Table.Cell
            collapsing
            textAlign="right"
            onClick={e => e.stopPropagation()}
          >
            <Button
              icon="file excel"
              label={T('XLS')}
              size="small"
              onClick={() => this.downloadReport(lo.lottery_id, 'xls')}
            />
            {canCancel && isEditable ? (
              <Button
                type="button"
                icon="undo"
                size="small"
                content={T('Cancel Draw')}
                onClick={() => this.cancelLottery(lo.lottery_id)}
              />
            ) : null}
            {isEditable && (
              <Button
                type="button"
                color="red"
                icon="trash"
                size="small"
                content={T('Delete')}
                onClick={() => this.deleteLottery(lo.lottery_id)}
              />
            )}
            <ModalWindow
              header={T('Preview')}
              buttonColor="blue"
              buttonLabel={T('Preview')}
            >
              <ModalContext.Consumer>
                {({ closeModal }) => (
                  <LotteryPreview
                    closeModal={closeModal}
                    lottery_id={lo.lottery_id}
                    ws_id={ws_id}
                  />
                )}
              </ModalContext.Consumer>
            </ModalWindow>
          </Table.Cell>
        </Table.Row>
      );
    });
  }
  render() {
    const { fetchAll } = this.props.lottery.status;
    if (fetchAll === 'waiting' || fetchAll === 'init') {
      return <Spinner />;
    }
    const { direction, colName } = this.state;
    return (
      <React.Fragment>
        <Table sortable compact celled padded stackable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  colName === 'lottery_id' ? this.getDirection(direction) : null
                }
                onClick={() => this.sortLotteries('lottery_id')}
              >
                {T('#')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  colName === 'lo_name' ? this.getDirection(direction) : null
                }
                onClick={() => this.sortLotteries('lo_name')}
              >
                {T('Name')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  colName === 'lo_draw' ? this.getDirection(direction) : null
                }
                onClick={() => this.sortLotteries('lo_draw')}
              >
                {T('Lottery Date')}
              </Table.HeaderCell>
              <Table.HeaderCell>{T('Status')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Participants')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Winners')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Prizes Claimed')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderLotteriesList()}
            <Table.Row>
              <Confirm
                header={T(this.state.confirmTitle)}
                content={T(this.state.confirmText)}
                cancelButton={T('Cancel')}
                confirmButton={T(this.state.confirmTitle)}
                open={this.state.confirmAction}
                onCancel={this.onCancelConfirm}
                onConfirm={this.onConfirm}
                size="tiny"
              />
            </Table.Row>
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="8">
                <Link to="/app/lottery/manage">
                  <Button icon="plus" primary content={T('Add')} />
                </Link>
                {this.props.lottery.headers &&
                  this.props.lottery.headers.total_pages > 1 && (
                    <Menu floated="right" pagination>
                      <Pagination
                        onPageChange={this.handlePaginationChange.bind(this)}
                        defaultActivePage={
                          this.props.lottery.headers.current_page
                        }
                        ellipsisItem={{
                          content: <Icon name="ellipsis horizontal" />,
                          icon: true,
                        }}
                        firstItem={{
                          content: <Icon name="angle double left" />,
                          icon: true,
                        }}
                        lastItem={{
                          content: <Icon name="angle double right" />,
                          icon: true,
                        }}
                        prevItem={{
                          content: <Icon name="angle left" />,
                          icon: true,
                        }}
                        nextItem={{
                          content: <Icon name="angle right" />,
                          icon: true,
                        }}
                        totalPages={this.props.lottery.headers.total_pages}
                      />
                    </Menu>
                  )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ auth, workspace, lottery }) {
  return { auth, lottery, ws_id: workspace.ws_id };
}
export default connect(mapStateToProps, {
  fetchLotteries,
  resetFetchLotteries,
  fetchLotteriesReport,
  deleteLottery,
  resetDeleteLottery,
  cancelLottery,
  resetCancelLottery,
})(withRouter(ListLotteries));

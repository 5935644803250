/* eslint-env browser */
import {
  // ERROR_UNAUTHORIZED,
  // // ERROR_FORBIDDEN,
  // ERROR_RESOURCE_NOT_FOUND,
  // ERROR_EXECUTION_FAILURE,
  ERROR_GATEWAY_TIMEOUT,
  ERROR_GENERIC,
} from '../Types';

function clearCredentials() {
  localStorage.removeItem('x-auth-key');
}

function ErrorAction(code, defaultErrorType) {
  switch (code) {
    // case 401:
    //   //remove from asyncstorage
    //   // clearCredentials();
    //   console.log('Original error: ', defaultErrorType);
    //   // return defaultErrorType;
    //   return ERROR_UNAUTHORIZED;
    //   // case 403:
    // //   //remove from asyncstorage
    // //   clearCredentials();
    // //   return ERROR_FORBIDDEN;
    // case 404:
    //   return ERROR_RESOURCE_NOT_FOUND;
    // case 409:
    //   return ERROR_EXECUTION_FAILURE;
    case 504:
      //remove from asyncstorage
      clearCredentials();
      return ERROR_GATEWAY_TIMEOUT;
    default:
      return defaultErrorType || ERROR_GENERIC;
  }
}

export default ErrorAction;

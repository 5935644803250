import DOMPurify from 'dompurify';
import _ from 'lodash';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
//import 'moment/locale/fi';
import { store } from '../../index';
import { EditorState, Modifier, convertFromHTML, ContentState } from 'draft-js';

const DATE_TIME_FORMAT = 'l HH:mm';
const DD_MM_YYYY_HH_MM = 'DD.MM.YYYY HH:mm';
const DATE_FORMAT = 'l';
const POINTS = 'pts';

export const DEFAULT_SORT_DIRECTIONS = {
  ev_event_start: 'asc',
  ev_name: 'asc',
  event_id: 'desc',
};
export const DEFAULT_TOOLBAR_OPTIONS = {
  options: [
    'inline',
    'blockType',
    'list',
    'textAlign',
    'link',
    'emoji',
    'image',
    'remove',
    'history',
  ],
  blockType: {
    inDropdown: true,
    options: [
      'Normal',
      'H1',
      'H2',
      'H3',
      'H4',
      'H5',
      'H6',
      'Blockquote',
      'Code',
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  inline: {
    options: ['bold', 'italic', 'underline', 'superscript', 'subscript'],
  },
  list: { inDropdown: true },
};
// get a translation
export const T = code => {
  const { translations } = store.getState().translations;
  for (const value of Object.values(translations)) {
    if (code === value.tr_code) {
      return value.tr_text;
    }
  }
  return code;
};

// render active icon
export const StatusIcon = props => {
  if (props.status) {
    //return <Icon color='green' name='check' />;
    return <i className="normal green checkmark icon" />;
  }
  //return <Icon color='red' name='x' />;
  return <i className="normal red x icon" />;
};

export const DeleteIcon = props => {
  if (props.isActive) {
    return '';
  }
  return (
    <Icon
      name="trash alternate"
      size={props.size}
      color="red"
      corner="bottom left"
      onClick={props.onDelete}
    />
  );
};

export const getErrorMessage = (error, defaultMessage) => {
  const errorObj = {
    status: 400,
    message: defaultMessage || 'Error has occured',
  };
  if (error && error.response) {
    const { status, statusText } = error.response;
    errorObj.status = status;
    errorObj.message = statusText;
  }
  return errorObj;
};

export const localDate = (date, userLang) => {
  //const options = { year: 'numeric', month: '2-digit', day: 'numeric' };
  if (!date) {
    return '';
  }
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const localdate = new Date(date);
  const lang =
    userLang ||
    navigator.language.substring(0, 2) ||
    navigator.userLanguage.substring(0, 2);
  return localdate.toLocaleDateString(lang, options);
};

export const localDateTime = (date, userLang) => {
  //const options = { year: 'numeric', month: '2-digit', day: 'numeric' };
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  };
  const localdate = new Date(date);
  const lang =
    userLang ||
    navigator.language.substring(0, 2) ||
    navigator.userLanguage.substring(0, 2);
  return localdate.toLocaleDateString(lang, options);
};

export const formatDateTime = value => {
  if (value === '' || value === null || value === undefined) {
    return '';
  }
  //const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm';
  //return moment(value).format('ddd, DD MMM YYYY HH:mm');
  return moment(value).format(DATE_TIME_FORMAT);
};

export const setEmptyStringToNull = value => {
  if (value === '' || value === undefined) {
    return null;
  }
  return value;
};

export const DDMMYYYYHHmm = value => {
  if (value === '' || value === null || value === undefined) {
    return '';
  }
  return moment(value).format(DD_MM_YYYY_HH_MM);
};

export const dddDMMMYYYY = value => {
  if (value === '' || value === null || value === undefined) {
    return '';
  }
  return moment(value).format(DATE_FORMAT);
};

export const getCurrentDateTime = () =>
  moment(new Date()).format(DATE_TIME_FORMAT);

export const getDateTimeFormat = () => DATE_TIME_FORMAT;

export const getDateFormat = () => DATE_FORMAT;

export const getDecimalSeparator = locale => {
  const numberWithDecimalSeparator = 1.1;
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithDecimalSeparator)
    .find(part => part.type === 'decimal').value;
};

export const convertPriceWithSymbol = (currency = 'EUR', price) => {
  if (!price) {
    return '';
  }
  if (currency === POINTS) {
    return price;
  }
  const convertedPrice = new Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency,
  }).format(parseFloat(price).toFixed(2));
  return convertedPrice;
};

export const convertPrice = (currency = 'EUR', price) => {
  if (!price) {
    return '';
  }
  if (currency === POINTS) {
    return price;
  }
  return parseFloat(price).toFixed(2);
};

// get active application based oon selected workspace
export const getActiveApplication = (auth, workspace, selected_workspace) => {
  const workspaces = _.map(workspace.workspaces, ws => ws);
  const active_workspace = workspaces.find(
    ws => ws.node_collection_id === selected_workspace
  );
  if (active_workspace !== undefined) {
    return auth.apps.find(
      app => app.application_id === active_workspace.cnc_application
    );
  }
  return active_workspace;
};

export const getLanguageObject = (languages, language_id) =>
  _.find(languages, element => element.language_id === language_id);

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateHhMm = val =>
  /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(val);

DOMPurify.addHook(
  'uponSanitizeElement',
  function (currentNode, hookEvent, config) {
    if (!!currentNode.style) {
      currentNode.style.removeProperty('background-color');
      currentNode.style.removeProperty('color');
      currentNode.style.removeProperty('font-family');
      currentNode.style.removeProperty('font-size');
    }
    return currentNode;
  }
);
export const cleanHtml = dirty =>
  DOMPurify.sanitize(dirty, { USE_PROFILES: { html: true } });

export const handlePastedText = (text, html, editorState, onChange) => {
  console.log('handlePastedText', { text, html, editorState, onChange });
  if (html) {
    const clean = cleanHtml(html);
    const blocksFromHTML = convertFromHTML(clean);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    const newContent = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      contentState.getBlockMap()
    );
    onChange(EditorState.push(editorState, newContent, 'insert-fragment'));
  } else {
    const newContent = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text
    );
    onChange(EditorState.push(editorState, newContent, 'insert-fragment'));
  }

  return true;
};

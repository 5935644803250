import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Nav from "../layout/Nav";

import ApplicationList from "../components/Applications/ApplicationList";
import Wizard from "../components/Wizard/Wizard";
import AppShopsParent from "../components/Applications/AppShopsParent";

const ApplicationRoute = () => {
  let { path } = useRouteMatch();

  return (
    <Container fluid style={{ marginBottom: "150px" }}>
      <Nav />
      <Switch>
        <Route name="Application List" path={`${path}/list`} exact>
          <ApplicationList />
        </Route>
        <Route path={`${path}/wizard`} exact>
          <Wizard />
        </Route>
        <Route path={`${path}/shops`} exact>
          <AppShopsParent />
        </Route>
      </Switch>
    </Container>
  );
};

export default ApplicationRoute;

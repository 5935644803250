/* eslint-env browser */
import {
  FETCH_APPLICATIONS_SUCCESS,
  FETCH_APPLICATIONS_WAITING,
  FETCH_APPLICATIONS_ERROR,
  FETCH_APPLICATIONS_RESET,
  EDIT_APPLICATION_WAITING,
  EDIT_APPLICATION_SUCCESS,
  EDIT_APPLICATION_ERROR,
  FETCH_APPLICATIONS_CONTEXTS_WAITING,
  FETCH_APPLICATIONS_CONTEXTS_SUCCESS,
  FETCH_APPLICATIONS_CONTEXTS_ERROR,
  FETCH_APPLICATION_WAITING,
  FETCH_APPLICATION_SUCCESS,
  FETCH_APPLICATION_ERROR,
  ADD_SHOP_TO_APPLICATION_WAITING,
  ADD_SHOP_TO_APPLICATION_SUCCESS,
  ADD_SHOP_TO_APPLICATION_ERROR,
  ADD_SHOP_TO_APPLICATION_RESET,
  REMOVE_SHOP_FROM_APPLICATION_WAITING,
  REMOVE_SHOP_FROM_APPLICATION_SUCCESS,
  REMOVE_SHOP_FROM_APPLICATION_ERROR,
  REMOVE_SHOP_FROM_APPLICATION_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  operationState: {
    add: 'init',
    delete: 'init',
    update: 'init',
    fetch: 'init',
    fetchAll: 'init',
    edit: 'init',
    contextStatus: 'init',
    selectStatus: 'init',
    fetchApp: 'init',
  },
  applications: [],
  appContexts: '',
  application: '',
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_APPLICATION_WAITING: {
      const operationState = { ...state.operationState, fetchApp: 'waiting' };
      return { ...state, operationState };
    }
    case FETCH_APPLICATION_SUCCESS: {
      const operationState = { ...state.operationState, fetchApp: 'success' };
      return { ...state, operationState, application: action.payload.data };
    }
    case FETCH_APPLICATION_ERROR: {
      const operationState = { ...state.operationState, fetchApp: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }

    case FETCH_APPLICATIONS_WAITING: {
      const operationState = { ...state.operationState, fetchAll: 'waiting' };
      return { ...state, operationState };
    }
    case FETCH_APPLICATIONS_SUCCESS: {
      const applications = action.payload.data.items;
      const operationState = { ...state.operationState, fetchAll: 'success' };
      const headers = action.payload.headers;
      return { ...state, operationState, headers, applications };
    }
    case FETCH_APPLICATIONS_ERROR: {
      const operationState = { ...state.operationState, fetchAll: 'error' };
      return {
        ...state,
        operationState,
        applications: [],
        error: action.payload.error,
      };
    }
    case FETCH_APPLICATIONS_RESET: {
      const operationState = { ...state.operationState, fetchAll: 'init' };
      return { ...state, operationState, applications: [] };
    }

    case FETCH_APPLICATIONS_CONTEXTS_WAITING: {
      const operationState = {
        ...state.operationState,
        contextStatus: 'waiting',
      };
      return { ...state, operationState };
    }
    case FETCH_APPLICATIONS_CONTEXTS_SUCCESS: {
      const operationState = {
        ...state.operationState,
        contextStatus: 'success',
      };
      const headers = action.payload.headers;
      return {
        ...state,
        appContexts: action.payload.data,
        operationState,
        headers,
      };
    }
    case FETCH_APPLICATIONS_CONTEXTS_ERROR: {
      const operationState = {
        ...state.operationState,
        contextStatus: 'error',
      };
      return { ...state, operationState, error: action.payload.error };
    }

    case EDIT_APPLICATION_WAITING: {
      const operationState = { ...state.operationState, edit: 'waiting' };
      return { ...state, operationState };
    }
    case EDIT_APPLICATION_SUCCESS: {
      // update local values
      const operationState = { ...state.operationState, edit: 'success' };
      return { ...state, operationState };
    }
    case EDIT_APPLICATION_ERROR: {
      const operationState = { ...state.operationState, edit: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case ADD_SHOP_TO_APPLICATION_WAITING: {
      const operationState = { ...state.operationState, addShop: 'waiting' };
      return { ...state, operationState };
    }
    case ADD_SHOP_TO_APPLICATION_SUCCESS: {
      const operationState = { ...state.operationState, addShop: 'success' };
      const shops = action.payload.data;
      return { ...state, operationState, shops };
    }
    case ADD_SHOP_TO_APPLICATION_ERROR: {
      const operationState = { ...state.operationState, addShop: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case ADD_SHOP_TO_APPLICATION_RESET: {
      const operationState = { ...state.operationState, addShop: 'init' };
      return { ...state, operationState };
    }

    case REMOVE_SHOP_FROM_APPLICATION_WAITING: {
      const operationState = { ...state.operationState, removeShop: 'waiting' };
      return { ...state, operationState };
    }
    case REMOVE_SHOP_FROM_APPLICATION_SUCCESS: {
      const operationState = { ...state.operationState, removeShop: 'success' };
      return { ...state, operationState };
    }
    case REMOVE_SHOP_FROM_APPLICATION_ERROR: {
      const operationState = { ...state.operationState, removeShop: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case REMOVE_SHOP_FROM_APPLICATION_RESET: {
      const operationState = { ...state.operationState, removeShop: 'init' };
      return { ...state, operationState };
    }

    default:
      return state;
  }
}

import {
  ADD_PROMETHEUS_APP_SUCCESS,
  ADD_PROMETHEUS_APP_WAITING,
  ADD_PROMETHEUS_APP_ERROR,
  ADD_PROMETHEUS_APP_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  operationState: {
    add: 'init',
    delete: 'init',
    update: 'init',
    fetch: 'init',
    fetchAll: 'init',
  },
  app: {},
  apps: [],
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case ADD_PROMETHEUS_APP_WAITING: {
      const operationState = { ...state.operationState, add: 'waiting' };
      return { ...state, operationState };
    }
    case ADD_PROMETHEUS_APP_SUCCESS: {
      const app = action.payload.data;
      const appKey = action.payload.data.ap_key;
      const appName = action.payload.data.ap_name;
      const appDefaultDir = action.payload.data.ap_default_dir;
      const operationState = { ...state.operationState, add: 'success' };
      const headers = action.payload.headers;
      const res = {
        ...state,
        appKey,
        appName,
        appDefaultDir,
        operationState,
        headers,
        app,
      };
      // console.log("Result workspaces: ", res);
      return res;
    }
    case ADD_PROMETHEUS_APP_ERROR: {
      const operationState = {
        ...state.operationState,
        add: 'error',
        error: action.payload.error,
      };
      // console.log("Workspaces fetch error");
      return { ...state, operationState, app: {} };
    }
    case ADD_PROMETHEUS_APP_RESET: {
      const operationState = { ...state.operationState, add: 'init' };
      return { ...state, operationState, app: {} };
    }

    default:
      return state;
  }
}

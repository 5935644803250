import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Nav from "../layout/Nav";

import PerformerList from "../components/PerformerTeams/PerformerList";
import PerformerAdd from "../components/PerformerTeams/PerformerAdd";
import PerformerTeamHomePage from "../components/PerformerTeams/PerformerTeamHomePage";

// import Performer from './PerformerTeams/Performer';
// import PlayersList from '../components/PerformerPlayers/PlayersList';
// import PlayerEdit from '../components/PerformerPlayers/PlayerEdit';
// import Player from '../components/PerformerPlayers/Player';
// import PlayerAdd from '../components/PerformerPlayers/PlayerAdd';

const PerformerRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: "150px" }}>
      <Nav />
      <Switch>
        <Route path={`${path}/list`} exact>
          <PerformerList />
        </Route>
        <Route path={`${path}/add`} exact>
          <PerformerAdd />
        </Route>
        <Route path={`${path}/:performer_id/edit`} exact>
          <PerformerTeamHomePage />
        </Route>
        {/*
        <Route path={`${path}/list`} exact component={PerformerList} />
        <Route path={`${path}/players/:performer_id`} component={PlayersList} />
        <Route path={`${path}/player/:performer_id/edit`} component={PlayerEdit} />
        <Route path={`${path}/player/:performer_id/add`} component={PlayerAdd} />
        <Route path={`${path}/player/:performer_id/delete`} component={Player} />
        <Route path={`${match.path}/:performer_id`} component={Performer} />
      */}
      </Switch>
    </Container>
  );
};
export default PerformerRoute;

import React from 'react';
import { Container } from 'semantic-ui-react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Nav from '../layout/Nav';

import AppShopSettingsHome from '../components/AppShop/AppShopSettingsHome';
import Sales from '../components/Shop/Sales';
import WaiterCalls from '../components/Shop/WaiterCalls';
import ShopContainer from '../components/Shop/ShopContainer';
import ShopList from '../components/Shop/ShopList';
import ShopConfigContainer from '../components/Shop/ShopConfigContainer';

const ShopRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: '150px' }}>
      <Nav />
      <Switch>
        <Route path={`${path}/home`} exact>
          <AppShopSettingsHome />
        </Route>
        <Route path={`${path}/waitercalls`} exact>
          <WaiterCalls />
        </Route>
        <Route path={`${path}/sales`} exact>
          <Sales />
        </Route>{' '}
        <Route path={`${path}/manageshop/config/:shopId`} exact>
          <ShopConfigContainer />
        </Route>
        <Route path={`${path}/manageshop/:shopId`} exact>
          <ShopContainer />
        </Route>
        <Route path={`${path}/manageshop`} exact>
          <ShopContainer />
        </Route>
        <Route path={`${path}/list`} exact>
          <ShopList />
        </Route>
      </Switch>
    </Container>
  );
};

export default ShopRoute;

export const FETCH_LOTTERY_PRIZES_SUCCESS = "fetch_lottery_prizes_success";
export const FETCH_LOTTERY_PRIZES_WAITING = "fetch_lottery_prizes_waiting";
export const FETCH_LOTTERY_PRIZES_ERROR = "fetch_lottery_prizes_error";
export const FETCH_LOTTERY_PRIZES_RESET = "fetch_lottery_prizes_reset";

export const FETCH_LOTTERY_PRIZE_SUCCESS = "fetch_lottery_prize_success";
export const FETCH_LOTTERY_PRIZE_WAITING = "fetch_lottery_prize_waiting";
export const FETCH_LOTTERY_PRIZE_ERROR = "fetch_lottery_prize_error";
export const FETCH_LOTTERY_PRIZE_RESET = "fetch_lottery_prize_reset";

export const ADD_LOTTERY_PRIZE_SUCCESS = "add_lottery_prize_success";
export const ADD_LOTTERY_PRIZE_WAITING = "add_lottery_prize_waiting";
export const ADD_LOTTERY_PRIZE_ERROR = "add_lottery_prize_error";
export const ADD_LOTTERY_PRIZE_RESET = "add_lottery_prize_reset";

export const EDIT_LOTTERY_PRIZE_SUCCESS = "edit_lottery_prize_success";
export const EDIT_LOTTERY_PRIZE_WAITING = "edit_lottery_prize_waiting";
export const EDIT_LOTTERY_PRIZE_ERROR = "edit_lottery_prize_error";
export const EDIT_LOTTERY_PRIZE_RESET = "edit_lottery_prize_reset";

// export const DELETE_LOTTERY_PRIZE_SUCCESS = 'delete_lottery_prize_success';
// export const DELETE_LOTTERY_PRIZE_WAITING = 'delete_lottery_prize_waiting';
// export const DELETE_LOTTERY_PRIZE_ERROR = 'delete_lottery_prize_error';
// export const DELETE_LOTTERY_PRIZE_RESET = 'delete_lottery_prize_reset';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fi';
import {
  Table,
  Confirm,
  Message,
  Icon,
  Header,
  Button,
} from 'semantic-ui-react';
import { Spinner } from '../Common/Spinner';
import { T, getErrorMessage } from '../Common/Helpers';
import {
  fetchCampaigns,
  resetFetchCampaigns,
  deleteCampaign,
} from '../../Redux/actions';

const INITIAL_EDIT_CODE = {
  edit_id: null,
  edit_code: '',
  edit_name: '',
  edit_valid_from: '',
  edit_valid_to: '',
};

class ListCampaigns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEditMode: false,
      sortByColumn: 'campaign_id',
      direction: 'desc',
      deleteCampaignId: null,
      deleteDialogOpen: false,
      ...INITIAL_EDIT_CODE,
    };
  }

  reloadCampaigns() {
    const authKey = localStorage.getItem('x-auth-key');
    const { sortByColumn, direction } = this.state;
    const { activeWorkspace } = this.props;
    const { node_collection_id, cnc_application } = activeWorkspace;

    this.props.fetchCampaigns({
      authKey,
      ws_id: node_collection_id,
      sortByColumn,
      direction,
      application_id: cnc_application,
    });
  }

  componentDidMount() {
    this.reloadCampaigns();
  }

  componentDidUpdate(prevProps) {
    // console.log('Campaigns props', this.props);
    if (
      (prevProps.campaign.addCampaignStatus !== 'success' &&
        this.props.campaign.addCampaignStatus === 'success') ||
      (prevProps.campaign.editCampaignStatus !== 'success' &&
        this.props.campaign.editCampaignStatus === 'success') ||
      (prevProps.campaign.deleteCampaignStatus !== 'success' &&
        this.props.campaign.deleteCampaignStatus === 'success')
    ) {
      this.reloadCampaigns();
      this.setState({ isEditMode: false });
    }
  }

  navigateToLink(campaign) {
    this.props.history.push({
      pathname: `/app/campaign/manage/${campaign.campaign_id}`,
    });
  }

  renderCampaigns(isWaiting) {
    const { campaign } = this.props;
    const { campaigns } = campaign;

    return (
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{T('Nimi')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Tulee näkyviin')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Poistuu')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Alkaen')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Päättyen')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Koodi (kampanja)')}</Table.HeaderCell>
            <Table.HeaderCell collapsing />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {campaigns.map(cp => (
            <Table.Row
              key={cp.campaign_id}
              style={{ cursor: 'pointer' }}
              onClick={e => {
                this.navigateToLink(cp);
              }}
            >
              <Table.Cell>{cp.cp_title}</Table.Cell>
              <Table.Cell>
                {cp.cp_visible_from
                  ? moment(cp.cp_visible_from).format('l')
                  : 'Heti'}
              </Table.Cell>
              <Table.Cell>
                {cp.cp_visible_to ? moment(cp.cp_visible_to).format('l') : '-'}
              </Table.Cell>
              <Table.Cell>
                {cp.cp_valid_from
                  ? moment(cp.cp_valid_from).format('l')
                  : 'Heti'}
              </Table.Cell>
              <Table.Cell>
                {cp.cp_valid_to ? moment(cp.cp_valid_to).format('l') : '-'}
              </Table.Cell>
              <Table.Cell>
                {cp.campaigncode ? cp.campaigncode.cc_code : ''}
              </Table.Cell>
              <Table.Cell
                collapsing
                singleLine
                onClick={e => e.stopPropagation()}
                style={{ cursor: 'default' }}
              >
                <Button
                  icon
                  primary
                  disabled={isWaiting}
                  onClick={() => this.navigateToLink(cp)}
                >
                  <Icon name="edit" />
                  {T('Edit')}
                </Button>
                <Button
                  disabled={isWaiting}
                  negative
                  onClick={e => {
                    e.preventDefault();
                    this.setState({
                      deleteCampaignId: cp.campaign_id,
                      deleteDialogOpen: true,
                    });
                  }}
                >
                  <Icon name="trash alternate" />
                  {T('Delete')}
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  handleDismiss() {
    this.props.resetFetchCampaigns();
  }

  render() {
    const { campaignsStatus, error } = this.props.campaign;

    let errorMsg = null;
    if (campaignsStatus === 'error') {
      const errorObj = getErrorMessage(error);
      errorMsg = (
        <Message
          negative
          onDismiss={() => {
            this.handleDismiss();
          }}
          icon="exclamation triangle"
          header={`${T('Error')} ${errorObj.status}`}
          content={T(errorObj.message)}
        />
      );
    }

    const isWaiting = campaignsStatus == 'waiting';

    return (
      <>
        {errorMsg}
        <Header>{T('Etukupongit')}</Header>
        {isWaiting && <Spinner />}
        {!isWaiting && this.renderCampaigns(isWaiting)}
        <Button
          disabled={isWaiting}
          primary
          onClick={() =>
            this.props.history.push({ pathname: '/app/campaign/manage/new' })
          }
        >
          <Icon name="add" />
          {T('Add')}
        </Button>
        <Confirm
          open={this.state.deleteDialogOpen}
          cancelButton={T('Cancel')}
          header={T('Oletko varma?')}
          content={T('Oletko varma, että haluat poistaa tämän etukupongin?')}
          onCancel={() => this.setState({ deleteDialogOpen: false })}
          onConfirm={() => {
            const authKey = localStorage.getItem('x-auth-key');
            const { activeWorkspace } = this.props;
            const { node_collection_id, cnc_application } = activeWorkspace;
            const campaign_id = this.state.deleteCampaignId;

            this.props.deleteCampaign({
              campaign_id,
              authKey,
              ws_id: node_collection_id,
              application_id: cnc_application,
            });

            this.setState({ deleteDialogOpen: false });
          }}
        />
      </>
    );
  }
}

function mapStateToProps({ campaign, auth, workspace }) {
  return {
    campaign: campaign,
    auth,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
  };
}
export default connect(mapStateToProps, {
  fetchCampaigns,
  resetFetchCampaigns,
  deleteCampaign,
})(withRouter(ListCampaigns));

import React, { Component } from 'react';
import { Segment, Button, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { T } from '../Common/Helpers';
import { addPickupPoint } from '../../Redux/actions';

class PickupPointAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pl_name: '',
      pl_address: '',
      pl_country: '',
      pl_city: '',
      pl_zip: '',
      pl_phone: '',
      pl_email: '',
    };
  }

  onValueChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  onCancel() {
    // this.props.history.push('/app/venues/list');
    this.props.closeModal();
  }

  onValidSubmit = e => {
    const {
      pl_name,
      pl_address,
      pl_country,
      pl_city,
      pl_zip,
      pl_phone,
      pl_email,
    } = this.state;
    e.preventDefault();
    e.stopPropagation();
    const price_list_id = this.props.price_list_id;
    const ws_id = this.props.ws_id;
    const pl_company = this.props.pl_company;
    const inputValues = {
      pl_name,
      pl_address,
      pl_country,
      pl_city,
      pl_zip,
      pl_company,
      pl_public: true,
      pl_parent: this.props.pl_parent,
      pl_phone,
      pl_email,
    };
    const authKey = localStorage.getItem('x-auth-key');
    this.props.addPickupPoint(authKey, inputValues, price_list_id, ws_id);
    this.props.closeModal();
  };

  getCountryList() {
    const selectLabel = {
      country_iso2: '',
      value: '',
      country_name: T('Select a Country'),
    };
    const countriesProp = this.props.countries.countries;
    if (typeof countriesProp === 'object' && countriesProp.length > 0) {
      const countries = this.props.countries.countries.slice();
      countries.unshift(selectLabel);
      return _.map(countries, option => ({
        flag: option.country_iso2.toLowerCase(),
        text: option.country_name,
        value: option.id,
      }));
    }
    return selectLabel;
  }

  render() {
    return (
      <Segment.Group horizontal>
        <Segment>
          <Form onSubmit={this.onValidSubmit.bind(this)}>
            <Form.Group>
              <Form.Input
                required
                name="pl_name"
                label={T('Name')}
                width={6}
                value={this.state.pl_name}
                onChange={this.onValueChange}
              />
              <Form.Input
                required
                name="pl_address"
                label={T('Address')}
                width={6}
                value={this.state.pl_address}
                onChange={this.onValueChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                required
                name="pl_zip"
                label={T('Zip')}
                width={6}
                type="number"
                value={this.state.pl_zip}
                onChange={this.onValueChange}
              />
              <Form.Input
                required
                name="pl_city"
                label={T('City')}
                width={6}
                value={this.state.pl_city}
                onChange={this.onValueChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Select
                selection
                search
                required
                name="pl_country"
                label={T('Country')}
                width={6}
                value={this.state.pl_country}
                options={this.getCountryList()}
                onChange={this.onValueChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                name="pl_phone"
                label={T('Phone')}
                width={6}
                value={this.state.pl_phone}
                onChange={this.onValueChange}
              />
              <Form.Input
                name="pl_email"
                label={T('Email')}
                width={6}
                value={this.state.pl_email}
                onChange={this.onValueChange}
              />
            </Form.Group>
            <Button
              content={T('Save')}
              color="green"
              icon="save"
              type="submit"
            />
            <Button
              type="button"
              color="black"
              content={T('Cancel')}
              onClick={() => this.onCancel()}
            />
          </Form>
        </Segment>
      </Segment.Group>
    );
  }
}

function mapStateToProps({ countries, workspace }) {
  return {
    countries,
    pl_parent: workspace.workspaces[workspace.activeWorkspace.id].cnc_place,
  };
}
export default connect(mapStateToProps, { addPickupPoint })(PickupPointAdd);

import axios from 'axios';
import config from '../../Config/AEConfig';
import ErrorAction from './ErrorAction';

import {
  ADD_PARTICIPANTS_TO_EVENT_WAITING,
  ADD_PARTICIPANTS_TO_EVENT_SUCCESS,
  ADD_PARTICIPANTS_TO_EVENT_ERROR,
  SET_EVENTPERFORMER_SUCCESS,
  FETCH_EVENT_SUCCESS,
} from '../Types';

const MODULE_ID = 7;
/*
 ****************************
 *  Read/Write Home/Visitor *
 **************************** */
//To do: Request Backend to support addition of multiple performers to an event
//eg. Athelets competing for 800M event
export function addParticipantsToEvent(
  authKey,
  eventId,
  performerIds,
  position,
  ws_id
) {
  return dispatch => {
    dispatch({ type: ADD_PARTICIPANTS_TO_EVENT_WAITING });
    const values = {
      ep_order: null,
      ep_performer: performerIds,
      ep_position: position,
    };
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .post(
        `${config.BASE_URL}/event/${eventId}/performer?module_id=${MODULE_ID}&ws_id=${ws_id}`,
        values
      )
      .then(response =>
        dispatch({ type: ADD_PARTICIPANTS_TO_EVENT_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_PARTICIPANTS_TO_EVENT_ERROR)
          : ADD_PARTICIPANTS_TO_EVENT_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function setEventPerformer(
  authKey,
  event_id,
  performer,
  position,
  old_id,
  ws_id
) {
  const values = {
    ep_order: null,
    ep_performer: performer,
    ep_position: position,
  };
  let request;
  axios.defaults.headers.common['x-auth-key'] = authKey;
  if (position === 'DELETE' && old_id === undefined) {
    // Delete old
    axios.delete(
      `${config.BASE_URL}/performer/${performer}/event/${event_id}?module_id=7&ws_id=${ws_id}`
    );
  } else {
    // Delete old, Add new
    if (old_id !== undefined && old_id !== null) {
      axios.delete(
        `${config.BASE_URL}/performer/${old_id}/event/${event_id}?module_id=7`
      );
    }
    if (performer !== null) {
      request = axios.post(
        `${config.BASE_URL}/event/${event_id}/performer?module_id=7`,
        values
      );
    }
  }
  return {
    type: SET_EVENTPERFORMER_SUCCESS,
    payload: request,
  };
}

export const getEventPerformer = (authKey, event_id, ws_id) => dispatch => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  //dispatch({ type: FETCH_EVENT_INIT, payload: response });
  axios
    .get(`${config.BASE_URL}/event/${event_id}&module_id=7&ws_id=${ws_id}`)
    .then(response => {
      dispatch({ type: FETCH_EVENT_SUCCESS, payload: response });
    })
    .catch(error => {
      // const type = error.response ? ErrorAction(error.response.status, ADD_PARTICIPANTS_TO_EVENT_ERROR) : ADD_PARTICIPANTS_TO_EVENT_ERROR;
      dispatch({ type: Error(error.response.status), payload: error });
    });
};

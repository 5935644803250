import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { fetchTranslations } from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import ForgotPassword from '../Auth/ForgotPassword';
import ResetPassword from '../Auth/ResetPassword';
import AddPersonConfirmation from '../Persons/AddPersonConfirmation';
import Login from '../Auth/Login';

class Public extends Component {
  componentDidMount() {
    this.reloadTranslations();
  }

  componentDidUpdate(prevProps) {
    this.reloadTranslations();
  }

  reloadTranslations() {
    const { status, translations } = this.props.translations;
    const translationsFetched = status === 'success' || translations.length > 0;
    if (!translationsFetched && status !== 'waiting' && status !== 'error') {
      console.log('foo', { status });
      this.props.fetchTranslations();
    }
  }

  render() {
    const { status, translations } = this.props.translations;

    if (status === 'error') {
      console.error(
        'Failed to fetch translations.',
        this.props.translations.error
      );
      return (
        <Message negative>
          <Message.Header>Failed to fetch translations</Message.Header>
          <p>{this.props.translations.error.message}</p>
        </Message>
      );
    }

    const translationsFetched = status === 'success' || translations.length > 0;

    if (!translationsFetched) {
      return <Spinner />;
    }

    return (
      <Switch>
        <Route path="/auth/login">
          <Login />
        </Route>
        <Route path="/auth/password">
          <ForgotPassword />
        </Route>
        <Route path="/auth/reset/:key/:resettype?">
          <ResetPassword />
        </Route>
        <Route path="/auth/confirm/:email">
          <AddPersonConfirmation />
        </Route>
      </Switch>
    );
  }
}

function mapStateToProps({ translations }) {
  return { translations };
}

export default connect(mapStateToProps, { fetchTranslations })(
  withRouter(Public)
);

import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
// import { HashLink as Link } from 'react-router-hash-link';
import { T } from "../Common/Helpers";

const menuStyle = {
  display: "block",
  width: "200px",
  height: "45px",
  lineHeight: "45px",
  verticalAlign: "middle",
  cursor: "pointer",
  background: "#F0F0F0",
  paddingLeft: "20px",
  fontFamily: "Nunito",
  fontWeight: "600px",
  fontSize: "15px",
  borderBottom: "1px solid #A0A0A0",
  borderLeft: "10px solid #CBA84A",
};
class MenuItemBlock extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.iconProps.disabled !== nextProps.iconProps.disabled;
  }
  render() {
    const { labelText, iconProps, activeItem } = this.props;
    const divStyle = activeItem
      ? {
          color: "metalblue",
          paddingLeft: "20px",
          borderLeft: "10px solid #CBA84A",
        }
      : { color: "black" };
    // console.log('MENU Item Props: ', this.props);
    // const index = window.location.href.indexOf('#');
    // const path = index !== -1 ? window.location.href.substring(0, index) : window.location.href;
    return (
      // <Link
      // to={`#${this.props.linkId}`}
      // >
      <div
        style={{
          ...menuStyle,
          ...divStyle,
        }}
        onClick={this.props.onClick}
      >
        <Icon
          name={iconProps.iconName}
          disabled={iconProps.disabled}
          color={iconProps.color}
          style={{ paddingRight: "10px" }}
        />
        <p style={{ display: "inline-block" }}>{T(labelText)}</p>
      </div>
      // </Link>
    );
  }
}
MenuItemBlock.propTypes = {
  iconProps: PropTypes.exact({
    color: PropTypes.string,
    iconName: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  labelText: PropTypes.string,
  linkId: PropTypes.string,
  id: PropTypes.string,
  containerId: PropTypes.string,
  onClick: PropTypes.func,
};

export default MenuItemBlock;

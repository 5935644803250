import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import {
  fetchCardInstances,
  resetDeleteCardInstance,
} from "../../Redux/actions";

import CardInstanceList from "../CardInstance/CardInstanceList";
import { T, getErrorMessage } from "../Common/Helpers";

const CARD_TYPE = "seasoncard";
const CARD_INSTANCE_DELETE_SUCCESS = "Season cardholder deleted successfully";
const LIST_HEADER = "Season Cardholders";
const VALID_HEADER = "Valid Season Card";
const CONFIRM_DELETE_HEADER = "Delete Season Cardholder";
const URL = "/app/ticketing/seasoncard";
const MODULE_ID = 37;

class SeasonCardList extends Component {
  componentDidMount() {
    const authKey = localStorage.getItem("x-auth-key");
    const ws_id = this.props.ws_id;
    const application_id = this.props.activeWorkspace.cnc_application;

    if (
      this.props.cardinstances.editCardInstanceStatus === "init" ||
      this.props.cardinstances.addCardInstancesStatus === "init"
    ) {
      this.props.fetchCardInstances({
        moduleId: MODULE_ID,
        authKey,
        ws_id,
        application_id,
        cardType: CARD_TYPE,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== "error" && fetchCardInstances === "error") {
      const errorObj = getErrorMessage(
        this.props.cardinstances.error,
        T("Error, fetching season card holders")
      );
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
    }

    if (this.props.cardinstances.deleteCardInstanceStatus === "success") {
      toast({
        type: "success",
        title: T("Success"),
        description: T(CARD_INSTANCE_DELETE_SUCCESS),
        time: 5000,
      });
      this.props.resetDeleteCardInstance();
      const authKey = localStorage.getItem("x-auth-key");
      const ws_id = this.props.ws_id;
      const application_id = this.props.activeWorkspace.cnc_application;
      this.props.fetchCardInstances({
        moduleId: MODULE_ID,
        authKey,
        ws_id,
        application_id,
        cardType: CARD_TYPE,
      });
    } else if (this.props.cardinstances.deleteCardInstancesStatus === "error") {
      const errorObj = getErrorMessage(
        this.props.cardinstances.error,
        T("Error while deleting seson card holder")
      );
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  render() {
    const params = {
      wsId: this.props.ws_id,
      applicationId: this.props.activeWorkspace.cnc_application,
      cardType: CARD_TYPE,
      listHeader: LIST_HEADER,
      validHeader: VALID_HEADER,
      confirmDeleteHeader: CONFIRM_DELETE_HEADER,
      url: URL,
      moduleId: MODULE_ID,
    };

    if (
      this.props.cardinstances &&
      this.props.cardinstances.cardInstancesStatus === "success"
    ) {
      return (
        <CardInstanceList
          cardinstances={this.props.cardinstances.data}
          history={this.props.history}
          params={params}
        />
      );
    }
    return null;
  }
}

function mapStateToProps({ workspace, cardinstances }) {
  return {
    workspace,
    cardinstances,
    ws_id: workspace.ws_id,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
  };
}

export default connect(mapStateToProps, {
  fetchCardInstances,
  resetDeleteCardInstance,
})(SeasonCardList);

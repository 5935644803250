import React from "react";
import { Container } from "semantic-ui-react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Nav from "../layout/Nav";

import VotingList from "../components/Voting/VotingList";
import ManageVoting from "../components/Voting/ManageVoting";

const VotingRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: "150px" }}>
      <Nav />
      <Switch>
        <Route path={`${path}/list`} exact>
          <VotingList />
        </Route>
        <Route path={`${path}/manage/:id`} exact>
          <ManageVoting />
        </Route>
        <Route path={`${path}/manage`} exact>
          <ManageVoting />
        </Route>
      </Switch>
    </Container>
  );
};

export default VotingRoute;

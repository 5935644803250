import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  AUTH_WAITING,
  AUTH_SUCCESS,
  AUTH_RESET,
  AUTH_ERROR,
  AUTH_CHECK_WAITING,
  AUTH_CHECK_SUCCESS,
  AUTH_CHECK_ERROR,
  ERROR_GATEWAY_TIMEOUT,
  SET_LANGUAGE_PROFILE,
} from '../Types';

// import ErrorAction from './ErrorAction';

export function authUser(values) {
  const username = values.username;
  const password = values.password;
  const instance = axios.create({
    baseURL: config.BASE_URL,
    auth: {
      username,
      password,
    },
  });

  return function (dispatch) {
    dispatch({ type: AUTH_WAITING });
    return instance
      .get('/login')
      .then(data => {
        dispatch({
          type: AUTH_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: AUTH_ERROR,
          payload: { error },
        });
      });
  };
}

export function checkAuthKey(authKey) {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return function (dispatch) {
    dispatch({ type: AUTH_CHECK_WAITING });
    return axios
      .get(`${config.BASE_URL}/auth`)
      .then(data => {
        dispatch({
          type: AUTH_CHECK_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        // console.log('Auth Error: ', error, error.response);
        const type =
          error.response && error.response.status === 504
            ? ERROR_GATEWAY_TIMEOUT
            : AUTH_CHECK_ERROR;
        dispatch({
          type,
          payload: error,
        });
      });
  };
}

export function authReset() {
  return {
    type: AUTH_RESET,
  };
}

export function logoutUser(authKey) {
  return dispatch => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .get(`${config.BASE_URL}/logout`)
      .then(response =>
        dispatch({
          type: AUTH_RESET,
          payload: response,
        })
      )
      .catch(error =>
        dispatch({
          type: AUTH_ERROR,
          payload: { error },
        })
      );
  };
}
export function setLanguage({ lang }) {
  return { type: SET_LANGUAGE_PROFILE, payload: lang };
}

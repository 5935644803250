import React, { Component } from 'react';
import FileSaver from 'file-saver';
import { toast } from 'react-semantic-toasts';
import {
  Header,
  Message,
  Segment,
  Tab,
  Button,
  Table,
  Menu,
  Dropdown,
} from 'semantic-ui-react';
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  BarChart,
  LabelList,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

import moment from 'moment';
import { connect } from 'react-redux';
import {
  fetchEventStatistic,
  resetFetchEventStatistic,
  downloadEventStatistic,
  resetDownloadEventStatistic,
} from '../../../Redux/actions';

import SospaReportDateRange from '../Common/SospaReportDateRange';

import { Spinner } from '../../Common/Spinner';

import { T, getErrorMessage } from '../../Common/Helpers';

const DD_MM_YYYY = 'DD-MM-YYYY';
const YYYY_MM_DD = 'YYYY-MM-DD';
const barColors = [
  '#d5652c',
  '#79bee8',
  '#608eb8',
  '#c6ddf7',
  '#4d4d4c',
  '#c2146b',
  '#efc144',
  '#8884d8',
];
const barColors2 = [
  '#d5652c',
  '#79bee8',
  '#608eb8',
  '#c6ddf7',
  '#bfbfbf',
  '#c2146b',
  '#efc144',
  '#8884d8',
];

const STATISTIC_TYPES_BASIC = [
  {
    name: 'Tehtävät tunneittain',
    type: 'Hour',
    options: {
      showPercentCheckbox: false,
      showHideTurkuCheckbox: false,
      showHideOuluCheckbox: false,
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: false,
      onlyDutyHours: false,
      displayPlaceFilter: false,
      displayClientPlaces: false,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: false,
      onlyDutyHours: false,
    },
  },
  {
    name: 'Tehtävät kunnittain',
    type: 'Location',
    options: {
      splittable: 'Maks. kuntaa per graafi',
      showPercentCheckbox: false,
      showHideZeroCheckbox: true,
      showHideTurkuCheckbox: false,
      showHideOuluCheckbox: true,
      displayPlaceFilter: false,
      displayClientPlaces: false,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: false,
      onlyDutyHours: false,
    },
  },
];
const STATISTIC_TYPES_EXTENDED = [
  {
    name: 'Yhteydenotot virka-ajan ulkopuolella',
    type: 'ContactTimes',
    options: {
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: false,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [],
      place_ids: [],
      event_place_ids: [],
    },
  },
  {
    name: 'Yhteydenottojen jakautuminen arki- ja pyhäöihin',
    type: 'Night',
    options: {
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: false,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [],
      place_ids: [],
      event_place_ids: [],
    },
  },
  {
    name: 'Yhteydenotot kotikunnittain (Varsinais-Suomi + muut)',
    type: 'HomeLocation',
    options: {
      splittable: 'Maks. kuntaa per graafi',
      showPercentCheckbox: true,
      showHideZeroCheckbox: true,
      showHideTurkuCheckbox: true,
      showHideOuluCheckbox: false,
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: true,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [
        275, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263,
        264, 265, 266, 268, 267, 269, 270, 271, 272, 273, 274, 276, 277,
      ],
      place_ids: [],
      event_place_ids: [],
    },
  },
  {
    name: 'Yhteydenotot kotikunnittain (Varsinais-Suomi ilman Turkua + muut)',
    type: 'HomeLocation',
    options: {
      splittable: 'Maks. kuntaa per graafi',
      showPercentCheckbox: true,
      showHideZeroCheckbox: true,
      showHideTurkuCheckbox: true,
      showHideOuluCheckbox: false,
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: true,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: true,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [
        275, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263,
        264, 265, 266, 268, 267, 269, 270, 271, 272, 273, 274, 276, 277,
      ],
      place_ids: [],
      event_place_ids: [],
    },
  },
  {
    name: 'Yhteydenotot koti- ja tapahtumakunnittain',
    type: 'ClientMunicipality',
    options: {
      splittable: 'Maks. kuntaa per graafi',
      showHideZeroCheckbox: true,
      showHideTurkuCheckbox: true,
      showHideOuluCheckbox: false,
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: true,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [
        275, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263,
        264, 265, 266, 268, 267, 269, 270, 271, 272, 273, 274, 276, 277,
      ],
      place_ids: [],
      event_place_ids: [],
    },
  },
  {
    name: 'Tehdyt toimenpiteet kotikunnittain (Varsinais-Suomi + muut)',
    type: 'Municipality',
    options: {
      splittable: 'Maks. kuntaa per graafi',
      showHideZeroCheckbox: true,
      showHideTurkuCheckbox: true,
      showHideOuluCheckbox: false,
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: true,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [
        275, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263,
        264, 265, 266, 268, 267, 269, 270, 271, 272, 273, 274, 276, 277,
      ],
      place_ids: [],
      event_place_ids: [],
    },
  },
  {
    name: 'Tehdyt toimenpiteet kotikunnittain (Varsinais-Suomi ilman Turkua + muut)',
    type: 'Municipality',
    options: {
      splittable: 'Maks. kuntaa per graafi',
      showHideZeroCheckbox: true,
      showHideTurkuCheckbox: true,
      showHideOuluCheckbox: false,
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: true,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: true,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [
        275, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263,
        264, 265, 266, 268, 267, 269, 270, 271, 272, 273, 274, 276, 277,
      ],
      place_ids: [],
      event_place_ids: [],
    },
  },
  {
    name: 'Tehdyt toimenpiteet kuukausittain',
    type: 'Month',
    options: {
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: false,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [],
      place_ids: [],
      event_place_ids: [],
    },
  },
  {
    name: 'Yhteydenotot kiireellisyysluokituksen mukaan kotikunnittain (Varsinais-Suomi + muut)',
    type: 'Triages',
    options: {
      splittable: 'Maks. kuntaa per graafi',
      showHideZeroCheckbox: true,
      showHideTurkuCheckbox: true,
      showHideOuluCheckbox: false,
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: true,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [
        275, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263,
        264, 265, 266, 268, 267, 269, 270, 271, 272, 273, 274, 276, 277,
      ],
      place_ids: [],
      event_place_ids: [],
    },
  },
  {
    name: 'Yhteydenotot kiireellisyysluokituksen mukaan kotikunnittain (Varsinais-Suomi ilman Turkua + muut)',
    type: 'Triages',
    options: {
      splittable: 'Maks. kuntaa per graafi',
      showHideZeroCheckbox: true,
      showHideTurkuCheckbox: true,
      showHideOuluCheckbox: false,
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: true,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: true,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [
        275, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263,
        264, 265, 266, 268, 267, 269, 270, 271, 272, 273, 274, 276, 277,
      ],
      place_ids: [],
      event_place_ids: [],
    },
  },
  {
    name: 'Tehtäväkoodit',
    type: 'EventCodes',
    options: {
      splittable: 'Maks. koodia per graafi',
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: false,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [],
      place_ids: [],
      event_place_ids: [],
    },
  },
  // {
  //   name: 'Yhteydenottajatahot',
  //   type: 'Sources',
  //   options: { showRemoveDutyHoursCheckbox: true, displayPlaceFilter: false },
  // },
  {
    name: 'Yhteydenottajatahot ja käytetty aika tunteina',
    type: 'SourceTimes',
    options: {
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: false,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [],
      place_ids: [],
      event_place_ids: [],
    },
  },
  // {
  //   name: 'Yhteydenottotavat',
  //   type: 'ContactMethods',
  //   options: { showRemoveDutyHoursCheckbox: true, displayPlaceFilter: false },
  // },
  {
    name: 'Yhteydenottotavat ja käytetty aika tunteina',
    type: 'ContactMethodTimes',
    options: {
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: false,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [],
      place_ids: [],
      event_place_ids: [],
    },
  },

  {
    name: 'Virka-ajalta virka-ajan ulkopuolelle siirtyneet yhteydenotot/lkm',
    type: 'MovedFromDutyHours',
    options: {
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: false,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [],
      place_ids: [],
      event_place_ids: [],
    },
  },
  {
    name: 'Virka-ajalta virka-ajan ulkopuolelle siirtyneet yhteydenotot/aika tunteina',
    type: 'MovedFromDutyHoursHR',
    options: {
      showRemoveDutyHoursCheckbox: true,
      displayPlaceFilter: false,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [],
      place_ids: [],
      event_place_ids: [],
    },
  },
  {
    name: 'Yhteydenotot ja niihin käytetty aika kuukausittain',
    type: 'EventsPerMonth',
    options: {
      showRemoveDutyHoursCheckbox: true,
      displayPlaceType: false,
      displayClientPlaces: true,
    },
    defaults: {
      hideZeros: false,
      hideTurku: false,
      hideOulu: false,
      removeDutyHours: true,
      onlyDutyHours: false,
      display_place_ids: [],
      place_ids: [],
      event_place_ids: [],
    },
  },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="intro">
          {`${label} :`}
          <br />
          {payload[0].value}
          <br />
          {payload[0].payload.percentLabel}
        </p>
      </div>
    );
  }

  return null;
};

const styles = {
  baseStat: {
    width: '100%',
    height: '500px',
    marginTop: '20px',
  },

  statContent: {
    width: '100%',
    height: '100%',
  },
};

const formatXAxis = tickItem => {
  // console.log('tickitem', tickItem);
  return tickItem;
};

class EventStatisticManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubItem: 'custom',
      start: null,
      end: null,
      slice_size: 1000,
      queryParams: {},
      currentStatisticType: 0,
    };
    this.currentChart = [];
  }

  getStatisticTypes() {
    const { cnc_context } = this.props.activeWorkspace;
    return cnc_context === 20002
      ? STATISTIC_TYPES_EXTENDED
      : STATISTIC_TYPES_BASIC;
  }

  componentDidMount() {
    this.props.resetFetchEventStatistic();
    this.props.resetDownloadEventStatistic();
  }

  componentDidUpdate() {
    if (this.props.eventReport.statistic.status === 'error') {
      const errorObj = getErrorMessage(this.props.eventReport.statistic.error);
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(`${errorObj.status}: ${errorObj.message}`),
        time: 5000,
      });
      this.props.resetFetchEventStatistic();
    }
    if (this.props.eventReport.statisticDownload.status === 'error') {
      const errorObj = getErrorMessage(
        this.props.eventReport.statisticDownload.error,
        this.props.eventReport.statisticDownload.error.response.statusText
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(`${errorObj.status}: ${errorObj.message}`),
        time: 5000,
      });
      this.props.resetDownloadEventStatistic();
    }
  }

  onFetchStatistics({
    start,
    end,
    startTime,
    endTime,
    limitByTime,
    activeSubItem,
    statisticType,
    pPercent,
    hideZeros,
    hideTurku,
    hideOulu,
    removeDutyHours,
    onlyDutyHours,
  }) {
    this.setState({
      activeSubItem,
      statisticType,
      start,
      end,
      startTime,
      endTime,
      limitByTime,
      pPercent,
      hideZeros,
      hideTurku,
      hideOulu,
      removeDutyHours,
      onlyDutyHours,
    });
    const status = this.props.eventReport.statistic.status;
    if (status !== 'waiting' && status !== 'error') {
      const queryParams = this.getQueryParams({
        statisticType,
        start,
        end,
        startTime,
        endTime,
        limitByTime,
        pPercent,
        hideZeros,
        hideTurku,
        hideOulu,
        removeDutyHours,
        onlyDutyHours,
      });
      this.setState({ queryParams });
      this.props.fetchEventStatistic(queryParams);
    }
  }

  onDownloadStatistics({
    start,
    end,
    startTime,
    endTime,
    limitByTime,
    activeSubItem,
    statisticType,
    pPercent,
    hideZeros,
    hideTurku,
    hideOulu,
    removeDutyHours,
    onlyDutyHours,
    excelGraphic = false,
  }) {
    this.setState({
      activeSubItem,
      statisticType,
      start,
      end,
      startTime,
      endTime,
      limitByTime,
      pPercent,
      hideZeros,
      hideTurku,
      hideOulu,
      removeDutyHours,
      onlyDutyHours,
    });
    const status = this.props.eventReport.statistic.status;
    const statistic_types = this.getStatisticTypes();
    const { currentStatisticType } = this.state;
    if (status !== 'waiting' && status !== 'error') {
      const queryParams = this.getQueryParams({
        statisticType:
          statisticType || statistic_types[currentStatisticType].type,
        start,
        end,
        startTime,
        endTime,
        limitByTime,
        pPercent,
        hideZeros,
        hideTurku,
        hideOulu,
        removeDutyHours,
        onlyDutyHours,
        excelGraphic,
      });
      this.setState({ queryParams });
      this.props.downloadEventStatistic(queryParams);
    }
  }

  // downloadStatistics = params => {
  //   if (
  //     this.props.eventReport.statisticDownload.status === 'waiting' ||
  //     this.props.eventReport.statisticDownload.status === 'error'
  //   ) {
  //     return;
  //   }
  //   const params = this.state.queryParams;
  //   params.reportType = reportType;
  //   this.props.downloadEventStatistic(params);
  // };

  getQueryParams({
    statisticType,
    start,
    end,
    startTime,
    endTime,
    limitByTime,
    pPercent,
    hideZeros,
    hideTurku,
    hideOulu,
    removeDutyHours,
    onlyDutyHours,
    excelGraphic = false,
  }) {
    const options = this.getStatisticTypeOptions();
    const authKey = localStorage.getItem('x-auth-key');
    const wsId = this.props.ws_id;
    const eventId = this.props.activeWorkspace.cnc_event;
    const lang = this.props.auth.lang;
    const plParent = this.props.activeWorkspace.cnc_place;
    const {
      place_ids,
      client_place_ids,
      display_place_ids,
      source_ids,
      reason_ids,
      mt_keys,
      ev_types,
      root_place_ids,
    } = this.state;

    const params = {
      authKey,
      wsId,
      plParent,
      eventId,
      place_ids,
      client_place_ids,
      display_place_ids: options.displayPlaceFilter ? display_place_ids : [],
      performer_ids: source_ids,
      eventcategory_ids: reason_ids,
      ev_types,
      mt_keys,
      startdate: moment(start, DD_MM_YYYY).format(YYYY_MM_DD),
      enddate: moment(end, DD_MM_YYYY).format(YYYY_MM_DD),
      lang,
      statisticType,
      pPercent,
      hideZeros,
      hideTurku,
      hideOulu,
      removeDutyHours,
      onlyDutyHours,
      root_place_ids,
      excelGraphic,
    };

    if (limitByTime && startTime && endTime) {
      params.start_time = startTime;
      params.end_time = endTime;
    }
    return params;
  }

  handleChange = (name, value) => {
    //if (name === 'place_ids' && value.length === 0) {
    //  this.setState({ ['root_place_ids']: value });
    //}
    this.setState({ [name]: value });
  };

  renderLabels(names) {
    return names.map(value => value);
  }

  exportChart(format, index, statisticName) {
    // A Recharts component is rendered as a div that contains namely an SVG
    // which holds the chart. We can access this SVG by calling upon the first child/
    //let chartSVG = ReactDOM.findDOMNode(this.currentChart).children[0];
    let chartSVG = this.currentChart[index].container.children[0];
    switch (format) {
      case 'svg': {
        let svgURL = new XMLSerializer().serializeToString(chartSVG);
        let svgBlob = new Blob([svgURL], {
          type: 'image/svg+xml;charset=utf-8',
        });
        FileSaver.saveAs(svgBlob, statisticName + '.svg');
        break;
      }
      case 'png': {
        const width = chartSVG.clientWidth;
        const height = chartSVG.clientHeight;
        let svgURL = new XMLSerializer().serializeToString(chartSVG);
        let svgBlob = new Blob([svgURL], {
          type: 'image/svg+xml;charset=utf-8',
        });
        let URL = window.URL || window.webkitURL || window;
        let blobURL = URL.createObjectURL(svgBlob);

        let image = new Image();
        image.onload = () => {
          let canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          let context = canvas.getContext('2d');
          context.drawImage(
            image,
            0,
            0,
            context.canvas.width,
            context.canvas.height
          );
          let png = canvas.toDataURL('image/png', 1.0);
          FileSaver.saveAs(png, statisticName + '.png');
        };

        image.src = blobURL;
        break;
      }
      default: {
        let svgBlob = new Blob([chartSVG.outerHTML], {
          type: 'text/html;charset=utf-8',
        });
        FileSaver.saveAs(svgBlob, statisticName + '.html');
        break;
      }
    }
  }

  graphMax(max) {
    const magnitude = Math.pow(10, Math.floor(Math.log10(max)));
    const mostSignificantDigit = Math.floor(max / magnitude);
    return (mostSignificantDigit + 1) * magnitude;
  }

  renderStatistic(data, total, statisticName) {
    //const isDownloading = this.props.eventReport.statisticDownload.status === 'waiting';
    const { statisticType, hideZeros } = this.state;
    const { error, items } = data;
    if (error) {
      return (
        <Message error>
          <Message.Header>{T('Error')}</Message.Header>
          <p>{T(error)}</p>
        </Message>
      );
    }
    let xLabel;
    let header = statisticName;
    let graph = [];
    let graphVertical;
    let oldGraph;
    let table = [];

    switch (statisticType) {
      case 'Hour': {
        const xKey = T('Events');
        xLabel = 'Hours';
        // header = 'Events Hourly';
        graph[0] = (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              ref={chart => (this.currentChart[0] = chart)}
              data={items}
              margin={{ top: 20, right: 65, bottom: 60 }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                BarCategoryGap="20"
              />
              <XAxis
                interval={0}
                type="category"
                dataKey={statisticType}
                label={{
                  value: T(xLabel),
                  position: 'insideBottomLeft',
                  dy: 10,
                }}
              />
              <YAxis
                type="number"
                domain={[0, 10]}
                dataKey={xKey}
                label={{
                  value: T('Events'),
                  angle: -90,
                  position: 'insideLeft',
                }}
              />
              <Tooltip />
              <Legend />
              <Bar
                dataKey={xKey}
                fill="#8884d8"
                label={{ value: T('Events'), position: 'top' }}
              />
            </BarChart>
          </ResponsiveContainer>
        );

        break;
      }
      case 'Location': {
        const xKey = T('Events');
        xLabel = 'Location';
        header = T('Vastaanotetut tehtävät tapahtumakunnan mukaan');
        const filteredItems = hideZeros
          ? items.filter(i => i.Tapahtumat !== 0)
          : items;
        const max = items.reduce((prev, current) =>
          prev > current.Tapahtumat ? prev : current.Tapahtumat
        );
        const dataMax = this.graphMax(max);
        for (let i = 0; i < filteredItems.length; i += this.state.slice_size) {
          const itemSlice = filteredItems.slice(i, i + this.state.slice_size);
          if (itemSlice.length > 0) {
            graph[i] = (
              <ResponsiveContainer width="100%" height={600}>
                <BarChart
                  ref={chart => (this.currentChart[i] = chart)}
                  data={itemSlice}
                  margin={{ top: 20, right: 65, bottom: 60 }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis
                    interval={0}
                    type="category"
                    dataKey={'Location'}
                    tick={{ angle: 90, dy: 10 }}
                    textAnchor="start"
                  />
                  <YAxis
                    type="number"
                    domain={[0, dataMax]}
                    padding={{ top: 20 }}
                    dataKey={xKey}
                    label={{
                      value: T('Events'),
                      angle: -90,
                      position: 'insideLeft',
                    }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    dataKey={xKey}
                    fill="#8884d8"
                    label={{ position: 'top' }}
                  >
                    <LabelList
                      dataKey="percentLabel"
                      position="top"
                      offset="30"
                      style={{ fill: 'rgba(0,0,0,0.66)' }}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            );
            table[i] = (
              <Table compact fluid>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    {itemSlice.map((item, i) => (
                      <Table.HeaderCell singleLine textAlign="right" key={i}>
                        {item.Location}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <span style={{ color: '#78bde7' }}>{T('Tehtävät')}</span>
                    </Table.Cell>
                    {itemSlice.map((item, i) => (
                      <Table.Cell singleLine textAlign="right" key={100 + i}>
                        {item[xKey]}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <span style={{ color: '#d5652c' }}>{T('%-osuus')}</span>
                    </Table.Cell>
                    {itemSlice.map((item, i) => (
                      <Table.Cell singleLine textAlign="right" key={100 + i}>
                        {item['percent']}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                </Table.Body>
              </Table>
            );
          }
        }
        break;
      }
      case 'HomeLocation': {
        const xKey = T('Events');
        xLabel = 'Location';
        header = T('Vastaanotetut tehtävät kotikunnan mukaan');
        const filteredItems = hideZeros
          ? items.filter(i => i.Tapahtumat !== 0)
          : items;
        const max = items.reduce((prev, current) =>
          prev > current.Tapahtumat ? prev : current.Tapahtumat
        );
        const dataMax = this.graphMax(max);
        for (let i = 0; i < filteredItems.length; i += this.state.slice_size) {
          const itemSlice = filteredItems.slice(i, i + this.state.slice_size);
          if (itemSlice.length > 0) {
            graph[i] = (
              <ResponsiveContainer width="100%" height={600}>
                <BarChart
                  ref={chart => (this.currentChart[i] = chart)}
                  data={itemSlice}
                  margin={{ top: 20, right: 65, bottom: 60 }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis
                    interval={0}
                    type="category"
                    dataKey={'Location'}
                    tick={{ angle: 90, dy: 10 }}
                    textAnchor="start"
                  />
                  <YAxis
                    type="number"
                    domain={[0, dataMax]}
                    padding={{ top: 20 }}
                    dataKey={xKey}
                    label={{
                      value: T('Events'),
                      angle: -90,
                      position: 'insideLeft',
                    }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    dataKey={xKey}
                    fill="#8884d8"
                    label={{ position: 'top' }}
                  >
                    <LabelList
                      dataKey="percentLabel"
                      position="top"
                      offset="30"
                      style={{ fill: 'rgba(0,0,0,0.66)' }}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            );
            table[i] = (
              <Table compact fluid>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    {itemSlice.map((item, i) => (
                      <Table.HeaderCell singleLine textAlign="right" key={i}>
                        {item.Location}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <span style={{ color: '#78bde7' }}>{T('Tehtävät')}</span>
                    </Table.Cell>
                    {itemSlice.map((item, i) => (
                      <Table.Cell singleLine textAlign="right" key={100 + i}>
                        {item[xKey]}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <span style={{ color: '#d5652c' }}>{T('%-osuus')}</span>
                    </Table.Cell>
                    {itemSlice.map((item, i) => (
                      <Table.Cell singleLine textAlign="right" key={100 + i}>
                        {item['percent']}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                </Table.Body>
              </Table>
            );
          }
        }
        break;
      }
      case 'Month': {
        xLabel = 'Toimenpiteet kuukausittain';
        const { names } = data;
        const bars = names.map((name, i) => (
          <Bar
            key={`mcpn${i}`}
            dataKey={name}
            fill={barColors2[i]}
            name={name}
            minPointSize={0}
            label={{ value: name, position: 'top' }}
          />
        ));
        graph[0] = (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              ref={chart => (this.currentChart[0] = chart)}
              data={items}
              barGap={0}
              margin={{ top: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                interval={0}
                type="category"
                tick={{ angle: 90, dy: 10 }}
                dataKey="month"
                height={100}
                textAnchor="start"
              />
              <YAxis type="number" />
              <Tooltip />
              <Legend
                verticalAlign="top"
                wrapperStyle={{ padding: 30, paddingLeft: 80 }}
              />
              {bars}
            </BarChart>
          </ResponsiveContainer>
        );

        table[0] = (
          <Table compact fluid>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                {items.map((item, i) => (
                  <Table.HeaderCell singleLine textAlign="right" key={i}>
                    {item.month}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {names.map((name, j) => (
                <Table.Row key={j}>
                  <Table.Cell>
                    <span style={{ color: barColors2[j] }}>{name}</span>
                  </Table.Cell>
                  {items.map((item, i) => (
                    <Table.Cell singleLine textAlign="right" key={j * 100 + i}>
                      {item[name]}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        );

        break;
      }
      case 'EventsPerMonth': {
        xLabel = 'Tehtävät kuukausittain';
        header = T(
          'Vastaanotetut tapahtumat ja niihin käytetty aika kuukausittain'
        );
        const lbl_count = T('Tapahtumat yhteensä');
        const lbl_hours = T('Käytetty aika tunteina');
        const lbl_avg = T('Käytetty aika min/tapahtuma');

        const mappedItems = items.map(item => ({
          [lbl_count]: item.count,
          [lbl_hours]: Math.round(item.event_time / 3600),
          [lbl_avg]: Math.round(item.avg_time / 60),
          month: item.month,
        }));
        graph[0] = (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              ref={chart => (this.currentChart[0] = chart)}
              data={mappedItems}
              barGap={0}
              margin={{ top: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                interval={0}
                type="category"
                tick={{ angle: 90, dy: 10 }}
                dataKey="month"
                height={100}
                textAnchor="start"
              />
              <YAxis type="number" />
              <Tooltip />
              <Legend
                verticalAlign="top"
                wrapperStyle={{ padding: 30, paddingLeft: 80 }}
              />
              <Bar
                dataKey={lbl_count}
                fill={barColors2[0]}
                minPointSize={0}
                label={{ position: 'top' }}
              />
              <Bar
                dataKey={lbl_hours}
                fill={barColors2[1]}
                minPointSize={0}
                label={{ position: 'top' }}
              />
              <Bar
                dataKey={lbl_avg}
                fill={barColors2[2]}
                minPointSize={0}
                label={{ position: 'top' }}
              />
            </BarChart>
          </ResponsiveContainer>
        );

        table[0] = (
          <Table compact fluid>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                {mappedItems.map(({ month }, i) => (
                  <Table.HeaderCell singleLine textAlign="right" key={i}>
                    {month}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <span style={{ color: barColors2[0] }}>{lbl_count}</span>
                </Table.Cell>
                {mappedItems.map((item, i) => (
                  <Table.Cell singleLine textAlign="right" key={i}>
                    {item[lbl_count]}
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <span style={{ color: barColors2[1] }}>{lbl_hours}</span>
                </Table.Cell>
                {mappedItems.map((item, i) => (
                  <Table.Cell singleLine textAlign="right" key={i}>
                    {item[lbl_hours]}
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <span style={{ color: barColors2[2] }}>{lbl_avg}</span>
                </Table.Cell>
                {mappedItems.map((item, i) => (
                  <Table.Cell singleLine textAlign="right" key={i}>
                    {item[lbl_avg]}
                  </Table.Cell>
                ))}
              </Table.Row>
            </Table.Body>
          </Table>
        );

        break;
      }

      case 'Night': {
        xLabel = 'Yhteydenottojen jakautuminen arki- ja pyhäöihin';
        header = 'Yhteydenottojen jakautuminen arki- ja pyhäöihin';
        // header = 'Contact distribution by Night Holiday Status'; // Yhteydenottojen jakautuminen arki- ja pyhäöihin
        items.forEach(item => {
          const { holiday_nights, regular_nights, all } = item;
          item.holidayPercent =
            ((holiday_nights / all) * 100.0).toFixed(1) + '%';
          item.regularPercent =
            ((regular_nights / all) * 100.0).toFixed(1) + '%';
        });
        graph[0] = (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              ref={chart => (this.currentChart[0] = chart)}
              data={items}
              barGap={0}
              margin={{ top: 5, right: 20, left: 100, bottom: 5 }}
              layout="vertical"
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis type="number" />
              <YAxis interval={0} type="category" dataKey="name" />
              <Tooltip />
              <Legend verticalAlign="top" wrapperStyle={{ padding: 20 }} />
              <Bar
                dataKey={'all'}
                fill="#9966ff"
                name={T('All')}
                label={{ position: 'right' }}
              />
              <Bar
                dataKey={'holiday_nights'}
                fill="#d5652c"
                name={T('Holiday Nights')}
                label={{ position: 'right' }}
              >
                <LabelList
                  dataKey="holidayPercent"
                  position="right"
                  offset="45"
                  style={{ fill: 'rgba(0, 0, 0, 0.66)' }}
                />
              </Bar>
              <Bar
                dataKey={'regular_nights'}
                fill="#78bde7"
                name={T('Regular Nights')}
                label={{ position: 'right' }}
              >
                <LabelList
                  dataKey="regularPercent"
                  position="right"
                  offset="45"
                  style={{ fill: 'rgba(0, 0, 0, 0.66)' }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        );
        break;
      }
      case 'Triages': {
        xLabel = 'Tehtävät kiireellisyysluokituksen mukaan';
        header = 'Tehtävät kiireellisyysluokituksen mukaan';
        // header = 'Events in each triage class per municipality'; // Yhteydenottojen jakautuminen arki- ja pyhäöihin
        const triages = [
          { key: 'A', name: T('Välittömästi (A)') },
          { key: 'B', name: T('Päivystyksellisesti (B)') },
          { key: 'C', name: T('Ohjaus ja neuvonta (C)') },
        ];
        const bars = triages.map((triage, i) => (
          <Bar
            key={`mcpn${i}`}
            dataKey={triage.key}
            fill={barColors[i]}
            name={triage.name}
          />
        ));
        const { names } = data;
        const filteredItems = hideZeros
          ? items.filter(i => i.all !== 0)
          : items;
        const max = filteredItems.reduce((acc, current) => {
          const curMax = names
            .map(name => current[name])
            .reduce((p, c) => (c > p ? c : p));
          return curMax > acc ? curMax : acc;
        }, 0);
        const dataMax = this.graphMax(max);
        for (let i = 0; i < filteredItems.length; i += this.state.slice_size) {
          const itemSlice = filteredItems.slice(i, i + this.state.slice_size);
          if (itemSlice.length > 0) {
            graph[i] = (
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  ref={chart => (this.currentChart[i] = chart)}
                  data={itemSlice}
                  barGap={0}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis
                    interval={0}
                    type="category"
                    dataKey="Location"
                    tick={{ angle: 90, dy: 10 }}
                    height={120}
                    textAnchor="start"
                  />
                  <YAxis type="number" domain={[0, dataMax]} />
                  <Tooltip />
                  <Legend verticalAlign="top" wrapperStyle={{ padding: 20 }} />
                  {bars}
                </BarChart>
              </ResponsiveContainer>
            );
            table[i] = (
              <Table compact fluid>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    {itemSlice.map((item, i) => (
                      <Table.HeaderCell singleLine textAlign="right" key={i}>
                        {item.Location}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {triages.map((triage, j) => (
                    <Table.Row key={j}>
                      <Table.Cell>
                        <span style={{ color: barColors2[j] }}>
                          {triage.name}
                        </span>
                      </Table.Cell>
                      {itemSlice.map((item, i) => (
                        <Table.Cell
                          singleLine
                          textAlign="right"
                          key={j * 100 + i}
                        >
                          {item[triage.key]}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            );
          }
        }
        break;
      }
      case 'Municipality': {
        xLabel = 'Toimenpiteet eriteltyinä kunnittain';
        const { names } = data;
        const bars = names.map((name, i) => (
          <Bar
            key={`mcpn${i}`}
            dataKey={name}
            fill={barColors[i]}
            name={name}
            minPointSize={0}
          />
        ));
        const filteredItems = hideZeros
          ? items.filter(i => i.all !== 0)
          : items;
        const max = filteredItems.reduce((acc, current) => {
          const curMax = names
            .map(name => current[name])
            .reduce((p, c) => (c > p ? c : p));
          return curMax > acc ? curMax : acc;
        }, 0);
        const dataMax = this.graphMax(max);
        for (let i = 0; i < filteredItems.length; i += this.state.slice_size) {
          const itemSlice = filteredItems.slice(i, i + this.state.slice_size);
          if (itemSlice.length > 0) {
            graph[i] = (
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  ref={chart => (this.currentChart[i] = chart)}
                  data={itemSlice}
                  barCategoryGap={2}
                  barGap={0}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis
                    interval={0}
                    type="category"
                    dataKey="Location"
                    tick={{ angle: 90, dy: 10 }}
                    height={120}
                    textAnchor="start"
                  />
                  <YAxis type="number" domain={[0, dataMax]} />
                  <Tooltip />
                  <Legend verticalAlign="top" wrapperStyle={{ padding: 20 }} />
                  {bars}
                </BarChart>
              </ResponsiveContainer>
            );
            table[i] = (
              <Table compact fluid>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    {itemSlice.map((item, i) => (
                      <Table.HeaderCell singleLine textAlign="right" key={i}>
                        {item.Location}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {names.map((name, j) => (
                    <Table.Row key={j}>
                      <Table.Cell>
                        <span style={{ color: barColors2[j] }}>{name}</span>
                      </Table.Cell>
                      {itemSlice.map((item, i) => (
                        <Table.Cell
                          singleLine
                          textAlign="right"
                          key={j * 100 + i}
                        >
                          {item[name]}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            );
          }
        }

        // <Bar dataKey={'all'} fill="#000000" name={T('Kaikki')} />

        break;
      }
      case 'Sources': {
        xLabel = 'Source Distribution';
        // header = 'Contact distribution by Source'; // Yhteydenottojen jakautuminen arki- ja pyhäöihin
        const { items, names } = data;

        const bars = names.map((name, i) => (
          <Bar
            key={`mcpn${i}`}
            dataKey={name}
            fill={barColors[i]}
            name={name}
          />
        ));
        const totalEvents = names.reduce((a, c) => a + items[0][c], 0);
        const mappedItems = names.map(name => ({
          name,
          count: items[0][name],
          percentLabel:
            ((items[0][name] / totalEvents) * 100.0).toFixed(1) + '%',
        }));

        graph[0] = (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              ref={chart => (this.currentChart[0] = chart)}
              width={600}
              height={300}
              data={mappedItems}
              layout="vertical"
              margin={{ top: 5, right: 65, left: 100, bottom: 5 }}
            >
              <XAxis type="number" />
              <YAxis interval={0} type="category" dataKey="name" />
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <Tooltip />
              <Bar
                dataKey="count"
                fill="#55BFE9"
                name={T('Vastaanotetut tehtävät')}
                label={{ position: 'right' }}
              >
                <LabelList
                  dataKey="percentLabel"
                  position="right"
                  offset="45"
                  style={{ fill: 'rgba(0, 0, 0, 0.5)' }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        );
        break;
      }
      case 'ContactTimes': {
        xLabel = 'Yhteydenotot virka-ajan ulkopuolella (klo 22-08)';
        header = 'Yhteydenotot virka-ajan ulkopuolella (klo 22-08)';
        const { items } = data;

        const lbl_year = T('Year');
        const lbl_all = T('Kaikki tapahtumat');
        const lbl_outside = T('Tapaht. klo 22-08 välillä');

        const mappedItems = items.map(item => ({
          [lbl_year]: item.year,
          [lbl_all]: item.n_outside_working_hours + item.n_within_working_hours,
          [lbl_outside]: item.n_outside_working_hours,
          percentLabel:
            (
              (item.n_outside_working_hours /
                (item.n_outside_working_hours + item.n_within_working_hours)) *
              100.0
            ).toFixed(1) + '%',
        }));
        graph[0] = (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              ref={chart => (this.currentChart[0] = chart)}
              width={600}
              height={300}
              data={mappedItems}
              layout="vertical"
              margin={{ top: 5, right: 65, left: 100, bottom: 5 }}
            >
              <XAxis type="number" />
              <YAxis interval={0} type="category" dataKey={lbl_year} />
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <Legend verticalAlign="top" wrapperStyle={{ padding: 20 }} />
              <Tooltip />
              <Bar
                dataKey={lbl_all}
                fill="#aae0f8"
                label={{ value: T('Events'), position: 'right' }}
              />
              <Bar
                dataKey={lbl_outside}
                fill="#0092bb"
                label={{ value: T('Events'), position: 'right' }}
              >
                <LabelList
                  dataKey="percentLabel"
                  position="right"
                  offset="45"
                  style={{ fill: 'rgba(0, 0, 0, 0.66)' }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        );
        break;
      }
      case 'SourceTimes': {
        xLabel = 'Source Time Distribution';
        header = T(
          'Tehtävien määrä ja niihin käytetty aika tunteina yhteydenottajatahoittain'
        );
        const { items } = data;

        const lbl_source = T('Yhteydenottajataho');
        const lbl_count = T('Vastaanotetut tehtävät');
        const lbl_event_time = T('Käytetty aika tunteina');

        const totalTime = items.reduce((a, c) => a + c.event_time, 0);
        const totalCount = items.reduce((a, c) => a + c.count, 0);
        const mappedItems = items.map(item => ({
          [lbl_source]: item.name,
          [lbl_count]: item.count,
          [lbl_event_time]: Math.round(item.event_time / 3600),
          percentCount: ((item.count / totalCount) * 100.0).toFixed(1) + '%',
          percentTime: ((item.event_time / totalTime) * 100.0).toFixed(1) + '%',
        }));

        graph[0] = (
          <ResponsiveContainer width="100%" height={800}>
            <BarChart
              ref={chart => (this.currentChart[0] = chart)}
              width={600}
              height={300}
              data={mappedItems}
              layout="vertical"
              margin={{ top: 5, right: 65, left: 100, bottom: 5 }}
            >
              <XAxis type="number" />
              <YAxis interval={0} type="category" dataKey={lbl_source} />
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <Legend verticalAlign="top" wrapperStyle={{ padding: 20 }} />
              <Tooltip />
              <Bar
                dataKey={lbl_count}
                fill="#aae0f8"
                label={{ position: 'right' }}
              >
                <LabelList
                  dataKey="percentCount"
                  position="right"
                  offset="45"
                  style={{ fill: 'rgba(0, 0, 0, 0.33)' }}
                />
              </Bar>
              <Bar
                dataKey={lbl_event_time}
                fill="#0092bb"
                label={{ position: 'right' }}
              >
                <LabelList
                  dataKey="percentTime"
                  position="right"
                  offset="45"
                  style={{ fill: 'rgba(0, 0, 0, 0.66)' }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        );
        break;
      }
      case 'ContactMethods': {
        xLabel = 'Contact Method Distribution';
        // header = 'Event distribution by contact method'; // Yhteydenottojen jakautuminen arki- ja pyhäöihin
        const { names } = data;
        const totalCount = names.reduce((a, name) => a + items[0][name], 0);
        // names.forEach(name => {
        //   const percentName = `${name}Percent`;
        //   items[0][percentName] = (items[0][name] / totalCount * 100.0).toFixed(2) + "%";
        // });
        // const bars = names.map((name, i) => (
        //   <Bar
        //     key={`mcpn${i}`}
        //     dataKey={name}
        //     fill={barColors[i]}
        //     name={name}
        //     label={{ position: 'top'}}
        //     margin={{ top: 20 }}
        //   >
        //     <LabelList dataKey={`${name}Percent`}  position="top" offset="25"  style={{ fill: 'rgba(0, 0, 0, 0.5)' }}/>
        //   </Bar>
        // ));

        const mappedItems = names.map(name => ({
          name,
          value: items[0][name],
          percent: ((items[0][name] / totalCount) * 100.0).toFixed(1) + '%',
        }));
        const cells = names.map((name, i) => (
          <Cell key={`cm${i}`} fill={barColors2[i]} />
        ));

        graph[0] = (
          <ResponsiveContainer width="100%" height={500}>
            <PieChart
              width={1024}
              height={1024}
              ref={chart => (this.currentChart[0] = chart)}
            >
              <Tooltip />
              <Pie
                data={mappedItems}
                dataKey="value"
                nameKey="name"
                label={({ x, y, name, value, percent }) => (
                  <text
                    x={x}
                    y={y}
                    fill="rgba(0,0,0,1)"
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    {`${name}; ${value}; ${percent}`}
                  </text>
                )}
              >
                {cells}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        );

        // graph[0]=(
        //   <ResponsiveContainer width='100%' height={500}>
        //     <BarChart ref={(chart) => this.currentChart[0] = chart} data={items}>
        //       <CartesianGrid strokeDasharray="3 3" vertical={false} />
        //       <XAxis interval={0} type="category" dataKey='name' />
        //       <YAxis type="number" />
        //       <Tooltip />
        //       <Legend verticalAlign="top" wrapperStyle={{ padding: 20 }} />
        //       {bars}
        //     </BarChart>
        //   </ResponsiveContainer>
        // );
        break;
      }
      case 'ContactMethodTimes': {
        xLabel = 'Contact Method Time Distribution';
        header = T(
          'Tehtävien määrä ja niihin käytetty aika tunteina yhteydenottotavoittain'
        );
        const { names, items } = data;

        const lbl_method = T('Yhteydenottotapa');
        const lbl_count = T('Vastaanotetut tehtävät');
        const lbl_event_time = T('Käytetty aika tunteina');

        const totalTime = names.reduce(
          (a, name) => a + items[0][`${name}Time`],
          0
        );
        const totalCount = names.reduce((a, name) => a + items[0][name], 0);
        const mappedItems = names.map(name => ({
          [lbl_method]: name,
          [lbl_count]: items[0][name],
          [lbl_event_time]: Math.round(items[0][`${name}Time`] / 3600),
          percentCount:
            ((items[0][name] / totalCount) * 100.0).toFixed(1) + '%',
          percentTime:
            ((items[0][`${name}Time`] / totalTime) * 100.0).toFixed(1) + '%',
        }));

        graph[0] = (
          <ResponsiveContainer width="100%" height={800}>
            <BarChart
              ref={chart => (this.currentChart[0] = chart)}
              width={600}
              height={300}
              data={mappedItems}
              layout="vertical"
              margin={{ top: 5, right: 65, left: 100, bottom: 5 }}
            >
              <XAxis type="number" />
              <YAxis interval={0} type="category" dataKey={lbl_method} />
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <Legend verticalAlign="top" wrapperStyle={{ padding: 20 }} />
              <Tooltip />
              <Bar
                dataKey={lbl_count}
                fill="#aae0f8"
                label={{ position: 'right' }}
              >
                <LabelList
                  dataKey="percentCount"
                  position="right"
                  offset="45"
                  style={{ fill: 'rgba(0, 0, 0, 0.33)' }}
                />
              </Bar>
              <Bar
                dataKey={lbl_event_time}
                fill="#0092bb"
                label={{ position: 'right' }}
              >
                <LabelList
                  dataKey="percentTime"
                  position="right"
                  offset="45"
                  style={{ fill: 'rgba(0, 0, 0, 0.66)' }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        );
        break;
      }
      case 'EventCodes': {
        xLabel = 'Tehtäväkoodit';
        const { names } = data;
        let remappedItems = [];
        const totalCount = names.reduce((a, name) => a + items[0][name], 0);
        for (let i = 0; i < names.length; i += this.state.slice_size) {
          const nameSlice = names.slice(i, i + this.state.slice_size);
          remappedItems[i] = nameSlice.map(name => {
            const count = items[0][name];
            return {
              name,
              Määrä: count,
              percentLabel: ((count / totalCount) * 100.0).toFixed(1) + '%',
            };
          });
        }
        remappedItems.forEach((ri, i) => {
          graph[i] = (
            <ResponsiveContainer width={'100%'} height={800}>
              <BarChart
                ref={chart => (this.currentChart[i] = chart)}
                width={1600}
                height={900}
                data={ri}
                layout="vertical"
                margin={{ top: 5, right: 65, left: 10, bottom: 5 }}
              >
                <XAxis type="number" />
                <YAxis
                  interval={0}
                  width={400}
                  type="category"
                  dataKey="name"
                />
                <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                <Tooltip />
                <Bar
                  dataKey="Määrä"
                  fill="#55BFE9"
                  label={{ value: T('Events'), position: 'right' }}
                >
                  <LabelList
                    dataKey="percentLabel"
                    position="right"
                    offset="45"
                    style={{ fill: 'rgba(0, 0, 0, 0.5)' }}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          );
        });
        break;
      }
      case 'ClientMunicipality': {
        xLabel = 'Client vs Event Municipality Distribution';
        // header = 'Clients and Events Per Municipality'; // Yhteydenottojen jakautuminen arki- ja pyhäöihin
        const filteredItems = hideZeros
          ? items.filter(i => i.n_clients !== 0 && i.n_events !== 0)
          : items;
        const max = filteredItems.reduce((acc, current) => {
          const curMax = ['n_clients', 'n_events']
            .map(name => current[name])
            .reduce((p, c) => (c > p ? c : p));
          return curMax > acc ? curMax : acc;
        }, 0);
        const dataMax = this.graphMax(max);
        for (let i = 0; i < filteredItems.length; i += this.state.slice_size) {
          const itemSlice = filteredItems.slice(i, i + this.state.slice_size);
          if (itemSlice.length > 0) {
            graph[i] = (
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  ref={chart => (this.currentChart[i] = chart)}
                  data={itemSlice}
                  barGap={0}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis
                    interval={0}
                    type="category"
                    dataKey="Location"
                    tick={{ angle: 90, dy: 10 }}
                    height={120}
                    textAnchor="start"
                  />
                  <YAxis type="number" domain={[0, dataMax]} />
                  <Tooltip />
                  <Legend verticalAlign="top" wrapperStyle={{ padding: 20 }} />
                  <Bar
                    dataKey={'n_clients'}
                    fill="#d5652c"
                    name={T('Kotikunta')}
                  />
                  <Bar
                    dataKey={'n_events'}
                    fill="#78bde7"
                    name={T('Tapahtumakunta')}
                  />
                </BarChart>
              </ResponsiveContainer>
            );
            table[i] = (
              <Table compact fluid>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    {itemSlice.map((item, i) => (
                      <Table.HeaderCell singleLine textAlign="right" key={i}>
                        {item.Location}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <span style={{ color: '#d5652c' }}>{T('Kotikunta')}</span>
                    </Table.Cell>
                    {itemSlice.map((item, i) => (
                      <Table.Cell singleLine textAlign="right" key={100 + i}>
                        {item['n_clients']}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <span style={{ color: '#78bde7' }}>
                        {T('Tapahtumakunta')}
                      </span>
                    </Table.Cell>
                    {itemSlice.map((item, i) => (
                      <Table.Cell singleLine textAlign="right" key={100 + i}>
                        {item['n_events']}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                </Table.Body>
              </Table>
            );
          }
        }
        break;
      }
      case 'MovedFromDutyHours': {
        xLabel = 'Events moved outside of duty hours';

        const lbl_moved = T('Arjesta siirtyneet (kpl)');
        const lbl_all = T('Kaikki tapahtumat (kpl)');

        const mappedItems = items.map(item => ({
          [lbl_moved]: item.moved,
          [lbl_all]: item.all,
          percentLabel: ((item.moved / item.all) * 100.0).toFixed(1) + '%',
          name: item.name,
        }));

        graph[0] = (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              ref={chart => (this.currentChart[0] = chart)}
              data={mappedItems}
              barGap={0}
              margin={{ top: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                interval={0}
                type="category"
                tick={{ angle: 90, dy: 10 }}
                dataKey="name"
                height={100}
                textAnchor="start"
              />
              <YAxis type="number" />
              <Tooltip />
              <Legend
                verticalAlign="top"
                wrapperStyle={{ padding: 30, paddingLeft: 80 }}
              />
              <Bar
                dataKey={lbl_moved}
                fill="#d5652c"
                name={lbl_moved}
                label={{ position: 'top' }}
              >
                <LabelList
                  dataKey="percentLabel"
                  position="top"
                  offset="30"
                  style={{ fill: 'rgba(0,0,0,0.66)' }}
                />
              </Bar>
              <Bar dataKey={lbl_all} fill="#78bde7" name={lbl_all} label />
            </BarChart>
          </ResponsiveContainer>
        );

        table[0] = (
          <Table compact fluid>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                {items.map((item, i) => (
                  <Table.HeaderCell singleLine textAlign="right" key={i}>
                    {item.name}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <span style={{ color: '#d5652c' }}>
                    {T('Arjesta siirtyneet')}
                  </span>
                </Table.Cell>
                {items.map((item, i) => (
                  <Table.Cell singleLine textAlign="right" key={100 + i}>
                    {item['moved']}
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <span style={{ color: '#78bde7' }}>
                    {T('Kaikki tapahtumat')}
                  </span>
                </Table.Cell>
                {items.map((item, i) => (
                  <Table.Cell singleLine textAlign="right" key={100 + i}>
                    {item['all']}
                  </Table.Cell>
                ))}
              </Table.Row>
            </Table.Body>
          </Table>
        );
        break;
      }
      case 'MovedFromDutyHoursHR': {
        xLabel = 'Events moved outside of duty hours in hours';
        const lbl_moved = T('Arjesta siirtyneet (h)');
        const lbl_all = T('Kaikki tapahtumat (h)');

        const mappedItems = items.map(item => ({
          [lbl_moved]: Math.round(item.moved_time / 3600),
          [lbl_all]: Math.round(item.all_time / 3600),
          percentLabel:
            ((item.moved_time / item.all_time) * 100.0).toFixed(1) + '%',
          name: item.name,
        }));
        graph[0] = (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              ref={chart => (this.currentChart[0] = chart)}
              data={mappedItems}
              barGap={0}
              margin={{ top: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                interval={0}
                type="category"
                tick={{ angle: 90, dy: 10 }}
                dataKey="name"
                height={100}
                textAnchor="start"
              />
              <YAxis type="number" />
              <Tooltip />
              <Legend
                verticalAlign="top"
                wrapperStyle={{ padding: 30, paddingLeft: 80 }}
              />
              <Bar
                dataKey={lbl_moved}
                fill="#d5652c"
                name={lbl_moved}
                label={{ position: 'top' }}
              >
                <LabelList
                  dataKey="percentLabel"
                  position="top"
                  offset="30"
                  style={{ fill: 'rgba(0,0,0,0.66)' }}
                />
              </Bar>
              <Bar dataKey={lbl_all} fill="#78bde7" name={lbl_all} label />
            </BarChart>
          </ResponsiveContainer>
        );

        table[0] = (
          <Table compact fluid>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                {mappedItems.map((item, i) => (
                  <Table.HeaderCell singleLine textAlign="right" key={i}>
                    {item.name}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <span style={{ color: '#d5652c' }}>{T(lbl_moved)}</span>
                </Table.Cell>
                {mappedItems.map((item, i) => (
                  <Table.Cell singleLine textAlign="right" key={100 + i}>
                    {item[lbl_moved]}
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <span style={{ color: '#78bde7' }}>{T(lbl_all)}</span>
                </Table.Cell>
                {mappedItems.map((item, i) => (
                  <Table.Cell singleLine textAlign="right" key={100 + i}>
                    {item[lbl_all]}
                  </Table.Cell>
                ))}
              </Table.Row>
            </Table.Body>
          </Table>
        );
        break;
      }
      default: {
        break;
      }
    }
    header = T(header);
    // const ctt = this.state.pPercent && this.state.statisticType === 'Location' ? 'Selected provinces total' : 'Selected municipalities total'
    header += ' ' + this.state.start + ' - ' + this.state.end; // + T(ctt) +': ' + total

    return (
      <>
        {graph.map((g, i) => (
          <Segment key={`gs${i}`}>
            <Button.Group floated="right" size="small" basic>
              <Button
                icon="download"
                content="SVG"
                onClick={() =>
                  this.exportChart(
                    'svg',
                    i,
                    `${statisticName}_${this.state.start}-${this.state.end}_${
                      i + 1
                    }_${graph.length}`
                  )
                }
              />
              <Button
                icon="download"
                content="PNG"
                onClick={() =>
                  this.exportChart(
                    'png',
                    i,
                    `${statisticName}_${this.state.start}-${this.state.end}_${
                      i + 1
                    }_${graph.length}`
                  )
                }
              />
            </Button.Group>
            <Header as="h4">{header}</Header>
            {g}
            {table[i] ? table[i] : null}
          </Segment>
        ))}
      </>
    );
  }

  renderTab({ type, name, options, defaults }, currentStatisticType) {
    const { status } = this.props.eventReport.statistic;
    const isValid =
      status === 'success' && this.props.eventReport.statistic.data;
    const { momentOptions } = this.props.eventReport;

    return (
      <Tab.Pane key={`pane_${currentStatisticType}`}>
        <SospaReportDateRange
          onFetchStatistics={this.onFetchStatistics.bind(this)}
          onDownloadStatistics={this.onDownloadStatistics.bind(this)}
          activeSubItem={this.state.activeSubItem}
          start={this.state.start ? this.state.start : null}
          end={this.state.end ? this.state.end : null}
          momentOptions={momentOptions}
          handleChange={this.handleChange.bind(this)}
          place_ids={this.state.place_ids}
          client_place_ids={this.state.client_place_ids}
          display_place_ids={this.state.display_place_ids}
          root_place_ids={this.state.root_place_ids}
          source_ids={this.state.source_ids}
          reason_ids={this.state.reason_ids}
          mt_keys={this.state.mt_keys}
          ev_types={this.state.ev_types}
          startTime={this.state.startTime}
          endTime={this.state.endTime}
          limitByTime={this.state.limitByTime}
          activeWorkspace={this.props.activeWorkspace}
          pPercent={this.state.pPercent}
          hideZeros={this.state.hideZeros}
          hideTurku={this.state.hideTurku}
          hideOulu={this.state.hideOulu}
          removeDutyHours={this.state.removeDutyHours}
          onlyDutyHours={this.state.onlyDutyHours}
          statisticType={type}
          status={status}
          downloadStatus={this.props.eventReport.statisticDownload.status}
          options={options}
          defaults={defaults}
          slice_size={this.state.slice_size}
        />
        {status === 'waiting' ? <Spinner /> : <></>}
        {isValid
          ? this.renderStatistic(
              this.props.eventReport.statistic.data,
              this.props.eventReport.statistic.data.total,
              name
            )
          : null}
      </Tab.Pane>
    );
  }

  getStatisticTypeOptions() {
    const statistic_types = this.getStatisticTypes();
    const { currentStatisticType } = this.state;
    const { options } = statistic_types[currentStatisticType];
    return options;
  }

  render() {
    const statistic_types = this.getStatisticTypes();
    const { currentStatisticType } = this.state;
    const items = statistic_types.map((st, i) => (
      <Dropdown.Item
        key={i}
        value={i}
        onClick={() => {
          this.props.resetFetchEventStatistic();
          this.setState({ ...st.defaults, currentStatisticType: i });
        }}
      >
        {T(st.name)}
      </Dropdown.Item>
    ));

    return (
      <>
        <Menu size="huge" inverted color="grey">
          <Menu.Item header>{T('Valitse graafin tyyppi:')}</Menu.Item>
          <Dropdown item text={T(statistic_types[currentStatisticType].name)}>
            <Dropdown.Menu>{items}</Dropdown.Menu>
          </Dropdown>
        </Menu>
        {this.renderTab(
          statistic_types[currentStatisticType],
          currentStatisticType
        )}
      </>
    );
  }
}

function mapStateToProps({ workspace, languages, eventReport, auth }) {
  return {
    ws_id: workspace.ws_id,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    langStatus: languages.status,
    languages: languages.languages,
    eventReport,
    auth,
  };
}
export default connect(mapStateToProps, {
  fetchEventStatistic,
  resetFetchEventStatistic,
  downloadEventStatistic,
  resetDownloadEventStatistic,
})(EventStatisticManager);

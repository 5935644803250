import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Table,
  Button,
  Icon,
  Menu,
  Pagination,
  Header,
  Container,
  Segment,
  Message,
} from 'semantic-ui-react';
import { fetchPerformers, resetFetchPerformers } from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import { T } from '../Common/Helpers';

class PerformerList extends Component {
  constructor(props) {
    super(props);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.state = { sortByColumn: 'pf_name', direction: 'asc' };
    this.sortByColumnName = this.sortByColumnName.bind(this);
  }

  componentDidMount() {
    // localStorage.setItem('route', this.props.history.location.pathname);
    const authKey = localStorage.getItem('x-auth-key');
    const { sortByColumn, direction } = this.state;
    const ws_id = this.props.workspace.activeWorkspace.id;
    //if (!this.props.workspace.cnc_performer) {
    //  return;
    //}
    if (this.props.performers && this.props.performers.headers) {
      const { headers } = this.props.performers;
      this.props.fetchPerformers(
        authKey,
        ws_id,
        headers.current_page,
        headers.searchterm,
        sortByColumn,
        direction
      );
    } else {
      this.props.fetchPerformers(
        authKey,
        ws_id,
        1,
        null,
        sortByColumn,
        direction
      );
    }
  }

  getDirection(dirn) {
    if (dirn === 'asc') {
      return 'ascending';
    }
    return 'descending';
  }

  navigateToLink = performer => {
    this.props.history.push(`/app/performers/${performer.performer_id}/edit`);
  };

  handlePaginationChange(e, { activePage }) {
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem('x-auth-key');
    const { headers } = this.props.performers;
    this.props.fetchPerformers(
      authKey,
      ws_id,
      activePage,
      headers.searchterm,
      headers.sort,
      null,
      headers.event_id
    );
  }

  sortByColumnName(columnName) {
    const { direction, sortByColumn } = this.state;
    const { headers } = this.props.performers;
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    if (sortByColumn === columnName) {
      const modifiedDirn = direction === 'asc' ? 'desc' : 'asc';
      this.props.resetFetchPerformers();
      this.props.fetchPerformers(
        authKey,
        ws_id,
        1,
        headers.searchterm,
        columnName,
        modifiedDirn,
        headers.event_id
      );
      this.setState({ direction: modifiedDirn });
    } else {
      const modifiedDirn = 'asc';
      this.props.resetFetchPerformers();
      this.props.fetchPerformers(
        authKey,
        ws_id,
        1,
        headers.searchterm,
        columnName,
        modifiedDirn,
        headers.event_id
      );
      this.setState({ sortByColumn: columnName, direction: modifiedDirn });
    }
  }

  renderPerformers() {
    const performers = this.props.performers.performersList;
    if (!performers) {
      return <Table.Row />;
    }
    return _.map(performers, performerData => {
      const performer = Object.values(performerData)[0];
      return (
        <Table.Row
          key={performer.performer_id}
          onClick={() => this.navigateToLink(performer)}
          style={{ cursor: 'pointer' }}
          className="tablerow"
        >
          <Table.Cell key={`${performer.performer_id}-name`}>
            {performer.pf_name}
          </Table.Cell>
          <Table.Cell key={`${performer.performer_id}-country`}>
            {performer.performer_country}
          </Table.Cell>
          <Table.Cell key={`${performer.performer_id}-home`}>
            {performer.performer_homeplace}
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    if (!this.props.workspace.activeWorkspace.cnc_performer) {
      return (
        <Container fluid style={{ marginBottom: '150px' }}>
          <Segment>
            <Message negative>
              <Message.Header>{T('Workspace Misconfiguration')}</Message.Header>
              <p>{T('No root performer found.')}</p>
            </Message>
          </Segment>
        </Container>
      );
    }

    console.log('performerlist', this.props);

    const { selectStatus } = this.props.performers;
    if (selectStatus === 'init' || selectStatus === 'waiting') {
      return <Spinner />;
    }

    const column = this.state.sortByColumn;
    const dirn = this.state.direction;

    return (
      <>
        <Header> {T('Teams list')} </Header>
        <Table celled padded sortable stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'pf_name' ? this.getDirection(dirn) : null}
                onClick={() => this.sortByColumnName('pf_name')}
              >
                {T('Name')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'pf_ctry' ? this.getDirection(dirn) : null}
                onClick={() => this.sortByColumnName('pf_ctry')}
              >
                {T('Country')}
              </Table.HeaderCell>
              <Table.HeaderCell>{T('Home venue')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderPerformers()}</Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="4">
                <Link to="/app/performers/add">
                  <Button color="blue" primary content={T('Add')} />
                </Link>
                {this.props.performers.headers &&
                  this.props.performers.headers.current_page && (
                    <Menu floated="right" pagination>
                      <Pagination
                        onPageChange={this.handlePaginationChange}
                        defaultActivePage={
                          this.props.performers.headers
                            ? this.props.performers.headers.current_page
                            : 1
                        }
                        ellipsisItem={{
                          content: <Icon name="ellipsis horizontal" />,
                          icon: true,
                        }}
                        firstItem={{
                          content: <Icon name="angle double left" />,
                          icon: true,
                        }}
                        lastItem={{
                          content: <Icon name="angle double right" />,
                          icon: true,
                        }}
                        prevItem={{
                          content: <Icon name="angle left" />,
                          icon: true,
                        }}
                        nextItem={{
                          content: <Icon name="angle right" />,
                          icon: true,
                        }}
                        totalPages={
                          this.props.performers.headers
                            ? this.props.performers.headers.total_pages
                            : 1
                        }
                      />
                    </Menu>
                  )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </>
    );
  }
}

function mapStateToProps({ performers, auth, workspace }) {
  return { performers, auth, workspace };
}

export default connect(mapStateToProps, {
  fetchPerformers,
  resetFetchPerformers,
})(withRouter(PerformerList));

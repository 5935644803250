/* eslint-env browser */
import { debounce } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Table,
  Message,
  Button,
  Icon,
  Menu,
  Pagination,
  Form,
  Input,
  Label,
} from 'semantic-ui-react';
import moment from 'moment';
import { fetchEvents, resetEvents } from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import { T, DEFAULT_SORT_DIRECTIONS } from '../Common/Helpers';

const DATE_TIME_FORMAT = 'l HH:mm';

class EventList extends Component {
  constructor(props) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 500);
    this.renderEvents = this.renderEvents.bind(this);
    this.state = {
      showAll: false,
      search: '',
      sortByColumn: 'ev_event_start',
      sortDirection: 'asc',
      activePage: 1,
    };
  }

  componentDidMount() {
    this.reloadEvents();
  }

  /**
   * Fetches the events from the server and updates the redux state
   */
  reloadEvents() {
    const authKey = localStorage.getItem('x-auth-key');
    const { showAll, activePage, search, sortByColumn, sortDirection } =
      this.state;
    this.props.fetchEvents(
      authKey,
      activePage,
      this.props.Parent,
      this.props.ws_id,
      showAll,
      search,
      sortByColumn,
      sortDirection
    );
  }

  /**
   * Reload the events when needed.
   */
  componentDidUpdate(prevProps, prevState) {
    const { showAll, activePage, search, sortByColumn, sortDirection } =
      this.state;

    if (
      prevProps.Parent !== this.props.Parent ||
      prevProps.ws_id !== this.props.ws_id ||
      prevState.showAll !== showAll ||
      prevState.activePage !== activePage ||
      prevState.search !== search ||
      prevState.sortByColumn !== sortByColumn ||
      prevState.sortDirection !== sortDirection
    ) {
      this.reloadEvents();
    }
  }

  onSearch({ target }) {
    const { value } = target;
    this.setState({ searchValue: value });
    this.handleSearch(value);
  }

  /**
   * The debounced (see constructor) handleSearch function takes in a search parameter and
   * sets the state of the search property to the value of the search input.
   * @param search - The search string that the user has entered.
   */
  handleSearch(search) {
    this.setState({ search });
  }

  onChange = (values, { name, checked }) => {
    this.setState({ [name]: checked });
  };

  getPerformersByPosition(performers, position) {
    if (performers === undefined) {
      return;
    }
    const names = [];
    if (this.props.Parent === null || this.props.Parent === undefined) {
      return;
    }
    for (let i = 0; i < performers.length; i++) {
      if (performers[i].ep_position === position) {
        names.push(performers[i].pf_name);
      }
    }
    return names;
  }

  getPerformersIdByPosition(performers, position) {
    if (performers === undefined) {
      return;
    }
    const names = [];
    if (this.props.Parent === null || this.props.Parent === undefined) {
      return;
    }
    for (let i = 0; i < performers.length; i++) {
      if (performers[i].ep_position === position) {
        names.push(performers[i].performer_id);
      }
    }
    if (names.length < 1) {
      return 0;
    }
    return names;
  }

  /* A function that is called when the user clicks on a page number in the pagination menu. */
  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  /* The function that is called when a row is clicked. */
  navigateToLink = event => {
    this.props.history.push({
      pathname: `/app/events/match/edit/${event.event_id}`,
      state: { match: event },
    });
  };

  /* Renders the events inside the table */
  renderEvents() {
    const { eventsx } = this.props.events;

    /* If there are no events, return a message saying that. */
    if (!eventsx || eventsx.length === 0) {
      return (
        <Table.Row className="tablerow">
          <Table.Cell colspan="3">
            <Message warning>
              <Icon name="info" />
              {T('No events')}
            </Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    /* Mapping the events array to table rows. */
    return eventsx.map(event => {
      let formattedEventStart = event.ev_event_start;
      formattedEventStart =
        moment(formattedEventStart).format(DATE_TIME_FORMAT);
      return (
        <Table.Row
          key={event.event_id}
          onClick={() => this.navigateToLink(event)}
          style={{ cursor: 'pointer' }}
          className="tablerow"
        >
          <Table.Cell>{event.event_id}</Table.Cell>
          <Table.Cell>{formattedEventStart}</Table.Cell>
          <Table.Cell>{event.ev_name}</Table.Cell>
        </Table.Row>
      );
    });
  }

  /**
   * If the column is already sorted, toggle the sort direction. Otherwise, set the sort direction to the
   * default for that column
   * @param column - The column that was clicked on.
   */
  handleSortChange(column) {
    const { sortByColumn, sortDirection } = this.state;

    if (sortByColumn === column) {
      this.setState({
        sortDirection: sortDirection === 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.setState({
        sortByColumn: column,
        sortDirection: DEFAULT_SORT_DIRECTIONS[column],
      });
    }
  }

  render() {
    /**
     * Check the status of the fetching of the events.
     * If the status is waiting or init, return a spinner.
     * If the status is error, return an error message.
     */
    if (
      this.props.events.fetchesStatus === 'waiting' ||
      this.props.events.fetchesStatus === 'init'
    ) {
      return <Spinner />;
    } else if (this.props.events.fetchesStatus === 'error') {
      return (
        <Message
          error
          header={T('Error')}
          list={[T('Error while fetching events')]}
        />
      );
    }
    const { searchValue, sortByColumn, sortDirection } = this.state;
    const direction = sortDirection === 'asc' ? 'ascending' : 'descending';
    return (
      <>
        <Menu
          attached="top"
          size="mini"
          compact
          secondary
          style={{ backgroundColor: '#e8e8e8' }}
        >
          <Menu.Item as={Label} attach="top">{`${T('Events under')} "${
            this.props.parentName
          }"`}</Menu.Item>
          <Menu.Item position="right">
            <Input
              icon="search"
              placeholder={T('Search ...')}
              onChange={(e, props) => this.onSearch(e, props)}
              value={searchValue}
            />
          </Menu.Item>
        </Menu>
        <Table compact celled stackable sortable attached="bottom">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={sortByColumn === 'event_id' ? direction : null}
                onClick={e => this.handleSortChange('event_id')}
              >
                {T('Id')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortByColumn === 'ev_event_start' ? direction : null}
                onClick={e => this.handleSortChange('ev_event_start')}
              >
                {T('Date & Time')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortByColumn === 'ev_name' ? direction : null}
                onClick={e => this.handleSortChange('ev_name')}
              >
                {T('Event')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderEvents()}</Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell>
                <Link to={`/app/events/match/add/${this.props.Parent}`}>
                  <Button icon="plus" primary content={T('Add')} />
                </Link>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Form.Checkbox
                  label={T('Show also completed events')}
                  name="showAll"
                  checked={this.state.showAll}
                  className="ui toggle checkbox"
                  onChange={this.onChange}
                />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="3">
                <Menu floated="right" pagination>
                  <Pagination
                    onPageChange={this.handlePaginationChange}
                    activePage={this.state.activePage}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={this.props.events.headers.total_pages}
                  />
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </>
    );
  }
}

function mapStateToProps({ events, auth }) {
  return { events, auth };
}

export default connect(mapStateToProps, { fetchEvents, resetEvents })(
  withRouter(EventList)
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import SalesReportManager from './SalesReportManager';
import { fetchPriceLists, fetchShops } from '../../../Redux/actions';
import { Spinner } from '../../Common/Spinner';
import { T } from '../../Common/Helpers';

class SalesReportContainer extends Component {
  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.ws_id;
    this.props.fetchPriceLists({ authKey, ws_id, all: true });
    this.props.fetchShops({ authKey, ws_id });
  }

  render() {
    if (
      this.props.plStatus === 'waiting' ||
      this.props.shStatus === 'waiting' ||
      this.props.plStatus === 'init' ||
      this.props.langStatus === 'waiting'
    ) {
      return <Spinner />;
    } else if (
      this.props.fetchAll === 'error' ||
      this.props.langStatus === 'error'
    ) {
      return (
        <Message error>
          <Message.Header>{T('Error')}</Message.Header>
          <p>{T('Error while listing data')}</p>
        </Message>
      );
    }
    return (
      <SalesReportManager
        shops={this.props.shops}
        pricelists={this.props.pricelists}
        languages={this.props.languages}
      />
    );
  }
}

function mapStateToProps({ workspace, pricelist, shop, languages }) {
  return {
    ws_id: workspace.ws_id,
    pricelists: pricelist.pricelists,
    shops: shop.shops,
    shStatus: shop.shopsFetchStatus,
    plStatus: pricelist.plStatus,
    langStatus: languages.status,
    languages: languages.languages,
  };
}
export default connect(mapStateToProps, { fetchPriceLists, fetchShops })(
  SalesReportContainer
);

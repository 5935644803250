/*
list all cards card type registration
add card owner
list all card owners
edit card owner details
remove card owner
*/

import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import {
  fetchCardInstances,
  resetDeleteCardInstance,
} from "../../../Redux/actions";

import CardInstanceList from "../../CardInstance/CardInstanceList";
import { T, getErrorMessage } from "../../Common/Helpers";

const CARD_TYPE = "registrationcard";
const CARD_INSTANCE_DELETE_SUCCESS =
  "Product Registration deleted successfully";
const CONFIRM_DELETE_HEADER = "Delete Product Registration";
const LIST_HEADER = "Product Registerations";
const VALID_HEADER = "Valid Registration Card";
const CARD_TITLE = "Registered Product";
const URL = "/app/productregistration";
const MODULE_ID = 38;

class ProductRegistrationCardList extends Component {
  componentDidMount() {
    const authKey = localStorage.getItem("x-auth-key");
    const ws_id = this.props.ws_id;
    const application_id = this.props.activeWorkspace.cnc_application;

    if (
      this.props.cardinstances.editCardInstanceStatus === "init" ||
      this.props.cardinstances.addCardInstancesStatus === "init"
    ) {
      this.props.fetchCardInstances({
        moduleId: MODULE_ID,
        authKey,
        ws_id,
        application_id,
        cardType: CARD_TYPE,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== "error" && fetchCardInstances === "error") {
      const errorObj = getErrorMessage(
        this.props.cardinstances.error,
        T("Error, fetching product registration ")
      );
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
    }

    if (this.props.cardinstances.deleteCardInstanceStatus === "success") {
      toast({
        type: "success",
        title: T("Success"),
        description: T(CARD_INSTANCE_DELETE_SUCCESS),
        time: 5000,
      });
      this.props.resetDeleteCardInstance();
      const authKey = localStorage.getItem("x-auth-key");
      const ws_id = this.props.ws_id;
      const application_id = this.props.activeWorkspace.cnc_application;
      this.props.fetchCardInstances({
        moduleId: MODULE_ID,
        authKey,
        ws_id,
        application_id,
        cardType: CARD_TYPE,
      });
    } else if (this.props.cardinstances.deleteCardInstancesStatus === "error") {
      const errorObj = getErrorMessage(
        this.props.cardinstances.error,
        T("Error while deleting membership")
      );
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
    }
    /*if (localStorage.getItem('membercards') === null && this.props.card.data) {
      localStorage.setItem('membercards', JSON.stringify(this.props.card.data));
    }*/
  }

  render() {
    const params = {
      wsId: this.props.ws_id,
      applicationId: this.props.activeWorkspace.cnc_application,
      cardType: CARD_TYPE,
      listHeader: LIST_HEADER,
      validHeader: VALID_HEADER,
      confirmDeleteHeader: CONFIRM_DELETE_HEADER,
      cardTitle: CARD_TITLE,
      url: URL,
      moduleId: MODULE_ID,
    };

    if (
      this.props.cardinstances &&
      this.props.cardinstances.cardInstancesStatus === "success"
    ) {
      return (
        <CardInstanceList
          cardinstances={this.props.cardinstances.data}
          history={this.props.history}
          params={params}
          cards={this.props.card.data}
        />
      );
    }
    return null;
  }
}

function mapStateToProps({ workspace, cardinstances, card }) {
  return {
    workspace,
    cardinstances,
    ws_id: workspace.ws_id,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    card,
  };
}

export default connect(mapStateToProps, {
  fetchCardInstances,
  resetDeleteCardInstance,
})(ProductRegistrationCardList);

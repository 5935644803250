import axios from 'axios';
import FileSaver from 'file-saver';
import config from '../../Config/AEConfig';
import {
  FETCH_SALES_REPORT_WAITING,
  FETCH_SALES_REPORT_SUCCESS,
  FETCH_SALES_REPORT_ERROR,
  FETCH_SALES_REPORT_RESET,
  FETCH_SALES_REPORT_DOWNLOAD_WAITING,
  FETCH_SALES_REPORT_DOWNLOAD_SUCCESS,
  FETCH_SALES_REPORT_DOWNLOAD_ERROR,
  FETCH_SALES_REPORT_DOWNLOAD_RESET,
  FETCH_SOLD_ITEMS_REPORT_WAITING,
  FETCH_SOLD_ITEMS_REPORT_SUCCESS,
  FETCH_SOLD_ITEMS_REPORT_ERROR,
  FETCH_SOLD_ITEMS_REPORT_RESET,
  FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_WAITING,
  FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_SUCCESS,
  FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_ERROR,
  FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_RESET,
} from '../Types';

import ErrorAction from './ErrorAction';

const MODULE_ID = 24;

export const downloadSalesReport =
  ({
    authKey,
    companyId,
    lang,
    fileFormat,
    range,
    all,
    sl_price_list,
    sl_shop,
    searchterm,
    sort,
    ws_id,
    status,
  }) =>
  dispatch => {
    dispatch({ type: FETCH_SALES_REPORT_DOWNLOAD_WAITING });
    const optionalParams = {
      all,
      sl_price_list,
      sl_shop,
      searchterm,
      sort,
      status,
    };
    const urlParams = Object.keys(optionalParams).reduce((acc, key) => {
      if (optionalParams[key]) {
        let temp = acc;
        temp += `&${key}=${optionalParams[key]}`;
        return temp;
      }
      return acc;
    }, '');
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url =
      `${config.BASE_URL}/sales?report_type=${fileFormat}&lang=${lang}&ws_id=${ws_id}&module_id=${MODULE_ID}&company_id=${companyId}` +
      `&startdate=${range.start}&enddate=${range.end}&${urlParams}`;
    // console.log('download report: ', url);
    axios
      .get(url, { responseType: 'blob' })
      .then(response => {
        // console.log('Response: ', response);
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        // console.log(
        //   'Response: ',
        //   response,
        //   '\nContent - disposition: ',
        //   response.headers['content-disposition'].split(';')[1].split('=')[1]
        // );
        let fileName;
        try {
          fileName = response.headers['content-disposition']
            .split(';')[1]
            .split('=')[1];
        } catch {
          fileName = `sales-report-${new Date().getTime()}.${fileFormat}`;
        }
        FileSaver(blob, fileName);
        dispatch({
          type: FETCH_SALES_REPORT_DOWNLOAD_SUCCESS,
          payload: response,
        });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              FETCH_SALES_REPORT_DOWNLOAD_ERROR
            )
          : FETCH_SALES_REPORT_DOWNLOAD_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const downloadSoldItemsReport =
  ({
    authKey,
    companyId,
    lang,
    fileFormat,
    range,
    all,
    sl_price_list,
    sl_shop,
    searchterm,
    sort,
    ws_id,
    status,
  }) =>
  dispatch => {
    dispatch({ type: FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_WAITING });
    const optionalParams = {
      all,
      sl_price_list,
      sl_shop,
      searchterm,
      sort,
      status,
    };
    const urlParams = Object.keys(optionalParams).reduce((acc, key) => {
      if (optionalParams[key]) {
        let temp = acc;
        temp += `&${key}=${optionalParams[key]}`;
        return temp;
      }
      return acc;
    }, '');
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url =
      `${config.BASE_URL}/sales/items?report_type=${fileFormat}&lang=${lang}&ws_id=${ws_id}&module_id=${MODULE_ID}&company_id=${companyId}` +
      `&startdate=${range.start}&enddate=${range.end}&${urlParams}`;
    console.log('dowload sales items report url: ', url);
    axios
      .get(url, { responseType: 'blob' })
      .then(response => {
        // console.log('Response: ', response);
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        let fileName;
        try {
          fileName = response.headers['content-disposition']
            .split(';')[1]
            .split('=')[1];
        } catch {
          fileName = `sold-items-report-${new Date().getTime()}.${fileFormat}`;
        }
        FileSaver(blob, fileName);
        dispatch({
          type: FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_SUCCESS,
          payload: response,
        });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_ERROR
            )
          : FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_ERROR;
        dispatch({ type, payload: error });
      });
  };

//sales/report/csv?module_id=24&ws_id=1&application_id=2&sort=sales_id:asc&searchterm=&startdate=2017-08-29&enddate=&all=true&sl_price_list=
// all := true -> Include Cancelled sales
export const fetchSalesReport =
  ({
    authKey,
    start,
    companyId,
    range,
    all,
    sl_price_list,
    sl_shop,
    searchterm,
    sort,
    ws_id,
    status,
  }) =>
  dispatch => {
    dispatch({ type: FETCH_SALES_REPORT_WAITING });
    const optionalParams = {
      start,
      all,
      sl_price_list,
      sl_shop,
      searchterm,
      sort,
      status,
    };
    const urlParams = Object.keys(optionalParams).reduce((acc, key) => {
      if (optionalParams[key]) {
        let temp = acc;
        temp += `&${key}=${optionalParams[key]}`;
        return temp;
      }
      return acc;
    }, '');
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url =
      `${config.BASE_URL}/sales?ws_id=${ws_id}&module_id=${MODULE_ID}&company_id=${companyId}` +
      `&startdate=${range.start}&enddate=${range.end}${urlParams}`;
    // console.log('salesreport URL: ', url);
    axios
      .get(url)
      .then(response =>
        dispatch({ type: FETCH_SALES_REPORT_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_SALES_REPORT_ERROR)
          : FETCH_SALES_REPORT_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchSoldItemsReport =
  ({
    authKey,
    start,
    companyId,
    range,
    all,
    sl_price_list,
    sl_shop,
    searchterm,
    sort,
    ws_id,
    status,
  }) =>
  dispatch => {
    dispatch({ type: FETCH_SOLD_ITEMS_REPORT_WAITING });
    const optionalParams = {
      start,
      all,
      sl_price_list,
      sl_shop,
      searchterm,
      sort,
      status,
    };
    const urlParams = Object.keys(optionalParams).reduce((acc, key) => {
      if (optionalParams[key]) {
        let temp = acc;
        temp += `&${key}=${optionalParams[key]}`;
        return temp;
      }
      return acc;
    }, '');
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url =
      `${config.BASE_URL}/sales/items?ws_id=${ws_id}&module_id=${MODULE_ID}&company_id=${companyId}` +
      `&startdate=${range.start}&enddate=${range.end}${urlParams}`;
    // console.log('salesreport items URL: ', url);
    axios
      .get(url)
      .then(response =>
        dispatch({ type: FETCH_SOLD_ITEMS_REPORT_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_SOLD_ITEMS_REPORT_ERROR)
          : FETCH_SOLD_ITEMS_REPORT_ERROR;
        dispatch({ type, payload: error });
      });
  };
export const resetFetchSalesReport = () => ({ type: FETCH_SALES_REPORT_RESET });
export const resetDownloadSalesReport = () => ({
  type: FETCH_SALES_REPORT_DOWNLOAD_RESET,
});
export const resetFetchSoldItemsReport = () => ({
  type: FETCH_SOLD_ITEMS_REPORT_RESET,
});
export const resetDownloadSoldItemsReport = () => ({
  type: FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_RESET,
});

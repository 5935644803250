import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Segment, Checkbox } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { connect } from 'react-redux';
import { T } from '../Common/Helpers';
import { listCategoryTypes } from './Common.js';

import {
  editNotificationCategory,
  deleteNotificationCategory,
  fetchMessageCategoryTypes,
} from '../../Redux/actions';

class EditNotificationCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDeleteConfirmation: false,
      mc_type: this.props.category.mc_type,
      mc_active_by_default: this.props.category.mc_active_by_default,
    };

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }

  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    this.props.fetchMessageCategoryTypes(authKey, ws_id);
  }

  onValidSubmit(formData) {
    const category = {
      ...formData,
      mc_active_by_default: this.state.mc_active_by_default,
    };
    const categoryId = this.props.categoryId;
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;

    this.props.editNotificationCategory({
      categoryId,
      category,
      authKey,
      ws_id,
    });
    this.props.closeModal();
  }

  onConfirmDelete() {
    // const companyId = this.props.match.params.companyId;
    const categoryId = this.props.categoryId;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.deleteNotificationCategory({
      categoryId,
      authKey,
      ws_id: this.props.workspace.activeWorkspace.id,
    });
    this.setState({ openDeleteConfirmation: false });
    this.props.closeModal();
    // console.log('delete confirmed');
  }

  onDeleteClick() {
    this.setState({ openDeleteConfirmation: true });
  }

  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }

  onCancel() {
    // this.props.history.push('/app/companies/list');
    this.props.closeModal();
  }

  getApplicationsList() {
    const apps = this.props.auth.apps;
    // console.log(apps);
    return _.map(apps, option => ({
      text: option.ap_name,
      value: option.application_id,
    }));
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Segment>
        <Form
          onValidSubmit={this.onValidSubmit.bind(this)}
          ref={ref => (this.form = ref)}
        >
          <Form.Group>
            <Form.Input
              required
              name="mc_name"
              value={this.props.category.mc_name}
              label={T('Category Name')}
              width={16}
              autoFocus
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.Select
                placeholder={T('Select Category Type')}
                label={T('Category Type')}
                name="mc_type"
                options={listCategoryTypes(this.props.categoryTypes)}
                onChange={(e, { name, value }) =>
                  this.handleInputChange(name, value)
                }
                value={this.state.mc_type}
                search
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <Checkbox
                toggle
                width={2}
                className="ui toggle checkbox"
                name="mc_active_by_default"
                label={T('Selected by Default in App')}
                checked={
                  this.state.mc_active_by_default
                    ? this.state.mc_active_by_default
                    : false
                }
                onChange={(e, { name, checked }) =>
                  this.handleInputChange(name, checked)
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Button content={T('Save')} color="green" />
            <Button
              type="button"
              color="black"
              content={T('Cancel')}
              onClick={() => this.onCancel()}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({ auth, workspace, notifications }) {
  return {
    auth,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    categoryTypes: notifications.categoryTypes,
  };
}

export default connect(mapStateToProps, {
  editNotificationCategory,
  deleteNotificationCategory,
  fetchMessageCategoryTypes,
})(EditNotificationCategory);

import React, { Component } from 'react';
import { Form, Button, Header, Segment } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
//import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import FileUpload from '../Common/FileUpload';
import { T, getDateTimeFormat } from '../Common/Helpers';
import { deleteFile } from '../../Redux/actions';
import { PopupContent } from '../Common/PopupContent';

const UPLOAD_TARGET = 'product';
const MODULE_ID = 17;
const RECOMMEND = 'recommend';
const DATE_TIME_FORMAT = getDateTimeFormat();

const POPUP_ICON_SIZE = 'large';
const POPUP_COLOR = 'grey';
const POPUP_WIDE = 'very';
const POPUP_CONTENT_1 =
  'Optional. Define the start date when product is recommended in shop listings. Delete the recommendation disabeling the Recommend product.';
const POPUP_CONTENT_2 =
  'Optional. Define the date when product is not recommended anymore in shop listings. Delete the recommendation by disabeling the Recommend product.';

const imageStyle = {
  height: 200,
  width: 200,
  minHeight: 193.5,
  minWidth: 193.5,
};

class ProductGeneralFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.product,
      pr_parent: this.props.parentId,
      imageDeleteData: null,
    };
  }
  onCancel = () => {
    this.props.history.goBack();
  };

  onDrop(files) {
    this.setState({
      files: files.map(file =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      ),
      pr_image_updated: true,
    });
    //, () => this.uploadFile()
  }
  onValidSubmit = () => {
    const valid = this.validateInputControl();
    if (valid) {
      if (this.state.imageDeleteData !== null) {
        this.props.deleteFile(
          this.state.imageDeleteData,
          this.props.workspace.ws_id
        );
        this.setState({
          pr_main_image: null,
          pr_main_image_file_id: null,
        });
      }
      const product = _.pickBy(
        this.state,
        (value, key) => !key.endsWith('Error')
      );
      product.pr_template = !product.pr_template
        ? null
        : parseInt(product.pr_template, 10);
      this.props.onValidSubmit({ ...product, pr_sales_unit: 1 });
    }
  };
  getTaxList() {
    if (this.props.taxList) {
      return _.map(this.props.taxList, tax => ({
        text: tax.tc_description,
        value: tax.tax_class_id,
      }));
    }
    return [];
  }

  setImageDeleted(imageDeleteData) {
    this.setState({ imageDeleteData });
  }

  validateInputControl = () => {
    const fields = [
      'pr_name',
      'pr_short_description',
      'pr_long_description',
      'pr_code',
      'pr_type',
      'pr_tax_class_id',
    ];
    const invalidFields = _.filter(
      fields,
      field =>
        this.state[field] === undefined ||
        (typeof this.state[field] === 'string' &&
          this.state[field].trim() === '')
    );
    if (invalidFields.length) {
      const errorFields = _.reduce(
        invalidFields,
        (acc, field) => ({ ...acc, [`${field}Error`]: true }),
        {}
      );
      this.setState({ ...errorFields });
      return false;
    }
    return true;
  };

  handleChange = (e, { name, value, checked }) => {
    if (name === RECOMMEND) {
      if (checked) {
        this.setState({
          pr_recommended_from: moment(),
          pr_recommended_to: null,
        });
      } else {
        this.setState({ pr_recommended_from: null, pr_recommended_to: null });
      }
      this.setState({ [name]: typeof checked === 'boolean' ? checked : value });
    } else {
      this.setState({
        [`${name}Error`]: false,
        [name]: typeof checked === 'boolean' ? checked : value,
      });
    }
  };

  handleDateTimeChange = (event, { name, value }) => {
    //const dateTime = moment(value, DATE_TIME_FORMAT, true);
    const dateTime = moment(value, DATE_TIME_FORMAT);
    const start = this.state.pr_recommended_from
      ? moment(this.state.pr_recommended_from, DATE_TIME_FORMAT)
      : null;
    const stop = this.state.pr_recommended_to
      ? moment(this.state.pr_recommended_to, DATE_TIME_FORMAT)
      : null;

    if (!dateTime.isValid()) {
      console.error('inVALID date');
      return;
    }
    switch (name) {
      case 'pr_recommended_from':
        if (stop && dateTime.isAfter(stop)) {
          this.setState({ pr_recommended_from_error: true });
          return;
        }
        this.setState({
          pr_recommended_from: dateTime.toISOString(),
          pr_recommended_from_error: false,
        });
        break;
      case 'pr_recommended_to':
        if (start && dateTime.isBefore(start)) {
          this.setState({ pr_recommended_to_error: true });
          return;
        }
        this.setState({
          pr_recommended_to: dateTime.toISOString(),
          pr_recommended_to_error: false,
        });
        break;
      default:
        break;
    }
  };

  deleteImage = (e, { file }) => {
    const files = this.state.files;
    const index = _.findIndex(files, item => item.preview === file.preview);
    if (index !== -1) {
      files.splice(index, 1);
    }
    this.setState({ pr_image_deleted: true, deleted_file: file, files });
    //this.props.deleteImage(file);
  };

  imageUpdate(urlSmall, urlLarge, key, file_id) {
    this.setState({ pr_main_image: urlLarge, pr_main_image_file_id: file_id });
  }

  render() {
    const pr_type = this.state.pr_type;
    return (
      <Segment>
        <Form error onSubmit={() => this.onValidSubmit()}>
          {this.state.parentId && (
            <Form.Group>
              <Form.Input
                required
                name="pr_parent"
                label={T('Parent Id')}
                value={this.state.pr_parent}
                disabled
              />
            </Form.Group>
          )}
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-start',
                flexBasis: '300px',
                flexDirection: 'column',
              }}
            >
              <Form.Input
                required
                name="pr_name"
                error={this.state.pr_nameError || false}
                label={T('Product name')}
                placeholder={T('Orange juice')}
                value={this.state.pr_name}
                onChange={this.handleChange}
              />
              <Form.Input
                required
                name="pr_short_description"
                error={this.state.pr_short_descriptionError || false}
                label={T('Name On Receipt (Short Description)')}
                placeholder={T('Juice')}
                value={this.state.pr_short_description || ''}
                onChange={this.handleChange}
              />
              <Form.TextArea
                required
                rows={4}
                name="pr_long_description"
                error={this.state.pr_long_descriptionError || false}
                label={T('Description in product listing')}
                placeholder={T('Glass of fresh orange juice')}
                value={this.state.pr_long_description}
                onChange={this.handleChange}
              />
              <Form.Group widths="equal">
                {pr_type !== null &&
                pr_type !== 'entry' &&
                pr_type !== 'engage_ticket' ? (
                  <Form.Field required>
                    <Form.Dropdown
                      search
                      name="pr_type"
                      error={this.state.pr_typeError || false}
                      label={T('Product type')}
                      placeholder={T('Select product type')}
                      value={this.state.pr_type}
                      fluid
                      selection
                      selectOnNavigation
                      noResultsMessage={T('No result')}
                      onChange={this.handleChange}
                      options={this.props.productTypes}
                      required
                      style={this.state.pr_typeErrorStyle}
                    />
                  </Form.Field>
                ) : null}
                <Form.Dropdown
                  search
                  error={this.state.pr_tax_class_idError || false}
                  name="pr_tax_class_id"
                  label={T('Tax class')}
                  placeholder={T('Select tax class')}
                  fluid
                  selection
                  clearable
                  selectOnNavigation
                  noResultsMessage={T('No result')}
                  onChange={this.handleChange}
                  options={this.getTaxList()}
                  value={this.state.pr_tax_class_id}
                  required
                />
                <Form.Input
                  name="pr_template"
                  disabled
                  //label={T('Product Template Id')}
                  type="hidden"
                  value={this.state.pr_template || ''}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Dropdown
                    search
                    name="pr_out_of_stock_message"
                    label={T('Out of stock message')}
                    placeholder={T('Product is out of stock')}
                    fluid
                    selection
                    clearable
                    selectOnNavigation
                    noResultsMessage={T('No result')}
                    options={this.props.stockMessages}
                    onChange={this.handleChange}
                    value={this.state.pr_out_of_stock_message}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Checkbox
                  toggle
                  name="pr_separate_ticket"
                  label={T(
                    'Create a separate ticket for each product purchased'
                  )}
                  checked={this.state.pr_separate_ticket || false}
                  onChange={this.handleChange}
                />
                <Form.Checkbox
                  toggle
                  name="recommend"
                  label={T('Recommend product')}
                  checked={
                    this.state.recommend ||
                    this.state.pr_recommended_from !== null
                  }
                  onChange={this.handleChange}
                />
                <Form.Checkbox
                  toggle
                  width={4}
                  label={T('Enable')}
                  // defaultChecked={this.state.pr_active || false}
                  checked={this.state.pr_active || false}
                  name="pr_active"
                  onChange={this.handleChange}
                />
              </Form.Group>
              {this.state.pr_recommended_from !== null && (
                <Form.Group widths="equal">
                  <Form.Field>
                    <DateTimeInput
                      // style={{ minWidth: '400px', marginRight: '5px' }}
                      closable
                      //placeholder={moment().format('ddd, DD MMM YYYY HH:mm')}
                      name="pr_recommended_from"
                      label={
                        <>
                          <Header as="label">
                            {T('Recommend Product Starts')}
                            <PopupContent
                              content={POPUP_CONTENT_1}
                              size={POPUP_ICON_SIZE}
                              color={POPUP_COLOR}
                              wide={POPUP_WIDE}
                              style={{ marginLeft: '5px' }}
                            />
                          </Header>
                        </>
                      }
                      //label={T('Recommend Product Starts')}
                      //value={this.state.pr_recommended_from || ''}
                      value={
                        this.state.pr_recommended_from
                          ? moment(this.state.pr_recommended_from).format(
                              DATE_TIME_FORMAT
                            )
                          : ''
                      }
                      onChange={this.handleDateTimeChange}
                      error={this.state.pr_recommended_from_error}
                      dateTimeFormat={DATE_TIME_FORMAT}
                      autoComplete="off"
                      history="off"
                      animation="off"
                    />
                  </Form.Field>
                  <Form.Field>
                    <DateTimeInput
                      style={{ minWidth: '400px', marginRight: '5px' }}
                      closable
                      //placeholder={moment().format('ddd, DD MMM YYYY HH:mm')}
                      minDate={moment(this.state.pr_recommended_from).format(
                        DATE_TIME_FORMAT
                      )}
                      name="pr_recommended_to"
                      label={
                        <>
                          <Header as="label">
                            {T('Recommend Product Ends')}
                            <PopupContent
                              content={POPUP_CONTENT_2}
                              size={POPUP_ICON_SIZE}
                              color={POPUP_COLOR}
                              wide={POPUP_WIDE}
                              style={{ marginLeft: '5px' }}
                            />
                          </Header>
                        </>
                      }
                      //value={this.state.pr_recommended_to || ''}
                      value={
                        this.state.pr_recommended_to
                          ? moment(this.state.pr_recommended_to).format(
                              DATE_TIME_FORMAT
                            )
                          : ''
                      }
                      //value={this.state.pr_recommended_to ? moment(this.state.pr_recommended_to).format(DATE_TIME_FORMAT) : null}
                      onChange={this.handleDateTimeChange}
                      error={this.state.pr_recommended_to_error}
                      dateTimeFormat={DATE_TIME_FORMAT}
                      autoComplete="off"
                      history="off"
                      animation="off"
                    />
                  </Form.Field>
                </Form.Group>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                marginLeft: '10px',
                justifyContent: 'flex-start',
                flexBasis: '300px',
                flexDirection: 'column',
              }}
            >
              <Form.Input
                required
                name="pr_code"
                error={this.state.pr_codeError || false}
                label={T('Product code')}
                placeholder={T('orangejuice')}
                value={this.state.pr_code}
                onChange={this.handleChange}
              />
              <Form.Field>
                <label>{T('Product image')}</label>
                <FileUpload
                  id={this.state.pr_merchant_id}
                  file_key={this.state.key}
                  file_url={this.state.pr_main_image || undefined}
                  file_id={this.state.pr_main_image_file_id}
                  ws_id={this.props.ws_id}
                  target={UPLOAD_TARGET}
                  module_id={MODULE_ID}
                  callback={this.imageUpdate.bind(this)}
                  //deletecallback={this.imageDelete.bind(this)}
                  deletecallback={this.setImageDeleted.bind(this)}
                  style={imageStyle}
                />
              </Form.Field>
            </div>
          </div>
          <Segment>
            <Header as="h4">{T('Prices')}</Header>
            {this.props.priceListHandler}
          </Segment>
          <Form.Group>
            <Button type="submit" content={T('Save')} color="green" />
            <Button
              type="button"
              secondary
              content={T('Cancel')}
              onClick={() => this.onCancel()}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({ workspace, file }) {
  return {
    workspace,
  };
}

export default connect(mapStateToProps, {
  deleteFile,
})(ProductGeneralFields);

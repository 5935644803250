import React, { Component } from 'react';
import { Form, Button, Header, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  editProductAttributes,
  resetEditProductAttributes,
} from '../../Redux/actions';
import { T } from '../Common/Helpers';

const styles = {
  attributeListContainer: {
    maxWidth: '150px',
    display: 'inline-block',
    flexDirection: 'row',
    flexWrap: 'wrap',
    background: '#F0F0F0',
    height: '30px',
    lineHeight: '30px',
    verticalAlign: 'middle',
    marginRight: '10px',
    marginBottom: '5px',
  },
  attributeParagraph: {
    display: 'inline-block',
    marginRight: '5px',
    padding: '5px',
    height: '20px',
    maxWidth: '120px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  commandText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    marginRight: '2px',
  },
  addbuttons: {
    marginLeft: '5px',
  },
  deleteIcon: { cursor: 'pointer', marginLeft: '5px', verticalAlign: 'top' },
};
class ProductAttributes extends Component {
  constructor(props) {
    super(props);
    // console.log('Props: ', this.props);
    const tempSelectected = _.reduce(
      this.props.data,
      (acc, ele) => {
        const temp = { ...acc };
        temp[ele.att_name] = [
          ...(temp[ele.att_name] || []),
          `${ele.attribute_id}#${ele.at_name}`,
        ];
        return temp;
      },
      {}
    );
    this.state = {
      showAttributes: {},
      pr_attribute: '',
      pr_attribute_options: '',
      selectedAttributes: tempSelectected,
    };
  }

  onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const productId = this.props.productId;
    const { ws_id } = this.props;
    const { merchantId } = this.props;
    let attributes = [];
    const selectedAttribues = { ...this.state.selectedAttributes };
    attributes = _.reduce(
      selectedAttribues,
      (acc, att) => acc.concat(att),
      []
    ).map(ele => parseInt(ele.split('#')[0], 10));
    // Object.keys(selectedAttribues).forEach(key => attributes.concat(selectedAttribues[key]));
    // attributes = _.map(attributes, attribute => attribute.split('#')[0]);
    // console.log('Attributes: ', attributes, '\nProduct Id: ', productId);
    if (attributes.length) {
      const data = {
        pr_merchant_id: merchantId,
        product_attributes: attributes,
      };
      const authKey = localStorage.getItem('x-auth-key');
      this.props.editProductAttributes(authKey, productId, data, ws_id);
    }
  };
  getAttributeOptions = () => {
    const { pr_attribute } = this.state;
    const options =
      pr_attribute === ''
        ? []
        : this.props.attributes[pr_attribute].map(ele => ({
            text: ele.at_name,
            value: `${ele.attribute_id}#${ele.at_name}`,
          }));
    return options;
  };
  addAllAttributes = () => {
    const allAttributes = this.getAttributeOptions();
    if (allAttributes.length) {
      const { pr_attribute } = this.state;
      const values = _.map(allAttributes, att => att.value);
      // const modifiedAttributes = { ...this.state.selectedAttributes[pr_attribute], ...values };
      const selectedAttributes = {
        ...this.state.selectedAttributes,
        [pr_attribute]: values,
      };
      const showAttributes = {
        ...this.state.showAttributes,
        [pr_attribute]: false,
      };
      this.setState({ selectedAttributes, showAttributes });
    }
  };
  deleteAll = keyName => {
    const selectedAttributes = { ...this.state.selectedAttributes };
    const existingOptions = selectedAttributes[keyName];
    const showAttributes = { ...this.state.showAttributes };
    if (existingOptions) {
      delete selectedAttributes[keyName];
      delete showAttributes[keyName];
      this.setState({ selectedAttributes, showAttributes });
    }
  };
  clearAll = () => {
    const selectedAttributes = {};
    this.setState({ selectedAttributes });
  };
  expandAll = () => {
    // debugger;
    const { showAttributes } = this.state;
    const temp = {};
    _.forEach(Object.keys(showAttributes), key => {
      temp[key] = true;
    });
    this.setState({ showAttributes: temp });
  };
  toggleAttribues = attributeName => {
    const { showAttributes } = this.state;
    showAttributes[attributeName] = !showAttributes[attributeName];
    this.setState({ showAttributes });
  };
  handleChange = (e, { name, value }) => {
    // console.log('Name: ', name, '\nValue: ', value);
    let tempObj = {};
    if (name === 'pr_attribute') {
      tempObj = { pr_attribute_options: '' };
    }
    this.setState({ [name]: value, ...tempObj });
  };

  makeCancelabelAttribute = (attributeName, attribute, index) => (
    <div
      style={styles.attributeListContainer}
      key={`att-container-${attributeName}-${index}`}
    >
      <p key={`${attributeName}-${index}`} style={styles.attributeParagraph}>
        {attribute.substr(attribute.indexOf('#') + 1)}
      </p>
      <Icon
        style={styles.deleteIcon}
        key={`icon-${attributeName}-${index}`}
        color="red"
        name="trash"
        onClick={() => this.removeSelectedAttribute(attributeName, index)}
      />
    </div>
  );

  addSelectedAttribute = () => {
    const { pr_attribute, pr_attribute_options } = this.state;
    if (pr_attribute !== '' && pr_attribute_options !== '') {
      const { selectedAttributes } = this.state;
      const attrArr = selectedAttributes[pr_attribute] || [];
      if (attrArr.indexOf(pr_attribute_options) === -1) {
        const { showAttributes } = this.state;
        showAttributes[pr_attribute] = true;
        selectedAttributes[pr_attribute] = [
          ...(selectedAttributes[pr_attribute] || []),
          pr_attribute_options,
        ];

        this.setState({ selectedAttributes, showAttributes });
      }
    }
    // const cancelabelAttribute = this.makeCancelabelAttribute(attributeName, attributeValue, index);
  };

  removeSelectedAttribute = (attributeName, index) => {
    const { selectedAttributes } = this.state;
    selectedAttributes[attributeName].splice(index, 1);
    if (selectedAttributes[attributeName].length === 0) {
      delete selectedAttributes[attributeName];
    }
    this.setState({ selectedAttributes });
  };

  constructCancelabelAttributes = attributeName => {
    if (attributeName === '') {
      return [];
    }
    return _.map(
      this.state.selectedAttributes[attributeName],
      (attribute, index) =>
        this.makeCancelabelAttribute(attributeName, attribute, index)
    );
  };
  render() {
    const keys = Object.keys(this.props.attributes).map(ele => ({
      text: ele,
      value: ele,
    }));
    const attributeOptions = this.getAttributeOptions();
    // console.log('State: ', this.state, '\nProps: ', this.props, '\nKeys: ', keys, '\nOptions: ', attributeOptions);
    return (
      <React.Fragment>
        <Form style={{ marginLeft: '50px' }}>
          <Header> {T('Add Attributes')} </Header>
          <Form.Group>
            <Form.Field width={3}>
              <Form.Select
                name="pr_attribute"
                placeholder={T('Select Attribute Name')}
                options={keys}
                value={this.state.pr_attribute.value}
                onChange={this.handleChange}
                search
              />
            </Form.Field>
            <Form.Field width={3}>
              <Form.Select
                name="pr_attribute_options"
                options={attributeOptions}
                placeholder={T('Select Attribute Value')}
                value={this.state.pr_attribute_options.value}
                onChange={this.handleChange}
                search
              />
            </Form.Field>
            <Button.Group>
              <Button
                style={styles.addbuttons}
                content={T('Add')}
                icon="plus"
                size="small"
                primary
                onClick={this.addSelectedAttribute}
              />
              <Button
                style={styles.addbuttons}
                content={T('Add All')}
                icon="plus"
                size="small"
                primary
                onClick={this.addAllAttributes}
              />
            </Button.Group>
          </Form.Group>
          <Header> {T('Currently Selected Attributes')} </Header>
          <div style={{ display: 'block', textAlign: 'right' }}>
            <span onClick={() => this.expandAll()} style={styles.commandText}>
              {T('Expand All')}
            </span>
            <span onClick={() => this.clearAll()} style={styles.commandText}>
              {T('Clear All')}
            </span>
          </div>
          <hr />
          <React.Fragment>
            {_.map(this.state.selectedAttributes, (obj, keyName) => (
              <Form.Group key={`form-group-${keyName}`}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      marginBottom: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.toggleAttribues(keyName)}
                  >
                    {this.state.showAttributes[keyName] ? (
                      <Icon name="caret square down" size="large" />
                    ) : (
                      <Icon name="caret square right" size="large" />
                    )}
                    <Header style={{ margin: 0 }}> {keyName} </Header>
                  </div>
                  {this.state.showAttributes[keyName] && (
                    <div style={{ marginLeft: '10px' }}>
                      <div
                        key={`container-${keyName}`}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          marginBottom: '10px',
                        }}
                      >
                        {this.constructCancelabelAttributes(keyName)}
                      </div>
                      <div>
                        <Button
                          color="red"
                          size="mini"
                          icon="trash"
                          content={T('Delete All')}
                          onClick={() => this.deleteAll(keyName)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Form.Group>
            ))}
          </React.Fragment>
          <Form.Group>
            <Button
              content={T('Save')}
              icon="save"
              primary
              onClick={this.onSubmit}
            />
            {/*<Button content={T('Cancel')} icon='ban' secondary onClick={this.addAttribute} />*/}
          </Form.Group>
        </Form>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ product, workspace }) {
  return {
    merchantId: workspace.workspaces[workspace.ws_id].cnc_company,
    attributeStatus: product.attributeStatus,
    ws_id: workspace.ws_id,
  };
}
export default connect(mapStateToProps, {
  editProductAttributes,
  resetEditProductAttributes,
})(ProductAttributes);

import React, { Component } from 'react';
import { Select, Button, Segment, Checkbox, Confirm } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import moment from 'moment';
import { T } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';
import { PopupContent } from '../Common/PopupContent';
import {
  editNotification,
  addNotification,
  resetEditNotification,
  fetchNotification,
  resetFetchNotification,
  fetchNotificationCategories,
  resetFetchNotificationCategories,
} from '../../Redux/actions';
// const errorStyle = {
//               backgroundColor: '#FFF6F6',
//               borderColor: '#E0B4B4',
//               color: '#9F3A38',
//               WebkitBoxShadow: 'none',
//                boxShadow: 'none'
//           };

class EditNotification extends Component {
  constructor(props) {
    super(props);
    const notificationDate = moment(
      props.notification.me_schedule_time
    ).toISOString();
    this.state = {
      selectedCategory: props.notification.me_category_id,
      categoryError: {},
      confirmSend: false,
      notification: {},
      notificationDate,
      sendItNow: props.notification.me_send_now,
      messagePriority: props.notification.me_priority,
    };
    this.onChangeCategory = this.onChangeMessageCategory.bind(this);
    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onChangeScheduleDate = this.onChangeScheduleDate.bind(this);
    this.onChangeSendItNow = this.onChangeSendItNow.bind(this);
    this.onChangePriority = this.onChangePriority.bind(this);
    this.onCancelSend = this.onCancelSend.bind(this);
    this.onConfirmSend = this.onConfirmSend.bind(this);
  }

  componentDidMount() {
    // const notificationId = this.props.match.params.notificationId;
    // const notificationId = this.props.notificationId;
    const authKey = localStorage.getItem('x-auth-key');
    const { activeWorkspace } = this.props.workspace;
    if (activeWorkspace && activeWorkspace.id) {
      this.props.fetchNotificationCategories({
        authKey,
        ws_id: activeWorkspace.id,
        sortByColumn: null,
        direction: null,
        application_id: activeWorkspace.cnc_application,
      });
    }

    //appId should come ideally from th workspace
    // this.props.fetchNotificationCategories({
    //   authKey,
    //   sortByColumn: null,
    //   direction: null,
    //   appId: this.props.applications[0].application_id
    // });
    // this.props.fetchNotification(notificationId, authKey, ws_id);
  }

  onValidSubmit(formData) {
    const notification = {
      me_schedule_time: this.state.notificationDate,
      me_category_id: this.state.selectedCategory,
      me_send_now: this.state.sendItNow,
      me_priority: this.state.messagePriority,
      ...formData,
    };

    if (this.state.sendItNow) {
      this.setState({ confirmSend: true, notification });
    } else {
      this.sendMessage(notification);
    }
  }

  onCancel(e) {
    this.props.closeModal(e);
  }

  onChangeMessageCategory(event, value) {
    this.setState({ selectedCategory: value.value, categoryError: {} });
  }

  onChangeScheduleDate(event, { value }) {
    const notificationDate = moment(value, 'l HH:mm').toISOString();
    this.setState({ notificationDate, sendItNow: false });
  }

  onChangeSendItNow() {
    if (!this.state.sendItNow) {
      let notificationDate = moment().toISOString();
      this.setState({ notificationDate, sendItNow: !this.state.sendItNow });
    } else {
      this.setState({ sendItNow: !this.state.sendItNow });
    }
  }

  onChangePriority() {
    this.setState({ messagePriority: !this.state.messagePriority });
  }

  onCancelSend() {
    this.setState({ confirmSend: false });
  }

  onConfirmSend() {
    const notification = this.state.notification;
    this.setState({ confirmSend: false });
    this.sendMessage(notification);
  }

  sendMessage(notification) {
    //send again
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    const { notificationId } = this.props;
    if (this.props.sendAgain) {
      this.props.addNotification({ notification, authKey, ws_id });
      this.props.closeModal();
    } else {
      this.props.editNotification({
        notificationId,
        notification,
        authKey,
        ws_id,
      });
      this.props.closeModal();
    }
    this.props.sendNow(notification.me_send_now);
  }

  renderCategories(categoriesList) {
    const categoriesResult = _.map(categoriesList, category => ({
      key: category.message_category_id,
      text: category.mc_name,
      value: category.message_category_id,
    }));
    categoriesResult.unshift({
      key: 'select',
      value: '',
      text: T('Select a Category'),
    });
    return categoriesResult;
  }

  render() {
    const popupParams = this.props.popupParams;
    /*

    <Form.Group widths="equal">
      <Form.Input
        name='me_url'
        label={T('URL')}
        value={notification.me_url}
      />
    </Form.Group>
    */
    if (
      //this.props.fetch !== 'success' ||
      this.props.fetchAllCat !== 'success'
    ) {
      return <Spinner />;
    }
    // console.log("Notification State: ", this.state, "\nProps: ", this.props);
    const { notification } = this.props;
    // console.log('notification date ', this.state.notificationDate);
    return (
      <Segment>
        <Form
          onValidSubmit={this.onValidSubmit.bind(this)}
          ref={ref => (this.form = ref)}
        >
          <Form.Group
            key={`eidtForm-${notification.message_id}`}
            widths="equal"
          >
            <Form.Field
              control={Select}
              required
              label={T('Category')}
              name="me_category_id"
              options={this.renderCategories(this.props.categories)}
              onChange={this.onChangeCategory}
              value={this.state.selectedCategory}
              style={this.state.selectStyle}
            />
            <Form.Input
              required
              label={T('Subject')}
              name="me_subject"
              value={notification.me_subject}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.TextArea
              label={T('Message')}
              name="me_message"
              maxLength="200"
              required
              style={{ minHeight: '200px' }}
              defaultValue={notification.me_message}
            />
          </Form.Group>
          <Form.Group widths="equal">
            {!this.state.sendItNow && (
              <DateTimeInput
                required
                name="cm_valid_from"
                label={T('Valid from')}
                closable
                onChange={this.onChangeScheduleDate}
                value={
                  this.state.notificationDate
                    ? moment(this.state.notificationDate).format('l HH:mm')
                    : ''
                }
                minDate={moment(new Date()).format('l HH:mm')}
                style={{ minWidth: '225px' }}
                animation="off"
                dateTimeFormat="l HH:mm"
              />
            )}
          </Form.Group>
          <Form.Group widths="equal">
            <Checkbox
              toggle
              name="me_send_now"
              checked={this.state.sendItNow ? this.state.sendItNow : false}
              label={T('Send immediately')}
              onChange={this.onChangeSendItNow}
            />
            <Checkbox
              toggle
              checked={this.state.messagePriority}
              name="me_priority"
              label={T('High priority')}
              onChange={this.onChangePriority}
            />
            <PopupContent
              wide={popupParams.wide}
              content={popupParams.content}
              size={popupParams.size}
              color={popupParams.color}
            />
          </Form.Group>

          <Button
            content={this.state.sendItNow ? T('Send') : T('Save')}
            color="green"
          />
          <Button
            type="button"
            color="black"
            content={T('Cancel')}
            onClick={e => this.onCancel(e)}
          />
        </Form>
        <Confirm
          header={T('Send message')}
          content={T('Are you sure you want to send the message immediately?')}
          confirmButton={T('Send')}
          open={this.state.confirmSend}
          onCancel={this.onCancelSend}
          onConfirm={this.onConfirmSend}
          size="tiny"
        />
      </Segment>
    );
  }
}

function mapStateToProps({ notifications, workspace }) {
  return {
    categories: notifications.categories,
    fetch: notifications.operationState.fetch,
    fetchAllCat: notifications.operationState.fetchAllCat,
    error: notifications.error,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
  };
}

export default connect(mapStateToProps, {
  addNotification,
  editNotification,
  resetEditNotification,
  fetchNotification,
  resetFetchNotification,
  fetchNotificationCategories,
  resetFetchNotificationCategories,
})(EditNotification);

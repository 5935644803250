import axios from 'axios';
import { map } from 'lodash';
import config from '../../Config/AEConfig';
import {
  FETCH_MODULES_WAITING,
  FETCH_MODULES_SUCCESS,
  FETCH_MODULES_ERROR,
  FETCH_MODULES_RESET,
  SET_MODULE_WAITING,
  SET_MODULE_SUCCESS,
  SET_MODULE_ERROR,
} from '../Types';
import ErrorAction from './ErrorAction';

const MODULE_ID = 13;

export const fetchApplicationModules =
  (contextId, authKey, application, ws_id) => dispatch => {
    // console.log('fetch app modules ', { contextId, authKey, application });
    dispatch({ type: FETCH_MODULES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/application/appmodules/${contextId}?module_id=${MODULE_ID}&application_id=${application}&ws_id=${ws_id}`;
    axios
      .get(url)
      .then(response => {
        // console.log('fetch app modules response ', response);
        dispatch({ type: FETCH_MODULES_SUCCESS, payload: response });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_MODULES_ERROR)
          : FETCH_MODULES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const setApplicationModules =
  (authKey, application, modules, ws_id, callback) => dispatch => {
    dispatch({ type: SET_MODULE_WAITING });
    const values = {
      modules: map(modules, module => ({
        appmodule_id: module.appmodule_id,
        aam_active: module.aam_active,
      })),
    };
    // console.log('set app modules) ', application, modules);
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/application/${application}/module?module_id=${MODULE_ID}&ws_id=${ws_id}`;
    return axios
      .post(url, values)
      .then(response => {
        // console.log('set app modules values: response ', modules, response);
        callback();
        dispatch({ type: SET_MODULE_SUCCESS, payload: { response, modules } });
        // response
      })
      .catch(
        error => {
          const type = error.response
            ? ErrorAction(error.response.status, SET_MODULE_ERROR)
            : SET_MODULE_ERROR;
          dispatch({ type, payload: error });
        }
        // error
      );
  };

export function resetFetchApplicationMODULEs() {
  return { type: FETCH_MODULES_RESET };
}

/* eslint-env browser */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import {
  Form as semanticForm,
  Button,
  Segment,
  // Popup
} from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { T, getErrorMessage } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';

import {
  createPerformer,
  resetCreatePerformer,
  // fetchCountries,
  fetchVenues,
  fetchPerformer,
} from '../../Redux/actions';

const errorStyle = {
  backgroundColor: '#FFF6F6',
  borderColor: '#E0B4B4',
  color: '#9F3A38',
  WebkitBoxShadow: 'none',
  boxShadow: 'none',
};

class PerformerAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: '',
      selectedLocation: '',
      name: '',
      nameStyle: '',
      applicationId: '',
      applicationStyle: '',
      countryError: {},
      locationError: {},
    };
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeHomePlace = this.onChangeHomePlace.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const paging = false;
    const startPage = 1;
    const searchterm = null;
    const sortField = 'pl_name';
    const asc = true;
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem('x-auth-key');
    // this.props.fetchCountries(authKey, ws_id);
    this.props.fetchVenues(
      authKey,
      ws_id,
      paging,
      startPage,
      searchterm,
      sortField,
      asc
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== 'success' && this.props.status === 'success') {
      this.props.resetCreatePerformer();
      this.props.history.push(`/app/performers/${this.props.newTeam}/edit`);
    } else if (this.props.status === 'error') {
      const errorObj = getErrorMessage(this.props.error);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetCreatePerformer();
    }
  }

  onSubmit(formData) {
    let styles = {};
    let isValidForm = true;
    if (this.state.selectedCountry === '') {
      styles = { ...styles, countryError: errorStyle };
      isValidForm = false;
    }
    if (!isValidForm) {
      this.setState({ ...styles });
      return false;
    }
    const authKey = localStorage.getItem('x-auth-key');
    const wsId = this.props.workspace.ws_id;
    let inputValues = {
      ...formData,
      pf_type: 'team',
      pf_ctry: this.state.selectedCountry,
    };
    if (this.state.selectedLocation !== '') {
      inputValues = {
        ...inputValues,
        pf_home_place: this.state.selectedLocation,
      };
    }
    // console.log('Input: ', inputValues);
    if (!inputValues.pf_public) {
      inputValues = { ...inputValues, pf_public: false };
    }
    if (!formData.pf_application) {
      delete inputValues.pf_application;
    }

    this.props.createPerformer(inputValues, authKey, wsId);
  }

  onCancel() {
    this.props.history.push('/app/performers/list');
  }

  onChangeApplicationId({ value }) {
    this.setState({ applicationId: value, applicationStyle: '' });
  }

  onChangeName({ value }) {
    this.setState({ name: value, nameStyle: '' });
  }

  onChangeCountry(event) {
    this.setState({ selectedCountry: event.target.value, countryError: {} });
  }

  onChangeHomePlace(event) {
    this.setState({ selectedLocation: event.target.value, locationError: {} });
  }

  renderOptionsCountryModified(opt, label) {
    const countries = opt.slice();
    countries.unshift({ id: '', country_name: T('Select a Country') });
    return _.map(countries, option => (
      <option key={option.id} value={option.id}>
        {option[label]}
      </option>
    ));
  }
  renderOptionsLocationModified(opt, label) {
    const venues = [
      { pl_name: T('Select Home Place'), place_id: '' },
      ..._.values(opt || {}),
    ];
    return _.map(venues, option => (
      <option key={option.place_id} value={option.place_id}>
        {option[label]}
      </option>
    ));
  }

  render() {
    if (
      this.props.countries.status !== 'success' ||
      this.props.venues.fetchAll !== 'success'
    ) {
      return <Spinner />;
    }
    return (
      <Segment>
        <h3>{T('Add Team')}</h3>
        <Form
          onSubmit={this.onSubmit.bind(this)}
          ref={ref => (this.form = ref)}
        >
          <Form.Group widths="equal">
            <Form.Input
              required
              name="pf_name"
              label={T('Name')}
              placeholder={T('Name')}
              width={8}
              autoFocus
            />
            <semanticForm.Field width="8" required>
              <label name="country_label" htmlFor="pf_ctry">
                {T('Country')}
              </label>
              <select
                required
                onChange={this.onChangeCountry}
                style={{
                  height: '40px',
                  marginBottom: '10px',
                  ...this.state.countryError,
                }}
                name="pf_ctry"
              >
                {this.renderOptionsCountryModified(
                  this.props.countries.countries,
                  'country_name'
                )}
              </select>
            </semanticForm.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <semanticForm.Field width="8">
              <label name="location_label" htmlFor="pf_home_place">
                {T('Home Venue')}
              </label>
              <select
                onChange={this.onChangeHomePlace}
                style={{
                  height: '40px',
                  marginBottom: '10px',
                  ...this.state.locationError,
                }}
                name="pf_home_place"
              >
                {this.renderOptionsLocationModified(
                  this.props.venues.venues,
                  'pl_name'
                )}
              </select>
            </semanticForm.Field>
            <semanticForm.Field>
              <Form.Checkbox
                toggle
                name="pf_public"
                label={T('Public')}
                style={{ marginTop: '20px' }}
              />
            </semanticForm.Field>
          </Form.Group>
          <Button content={T('Save')} color="green" />
          <Button
            type="button"
            color="black"
            content={T('Cancel')}
            onClick={() => this.onCancel()}
          />
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({ performers, countries, venues, workspace }) {
  return {
    countries,
    venues,
    newTeam: performers.newTeam,
    status: performers.createStatus,
    error: performers.error,
    workspace,
  };
}

export default connect(mapStateToProps, {
  createPerformer,
  resetCreatePerformer,
  // fetchCountries,
  fetchVenues,
  fetchPerformer,
})(withRouter(PerformerAdd));

/* eslint-env browser */
import React, { Component } from "react";
import _ from "lodash";
import { reduxForm } from "redux-form";
import { Message, Label, Button, Segment, Header } from "semantic-ui-react";
import { toast } from "react-semantic-toasts";
import { Form } from "formsy-semantic-ui-react";
// more
import { connect } from "react-redux";
import { addPerson, fetchCountries, fetchContexts } from "../../Redux/actions";
import { T, getErrorMessage } from "../Common/Helpers";
import { Spinner } from "../Common/Spinner";

const errorStyle = {
  backgroundColor: "#FFF6F6",
  borderColor: "#E0B4B4",
  color: "#9F3A38",
  WebkitBoxShadow: "none",
  boxShadow: "none",
};

class PersonRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: null,
      selectedRequiredLanguage: null,
      selectedContext: null,
      selectStyle: {},
      contextSelectStyle: {},
      openDeleteConfirmation: false,
    };
    this.getCountryList = this.getCountryList.bind(this);
    this.setSelectedCountry = this.setSelectedCountry.bind(this);
    this.setSelectedContext = this.setSelectedContext.bind(this);
    this.setSelectedLanguage = this.setSelectedLanguage.bind(this);
  }

  componentDidMount() {
    // localStorage.setItem('route', this.props.history.location.pathname);
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem("x-auth-key");
    if (this.props.auth.isSuperAdmin === false) {
      toast({
        type: "error",
        title: T("Error"),
        description: T("Not found"),
        time: 5000,
      });
    } else if (this.props.fetchStatus !== "success") {
      this.props.fetchCountries(authKey, ws_id);
      this.props.fetchContexts(authKey, ws_id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.persons.operationState.add !== "success" &&
      this.props.persons.operationState.add === "success"
    ) {
      //saved
      toast({
        type: "success",
        title: T("Success"),
        description: T("Person added successfully"),
        time: 5000,
      });
    }
    if (
      prevProps.persons.operationState.add !== "error" &&
      this.props.persons.operationState.add === "error"
    ) {
      const errorObj = getErrorMessage(
        this.props.persons.error,
        "Error in adding a person"
      );
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
    }
    if (
      prevProps.applications.contextStatus !== "error" &&
      this.props.applications.contextStatus === "error"
    ) {
      const errorObj = getErrorMessage(
        this.props.applications.error,
        "Failed to fetch context"
      );
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  onValidSubmit(formData) {
    const inputValues = {
      ...formData,
      co_country: this.state.selectedCountry,
      context_id: this.state.selectedContext,
      pe_name: `${formData.pe_first_name} ${formData.pe_last_name}`,
    };

    if (!inputValues.co_country) {
      this.setState({ selectStyle: errorStyle });
      return;
    }

    if (!inputValues.context_id) {
      this.setState({ contextSelectStyle: errorStyle });
      return;
    }
    this.props.addPerson(inputValues);
  }

  onCancel() {
    this.props.history.push("/app/persons/list");
  }

  getCountryList() {
    const selectLabel = {
      country_iso2: "",
      value: "",
      country_name: T("Select a Country"),
    };
    const countriesProp = this.props.countries.countries;
    if (typeof countriesProp === "object" && countriesProp.length > 0) {
      const countries = this.props.countries.countries.slice();
      countries.unshift(selectLabel);
      return _.map(countries, (option) => ({
        flag: option.country_iso2.toLowerCase(),
        text: option.country_name,
        value: option.country_iso2,
      }));
    }
    return selectLabel;
  }

  getLanguageList() {
    return _.map(this.props.languages, (language) => ({
      text: T(language.ln_name),
      value: language.language_id,
    }));
  }

  getContextList() {
    const contexts = this.props.applications.appContexts;
    return _.map(contexts, (option) => ({
      text: option.cct_name,
      value: option.context_id,
    }));
  }

  setSelectedCountry(e, { value }) {
    this.setState({ selectedCountry: value, selectStyle: {} });
  }

  setSelectedLanguage(e, { value }) {
    this.setState({ selectedLanguage: value, selectStyle: {} });
  }

  setSelectedContext(e, { value }) {
    this.setState({ selectedContext: value, contextSelectStyle: {} });
  }

  render() {
    if (!this.props.auth.isSuperAdmin) {
      return (
        <Message
          header={T("Error")}
          error
          icon="exclamation triangle"
          content={T("Unauthorized access")}
        />
      );
    } else if (
      this.props.countries.status !== "success" ||
      this.props.persons.operationState.add === "waiting" ||
      this.props.applications.contextStatus === "waiting"
    ) {
      return <Spinner />;
    }

    const nameRequired = T("Name is required");
    const companyRequired = T("Company is required");
    const vatRequired = T("VAT number is required");
    const emailRequired = T("Email is required");
    const contextRequired = T("Context is required");
    const countryRequired = T("Country is required");
    const languageRequired = T("Language is required");
    const invalidEmail = T("Invalid Email");
    const errorLabel = <Label color="red" pointing />;

    return (
      <Segment>
        <Header as="h3">{T("Register Person")}</Header>
        <Form
          onValidSubmit={this.onValidSubmit.bind(this)}
          ref={(ref) => (this.form = ref)}
        >
          <Form.Group widths="equal">
            <Form.Input
              required
              name="pe_first_name"
              label={T("First Name")}
              width={6}
              validationErrors={{ isDefaultRequiredValue: nameRequired }}
              errorLabel={errorLabel}
            />
            <Form.Input
              required
              name="pe_last_name"
              label={T("Last Name")}
              width={6}
              validationErrors={{ isDefaultRequiredValue: nameRequired }}
              errorLabel={errorLabel}
            />
            <Form.Input
              required
              name="pe_email"
              label={T("Email")}
              validations="isEmail"
              width={6}
              validationErrors={{
                isEmail: invalidEmail,
                isDefaultRequiredValue: emailRequired,
              }}
              errorLabel={errorLabel}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              required
              name="co_name"
              label={T("Company")}
              width={6}
              validationErrors={{ isDefaultRequiredValue: companyRequired }}
              errorLabel={errorLabel}
            />
            <Form.Input
              required
              name="co_vat"
              label={T("VAT")}
              width={6}
              maxLength={15}
              validationErrors={{ isDefaultRequiredValue: vatRequired }}
              errorLabel={errorLabel}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Dropdown
              required
              name="co_country"
              label={T("Country")}
              placeholder={T("Country")}
              width={6}
              fluid
              search
              selection
              clearable
              selectOnNavigation
              validationErrors={{ isDefaultRequiredValue: countryRequired }}
              errorLabel={errorLabel}
              noResultsMessage={T("No result")}
              style={this.state.selectStyle}
              onChange={this.setSelectedCountry}
              options={this.getCountryList()}
              value={this.state.selectedCountry}
            />
            <Form.Dropdown
              required
              name="pe_language"
              label={T("Language")}
              placeholder={T("Language")}
              width={6}
              fluid
              search
              selection
              clearable
              selectOnNavigation
              validationErrors={{ isDefaultRequiredValue: languageRequired }}
              errorLabel={errorLabel}
              noResultsMessage={T("No result")}
              style={this.state.selectStyle}
              onChange={this.setSelectedLanguage}
              options={this.getLanguageList()}
              value={this.state.selectedLanguage}
            />
            <Form.Dropdown
              required
              name="context_id"
              label={T("Context")}
              placeholder={T("Context")}
              width={6}
              fluid
              selection
              clearable
              selectOnNavigation
              validationErrors={{ isDefaultRequiredValue: contextRequired }}
              errorLabel={errorLabel}
              noResultsMessage={T("No result")}
              style={this.state.contextSelectStyle}
              onChange={this.setSelectedContext}
              options={this.getContextList()}
              value={this.state.selectedContext}
            />
          </Form.Group>
          <Form.Group>
            <Button content={T("Save")} color="green" />
            <Button
              type="button"
              secondary
              content={T("Cancel")}
              onClick={() => this.onCancel()}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({
  persons,
  countries,
  languages,
  auth,
  applications,
  workspace,
}) {
  return {
    countries,
    persons,
    auth,
    applications,
    workspace,
    languages: languages.languages,
  };
}

export default reduxForm({
  form: "personregisterform", // a unique identifier for this form
})(
  connect(mapStateToProps, { addPerson, fetchCountries, fetchContexts })(
    PersonRegister
  )
);

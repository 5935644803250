import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import {
  Accordion,
  Message,
  Menu,
  Button,
  Modal,
  Loader,
} from 'semantic-ui-react';
import EventDefaultSeason from './EventDefaultSeason';
import { T } from '../Common/Helpers';
import { fetchSeries } from '../../Redux/actions';
import EventSeriesList from './EventSeriesList';

class EventMenuManager extends Component {
  state = {
    activeIndex: 0,
    menuOpen: false,
  };

  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');

    if (
      this.props.parents.seriesList &&
      Object.keys(this.props.parents.seriesList).length !== 0
    ) {
      return;
    }

    const parentId = this.props.parentId || null;

    if (parentId) {
      // At the moment, parentId it's not used in the request.
      this.props.fetchSeries(authKey, this.props.ws_id);
    } else {
      this.props.fetchSeries(authKey, this.props.ws_id);
    }
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  goToAddEvent = eventType => {
    this.setState({ menuOpen: false });
    this.props.history.push({
      pathname: '/app/events/season/add',
      state: { [eventType]: true },
    });
  };

  render() {
    if (this.props.parents.statusSeries === 'waiting') {
      return <Loader active inline />;
    } else if (this.props.parents.statusSeries === 'error') {
      return (
        <Message
          error
          header={T('Error')}
          list={[T('Error while fetching series')]}
        />
      );
    }
    return (
      <Modal
        closeIcon
        onClose={() => this.setState({ menuOpen: false })}
        onOpen={() => this.setState({ menuOpen: true })}
        open={this.state.menuOpen}
        trigger={
          <Button color="teal" basic>
            {T('Event Seasons')}
          </Button>
        }
      >
        <Modal.Header>{T('Event Season Management')}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {T(
              'Please choose a season or add a new one using the buttons below'
            )}
          </Modal.Description>
          <Accordion as={Menu} vertical fluid>
            <EventDefaultSeason
              toggle={false}
              eventType="defaultSeasons"
              menuItem={{ ev_name: T('Event Seasons') }}
              parentId={null}
              handleClick={this.handleClick}
              activeIndex={this.state.activeIndex}
              ws_id={this.props.ws_id}
              closeModal={() => this.setState({ menuOpen: false })}
            />
            {_.map(this.props.parents.seriesList, (series, index) => (
              <EventSeriesList
                index={index + 1}
                key={`series-${series.event_id}`}
                toggle={false}
                eventType={series.ev_type}
                eventId={series.event_id}
                parentId={series.ev_parent}
                menuItem={series}
                history={this.props.history}
                handleClick={this.handleClick}
                activeIndex={this.state.activeIndex}
                ws_id={this.props.ws_id}
                closeModal={() => this.setState({ menuOpen: false })}
              />
            ))}
          </Accordion>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon="plus"
            type="button"
            primary
            onClick={() => this.goToAddEvent('season')}
            content={T('Add Season')}
          />
          <Button
            icon="plus"
            type="button"
            primary
            onClick={() => this.goToAddEvent('series')}
            content={T('Add Series')}
          />
          <Button
            color="black"
            onClick={() => this.setState({ menuOpen: false })}
          >
            {T('Cancel')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps({ parents, auth, events, venues }) {
  return { parents, auth, events, venues };
}

export default connect(mapStateToProps, {
  fetchSeries,
})(withRouter(EventMenuManager));

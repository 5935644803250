import React, { Component } from 'react';
import {
  //Form,
  Segment,
  //Button,
  //Icon,
  // Image,
  Header,
} from 'semantic-ui-react';
// import { Form } from 'formsy-semantic-ui-react';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { uploadFile } from '../../Redux/actions';
import ImageCrop from './ImageCrop';
import defaultImage from '../../layout/images/images.png';
import { T, getErrorMessage } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';

class Sponsor extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.status !== 'success' && this.props.status === 'success') {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Sponsor image uploaded successfully'),
      });
    } else if (prevProps.status !== 'error' && this.props.status === 'error') {
      const errorObj = getErrorMessage(
        this.props.error,
        T('Error while uploading sponsor image')
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }
  getPlayerImage(player) {
    if (!player) return {};
    const metadata = player.performer_metadata;
    let imageSrc = '';
    let metadataId = null;
    if (metadata && metadata.length > 0) {
      const pictMeta = metadata.filter(meta => meta.pm_key === 'imageurl');
      if (pictMeta && pictMeta.length !== 0) {
        imageSrc = pictMeta[0].pm_value;
        metadataId = pictMeta[0].performer_metadata_id;
      }
    }
    // console.log('Image src: ', imageSrc);
    return {
      url: imageSrc || defaultImage,
      metadataId,
      showDeleteButton: imageSrc !== '',
    };
  }
  render() {
    if (this.props.status === 'waiting') {
      return <Spinner />;
    }
    const playerField = this.getPlayerImage(this.props.player);
    return (
      <div
        style={{
          minWidth: '400px',
          textAlign: 'center',
          marginLeft: '10px',
          minHeight: '500px',
        }}
      >
        <Segment>
          <Header as="h3" content={T('Sponsor Image')} />
          {/*  <Image
                    src={this.getPlayerImage(this.props.player)}
                    size="medium"
                    centered
                    bordered
                    spaced
                    alt='Player Picture'
                />*/}
          <ImageCrop
            sponsorImage
            performerId={this.props.player.performer_id}
            uploadPerformerImage={this.props.uploadFile}
            playerImg={playerField.url}
            metadataId={this.props.metadataId}
            showDeleteButton={playerField.showDeleteButton}
            onDeleteImage={this.deletePerformerImage}
            onResetEditAndAddPlayer={this.props.onResetEditAndAddPlayer}
            centeredImage
            uploadStatus={this.props.uploadStatus}
            altText={T('Player Image')}
          />
          {/*<Segment>
                <Form>
                  <Form.Group widths="equal">
                  <Form.Input
                      required
                      name="sponsor_name"
                      label={T('Sponsor name: ')}
                      width={10}
                  />
                  <Form.Input
                      required
                      name="sponsor_web_link"
                      label={T('Web Link')}
                      width={10}
                  />
                </Form.Group>
                  <Form.Field>
                          <Button color='green' type="submit">
                            <Icon name='save' /> {T('Save')}
                          </Button>
                          <Button color='red' type="button">
                            <Icon name='ban' /> {T('Remove Sponsor Data')}
                          </Button>
                    </Form.Field>
            </Form>
          </Segment>*/}
        </Segment>
      </div>
    );
  }
}
function mapStateToProps({ file }) {
  return { status: file.uploadStatus, error: file.error };
}
export default connect(mapStateToProps, {
  uploadFile,
})(Sponsor);

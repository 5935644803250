import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  FETCH_CURRENCIES_WAITING,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_ERROR,
  FETCH_CURRENCIES_RESET,
  // ERROR_GATEWAY_TIMEOUT,
} from '../Types';
import ErrorAction from './ErrorAction';

export function fetchCurrencies({ authKey, ws_id }) {
  return dispatch => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_CURRENCIES_WAITING });
    axios
      .get(`${config.BASE_URL}/currencies?ws_id=${ws_id}`)
      .then(response =>
        dispatch({ type: FETCH_CURRENCIES_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_CURRENCIES_ERROR)
          : FETCH_CURRENCIES_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetFetchCurrencies() {
  return { type: FETCH_CURRENCIES_RESET };
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import { Accordion, Menu, Label, List, Icon, Table } from 'semantic-ui-react';
import { Spinner } from '../Common/Spinner';
import { T } from '../Common/Helpers';

import { fetchSeasons } from '../../Redux/actions';

const DATE_TIME_FORMAT = 'l HH:mm';

//for seasons which are not children of listed series, searching without parentId
class EventDefaultSeason extends Component {
  render() {
    if (this.props.parents.statusSeasons !== 'success') {
      return <Spinner />;
    }
    console.log('seasons', this.props.parents.seasons);
    return (
      <Menu.Item>
        <Accordion.Title
          active={this.props.activeIndex === 0}
          index={0}
          onClick={this.props.handleClick}
        >
          <Icon
            name={
              this.props.activeIndex === 0
                ? 'caret square down'
                : 'caret square right'
            }
          />
          <Label basic fluid>
            {T('Event Seasons')}
          </Label>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 0}>
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{T('Name')}</Table.HeaderCell>
                <Table.HeaderCell>{T('Begins')}</Table.HeaderCell>
                <Table.HeaderCell>{T('Ends')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(this.props.parents.seasons, season => {
                const { event_id, ev_event_start, ev_event_end, ev_name } =
                  season;
                const formattedEventStart = ev_event_start
                  ? moment(ev_event_start).format(DATE_TIME_FORMAT)
                  : '';
                const formattedEventEnd = ev_event_end
                  ? moment(ev_event_end).format(DATE_TIME_FORMAT)
                  : '';
                return (
                  <Table.Row
                    key={event_id}
                    onClick={() => {
                      this.props.history.push(
                        `/app/events/season/edit/${event_id}`
                      );
                      this.props.closeModal();
                    }}
                  >
                    <Table.Cell>{ev_name}</Table.Cell>
                    <Table.Cell>{formattedEventStart}</Table.Cell>
                    <Table.Cell>{formattedEventEnd}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Accordion.Content>
      </Menu.Item>
    );
  }
}

function mapStateToProps({ parents, events }) {
  return { parents, events };
}
export default connect(mapStateToProps, {
  fetchSeasons,
})(withRouter(EventDefaultSeason));

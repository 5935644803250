import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Image, Icon } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import { T } from './Helpers';
import { Spinner } from './Spinner';
import { uploadFile, deleteFile } from '../../Redux/actions';
import placeholder_image from '../../layout/images/placeholder_image.png';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: props.file_key,
      files: [],
      deleted: false,
    };
  }

  componentDidMount() {
    this.refreshStateFiles();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.file_url !== this.props.file_url) {
      this.refreshStateFiles();
    }

    if (
      this.props.file.uploadStatus === 'success' &&
      prevProps.file.uploadStatus !== 'success'
    ) {
      this.props.callback(
        this.props.file.data.urlSmall,
        this.props.file.data.urlLarge,
        this.props.file.ref,
        this.props.file.data.file_id,
        this.props.file.data.target
      );
    }
  }

  refreshStateFiles() {
    const { file_url, file_id } = this.props;
    const name = file_url ? file_url.split('/').pop() : null;
    // console.log("{ file_url, file_id, name }", { file_url, file_id, name });
    if (name) {
      this.setState({ files: [{ name, preview: file_url, id: file_id }] });
    }
  }

  onDrop(files) {
    this.setState(
      {
        files: files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: file.file_id,
          })
        ),
        image_updated: true,
        deleted: false,
      },
      () => this.uploadFile(files)
    );
  }

  onDelete = e => {
    e.preventDefault();
    if (this.props.file_id !== null && this.props.file_id > 0) {
      const authKey = localStorage.getItem('x-auth-key');
      const { target, file_id, id } = this.props;
      const module_id = 26;
      this.setState({ files: [], deleted: true });
      this.props.deletecallback({ authKey, file_id, module_id, target, id });
      //this.props.deleteFile({ authKey, file_id, module_id, target, id });
      // this calls callback
      // delete file is called when saved
    }
  };

  getTrash() {
    if (this.props.file_id !== null && this.props.file_id > 0) {
      return (
        <div style={trash}>
          <Icon
            name="trash alternate"
            size="big"
            color="red"
            corner="bottom left"
            onClick={this.onDelete}
          />
        </div>
      );
    }
  }

  uploadFile = files => {
    if (files.length > 0) {
      const { ws_id, workspace } = this.props;
      let { target, id, module_id } = this.props;
      // defaults if not provided
      id = id || workspace.workspaces[ws_id].cnc_company;
      target = target || 'product';
      module_id = module_id || 26;
      // iterate through files and update (only one file for now)
      files.forEach(file => {
        const reader = new FileReader();
        reader.onload = () => {
          // const fileAsBinaryString = reader.result;
          const formData = new FormData();
          const inputValues = { id, target };
          Object.keys(inputValues).forEach(key => {
            formData.append(key, inputValues[key]);
          });
          const authKey = localStorage.getItem('x-auth-key');
          formData.append('file', file);
          this.props.uploadFile({
            authKey,
            formData,
            module_id,
            ws_id,
            ref: this.props.file_key,
          });
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.readAsBinaryString(file);
      });
      this.setState({ deleted: false });
    }
  };

  render() {
    const { files } = this.state;
    const thumbs = files.map((file, key) => (
      <div style={imageWrapper} key={key}>
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            <img src={file.preview} style={img} alt={file.name} />
          </div>
        </div>
      </div>
    ));

    if (
      this.props.file.uploadStatus === 'waiting' &&
      this.props.file.ref === this.state.key
    ) {
      return (
        <div style={this.props.style}>
          <Spinner
            width={this.props.style.minWidth}
            height={this.props.style.minHeight}
          />
        </div>
      );
    }
    return (
      <section>
        <aside style={thumbsContainer}>
          {files.length < 1 ? (
            <Image
              src={placeholder_image}
              id="image_place_holder"
              style={{
                width: 120,
                height: 120,
                marginBottom: 8,
                marginRight: 8,
                ...this.props.style,
              }}
            />
          ) : (
            thumbs
          )}
          {this.getTrash()}
        </aside>
        {!this.props.disabled && (
          <Dropzone
            multiple={false}
            accept="image/jpeg, image/png"
            onDrop={this.onDrop.bind(this)}
            name="image_file"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                  icon="upload"
                  width={6}
                  label={T('Select image')}
                  primary
                  onClick={e => e.preventDefault()}
                />
                png, jpg, jpeg
              </div>
            )}
          </Dropzone>
        )}
      </section>
    );
  }
}

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  //  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 200,
  height: 200,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  //display: 'flex',
  display: 'block',
  minWidth: 0,
  //  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const imageWrapper = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const trash = {
  margin: 'auto',
  marginLeft: '10px',
};

function mapStateToProps({ workspace, file }) {
  return {
    workspace,
    file,
  };
}

export default connect(mapStateToProps, { uploadFile, deleteFile })(FileUpload);

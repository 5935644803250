export const FETCH_SALES_REPORT_WAITING = "fetch_sales_report_waiting";
export const FETCH_SALES_REPORT_SUCCESS = "fetch_sales_report_success";
export const FETCH_SALES_REPORT_ERROR = "fetch_sales_report_error";
export const FETCH_SALES_REPORT_RESET = "fetch_sales_report_reset";

export const FETCH_SALES_REPORT_DOWNLOAD_WAITING =
  "fetch_sales_report_download_waiting";
export const FETCH_SALES_REPORT_DOWNLOAD_SUCCESS =
  "fetch_sales_report_download_success";
export const FETCH_SALES_REPORT_DOWNLOAD_ERROR =
  "fetch_sales_report_download_error";
export const FETCH_SALES_REPORT_DOWNLOAD_RESET =
  "fetch_sales_report_download_reset";

export const FETCH_SOLD_ITEMS_REPORT_WAITING =
  "fetch_sold_items_report_waiting";
export const FETCH_SOLD_ITEMS_REPORT_SUCCESS =
  "fetch_sold_items_report_success";
export const FETCH_SOLD_ITEMS_REPORT_ERROR = "fetch_sold_items_report_error";
export const FETCH_SOLD_ITEMS_REPORT_RESET = "fetch_sold_items_report_reset";

export const FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_WAITING =
  "fetch_sold_items_report_download_waiting";
export const FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_SUCCESS =
  "fetch_sold_items_report_download_success";
export const FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_ERROR =
  "fetch_sold_items_report_download_error";
export const FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_RESET =
  "fetch_sold_items_report_download_reset";

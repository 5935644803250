import React, { Component } from 'react';
import { Button, Segment, Message } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { toast } from 'react-semantic-toasts';
import { connect } from 'react-redux';
import { Spinner } from '../Common/Spinner';
import {
  //fetchCountries,
  addVenue,
  fetchLocations,
  resetAddVenue,
  selectedAddVenueLocation,
  resetselectedAddVenueLocation,
} from '../../Redux/actions';
import LocationList from './Locations/LocationList';
import ModalWindow, { ModalContext } from '../Common/ModalWindow';
import { T, getErrorMessage } from '../Common/Helpers';

class VenueAdd extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedCountry: '', openDeleteConfirmation: false };
    this.onChangeCountry = this.onChangeCountry.bind(this);
  }

  // componentDidMount() {
  //   if (this.props.countriesStatus !== 'success') {
  //     const ws_id = this.props.workspace.activeWorkspace.id;
  //     const authKey = localStorage.getItem('x-auth-key');
  //     // this.props.fetchCountries(authKey, ws_id);
  //     }
  //   }
  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.addStatus === 'success') {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Location added successfully'),
        time: 5000,
      });
      this.props.resetAddVenue();
      this.props.history.push('/app/venues/list');
    } else if (nextProps.addStatus === 'error') {
      const errorObj = getErrorMessage(nextProps.venues.error);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetAddVenue();
    }
  }

  onValidSubmit(data) {
    // console.log('LOCATION DATA: ', data);
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props.workspace;
    const pl_parent = this.props.workspace.workspaces[ws_id].cnc_place;
    const pl_company = this.props.workspace.workspaces[ws_id].cnc_company;
    let inputValues = { ...data, pl_parent, pl_company };
    if (!data.pl_location) {
      delete inputValues.pl_location;
    }
    // if (!data.pl_parent) {
    //   delete inputValues.pl_parent;
    // }
    if (!data.pl_public) {
      inputValues = { ...inputValues, pl_public: false };
    }
    this.props.addVenue(inputValues, authKey, ws_id);
    // console.log('VENUE ADD DATA: ', inputValues);
  }
  onChangeCountry(event) {
    this.setState({ selectedCountry: event.target.value, countryError: {} });
  }
  onCancel() {
    this.props.history.push('/app/venues/list');
  }

  preventFormSubmitOnEnter(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }
  renderOptionsCountryModified(opt, label) {
    // console.log('List: ', opt);
    const countries = opt.slice();
    countries.unshift({ id: '', country_name: T('Select a Country') });
    return _.map(countries, option => (
      <option key={option.id} value={option.id}>
        {option[label]}
      </option>
    ));
  }
  render() {
    const { ws_id } = this.props.workspace;
    const pl_parent = this.props.workspace.workspaces[ws_id].cnc_place;
    if (!pl_parent) {
      return (
        <Message error>
          <Message.Header> {T('Root parent is null')} </Message.Header>
          <p> {T('Venue can not be created with null root')} </p>
        </Message>
      );
    } else if (this.props.addStatus === 'waiting') {
      return <Spinner />;
    }
    // console.log('PROPS COUNTRIES: ', this.props);
    //console.log('Rendering Venue Add', this.props);
    const inputStyle = { minWidth: '250px' };
    return (
      <Segment style={{ marginBottom: '300px' }}>
        <h3>{T('Add Venue')}</h3>
        <Form
          onValidSubmit={this.onValidSubmit.bind(this)}
          ref={ref => (this.form = ref)}
        >
          <Form.Group>
            <Form.Input
              required
              // value={performer.pf_name}
              name="pl_name"
              label={T('Name')}
              width={6}
              style={{ ...inputStyle }}
              onKeyPress={e => {
                this.preventFormSubmitOnEnter(e);
              }}
            />
          </Form.Group>
          <Form.Group>
            {/*<Form.Input
                // required
                type="number"
                // defaultValue="130"
                name="pl_parent"
                label={T('Parent')}
                width={6}
                style={{ ...inputStyle }}
                onKeyPress={this.preventFormSubmitOnEnter.bind(this)}
                // readOnly
            />*/}
          </Form.Group>
          <Form.Group>
            <Form.Input
              name="pl_location"
              label={T('Map Location')}
              width={6}
              style={{ ...inputStyle }}
              value={
                this.props.selectedLocation
                  ? this.props.selectedLocation.location_id
                  : ''
              }
              onKeyPress={this.preventFormSubmitOnEnter}
              readOnly
              action={
                <ModalWindow
                  buttonColor="blue"
                  buttonSize="tiny"
                  buttonLabel={T('Select Location')}
                  header={T('Select Location')}
                >
                  <ModalContext.Consumer>
                    {({ closeModal }) => (
                      <LocationList
                        setLocation={this.props.selectedAddVenueLocation}
                        closeModal={closeModal}
                      />
                    )}
                  </ModalContext.Consumer>
                </ModalWindow>
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              required
              // value={performer.pf_name}
              name="pl_address"
              label={T('Address')}
              width={6}
              style={{ ...inputStyle }}
              onKeyPress={e => {
                this.preventFormSubmitOnEnter(e);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              required
              // value={performer.pf_name}
              name="pl_zip"
              label={T('Zip')}
              width={6}
              style={{ ...inputStyle }}
              onKeyPress={e => {
                this.preventFormSubmitOnEnter(e);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              required
              // value={performer.pf_name}
              name="pl_city"
              label={T('City')}
              width={6}
              style={{ ...inputStyle }}
              onKeyPress={e => {
                this.preventFormSubmitOnEnter(e);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              name="pl_phone"
              label={T('Phone')}
              width={6}
              style={{ ...inputStyle }}
              onKeyPress={e => {
                this.preventFormSubmitOnEnter(e);
              }}
            />
            <Form.Input
              name="pl_email"
              label={T('Email')}
              width={6}
              style={{ ...inputStyle }}
              onKeyPress={e => {
                this.preventFormSubmitOnEnter(e);
              }}
            />
          </Form.Group>
          {/* <Form.Group>
              <semanticForm.Field width="6" required>
                <label
                    name="country_label"
                    htmlFor="pf_ctry"
                >
                  {T('Country')}
               </label>
              <select
               required
               onChange={this.onChangeCountry}
                name="pf_ctry"
                value={this.state.selectedCountry}
               style={{ height: '40px',
                        marginBottom: '10px',
                          ...this.state.countryError,
                          ...inputStyle
                        }}
              >
              {this.renderOptionsCountryModified(
                       this.props.countries.countries,
                       'country_name')}
             </select>
          </semanticForm.Field>
        </Form.Group> */}
          <Form.Group widths="16">
            <Form.Checkbox
              toggle
              name="pl_public"
              label={T('Public')}
              onKeyPress={e => {
                this.preventFormSubmitOnEnter(e);
              }}
              // defaultChecked={performer.pf_public}
            />
            {/* {console.log('IS ACTIVE:', performer.pf_is_active, ' Public: ', performer.pf_public)} */}
          </Form.Group>
          <Button content={T('Save')} color="green" icon="save" />
          <Button
            type="button"
            color="black"
            content={T('Cancel')}
            onClick={() => this.onCancel()}
          />
        </Form>
        {/* <DropDownTreeTest />; */}
        {/* <LocationList/> */}
      </Segment>
    );
  }
}
function mapStateToProps({ countries, venues, locations, workspace }) {
  return {
    countries,
    venues,
    // locations,
    countriesStatus: countries.status,
    locationStatus: venues.createStatus,
    addStatus: venues.operationState.add,
    selectedLocation: locations.selectedAddVenueLocation,
    workspace,
  };
}

export default connect(mapStateToProps, {
  //fetchCountries,
  addVenue,
  fetchLocations,
  resetAddVenue,
  selectedAddVenueLocation,
  resetselectedAddVenueLocation,
})(withRouter(VenueAdd));

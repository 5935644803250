import {
  FETCH_MESSAGE_TEMPLATE_WAITING,
  FETCH_MESSAGE_TEMPLATE_SUCCESS,
  FETCH_MESSAGE_TEMPLATE_ERROR,
  FETCH_MESSAGE_TEMPLATE_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  status: 'init',
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_MESSAGE_TEMPLATE_WAITING:
      return { ...state, status: 'waiting' };
    case FETCH_MESSAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        headers: action.payload.headers,
        status: 'success',
      };
    case FETCH_MESSAGE_TEMPLATE_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    case FETCH_MESSAGE_TEMPLATE_RESET:
      return { ...state, status: 'init' };
    default:
      return state;
  }
}

import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Form, Button, Segment, Header, Message } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import moment from 'moment';

import { toast } from 'react-semantic-toasts';
import { Spinner } from '../Common/Spinner';
import {
  fetchCmsContentTypes,
  addCmsContent,
  editCmsContent,
  addCmsContentReset,
  editCmsContentReset,
  uploadFile,
  resetUploadFile,
  resetCmsContents,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

// const timezone = jstz.determine().name();

class ManageCmsParent extends Component {
  constructor(props) {
    super(props);
    let tempState = {};
    if (this.props.location.state && this.props.location.state.content) {
      const {
        cm_content_type,
        cm_valid_from,
        cm_valid_to,
        cm_short_header,
        cm_url,
        cm_main_image,
      } = this.props.location.state.content;
      if (cm_main_image) {
        const name = cm_main_image.split('/').pop();
        tempState = {
          files: [{ name, preview: cm_main_image }],
          cm_main_image,
        };
      } else {
        tempState = {
          files: [{ name: null, preview: cm_main_image }],
          cm_main_image,
        };
      }
      tempState = {
        ...tempState,
        cm_content_type,
        cm_valid_from: cm_valid_from
          ? moment(cm_valid_from).format('l HH:mm')
          : '',
        cm_valid_to: cm_valid_to ? moment(cm_valid_to).format('l HH:mm') : '',
        cc_main_image: null,
        cm_short_header,
        cm_url,
        cm_main_image,
      };
    } else {
      const contentType = this.props.location.state.contentType;
      //console.log('Content type: ', contentType);
      tempState = {
        cm_image_updated: false,
        cm_url: '',
        cm_short_header: '',
        cm_content_type: contentType,
        cm_valid_from: '',
        cm_valid_to: '',
        files: [],
        cm_main_image: '',
      };
    }
    this.state = { ...tempState };
  }

  componentDidUpdate(prevProps) {
    //upload image success
    if (
      prevProps.file.uploadStatus !== 'success' &&
      this.props.file.uploadStatus === 'success'
    ) {
      this.postData();
    }
    //upload image error
    if (
      prevProps.file.uploadStatus !== 'error' &&
      this.props.file.uploadStatus === 'error'
    ) {
      //error
      const errorObj = getErrorMessage(
        this.props.file.error,
        'Could not upload image'
      );
      this.props.resetUploadFile();
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
    // parent added, replace child content
    if (
      prevProps.cmsContent.addStatus !== 'success' &&
      this.props.cmsContent.addStatus === 'success'
    ) {
      this.props.history.push({
        pathname: `/app/cmscontent/${this.props.location.state.contentType}/list`,
        state: { contentAdded: true },
      });
    }
    // parent edited, replace child content
    if (
      prevProps.cmsContent.editStatus !== 'success' &&
      this.props.cmsContent.editStatus === 'success'
    ) {
      this.props.history.push({
        pathname: `/app/cmscontent/${this.props.location.state.contentType}/list`,
        state: { contentAdded: true },
      });
    }
    if (
      prevProps.cmsContent.editStatus !== 'error' &&
      this.props.cmsContent.editStatus === 'error'
    ) {
      this.props.resetCmsContents();
      const errorObj = getErrorMessage(
        this.props.cmsContent.error,
        'Failed to update cms content'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }

    if (
      prevProps.cmsContent.addStatus !== 'error' &&
      this.props.cmsContent.addStatus === 'error'
    ) {
      this.props.resetCmsContents();
      const errorObj = getErrorMessage(
        this.props.cmsContent.error,
        'Failed to add cms content'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  onCancel = () => {
    this.props.history.push(
      `/app/cmscontent/${this.props.location.state.contentType}/list`
    );
  };

  onDrop(files) {
    this.setState({
      files: files.map(file =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      ),
      cm_image_updated: true,
    });
    //, () => this.uploadFile()
  }

  postData = () => {
    const {
      cm_content_type,
      cm_short_header,
      cm_url,
      cm_valid_from,
      cm_valid_to,
      cm_main_image,
      cm_file_id,
    } = this.state;
    const data = {
      cm_content_type,
      cm_short_header,
      cm_url,
      [`cm_${this.props.config.content_category}`]:
        this.props.config.category_id,
      cm_main_image,
      cm_file_id,
    };
    if (cm_valid_from) {
      data.cm_valid_from = cm_valid_from;
    }
    if (cm_valid_to) {
      data.cm_valid_to = cm_valid_to;
    }
    const authKey = localStorage.getItem('x-auth-key');
    let updatedData = { ...data };
    if (this.props.file.data && this.state.cm_image_updated) {
      updatedData = {
        ...data,
        cm_main_image: this.props.file.data.urlLarge,
        cm_file_id: this.props.file.data.file_id,
      };
    }

    const { parentId } = this.props.match.params;
    if (parentId) {
      this.props.editCmsContent(
        parentId,
        updatedData,
        authKey,
        this.props.ws_id
      );
    } else {
      this.props.addCmsContent(updatedData, authKey, this.props.ws_id);
    }
  };

  handleFormSubmit = () => {
    const { files, cm_image_updated, cm_content_type } = this.state;
    if (files.length > 0 && cm_image_updated) {
      const { ws_id, workspace } = this.props;
      //upload files and then post form data in componentDidUpdate
      files.forEach(file => {
        const reader = new FileReader();
        reader.onload = () => {
          // const fileAsBinaryString = reader.result;
          const formData = new FormData();
          const inputValues = {
            id: workspace.workspaces[ws_id].cnc_application,
            target: cm_content_type,
          };
          Object.keys(inputValues).forEach(key => {
            formData.append(key, inputValues[key]);
          });
          formData.append('file', file);
          const authKey = localStorage.getItem('x-auth-key');
          //console.log('upload file data ', this.props.auth.companyId);
          this.props.uploadFile({
            authKey,
            formData,
            module_id: 15,
            ws_id: this.props.ws_id,
          });
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.readAsBinaryString(file);
      });
    } else {
      this.postData();
    }
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  render() {
    // console.log("CMS MANAGE PROPS: ", this.props);
    // Reactotron.logImportant('CMS Page: ', this.props, this.state);
    if (
      this.props.cmsContent.ctypeStatus === 'waiting' ||
      // this.props.langStatus !== 'success' ||
      this.props.file.uploadStatus === 'waiting' ||
      this.props.file.addStatus === 'waiting' ||
      this.props.file.editStatus === 'waiting'
    ) {
      return <Spinner />;
    } else if (this.props.cmsContent.ctypeStatus === 'error') {
      return (
        <Message
          negative
          icon="exclamation triangle"
          header={T('Error')}
          content={T('Error occured, while fetching content types')}
        />
      );
    }
    const { files } = this.state;

    const thumbs = files.map(file => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img src={file.preview} style={img} alt={file.name} />
        </div>
      </div>
    ));

    return (
      <Segment>
        <Header as="h3">{T('Manage content')}</Header>
        <Form onSubmit={this.handleFormSubmit} ref={ref => (this.form = ref)}>
          <Form.Group widths="equal">
            <Form.Input
              required
              name="cm_short_header"
              label={T('Short header')}
              value={this.state.cm_short_header || ''}
              onChange={this.handleChange}
            />
            <Form.Field>
              <label>{T('Image')}</label>
              <section>
                <Dropzone
                  multiple={false}
                  accept="image/jpeg, image/png"
                  onDrop={this.onDrop.bind(this)}
                  name="cm_main_image"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button
                        icon="upload"
                        width={6}
                        label={T('Select image')}
                        primary
                      />
                      png, jpg, jpeg
                    </div>
                  )}
                </Dropzone>
                <aside style={thumbsContainer}>{thumbs}</aside>
              </section>
            </Form.Field>
          </Form.Group>
          <Form.Group widths="8">
            <Form.Field width="8">
              <Form.Input
                fluid
                name="cm_url"
                label={T('URL')}
                onChange={this.handleChange}
                value={this.state.cm_url || ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="8">
            <Form.Field width="4">
              <DateTimeInput
                // required
                closable
                name="cm_valid_from"
                label={T('Valid from')}
                onChange={this.handleChange}
                value={this.state.cm_valid_from}
                dateTimeFormat="l HH:mm"
                minDate={this.state.cm_valid_from}
                animation="off"
              />
            </Form.Field>
            <Form.Field width="4">
              <DateTimeInput
                // required
                closable
                name="cm_valid_to"
                label={T('Valid to')}
                onChange={this.handleChange}
                value={this.state.cm_valid_to}
                dateTimeFormat="l HH:mm"
                // style={this.state.cm_valid_to === '' ? errorStyle : { backgroundColor: 'white' }}
                minDate={this.state.cm_valid_from}
                animation="off"
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Button icon="save" content={T('Save')} color="green" />
            <Button
              icon="ban"
              type="button"
              secondary
              content={T('Cancel')}
              onClick={() => this.onCancel()}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({ cmsContent, languages, file, auth, workspace }) {
  return {
    cmsContent,
    languages: languages.languages,
    auth,
    file,
    workspace,
    ws_id: workspace.ws_id,
  };
}

export default reduxForm({
  form: 'manageCmsForm',
})(
  connect(mapStateToProps, {
    fetchCmsContentTypes,
    addCmsContent,
    // saveCmsContents,
    addCmsContentReset,
    editCmsContentReset,
    editCmsContent,
    // fetchLanguages,
    // fetchCmsChildContents,
    resetCmsContents,
    uploadFile,
    resetUploadFile,
  })(withRouter(ManageCmsParent))
);

import {
  FETCH_MOMENT_OPTIONS_WAITING,
  FETCH_MOMENT_OPTIONS_SUCCESS,
  FETCH_MOMENT_OPTIONS_ERROR,
  FETCH_MOMENT_OPTIONS_RESET,
  FETCH_EVENT_REPORT_WAITING,
  FETCH_EVENT_REPORT_SUCCESS,
  FETCH_EVENT_REPORT_ERROR,
  FETCH_EVENT_REPORT_RESET,
  DOWNLOAD_EVENT_REPORT_WAITING,
  DOWNLOAD_EVENT_REPORT_SUCCESS,
  DOWNLOAD_EVENT_REPORT_ERROR,
  DOWNLOAD_EVENT_REPORT_RESET,
  FETCH_EVENT_STATISTIC_WAITING,
  FETCH_EVENT_STATISTIC_SUCCESS,
  FETCH_EVENT_STATISTIC_ERROR,
  FETCH_EVENT_STATISTIC_RESET,
  DOWNLOAD_EVENT_STATISTIC_WAITING,
  DOWNLOAD_EVENT_STATISTIC_SUCCESS,
  DOWNLOAD_EVENT_STATISTIC_ERROR,
  DOWNLOAD_EVENT_STATISTIC_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  momentOptions: {
    status: 'init',
  },
  report: {
    status: 'init',
  },
  download: {
    status: 'init',
  },
  summary: {},
  statistic: {
    status: 'init',
  },

  statisticDownload: {
    status: 'init',
  },
};

const EventReportReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_MOMENT_OPTIONS_RESET: {
      return { ...state, momentOptions: { status: 'init', error: null } };
    }
    case FETCH_MOMENT_OPTIONS_WAITING: {
      return { ...state, momentOptions: { status: 'waiting', error: null } };
    }
    case FETCH_MOMENT_OPTIONS_SUCCESS: {
      return {
        ...state,
        momentOptions: { ...action.payload, status: 'success', error: null },
      };
    }
    case FETCH_MOMENT_OPTIONS_ERROR: {
      return {
        ...state,
        momentOptions: { status: 'error', error: action.payload.error },
      };
    }
    case FETCH_EVENT_REPORT_RESET: {
      return { ...state, report: { status: 'init', error: null } };
    }
    case FETCH_EVENT_REPORT_WAITING: {
      return { ...state, report: { status: 'waiting', error: null } };
    }
    case FETCH_EVENT_REPORT_SUCCESS: {
      return {
        ...state,
        report: { ...action.payload, status: 'success', error: null },
      };
    }
    case FETCH_EVENT_REPORT_ERROR: {
      return {
        ...state,
        report: { status: 'error', error: action.payload.error },
      };
    }
    case DOWNLOAD_EVENT_REPORT_RESET: {
      return { ...state, download: { status: 'init', error: null } };
    }
    case DOWNLOAD_EVENT_REPORT_WAITING: {
      return {
        ...state,
        download: {
          status: 'waiting',
          reportType: action.payload,
          error: null,
        },
      };
    }
    case DOWNLOAD_EVENT_REPORT_SUCCESS: {
      return { ...state, download: { status: 'success', error: null } };
    }
    case DOWNLOAD_EVENT_REPORT_ERROR: {
      return {
        ...state,
        download: { status: 'error', error: action.payload.error },
      };
    }

    case FETCH_EVENT_STATISTIC_WAITING: {
      return { ...state, statistic: { status: 'waiting', error: null } };
    }
    case FETCH_EVENT_STATISTIC_SUCCESS: {
      return {
        ...state,
        statistic: { ...action.payload, status: 'success', error: null },
      };
    }
    case FETCH_EVENT_STATISTIC_ERROR: {
      return {
        ...state,
        statistic: { status: 'error', error: action.payload.error },
      };
    }

    case FETCH_EVENT_STATISTIC_RESET: {
      return { ...state, statistic: { status: 'init', error: null } };
    }

    case DOWNLOAD_EVENT_STATISTIC_WAITING: {
      return {
        ...state,
        statisticDownload: { status: 'waiting', error: null },
      };
    }
    case DOWNLOAD_EVENT_STATISTIC_SUCCESS: {
      return {
        ...state,
        statisticDownload: { status: 'success', error: null },
      };
    }
    case DOWNLOAD_EVENT_STATISTIC_ERROR: {
      return {
        ...state,
        statisticDownload: { status: 'error', error: action.payload.error },
      };
    }
    case DOWNLOAD_EVENT_STATISTIC_RESET: {
      return { ...state, statisticDownload: { status: 'init', error: null } };
    }
    default:
      return state;
  }
};

export default EventReportReducer;

import axios from 'axios';
import config from '../../Config/AEConfig';
import {
  FETCH_NOTIFICATIONS_WAITING,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  FETCH_NOTIFICATIONS_RESET,
  FETCH_NOTIFICATION_WAITING,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_ERROR,
  FETCH_NOTIFICATION_RESET,
  ADD_NOTIFICATION_WAITING,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_ERROR,
  ADD_NOTIFICATION_RESET,
  EDIT_NOTIFICATION_WAITING,
  EDIT_NOTIFICATION_SUCCESS,
  EDIT_NOTIFICATION_ERROR,
  EDIT_NOTIFICATION_RESET,
  DELETE_NOTIFICATION_WAITING,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_ERROR,
  DELETE_NOTIFICATION_RESET,
  FETCH_NOTIFICATION_CATEGORIES_WAITING,
  FETCH_NOTIFICATION_CATEGORIES_SUCCESS,
  FETCH_NOTIFICATION_CATEGORIES_ERROR,
  FETCH_NOTIFICATION_CATEGORIES_RESET,
  ADD_NOTIFICATION_CATEGORY_WAITING,
  ADD_NOTIFICATION_CATEGORY_SUCCESS,
  ADD_NOTIFICATION_CATEGORY_ERROR,
  ADD_NOTIFICATION_CATEGORY_RESET,
  EDIT_NOTIFICATION_CATEGORY_WAITING,
  EDIT_NOTIFICATION_CATEGORY_SUCCESS,
  EDIT_NOTIFICATION_CATEGORY_ERROR,
  EDIT_NOTIFICATION_CATEGORY_RESET,
  DELETE_NOTIFICATION_CATEGORY_WAITING,
  DELETE_NOTIFICATION_CATEGORY_SUCCESS,
  DELETE_NOTIFICATION_CATEGORY_ERROR,
  DELETE_NOTIFICATION_CATEGORY_RESET,
  FETCH_NOTIFICATION_CATEGORY_TYPES_WAITING,
  FETCH_NOTIFICATION_CATEGORY_TYPES_SUCCESS,
  FETCH_NOTIFICATION_CATEGORY_TYPES_ERROR,
} from '../Types';
import ErrorAction from './ErrorAction';

const MODULE_ID = 13;
export const fetchNotifications = ({
  authKey,
  ws_id,
  sortByColumn,
  dirn,
  start,
  searchterm,
  application_id,
  sent,
}) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return dispatch => {
    dispatch({ type: FETCH_NOTIFICATIONS_WAITING });
    let url = `${config.BASE_URL}/advertising/messages/${application_id}?module_id=${MODULE_ID}&ws_id=${ws_id}`;
    if (sortByColumn && dirn) {
      url += `&sort=${sortByColumn}:${dirn}`;
    } else if (sortByColumn) {
      url += `&sort=${sortByColumn}`;
    }
    if (start) {
      url += `&start=${start}`;
    }
    if (searchterm) {
      url += `&searchterm=${searchterm}`;
    }
    if (sent) {
      url += `&sent=${sent}`;
    }
    // console.log('notifications list', url);
    axios
      .get(url)
      .then(response =>
        dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_NOTIFICATIONS_ERROR)
          : FETCH_NOTIFICATIONS_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetFetchNotifications = () => ({
  type: FETCH_NOTIFICATIONS_RESET,
});

export const fetchNotification = (id, authKey, ws_id) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return dispatch => {
    dispatch({ type: FETCH_NOTIFICATION_WAITING });
    axios
      .get(
        `${config.BASE_URL}/advertising/message/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then(response =>
        dispatch({ type: FETCH_NOTIFICATION_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_NOTIFICATION_ERROR)
          : FETCH_NOTIFICATION_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetFetchNotification = () => ({
  type: FETCH_NOTIFICATION_RESET,
});

export const addNotification = ({ notification, authKey, priority, ws_id }) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return dispatch => {
    dispatch({ type: ADD_NOTIFICATION_WAITING });
    axios
      .post(
        `${config.BASE_URL}/advertising/message?ws_id=${ws_id}&module_id=${MODULE_ID}&me_priority=${priority}`,
        notification
      )
      .then(response =>
        dispatch({ type: ADD_NOTIFICATION_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_NOTIFICATION_ERROR)
          : ADD_NOTIFICATION_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetAddNotification = () => ({ type: ADD_NOTIFICATION_RESET });

export const editNotification = ({
  notificationId,
  notification,
  authKey,
  ws_id,
}) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return dispatch => {
    dispatch({ type: EDIT_NOTIFICATION_WAITING });
    axios
      .put(
        `${config.BASE_URL}/advertising/message/${notificationId}?ws_id=${ws_id}&module_id=${MODULE_ID}`,
        notification
      )
      .then(response =>
        dispatch({ type: EDIT_NOTIFICATION_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_NOTIFICATION_ERROR)
          : EDIT_NOTIFICATION_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetEditNotification = () => ({ type: EDIT_NOTIFICATION_RESET });

export const deleteNotification = ({ notificationId, authKey, ws_id }) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return dispatch => {
    dispatch({ type: DELETE_NOTIFICATION_WAITING });
    axios
      .delete(
        `${config.BASE_URL}/advertising/message/${notificationId}?ws_id=${ws_id}&module_id=${MODULE_ID}`
      )
      .then(response =>
        dispatch({ type: DELETE_NOTIFICATION_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_NOTIFICATION_ERROR)
          : DELETE_NOTIFICATION_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetDeleteNotification = () => ({
  type: DELETE_NOTIFICATION_RESET,
});

export const fetchNotificationCategories =
  ({ authKey, ws_id, sortByColumn, direction, application_id }) =>
  dispatch => {
    // console.log('fetch notification cats', authKey, sortByColumn, direction, appId);
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_NOTIFICATION_CATEGORIES_WAITING });
    let url = `${config.BASE_URL}/advertising/message/categories/${application_id}?module_id=${MODULE_ID}&ws_id=${ws_id}`;
    if (sortByColumn && direction) {
      url += `&sort=${sortByColumn}:${direction}`;
    } else if (sortByColumn && !direction) {
      url += `&sort=${sortByColumn}`;
    }
    // console.log('final url is ', url);
    axios
      .get(url)
      .then(response => {
        dispatch({
          type: FETCH_NOTIFICATION_CATEGORIES_SUCCESS,
          payload: response,
        });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              FETCH_NOTIFICATION_CATEGORIES_ERROR
            )
          : FETCH_NOTIFICATION_CATEGORIES_ERROR;
        dispatch({ type, payload: error });
      });
  };

// fetch message category types
export const fetchMessageCategoryTypes = authKey => dispatch => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  dispatch({ type: FETCH_NOTIFICATION_CATEGORY_TYPES_WAITING });
  const url = `${config.BASE_URL}/advertising/category/types`;

  axios
    .get(url)
    .then(response => {
      dispatch({
        type: FETCH_NOTIFICATION_CATEGORY_TYPES_SUCCESS,
        payload: response,
      });
    })
    .catch(error => {
      const type = error.response
        ? ErrorAction(
            error.response.status,
            FETCH_NOTIFICATION_CATEGORY_TYPES_ERROR
          )
        : FETCH_NOTIFICATION_CATEGORY_TYPES_ERROR;
      dispatch({ type, payload: { error } });
    });
};

export const resetFetchNotificationCategories = () => ({
  type: FETCH_NOTIFICATION_CATEGORIES_RESET,
});

export const addNotificationCategory = ({
  category,
  authKey,
  ws_id,
  module_id = MODULE_ID,
  event_id,
}) => {
  let url = event_id ? `&event_id=${event_id}` : '';

  axios.defaults.headers.common['x-auth-key'] = authKey;
  return dispatch => {
    dispatch({ type: ADD_NOTIFICATION_CATEGORY_WAITING });
    axios
      .post(
        `${config.BASE_URL}/advertising/message/category?ws_id=${ws_id}&module_id=${module_id}${url}`,
        category
      )
      .then(response =>
        dispatch({ type: ADD_NOTIFICATION_CATEGORY_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_NOTIFICATION_CATEGORY_ERROR)
          : ADD_NOTIFICATION_CATEGORY_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetAddNotificationCategory = () => ({
  type: ADD_NOTIFICATION_CATEGORY_RESET,
});

export const editNotificationCategory = ({
  categoryId,
  category,
  authKey,
  ws_id,
}) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return dispatch => {
    dispatch({ type: EDIT_NOTIFICATION_CATEGORY_WAITING });
    axios
      .put(
        `${config.BASE_URL}/advertising/message/category/${categoryId}?ws_id=${ws_id}&module_id=${MODULE_ID}`,
        category
      )
      .then(response =>
        dispatch({
          type: EDIT_NOTIFICATION_CATEGORY_SUCCESS,
          payload: response,
        })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_NOTIFICATION_CATEGORY_ERROR)
          : EDIT_NOTIFICATION_CATEGORY_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetEditNotificationCategory = () => ({
  type: EDIT_NOTIFICATION_CATEGORY_RESET,
});

export const deleteNotificationCategory = ({ categoryId, authKey, ws_id }) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return dispatch => {
    dispatch({ type: DELETE_NOTIFICATION_CATEGORY_WAITING });
    axios
      .delete(
        `${config.BASE_URL}/advertising/message/category/${categoryId}?ws_id=${ws_id}&module_id=${MODULE_ID}`
      )
      .then(response =>
        dispatch({
          type: DELETE_NOTIFICATION_CATEGORY_SUCCESS,
          payload: response,
        })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              DELETE_NOTIFICATION_CATEGORY_ERROR
            )
          : DELETE_NOTIFICATION_CATEGORY_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetDeleteNotificationCategory = () => ({
  type: DELETE_NOTIFICATION_CATEGORY_RESET,
});

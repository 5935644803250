import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { fetchCategories, fetchEventsByCategory } from '../../Redux/actions';

import TeamListSeasonFilter from './TeamListSeasonFilter';
import { Spinner } from '../Common/Spinner';

const errorStyle = {
  backgroundColor: '#FFF6F6',
  borderColor: '#E0B4B4',
  color: '#9F3A38',
  WebkitBoxShadow: 'none',
  boxShadow: 'none',
};

class TeamListSeasonFilterController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: '',
      selectedSeries: '',
      categoryError: '',
      seriesError: '',
    };
    this.onChangeSelectedSeason = this.onChangeSelectedSeason.bind(this);
    this.onChangeSelectedCategory = this.onChangeSelectedCategory.bind(this);
    this.buildCategories = this.buildCategories.bind(this);
    this.buildSeasonsByCategory = this.buildSeasonsByCategory.bind(this);
    this.setSeasonErrorStyle = this.setSeasonErrorStyle.bind(this);
    this.setErrorStyle = this.setErrorStyle.bind(this);
    this.setCategoryErrorStyle = this.setCategoryErrorStyle.bind(this);
    this.resetInputOptions = this.resetInputOptions.bind(this);
  }
  componentDidMount() {
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.fetchCategories(authKey, ws_id, 'season');
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.state.selectedCategory !== nextState.selectedCategory) {
      const authKey = localStorage.getItem('x-auth-key');
      const categoryId = nextState.selectedCategory;
      const ws_id = this.props.workspace.activeWorkspace.id;
      this.props.fetchEventsByCategory(authKey, categoryId, 'season', ws_id);
    }
  }

  onChangeSelectedSeason(event, { value }) {
    this.setState({ selectedSeason: value, seasonError: '' });
  }

  onChangeSelectedCategory(event, { value }) {
    this.setState({
      selectedCategory: value,
      selectedSeason: '',
      categoryError: '',
    });
  }
  setCategoryErrorStyle() {
    this.setState({ categoryError: errorStyle });
  }
  setSeasonErrorStyle() {
    this.setState({ seasonError: errorStyle });
  }
  setErrorStyle() {
    this.setState({ categoryError: errorStyle, seasonError: errorStyle });
  }

  resetInputOptions() {
    this.setState({
      selectedCategory: '',
      selectedSeason: '',
      categoryError: '',
      seasonError: '',
    });
  }
  buildCategories() {
    const categories = _.map(
      this.props.eventcategories.eventcategories,
      elt => ({ text: elt.ec_name, value: elt.eventcategory_id })
    );
    return categories;
  }

  buildSeasonsByCategory() {
    let season = [];
    if (!this.props.season) {
      const { parents } = this.props.parents;
      const seasons = _.toArray(this.props.season);
      const performerSeasons = seasons.reduce((arr, elt) => {
        arr.push(elt.event_id);
        return arr;
      }, []);
      const filteredSeasons = parents
        ? _.toArray(parents).filter(
            elt =>
              !performerSeasons.includes(elt.event_id) &&
              elt.ev_type === 'season'
          )
        : [];
      season = _.map(filteredSeasons, elt => ({
        text: elt.ev_name,
        value: elt.event_id,
      }));
    } else {
      season = _.map(this.props.parents, elt => ({
        text: elt.ev_name,
        value: elt.event_id,
      }));
    }
    return season;
  }

  render() {
    if (this.props.eventcategories.status !== 'success') {
      return <Spinner />;
    }
    const categoryList = this.buildCategories();
    const seasonList = this.buildSeasonsByCategory();

    return (
      <TeamListSeasonFilter
        isInline={this.props.isInline}
        header={this.props.header}
        categoryList={categoryList}
        seasonList={seasonList}
        onChangeCategory={this.onChangeSelectedCategory}
        onChangeSeason={this.onChangeSelectedSeason}
        categoryValue={this.state.selectedCategory}
        seasonValue={this.state.selectedSeason}
        categoryStyle={this.state.cateogryError}
        seasonStyle={this.state.seasonError}
        setSeasonErrorStyle={this.setSeasonErrorStyle}
        setCategoryErrorStyle={this.setCategoryErrorStyle}
        setErrorStyle={this.setErrorStyle}
        performerId={this.props.performerId}
        resetInputOptions={this.resetInputOptions}
      />
    );
  }
}
function mapStateToProps(
  { eventcategories, parents, auth, workspace },
  ownProps
) {
  return {
    eventcategories,
    parents,
    auth,
    workspace,
    ...ownProps,
  };
}

export default connect(mapStateToProps, {
  fetchCategories,
  fetchEventsByCategory,
})(TeamListSeasonFilterController);

import React, { Component } from 'react';
import { Form, Grid, Header, Icon } from 'semantic-ui-react';
import { DateTimeInput, TimeInput } from 'semantic-ui-calendar-react-yz';
import { toast } from 'react-semantic-toasts';

import moment from 'moment';
import _ from 'lodash';
import { T } from '../Common/Helpers';

class ShopSpecialOpeningHour extends Component {
  constructor(props) {
    super(props);
    moment.defaultFormat = 'l HH:mm';
    const from = moment().day() + 1;
    const to = (from % 7) + 1;
    this.state = { active: this.props.active, dayRange: { from, to } };
  }

  getRange = (validFrom, validTo) => {
    const vf = moment(validFrom);
    const vt = moment(validTo);

    const diff = vt.diff(vf, 'days');
    if (diff >= 6) {
      return { f: 1, t: 7 };
    }
    const f = moment(validFrom).day() + 1;
    const t = moment(validTo).day() + 1;
    return { f, t };
  };
  getValidDays = (f, t) => {
    const tempDays = _.map(this.days, (d, i) => ({ d, i: i + 1 }));
    if (f <= t) {
      return tempDays
        .filter(ele => ele.i >= f && ele.i <= t)
        .map(ele => ({ text: T(ele.d), value: ele.i }));
    }
    return tempDays
      .filter(ele => ele.i <= t || ele.i >= f)
      .map(ele => ({ text: T(ele.d), value: ele.i }));
  };

  validateOpeningHours = () => {
    const start = moment(this.state.startTime);
    const stop = moment(this.state.endTime);
    if (start > stop) {
      toast({
        type: 'error',
        title: T('Error'),
        description: T('Ending time cannot precede start time!'),
        time: 5000,
      });
      return;
    }
    const shopOpening = this.state.shopOpening.slice();
    return shopOpening.every(
      opening => opening.soh_open_time <= opening.soh_close_time
    );
  };

  days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  toggleShow = () => {
    this.setState(prevState => ({ active: !prevState.active }));
  };
  renderOpenningHours = (f, t) => (
    <Grid.Column>
      <Form.Group widths="equal">
        <Form.Dropdown
          required
          error={this.props.so.saoh_day_of_weekError}
          name="saoh_day_of_week"
          key="saoh_day_of_week"
          label={T('Day of week')}
          placeholder={T('Day of week')}
          clearable={this.props.objKey === 'new_alt_opening'}
          fluid
          selection
          selectOnNavigation
          noResultsMessage={T('No result')}
          onChange={(e, { value }) =>
            this.props.setShopOpening(
              this.props.objKey,
              'saoh_day_of_week',
              value
            )
          }
          options={this.getValidDays(f, t)}
          value={this.props.so.saoh_day_of_week}
        />
        <Form.Field>
          <TimeInput
            // style={{ minWidth: '100px', marginRight: '5px' }}
            error={this.props.so.saoh_open_timeError}
            closable
            clearable={this.props.objKey === 'new_alt_opening'}
            // initialDate={moment()}
            // placeholder={moment().format('HH:mm')}
            name="saoh_open_time"
            label={T('From')}
            disabled={this.props.so.closed}
            //value={this.props.so.saoh_open_time ? moment(this.props.so.saoh_open_time).format('l HH:mm') : ''}
            value={
              this.props.so.saoh_open_time
                ? this.props.formatTime(this.props.so.saoh_open_time)
                : ''
            }
            iconPosition="left"
            onChange={(e, { value }) =>
              this.props.setShopOpening(
                this.props.objKey,
                'saoh_open_time',
                value
              )
            }
            dateTimeFormat="l HH:mm"
            timeFormat="24"
            autoComplete="off"
            history="off"
            required
            pickerWidth="200px"
          />
        </Form.Field>
        <Form.Field>
          <TimeInput
            // style={{ minWidth: '100px', marginRight: '5px' }}
            error={this.props.so.saoh_close_timeError}
            clearable
            closable
            name="saoh_close_time"
            label={T('To')}
            disabled={this.props.so.closed}
            //value={this.props.so.saoh_close_time ? moment(this.props.so.saoh_close_time).format('l HH:mm') : ''}
            value={
              this.props.so.saoh_close_time
                ? this.props.formatTime(this.props.so.saoh_close_time)
                : ''
            }
            iconPosition="left"
            onChange={(e, { value }) =>
              this.props.setShopOpening(
                this.props.objKey,
                'saoh_close_time',
                value
              )
            }
            dateTimeFormat="l HH:mm"
            timeFormat="24"
            autoComplete="off"
            history="off"
            // required
            pickerWidth="200px"
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <DateTimeInput
            // style={{ minWidth: '100px', marginRight: '5px' }}
            closable
            animation="off"
            name="saoh_valid_from"
            dateTimeFormat="l HH:mm"
            label={T('Valid From')}
            value={moment(this.props.so.saoh_valid_from).format('l HH:mm')}
            iconPosition="left"
            onChange={(e, { value }) =>
              this.props.setShopOpening(
                this.props.objKey,
                'saoh_valid_from',
                value
              )
            }
            timeFormat="24"
            autoComplete="off"
            history="off"
            required
            // pickerWidth='200px'
          />
        </Form.Field>
        <Form.Field>
          <DateTimeInput
            // style={{ minWidth: '100px', marginRight: '5px' }}
            closable
            animation="off"
            dateTimeFormat="l HH:mm"
            name="saoh_valid_to"
            label={T('Valid To')}
            value={moment(this.props.so.saoh_valid_to).format('l HH:mm')}
            iconPosition="left"
            onChange={(e, { value }) =>
              this.props.setShopOpening(
                this.props.objKey,
                'saoh_valid_to',
                value
              )
            }
            autoComplete="off"
            history="off"
            required
            // pickerWidth='200px'
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Checkbox
          toggle
          checked={!this.props.so.saoh_closed}
          onChange={(e, { checked }) =>
            this.props.setShopOpening(this.props.objKey, 'saoh_closed', checked)
          }
          label={T('Open')}
        />
      </Form.Group>
    </Grid.Column>
  );

  render() {
    // console.log('Props: ', this.props);
    const validFrom = this.props.so.saoh_valid_from;
    const validTo = this.props.so.saoh_valid_to;
    const validRange = this.getRange(validFrom, validTo);
    //console.log('Shop opening hours props: ', this.props);
    return (
      <Grid
        stackable
        style={{ marginTop: '10px', maxWidth: '1280px', marginBottom: '20px' }}
      >
        <Grid.Row columns="1" onClick={this.toggleShow}>
          <Grid.Column style={{ cursor: 'pointer' }}>
            <Header>
              {!this.state.active ? (
                <Icon name="caret square right" size="large" />
              ) : (
                <Icon name="caret square down" size="large" />
              )}
              {T(this.props.title)}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {this.state.active && (
          <React.Fragment>
            <Grid.Row
              columns="2"
              style={{ border: '1px solid rgba(232, 232, 232, 1)' }}
            ></Grid.Row>
            <Grid.Row
              columns="1"
              style={{ border: '1px solid rgba(232, 232, 232, 1)' }}
            >
              {this.renderOpenningHours(validRange.f, validRange.t)}
            </Grid.Row>
          </React.Fragment>
        )}
      </Grid>
    );
  }
}
export default ShopSpecialOpeningHour;

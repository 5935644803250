import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Header,
  Icon,
  Pagination,
  Confirm,
  Message,
  Modal,
  Button,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
//import { fetchUsers } from '../../Redux/actions';
import { T, dddDMMMYYYY, StatusIcon } from '../Common/Helpers';
import {
  deleteCardInstance,
  downloadCardInstancesReport,
} from '../../Redux/actions';
import MemberCardAdd from '../Membership/MemberCardAdd';

const CARD_INSTANCE_ID = 'card_instance_id';
const CD_NAME = 'cd_name';
const CD_VALID_FROM = 'cd_validfrom';
const CD_VALID_TO = 'cd_validto';
const CARD_NAME = 'Card Name';
const CI_FIRSTNAME = 'ci_firstname';
const CI_LASTNAME = 'ci_lastname';
const CI_EMAIL = 'ci_email';
const CI_PHONE = 'ci_phone';
const CI_CREATED = 'ci_created';
const CI_CREATOR = 'ci_creator';
const CI_LICENSE = 'ci_licencecode';
const VALID_FROM = 'Valid from';
const VALID_TO = 'Valid to';
const FIRST_NAME = 'First Name';
const LAST_NAME = 'Last Name';
const EMAIL = 'Email';
const PHONE = 'Phone';
const CREATE_DATE = 'Created';
const CC_ADD_TITLE = 'Client Console Add';
const CC_PERSON = 'Client Console User';
const CC_ADD = 'cc_create';
const VALID_MEMBER_CARD = 'valid_member_card';
const LICENSE_CODE = 'Serial Number';
const ASCENDING = 'ascending';
const DESCENDING = 'descending';
const TABLE_COLS = 13;

const CONFIRM_DELETE_TEXT = 'Delete';
const NO_RESULTS = 'No results found';
const ADD_MEMBER_CARD = 'Add Member Card';
const NO_CARDS = 'No Card Available';

const MEMBERSHIP = 'membership';
const REGISTRATIONCARD = 'registrationcard';

const styles = {
  downloadBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '30px',
  },
  downloadItem: {
    display: 'flex',
    flexBasis: '80px',
    textDecoration: 'underline',
    color: 'blue',
    textTransform: 'uppercase',
    cursor: 'pointer',
    fontSize: 'large',
  },
};

class CardInstanceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortDirection: ASCENDING,
      sortColName: CARD_INSTANCE_ID,
      cardinstances: this.props.cardinstances,
      rowsPerPage: 20,
      currentPage: 1,
      showConfirm: false,
      openModal: false,
      availableCards: [],
      reportLang: 'en',
    };

    this.deleteCardInstance = this.deleteCardInstance.bind(this);
    this.onCancelConfirm = this.onCancelConfirm.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    //this.toggleMemberCardModal = this.toggleMemberCardModal.bind(this);
    //this.closeMemberCardModal = this.closeMemberCardModal.bind(this);
    this.moduleId = this.props.params.moduleId;
  }

  onCancelConfirm() {
    this.setState({ showConfirm: false });
  }

  onConfirmDelete() {
    const { cardInstanceId } = this.state;
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.ws_id;
    const application_id = this.props.activeWorkspace.cnc_application;
    if (cardInstanceId) {
      this.props.deleteCardInstance({
        moduleId: this.moduleId,
        authKey,
        ws_id,
        application_id,
        card_instance_id: cardInstanceId,
      });
    }
    this.setState({ showConfirm: false });
  }

  getDirection(dirn) {
    return dirn === ASCENDING ? ASCENDING : DESCENDING;
  }

  downloadReport(format) {
    const authKey = localStorage.getItem('x-auth-key');
    const { wsId, applicationId, cardType } = this.props.params;
    console.log('this.props', this.props);
    const { searchTerm } = this.props.cardinstance;
    this.props.downloadCardInstancesReport(
      this.moduleId,
      authKey,
      wsId,
      applicationId,
      cardType,
      format,
      searchTerm
    );
  }

  deleteCardInstance = (e, cardInstanceId, ciEmail) => {
    e.stopPropagation();
    //confirmText: `${T(CONFIRM_DELETE_TEXT)} id: ${cardInstanceId} email: ${ciEmail} ?`,
    this.setState({
      showConfirm: true,
      cardInstanceId,
      confirmText: `${T(CONFIRM_DELETE_TEXT)} Id: ${cardInstanceId} ${T(
        'Email'
      )} : ${ciEmail} ?`,
    });
  };

  closeMemberCardModal(card) {
    this.setState({ openModal: false });
    this.updateMemberCardsStatus(card[0]);
  }

  updateMemberCardsStatus(card) {
    const cardinstances = [...this.state.cardinstances];
    const index = cardinstances.findIndex(
      item => item.card_instance_id === this.state.cardInstanceId
    );
    const valid = card.valid === undefined ? false : card.valid;
    if (index !== -1) {
      const tmp = cardinstances[index];
      const availableCards = tmp.available_cards.filter(
        item => item.card_id !== card.card_id
      );
      const validCards = cardinstances[index].valid_cards;
      if (valid) {
        validCards.push(card);
      }
      cardinstances[index].available_cards = availableCards;
      cardinstances[index].valid_cards = validCards;
      this.setState({ cardinstances });
      this.forceUpdate();
    }
  }

  toggleMemberCardModal = (e, value, cardInstanceId, availableCards) => {
    e.stopPropagation();
    this.setState({ openModal: value, cardInstanceId, availableCards });
  };

  navigateToLink = card_instance_id => {
    //this.props.history.push({ pathname: `/app/ticketing/seasoncard/edit/${card_instance_id}` });
    this.props.history.push({
      pathname: `${this.props.params.url}/edit/${card_instance_id}`,
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    const { total_number } = this.props.cardinstances;
    const { rowsPerPage } = this.state;
    if ((activePage - 1) * rowsPerPage > total_number) {
      return;
    }
    this.setState({ currentPage: activePage });
    //this.fetchReport(activePage);
  };

  handleSort = (sortColName, isNumeric) => {
    const { sortDirection, cardinstances } = this.state;
    const isAscending = sortDirection === ASCENDING;

    if (isNumeric) {
      if (isAscending) {
        cardinstances.sort((a, b) => a[sortColName] - b[sortColName]);
      } else {
        cardinstances.sort((a, b) => b[sortColName] - a[sortColName]);
      }
    }

    if (isNumeric === false) {
      if (isAscending) {
        cardinstances.sort((a, b) =>
          a[sortColName] < b[sortColName] ? 1 : -1
        );
      } else {
        cardinstances.sort((a, b) =>
          a[sortColName] < b[sortColName] ? -1 : 1
        );
      }
    }

    const dirn = sortDirection === ASCENDING ? DESCENDING : ASCENDING;
    this.setState({ cardinstances, sortDirection: dirn, sortColName });
  };

  renderPagination(tableCols) {
    const total_number = this.state.cardinstances.length;
    const { rowsPerPage, currentPage } = this.state;
    const totalPages = parseInt(total_number / rowsPerPage, 10) + 1;
    const firstItem = (currentPage - 1) * rowsPerPage + 1;
    let lastItem = currentPage * rowsPerPage;
    if (lastItem > total_number) {
      lastItem = total_number;
    }

    return (
      <Table.Row>
        <Table.HeaderCell colSpan={tableCols}>
          {`${firstItem} - ${lastItem} / ${total_number}`}
          <Pagination
            floated="right"
            onPageChange={this.handlePaginationChange}
            activePage={currentPage}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={totalPages}
          />
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  renderReport(tableCols) {
    return (
      <Table.Row>
        <Table.HeaderCell colSpan={tableCols}>
          <div>
            <div style={styles.downloadBlock}>
              <div
                style={styles.downloadItem}
                onClick={() => this.downloadReport('csv')}
              >
                <Button icon="file excel" label={T('CSV')} />
              </div>
              <div
                style={styles.downloadItem}
                onClick={() => this.downloadReport('xls')}
              >
                <Button icon="file excel" label={T('XLS')} />
              </div>
            </div>
          </div>
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  renderCardInstanceList(cardType) {
    /*
    <Icon
      name="id card"
      color='green'
      corner='bottom left'
      size='large'
      onClick={(e) => this.showMemberCard(e, cardinstance.card_instance_id)}
      //onClick={this.toggleModal}
    />*/

    const { rowsPerPage, currentPage } = this.state;
    // TODO testaa tyhjä lista
    // const cardinstances = this.props.cardinstances.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
    const cardinstances = this.state.cardinstances.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
    const isEditable = true;
    //const cursor = isEditable ? { cursor: 'pointer' } : { cursor: 'default' };
    const cursor = { cursor: 'pointer' };

    return cardinstances.map(cardinstance => {
      const validCards =
        cardType === MEMBERSHIP
          ? cardinstance.valid_cards.length > 0
          : cardinstance.valid_card;
      const ccCreate = cardinstance.ci_first_sales_row === null;
      const showValidCard =
        cardType !== MEMBERSHIP && cardType !== REGISTRATIONCARD;
      return (
        <Table.Row
          key={cardinstance.card_instance_id}
          onClick={() => this.navigateToLink(cardinstance.card_instance_id)}
          style={cursor}
          warning={isEditable}
        >
          <Table.Cell>{cardinstance.card_instance_id}</Table.Cell>
          <Table.Cell>{cardinstance.cd_name}</Table.Cell>
          {cardType === REGISTRATIONCARD ? (
            <Table.Cell>{cardinstance.ci_licencecode}</Table.Cell>
          ) : null}
          <Table.Cell>{cardinstance.ci_firstname}</Table.Cell>
          <Table.Cell>{cardinstance.ci_lastname}</Table.Cell>
          <Table.Cell>{cardinstance.ci_email}</Table.Cell>
          <Table.Cell>{cardinstance.ci_phone}</Table.Cell>
          <Table.Cell>{dddDMMMYYYY(cardinstance.cd_validfrom)}</Table.Cell>
          <Table.Cell>{dddDMMMYYYY(cardinstance.cd_validto)}</Table.Cell>
          <Table.Cell>{dddDMMMYYYY(cardinstance.ci_created)}</Table.Cell>
          <Table.Cell>{cardinstance.ci_creator}</Table.Cell>
          {showValidCard ? (
            <Table.Cell>
              <StatusIcon status={ccCreate} />
            </Table.Cell>
          ) : null}
          {cardType === REGISTRATIONCARD ? null : (
            <Table.Cell>
              <StatusIcon status={validCards} />
            </Table.Cell>
          )}
          {cardType === MEMBERSHIP ? (
            <Table.Cell collapsing textAlign="right">
              {cardinstance.available_cards.length > 0 ? (
                <Icon
                  name="id card"
                  color="green"
                  corner="bottom left"
                  size="big"
                  onClick={e =>
                    this.toggleMemberCardModal(
                      e,
                      true,
                      cardinstance.card_instance_id,
                      cardinstance.available_cards
                    )
                  }
                  //onClick={this.toggleModal}
                />
              ) : (
                T(NO_CARDS)
              )}
            </Table.Cell>
          ) : null}

          <Table.Cell collapsing textAlign="right">
            <Icon
              name="trash alternate"
              color="red"
              corner="bottom left"
              size="large"
              onClick={e =>
                this.deleteCardInstance(
                  e,
                  cardinstance.card_instance_id,
                  cardinstance.ci_email
                )
              }
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    const { sortDirection, sortColName } = this.state;
    const { cardType, cardTitle } = this.props.params;
    const tableCols =
      cardType === REGISTRATIONCARD ? TABLE_COLS + 1 : TABLE_COLS;

    return (
      <React.Fragment>
        <Header> {T(this.props.params.listHeader)} </Header>
        {this.props.cardinstances.length === 0 ? (
          <Message info>{T(NO_RESULTS)}</Message>
        ) : (
          <Table sortable compact celled padded stackable selectable>
            <Table.Header>
              {this.renderReport(tableCols)}
              {this.renderPagination(tableCols)}
              <Table.Row>
                <Table.HeaderCell
                  sorted={
                    sortColName === CARD_INSTANCE_ID
                      ? this.getDirection(sortDirection)
                      : null
                  }
                  //onClick={() => this.handleSortingReport(CARD_INSTANCE_ID, sortDirection)}
                  onClick={() => this.handleSort(CARD_INSTANCE_ID, true)}
                >
                  {T('#')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    sortColName === CD_NAME
                      ? this.getDirection(sortDirection)
                      : null
                  }
                  onClick={() => this.handleSort(CD_NAME, false)}
                >
                  {T(cardTitle) || T(CARD_NAME)}
                </Table.HeaderCell>
                {cardType === REGISTRATIONCARD ? (
                  <Table.HeaderCell
                    sorted={
                      sortColName === CI_LICENSE
                        ? this.getDirection(sortDirection)
                        : null
                    }
                    onClick={() => this.handleSort(CI_LICENSE, false)}
                  >
                    {T(LICENSE_CODE)}
                  </Table.HeaderCell>
                ) : null}
                <Table.HeaderCell
                  sorted={
                    sortColName === CI_FIRSTNAME
                      ? this.getDirection(sortDirection)
                      : null
                  }
                  onClick={() => this.handleSort(CI_FIRSTNAME, false)}
                >
                  {T(FIRST_NAME)}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    sortColName === CI_LASTNAME
                      ? this.getDirection(sortDirection)
                      : null
                  }
                  onClick={() => this.handleSort(CI_LASTNAME, false)}
                >
                  {T(LAST_NAME)}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    sortColName === CI_EMAIL
                      ? this.getDirection(sortDirection)
                      : null
                  }
                  onClick={() => this.handleSort(CI_EMAIL, false)}
                >
                  {T(EMAIL)}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    sortColName === CI_PHONE
                      ? this.getDirection(sortDirection)
                      : null
                  }
                  onClick={() => this.handleSort(CI_PHONE, false)}
                >
                  {T(PHONE)}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    sortColName === CD_VALID_FROM
                      ? this.getDirection(sortDirection)
                      : null
                  }
                  onClick={() => this.handleSort(CD_VALID_FROM, false)}
                >
                  {T(VALID_FROM)}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    sortColName === CD_VALID_TO
                      ? this.getDirection(sortDirection)
                      : null
                  }
                  onClick={() => this.handleSort(CD_VALID_TO, false)}
                >
                  {T(VALID_TO)}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    sortColName === CI_CREATED
                      ? this.getDirection(sortDirection)
                      : null
                  }
                  onClick={() => this.handleSort(CI_CREATED, false)}
                >
                  {T(CREATE_DATE)}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    sortColName === CI_CREATOR
                      ? this.getDirection(sortDirection)
                      : null
                  }
                  onClick={() => this.handleSort(CI_CREATOR, false)}
                >
                  {T(CC_PERSON)}
                </Table.HeaderCell>
                {cardType !== MEMBERSHIP && cardType !== REGISTRATIONCARD ? (
                  <Table.HeaderCell
                    sorted={
                      sortColName === CC_ADD
                        ? this.getDirection(sortDirection)
                        : null
                    }
                    onClick={() => this.handleSort(CC_ADD, false)}
                  >
                    {T(CC_ADD_TITLE)}
                  </Table.HeaderCell>
                ) : null}
                {cardType === REGISTRATIONCARD ? null : (
                  <Table.HeaderCell
                    sorted={
                      sortColName === VALID_MEMBER_CARD
                        ? this.getDirection(sortDirection)
                        : null
                    }
                    onClick={() => this.handleSort(VALID_MEMBER_CARD, false)}
                  >
                    {T(this.props.params.validHeader)}
                  </Table.HeaderCell>
                )}
                {cardType === MEMBERSHIP ? (
                  <Table.HeaderCell>{T(ADD_MEMBER_CARD)}</Table.HeaderCell>
                ) : null}
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.renderCardInstanceList(cardType)}
              <Table.Row>
                <Confirm
                  header={T(this.props.params.confirmDeleteHeader)}
                  content={T(this.state.confirmText)}
                  cancelButton={T('Cancel')}
                  confirmButton={T('Delete Data')}
                  open={this.state.showConfirm}
                  onCancel={this.onCancelConfirm}
                  onConfirm={this.onConfirmDelete}
                  size="tiny"
                />
              </Table.Row>
              <Table.Row>
                <Modal open={this.state.openModal}>
                  <Header>{T(ADD_MEMBER_CARD)}</Header>
                  <Modal.Content>
                    <MemberCardAdd
                      cardInstanceId={this.state.cardInstanceId}
                      closeModal={this.toggleMemberCardModal.bind(this)}
                      closeOnSaveModal={this.closeMemberCardModal.bind(this)}
                      applicationId={this.props.params.applicationId}
                      wsId={this.props.params.wsId}
                      cards={this.state.availableCards}
                    />
                  </Modal.Content>
                </Modal>
              </Table.Row>
            </Table.Body>
            <Table.Footer>{this.renderPagination(tableCols)}</Table.Footer>
          </Table>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps({ cardinstances, workspace }) {
  return {
    cardinstance: cardinstances,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
  };
}

export default withRouter(
  connect(mapStateToProps, {
    deleteCardInstance,
    downloadCardInstancesReport,
  })(CardInstanceList)
);

import React, { Component } from 'react';
import { Form, Segment, Button, Confirm } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { T } from '../../Common/Helpers';
import {
  editLocation,
  resetEditLocation,
  addLocation,
  resetAddLocation,
} from '../../../Redux/actions';
// import MapController from './MapController';
import GoogleMaps from './GoogleMaps';
import { Spinner } from '../../Common/Spinner';
// import ErrorBoundary from './ErrorBoundary';

const errorStyle = {
  backgroundColor: '#FFF6F6',
  borderColor: '#E0B4B4',
  color: '#9F3A38',
  WebkitBoxShadow: 'none',
  boxShadow: 'none',
  width: '250px',
  outlineStyle: 'solid',
  outlineColor: 'rgb(224, 180, 180)',
  outlineWidth: '1px',
};
const inputStyle = { width: '250px' };

class LocationManager extends Component {
  constructor(props) {
    super(props);
    //console.log('Add Location constructor');
    if (this.props.location) {
      const lat = this.props.location.lo_latitude;
      const lng = this.props.location.lo_longitude;
      this.state = {
        openDeleteConfirmation: false,
        lat: this.isValidCoord(lat, 90) ? lat : 48.60770775362637,
        lng: this.isValidCoord(lng, 180) ? lng : 2.3015814091647826,
        latStyle: inputStyle,
        lngStyle: inputStyle,
        locationName: props.location.lo_name,
        initialized: true,
        map: undefined,
      };
    } else {
      this.state = { initialized: false };
    }
    this.setLocation = this.setLocation.bind(this);
    this.setLatitude = this.setLatitude.bind(this);
    this.setLongitude = this.setLongitude.bind(this);
    this.setNewLocationName = this.setNewLocationName.bind(this);
    this.onValidSubmit = this.onValidSubmit.bind(this);
  }
  componentDidMount() {
    if (!this.props.location) {
      this.setDefaultCenter();
    }
  }
  onDeleteClick() {
    this.setState({ openDeleteConfirmation: true });
  }
  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }
  onConfirmDelete() {
    const authKey = localStorage.getItem('x-auth-key');
    this.props.deleteLocation(
      this.props.match.params.locationId,
      authKey,
      this.props.ws_id
    );
    this.setState({ openDeleteConfirmation: false });
  }
  onCancel() {
    this.props.closeModal();
  }

  onValidSubmit(e) {
    e.preventDefault();
    const { lat, lng } = this.state;
    const latErr = !this.isValidCoord(lat, 90);
    const lngErr = !this.isValidCoord(lng, 180);
    if (latErr && lngErr) {
      this.setState({ latStyle: errorStyle, lngStyle: errorStyle });
      return;
    } else if (latErr) {
      this.setState({ latStyle: errorStyle });
      return;
    } else if (lngErr) {
      this.setState({ lngStyle: errorStyle });
      return;
    }
    const inputValues = {
      lo_name: this.state.locationName,
      lo_latitude: lat,
      lo_longitude: lng,
    };
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    const { location } = this.props;
    if (location) {
      const locationId = location.location_id;
      this.props.editLocation(locationId, inputValues, authKey, ws_id);
    } else {
      this.props.addLocation(inputValues, authKey, ws_id);
    }
    this.props.closeModal();
  }
  setDefaultCenter() {
    // console.log("Set Default center called");
    const tempState = {
      lat: 48.60770775362637,
      lng: 2.3015814091647826,
      locationName: '',
      latStyle: inputStyle,
      lngStyle: inputStyle,
      nameStyle: inputStyle,
      initialized: false,
    };
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(position =>
        this.setState({
          ...tempState,
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          initialized: true,
        })
      );
    } else {
      return { ...tempState, initialized: true };
    }
  }

  setLocation(e) {
    // debugger;
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    // debugger;
    this.setState({ lat, lng });
  }
  setNewLocationName(e, { value }) {
    this.setState({ locationName: value });
  }
  setLatitude(e, { value }) {
    this.setState({ lat: value, latStyle: inputStyle });
  }
  setLongitude(e, { value }) {
    this.setState({ lng: value, lngStyle: inputStyle });
  }
  setMapObj = map => {
    console.log('Map: ', map);
    this.setState({ map });
  };
  isValidCoord = (val, lim) => val !== null && val < lim && val > -1 * lim;

  render() {
    // const { location } = this.props;
    //console.log('Location state: ', this.state, '\nProps: ', this.props);
    const { initialized, lat, lng, locationName, map } = this.state;
    if (!initialized) {
      return <Spinner />;
    }
    return (
      <Segment.Group horizontal>
        <Segment>
          <Form>
            <Form.Group>
              <Form.Input
                required
                // value={performer.pf_name}
                autoComplete="off"
                name="lo_name"
                label={T('Name')}
                width={6}
                style={{ ...inputStyle }}
                value={locationName}
                onChange={this.setNewLocationName}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                required
                // value={performer.pf_name}
                autoComplete="off"
                type="number"
                min="-90"
                max="90"
                step={String(1e-15)}
                name="lo_latitude"
                label={T('Latitude')}
                width={6}
                value={lat}
                onChange={this.setLatitude}
                style={this.state.latStyle}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                required
                // value={performer.pf_name}
                autoComplete="off"
                min="-180"
                max="180"
                type="number"
                step={String(1e-15)}
                // onChange={(e)=>this.validateLongitude(e)}
                name="lo_longitude"
                label={T('Longitude')}
                // style={{...inputStyle, ...this.state.longitudeStyle}}
                style={this.state.lngStyle}
                value={lng}
                onChange={this.setLongitude}
                width={6}
              />
            </Form.Group>
            <Button
              content={T('Save')}
              color="green"
              icon="save"
              onClick={this.onValidSubmit}
            />
            <Button
              type="button"
              color="red"
              content={T('Delete Venue')}
              onClick={this.onDeleteClick.bind(this)}
            />
            <Confirm
              header={T('Delete Location')}
              content={T('Are you sure you want to delete the location?')}
              confirmButton={T('Delete')}
              open={this.state.openDeleteConfirmation}
              onCancel={this.oncancelDeleteConfirmation}
              onConfirm={this.onConfirmDelete}
              size="tiny"
            />
            <Button
              type="button"
              color="black"
              content={T('Cancel')}
              onClick={() => this.onCancel()}
            />
          </Form>
        </Segment>
        <Segment>
          <GoogleMaps
            setLocation={this.setLocation}
            setMapObj={this.setMapObj}
            map={map}
            lat={lat}
            lng={lng}
          />
        </Segment>
      </Segment.Group>
    );
  }
}

function mapStateToProps({ workspace }) {
  return { ws_id: workspace.ws_id };
}

export default connect(mapStateToProps, {
  editLocation,
  resetEditLocation,
  addLocation,
  resetAddLocation,
})(withRouter(LocationManager));

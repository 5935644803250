import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import PersonEdit from "./PersonEdit";
import { withRouter } from "react-router";
import { Spinner } from "../Common/Spinner";
import { fetchPerson } from "../../Redux/actions";

class PermissionControl extends Component {
  componentDidMount() {
    // localStorage.setItem('route', this.props.history.location.pathname);
    const authKey = localStorage.getItem("x-auth-key");
    const ws_id = this.props.workspace.activeWorkspace.id;
    this.waitFetch = false;
    if (!(this.props.location.state && this.props.location.state.person)) {
      const publicId = this.props.match.params.personId;
      this.props.fetchPerson(publicId, authKey, ws_id);
      this.waitFetch = true;
    }
  }
  render() {
    const personId = this.props.match.params.personId;
    // console.log('Permission Control waitFetch: ', this.waitFetch, ' FetchStatus: ', this.props.fetchStatus);
    if (this.waitFetch === true && this.props.fetchStatus !== "success") {
      return <Spinner />;
    }
    this.waitFetch = false;
    const person =
      this.props.location.state && this.props.location.state.person
        ? this.props.location.state.person
        : this.props.person;
    return (
      <Segment.Group>
        <Segment>
          <PersonEdit {...this.props} personId={personId} person={person} />
        </Segment>
      </Segment.Group>
    );
    //   return (
    //     <Segment.Group>
    //       <Segment>
    //          <PersonEdit personId={personId} />
    //       </Segment>
    //     <Segment.Group horizontal>
    //       <Segment>
    //         <Header as="h4" content={T('Permissions')} />
    //         <PermissionManager personId={personId} />
    //       </Segment>
    //       <Segment>
    //         <Header as="h4" content={T('Companies')} />
    //         <CompanyList />
    //       </Segment>
    //     </Segment.Group>
    //   </Segment.Group>
    // );
  }
}

function mapStateToProps({ persons, workspace }) {
  return {
    person: persons.person,
    fetchStatus: persons.operationState.fetch,
    workspace,
  };
}
export default connect(mapStateToProps, {
  fetchPerson,
})(withRouter(PermissionControl));

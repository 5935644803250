/* eslint-env browser */
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { withRouter } from 'react-router';
import { Table, Icon, Menu, Pagination } from 'semantic-ui-react';
import {
  addProductPrice,
  editProductPrice,
  resetAddProductPrice,
  resetEditProductPrice,
} from '../../Redux/actions';
// import { Spinner } from '../Common/Spinner';
import { T, getErrorMessage } from '../Common/Helpers';
import RenderPriceListTickets from './RenderPriceListTickets';
// Todo: remove hardcoded values ...
// const pr_type = 'entry';
// const pp_currency = 'pts';
// const pp_price_list = 27;

class PriceListTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByColumn: 'product_id',
      direction: 'asc',
      // prices: [],
      // priceLists: []
    };
  }
  // componentDidMount() {
  //   // console.log('opening with props ', this.props);
  //   if (!this.props.productsList) {
  //       const ws_id = this.props.workspace.activeWorkspace.id;
  //       // localStorage.setItem('route', this.props.history.location.pathname);
  //       const authKey = localStorage.getItem('x-auth-key');
  //       this.props.fetchProducts({
  //         authKey,
  //         sortByColumn: 'product_id',
  //         direction: 'asc',
  //         start: 1,
  //         searchterm: null,
  //         ws_id
  //       });
  //     }
  // }

  componentDidUpdate(prevProps) {
    if (
      this.props.fetchStatus === 'error' &&
      prevProps.fetchStatus !== 'error'
    ) {
      // this.props.resetProducts();
      const errorObj = getErrorMessage(
        this.props.error,
        'Could not fetch products'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      this.props.editMulti === 'error' &&
      prevProps.editMulti !== 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.error,
        'Could not edit multiple prices'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      this.props.editMulti === 'success' &&
      prevProps.editMulti !== 'success'
    ) {
      toast({
        type: 'success',
        animation: 'scale',
        title: T('Success'),
        description: T('price(s) updated successfully'),
        time: 5000,
      });
      const ws_id = this.props.workspace.activeWorkspace.id;
      const authKey = localStorage.getItem('x-auth-key');
      const pp_price_list = this.props.match.params.priceListId;
      this.props.getListOfProducts({
        authKey,
        sortByColumn: 'product_id',
        direction: 'asc',
        start: 1,
        searchterm: null,
        ws_id,
        pp_price_list,
      });
      // this.props.fetchProducts({
      //   authKey,
      //   sortByColumn: 'product_id',
      //   direction: 'asc',
      //   start: 1,
      //   searchterm: null,
      //   ws_id
      // });
    }
  }

  getDirection(dirn) {
    if (dirn === 'asc') {
      return 'ascending';
    }
    return 'descending';
  }

  getPriceListObj = () => {
    //console.log('Updating price list');
    let priceLists = {};
    const priceListId = this.props.match.params.priceListId;
    // console.log('Products prop: ', priceListId);
    // const productData = this.state.internalSearch ? this.props.products : this.props.productsList;
    const productsWithPriceList = _.filter(
      this.props.products,
      p => p.pricelists !== null
    );
    _.map(productsWithPriceList, product => {
      _.forEach(product.pricelists, pricelist => {
        if (
          (priceListId &&
            parseInt(pricelist.pp_price_list, 10) ===
              parseInt(priceListId, 10)) ||
          !priceListId
        ) {
          priceLists = {
            ...priceLists,
            //[pricelist.product_price_id]: {
            [pricelist.pp_list_order]: {
              price: pricelist.pp_price,
              pp_price_list: pricelist.pp_price_list,
              product_price_id: pricelist.product_price_id,
              pr_id: product.product_id,
              pr_name: product.pr_name,
              pr_short_description: product.pr_short_description,
              // pr_code: product.pr_code,
              pp_active: pricelist.pp_active,
              pr_active: product.pr_active,
              pr_separate_ticket: product.pr_separate_ticket,
              pr_recommended: product.pr_recommended,
              pp_currency: pricelist.pp_currency,
              pr_list_order: product.pr_list_order,
              pp_list_order: pricelist.pp_list_order,
            },
          };
        }
      });
    });
    return priceLists;
  };
  navigateToLink = () => {
    this.props.history.push('/app/product/manage/');
  };
  handlePaginationChange = (e, { activePage }) => {
    // this should be updated to list price-list products for the current price-list
    const ws_id = this.props.workspace.activeWorkspace.id;
    const { headers } = this.props;
    const authKey = localStorage.getItem('x-auth-key');
    let params = {
      authKey,
      sortByColumn: headers.sort,
      direction: null,
      start: activePage,
      searchterm: headers.searchterm,
      // pr_type,
      ws_id, //<price-list-id> will be included
    };
    if (this.props.pp_price_list) {
      params = { ...params, pp_price_list: this.props.pp_price_list };
    }
    this.props.getListOfProducts(params);
  };

  sortByColumn = columnName => {
    // this should be updated to list price-list products for the current price-list
    const { direction, sortByColumn } = this.state;
    const { searchterm } = this.props.headers;
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem('x-auth-key');
    let params = {
      authKey,
      sortByColumn: columnName,
      start: 1,
      searchterm,
      // pr_type,
      ws_id,
    };
    if (this.props.pp_price_list) {
      params = { ...params, pp_price_list: this.props.pp_price_list };
    }
    if (sortByColumn === columnName) {
      const modifiedDirn = direction === 'asc' ? 'desc' : 'asc';
      this.props.getListOfProducts({ ...params, direction: modifiedDirn });
      this.setState({ direction: modifiedDirn });
    } else {
      const modifiedDirn = 'asc';
      this.props.getListOfProducts({ ...params, direction: modifiedDirn });
      this.setState({ sortByColumn: columnName, direction: modifiedDirn });
    }
  };

  render() {
    const priceLists = this.getPriceListObj();
    const column = this.state.sortByColumn;
    const dirn = this.state.direction;
    // console.log('Price list tickets: ', this.props.headers);
    return (
      <Table sortable compact celled padded stackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === 'product_id' ? this.getDirection(dirn) : null}
              onClick={() => this.sortByColumn('product_id')}
            >
              {T('Id')}
            </Table.HeaderCell>
            <Table.HeaderCell>{T('Order')}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'pr_name' ? this.getDirection(dirn) : null}
              onClick={() => this.sortByColumn('pr_name')}
            >
              {T('Name')}
            </Table.HeaderCell>
            <Table.HeaderCell>{T('Short description')}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'pr_code' ? this.getDirection(dirn) : null}
              onClick={() => this.sortByColumn('pp_currency')}
            >
              {T('Currency')}
            </Table.HeaderCell>
            <Table.HeaderCell>{T('Active')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Price')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <RenderPriceListTickets
            {...this.props}
            priceLists={priceLists}
            deletePPStatus={this.props.deletePPStatus}
            priceListId={this.props.match.params.priceListId}
          />
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="8">
              {this.props.headers && this.props.headers.total_pages > 1 && (
                <Menu floated="right" pagination>
                  <Pagination
                    onPageChange={this.handlePaginationChange}
                    defaultActivePage={this.props.headers.current_page}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={this.props.headers.total_pages}
                  />
                </Menu>
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}

function mapStateToProps({ auth, product, workspace }) {
  return {
    auth,
    deletePPStatus: product.deletePPStatus,
    products: product.products,
    headers: product.headers,
    fetchStatus: product.fetchStatus,
    workspace,
    editMulti: product.editMulti,
    error: product.error,
  };
}

export default connect(mapStateToProps, {
  addProductPrice,
  editProductPrice,
  resetAddProductPrice,
  resetEditProductPrice,
})(withRouter(PriceListTickets));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import jstz from 'jstz';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { toast } from 'react-semantic-toasts';
import {
  Table,
  Icon,
  Confirm,
  Menu,
  Pagination,
  Message,
} from 'semantic-ui-react';

import {
  fetchNotifications,
  resetFetchNotifications,
  deleteNotification,
  resetDeleteNotification,
  fetchApplications,
  resetFetchApplications,
  resetAddNotification,
} from '../../Redux/actions';
import AddNotification from './AddNotification';
import EditNotification from './EditNotification';
import ModalWindow, { ModalContext } from '../Common/ModalWindow';
import { Spinner } from '../Common/Spinner';
import { T, getErrorMessage } from '../Common/Helpers';
import MessageToggler from './MessageToggler';

const TIMER_SECONDS = 1;
const timezone = jstz.determine().name();
const POPUP_CONTENT =
  'If you select High priority the notification is trying to be sent even if the app is in the background or phone is on power save mode.';
const POPUP_PARAMS = {
  size: 'large',
  color: 'grey',
  wide: 'very',
  content: POPUP_CONTENT,
};
const SAVE_SUCCESS = 'Data saved successfully';
const DELETE_SUCCESS = 'Data deleted successfully';

class ListNotification extends Component {
  constructor(props) {
    super(props);
    this.timer = 0;
    this.state = {
      sortByColumn: 'message_id',
      direction: 'desc',
      expandedNotificationId: -1,
      openDeleteConfirmation: false,
      seconds: TIMER_SECONDS,
      update: false,
      time: {},
    };
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.toggleTableCellSize = this.toggleTableCellSize.bind(this);
    this.sortVenuesByColumnName = this.sortVenuesByColumnName.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    this.fetchNotificationsList();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.archive !== prevProps.match.params.archive &&
      this.props.auth.apps.length > 0
    ) {
      this.fetchNotificationsList();
    }

    if (
      (prevProps.addStatus !== 'success' &&
        this.props.addStatus === 'success') ||
      (prevProps.editStatus !== 'success' &&
        this.props.editStatus === 'success')
    ) {
      this.props.resetAddNotification();
      toast({
        type: 'success',
        title: T('Success'),
        description: T(SAVE_SUCCESS),
        time: 5000,
      });
    } else if (
      (prevProps.addStatus !== 'error' && this.props.addStatus === 'error') ||
      (prevProps.editStatus !== 'error' && this.props.editStatus === 'error')
    ) {
      this.props.resetAddNotification();
      const errorObj = getErrorMessage(
        this.props.error,
        'Failed to save notification'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }

    if (
      prevProps.deleteStatus !== 'success' &&
      this.props.deleteStatus === 'success'
    ) {
      this.props.resetAddNotification();
      toast({
        type: 'success',
        title: T('Success'),
        description: T(DELETE_SUCCESS),
        time: 5000,
      });
    } else if (
      prevProps.deleteStatus !== 'error' &&
      this.props.deleteStatus === 'error'
    ) {
      this.props.resetAddNotification();
      const errorObj = getErrorMessage(
        this.props.error,
        'Failed to delete notification'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  onConfirmDelete() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    const notificationId = this.state.deleteNotificationId;
    this.props.deleteNotification({ notificationId, authKey, ws_id });
    this.setState({ openDeleteConfirmation: false });
  }

  onDeleteClick = (e, notificationId) => {
    e.stopPropagation();
    this.setState({
      openDeleteConfirmation: true,
      deleteNotificationId: notificationId,
    });
  };

  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false, deleteNotificationId: '' });
  }

  onSendNow(sendNow) {
    // if message is to be sent immediately, and send status is success,
    // reset the list of messages not yet sent
    if (sendNow) {
      this.setState({ update: true });
      this.startTimer();
    }
  }

  getDirection(dirn) {
    if (dirn === 'asc') {
      return 'ascending';
    }
    return 'descending';
  }

  getApplicationsList = () =>
    this.props.auth.apps.map(app => ({
      text: app.ap_name,
      value: app.application_id,
    }));

  fetchNotificationsList() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    const sortByColumn = this.state.sortByColumn;
    const dirn = this.state.direction;
    this.props.fetchNotifications({
      authKey,
      ws_id,
      sortByColumn,
      dirn,
      application_id: this.props.activeWorkspace.cnc_application,
      sent: this.props.match.params.archive,
    });
  }

  startTimer() {
    if (this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    const seconds = this.state.seconds - 1;
    this.setState({
      seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
      this.setState({ update: false, seconds: TIMER_SECONDS });
      this.fetchNotificationsList();
    }
  }

  handlePaginationChange(e, { activePage }) {
    const { header } = this.props;
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    const application_id = this.props.activeWorkspace.cnc_application;

    this.props.fetchNotifications({
      authKey,
      ws_id,
      sortByColumn: header.sort,
      dirn: null,
      start: activePage,
      searchterm: header.searchterm,
      application_id,
      sent: this.props.match.params.archive,
    });
  }

  sortVenuesByColumnName(columnName) {
    const { direction, sortByColumn } = this.state;
    const { header } = this.props;
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    let modifiedDirn = direction === 'asc' ? 'desc' : 'asc';
    if (sortByColumn === columnName) {
      this.setState({ direction: modifiedDirn });
    } else {
      modifiedDirn = 'asc';
      this.setState({ sortByColumn: columnName, direction: modifiedDirn });
    }
    this.props.fetchNotifications({
      authKey,
      ws_id,
      sortByColumn: columnName,
      dirn: modifiedDirn,
      start: header.current_page,
      searchterm: header.searchterm,
      application_id: this.props.activeWorkspace.cnc_application,
      sent: this.props.match.params.archive,
    });
  }

  toggleTableCellSize(id) {
    this.setState(({ expandedNotificationId }) =>
      expandedNotificationId === id
        ? { expandedNotificationId: -1 }
        : { expandedNotificationId: id }
    );
  }

  renderNotifications(notificationList) {
    const column = this.state.sortByColumn;
    const dirn = this.state.direction;
    const { header } = this.props;
    const result = (
      <div>
        <Table celled padded sortable stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  column === 'message_id' ? this.getDirection(dirn) : null
                }
                onClick={() => this.sortVenuesByColumnName('message_id')}
                width="1"
              >
                {T('Id')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  column === 'me_schedule_time' ? this.getDirection(dirn) : null
                }
                onClick={() => this.sortVenuesByColumnName('me_schedule_time')}
                width="2"
              >
                {T('Date and Time')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  column === 'me_subject' ? this.getDirection(dirn) : null
                }
                onClick={() => this.sortVenuesByColumnName('me_subject')}
                width="2"
              >
                {T('Subject')}
              </Table.HeaderCell>
              <Table.HeaderCell width="6">{T('Message')}</Table.HeaderCell>
              <Table.HeaderCell width="2">{T('Category')}</Table.HeaderCell>
              <Table.HeaderCell width="1">{T('Status')}</Table.HeaderCell>
              <Table.HeaderCell width="1">{T('Send Success')}</Table.HeaderCell>
              <Table.HeaderCell width="1">
                {T('Send Failured')}
              </Table.HeaderCell>
              <Table.HeaderCell width="1">{T('Sends Total')}</Table.HeaderCell>
              <Table.HeaderCell width="2" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {notificationList.map(notification => {
              let scheduledDate = momentTimezone
                .tz(notification.me_schedule_time, timezone)
                .format();
              //scheduledDate = moment(scheduledDate, 'ddd, DDD MMM YYYY HH:mm').format('ddd, DDD MMM YYYY HH:mm');
              scheduledDate = moment(scheduledDate).format('l HH:mm');
              const columnItems = [];
              const messageStatus = notification.me_send_time
                ? T('Delivered')
                : T('Scheduled');
              const total = notification.me_send_attempts;
              const success = notification.me_send_successes;
              const failure = notification.me_send_failure;
              //const failure = total > 0 ? total - success : total;

              columnItems.push(notification.message_id);
              columnItems.push(scheduledDate);
              columnItems.push(notification.me_subject);
              columnItems.push(
                <MessageToggler
                  message={notification.me_message}
                  messageLength={notification.me_message.length}
                  maxLength={50}
                  isLess
                />
              );
              columnItems.push(notification.mc_name);
              columnItems.push(messageStatus);
              columnItems.push(success);
              columnItems.push(failure);
              columnItems.push(total);
              columnItems.push(
                <Icon
                  name="trash alternate"
                  size="large"
                  color="red"
                  corner="bottom left"
                  key={`button-${notification.message_id}`}
                  onClick={e => this.onDeleteClick(e, notification.message_id)}
                />
              );
              return notification.me_send_time ? (
                <ModalWindow
                  key={`modal-${notification.message_id}`}
                  modalSize="tiny"
                  header={T('Make a Copy')}
                  tableRowTrigger={columnItems}
                >
                  <ModalContext.Consumer>
                    {({ closeModal }) => (
                      <EditNotification
                        key={`copyNot-${notification.message_id}`}
                        closeModal={closeModal}
                        notificationId={notification.message_id}
                        notification={notification}
                        applications={this.props.auth.apps}
                        sendAgain
                        sendNow={this.onSendNow.bind(this)}
                        popupParams={POPUP_PARAMS}
                      />
                    )}
                  </ModalContext.Consumer>
                </ModalWindow>
              ) : (
                <ModalWindow
                  key={`modal-${notification.message_id}`}
                  modalSize="tiny"
                  header={T('Edit Notification')}
                  tableRowTrigger={columnItems}
                >
                  <ModalContext.Consumer>
                    {({ closeModal }) => (
                      <EditNotification
                        key={`editNot-${notification.message_id}`}
                        closeModal={closeModal}
                        notificationId={notification.message_id}
                        notification={notification}
                        applications={this.props.auth.apps}
                        sendNow={this.onSendNow.bind(this)}
                        popupParams={POPUP_PARAMS}
                      />
                    )}
                  </ModalContext.Consumer>
                </ModalWindow>
              );
            })}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="10" textAlign="left">
                <ModalWindow
                  buttonColor="blue"
                  buttonSize="small"
                  modalSize="tiny"
                  buttonLabel={T('Add Notification')}
                  header={T('Add Notification')}
                >
                  <ModalContext.Consumer>
                    {({ closeModal }) => (
                      <AddNotification
                        closeModal={closeModal}
                        applications={this.props.auth.apps}
                        appId={this.props.activeWorkspace.cnc_application}
                        sendNow={this.onSendNow.bind(this)}
                        popupParams={POPUP_PARAMS}
                      />
                    )}
                  </ModalContext.Consumer>
                </ModalWindow>
                {header && (
                  <Menu floated="right" pagination>
                    <Pagination
                      onPageChange={this.handlePaginationChange}
                      defaultActivePage={header.current_page}
                      ellipsisItem={{
                        content: <Icon name="ellipsis horizontal" />,
                        icon: true,
                      }}
                      firstItem={{
                        content: <Icon name="angle double left" />,
                        icon: true,
                      }}
                      lastItem={{
                        content: <Icon name="angle double right" />,
                        icon: true,
                      }}
                      prevItem={{
                        content: <Icon name="angle left" />,
                        icon: true,
                      }}
                      nextItem={{
                        content: <Icon name="angle right" />,
                        icon: true,
                      }}
                      totalPages={header.total_pages}
                    />
                  </Menu>
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Confirm
          header={T('Delete notification')}
          content={T('Are you sure you want to delete the notification?')}
          confirmButton={T('Delete')}
          cancelButton={T('Cancel')}
          open={this.state.openDeleteConfirmation}
          onCancel={this.oncancelDeleteConfirmation}
          onConfirm={this.onConfirmDelete}
          size="tiny"
        />
      </div>
    );
    return result;
  }

  render() {
    if (this.props.fetchAllStatus === 'error') {
      const errorObj = getErrorMessage(this.props.error);
      return (
        <Message
          negative
          icon="exclamation triangle"
          header={T('Error')}
          content={`${T('Status')}: ${errorObj.status}, ${T('Message')}: ${T(
            errorObj.message
          )}`}
        />
      );
    } else if (
      this.props.fetchAllStatus === 'waiting' ||
      this.props.fetchAppsStatus === 'waiting' ||
      (this.state.update && this.props.addStatus === 'success') ||
      !this.props.notifications
    ) {
      return <Spinner />;
    }

    return this.renderNotifications(this.props.notifications);
  }
}

function mapStateToProps({ notifications, applications, auth, workspace }) {
  return {
    notifications: notifications.notifications,
    fetchAllStatus: notifications.operationState.fetchAll,
    fetchAllError: notifications.fetchAllError,
    fetchAppsStatus: applications.operationState.fetchAll,
    header: notifications.header,
    error: notifications.error,
    auth,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    addStatus: notifications.operationState.add,
    editStatus: notifications.operationState.edit,
    deleteStatus: notifications.operationState.delete,
  };
}
export default connect(mapStateToProps, {
  fetchNotifications,
  resetFetchNotifications,
  deleteNotification,
  resetDeleteNotification,
  fetchApplications,
  resetFetchApplications,
  resetAddNotification,
})(withRouter(ListNotification));

import React, { Component } from 'react';
import { Form, Header, Confirm, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  editProductPricelists,
  deleteProductPrice,
  resetEditProductPricelists,
} from '../../Redux/actions';
import { T, convertPrice } from '../Common/Helpers';

class ProductPricelist extends Component {
  state = { pricelists: [...(this.props.pricelists || [])], convert: true };

  onDelete = (e, { pp_value, pp_index }) => {
    this.setState({
      confirmDelete: true,
      selectedContent: pp_value,
      selectedIndex: pp_index,
    });
  };

  onDeleteCancel = () => {
    this.setState({ confirmDelete: false });
  };

  onDeleteConfirm = () => {
    const { selectedContent, selectedIndex } = this.state;
    const pricelists = this.state.pricelists.slice();
    //console.log(' before deleting ', selectedIndex, selectedContent);
    if (selectedContent) {
      //make api call
      const authKey = localStorage.getItem('x-auth-key');
      const productId = this.props.productId;
      this.props.deleteProductPrice(
        selectedContent,
        authKey,
        productId,
        this.props.ws_id
      );
      pricelists.splice(selectedIndex, 1);
    } else {
      // no need for api call
      pricelists.splice(selectedIndex, 1);
    }
    this.setState({ pricelists, confirmDelete: false });
    this.props.priceListCallback(pricelists);
  };
  onSubmitPricelist = () => {
    let product_prices = this.state.pricelists.slice();
    product_prices = _.map(product_prices, pl => ({
      ...pl,
      pp_price: parseFloat(pl.pp_price, 10),
    }));
    const productId = this.props.productId;
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    this.props.editProductPricelists(
      authKey,
      productId,
      { product_prices },
      ws_id
    );
  };

  getPricelist = () => {
    let product_prices = this.state.pricelists.slice();
    product_prices = _.map(product_prices, pl => ({
      ...pl,
      pp_price: parseFloat(pl.pp_price, 10),
    }));
    const productId = this.props.productId;
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    this.props.editProductPricelists(
      authKey,
      productId,
      { product_prices },
      ws_id
    );
  };
  addMore = () => {
    //console.log('State: ', this.state);
    //console.log('Before Delete State: ', this.state);
    const pricelists = this.state.pricelists.slice();
    pricelists.push({
      pp_currency: null,
      pp_price: null,
      pp_price_list: null,
      product_price_id: null,
      pp_active: true,
    });
    this.setState({ pricelists });
  };

  handlePriceChange = (e, { value, id, pp_type }) => {
    // console.log('handlePriceChange');
    const pricelists = this.state.pricelists.slice();
    let listprice;
    if (this.state.pricelists[id]) {
      let listpriceUpdated;
      listprice = this.state.pricelists[id];
      // console.log('listprice', listprice);
      // console.log('pp_type', pp_type, 'alue', value);
      switch (pp_type) {
        case 'currency':
          listpriceUpdated = {
            ...listprice,
            pp_currency: value,
            pp_currencyErrorStyle: {},
          };
          break;
        case 'price':
          //   let parsedValue = value;
          // //
          //     parsedValue = parseFloat(value, 10);
          //   if (value !== '' && Object.is(parsedValue, NaN)) { //NaN
          //     parsedValue = listprice.pp_price;
          //   }
          listpriceUpdated = { ...listprice, pp_price: value };
          // console.log('Price After: ', parsedValue);

          break;
        case 'pricelist':
          listpriceUpdated = {
            ...listprice,
            pp_price_list: value,
            pp_price_listErrorStyle: {},
          };
          break;
        case 'active':
          //console.log('checked: ', value, '\nName: ', pp_type);
          listpriceUpdated = { ...listprice, pp_active: value };
          break;
        default:
          listpriceUpdated = { ...listprice, pp_price: value };
      }
      pricelists[id] = listpriceUpdated;
      this.setState({ pricelists, convert: false });
      this.props.priceListCallback(pricelists);
    }
  };

  renderPrices = pricelist =>
    _.map(pricelist, (plist, index) => {
      const pp_price_list = plist.pp_price_list
        ? parseInt(plist.pp_price_list, 10)
        : null;
      //const price = this.state.convert ? convertPrice(plist.pp_currency, plist.pp_price) : plist.pp_price;
      const price = this.state.convert
        ? convertPrice(plist.pp_currency, plist.pp_price)
        : undefined;
      //const price = plist.pp_price;
      //this.setState({ listing: false });
      //}
      return (
        <Form.Group key={index} style={{ lineHeight: 0 }}>
          <Form.Field required width={4}>
            <Form.Dropdown
              search
              required
              id={index.toString()}
              name="pp_price_list"
              pp_type="pricelist"
              fluid
              selection
              clearable
              selectOnNavigation
              noResultsMessage={T('No result')}
              onChange={this.handlePriceChange.bind(this)}
              options={this.props.plOptions}
              value={pp_price_list}
              style={plist.pp_price_listErrorStyle || {}}
            />
          </Form.Field>
          <Form.Field required width={2}>
            <Form.Dropdown
              search
              required
              id={index.toString()}
              pp_type="currency"
              name="pp_currency"
              fluid
              selection
              clearable
              selectOnNavigation
              noResultsMessage={T('No result')}
              onChange={this.handlePriceChange.bind(this)}
              options={this.props.currencyList}
              value={plist.pp_currency}
              style={plist.pp_currencyErrorStyle || {}}
            />
          </Form.Field>
          <Form.Input
            required
            width={2}
            id={index.toString()}
            pp_type="price"
            name="pl_name"
            //value={plist.pp_price != null ? plist.pp_price : null}
            value={price}
            type="number"
            step={0.5}
            onChange={this.handlePriceChange.bind(this)}
          />
          <Form.Field>
            <Form.Checkbox
              toggle
              width={2}
              // pp_type='active'
              // id={index.toString()}
              // label={T('Active')}
              checked={plist.pp_active}
              name="pp_active"
              onChange={(e, { checked }) =>
                this.handlePriceChange(e, {
                  value: checked,
                  id: index.toString(),
                  pp_type: 'active',
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <Icon
              name="trash alternate"
              size="big"
              color="red"
              corner="bottom left"
              pp_value={plist.product_price_id}
              pp_index={index}
              onClick={this.onDelete.bind(this)}
            />
          </Form.Field>
        </Form.Group>
      );
    });
  ////<Button color='red' icon='trash' type='button' pp_value={plist.product_price_id} pp_index={index} content={T('Delete')} onClick={this.onDelete.bind(this)} />
  renderPrice = () => (
    <div>
      <Form.Group>
        <Form.Field width={4}>
          <Header sub>{T('Price List')}</Header>
        </Form.Field>
        <Form.Field width={2}>
          <Header sub>{T('Currency')}</Header>
        </Form.Field>
        <Form.Field width={2}>
          <Header sub>{T('Price')}</Header>
        </Form.Field>
        <Form.Field width={2}>
          <Header sub>{T('Active')}</Header>
        </Form.Field>
        <Form.Field>
          <Header sub />
        </Form.Field>
      </Form.Group>
      {this.renderPrices(this.state.pricelists)}
      <Form.Group>
        <Icon name="add" size="large" onClick={this.addMore.bind(this)} />
      </Form.Group>
      <Confirm
        header={T('Delete price')}
        content={T('Are you sure you want to delete the selected price?')}
        cancelButton={T('Cancel')}
        confirmButton={T('Delete')}
        open={this.state.confirmDelete}
        onCancel={this.onDeleteCancel}
        onConfirm={this.onDeleteConfirm}
        size="tiny"
      />
    </div>
  );
  //  <Button type='button' content={T('Add more')} onClick={this.addMore.bind(this)} />
  //<Button content={T('Save')} color="green" onClick={this.onSubmitPricelist} />
  render() {
    return this.renderPrice();
  }
}

function mapStateToProps({ workspace }) {
  return { ws_id: workspace.ws_id };
}

export default connect(mapStateToProps, {
  editProductPricelists,
  deleteProductPrice,
  resetEditProductPricelists,
})(ProductPricelist);

export const MODIFY_CONFIG_WAITING = "modify_config_waiting";
export const MODIFY_CONFIG_SUCCESS = "modify_config_success";
export const MODIFY_CONFIG_ERROR = "modify_config_error";
export const MODIFY_CONFIG_RESET = "modify_configs_reset";

export const MODIFY_CONFIG_LOGO_WAITING = "modify_config_logo_waiting";
export const MODIFY_CONFIG_LOGO_SUCCESS = "modify_config_logo_success";
export const MODIFY_CONFIG_LOGO_ERROR = "modify_config_logo_error";

export const MODIFY_CONFIG_BRAND_COLOR_1_WAITING =
  "modify_config_brand_color_1_waiting";
export const MODIFY_CONFIG_BRAND_COLOR_1_SUCCESS =
  "modify_config_brand_color_1_success";
export const MODIFY_CONFIG_BRAND_COLOR_1_ERROR =
  "modify_config_brand_color_1_error";

export const MODIFY_CONFIG_BRAND_COLOR_2_WAITING =
  "modify_config_brand_color_2_waiting";
export const MODIFY_CONFIG_BRAND_COLOR_2_SUCCESS =
  "modify_config_brand_color_2_success";
export const MODIFY_CONFIG_BRAND_COLOR_2_ERROR =
  "modify_config_brand_color_2_error";

export const MODIFY_CONFIG_BRAND_COLOR_3_WAITING =
  "modify_config_brand_color_3_waiting";
export const MODIFY_CONFIG_BRAND_COLOR_3_SUCCESS =
  "modify_config_brand_color_3_success";
export const MODIFY_CONFIG_BRAND_COLOR_3_ERROR =
  "modify_config_brand_color_3_error";

export const MODIFY_CONFIG_TEMPLATE_WAITING = "modify_config_template_waiting";
export const MODIFY_CONFIG_TEMPLATE_SUCCESS = "modify_config_template_success";
export const MODIFY_CONFIG_TEMPLATE_ERROR = "modify_config_template_error";

export const MODIFY_APPLICATION_CONFIG_WAITING = "modify_config_waiting";
export const MODIFY_APPLICATION_CONFIG_SUCCESS = "modify_config_success";
export const MODIFY_APPLICATION_CONFIG_ERROR = "modify_config_error";

export const FETCH_APPLICATION_CONFIG_WAITING =
  "fetch_application_config_waiting";
export const FETCH_APPLICATION_CONFIG_SUCCESS =
  "fetch_application_config_success";
export const FETCH_APPLICATION_CONFIG_ERROR = "fetch_application_config_error";
export const FETCH_APPLICATION_CONFIG_RESET = "fetch_application_config_reset";

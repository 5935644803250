import {
  PREVIEW_LOTTERY_WAITING,
  PREVIEW_LOTTERY_SUCCESS,
  PREVIEW_LOTTERY_ERROR,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  status: 'init',
  data: [],
};

export default function lotteryPreviewReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case PREVIEW_LOTTERY_WAITING:
      return { ...state, status: 'waiting' };
    case PREVIEW_LOTTERY_SUCCESS:
      return { ...state, status: 'success', data: action.payload.data };
    case PREVIEW_LOTTERY_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    default:
      return state;
  }
}

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Nav from "../layout/Nav";

import MembershipCardList from "../components/Membership/MembershipCardList";
import MemberCardList from "../components/Membership/MemberCardList";
import MembershipCardEdit from "../components/Membership/MembershipCardEdit";
import MembershipCardAdd from "../components/Membership/MembershipCardAdd";
import MemberCardEdit from "../components/Membership/MemberCardEdit";

const MembershipRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: "150px" }}>
      <Nav />
      <Switch>
        <Route path={`${path}/list`} exact>
          <MembershipCardList />
        </Route>
        <Route path={`${path}/add`} exact>
          <MembershipCardAdd />
        </Route>
        <Route path={`${path}/edit/:card_instance_id`} exact>
          <MembershipCardEdit />
        </Route>
        <Route path={`${path}/membercard/list`} exact>
          <MemberCardList />
        </Route>
        <Route path={`${path}/membercard/edit/:card_instance_id`} exact>
          <MemberCardEdit />
        </Route>
      </Switch>
    </Container>
  );
};

export default MembershipRoute;

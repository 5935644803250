import _ from 'lodash';
import { T } from '../Common/Helpers';

export const listCategoryTypes = categoryTypes => {
  if (categoryTypes) {
    return _.map(categoryTypes, item => ({
      key: item.mc_type,
      text: T(item.mc_text),
      value: item.mc_type,
    }));
  }
  return [];
};

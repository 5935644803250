import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { T } from "./Helpers";

export const Spinner = (props) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      position: "relative",
      minHeight: props.width || "400px",
      minWidth: props.height || "400px",
      width: "intherit",
      height: "inherit",
    }}
  >
    {/*}<div className="loading" />{*/}
    <Dimmer inverted active>
      <Loader content={T("Loading")} />
    </Dimmer>
  </div>
);

import React, { Component } from 'react';
import { Image, Button, Grid, Header, Form, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { resetPassword } from '../../Redux/actions';
import { withRouter } from 'react-router';
import { Spinner } from '../Common/Spinner';
import { T, getErrorMessage } from '../Common/Helpers';
import logo from '../../layout/images/keino_500x160.png';
//import logo from '../../layout/images/keino_500x160.png';
import '../../Style/App.css';

const RESET_HEADER = 'Reset Your Password';
const CREATE_HEADER = 'Set a Password';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      formErrors: { password: null, confirmPassword: null },
      passwordValid: false,
      confirmPasswordValid: false,
      passwordEqual: false,
      confirmPasswordEqual: false,
    };
    this.handleChange = this.handleInputChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.resetMessage.status !== 'success' &&
      this.props.resetMessage.status === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Password changed successfully'),
        time: 2500,
      });
      setTimeout(() => {
        this.props.history.push('/auth/login');
      }, 2500);
    } else if (
      prevProps.resetMessage.status !== 'error' &&
      this.props.resetMessage.status === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.resetMessage.error,
        'Request to change password failed'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  onValidSubmit(event) {
    let path = this.props.location.pathname;
    event.preventDefault();
    if (event.target.password.value !== event.target.confirmPassword.value) {
      toast({
        type: 'error',
        title: T('Error'),
        description: T('Passwords do not match'),
        time: 5000,
      });
      this.setState({ errors: { password1: true } });
      return;
    }
    if (this.props.match.params.resettype === 'confirm') {
      path = path.replace('/confirm', '');
    }
    this.props.resetPassword({
      password: event.target.password.value,
      path: path,
    });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;
    let passwordValid = this.state.passwordValid;
    let confirmPasswordValid = this.state.confirmPasswordValid;
    let confirmPasswordEqual = this.state.confirmPasswordEqual;
    let passwordEqual = this.state.passwordEqual;
    switch (fieldName) {
      case 'password':
        passwordValid = value.length >= 6;
        if (value.length >= 6 && this.state.confirmPassword.length >= 6) {
          passwordEqual = value === this.state.confirmPassword;
          fieldValidationErrors.password = passwordEqual
            ? ''
            : ' is too short or does not match';
          fieldValidationErrors.confirmPassword = passwordEqual
            ? ''
            : ' is too short or does not match';
        } else {
          fieldValidationErrors.password = passwordValid ? '' : ' is too short';
        }
        break;
      case 'confirmPassword':
        confirmPasswordValid = value.length >= 6;
        if (value.length >= 6 && this.state.password.length >= 6) {
          confirmPasswordEqual = value === this.state.password;
          fieldValidationErrors.password = confirmPasswordEqual
            ? ''
            : ' is too short or does not match';
          fieldValidationErrors.confirmPassword = confirmPasswordEqual
            ? ''
            : ' is too short or does not match';
        } else {
          fieldValidationErrors.confirmPassword = confirmPasswordValid
            ? ''
            : ' is too short';
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        passwordValid,
        confirmPasswordValid,
        confirmPasswordEqual,
      },
      this.validateForm
    );
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  errorClass(error) {
    return error.length === 0 ? 'loginInput' : 'inputError';
  }

  setPasswordText() {
    const text = T('setPasswordText');
    const textArray = text.split('|');
    return textArray.map(line => <div key={line}>{line}</div>);
  }

  render() {
    const isConfirm = this.props.match.params.resettype ? true : false;
    const headerText = isConfirm ? CREATE_HEADER : RESET_HEADER;

    if (this.props.resetMessage.status === 'waiting') {
      return <Spinner />;
    }
    return (
      <Grid
        textAlign="center"
        style={{ height: '100vh' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Image size="small" src={logo} />
          <Segment stacked>
            <Header as="h3" textAlign="center">
              {T(headerText)}
            </Header>
            {isConfirm && (
              <Header as="h3" className="ui header" textAlign="left">
                {this.setPasswordText()}
              </Header>
            )}
            <Form
              size="large"
              onSubmit={this.onValidSubmit.bind(this)}
              ref={ref => (this.form = ref)}
            >
              <Form.Input
                fluid
                minLength="6"
                required
                icon="lock"
                placeholder={T('Password')}
                name="password"
                type="password"
                onChange={this.handleChange}
                error={this.state.formErrors.password}
              />
              <Form.Input
                fluid
                minLength="6"
                required
                icon="lock"
                name="confirmPassword"
                type="password"
                placeholder={T('Retype password')}
                onChange={this.handleChange}
                error={this.state.formErrors.confirmPassword}
              />
              <Button
                primary
                fluid
                id="sendButton"
                icon="send"
                content={T('RESET PASSWORD')}
              />
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return { resetMessage: state.reset };
}

export default connect(mapStateToProps, { resetPassword })(
  withRouter(ResetPassword)
);

import axios from 'axios';
import config from '../../Config/AEConfig';
import {
  FETCH_APPLICATIONS_SUCCESS,
  FETCH_APPLICATIONS_WAITING,
  FETCH_APPLICATIONS_ERROR,
  FETCH_APPLICATIONS_RESET,
  EDIT_APPLICATION_WAITING,
  EDIT_APPLICATION_SUCCESS,
  EDIT_APPLICATION_ERROR,
  FETCH_APPLICATIONS_CONTEXTS_WAITING,
  FETCH_APPLICATIONS_CONTEXTS_SUCCESS,
  FETCH_APPLICATIONS_CONTEXTS_ERROR,
  SET_SELECTED_APPLICATION_SUCCESS,
  FETCH_APPLICATION_WAITING,
  FETCH_APPLICATION_SUCCESS,
  FETCH_APPLICATION_ERROR,
  ADD_SHOP_TO_APPLICATION_WAITING,
  ADD_SHOP_TO_APPLICATION_SUCCESS,
  ADD_SHOP_TO_APPLICATION_ERROR,
  ADD_SHOP_TO_APPLICATION_RESET,
  REMOVE_SHOP_FROM_APPLICATION_WAITING,
  REMOVE_SHOP_FROM_APPLICATION_SUCCESS,
  REMOVE_SHOP_FROM_APPLICATION_ERROR,
  REMOVE_SHOP_FROM_APPLICATION_RESET,
} from '../Types';
import ErrorAction from './ErrorAction';

const MODULE_ID = 13;
const APP_MODULE_ID = 15;

export const fetchApplications = (authKey, ws_id) => dispatch => {
  dispatch({ type: FETCH_APPLICATIONS_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  const url = `${config.BASE_URL}/applications?module_id=${MODULE_ID}&ws_id=${ws_id}`;
  axios
    .get(url)
    .then(response => {
      dispatch({ type: FETCH_APPLICATIONS_SUCCESS, payload: response });
    })
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_APPLICATIONS_ERROR)
        : FETCH_APPLICATIONS_ERROR;
      dispatch({ type, payload: error });
    });
};

export const fetchContexts = (authKey, ws_id) => dispatch => {
  dispatch({ type: FETCH_APPLICATIONS_CONTEXTS_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  const url = `${config.BASE_URL}/application/contexts?ws_id=${ws_id}`;
  axios
    .get(url)
    .then(response => {
      dispatch({
        type: FETCH_APPLICATIONS_CONTEXTS_SUCCESS,
        payload: response,
      });
    })
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_APPLICATIONS_CONTEXTS_ERROR)
        : FETCH_APPLICATIONS_CONTEXTS_ERROR;
      dispatch({ type, payload: error });
    });
};

export const editApplication =
  (authKey, id, name, value, ws_id) => dispatch => {
    dispatch({ type: EDIT_APPLICATION_WAITING });
    const values = {
      [name]: value,
    };
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/application/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`;
    axios
      .put(url, values)
      .then(response => {
        //Todo: remove this ugly fix
        dispatch({ type: EDIT_APPLICATION_SUCCESS, payload: response });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_APPLICATION_ERROR)
          : EDIT_APPLICATION_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchApplication =
  ({ authKey, ap_key, ws_id }) =>
  dispatch => {
    dispatch({ type: FETCH_APPLICATION_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/application/${ap_key}?module_id=${APP_MODULE_ID}&ws_id=${ws_id}`;
    axios
      .get(url)
      .then(response => {
        dispatch({ type: FETCH_APPLICATION_SUCCESS, payload: response });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_APPLICATION_ERROR)
          : FETCH_APPLICATION_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const addShopToApplication =
  ({ authKey, appId, data, ws_id }) =>
  dispatch => {
    dispatch({ type: ADD_SHOP_TO_APPLICATION_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/applicationshop/${appId}?module_id=${APP_MODULE_ID}&ws_id=${ws_id}`;
    axios
      .post(url, data)
      .then(response => {
        dispatch({ type: ADD_SHOP_TO_APPLICATION_SUCCESS, payload: response });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_SHOP_TO_APPLICATION_ERROR)
          : ADD_SHOP_TO_APPLICATION_ERROR;
        dispatch({ type, payload: error });
      });
  };

//url and methods need to be updated, currently the functionality is not activated from backend
export const removeShopFromApplication =
  (authKey, appId, data, ws_id) => dispatch => {
    dispatch({ type: REMOVE_SHOP_FROM_APPLICATION_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/applicationshop/${appId}?module_id=${APP_MODULE_ID}&ws_id=${ws_id}`;
    axios
      .delete(url, data)
      .then(response => {
        dispatch({
          type: REMOVE_SHOP_FROM_APPLICATION_SUCCESS,
          payload: response,
        });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              REMOVE_SHOP_FROM_APPLICATION_ERROR
            )
          : REMOVE_SHOP_FROM_APPLICATION_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetFetchApplications = () => ({
  type: FETCH_APPLICATIONS_RESET,
});

export const resetAddShopToApplication = () => ({
  type: ADD_SHOP_TO_APPLICATION_RESET,
});

export const resetRemoveShopFromApplication = () => ({
  type: REMOVE_SHOP_FROM_APPLICATION_RESET,
});

import _ from 'lodash';
import {
  FETCH_TAXES_WAITING,
  FETCH_TAXES_SUCCESS,
  FETCH_TAXES_ERROR,
  FETCH_TAXES_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  fetchStatus: 'init',
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_TAXES_WAITING: {
      return { ...state, fetchStatus: 'waiting' };
    }
    case FETCH_TAXES_SUCCESS:
      return {
        ...state,
        data: _.mapKeys(action.payload.data.items, 'tax_class_id'),
        headers: action.payload.headers,
        fetchStatus: 'success',
      };
    case FETCH_TAXES_ERROR: {
      return { ...state, fetchStatus: 'error', error: action.payload.error };
    }
    case FETCH_TAXES_RESET: {
      return { ...INIT_STATE };
    }
    default:
      return state;
  }
}

import axios from 'axios';
import config from '../../Config/AEConfig';
import {
  FETCH_PRODUCT_ATTRIBUTES_LIST_WAITING,
  FETCH_PRODUCT_ATTRIBUTES_LIST_SUCCESS,
  FETCH_PRODUCT_ATTRIBUTES_LIST_ERROR,
  FETCH_PRODUCT_ATTRIBUTES_LIST_RESET,
  EDIT_PRODUCT_ATTRIBUTES_WAITING,
  EDIT_PRODUCT_ATTRIBUTES_SUCCESS,
  EDIT_PRODUCT_ATTRIBUTES_ERROR,
  EDIT_PRODUCT_ATTRIBUTES_RESET,
} from '../Types';
import ErrorAction from './ErrorAction';

const MODULE_ID = 19;
export const fetchProductAttributesList = (authKey, ws_id) => dispatch => {
  dispatch({ type: FETCH_PRODUCT_ATTRIBUTES_LIST_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .get(`${config.BASE_URL}/attribute/types?ws_id=${ws_id}`)
    .then(response =>
      dispatch({
        type: FETCH_PRODUCT_ATTRIBUTES_LIST_SUCCESS,
        payload: response,
      })
    )
    .catch(error => {
      const type = error.response
        ? ErrorAction(
            error.response.status,
            FETCH_PRODUCT_ATTRIBUTES_LIST_ERROR
          )
        : FETCH_PRODUCT_ATTRIBUTES_LIST_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetFetchProductAttributesList = () => ({
  type: FETCH_PRODUCT_ATTRIBUTES_LIST_RESET,
});

export const editProductAttributes =
  (authKey, productId, data, ws_id) => dispatch => {
    dispatch({ type: EDIT_PRODUCT_ATTRIBUTES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .post(
        `${config.BASE_URL}/product/${productId}/attribute?ws_id=${ws_id}&module_id=${MODULE_ID}`,
        data
      )
      .then(response =>
        dispatch({ type: EDIT_PRODUCT_ATTRIBUTES_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_PRODUCT_ATTRIBUTES_ERROR)
          : EDIT_PRODUCT_ATTRIBUTES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetEditProductAttributes = () => ({
  type: EDIT_PRODUCT_ATTRIBUTES_RESET,
});

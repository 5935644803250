import React, { Component } from "react";
import { Icon } from "semantic-ui-react";

class ModuleItem extends Component {
  onModuleChecked = (moduleId, isChecked) => {
    this.props.onSelectedModuleChange("modules", [
      { appmodule_id: moduleId, aam_active: !isChecked },
    ]);
  };
  renderCheckbox() {
    const { isChecked, moduleId } = this.props;
    return (
      <input
        onChange={() => this.onModuleChecked(moduleId, isChecked)}
        type="checkbox"
        checked={isChecked}
        id={moduleId}
        style={{
          display: "inline-flex",
          width: "23px",
          height: "23px",
          margin: "0 !important",
          padding: "0 !important",
          borderRadius: "0 !important",
        }}
      />
    );
  }
  render() {
    let { iconName } = this.props;
    iconName = !iconName ? "shop" : iconName;
    return (
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          minWidth: "300px",
          width: "50%",
          marginBottom: "50px",
        }}
      >
        <div style={{ display: "flex" }}>
          {this.renderCheckbox()}
          <p
            style={{
              width: "70px",
              height: "70px",
              justifyContent: "center",
              alignItems: "center",
              display: "inline-flex",
              border: "1px solid grey",
              margin: "0 !important",
              padding: "0 !important",
            }}
          >
            <Icon name={iconName} size="big" />
          </p>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
            }}
          >
            <p style={{ display: "block", fontWeight: 600 }}>
              {" "}
              {this.props.moduleName || "SHOP"}{" "}
            </p>
            <p style={{ display: "block", textAlign: "left" }}>
              {this.props.moduleDescription}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ModuleItem;

import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import { Message } from "semantic-ui-react";
import { withRouter } from "react-router";
import {
  confirmPersonRegistration,
  resetPersonRegistration,
} from "../../Redux/actions";
import { Spinner } from "../Common/Spinner";
import { T } from "../Common/Helpers";

class AddPersonConfirmation extends Component {
  componentDidMount() {
    const email = this.props.match.params.email;
    const url = this.props.location.search;
    const params = new URLSearchParams(url);
    const key = encodeURIComponent(params.get("key"));
    const expire = encodeURIComponent(params.get("expy"));
    if (!key) {
      toast({
        type: "error",
        title: T("Error"),
        description: T("Wrong confirmation path, please try again"),
        time: 5000,
      });
      setTimeout(() => this.props.history.push("/auth/login"), 2800);
    }
    this.props.confirmPersonRegistration(email, key, expire);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.confirmStatus !== "success" &&
      this.props.confirmStatus === "success"
    ) {
      // TODO pitäsikö olla eri route
      setTimeout(
        () =>
          this.props.history.push(
            `/auth/reset/${this.props.persons.key}/confirm`
          ),
        2500
      );
    }
  }
  render() {
    if (
      this.props.confirmStatus === "init" ||
      this.props.confirmStatus === "waiting"
    ) {
      return <Spinner />;
    } else if (this.props.confirmStatus === "error") {
      return (
        <div style={styles.containerStyle}>
          <Message
            error
            compact
            size="large"
            style={{ height: "150px" }}
            header={T("Confirmation error")}
            content={T(`${this.props.confirmStatus}`)}
          />
        </div>
      );
    } else if (this.props.confirmStatus === "success") {
      return (
        // TODO add parameter in case of regisration password form should show the header Create a password and message defined in Jira otherwise as it it right now
        <div style={styles.containerStyle}>
          <Message
            success
            compact
            size="large"
            style={{ height: "150px" }}
            header={T("Registration confirmed, redirecting to create password")}
            content={T(`${this.props.confirmStatus}`)}
          />
        </div>
      );
    }
    return null;
  }
}

const styles = {
  containerStyle: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
};

function mapStateToProps({ persons }) {
  return { confirmStatus: persons.operationState.confirmStatus, persons };
}
export default connect(mapStateToProps, {
  confirmPersonRegistration,
  resetPersonRegistration,
})(withRouter(AddPersonConfirmation));

import {
  AUTH_SUCCESS,
  AUTH_RESET,
  AUTH_ERROR,
  AUTH_WAITING,
  ERROR_UNAUTHORIZED,
  ERROR_GATEWAY_TIMEOUT,
  ERROR_FORBIDDEN,
  AUTH_CHECK_WAITING,
  AUTH_CHECK_ERROR,
  AUTH_CHECK_SUCCESS,
  SET_LANGUAGE_PROFILE,
  // FORGET_SUCCESS,
  // RESET_SUCCESS
} from '../Types';

const INIT_STATE = {
  status: 'init',
  res: null,
  loggedIn: false,
  isSuperAdmin: false,
  error: {},
  triggeredBy: '',
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_SUCCESS: {
      const lang =
        navigator.language.substring(0, 2) ||
        navigator.userLanguage.substring(0, 2);
      return {
        ...state,
        res: action.payload,
        status: 'success',
        loggedIn: true,
        isSuperAdmin: action.payload.data.isadmin === true,
        pe_public_id: action.payload.data.pe_public_id,
        triggeredBy: 'login',
        apps: action.payload.data.applications,
        companies: action.payload.data.companies,
        username: action.payload.data.pe_name,
        companyName: action.payload.data.companies[0].co_name, // temporarily taking first company
        companyId: action.payload.data.companies[0].company_id, // and it should be handled later,
        lang: action.payload.data.language
          ? action.payload.data.language.ln_alpha_2
          : lang,
      };
    }
    case AUTH_CHECK_SUCCESS: {
      const lang =
        navigator.language.substring(0, 2) ||
        navigator.userLanguage.substring(0, 2);
      return {
        ...state,
        res: action.payload,
        status: 'success',
        loggedIn: true,
        triggeredBy: 'authCheck',
        isSuperAdmin: action.payload.data.isadmin === true,
        pe_public_id: action.payload.data.pe_public_id,
        apps: action.payload.data.applications,
        companies: action.payload.data.companies,
        username: action.payload.data.pe_name,
        companyName: action.payload.data.companies[0].co_name, // temporarily taking first company
        companyId: action.payload.data.companies[0].company_id, // and it should be handled later
        lang: action.payload.data.language
          ? action.payload.data.language.ln_alpha_2
          : lang,
      };
    }
    case AUTH_WAITING:
    case AUTH_CHECK_WAITING:
      return { ...state, status: 'waiting' };
    case AUTH_ERROR:
    case AUTH_CHECK_ERROR: {
      localStorage.clear();
      return {
        status: 'error',
        res: action.payload,
        loggedIn: false,
        apps: false,
        error: action.payload.error,
      };
    }
    case SET_LANGUAGE_PROFILE:
      return { ...state, lang: action.payload };
    case AUTH_RESET:
      return { ...INIT_STATE };
    case ERROR_UNAUTHORIZED:
      return { ...INIT_STATE };
    case ERROR_GATEWAY_TIMEOUT: {
      localStorage.clear();
      return {
        res: action.payload,
        status: 'timeout',
        loggedIn: false,
        apps: false,
      };
    }
    case ERROR_FORBIDDEN:
      return { ...INIT_STATE };
    // case FORGET_SUCCESS:
    //   return { ...state,forgetMessage:action.payload.data.message};
    // case RESET_SUCCESS:
    //   return {...state,resetMessage:action.payload.data};
    default:
      return state;
  }
}

/* eslint-env browser */
import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  FETCH_COUNTRY_WAITING,
  FETCH_COUNTRY_SUCCESS,
  FETCH_COUNTRY_ERROR,
  // ERROR_GATEWAY_TIMEOUT
} from '../Types';

import ErrorAction from './ErrorAction';

// const REST_COUNTRIES_API = "https://restcountries.eu/rest/v2/all";

export const fetchCountries = (authKey, ws_id) => dispatch => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  dispatch({ type: FETCH_COUNTRY_WAITING });
  axios
    .get(`${config.BASE_URL}/config/countries?ws_id=${ws_id}`)
    .then(response => {
      dispatch({ type: FETCH_COUNTRY_SUCCESS, payload: response });
    })
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_COUNTRY_ERROR)
        : FETCH_COUNTRY_ERROR;
      dispatch({ type, payload: error });
    });
};

// export const fetchCountriesFromRestCountries = () => (dispatch) => {
//       delete axios.defaults.headers.common['x-auth-key'];
//       dispatch({ type: FETCH_COUNTRY_WAITING });
//       axios.get(`${REST_COUNTRIES_API}?fields=alpha2Code;name;flag;`)
//       .then(response => {
//         dispatch({ type: FETCH_COUNTRY_SUCCESS, payload: response });
//       }).catch(error => {
//         dispatch({ type:FETCH_COUNTRY_ERROR, payload: error });
//       });
//     };

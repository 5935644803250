import React, { Component } from 'react';
import {
  Header,
  Grid,
  Segment,
  Button,
  Table,
  Icon,
  Confirm,
  Message,
} from 'semantic-ui-react';
// import { toast } from 'react-semantic-toasts';
import moment from 'moment';
import jstz from 'jstz';
import momentTimezone from 'moment-timezone';
import _ from 'lodash';
import { connect } from 'react-redux';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  fetchPerformerCMSContents,
  resetPerformerCMSContent,
  //fetchPerformerCMSContentTypes,
  deletePerformerCMSContent,
  fetchCCDataKeyValues,
} from '../../Redux/actions';
import { T } from '../Common/Helpers';
import ModalWindow, { ModalContext } from '../Common/ModalWindow';
import PlayerCMSParentEditor from './PlayerCMSParentEditor';
import PlayerCMSContentController from './PlayerCMSContentController';
import { Spinner } from '../Common/Spinner';

const timezone = jstz.determine().name();
const CONTENT_TYPE = 'performer';

class PlayerCMSContnentList extends Component {
  constructor(props) {
    super(props);
    this.state = { openDeleteConfirmation: false, cmsId: '' };
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }
  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    //this.props.fetchPerformerCMSContent(this.props.performerId, authKey);
    //this.props.fetchPerformerCMSContentTypes(authKey, ws_id);
    this.props.fetchPerformerCMSContents(
      this.props.performerId,
      authKey,
      ws_id
    );

    if (this.props.keyValueSettings.status !== 'success') {
      this.props.fetchCCDataKeyValues(authKey);
    }
  }

  // UNSAFE_componentWillUpdate(nextProps) {
  //       const authKey = localStorage.getItem('x-auth-key');
  //       const ws_id = this.props.workspace.activeWorkspace.id;
  //       if (this.props.cmsStatus !== 'success' && nextProps.cmsStatus === 'success') {
  //         window.scroll(50, 50);
  //         toast({ type: 'success', title: T('Success'), description: T('CMS content updated successfully'), time: 5000 });
  //         this.props.fetchPerformerCMSContents(nextProps.performerId, authKey, ws_id);
  //         this.props.resetPerformerCMSContent();
  //       } else if (this.props.cmsStatus !== 'error' && nextProps.cmsStatus === 'error') {
  //         window.scroll(50, 50);
  //         toast({ type: 'error', title: T('Error'), description: T('Error has occurred'), time: 5000 });
  //         this.props.resetPerformerCMSContent();
  //       } else if (this.props.performerId !== nextProps.performerId) {
  //            this.props.fetchPerformerCMSContents(nextProps.performerId, authKey, ws_id);
  //       }
  // }

  // componentWillUnmount() {
  //     this.props.resetPerformerCMSContent();
  // }
  onCancel() {
    this.props.onResetEditAndAddPlayer();
  }
  onDeleteCMSContent(cmsId) {
    this.setState({ openDeleteConfirmation: true, cmsId });
  }
  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }
  onConfirmDelete() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    this.props.deletePerformerCMSContent(this.state.cmsId, authKey, ws_id);
    this.setState({ openDeleteConfirmation: false });
  }

  getCMSList(cmsList) {
    return _.map(cmsList, cms => {
      let validFrom;
      let validTo;
      validFrom = momentTimezone.tz(cms.cm_valid_from, timezone).format();
      validFrom = moment(validFrom).format('l HH:mm');
      if (cms.cm_valid_to) {
        validTo = momentTimezone.tz(cms.cm_valid_to, timezone).format();
        validTo = moment(validTo).format('l HH:mm');
      } else {
        validTo = T('Permanent');
      }
      return (
        <Table.Row key={cms.cms_content_id}>
          <Table.Cell>
            <ModalWindow
              header={T('Add/Edit CMS content')}
              modalSize="small"
              buttonIcon="plus"
              buttonColor="blue"
              key={`modal-${cms.cms_content_id}`}
            >
              <ModalContext.Consumer>
                {({ closeModal }) => (
                  <PlayerCMSContentController
                    closeModal={closeModal}
                    parentId={cms.cms_content_id}
                    onResetEditAndAddPlayer={this.props.onResetEditAndAddPlayer}
                  />
                )}
              </ModalContext.Consumer>
            </ModalWindow>
          </Table.Cell>
          <Table.Cell>{cms.cms_content_id}</Table.Cell>
          <Table.Cell>{cms.cm_content_type}</Table.Cell>
          <Table.Cell>{cms.cm_short_header}</Table.Cell>
          <Table.Cell>{validFrom}</Table.Cell>
          <Table.Cell>{validTo}</Table.Cell>
          <Table.Cell>
            <ModalWindow
              header={T('Edit CMS Parent')}
              modalSize="small"
              buttonColor="blue"
              buttonIcon="edit"
            >
              <ModalContext.Consumer>
                {({ closeModal }) => (
                  <PlayerCMSParentEditor
                    closeModal={closeModal}
                    playerId={this.props.performerId}
                    onResetEditAndAddPlayer={this.props.onResetEditAndAddPlayer}
                    cmsProp={cms}
                    contentId={cms.cms_content_id}
                    newCMS={false}
                    content_type={CONTENT_TYPE}
                    contenttypes={this.props.keyValueSettings.contenttypes}
                  />
                )}
              </ModalContext.Consumer>
            </ModalWindow>
          </Table.Cell>
          <Table.Cell>
            <Button
              icon="trash"
              color="red"
              onClick={() => {
                this.onDeleteCMSContent(cms.cms_content_id);
              }}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    // console.log('PLAYER CMS CONTENT');
    if (
      this.props.cmsStatus === 'waiting' ||
      this.props.typeStatus === 'waiting'
    ) {
      return (
        <div>
          <Spinner />
        </div>
      );
      // return <h3> No Data </h3>;
    } else if (
      this.props.cmsStatus === 'error' ||
      this.props.typeStatus === 'error'
    ) {
      return (
        <Message
          error
          icon="exclamation triangle"
          header={T('Error')}
          content={`${T('Error has occured, while fetching data.')} \n${
            this.props.error
          }`}
        />
      );
    }
    return (
      <div>
        <Header as="h3" content={T('Transfer Player')} />
        <Grid columns={2} stackable>
          <Grid.Column width="12">
            <Segment compact>
              <Table compact celled padded>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Icon name="plus" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>{T('CMS Type')}</Table.HeaderCell>
                    <Table.HeaderCell>{T('ID')}</Table.HeaderCell>
                    <Table.HeaderCell>{T('Title')}</Table.HeaderCell>
                    <Table.HeaderCell>{T('Valid from')}</Table.HeaderCell>
                    <Table.HeaderCell>{T('Valid to')}</Table.HeaderCell>
                    <Table.HeaderCell> {T('Edit')} </Table.HeaderCell>
                    <Table.HeaderCell>{T('Delete')}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body></Table.Body>
                <Table.Body>{this.getCMSList(this.props.cmsList)}</Table.Body>
              </Table>
              <ModalWindow
                header={T('Add CMS Parent')}
                buttonLabel={T('Add CMS content')}
                buttonColor="blue"
                modalSize="tiny"
              >
                <ModalContext.Consumer>
                  {({ closeModal }) => (
                    <PlayerCMSParentEditor
                      closeModal={closeModal}
                      playerId={this.props.performerId}
                      onResetEditAndAddPlayer={
                        this.props.onResetEditAndAddPlayer
                      }
                      cmsTypes={this.props.cmsTypes} // TODO is this needed
                      newCMS
                      contenttypes={this.props.keyValueSettings.contenttypes}
                      content_type={CONTENT_TYPE}
                    />
                  )}
                </ModalContext.Consumer>
              </ModalWindow>
              <Confirm
                header={T('Delete CMS')}
                content={T('Are you sure you want to delete the CMS?')}
                confirmButton={T('Delete')}
                open={this.state.openDeleteConfirmation}
                onCancel={this.oncancelDeleteConfirmation}
                onConfirm={this.onConfirmDelete}
                size="tiny"
              />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps({ performerPlayers, workspace, keyValueSettings }) {
  return {
    // cms: performerPlayers.cms,
    cmsStatus: performerPlayers.cmsFetchStatus,
    cmsTypes: performerPlayers.cmsTypes,
    typeStatus: performerPlayers.cmsTypesStatus,
    cmsList: performerPlayers.cmsList,
    error: performerPlayers.error,
    workspace,
    keyValueSettings,
  };
}
export default connect(mapStateToProps, {
  fetchPerformerCMSContents,
  resetPerformerCMSContent,
  deletePerformerCMSContent,
  fetchCCDataKeyValues,
})(PlayerCMSContnentList);

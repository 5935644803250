import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import moment from 'moment';
import EventEdit from './EventEdit';
import {
  fetchEvent,
  setLastActiveEventId,
  fetchSeasons,
} from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import { T } from '../Common/Helpers';

class EventEditContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      seasonCheckDone: false,
    };
  }

  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const { eventId } = this.props.match.params;
    if (eventId) {
      this.props.setLastActiveEventId(eventId);
      this.props.fetchEvent(
        authKey,
        this.props.match.params.eventId,
        this.props.ws_id
      );
    } else {
      // if no season selected, fetch all
      // console.log(this.props);
      const { cnc_event } = this.props.activeWorkspace;
      this.props.fetchSeasons(authKey, cnc_event, this.props.ws_id);
    }
  }

  componentDidUpdate(prevProps) {
    const authKey = localStorage.getItem('x-auth-key');
    const { eventId } = this.props.match.params;
    if (
      eventId &&
      prevProps.match.params.eventId !== this.props.match.params.eventId
    ) {
      this.props.setLastActiveEventId(eventId);
      this.props.fetchEvent(authKey, eventId, this.props.ws_id);
    }
    if (!eventId) {
      const { cnc_event } = this.props.activeWorkspace;
      const prevParent = prevProps.parents[cnc_event];
      const parent = this.props.parents[cnc_event];
      if (
        (!prevParent || prevParent.statusSeasons !== 'success') &&
        parent &&
        parent.statusSeasons === 'success'
      ) {
        // is the parent a season itself?
        const parentItself = this.props.parents.seasons[cnc_event];
        if (parentItself && parentItself.ev_type === 'season') {
          // yes! let's redirect
          this.setState({ redirect: `/app/events/season/edit/${cnc_event}` });
        } else if (!this.selectDefaultSeasonIFPossible(parent)) {
          this.setState({ seasonCheckDone: true });
        }
      }
    }
  }

  selectDefaultSeasonIFPossible(parent) {
    let eventId = null;
    // console.log('trying to determine default season props', this.props);
    // season fetch finished, let's see if we find a current one
    for (const [seasonId, season] of Object.entries(parent)) {
      // console.log('trying to determine default season', { seasonId, season });
      if (season.event_id) {
        // make sure it's a valid event object
        const { ev_event_start, ev_event_stop } = season;

        // specail case if  season is always running
        if (!ev_event_start && !ev_event_stop) {
          eventId = seasonId;
          break;
        }

        // otherwise check dates
        const evStart = ev_event_start ? moment(ev_event_start) : null;
        const evStop = ev_event_stop ? moment(ev_event_stop) : null;
        const now = moment();

        if (
          (evStart === null || evStart.isSameOrBefore(now)) &&
          (evStop === null || evStop.isAfter(now))
        ) {
          eventId = seasonId;
          break;
        }
      }
    }
    // found a suitable season, let's select by default
    if (eventId) {
      this.setState({ redirect: `/app/events/season/edit/${eventId}` });
      return true;
    }
    return false;
  }
  render() {
    const { eventId } = this.props.match.params;
    const { fetchStatus } = this.props.events;
    if (!eventId && this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    if (!eventId && !this.state.seasonCheckDone) {
      return <Spinner />;
    }

    if (!eventId || fetchStatus === 'error') {
      // console.log('No event Id!');
      return (
        <Message info>
          <Message.Header>{T('Welcome to Events home page')}</Message.Header>
          <p>{T('please choose an item from the menu')}</p>
        </Message>
      );
    }

    if (fetchStatus !== 'success') {
      return <Spinner />;
    }

    return (
      <EventEdit
        {...this.props}
        event={this.props.events.event}
        categories={this.props.eventcategories.eventcategories}
        eventId={this.props.events.event.event_id}
        setSelectedEvent={this.props.setSelectedEvent}
        series={this.props.parents.seriesList}
        ws_id={this.props.ws_id}
        history={this.props.history}
      />
    );
  }
}

function mapStateToProps({ workspace, eventcategories, parents, events }) {
  const ws_id = workspace.activeWorkspace.id;
  return {
    ws_id,
    activeWorkspace: workspace.workspaces[ws_id],
    eventcategories,
    parents,
    events,
  };
}

export default connect(mapStateToProps, {
  fetchEvent,
  setLastActiveEventId,
  fetchSeasons,
})(withRouter(EventEditContainer));

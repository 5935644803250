import React, { Component } from 'react';

class EventSideMenu extends Component {
  constructor(props) {
    super(props);
    this.linkToEditPage = this.linkToEditPage.bind(this);
  }

  linkToEditPage() {
    const eventType = this.props.eventType || this.props.menuItem.ev_type;
    if (eventType !== 'defaultSeasons' && eventType !== 'series') {
      this.props.closeModal();
      this.props.history.push(`/app/events/season/edit/${this.props.eventId}`);
    }
  }

  render() {
    return (
      <span
        onClick={this.linkToEditPage}
        style={{ cursor: 'pointer', marginLeft: '10px' }}
      >
        {this.props.menuItem.ev_name}
      </span>
    );
  }
}

export default EventSideMenu;

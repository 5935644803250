/**
devMode: local, development , production
default: local
**/
// export const devMode = 'local';
// export const local_port = 8100;
// export const devMode = 'development';
// export const devMode = 'pannu';
export const devMode = 'production';

export const local_port = 5002;
const version = 'api/v0.0.1';

function AEConfig() {
  let config;
  switch (devMode) {
    case 'local': {
      config = {
        BASE_URL: `http://localhost:${local_port}/${version}`,
        IMAGE_BASE_URL:
          'https://engage-assets-production.s3-eu-west-1.amazonaws.com/',
        WS_BASE_URL: 'ws://localhost:8080/',
        WS2_BASE_URL: 'ws://localhost:8080/',
        TITLEBAR_TEXT: 'localhost',
        TITLEBAR_COLOR: 'gray',
      };
      break;
    }
    case 'development': {
      config = {
        BASE_URL: `https://dev.engage.fi/clientconsoleapi/${version}`,
        IMAGE_BASE_URL:
          'https://engage-assets-production.s3-eu-west-1.amazonaws.com/',
        WS_BASE_URL: 'wss://dev.engage.fi/',
        WS2_BASE_URL: 'wss://dev.engage.fi/',
        TITLEBAR_TEXT: 'dev.engage.fi',
        TITLEBAR_COLOR: 'red',
      };
      break;
    }
    case 'production': {
      config = {
        BASE_URL: `https://api.engage.fi/clientconsoleapi/${version}`,
        IMAGE_BASE_URL:
          'https://engage-assets-production.s3-eu-west-1.amazonaws.com/',
        WS_BASE_URL: 'wss://api.engage.fi/',
        //WS2_BASE_URL: 'wss://api2.engage.fi/'
        WS2_BASE_URL: 'wss://api.engage.fi/',
        TITLEBAR_TEXT: 'Keino Console',
        TITLEBAR_COLOR: 'black',
      };
      break;
    }
    case 'staging': {
      config = {
        BASE_URL: `https://staging.engage.fi:1443/clientconsoleapi/${version}`,
        IMAGE_BASE_URL:
          'https://engage-assets-production.s3-eu-west-1.amazonaws.com/',
        WS_BASE_URL: 'wss://staging.engage.fi:1443/',
        WS2_BASE_URL: 'wss://staging.engage.fi/',
        TITLEBAR_TEXT: 'staging.engage.fi',
        TITLEBAR_COLOR: 'purple',
      };
      break;
    }
    case 'pannu': {
      config = {
        BASE_URL: `https://cc.vsiservice.net/clientconsoleapi/${version}`,
        IMAGE_BASE_URL:
          'https://engage-assets-production.s3-eu-west-1.amazonaws.com/',
        WS_BASE_URL: 'wss://api.vsiservice.net/',
        WS2_BASE_URL: 'wss://api.vsiservice.net/',
        TITLEBAR_TEXT: 'api.vsiservice.net',
        TITLEBAR_COLOR: 'purple',
      };
      break;
    }
    default:
      config = {
        BASE_URL: `http://localhost:${local_port}/${version}`,
        IMAGE_BASE_URL: 'https://localhost:${local_port}/images/',
        WS_BASE_URL: 'ws://localhost:8080/',
        WS2_BASE_URL: 'ws://localhost:8080/',
        TITLEBAR_TEXT: 'localhost',
        TITLEBAR_COLOR: 'gray',
      };
  }
  return config;
}

export default AEConfig();

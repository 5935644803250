import React, { Component } from 'react';
import { Grid, Segment, Header } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { connect } from 'react-redux';
import {
  uploadPlayerTranferImage,
  resetUploadPlayerTranferImage,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';
import defaultImage from '../../layout/images/images.png';
import ImageCrop from './ImageCrop';

class PlayerTranferImage extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.uploadTransferImage = this.uploadTransferImage.bind(this);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    // console.log('TRANSFER IMage: ', nextProps);
    if (
      this.props.transferImage !== 'success' &&
      nextProps.transferImage === 'success'
    ) {
      window.scroll(20, 20);
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Player Transfer Image successfully updated'),
        time: 5000,
      });
      this.props.onResetEditAndAddPlayer();
      this.props.resetUploadPlayerTranferImage();
      this.props.setTranferConnectionId(null);
      // } else if (this.props.editTransferStatus !== 'success' &&
      //  nextProps.editTransferStatus === 'success') {
      //    window.scroll(20, 20);
      //    Alert.success(T('Player Transfer connection successfully updated'), {
      //      position: 'top',
      //      timeout: 1500
      //    });
      //      this.props.onResetEditAndAddPlayer();
      //      this.props.resetEditTransferConnection();
      //      const authKey = localStorage.getItem('x-auth-key');
      //        this.props.fetchPerformerPlayers(authKey,
      //                      nextProps.currentTeamId,
      //                      nextProps.headers.current_page);
    } else if (
      this.props.transferImage !== 'error' &&
      nextProps.transferImage === 'error'
    ) {
      const errorObj = getErrorMessage(
        nextProps.error,
        'Error in uploading transfer image'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.onResetEditAndAddPlayer();
      this.props.resetUploadPlayerTranferImage();
      this.props.setTranferConnectionId(null);
    }
  }
  onCancel() {
    //this.props.onResetEditAndAddPlayer();
    this.props.closemodal();
  }
  getPlayerImage(player) {
    const metadata = player.performer_metadata;
    let imageSrc = '';
    if (metadata && metadata.length > 0) {
      const pictMeta = metadata.filter(meta => meta.pm_key === 'imageurl');
      if (pictMeta.length !== 0) {
        // console.log('meta', pictMeta[0].pm_value);
        imageSrc = pictMeta[0].pm_value;
      }
    }
    return imageSrc || defaultImage;
  }
  uploadTransferImage(inputValues, authKey) {
    this.props.uploadPlayerTranferImage(inputValues, authKey);
  }
  render() {
    // console.log('TRANFER CONTROL PROPS: ', this.props);
    if (!this.props.teams || !this.props.player) {
      return <Spinner />;
    }
    return (
      <div>
        <Grid columns="1" stackable>
          <Grid.Column width="16">
            <Segment textAlign="center">
              <Header
                as="h4"
                color="blue"
                content={this.props.player.pf_name}
              />
              <ImageCrop
                width="80%"
                performerId={this.props.performerId}
                onResetEditAndAddPlayer={this.onCancel}
                playerImg={this.getPlayerImage(this.props.player)}
                uploadPerformerImage={this.uploadTransferImage}
                titleHeader={T('Add Transfer Player Image')}
                transferId={this.props.transferId}
                uploadStatus={this.props.transferImage}
                centeredImage
              />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
function mapStateToProps({ performers, performerPlayers }) {
  return {
    teams: performers.teams,
    transferredStatus: performerPlayers.transferredStatus,
    editTransferStatus: performerPlayers.editTransferStatus,
    players: performerPlayers.players,
    player: performerPlayers.player,
    headers: performerPlayers.headers,
    transferImage: performerPlayers.transferImage,
    error: performerPlayers.error,
  };
}

export default connect(mapStateToProps, {
  uploadPlayerTranferImage,
  resetUploadPlayerTranferImage,
})(PlayerTranferImage);

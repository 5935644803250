import _ from 'lodash';
import {
  FETCH_DEFAULT_SETTINGS_WAITING,
  FETCH_DEFAULT_SETTINGS_SUCCESS,
  FETCH_DEFAULT_SETTINGS_ERROR,
  FETCH_DEFAULT_SETTINGS_RESET,
  ADD_DEFAULT_SETTINGS_WAITING,
  ADD_DEFAULT_SETTINGS_SUCCESS,
  ADD_DEFAULT_SETTINGS_ERROR,
  ADD_DEFAULT_SETTINGS_RESET,
  EDIT_DEFAULT_SETTINGS_WAITING,
  EDIT_DEFAULT_SETTINGS_SUCCESS,
  EDIT_DEFAULT_SETTINGS_ERROR,
  EDIT_DEFAULT_SETTINGS_RESET,
  DELETE_DEFAULT_SETTINGS_WAITING,
  DELETE_DEFAULT_SETTINGS_SUCCESS,
  DELETE_DEFAULT_SETTINGS_ERROR,
  DELETE_DEFAULT_SETTINGS_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  opStatus: {
    add: 'init',
    fetch: 'init',
    fetchAll: 'init',
    update: 'init',
    delete: 'init',
  },
  settings: [],
};

export default function defaultTicketReducer(state = INIT_STATE, action) {
  let opStatus = { ...state.opStatus };
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_DEFAULT_SETTINGS_WAITING:
      opStatus = { ...state.opStatus, fetchAll: 'waiting' };
      return { ...state, opStatus };
    case FETCH_DEFAULT_SETTINGS_SUCCESS: {
      const settings = _.mapKeys(
        action.payload.data.items,
        'product_template_id'
      );
      opStatus = { ...state.opStatus, fetchAll: 'success' };
      return { ...state, opStatus, settings };
    }
    case FETCH_DEFAULT_SETTINGS_ERROR:
      opStatus = { ...state.opStatus, fetchAll: 'error' };
      return { ...state, opStatus, error: action.payload.error };
    case FETCH_DEFAULT_SETTINGS_RESET:
      opStatus = { ...state.opStatus, fetchAll: 'init' };
      return { ...state, opStatus };

    case ADD_DEFAULT_SETTINGS_WAITING:
      opStatus = { ...state.opStatus, add: 'waiting' };
      return { ...state, opStatus };
    case ADD_DEFAULT_SETTINGS_SUCCESS: {
      let ticket = action.payload.data;
      ticket = { [ticket.product_template_id]: ticket };
      const settings = { ...state.settings, ...ticket };
      opStatus = { ...state.opStatus, add: 'success' };
      return { ...state, opStatus, settings };
    }
    case ADD_DEFAULT_SETTINGS_ERROR:
      opStatus = { ...state.opStatus, add: 'error' };
      return { ...state, opStatus, error: action.payload.error };
    case ADD_DEFAULT_SETTINGS_RESET:
      opStatus = { ...state.opStatus, add: 'init' };
      return { ...state, opStatus };

    case EDIT_DEFAULT_SETTINGS_WAITING:
      opStatus = { ...state.opStatus, update: 'waiting' };
      return { ...state, opStatus };
    case EDIT_DEFAULT_SETTINGS_SUCCESS: {
      let ticket = action.payload.data;
      ticket = { [ticket.product_template_id]: ticket };
      const settings = { ...state.settings, ...ticket };
      opStatus = { ...state.opStatus, update: 'success' };
      return { ...state, opStatus, settings };
    }
    case EDIT_DEFAULT_SETTINGS_ERROR:
      opStatus = { ...state.opStatus, update: 'error' };
      return { ...state, opStatus, error: action.payload.error };
    case EDIT_DEFAULT_SETTINGS_RESET:
      opStatus = { ...state.opStatus, update: 'init' };
      return { ...state, opStatus };

    case DELETE_DEFAULT_SETTINGS_WAITING:
      opStatus = { ...state.opStatus, delete: 'waiting' };
      return { ...state, opStatus };
    case DELETE_DEFAULT_SETTINGS_SUCCESS: {
      const id = action.payload.id;
      const settings = _.omit(state.settings, id);
      opStatus = { ...state.opStatus, delete: 'success' };
      return { ...state, opStatus, settings };
    }
    case DELETE_DEFAULT_SETTINGS_ERROR:
      opStatus = { ...state.opStatus, delete: 'error' };
      return { ...state, opStatus, error: action.payload.error };
    case DELETE_DEFAULT_SETTINGS_RESET:
      opStatus = { ...state.opStatus, delete: 'init' };

      break;
    default:
      return state;
  }
}

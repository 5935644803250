import React, { Component } from 'react';
import { Form, Grid, Header, Icon } from 'semantic-ui-react';
import { TimeInput } from 'semantic-ui-calendar-react-yz';
import _ from 'lodash';
import { T } from '../Common/Helpers';

class ShopOpeningHour extends Component {
  state = { active: false };
  validateOpeningHours = () => {
    const shopOpening = this.state.shopOpening.slice();
    return shopOpening.every(
      opening => opening.soh_open_time <= opening.soh_close_time
    );
  };
  days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  toggleShow = () => {
    this.setState(prevState => ({ active: !prevState.active }));
  };

  renderOpenningHours = () =>
    _.map(
      this.days,
      (
        day,
        index //shop_day_of_week starts from 1 not from 0, Therefore 1 is added to adjust it.
      ) => (
        <Grid.Row
          columns="4"
          style={{
            borderRight: '1px solid rgba(232, 232, 232, 1)',
            borderBottom: '1px solid rgba(232, 232, 232, 1)',
          }}
          key={`grid-row-${index}`}
        >
          <Grid.Column>{T(day)}</Grid.Column>
          <Grid.Column>
            <Form.Field>
              <TimeInput
                // style={{ minWidth: '100px', marginRight: '5px' }}
                closable
                // initialDate={moment()}
                // placeholder={moment().format('HH:mm')}
                name="soh_open_time"
                // label={T('Opens')}
                disabled={this.props.shopOpening[index + 1].closed}
                value={this.props.formatTime(
                  this.props.shopOpening[index + 1].soh_open_time
                )}
                iconPosition="left"
                onChange={(e, { value }) =>
                  this.props.setShopOpening(index + 1, 'soh_open_time', value)
                }
                timeFormat="24"
                //autoComplete='off'
                //history='off'
                required
                //readOnly
                pickerWidth="200px"
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <TimeInput
                // style={{ minWidth: '100px', marginRight: '5px' }}
                closable
                name="soh_close_time"
                // label={T('Closes')}
                disabled={this.props.shopOpening[index + 1].closed}
                value={this.props.formatTime(
                  this.props.shopOpening[index + 1].soh_close_time
                )}
                iconPosition="left"
                onChange={(e, { value }) =>
                  this.props.setShopOpening(index + 1, 'soh_close_time', value)
                }
                timeFormat="24"
                //autoComplete='off'
                //history='off'
                required
                //readOnly
                pickerWidth="200px"
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <Form.Checkbox
                toggle
                checked={this.props.shopOpening[index + 1].closed}
                onChange={(e, { checked }) =>
                  this.props.setShopOpening(index + 1, 'closed', checked)
                }
                // label={T('Closed')}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      )
    );

  render() {
    return (
      <Grid
        stackable
        style={{ marginTop: '10px', maxWidth: '1280px', marginBottom: '20px' }}
      >
        <Grid.Row columns="1" onClick={this.toggleShow}>
          <Grid.Column style={{ cursor: 'pointer' }}>
            <Header>
              {!this.state.active ? (
                <Icon name="caret square right" size="large" />
              ) : (
                <Icon name="caret square down" size="large" />
              )}
              {T(this.props.title)}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {this.state.active && (
          <React.Fragment>
            <Grid.Row
              columns="4"
              style={{ border: '1px solid rgba(232, 232, 232, 1)' }}
            >
              <Grid.Column>
                <Header> {T('Day')} </Header>
              </Grid.Column>
              <Grid.Column>
                <Header> {T('Opens')} </Header>
              </Grid.Column>
              <Grid.Column>
                <Header> {T('Closes')} </Header>
              </Grid.Column>
              <Grid.Column>
                <Header> {T('Closed')} </Header>
              </Grid.Column>
            </Grid.Row>
            {this.renderOpenningHours()}
          </React.Fragment>
        )}
      </Grid>
    );
  }
}
export default ShopOpeningHour;

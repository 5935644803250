import React, { Component } from 'react';
import { Grid, Form, Button, Divider } from 'semantic-ui-react';
import { T } from '../Common/Helpers';
import FileUpload from '../Common/FileUpload';

class PollOptionItem extends Component {
  render() {
    const {
      key,
      cms_content_id,
      cm_main_image,
      parentkey,
      cm_application,
      game_id,
    } = this.props.polloption;
    const cm_short_header = this.props.polloption.cm_short_header;
    let file_upload_id = cm_application;
    let upload_target = 'application';

    if (game_id) {
      file_upload_id = Number(game_id);
      upload_target = 'game';
    }

    return (
      <Grid stackable style={{ marginBottom: '10px' }} key={key}>
        <Grid.Row columns={3}>
          <Grid.Column width={8}>
            <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
              <Form.Input
                name="cms_content_id"
                type="hidden"
                value={cms_content_id}
                //onChange={(e, { name, value }) => this.props.onUpdatePollOption(cms_content_id, name, value)}
              />
              <Form.Input
                name="cm_short_header"
                type="text"
                label={T('Option')}
                placeholder={T('option name')}
                value={cm_short_header}
                onChange={(e, { name, value }) =>
                  this.props.onUpdatePollOption(key, name, value)
                }
              />
            </div>
          </Grid.Column>
          <Grid.Column width={7}>
            <Form.Field>
              <label>{T('Option Image')}</label>
              <FileUpload
                id={file_upload_id}
                file_key={key}
                file_url={cm_main_image}
                ws_id={this.props.ws_id}
                target={upload_target}
                module_id={this.props.module_id}
                callback={this.props.imageUpdate}
                style={{
                  height: 120,
                  width: 120,
                  minHeight: 120,
                  minWidth: 120,
                }}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Button
              icon="trash"
              content={T('Delete Option')}
              color="red"
              onClick={() => this.props.onRemove(parentkey)}
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
      </Grid>
    );
  }
}

export default PollOptionItem;

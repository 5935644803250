/* eslint-env browser */
import axios from 'axios';
import config from '../../Config/AEConfig';
import ErrorAction from './ErrorAction';
import {
  FETCH_TRANSLATION_WAITING,
  FETCH_TRANSLATION_SUCCESS,
  FETCH_TRANSLATION_ERROR,
} from '../Types';

// import ErrorAction from './ErrorAction';

export const fetchTranslations_not_in_use = langInput => dispatch => {
  const lang =
    langInput ||
    navigator.language.substring(0, 2) ||
    navigator.userLanguage.substring(0, 2);
  // console.log(' translation language ', lang);
  // const lang = getState().auth.lang;
  dispatch({ type: FETCH_TRANSLATION_WAITING });
  return axios
    .get(`${config.BASE_URL}/config/translations/${lang}`)
    .then(data => dispatch({ type: FETCH_TRANSLATION_SUCCESS, payload: data }))
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_TRANSLATION_ERROR)
        : FETCH_TRANSLATION_ERROR;
      dispatch({ type, payload: error });
    });
};

export const fetchTranslations = (contextId, langInput) => dispatch => {
  const lang =
    langInput ||
    navigator.language.substring(0, 2) ||
    navigator.userLanguage.substring(0, 2);
  dispatch({ type: FETCH_TRANSLATION_WAITING });
  let url = `${config.BASE_URL}/config/translations/${lang}`;
  if (contextId) {
    url += `?context_id=${contextId}`;
  }
  axios
    .get(url)
    .then(data => dispatch({ type: FETCH_TRANSLATION_SUCCESS, payload: data }))
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_TRANSLATION_ERROR)
        : FETCH_TRANSLATION_ERROR;
      dispatch({ type, payload: error });
    });
};

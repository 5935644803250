export const FETCH_LINEUP_SUCCESS = "fetch_lineup_success";
export const FETCH_LINEUP_WAITING = "fetch_lineup_waiting";
export const FETCH_LINEUP_ERROR = "fetch_lineup_error";
export const FETCH_LINEUP_RESET = "fetch_lineup_reset";

export const CREATE_LINEUP_SUCCESS = "create_lineup_success";
export const CREATE_LINEUP_WAITING = "create_lineup_waiting";
export const CREATE_LINEUP_ERROR = "create_lineup_error";
export const CREATE_LINEUP_RESET = "create_lineup_reset";

export const EDIT_LINEUP_SUCCESS = "edit_lineup_success";
export const EDIT_LINEUP_WAITING = "edit_lineup_waiting";
export const EDIT_LINEUP_ERROR = "edit_lineup_error";
export const EDIT_LINEUP_RESET = "edit_lineup_reset";

export const DELETE_LINEUP_SUCCESS = "delete_lineup_success";
export const DELETE_LINEUP_WAITING = "delete_lineup_waiting";
export const DELETE_LINEUP_ERROR = "delete_lineup_error";
export const DELETE_LINEUP_RESET = "delete_lineup_reset";

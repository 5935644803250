/* eslint-env browser */
import React, { Component } from 'react';
import { Button, Segment, Grid, Confirm } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { T, getErrorMessage } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';
// import UploadPerformerImage from '../UploadPerformerImage';
import {
  fetchPerformerPlayer,
  fetchPerformerPlayersList,
  resetFetchPerformerPlayer,
  editPerformerPlayer,
  resetEditPerformerPlayer,
  uploadPerformerPlayerImage,
  deletePerformerPlayer,
  resetDeletePerformerPlayer,
  deletePerformerMetadata,
  deletePerformerPlayerImage,
  resetUploadPlayerImage,
  resetDeletePerformerTeamImage,
  resetdeletePerformerMetadata,
} from '../../Redux/actions';
import ImageCrop from './ImageCrop';
import defaultImage from '../../layout/images/images.png';

class PlayerEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { openDeleteConfirmation: false };
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.deletePerformerImage = this.deletePerformerImage.bind(this);
  }
  componentDidMount() {
    const performerId = this.props.performerId;
    // localStorage.setItem('route', this.props.history.location.pathname);
    const authKey = localStorage.getItem('x-auth-key');
    if (!this.props.player) {
      this.props.fetchPerformerPlayer(
        authKey,
        performerId,
        this.props.workspace.ws_id
      );
    }
  }
  UNSAFE_componentWillUpdate(nextProps) {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    if (
      this.props.updateStatus !== 'success' &&
      nextProps.updateStatus === 'success'
    ) {
      this.props.resetEditPerformerPlayer();
      this.props.fetchPerformerPlayersList(
        authKey,
        ws_id,
        this.props.match.params.performer_id,
        this.props.headers.current_page
      );
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Player successfully updated'),
        time: 5000,
      });
      window.scroll(50, 100);
      this.props.fetchPerformerPlayer(authKey, nextProps.performerId, ws_id);
    } else if (
      this.props.deleteStatus !== 'success' &&
      nextProps.deleteStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Player deleted successfully'),
        time: 5000,
      });
      window.scroll(50, 100);
      this.props.onResetEditAndAddPlayer();
      this.props.fetchPerformerPlayersList(
        authKey,
        ws_id,
        nextProps.parentId,
        this.props.headers.current_page
      );
      this.props.resetDeletePerformerPlayer();
    } else if (
      this.props.deleteStatus !== 'error' &&
      nextProps.deleteStatus === 'error'
    ) {
      const errorObj = getErrorMessage(nextProps.error);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      window.scroll(50, 100);
      this.props.resetDeletePerformerPlayer();
    }
  }
  onConfirmDelete() {
    const performerId = this.props.performerId;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.deletePerformerPlayer(
      performerId,
      authKey,
      this.props.workspace.ws_id
    );
    this.setState({ openDeleteConfirmation: false });
  }
  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }
  onCancel() {
    this.props.onResetEditAndAddPlayer();
  }
  onDeleteClick() {
    this.setState({ openDeleteConfirmation: true });
  }
  onValidSubmit(formData) {
    // console.log('ON VALID SUBIT: ', this.props);
    const performerId = this.props.performerId;
    const inputValues = {
      ...formData,
      pf_name: `${formData.pf_last_name} ${formData.pf_first_name}`,
    };
    const keys = Object.keys(inputValues);
    keys.forEach(k => {
      if (k !== 'pf_public' && !inputValues[k]) {
        delete inputValues[k];
      }
    });
    const authKey = localStorage.getItem('x-auth-key');
    this.props.editPerformerPlayer(
      performerId,
      authKey,
      inputValues,
      this.props.workspace.ws_id
    );
  }

  // fileChangedHandler(event) {
  //   this.setState({ selectedFile: event.target.files[0],
  //                   fileUploadStyle: {}
  //                  });
  // }

  getPlayerImage(player) {
    if (!player) return {};
    const metadata = player.performer_metadata;
    let imageSrc = '';
    let metadataId = null;
    if (metadata && metadata.length > 0) {
      const pictMeta = metadata.filter(
        meta => meta.pm_key === 'imageurl' || meta.pm_key === 'imageurlSmall'
      );
      if (pictMeta && pictMeta.length !== 0) {
        imageSrc = pictMeta[0].pm_value;
        metadataId = pictMeta[0].performer_metadata_id;
      }
    }
    // console.log('Image src: ', imageSrc);
    return {
      url: imageSrc || defaultImage,
      metadataId,
      showDeleteButton: imageSrc !== '',
    };
  }

  deletePerformerImage(performerId) {
    const authKey = localStorage.getItem('x-auth-key');
    const imageObj = this.getPlayerImage(this.props.player);
    this.props.deletePerformerPlayerImage(
      performerId,
      imageObj.url,
      authKey,
      this.props.workspace.ws_id
    );
  }
  render() {
    const { player } = this.props;
    if (!player) {
      return <Spinner />;
    }
    // console.log('PLAYER EDIT PROPS: ', this.props);
    const playerField = this.getPlayerImage(this.props.player);
    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column
            width="6"
            textAlign="center"
            style={{ minWidth: '280px', maxWidth: '350px' }}
            stretched
          >
            <Segment>
              <ImageCrop
                performerId={this.props.performerId}
                uploadPerformerImage={this.props.uploadPerformerPlayerImage}
                playerImg={playerField.url}
                metadataId={this.props.metadataId}
                showDeleteButton={playerField.showDeleteButton}
                hideCancelButton
                onDeleteImage={this.deletePerformerImage}
                onResetEditAndAddPlayer={this.props.onResetEditAndAddPlayer}
                centeredImage
                uploadStatus={this.props.uploadStatus}
                altText={T('Player Image')}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width="10">
            <Segment style={{ minWidth: '360px' }}>
              <h3>{T('Edit Player')}</h3>
              <Form
                onValidSubmit={this.onValidSubmit.bind(this)}
                ref={ref => (this.form = ref)}
              >
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    name="pf_first_name"
                    label={T('First Name')}
                    width={6}
                    value={player.pf_first_name}
                  />
                  <Form.Input
                    required
                    name="pf_last_name"
                    label={T('Last Name')}
                    width={6}
                    value={player.pf_last_name}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    type="number"
                    name="pf_license_code"
                    label={T('Licence Code')}
                    width={6}
                    value={player.pf_license_code}
                    placeholder="Licence Code"
                  />
                  {player.pf_remote_id !== null ? (
                    <Form.Input
                      disabled
                      type="text"
                      name="pf_remote_id"
                      label={T('Remote Id')}
                      width={6}
                      value={String(player.pf_remote_id)}
                      placeholder="Remote Id"
                    />
                  ) : (
                    ''
                  )}
                  <Form.Checkbox
                    toggle
                    name="pf_public"
                    label={T('Public')}
                    style={{ marginTop: '25px' }}
                    defaultChecked={player.pf_public}
                  />
                </Form.Group>
                <Button content={T('Save')} color="green" />
                <Button
                  type="button"
                  color="red"
                  content={T('Delete Player')}
                  onClick={this.onDeleteClick.bind(this)}
                />
                <Confirm
                  header={T('Delete Player')}
                  content={T('Are you sure you want to delete the player?')}
                  color="red"
                  confirmButton={T('Delete')}
                  open={this.state.openDeleteConfirmation}
                  onCancel={this.oncancelDeleteConfirmation}
                  onConfirm={this.onConfirmDelete}
                  size="tiny"
                />
                <Button
                  type="button"
                  color="black"
                  content={T('Cancel')}
                  onClick={() => this.onCancel()}
                />
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps({ performerPlayers, workspace }) {
  return {
    selectStatus: performerPlayers.selectStatus,
    updateStatus: performerPlayers.updateStatus,
    headers: performerPlayers.headers,
    uploadStatus: performerPlayers.uploadStatus,
    metadataDelete: performerPlayers.metadataDelete,
    deleteStatus: performerPlayers.deleteStatus,
    deleteImageStatus: performerPlayers.deleteImageStatus,
    error: performerPlayers.error,
    workspace,
  };
}
export default connect(mapStateToProps, {
  fetchPerformerPlayer,
  fetchPerformerPlayersList,
  resetFetchPerformerPlayer,
  editPerformerPlayer,
  resetEditPerformerPlayer,
  uploadPerformerPlayerImage,
  deletePerformerPlayer,
  resetDeletePerformerPlayer,
  deletePerformerMetadata,
  deletePerformerPlayerImage,
  resetUploadPlayerImage,
  resetDeletePerformerTeamImage,
  resetdeletePerformerMetadata,
})(withRouter(PlayerEdit));

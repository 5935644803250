import React, { Component } from 'react';
import { Grid, Form, Button, Header, Divider } from 'semantic-ui-react';
import { T } from '../Common/Helpers';
import FileUpload from '../Common/FileUpload';

class PrizeItem extends Component {
  render() {
    const { isEditable } = this.props;
    const { gp_number, gp_order, key, image, game_prize_id } = this.props.prize;
    const prize_name = this.props.prize.name;
    // console.log('###this.props.workspace', this.props.workspace);
    return (
      <Grid stackable style={{ marginBottom: '10px' }} key={key}>
        <Grid.Row columns={3}>
          <Grid.Column width={1}>
            <Header style={{ fontSize: '72px' }}>
              {this.props.index + 1}.
            </Header>
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Group widths="equal">
              <Form.Input
                disabled={!isEditable}
                name="name"
                type="text"
                required
                label={T('Palkinnon nimi')}
                placeholder={T(
                  'Syötä tähän nimi, jonka käyttäjä näkee sovelluksessa'
                )}
                value={prize_name}
                onChange={(e, { name, value }) =>
                  this.props.onUpdatePrize(key, name, value)
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                disabled={!isEditable}
                name="gp_number"
                type="number"
                step={1}
                required
                label={T('Number of Winners')}
                value={gp_number}
                onChange={(e, { name, value }) =>
                  this.props.onUpdatePrize(key, name, value)
                }
              />
            </Form.Group>
            <Form.Input
              disabled={!isEditable}
              name="gp_order"
              type="hidden"
              value={gp_order}
              onChange={(e, { name, value }) =>
                this.props.onUpdatePrize(key, name, value)
              }
            />
            {this.props.index > 0 && (
              <Form.Group>
                <Button
                  disabled={!isEditable}
                  icon="trash"
                  content={T('Delete Prize')}
                  color="red"
                  onClick={e => {
                    e.preventDefault();
                    this.props.onRemove(key, game_prize_id);
                  }}
                />
              </Form.Group>
            )}
          </Grid.Column>
          <Grid.Column width={7}>
            <Form.Field>
              <label>{T('Prize Image')}</label>
              <FileUpload
                disabled={!isEditable}
                file_key={key}
                file_url={image}
                ws_id={this.props.ws_id}
                target="application"
                id={this.props.workspace.activeWorkspace.cnc_application}
                module_id={this.props.module_id}
                callback={this.props.imageUpdate}
                deletecallback={this.props.imageDelete}
                style={{
                  height: 193.5,
                  width: 150,
                  minHeight: 193.5,
                  minWidth: 150,
                }}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Divider />
      </Grid>
    );
  }
}

export default PrizeItem;

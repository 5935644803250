import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import Nav from '../layout/Nav';

import CmsContentList from '../components/CmsContent/CmsContentList';
import ManageCmsContent from '../components/CmsContent/ManageCmsContent';
import ManageCmsParent from '../components/CmsContent/ManageCmsParent';

const CmsContentRoute = ({ config }) => {
  let { path } = useRouteMatch();

  return (
    <Container fluid style={{ marginBottom: '150px' }}>
      <Nav />
      <Switch>
        <Route path={`${path}/:type/list`} exact>
          <CmsContentList config={config} />
        </Route>
        <Route path={`${path}/manage`} exact>
          <ManageCmsContent config={config} />
        </Route>
        <Route path={`${path}/manage/:parentId`} exact>
          <ManageCmsContent config={config} />
        </Route>
        <Route path={`${path}/manageParent`} exact>
          <ManageCmsParent config={config} />
        </Route>
        <Route path={`${path}/manageParent/:parentId`} exact>
          <ManageCmsParent config={config} />
        </Route>
      </Switch>
    </Container>
  );
};

export default CmsContentRoute;

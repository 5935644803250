import axios from 'axios';
import config from '../../Config/AEConfig';
import {
  ADD_PROMETHEUS_APP_SUCCESS,
  ADD_PROMETHEUS_APP_WAITING,
  ADD_PROMETHEUS_APP_ERROR,
  ADD_PROMETHEUS_APP_RESET,
} from '../Types';
import ErrorAction from './ErrorAction';

const ModuleId = 16;
export function createPrometheusApp(data, authKey) {
  return dispatch => {
    dispatch({ type: ADD_PROMETHEUS_APP_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/application?module_id=${ModuleId}`;
    axios
      .post(url, data)
      .then(response =>
        dispatch({ type: ADD_PROMETHEUS_APP_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_PROMETHEUS_APP_ERROR)
          : ADD_PROMETHEUS_APP_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetCreatePrometheusApp() {
  return { type: ADD_PROMETHEUS_APP_RESET };
}

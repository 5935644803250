import React, { Component } from "react";
import PropTypes from "prop-types";
// import MenuItemBlock from './MenuItemBlock';
class StickyMenu extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.children !== nextProps.children;
  }
  render() {
    return (
      <div className="menu-container">
        {/*<Header as='h3'>Stuck Content</Header>
        <Image size="tiny" src='https://react.semantic-ui.com/images/wireframe/image.png' />*/}
        <div className="sticky-menu">
          {/*<MenuItemBlock labelText="App name" />
          <MenuItemBlock labelText="Logo" />
          <MenuItemBlock labelText="Colors" />
          <MenuItemBlock labelText="Template" />
          <MenuItemBlock labelText="Modules" />
          <MenuItemBlock labelText="View app" />*/}
          {this.props.children}
        </div>
      </div>
    );
  }
}

StickyMenu.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

export default StickyMenu;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Image,
  Button,
  Grid,
  Header,
  Form,
  // Container,
  Segment,
  Message,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { toast } from 'react-semantic-toasts';
import { forgotPassword } from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import { T, getErrorMessage } from '../Common/Helpers';
import logo from '../../layout/images/keino_500x160.png';
import '../../Style/Login.css';

class ForgotPassword extends Component {
  onValidSubmit(event) {
    event.preventDefault();
    this.props.forgotPassword(event);
  }

  renderInnerPage() {
    if (this.props.forgetMessage.status === 'waiting') {
      return <Spinner />;
    } else if (this.props.forgetMessage.status === 'error') {
      const errorObj = getErrorMessage(
        this.props.error,
        'Request to send password reset link failed, user is inactive or user not found'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (this.props.forgetMessage.status === 'success') {
      const successMessage = this.props.forgetMessage.res.message;
      return (
        <Message
          success
          fluid={+true}
          size="large"
          header={T('Reset link sent')}
          content={T(successMessage)}
        />
      );
    }

    return (
      <>
        <Form
          onSubmit={this.onValidSubmit.bind(this)}
          ref={ref => (this.form = ref)}
        >
          <Form.Input
            name="email"
            required
            icon="user"
            placeholder={T('Email address')}
          />
          <Button
            primary
            fluid
            id="sendButton"
            icon="key"
            content={T('Request Password Change Link')}
          />
        </Form>
      </>
    );
  }

  render() {
    return (
      <Grid
        textAlign="center"
        style={{ height: '100vh' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Image size="small" src={logo} />
          <Segment stacked>
            <Header as="h3" textAlign="center">
              {T('Regain access to Keino')}
            </Header>
            {this.renderInnerPage()}
          </Segment>
          <Segment>
            <Button
              secondary
              fluid
              as={Link}
              id="sendButton"
              icon="arrow left"
              content={T('Return to Login Page')}
              to="/auth/login"
            />
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return { forgetMessage: state.forget, error: state.forget.error };
}

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);

import axios from 'axios';
import config from '../../Config/AEConfig';
import ErrorAction from './ErrorAction';
import {
  FETCH_SHOP_PRODUCTS_WAITING,
  FETCH_SHOP_PRODUCTS_SUCCESS,
  FETCH_SHOP_PRODUCTS_ERROR,
  FETCH_SHOP_PRODUCTS_RESET,
  FETCH_SHOPS_WAITING,
  FETCH_SHOPS_SUCCESS,
  FETCH_SHOPS_ERROR,
  FETCH_SHOPS_RESET,
  FETCH_APP_SHOPS_WAITING,
  FETCH_APP_SHOPS_SUCCESS,
  FETCH_APP_SHOPS_ERROR,
  FETCH_APP_SHOPS_RESET,
  FETCH_SHOP_WAITING,
  FETCH_SHOP_SUCCESS,
  FETCH_SHOP_ERROR,
  FETCH_SHOP_RESET,
  ADD_SHOP_WAITING,
  ADD_SHOP_SUCCESS,
  ADD_SHOP_ERROR,
  ADD_SHOP_RESET,
  EDIT_SHOP_WAITING,
  EDIT_SHOP_SUCCESS,
  EDIT_SHOP_ERROR,
  EDIT_SHOP_RESET,
  DELETE_SHOP_WAITING,
  DELETE_SHOP_SUCCESS,
  DELETE_SHOP_ERROR,
  DELETE_SHOP_RESET,
  FETCH_SHOP_PRODUCT_WAITING,
  FETCH_SHOP_PRODUCT_SUCCESS,
  FETCH_SHOP_PRODUCT_ERROR,
  FETCH_SHOP_PRODUCT_RESET,
  ADD_SHOP_PRODUCT_WAITING,
  ADD_SHOP_PRODUCT_SUCCESS,
  ADD_SHOP_PRODUCT_ERROR,
  ADD_SHOP_PRODUCT_RESET,
  EDIT_SHOP_PRODUCT_WAITING,
  EDIT_SHOP_PRODUCT_SUCCESS,
  EDIT_SHOP_PRODUCT_ERROR,
  EDIT_SHOP_PRODUCT_RESET,
  DELETE_SHOP_PRODUCT_WAITING,
  DELETE_SHOP_PRODUCT_SUCCESS,
  DELETE_SHOP_PRODUCT_ERROR,
  DELETE_SHOP_PRODUCT_RESET,
  FETCH_SHOP_PRICELIST_WAITING,
  FETCH_SHOP_PRICELIST_SUCCESS,
  FETCH_SHOP_PRICELIST_ERROR,
  FETCH_SHOP_PRICELIST_RESET,
  FETCH_PRICELIST_WAITING,
  FETCH_PRICELIST_SUCCESS,
  FETCH_PRICELIST_ERROR,
  FETCH_PRICELIST_RESET,
  FETCH_SHOP_PRODUCT_UNIT_WAITING,
  FETCH_SHOP_PRODUCT_UNIT_SUCCESS,
  FETCH_SHOP_PRODUCT_UNIT_ERROR,
  FETCH_SHOP_PRODUCT_UNIT_RESET,
  ADD_SHOP_PRICELIST_WAITING,
  ADD_SHOP_PRICELIST_SUCCESS,
  ADD_SHOP_PRICELIST_ERROR,
  ADD_SHOP_PRICELIST_RESET,
  EDIT_SHOP_PRICELIST_WAITING,
  EDIT_SHOP_PRICELIST_SUCCESS,
  EDIT_SHOP_PRICELIST_ERROR,
  EDIT_SHOP_PRICELIST_RESET,
  DELETE_SHOP_PRICELIST_WAITING,
  DELETE_SHOP_PRICELIST_SUCCESS,
  DELETE_SHOP_PRICELIST_ERROR,
  DELETE_SHOP_PRICELIST_RESET,
  DELETE_PRODUCT_PRICELIST_WAITING,
  DELETE_PRODUCT_PRICELIST_SUCCESS,
  DELETE_PRODUCT_PRICELIST_ERROR,
  DELETE_PRODUCT_PRICELIST_RESET,
  ADD_SHOP_PRODUCT_PRICE_WAITING,
  ADD_SHOP_PRODUCT_PRICE_SUCCESS,
  ADD_SHOP_PRODUCT_PRICE_ERROR,
  ADD_SHOP_PRODUCT_PRICE_RESET,
  EDIT_SHOP_PRODUCT_PRICE_WAITING,
  EDIT_SHOP_PRODUCT_PRICE_SUCCESS,
  EDIT_SHOP_PRODUCT_PRICE_ERROR,
  EDIT_SHOP_PRODUCT_PRICE_RESET,
  EDIT_SHOP_PRODUCT_PRICELISTS_WAITING,
  EDIT_SHOP_PRODUCT_PRICELISTS_SUCCESS,
  EDIT_SHOP_PRODUCT_PRICELISTS_ERROR,
  EDIT_SHOP_PRODUCT_PRICELISTS_RESET,
  EDIT_SHOP_SETTINGS_WAITING,
  EDIT_SHOP_SETTINGS_SUCCESS,
  EDIT_SHOP_SETTINGS_ERROR,
  EDIT_SHOP_SETTINGS_RESET,
  FETCH_SHOP_SETTINGS_WAITING,
  FETCH_SHOP_SETTINGS_SUCCESS,
  FETCH_SHOP_SETTINGS_ERROR,
  FETCH_SHOP_SETTINGS_RESET,
  FETCH_SHOP_SETTINGS_KEYWORDS_WAITING,
  FETCH_SHOP_SETTINGS_KEYWORDS_SUCCESS,
  FETCH_SHOP_SETTINGS_KEYWORDS_ERROR,
  FETCH_SHOP_SETTINGS_KEYWORDS_RESET,
  FETCH_PRODUCT_TYPES_WAITING,
  FETCH_PRODUCT_TYPES_SUCCESS,
  FETCH_PRODUCT_TYPES_ERROR,
  FETCH_PRODUCT_TYPES_RESET,
  EDIT_MULTIPLE_PRODUCT_PRICES_WAITING,
  EDIT_MULTIPLE_PRODUCT_PRICES_SUCCESS,
  EDIT_MULTIPLE_PRODUCT_PRICES_ERROR,
  EDIT_MULTIPLE_PRODUCT_PRICES_RESET,
  IS_OPEN_SHOP_NOW_WAITING,
  IS_OPEN_SHOP_NOW_SUCCESS,
  IS_OPEN_SHOP_NOW_ERROR,
  IS_OPEN_SHOP_NOW_RESET,
  SHOP_CONFIG_LIST_KEYWORKDS_WAITING,
  SHOP_CONFIG_LIST_KEYWORKDS_SUCCESS,
  SHOP_CONFIG_LIST_KEYWORKDS_ERROR,
  SHOP_CONFIG_LIST_KEYWORKDS_RESET,
  SHOP_CONFIG_MANAGE_WAITING,
  SHOP_CONFIG_MANAGE_SUCCESS,
  SHOP_CONFIG_MANAGE_ERROR,
  SHOP_CONFIG_MANAGE_RESET,
  SHOP_CONFIG_LIST_WAITING,
  SHOP_CONFIG_LIST_SUCCESS,
  SHOP_CONFIG_LIST_ERROR,
  SHOP_CONFIG_LIST_RESET,
  FETCH_OUT_OF_STOCK_MESSAGE_WAITING,
  FETCH_OUT_OF_STOCK_MESSAGE_SUCCESS,
  FETCH_OUT_OF_STOCK_MESSAGE_ERROR,
  FETCH_OUT_OF_STOCK_MESSAGE_RESET,
  EDIT_PRICELIST_PRODUCT_ORDER_WAITING,
  EDIT_PRICELIST_PRODUCT_ORDER_SUCCESS,
  EDIT_PRICELIST_PRODUCT_ORDER_ERROR,
  EDIT_PRICELIST_PRODUCT_ORDER_RESET,
} from '../Types';

const MODULE_ID = 17;
// const PRODUCT_UPDATE_MODULE_ID = 17;
// const PRICELIST_UPDATE_MODULE_ID = 17;
// const SHOP_SETTINGS_MODULE_ID = 17;
// const SHOP_MODULE_ID = 17;
// const SHOP_PRICE_LIST_MODULE_ID = 17;
const PRODUCT_UPDATE_MODULE_ID = 18;
const PRICELIST_UPDATE_MODULE_ID = 19;
const SHOP_SETTINGS_MODULE_ID = 22;
const SHOP_MODULE_ID = 22;
const SHOP_PRICE_LIST_MODULE_ID = 19;
const SALES_MODULE_ID = 22;

export const fetchProducts =
  ({
    authKey,
    sortByColumn,
    direction,
    start,
    searchterm,
    pr_type,
    pp_price_list,
    ws_id,
    module_id = MODULE_ID,
  }) =>
  dispatch => {
    dispatch({ type: FETCH_SHOP_PRODUCTS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/products?module_id=${module_id}&ws_id=${ws_id}`;
    let sort;
    if (sortByColumn && direction) {
      sort = `${sortByColumn}:${direction}`;
    } else if (sortByColumn) {
      sort = sortByColumn;
    }
    let params = { searchterm, start, sort, pr_type };
    if (pp_price_list) {
      params = { ...params, pp_price_list };
    }
    //removed application_id: 4, after consultation with carita 26.03.2019
    axios
      .get(url, { params })
      .then(response =>
        dispatch({ type: FETCH_SHOP_PRODUCTS_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_SHOP_PRODUCTS_ERROR)
          : FETCH_SHOP_PRODUCTS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchShops =
  ({
    authKey,
    ws_id,
    application_id,
    module_id = SHOP_MODULE_ID,
    noLodash = false,
  }) =>
  dispatch => {
    dispatch({ type: FETCH_SHOPS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url = `${config.BASE_URL}/shops?module_id=${module_id}&ws_id=${ws_id}`;
    url += application_id ? `&application_id=${application_id}` : '';
    axios
      .get(url)
      .then(response =>
        dispatch({ type: FETCH_SHOPS_SUCCESS, payload: response, noLodash })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_SHOPS_ERROR)
          : FETCH_SHOPS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchAppShops =
  ({ authKey, appId, module_id = SHOP_MODULE_ID, ws_id }) =>
  dispatch => {
    dispatch({ type: FETCH_APP_SHOPS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/shops?application_id=${appId}&module_id=${module_id}&ws_id=${ws_id}`;
    axios
      .get(url, { application_id: appId })
      .then(response =>
        dispatch({ type: FETCH_APP_SHOPS_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_APP_SHOPS_ERROR)
          : FETCH_APP_SHOPS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchShop =
  ({ authKey, shopId, module_id = SHOP_MODULE_ID, ws_id }) =>
  dispatch => {
    dispatch({ type: FETCH_SHOP_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/shop/${shopId}?module_id=${module_id}&ws_id=${ws_id}`;
    axios
      .get(url)
      .then(response =>
        dispatch({ type: FETCH_SHOP_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_SHOP_ERROR)
          : FETCH_SHOP_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchSales =
  ({ authKey, shopId, module_id = SALES_MODULE_ID, ws_id }) =>
  dispatch => {
    dispatch({ type: FETCH_SALES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/shop/${shopId}/sales?module_id=${module_id}&ws_id=${ws_id}`;
    axios
      .get(url)
      .then(response =>
        dispatch({ type: FETCH_SALES_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_SALES_ERROR)
          : FETCH_SALES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const addShop =
  ({ authKey, formData, module_id = SHOP_MODULE_ID, ws_id }) =>
  dispatch => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: ADD_SHOP_WAITING });
    axios
      .post(
        `${config.BASE_URL}/shop?module_id=${module_id}&ws_id=${ws_id}&pl_module_id=${SHOP_PRICE_LIST_MODULE_ID}`,
        formData
      )
      .then(response => dispatch({ type: ADD_SHOP_SUCCESS, payload: response }))
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_SHOP_ERROR)
          : ADD_SHOP_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const editShop =
  ({ authKey, shopId, formData, ws_id, module_id = SHOP_MODULE_ID, sender }) =>
  dispatch => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: EDIT_SHOP_WAITING });
    axios
      .put(
        `${config.BASE_URL}/shop/${shopId}?module_id=${module_id}&ws_id=${ws_id}&pl_module_id=${SHOP_PRICE_LIST_MODULE_ID}`,
        formData
      )
      .then(response =>
        dispatch({ type: EDIT_SHOP_SUCCESS, payload: { response, sender } })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_SHOP_ERROR)
          : EDIT_SHOP_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const deleteShop =
  ({ authKey, shopId, module_id = SHOP_MODULE_ID, ws_id }) =>
  dispatch => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: DELETE_SHOP_WAITING });
    axios
      .delete(
        `${config.BASE_URL}/shop/${shopId}?module_id=${module_id}&ws_id=${ws_id}`
      )
      .then(response =>
        dispatch({
          type: DELETE_SHOP_SUCCESS,
          payload: { ...response, shopId },
        })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_SHOP_ERROR)
          : DELETE_SHOP_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchPriceListProducts =
  ({
    authKey,
    sortByColumn,
    direction,
    start,
    searchterm,
    pr_type,
    pp_price_list,
    ws_id,
    module_id = MODULE_ID,
  }) =>
  dispatch => {
    dispatch({ type: FETCH_SHOP_PRODUCTS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/products?module_id=${module_id}&ws_id=${ws_id}&pp_price_list=${pp_price_list}`;
    let sort;
    if (sortByColumn && direction) {
      sort = `${sortByColumn}:${direction}`;
    } else if (sortByColumn) {
      sort = sortByColumn;
    }
    const params = { searchterm, start, sort, pr_type };
    //removed application_id: 4, after consultation with carita 26.03.2019
    axios
      .get(url, { params })
      .then(response =>
        dispatch({ type: FETCH_SHOP_PRODUCTS_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_SHOP_PRODUCTS_ERROR)
          : FETCH_SHOP_PRODUCTS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchProduct =
  ({ id, authKey, module_id = MODULE_ID, ws_id }) =>
  dispatch => {
    // "cms_content_id","cm_short_header","cm_performer","cm_event","cm_place","cm_application","cm_valid_from","cm_valid_to","cm_content_type","cm_edited"
    //console.log('fetch product ', content);
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_SHOP_PRODUCT_WAITING });
    axios
      .get(
        `${config.BASE_URL}/product/${id}?module_id=${module_id}&ws_id=${ws_id}`
      )
      .then(response =>
        dispatch({ type: FETCH_SHOP_PRODUCT_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_SHOP_PRODUCT_ERROR)
          : FETCH_SHOP_PRODUCT_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchPriceLists =
  ({ authKey, ws_id, searchterm, all = false, module_id = MODULE_ID }) =>
  dispatch => {
    //console.log('fetch price lists ');
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_SHOP_PRICELIST_WAITING });
    let url = `${config.BASE_URL}/pricelists?module_id=${module_id}&ws_id=${ws_id}&all=${all}`;
    if (searchterm) {
      url += `&searchterm=${searchterm}`;
    }
    axios
      .get(url)
      .then(response =>
        dispatch({ type: FETCH_SHOP_PRICELIST_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_SHOP_PRICELIST_ERROR)
          : FETCH_SHOP_PRICELIST_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchPriceList =
  ({ authKey, ws_id, pp_price_list, module_id = MODULE_ID }) =>
  dispatch => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_PRICELIST_WAITING });
    const url = `${config.BASE_URL}/pricelist/${pp_price_list}?module_id=${module_id}&ws_id=${ws_id}`;
    axios
      .get(url)
      .then(response =>
        dispatch({ type: FETCH_PRICELIST_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_PRICELIST_ERROR)
          : FETCH_PRICELIST_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchProductUnits =
  ({ authKey, ws_id, module_id = MODULE_ID }) =>
  dispatch => {
    //console.log('fetch product units ');
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_SHOP_PRODUCT_UNIT_WAITING });
    axios
      .get(
        `${config.BASE_URL}/productunits?module_id=${module_id}&ws_id=${ws_id}`
      )
      .then(response =>
        dispatch({ type: FETCH_SHOP_PRODUCT_UNIT_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_SHOP_PRODUCT_UNIT_ERROR)
          : FETCH_SHOP_PRODUCT_UNIT_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const addProduct =
  (authKey, formData, ws_id, module_id = MODULE_ID) =>
  dispatch => {
    // "cms_content_id","cm_short_header","cm_performer","cm_event","cm_place","cm_application","cm_valid_from","cm_valid_to","cm_content_type","cm_edited"
    // console.log('Add product ', formData);
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: ADD_SHOP_PRODUCT_WAITING });
    axios
      .post(
        `${config.BASE_URL}/product?module_id=${module_id}&ws_id=${ws_id}`,
        formData
      )
      .then(response =>
        dispatch({ type: ADD_SHOP_PRODUCT_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_SHOP_PRODUCT_ERROR)
          : ADD_SHOP_PRODUCT_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const addPriceList =
  (authKey, formData, ws_id, module_id = MODULE_ID) =>
  dispatch => {
    // "cms_content_id","cm_short_header","cm_performer","cm_event","cm_place","cm_application","cm_valid_from","cm_valid_to","cm_content_type","cm_edited"
    //console.log('Add pricelist ', formData);
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: ADD_SHOP_PRICELIST_WAITING });
    axios
      .post(
        `${config.BASE_URL}/pricelist?module_id=${module_id}&ws_id=${ws_id}`,
        formData
      )
      .then(response =>
        dispatch({ type: ADD_SHOP_PRICELIST_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_SHOP_PRICELIST_ERROR)
          : ADD_SHOP_PRICELIST_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const editProduct =
  (authKey, formData, id, ws_id, module_id = PRODUCT_UPDATE_MODULE_ID) =>
  dispatch => {
    //console.log('Edit product', id, formData);
    dispatch({ type: EDIT_SHOP_PRODUCT_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/product/${id}?module_id=${module_id}&ws_id=${ws_id}`;
    axios
      .put(url, formData)
      .then(response => {
        dispatch({ type: EDIT_SHOP_PRODUCT_SUCCESS, payload: response });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_SHOP_PRODUCT_ERROR)
          : EDIT_SHOP_PRODUCT_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const editPriceList =
  (authKey, formData, id, ws_id, module_id = PRODUCT_UPDATE_MODULE_ID) =>
  dispatch => {
    //console.log('Edit pricelist', id, formData);
    dispatch({ type: EDIT_SHOP_PRICELIST_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/pricelist/${id}?module_id=${module_id}&ws_id=${ws_id}`;
    axios
      .put(url, formData)
      .then(response =>
        dispatch({ type: EDIT_SHOP_PRICELIST_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_SHOP_PRICELIST_ERROR)
          : EDIT_SHOP_PRICELIST_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const deleteProduct =
  (productId, authKey, ws_id, module_id = PRODUCT_UPDATE_MODULE_ID) =>
  dispatch => {
    //console.log('delete product', productId);
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: DELETE_SHOP_PRODUCT_WAITING });
    axios
      .delete(
        `${config.BASE_URL}/product/${productId}?module_id=${module_id}&ws_id=${ws_id}`
      )
      .then(response =>
        dispatch({ type: DELETE_SHOP_PRODUCT_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_SHOP_PRODUCT_ERROR)
          : DELETE_SHOP_PRODUCT_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const deletePriceList = (priceListId, authKey, ws_id) => dispatch => {
  //console.log('delete product', priceListId);
  axios.defaults.headers.common['x-auth-key'] = authKey;
  dispatch({ type: DELETE_SHOP_PRICELIST_WAITING });
  axios
    .delete(
      `${config.BASE_URL}/pricelist/${priceListId}?module_id=${PRICELIST_UPDATE_MODULE_ID}&ws_id=${ws_id}`
    )
    .then(response =>
      dispatch({ type: DELETE_SHOP_PRICELIST_SUCCESS, payload: response })
    )
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, DELETE_SHOP_PRICELIST_ERROR)
        : DELETE_SHOP_PRICELIST_ERROR;
      dispatch({ type, payload: error });
    });
};

export const deleteProductPrice =
  (productPriceId, authKey, prodId, ws_id) => dispatch => {
    //console.log('delete product price', productPriceId);
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: DELETE_PRODUCT_PRICELIST_WAITING });
    axios
      .delete(
        `${config.BASE_URL}/product/price/${productPriceId}?module_id=${PRICELIST_UPDATE_MODULE_ID}&ws_id=${ws_id}`
      )
      .then(response => {
        //console.log('delete product price response ', response);
        dispatch({
          type: DELETE_PRODUCT_PRICELIST_SUCCESS,
          payload: { ...response, prodId },
        });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_PRODUCT_PRICELIST_ERROR)
          : DELETE_PRODUCT_PRICELIST_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const addProductPrice =
  ({ authKey, data, ws_id }) =>
  dispatch => {
    // "cms_content_id","cm_short_header","cm_performer","cm_event","cm_place","cm_application","cm_valid_from","cm_valid_to","cm_content_type","cm_edited"
    dispatch({ type: ADD_SHOP_PRODUCT_PRICE_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/product/price?module_id=${PRICELIST_UPDATE_MODULE_ID}&ws_id=${ws_id}`;
    axios
      .post(url, data)
      .then(response => {
        dispatch({ type: ADD_SHOP_PRODUCT_PRICE_SUCCESS, payload: response });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_SHOP_PRODUCT_PRICE_ERROR)
          : ADD_SHOP_PRODUCT_PRICE_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const editProductPrice =
  ({ authKey, data, id, ws_id }) =>
  dispatch => {
    //console.log('Edit ticket price ', id, data);
    dispatch({ type: EDIT_SHOP_PRODUCT_PRICE_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/product/price/${id}?module_id=${PRICELIST_UPDATE_MODULE_ID}&ws_id=${ws_id}`;
    axios
      .put(url, data)
      .then(response => {
        dispatch({ type: EDIT_SHOP_PRODUCT_PRICE_SUCCESS, payload: response });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_SHOP_PRODUCT_PRICE_ERROR)
          : EDIT_SHOP_PRODUCT_PRICE_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const editPriceListProductOrder =
  (authKey, data, priceListId, ws_id) => dispatch => {
    //console.log('Edit product', id, formData);

    dispatch({ type: EDIT_PRICELIST_PRODUCT_ORDER_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/pricelist/${priceListId}/product/order?module_id=${PRICELIST_UPDATE_MODULE_ID}&ws_id=${ws_id}`;
    axios
      .put(url, data)
      .then(response => {
        dispatch({
          type: EDIT_PRICELIST_PRODUCT_ORDER_SUCCESS,
          payload: response,
        });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              EDIT_PRICELIST_PRODUCT_ORDER_ERROR
            )
          : EDIT_PRICELIST_PRODUCT_ORDER_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const editShopSettings =
  ({ authKey, values, ws_id }) =>
  dispatch => {
    dispatch({ type: EDIT_SHOP_SETTINGS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/block_config/${values.block_config_id}?ws_id=${ws_id}&module_id=${SHOP_SETTINGS_MODULE_ID}&ws_id=${values.ws_id}`;
    const payload = {
      bc_block: values.bc_block,
      bc_keyword: values.bc_keyword,
      bc_application: values.appId,
      bc_value: values.bc_value,
      block_config_id: values.block_config_id,
    };
    axios
      .put(url, payload)
      .then(response =>
        dispatch({ type: EDIT_SHOP_SETTINGS_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_SHOP_SETTINGS_ERROR)
          : EDIT_SHOP_SETTINGS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchShopSettings =
  ({ appId, authKey, ws_id }) =>
  dispatch => {
    dispatch({ type: FETCH_SHOP_SETTINGS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/block_configs/${appId}?module_id=${SHOP_SETTINGS_MODULE_ID}&ws_id=${ws_id}`;
    axios
      .get(url)
      .then(response => {
        dispatch({ type: FETCH_SHOP_SETTINGS_SUCCESS, payload: response });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_SHOP_SETTINGS_ERROR)
          : FETCH_SHOP_SETTINGS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchShopSettingsKeywords =
  ({ appId, authKey, ws_id }) =>
  dispatch => {
    dispatch({ type: FETCH_SHOP_SETTINGS_KEYWORDS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/block_config_keys/${appId}?module_id=${SHOP_SETTINGS_MODULE_ID}&ws_id=${ws_id}`;
    axios
      .get(url)
      .then(response => {
        dispatch({
          type: FETCH_SHOP_SETTINGS_KEYWORDS_SUCCESS,
          payload: response,
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_SHOP_SETTINGS_KEYWORDS_ERROR, payload: error });
      });
  };

export const fetchProductTypes =
  ({ authKey, ws_id }) =>
  dispatch => {
    dispatch({ type: FETCH_PRODUCT_TYPES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/producttypes?module_id=${SHOP_SETTINGS_MODULE_ID}&ws_id=${ws_id}`;
    axios
      .get(url)
      .then(response => {
        dispatch({ type: FETCH_PRODUCT_TYPES_SUCCESS, payload: response });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_PRODUCT_TYPES_ERROR)
          : FETCH_PRODUCT_TYPES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const editMultipleProductPrices =
  (authKey, data, priceListId, ws_id) => dispatch => {
    //console.log('Edit product', id, formData);
    dispatch({ type: EDIT_MULTIPLE_PRODUCT_PRICES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/pricelist/${priceListId}/products?module_id=${PRICELIST_UPDATE_MODULE_ID}&ws_id=${ws_id}`;
    axios
      .put(url, data)
      .then(response => {
        dispatch({
          type: EDIT_MULTIPLE_PRODUCT_PRICES_SUCCESS,
          payload: response,
        });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              EDIT_MULTIPLE_PRODUCT_PRICES_ERROR
            )
          : EDIT_MULTIPLE_PRODUCT_PRICES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const setShopOpenNowState = (authKey, data, ws_id) => dispatch => {
  dispatch({ type: IS_OPEN_SHOP_NOW_WAITING });
  axios.headers.common['x-auth-key'] = authKey;
  axios
    .put(`${config.BASE_URL}/shop/altopening?ws_id=${ws_id}`, data)
    .then(response =>
      dispatch({ type: IS_OPEN_SHOP_NOW_SUCCESS, payload: response })
    )
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, IS_OPEN_SHOP_NOW_ERROR)
        : IS_OPEN_SHOP_NOW_ERROR;
      dispatch({ type, payload: error });
    });
};

export const fetchShopConfigKeywords = (authKey, shopId, ws_id) => dispatch => {
  dispatch({ type: SHOP_CONFIG_LIST_KEYWORKDS_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .get(`${config.BASE_URL}/shop/keys?shop_id=${shopId}&ws_id=${ws_id}`)
    .then(response =>
      dispatch({
        type: SHOP_CONFIG_LIST_KEYWORKDS_SUCCESS,
        payload: response,
      })
    )
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, SHOP_CONFIG_LIST_KEYWORKDS_ERROR)
        : SHOP_CONFIG_LIST_KEYWORKDS_ERROR;
      dispatch({ type, payload: error });
    });
};
export const manageShopConfig =
  ({ authKey, shopId, shopConfig, appId, ws_id }) =>
  dispatch => {
    dispatch({ type: SHOP_CONFIG_MANAGE_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .post(
        `${config.BASE_URL}/shopconfig/${shopId}?ws_id=${ws_id}&application_id=${appId}&module_id=${SHOP_SETTINGS_MODULE_ID}`,
        shopConfig
      )
      .then(response =>
        dispatch({ type: SHOP_CONFIG_MANAGE_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, SHOP_CONFIG_MANAGE_ERROR)
          : SHOP_CONFIG_MANAGE_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const listShopConfigs = (authKey, shopId, appId, ws_id) => dispatch => {
  dispatch({ type: SHOP_CONFIG_LIST_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .get(
      `${config.BASE_URL}/shopconfigs/${shopId}?ws_id=${ws_id}&application_id=${appId}&module_id=${SHOP_SETTINGS_MODULE_ID}`
    )
    .then(response =>
      dispatch({ type: SHOP_CONFIG_LIST_SUCCESS, payload: response })
    )
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, SHOP_CONFIG_LIST_ERROR)
        : SHOP_CONFIG_LIST_ERROR;
      dispatch({ type, payload: error });
    });
};

export const editProductPricelists =
  (authKey, productId, pricelists, ws_id) => dispatch => {
    dispatch({ type: EDIT_SHOP_PRODUCT_PRICELISTS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .post(
        `${config.BASE_URL}/product/${productId}/price?ws_id=${ws_id}&module_id=${PRICELIST_UPDATE_MODULE_ID}`,
        pricelists
      )
      .then(response =>
        dispatch({
          type: EDIT_SHOP_PRODUCT_PRICELISTS_SUCCESS,
          payload: response,
        })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              EDIT_SHOP_PRODUCT_PRICELISTS_ERROR
            )
          : EDIT_SHOP_PRODUCT_PRICELISTS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchOutOfStockMessages =
  ({ authKey, ws_id }) =>
  dispatch => {
    dispatch({ type: FETCH_OUT_OF_STOCK_MESSAGE_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/product/stockmessages?module_id=${SHOP_SETTINGS_MODULE_ID}&ws_id=${ws_id}`; // TODO no module used at the moment
    axios
      .get(url)
      .then(response => {
        dispatch({
          type: FETCH_OUT_OF_STOCK_MESSAGE_SUCCESS,
          payload: response,
        });
      })
      .catch(error => {
        const type = error.respponse
          ? ErrorAction(error.response.status, FETCH_OUT_OF_STOCK_MESSAGE_ERROR)
          : FETCH_OUT_OF_STOCK_MESSAGE_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetProducts = () => ({ type: FETCH_SHOP_PRODUCTS_RESET });
export const resetFetchShops = () => ({ type: FETCH_SHOPS_RESET });
export const resetFetchAppShops = () => ({ type: FETCH_APP_SHOPS_RESET });
export const resetFetchShop = () => ({ type: FETCH_SHOP_RESET });
export const resetAddShop = () => ({ type: ADD_SHOP_RESET });
export const resetProduct = () => ({ type: FETCH_SHOP_PRODUCT_RESET });
export const resetDeleteShop = () => ({ type: DELETE_SHOP_RESET });
export const resetEditShop = () => ({ type: EDIT_SHOP_RESET });
export const addProductReset = () => ({ type: ADD_SHOP_PRODUCT_RESET });
export const editProductReset = () => ({ type: EDIT_SHOP_PRODUCT_RESET });
export const addPriceListReset = () => ({ type: ADD_SHOP_PRICELIST_RESET });
export const editPriceListReset = () => ({ type: EDIT_SHOP_PRICELIST_RESET });
export const deleteProductReset = () => ({ type: DELETE_SHOP_PRODUCT_RESET });
export const deletePricelistReset = () => ({
  type: DELETE_SHOP_PRICELIST_RESET,
});
export const deleteProductPriceReset = () => ({
  type: DELETE_PRODUCT_PRICELIST_RESET,
});
export const resetPriceList = () => ({ type: FETCH_SHOP_PRICELIST_RESET });
export const resetOnePriceList = () => ({ type: FETCH_PRICELIST_RESET });
export const resetProductUnit = () => ({ type: FETCH_SHOP_PRODUCT_UNIT_RESET });
export const resetAddProductPrice = () => ({
  type: ADD_SHOP_PRODUCT_PRICE_RESET,
});
export const resetEditProductPrice = () => ({
  type: EDIT_SHOP_PRODUCT_PRICE_RESET,
});
export const resetEditShopSettings = () => ({ type: EDIT_SHOP_SETTINGS_RESET });
export const resetFetchShopSettings = () => ({
  type: FETCH_SHOP_SETTINGS_RESET,
});
export const resetFetchShopSettingsKeywords = () => ({
  type: FETCH_SHOP_SETTINGS_KEYWORDS_RESET,
});
export const resetFetchProductTypes = () => ({
  type: FETCH_PRODUCT_TYPES_RESET,
});
export const resetEditMultipleProductPrices = () => ({
  type: EDIT_MULTIPLE_PRODUCT_PRICES_RESET,
});
export const resetSetShopOpenNowState = () => ({
  type: IS_OPEN_SHOP_NOW_RESET,
});
export const resetfetchShopConfigKeywords = () => ({
  type: SHOP_CONFIG_LIST_KEYWORKDS_RESET,
});
export const resetManageShopConfig = () => ({ type: SHOP_CONFIG_MANAGE_RESET });
export const resetListShopConfigs = () => ({ type: SHOP_CONFIG_LIST_RESET });
export const resetEditProductPricelists = () => ({
  type: EDIT_SHOP_PRODUCT_PRICELISTS_RESET,
});
export const resetStockMessage = () => ({
  type: FETCH_OUT_OF_STOCK_MESSAGE_RESET,
});
export const resetPriceListProductOrder = () => ({
  type: EDIT_PRICELIST_PRODUCT_ORDER_RESET,
});

import React from 'react';
import { T } from './Helpers';

export const getMultiLineText = p_content => {
  const content = p_content.map((line, key) => <div key={key}>{T(line)}</div>);
  return content;
};

export const Capitalize = (text, language) => {
  // console.log(language);
  if (language === 'en') {
    return CapitalizeAll(text);
  }
  return CapitalizeFirst(text);
};

const CapitalizeAll = text => {
  const arr = text.split(' ');
  const len = arr.length - 1;
  const content = arr.map((line, key) => CapitalizeFirst(line, key < len));
  return content;
};

const CapitalizeFirst = (text, space) => {
  const value = text.charAt(0).toUpperCase() + text.slice(1);
  if (space) {
    return value + ' ';
  }
  return value;
};

import React, { Component } from "react";
import { Button, Grid, Segment } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-semantic-toasts";
// import { Spinner } from '../Common/Spinner';
import {
  //fetchCountries,
  fetchVenue,
  editVenue,
  resetEditVenue,
  selectedEditVenueLocation,
  resetselectedEditVenueLocation,
} from "../../Redux/actions";
import { T, getErrorMessage } from "../Common/Helpers";
import SubVenues from "./SubVenues";
import ModalWindow, { ModalContext } from "../Common/ModalWindow";
import LocationList from "./Locations/LocationList";

class VenueEditInput extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedCountry: "init", selectedLocation: "" };
    this.onChangeCountry = this.onChangeCountry.bind(this);
    // this.onConfirmDelete = this.onConfirmDelete.bind(this);
    // this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onCancel = this.onCancel.bind(this);
    // this.oncancelDeleteConfirmation = this.oncancelDeleteConfirmation.bind(this);
    this.onLocationChange = _.debounce(this.onLocationChange.bind(this), 100);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const ws_id = this.props.workspace.activeWorkspace.id;
    if (nextProps.editStaus === "success") {
      toast({
        type: "success",
        title: T("Success"),
        description: T("Location edited successfully"),
        time: 5000,
      });
      this.props.resetEditVenue();
      this.props.history.push("/app/venues/list");
    } else if (
      this.props.editStaus !== "error" &&
      nextProps.editStaus === "error"
    ) {
      const errorObj = getErrorMessage(nextProps.error);
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetEditVenue();
    } else if (
      this.props.match.params.venueId !== nextProps.match.params.venueId
    ) {
      const authKey = localStorage.getItem("x-auth-key");
      this.props.setSelectedLocationById(
        nextProps.venue.location_id,
        authKey,
        ws_id
      );
    }
  }
  onValidSubmit(data) {
    const stop =
      data.pl_location.indexOf(":") === -1
        ? data.pl_location.length
        : data.pl_location.indexOf(":");
    console.log(this.props);
    const ws_id = this.props.workspace.activeWorkspace.id;
    const pl_company = this.props.workspace.workspaces[ws_id].cnc_company;
    const inputValues = {
      ...data,
      pl_location: data.pl_location.substring(0, stop),
      pl_company,
    };
    const authKey = localStorage.getItem("x-auth-key");
    this.props.editVenue(
      this.props.match.params.venueId,
      inputValues,
      authKey,
      ws_id
    );
  }
  onChangeCountry(event) {
    this.setState({ selectedCountry: event.target.value, countryError: {} });
  }

  onCancel() {
    this.props.history.push("/app/venues/list");
  }

  onLocationChange() {
    this.setState({
      selectedLocation: this.props.selectedLocation.location_id,
    });
  }

  preventFormSubmitOnEnter(e) {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }

  renderOptionsCountryModified(opt, label) {
    // console.log('List: ', opt);
    const countries = opt.slice();
    countries.unshift({ id: "", country_name: T("Select a Country") });
    return _.map(countries, (option) => (
      <option key={option.id} value={option.id}>
        {" "}
        {option[label]}{" "}
      </option>
    ));
  }

  render() {
    //console.log('Venue edit rendered');
    // console.log('PROPS LOCATION: ', this.props);
    // if (this.props.venue.pl_location && !this.state.selectedLocation){
    //       this.setState({ selectedLocation: this.props.venue.pl_location});
    // }
    const inputStyle = { minWidth: "300px" };
    const { venue } = this.props.location.state;
    return (
      <Grid columns="2" stackable style={{ marginBottom: "300px" }}>
        <Grid.Column>
          <Segment style={{ marginBottom: "300px" }}>
            <h3>{T("Edit Venue")}</h3>
            <Form
              onValidSubmit={this.onValidSubmit.bind(this)}
              ref={(ref) => (this.form = ref)}
            >
              <strong> ID: {venue.place_id} </strong>
              <Form.Group>
                <Form.Input
                  required
                  value={venue.pl_name}
                  type="text"
                  name="pl_name"
                  label={T("Name")}
                  width={6}
                  style={{ ...inputStyle }}
                  onKeyPress={this.preventFormSubmitOnEnter}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  required
                  type="number"
                  value={venue.pl_parent}
                  name="pl_parent"
                  label={T("Parent")}
                  width={6}
                  style={{ ...inputStyle }}
                  readOnly
                  onKeyPress={this.preventFormSubmitOnEnter}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  name="pl_location"
                  label={T("Map Location")}
                  width={6}
                  style={{ ...inputStyle }}
                  readOnly
                  type="string"
                  // onChange={this.onLocationChange}
                  value={
                    this.props.selectedLocation
                      ? `${this.props.selectedLocation.location_id}: ${this.props.selectedLocation.lo_name}`
                      : (venue.pl_location &&
                          `${venue.pl_location} : ${venue.location}`) ||
                        ""
                  }
                  onKeyPress={this.preventFormSubmitOnEnter}
                  action={
                    <ModalWindow
                      buttonColor="blue"
                      buttonSize="tiny"
                      buttonLabel={T("Select Location")}
                      header={T("Select Location")}
                    >
                      <ModalContext.Consumer>
                        {({ closeModal }) => (
                          <LocationList
                            closeModal={closeModal}
                            setLocation={this.props.selectedEditVenueLocation}
                          />
                        )}
                      </ModalContext.Consumer>
                    </ModalWindow>
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  required
                  name="pl_address"
                  label={T("Address")}
                  width={6}
                  style={{ ...inputStyle }}
                  value={venue.pl_address}
                  onKeyPress={this.preventFormSubmitOnEnter}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  required
                  value={venue.pl_zip}
                  name="pl_zip"
                  label={T("Zip")}
                  width={6}
                  style={{ ...inputStyle }}
                  onKeyPress={this.preventFormSubmitOnEnter}
                />
                <Form.Input
                  required
                  type="text"
                  value={venue.pl_city}
                  name="pl_city"
                  label={T("City")}
                  width={6}
                  style={{ ...inputStyle }}
                  onKeyPress={this.preventFormSubmitOnEnter}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  type="text"
                  value={venue.pl_phone}
                  name="pl_phone"
                  label={T("Phone")}
                  width={6}
                  style={{ ...inputStyle }}
                  onKeyPress={(e) => {
                    this.preventFormSubmitOnEnter(e);
                  }}
                />
                <Form.Input
                  name="pl_email"
                  type="text"
                  value={venue.pl_email}
                  label={T("Email")}
                  width={6}
                  style={{ ...inputStyle }}
                  onKeyPress={(e) => {
                    this.preventFormSubmitOnEnter(e);
                  }}
                />
              </Form.Group>
              <Button content={T("Save")} color="green" icon="save" />
              <Button
                type="button"
                color="black"
                content={T("Cancel")}
                onClick={() => this.onCancel()}
              />
            </Form>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <SubVenues parentVenue={venue} />
        </Grid.Column>
      </Grid>
    );
  }
}
function mapStateToProps({ countries, venues, locations, workspace }) {
  return {
    countries,
    // venues,
    // fetchStatus: venues.operationState.fetch,
    venue: venues.venue,
    countriesStatus: countries.status,
    // locationStatus: venues.createStatus,
    editStaus: venues.operationState.update,
    selectedLocation: locations.selectedEditVenueLocation,
    error: venues.error,
    workspace,
  };
}

export default connect(mapStateToProps, {
  //fetchCountries,
  fetchVenue,
  editVenue,
  resetEditVenue,
  selectedEditVenueLocation,
  resetselectedEditVenueLocation,
})(withRouter(VenueEditInput));

import _ from "lodash";
import React, { Component } from "react";
import { Table, Header, Divider, Button } from "semantic-ui-react";
import { T } from "../Common/Helpers";
import TranslationItemManager from "./TranslationItemManager";

class TranslationList extends Component {
  renderOld() {
    return (
      <>
        <Header> {T("Translations")} </Header>
        <Divider />
        <TranslationItemManager
          languages={this.props.language}
          translationitems={this.props.translationitems}
          onRemove={this.props.onRemove}
          workspace={this.props.workspace}
          ws_id={this.props.ws_id}
          module_id={this.props.module_id}
        />
      </>
    );
  }

  renderTranslationList() {
    const data = this.props.translationitems;
    const isEditable = true;
    //const isEditable = lo.lo_status !== 'draw';
    //const canCancel = lo.lo_status !== 'draft';
    //const canCancel = true;
    const cursor = isEditable ? { cursor: "pointer" } : { cursor: "default" };
    return _.map(data, (translation) => {
      return (
        <Table.Row
          key={translation.gtr_language}
          onClick={() => this.props.onGotoLanguage(translation.gtr_language)}
          style={cursor}
          warning={isEditable}
        >
          <Table.Cell>{translation.gtr_name}</Table.Cell>
          <Table.Cell>{translation.gtr_description}</Table.Cell>
          <Table.Cell>{translation.gtr_alpha_2}</Table.Cell>
          <Table.Cell collapsing textAlign="right">
            <Button
              type="button"
              color="red"
              icon="trash"
              size="small"
              content={T("Remove translation")}
              onClick={() =>
                this.props.onRemoveTranslation(
                  translation.gtr_language,
                  translation.gtr_alpha_2
                )
              }
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    return (
      <div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{T("Name")}</Table.HeaderCell>
              <Table.HeaderCell>{T("Description")}</Table.HeaderCell>
              <Table.HeaderCell>{T("Language")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderTranslationList()}</Table.Body>
        </Table>
      </div>
    );
  }
}

export default TranslationList;

/* eslint-env browser */
/* ************************************ */
/*  onChange(primary, secondary, bgcolor)
/* ************************************ */
import React, { Component } from "react";
import styled from "styled-components";
import { Form } from "formsy-semantic-ui-react";
import { SketchPicker } from "react-color";
import Color from "color";
import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";
//import colorString from 'color-string';
import { T } from "../Common/Helpers";

const Colorbox = styled.div`
  background-color: ${(props) => props.color};
  color: ${(props) => props.textColor};
  padding: 4px;
  width: 50%;
  display: flex;
`;
const SelectColorBox = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.color};
  border: ${(props) =>
    props.active ? "2px solid #CBA84A" : "1px solid black"};
  color: ${(props) => props.textColor};
  padding: 5px;
  display: block;
  width: 110px;
  height: 170px;
  /* border-radius: 4px; */
  fontFamily: 'Nunito'
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  /* :hover {
     border: ${(props) => (props.active ? "2px solid red" : "1px solid grey")};
     font-weight: bold;
   } */
`;
const Palette = styled.div`
  display: flex;
  flex-direction: row;
  top: 62px;
  position: absolute;
  z-index: 1;
`;

const PaletteButtons = styled.div`
  display: flex;
  height: 40px;
`;

const HidAlpha = styled.div`
  top: ${(props) => props.Top};
  left: ${(props) => props.Left};
  width: ${(props) => props.Width};
  height: ${(props) => props.Height};
  background-color: white;
  color: black;
  position: absolute;
  z-index: 1;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
`;

const ColorBasket = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  height: 200px;
  width: 200px;
  flex-direction: row;
  background-color: ${(props) => props.background};
  border: ${(props) => (props.active ? "2px solid" : "1px solid")}
    ${(props) => props.color};
  border-radius: 0px;
  /* box-shadow: ${(props) =>
    props.active ? `0px 0px 2px ${props.color}` : "0px 0px 0px black"}; */
  cursor: pointer;
  /* :hover {
     box-shadow: ${(props) =>
    props.active ? `0px 0px 4px ${props.color}` : "0px 0px 4px black"};
     font-weight: bold;
   } */
`;

// const Container = styled.div`
//   display: flex;
//   width: 100%;
//   flex-direction: row;
// `;

class ColorPicker extends Component {
  constructor(props) {
    super(props);
    const brandColor3 = this.props.brandColor3 || "#000000";
    const pColor = this.props.brandColor1 || "#0000ff";
    const sColor = this.props.brandColor2 || "#00ff00";
    const kColor = Color(brandColor3).luminosity() < 0.5 ? "black" : "white";
    this.appColor = { pColor, sColor, kColor };

    this.onCLick = this.onClick.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // console.log('Color Props: ', this.props);
    this.state = {
      pColor: {
        color: pColor,
      },
      sColor: {
        color: sColor,
      },
      TextLight: {
        color: "#FFC72C",
      },
      TextDark: {
        color: "#ee1122",
      },
      kColor,
      editing: "none",
      submit: false,
      excolor: "#000000",
    };
  }
  componentDidMount() {
    // this.props.onChange(this.state.pColor.color, this.state.sColor.color, this.state.kColor);
  }

  // componentDidUpdate(prevProps, prevState) {
  // // few sanity checks
  // if (this.state !== prevState && prevState.submit !== true && this.state.submit === true) {
  //   console.log('COLOR picker component updated\nThis.State: ', this.state, '\nPrev State: ', prevState);
  //   const { pColor, sColor, kColor } = this.state;
  //   if (pColor.color !== this.appColor.pColor) {
  //     console.log('pColor update');
  //     this.appColor.pColor = pColor.color;
  //     this.props.onComponentValueUpdate('brandColor1', pColor.color);
  //   }
  //   if (sColor.color !== this.appColor.sColor) {
  //     console.log('sColor update');
  //     this.appColor.sColor = sColor.color;
  //     this.props.onComponentValueUpdate('brandColor2', sColor.color);
  //   }
  //   if (kColor.color !== this.appColor.kColor) {
  //     console.log('kColor update');
  //     this.appColor.kColor = kColor.color;
  //     this.props.onComponentValueUpdate('brandColor3', kColor.color);
  //   }
  // }
  // }

  onCancel(e) {
    const newState = {
      ...this.state,
      [e]: { color: this.state.excolor },
      editing: "none",
      submit: false,
    };
    this.setState(newState);
    return;
  }

  onSubmit() {
    const newState = { ...this.state, editing: "none", submit: true };
    this.setState(newState);
    this.updateColors(this.state);
    return;
  }

  onClick(e) {
    // console.log('color change onClick: \nstate: ', this.state, '\nClass Variable: ', this.appColor);
    const val = this.state.kColor === "white" ? "black" : "white";
    const newState = {
      ...this.state,
      submit: true,
      [e === "key" ? "kColor" : "editing"]: e === "key" ? val : e,
      excolor: e === "key" ? this.state.excolor : this.state[e].color,
    };
    if (e === "key") {
      this.updateColors({ ...this.state, kColor: val });
    }
    this.setState(newState);
    return;
  }
  updateColors = (state) => {
    // few sanity checks
    const { pColor, sColor, kColor } = state;
    if (pColor.color !== this.appColor.pColor) {
      // console.log('pColor update');
      this.appColor.pColor = pColor.color;
      this.props.onComponentValueUpdate("brandColor1", pColor.color);
    }
    if (sColor.color !== this.appColor.sColor) {
      // console.log('sColor update');
      this.appColor.sColor = sColor.color;
      this.props.onComponentValueUpdate("brandColor2", sColor.color);
    }
    if (kColor.color !== this.appColor.kColor) {
      // console.log('kColor update');
      this.appColor.kColor = kColor;
      this.props.onComponentValueUpdate("brandColor3", kColor);
    }
  };
  handleChange(color) {
    this.setState({
      ...this.state,
      [this.state.editing]: {
        ...this.state[this.state.editing],
        color: color.hex,
      },
    });
  }

  render() {
    const white = Color("#FFFFFF").darken(0.06).rgb();
    const black = Color("#FFFFFF").darken(0.14).negate().rgb();
    const ltext = Color("#FFFFFF").darken(0.06).rgb();
    const dtext = Color("#FFFFFF").darken(0.14).negate().rgb();
    const colorp = Color(this.state.pColor.color);
    const colors = Color(this.state.sColor.color);
    const colora =
      colorp.luminosity() > 0.5
        ? colorp.darken(0.2).rgb()
        : colorp.lighten(0.2).rgb();
    const colorb =
      colors.luminosity() > 0.5
        ? colors.darken(0.2).rgb()
        : colors.lighten(0.2).rgb();
    return (
      <div>
        <Form size="large" name="colors">
          <Form.Group widths="equal">
            <Form.Field>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  minWidth: "300px",
                  marginBottom: "50px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <ColorBasket
                    onClick={() =>
                      this.state.editing === "pColor"
                        ? this.onSubmit()
                        : this.onClick("pColor")
                    }
                    color={this.state.pColor.color}
                    background={
                      this.state.kColor === "white" ? white.hex() : black.hex()
                    }
                    active={this.state.editing === "pColor"}
                  >
                    <Colorbox
                      name="pColor"
                      textColor={colorp.isLight() ? dtext.hex() : ltext.hex()}
                      color={this.state.pColor.color}
                    />
                    <Colorbox
                      name="pColor"
                      textColor={colora.isLight() ? dtext.hex() : ltext.hex()}
                      color={colora.hex()}
                    />
                  </ColorBasket>
                  {this.state.editing === "pColor" ? (
                    <Palette>
                      <HidAlpha
                        Top={"174px"}
                        Left={"2px"}
                        Width={"182px"}
                        Height={"16px"}
                      >
                        &nbsp;Primary Color
                      </HidAlpha>
                      <HidAlpha
                        Top={"196px"}
                        Left={"180px"}
                        Width={"32px"}
                        Height={"40px"}
                      >
                        &nbsp;
                      </HidAlpha>
                      <div>
                        <SketchPicker
                          color={this.state[this.state.editing].color}
                          onChangeComplete={this.handleChange}
                        />
                      </div>
                      <PaletteButtons>
                        <Button
                          onClick={() => this.onSubmit()}
                          icon={{ color: "green", name: "check" }}
                        />
                        <Button
                          onClick={() => this.onCancel("pColor")}
                          icon={{ color: "red", name: "times" }}
                        />
                      </PaletteButtons>
                    </Palette>
                  ) : (
                    <div />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      fontSize: "20px",
                      fontWeight: 600,
                    }}
                  >
                    {T("Primary color")}
                  </div>
                  <div style={{ display: "block", textAlign: "left" }}>
                    {T("Select the primary color of your app.")}
                  </div>
                  <div>
                    <Button
                      style={{
                        color: "white",
                        background: "#CBA84A",
                        borderRadius: "0",
                        width: "200px",
                      }}
                      content={T("SELECT")}
                      onClick={() =>
                        this.state.editing === "pColor"
                          ? this.onSubmit()
                          : this.onClick("pColor")
                      }
                    />
                  </div>
                </div>
              </div>
            </Form.Field>
            <Form.Field>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  minWidth: "300px",
                  marginBottom: "50px",
                }}
              >
                <div style={{ display: "flex", marginRight: "10px" }}>
                  <ColorBasket
                    onClick={() =>
                      this.state.editing === "sColor"
                        ? this.onSubmit()
                        : this.onClick("sColor")
                    }
                    color={this.state.sColor.color}
                    background={
                      this.state.kColor === "white" ? white.hex() : black.hex()
                    }
                    active={this.state.editing === "sColor"}
                  >
                    <Colorbox
                      name="sColor"
                      textColor={colors.isLight() ? dtext.hex() : ltext.hex()}
                      color={this.state.sColor.color}
                    />
                    <Colorbox
                      name="sColor"
                      textColor={colorb.isLight() ? dtext.hex() : ltext.hex()}
                      color={colorb.hex()}
                    />
                  </ColorBasket>
                  {this.state.editing === "sColor" ? (
                    <Palette>
                      <HidAlpha
                        Top={"174px"}
                        Left={"2px"}
                        Width={"182px"}
                        Height={"16px"}
                      >
                        &nbsp;Secondary Color
                      </HidAlpha>
                      <HidAlpha
                        Top={"196px"}
                        Left={"180px"}
                        Width={"32px"}
                        Height={"40px"}
                      >
                        &nbsp;
                      </HidAlpha>
                      <div>
                        <SketchPicker
                          color={this.state[this.state.editing].color}
                          onChangeComplete={this.handleChange}
                        />
                      </div>
                      <PaletteButtons>
                        <Button
                          onClick={() => this.onSubmit()}
                          icon={{ color: "green", name: "check" }}
                        />
                        <Button
                          onClick={() => this.onCancel("sColor")}
                          icon={{ color: "red", name: "times" }}
                        />
                      </PaletteButtons>
                    </Palette>
                  ) : (
                    <div />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      fontSize: "20px",
                      fontWeight: 600,
                    }}
                  >
                    {T("Secondary color")}
                  </div>
                  <div style={{ display: "block", textAlign: "left" }}>
                    {T("Select the secondary color of your app.")}
                  </div>
                  <div>
                    <Button
                      style={{
                        color: "white",
                        background: "#CBA84A",
                        borderRadius: "0",
                        width: "200px",
                      }}
                      content={T("SELECT")}
                      onClick={() =>
                        this.state.editing === "sColor"
                          ? this.onSubmit()
                          : this.onClick("sColor")
                      }
                    />
                  </div>
                </div>
              </div>
              {/*
          <Box>
          <Form.Input name="primary" label={this.state.editing === 'pColor' ? T('Change the primary color') : T('Change the secondary color')}>
          <CompactPicker
            name="pColor"
            color={ this.state[this.state.editing].color }
            onChangeComplete={ this.handleChange }
          />
          </Form.Input>
          </Box>*/}
            </Form.Field>
            <Form.Field>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  minWidth: "300px",
                  marginTop: "20px",
                  marginBottom: "50px",
                }}
              >
                <div style={{ display: "flex", marginRight: "10px" }}>
                  <SelectColorBox
                    name="white"
                    // onClick={() => this.onClick('key')}
                    textColor={dtext.hex()}
                    color={white.hex()}
                    active={this.state.kColor === "white"}
                  />
                  <SelectColorBox
                    name="black"
                    // onClick={() => this.onClick('key')}
                    textColor={ltext.hex()}
                    color={black.hex()}
                    active={this.state.kColor === "black"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ fontSize: "20px", fontWeight: 600 }}>
                    {" "}
                    {T("Background color")}{" "}
                  </div>
                  <div> {T("Select the background of your app")} </div>
                  <div>
                    <Button
                      style={{
                        color: "white",
                        background: "#CBA84A",
                        borderRadius: "0",
                        width: "200px",
                      }}
                      content={T("TOGGLE")}
                      onClick={() => this.onClick("key")}
                    />
                  </div>
                </div>
              </div>
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

ColorPicker.propTypes = {
  brandColor1: PropTypes.string,
  brandColor2: PropTypes.string,
  brandColor3: PropTypes.string,
  onComponentValueUpdate: PropTypes.func,
};

export default ColorPicker;

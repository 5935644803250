import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import { T } from "../Common/Helpers";

class TranslationItem extends Component {
  render() {
    const { translation } = this.props;

    return (
      <div>
        <Form.Group widths="equal" style={{ marginBottom: "10px" }}>
          <Form.Input
            name="gtr_language"
            type="hidden"
            value={translation.gtr_language}
            //onChange={(e, { name, value }) => this.props.onUpdatePollOption(cms_content_id, name, value)}
          />
          <Form.Input
            name="gtr_name"
            type="text"
            label={T("Name")}
            value={translation.gtr_name}
          />
          <Form.Input
            name="gtr_description"
            type="text"
            label={T("Description")}
            value={translation.gtr_description}
          />
          <Form.Input
            name="gtr_alpha_2"
            type="text"
            label={T("Language")}
            value={translation.gtr_alpha_2}
          />
          <Button
            icon="trash"
            content={T("Delete translation")}
            color="red"
            onClick={() => this.props.onRemove(translation.gtr_language)}
          />
        </Form.Group>
      </div>
    );
  }
}

export default TranslationItem;

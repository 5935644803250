import Moment from 'moment';

export const createMoment = ({
  eventId,
  performerId,
  description = null,
  key,
  value,
  period,
  timestamp = Moment(),
}) => {
  return {
    moment_id: null,
    mt_type: 'SosEvent',
    mt_description: description,
    mt_timestamp: timestamp.toISOString(),
    mt_active: true,
    mt_performer: performerId,
    mt_key: key,
    mt_value: value,
    mt_period: period,
    mt_walltime: timestamp.format('HH:mm'),
    mt_code: null,
    mt_parent: null,
    mt_event: eventId,
  };
};

class PeriodPair {
  start = {};
  stop = {};

  constructor(start, stop) {
    this.start = start.start;
    this.stop = stop;
  }
}
export const getEventState = ({ event, moments, isInEdit = false }) => {
  let isOnBreak;
  let isArchived = false;
  let isEditable = true;
  let currentPeriod = 1;
  let periodPairs = [];
  let hakeTimestamp = null;
  let maxPeriod = 0;

  // console.log("getEventState isInEdit", isInEdit);
  moments
    .sort((m_a, m_b) => {
      const periodDiff = m_a.mt_period - m_b.mt_period;
      if (periodDiff !== 0) {
        // order by period number if different periods
        return periodDiff;
      }
      // if the compared events are within same period, make sure timestamped events are in the correct order,
      // we don't care about the order of the rest, they just randomly go in between
      const samePeriodOrder = {
        EventStart: 0,
        BreakStart: 1,
        EditStart: 2,
        EditStop: 4,
        BreakStop: 5,
        EventStop: 6,
      };
      const aOrder = samePeriodOrder[m_a.mt_key] || 3;
      const bOrder = samePeriodOrder[m_b.mt_key] || 3;
      return bOrder - aOrder;
    })
    .forEach(moment => {
      // console.log(`state determine moment `, moment);
      const { mt_key, mt_period } = moment;
      if (
        !periodPairs[mt_period] &&
        (mt_key.startsWith('Event') || mt_key.startsWith('Break'))
      ) {
        periodPairs[mt_period] = new PeriodPair({}, {});
        if (mt_period > maxPeriod) {
          maxPeriod = mt_period;
        }
      }
      switch (mt_key) {
        case 'Archival': {
          isArchived = true;
          const archivalTimestamp = Moment(moment.mt_timestamp);
          const archivalHours = Moment.duration(
            Moment().diff(archivalTimestamp)
          ).hours();
          // console.log('archival moment', moment)
          // console.log('archivalTimestamp', archivalTimestamp.format('l HH:mm'))
          // console.log('archivalHours', archivalHours)
          if (archivalHours > 12) {
            isEditable = isInEdit; // we don't want this to change if we are already editing
          }
          break;
        }
        case 'EventStart': {
          // matches event.eventStart
          isOnBreak = false;
          isArchived = false;
          periodPairs[mt_period].start = moment;
          break;
        }
        case 'BreakStart': {
          isOnBreak = true;
          periodPairs[mt_period].stop = moment;
          break;
        }
        case 'BreakStop': {
          isOnBreak = false;
          periodPairs[mt_period].start = moment;
          break;
        }
        case 'EventStop': {
          // matches event.eventStop
          isOnBreak = false;
          isArchived = true;
          periodPairs[mt_period].stop = moment;
          break;
        }
        case 'ERC': {
          hakeTimestamp = moment.mt_timestamp;
          if (Moment(hakeTimestamp).format('HH:mm') !== moment.mt_value) {
            // fix häke timestamp
            console.log('häke needs fixing', moment);
            if (moment.mt_value !== '') {
              let timeMoment = Moment(moment.mt_value, 'HH:mm');
              let dateMoment = Moment(hakeTimestamp);
              dateMoment.set({
                hour: timeMoment.get('hour'),
                minute: timeMoment.get('minute'),
                second: timeMoment.get('second'),
              });

              if (Moment(event.ev_start).isBefore(dateMoment)) {
                dateMoment = dateMoment.subtract(1, 'days');
              }
              // console.log(
              //   "===> dateMoment",
              //   dateMoment.format("YYYY-MM-DD HH:mm")
              // );
              moment.mt_timestamp = dateMoment.toDate();
            }
          }
          break;
        }
        default:
          break;
      }
      if (mt_period > currentPeriod) {
        currentPeriod = mt_period;
      }
    });
  return {
    isOnBreak,
    isArchived,
    currentPeriod,
    periodPairs,
    hakeTimestamp,
    maxPeriod,
    isEditable,
  };
};

export const getArchivalMoment = moments => {
  const archival = moments.find(m => m.mt_key === 'Archival');
  if (archival) {
    return archival;
  }
  return moments.find(m => m.mt_key === 'EventStop');
};

export const getArchivalTimestamp = moments => {
  const moment = getArchivalMoment(moments);
  if (moment) {
    return moment.mt_timestamp;
  }
  return null;
};

export const updateArchivalTimestamp = ({
  moments,
  workerid,
  currentPeriod,
}) => {
  const moment = getArchivalMoment(moments);
  if (moment) {
    // is archived if either moment found
    if (moment.mt_key === 'Archival') {
      console.log('updating archival time');
      const currentDate = new Date();
      moment.mt_timestamp = currentDate;
      moment.mt_walltime = Moment(currentDate).format('HH:mm');
    } else {
      // fix broken events
      moments.push(
        createMoment({
          mt_performerId: workerid,
          mt_key: 'Archival',
          mt_value: `${currentPeriod}`,
          mt_period: currentPeriod,
        })
      );
    }
  }
};

export const getMetadataValue = ({ event, emKey }) => {
  if (!event.eventmetadata) {
    return null;
  }
  const metadata = event.eventmetadata.find(em => em.em_key === emKey);
  if (metadata) {
    return metadata.em_value;
  }
  return null;
};

export const getEventPerformerByPosition = ({ eventPerformers, position }) => {
  if (!eventPerformers) {
    return null;
  }
  const find = eventPerformers.find(ep => ep.ep_position === position);
  return find;
};

import React, { Component } from 'react';
import { toast } from 'react-semantic-toasts';
import { Header } from 'semantic-ui-react';
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  Area,
  AreaChart,
  Bar,
  ComposedChart,
} from 'recharts';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  fetchAppStatistics,
  resetFetchAppStatistics,
} from '../../../Redux/actions';
import AppStatisticSummary from './AppStatisticSummary';
import ReportDateRange from '../Common/ReportDateRange';
//import RechartCustomTooltip from '../Common/RechartCustomTooltip';
import { Spinner } from '../../Common/Spinner';

//import { Message } from 'semantic-ui-react';
//import { fetchMomentsOptions } from '../../../Redux/actions';
import { T, getErrorMessage } from '../../Common/Helpers';

const MODULE_ID = 40;
const STATIC_TYPE = 'app';
const SLG_EVENT = 'foreground';
//const NUMBER_OF_OPENINGS = "Number of Openings";
const APP_LAUNCHED = 'App Launched';
const UNIQUE_USERS = 'Unique Users';
const IOS = 'iOS';
const ANDROID = 'Android';
/*const TOOLTIPS = [
  "How many times app has been opened",
  "How many unique users have using the app",
  "How many times app has been opend from iOS phones",
  "How many times app has been opened from Android phones"
];*/

const styles = {
  baseStat: {
    width: '100%',
    height: '500px',
  },

  statHeader: {
    align: 'center',
    border: 'solid red 1px',
  },

  statContent: {
    width: '100%',
    height: '100%',
  },

  containerTotals: {
    border: 'solid 2px lightgray',
    height: 'auto',
    margin: '2%',
    marginBottom: '3em',
  },

  contentTotals: {
    justifyContent: 'left',
    alignItems: 'left',
    margin: '1%',
  },

  headerTotals: {
    marginBottom: '3px',
  },

  underLine: {
    textDecoration: 'underline solid 2px',
  },
};

class AppStatisticManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubItem: 'month',
      start: null,
      end: null,
      selectedValue: 0,
    };
  }

  componentDidMount() {
    this.props.resetFetchAppStatistics();
  }

  componentDidUpdate() {
    if (this.props.appStatistics.appStatisticStatus === 'error') {
      const errorObj = getErrorMessage(this.props.appStatistics.error);
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(`${errorObj.status}: ${errorObj.message}`),
        time: 5000,
      });
    }
  }

  onFetchStatistics(start, end, activeSubItem) {
    this.setState({ activeSubItem, start, end });
    const authKey = localStorage.getItem('x-auth-key');
    const wsId = this.props.ws_id;
    const applicationId = this.props.activeWorkspace.cnc_application;
    const startdate = moment(start, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const enddate = moment(end, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const status = this.props.appStatistics.appStatisticStatus;
    const lang = this.props.auth.lang;

    if (status !== 'waiting' && status !== 'error') {
      this.props.fetchAppStatistics(
        MODULE_ID,
        authKey,
        wsId,
        applicationId,
        STATIC_TYPE,
        SLG_EVENT,
        startdate,
        enddate,
        lang
      );
    }
  }

  /**
   * A function that takes in a start date, end date, and a type. It then creates a range of dates between the start and end date.
   */
  getRange = (startDate, endDate, type) => {
    let fromDate = moment(startDate);
    let toDate = moment(endDate).add(1, 'days');
    let diff = toDate.diff(fromDate, type);
    let range = [];
    for (let i = 0; i < diff; i++) {
      range.push(moment(startDate).add(i, type));
    }
    return range;
  };

  renderTotals(totals) {
    return (
      <div style={styles.containerTotals}>
        <div style={styles.contentTotals}>
          <div style={styles.headerTotals}>
            <h4 style={styles.underLine}>{T('Totals')}</h4>
          </div>
          <div>
            <label>{T('Downloads')}: </label>
            {totals.total_downloads}
            <br />
            <label>{T('Unique Users')}: </label>
            {totals.total_unique_users}
            <br />
            <label>{T('Used Operating Systems')}: </label>
            <br />
            <div style={{ marginLeft: '20px' }}>
              <label>- {T('IOS')}: </label>
              {totals.total_os.iOS}
              <br />
              <label>- {T('Android')}: </label>
              {totals.total_os.Android}
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderChart(data) {
    if (data.length === 0) {
      return <span>{T('No data')}</span>;
    }
    const dateRange = this.getRange(
      moment(data[0].year_month_date, 'YYYY-MM-DD'),
      moment(data[data.length - 1].year_month_date, 'YYYY-MM-DD'),
      'days'
    );
    const items = dateRange.map(d => {
      const find_date = d.format('YYYY-MM-DD');
      const dateData = data.find(dmy => dmy.year_month_date === find_date) || {
        [IOS]: 0,
        [ANDROID]: 0,
        [T(APP_LAUNCHED)]: 0,
      };
      return {
        ...dateData,
        year_month_date: d.format('l'),
      };
    });
    return (
      <div>
        <div style={styles.baseStat}>
          <div style={styles.statContent}>
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={500}
                height={400}
                data={items}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid />
                <XAxis dataKey="year_month_date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey={IOS}
                  stroke="none"
                  fillOpacity={1}
                  fill="#1e1d1d"
                  stackId="1"
                />
                <Bar
                  dataKey={ANDROID}
                  fillOpacity={1}
                  stroke="none"
                  fill="#aec549"
                  stackId="1"
                />
                <Line
                  type="monotone"
                  dataKey={T(APP_LAUNCHED)}
                  stroke="#8884d8"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }

  showToolTip = e => {
    // console.log('showTooltip');
    let x = Math.round(e.cx);
    let y = Math.round(e.cy);
    // console.log('x', x, 'y', y);
    this.tooltip.style.opacity = '1';
    this.tooltip.childNode[0].innerHTML = e.payload['value'];
  };

  hideTooltip = e => {
    this.tooltip.style.opacity = '0';
    // console.log(e);
  };

  render() {
    const isValid =
      this.props.appStatistics.appStatisticStatus === 'success' &&
      this.props.appStatistics.data;

    if (this.props.appStatistics.appStatisticStatus === 'waiting') {
      return <Spinner />;
    }

    return (
      <>
        <Header dividing as="h3">
          {T('App Statistics')}
        </Header>
        <ReportDateRange
          onSendDateRange={this.onFetchStatistics.bind(this)}
          activeSubItem={this.state.activeSubItem}
          //start = { this.state.activeSubItem === 'custom' ? this.state.start : null}
          //end = { this.state.activeSubItem === 'custom' ? this.state.end : null}
          start={this.state.start ? this.state.start : null}
          end={this.state.end ? this.state.end : null}
        />

        {isValid ? (
          <AppStatisticSummary
            totals={this.props.appStatistics.data.totals}
            date_range_totals={this.props.appStatistics.data.date_range_totals}
          />
        ) : null}
        {isValid ? this.renderChart(this.props.appStatistics.data.items) : null}
      </>
    );
  }
}

function mapStateToProps({ workspace, languages, statistics, auth }) {
  return {
    ws_id: workspace.ws_id,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    appStatistics: statistics,
    langStatus: languages.status,
    languages: languages.languages,
    auth,
  };
}
export default connect(mapStateToProps, {
  fetchAppStatistics,
  resetFetchAppStatistics,
})(AppStatisticManager);

import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { T } from '../Common/Helpers';

class MessageToggler extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      commandText: '',
      isLess: true,
    };
    this.toggleMessageContent = this.toggleMessageContent.bind(this);
  }

  componentDidMount() {
    const { message, messageLength, maxLength, isLess } = this.props;
    if (messageLength <= maxLength) {
      this.setState({
        message,
        commandText: '',
      });
    } else {
      this.toggleMessageContent(null, message, maxLength, isLess);
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    //ugly fix for update
    if (this.props.message !== nextProps.message) {
      if (nextProps.message.length <= nextProps.maxLength) {
        this.setState({
          message: nextProps.message,
          commandText: '',
        });
      } else {
        this.toggleMessageContent(
          null,
          nextProps.message,
          nextProps.maxLength,
          nextProps.isLess
        );
      }
    }
  }

  toggleMessageContent(e, message, maxLength, isLess) {
    if (e) {
      e.stopPropagation();
    }
    if (isLess === true) {
      const msg = message.substring(0, maxLength);
      const commandText = ` ... ${T('read more')}`;
      this.setState({
        message: msg,
        commandText,
        isLess: !isLess,
      });
    } else if (isLess === false) {
      const commandText = ` ${T('read less')}`;
      this.setState({
        message,
        commandText,
        isLess: !isLess,
      });
    }
  }
  render() {
    const { message, maxLength } = this.props;
    const { isLess } = this.state;
    return (
      <div>
        <p style={{ wordBreak: 'break-all' }}>{this.state.message}</p>
        <p
          style={{
            display: 'inline',
            color: 'blue',
            cursor: 'pointer',
          }}
          onClick={e =>
            this.toggleMessageContent(e, message, maxLength, isLess)
          }
        >
          {this.state.commandText}
        </p>
      </div>
    );
  }
}

MessageToggler.propTypes = {
  message: propTypes.string.isRequired,
  messageLength: propTypes.number.isRequired,
  maxLength: propTypes.number.isRequired,
  isLess: propTypes.bool,
};
export default MessageToggler;

import _ from 'lodash';
import {
  FETCH_CMS_CONTENT_SUCCESS,
  FETCH_CMS_CONTENT_WAITING,
  FETCH_CMS_CONTENT_ERROR,
  FETCH_CMS_CONTENT_RESET,
  EDIT_CMS_CONTENT_WAITING,
  EDIT_CMS_CONTENT_SUCCESS,
  EDIT_CMS_CONTENT_ERROR,
  EDIT_CMS_CONTENT_RESET,
  ADD_CMS_CONTENT_WAITING,
  ADD_CMS_CONTENT_SUCCESS,
  ADD_CMS_CONTENT_ERROR,
  ADD_CMS_CONTENT_RESET,
  DELETE_CMS_CONTENT_WAITING,
  DELETE_CMS_CONTENT_SUCCESS,
  DELETE_CMS_CONTENT_ERROR,
  DELETE_CMS_CONTENT_RESET,
  CMS_CONTENT_TYPES_WAITING,
  CMS_CONTENT_TYPES_SUCCESS,
  CMS_CONTENT_TYPES_ERROR,
  CMS_CONTENT_TYPES_RESET,
  FETCH_CMS_CONTENT_CHILDREN_WAITING,
  FETCH_CMS_CONTENT_CHILDREN_SUCCESS,
  FETCH_CMS_CONTENT_CHILDREN_ERROR,
  FETCH_CMS_CONTENT_CHILDREN_RESET,
  SAVE_CMS_CONTENTS_WAITING,
  SAVE_CMS_CONTENTS_SUCCESS,
  SAVE_CMS_CONTENTS_ERROR,
  SAVE_CMS_CONTENTS_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  fetchStatus: 'init',
  addStatus: 'init',
  editStatus: 'init',
  deleteStatus: 'init',
  ctypeStatus: 'init',
  ctypes: '',
  contentChildren: '',
  childStatus: 'init',
  saveStatus: 'init',
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_CMS_CONTENT_WAITING: {
      return { ...state, fetchStatus: 'waiting' };
    }
    case FETCH_CMS_CONTENT_SUCCESS:
      return {
        ...state,
        dataSet: _.mapKeys(action.payload.data, 'cms_content_id'),
        headers: action.payload.headers,
        fetchStatus: 'success',
      };
    case FETCH_CMS_CONTENT_ERROR: {
      return { ...state, fetchStatus: 'error', error: action.payload.error };
    }
    case FETCH_CMS_CONTENT_RESET: {
      return { ...INIT_STATE };
    }

    case ADD_CMS_CONTENT_WAITING: {
      return { ...state, addStatus: 'waiting', newlyAddedCMSId: null };
    }
    case ADD_CMS_CONTENT_SUCCESS: {
      const cmsContent = {
        ...state.dataSet,
        [action.payload.data.cms_content_id]: action.payload.data,
      };
      return {
        ...state,
        dataSet: cmsContent,
        data: action.payload.data,
        addStatus: 'success',
        newlyAddedCMSId: action.payload.data.cms_content_id,
      };
    }
    case ADD_CMS_CONTENT_ERROR:
      return { ...state, addStatus: 'error', error: action.payload.error };
    case ADD_CMS_CONTENT_RESET: {
      return { ...state, addStatus: 'init' };
    }

    case SAVE_CMS_CONTENTS_WAITING: {
      return { ...state, saveStatus: 'waiting' };
    }
    case SAVE_CMS_CONTENTS_SUCCESS: {
      return { ...state, saveStatus: 'success' };
    }
    case SAVE_CMS_CONTENTS_ERROR: {
      return { ...state, saveStatus: 'error', error: action.payload.error };
    }
    case SAVE_CMS_CONTENTS_RESET: {
      return { ...state, saveStatus: 'reset' };
    }

    case DELETE_CMS_CONTENT_WAITING: {
      return { ...state, deleteStatus: 'waiting' };
    }
    case DELETE_CMS_CONTENT_SUCCESS: {
      const dataState = _.omit(
        state.dataSet,
        action.payload.data.cms_content_id
      );
      return { ...state, deleteStatus: 'success', dataSet: dataState };
    }
    case DELETE_CMS_CONTENT_ERROR: {
      return { ...state, deleteStatus: 'error', error: action.payload.error };
    }
    case DELETE_CMS_CONTENT_RESET: {
      return { ...state, deleteStatus: 'init' };
    }

    case EDIT_CMS_CONTENT_WAITING:
      return { ...state, editStatus: 'waiting' };
    case EDIT_CMS_CONTENT_SUCCESS: {
      const cmsContent = {
        ...state.dataSet,
        [action.payload.data.cms_content_id]: action.payload.data,
      };
      return {
        ...state,
        dataSet: cmsContent,
        data: action.payload.data,
        editStatus: 'success',
      };
      //return { ...state, editStatus: 'success' };
    }
    case EDIT_CMS_CONTENT_ERROR:
      return { ...state, editStatus: 'error', error: action.payload.error };
    case EDIT_CMS_CONTENT_RESET:
      return { ...state, editStatus: 'init' };

    case CMS_CONTENT_TYPES_WAITING:
      return { ...state, ctypeStatus: 'waiting' };
    case CMS_CONTENT_TYPES_SUCCESS:
      return {
        ...state,
        ctypes: _.mapKeys(action.payload.data.items, 'datakey'),
        ctypeStatus: 'success',
      };
    case CMS_CONTENT_TYPES_ERROR:
      return { INIT_STATE, ctypeStatus: 'error', error: action.payload.error };
    case CMS_CONTENT_TYPES_RESET: {
      return { ...state, ctypes: '', ctypeStatus: 'init' };
    }

    case FETCH_CMS_CONTENT_CHILDREN_WAITING: {
      return { ...state, childStatus: 'waiting' };
    }
    case FETCH_CMS_CONTENT_CHILDREN_SUCCESS:
      return {
        ...state,
        contentChildren: action.payload.data,
        headers: action.payload.headers,
        childStatus: 'success',
      };
    case FETCH_CMS_CONTENT_CHILDREN_ERROR: {
      return { ...state, childStatus: 'error', error: action.payload.error };
    }
    case FETCH_CMS_CONTENT_CHILDREN_RESET: {
      return { ...state, contentChildren: '', childStatus: 'init' };
    }
    default:
      return state;
  }
}

export const FETCH_PERFORMERS_BY_SERIES_WAITING =
  "fetch_performers_by_seriers_waiting";
export const FETCH_PERFORMERS_BY_SERIES_SUCCESS =
  "fetch_performers_by_seriers_success";
export const FETCH_PERFORMERS_BY_SERIES_ERROR =
  "fetch_performers_by_seriers_error";
export const FETCH_PERFORMERS_BY_SERIES_RESET =
  "fetch_performers_by_seriers_reset";

export const FETCH_TEAMS_FOR_SEASON_WAITING = "fetch_teams_for_season_waiting";
export const FETCH_TEAMS_FOR_SEASON_SUCCESS = "fetch_teams_for_season_success";
export const FETCH_TEAMS_FOR_SEASON_ERROR = "fetch_teams_for_season_error";
export const FETCH_TEAMS_FOR_SEASON_RESET = "fetch_teams_for_season_reset";

export const FETCH_PERFORMERS_SUCCESS = "fetch_performers_success";
export const FETCH_PERFORMERS_WAITING = "fetch_performers_waiting";
export const FETCH_PERFORMERS_RESET = "fetch_performers_reset";
export const FETCH_PERFORMERS_ERROR = "fetch_performers_error";

export const FETCH_PERFORMER_SUCCESS = "fetch_performer_success";
export const FETCH_PERFORMER_WAITING = "fetch_performer_waiting";
export const FETCH_PERFORMER_RESET = "fetch_performer_reset";
export const FETCH_PERFORMER_ERROR = "fetch_performer_error";

export const CREATE_PERFORMER_WAITING = "create_performer_waiting";
export const CREATE_PERFORMER_SUCCESS = "create_performer_success";
export const CREATE_PERFORMER_ERROR = "create_performer_error";
export const CREATE_PERFORMER_RESET = "create_performer_reset";

export const DELETE_PERFORMER_WAITING = "delete_performer_waiting";
export const DELETE_PERFORMER_SUCCESS = "delete_performer_success";
export const DELETE_PERFORMER_ERROR = "delete_performer_error";
export const DELETE_PERFORMER_RESET = "delete_performer_reset";

export const EDIT_PERFORMER_WAITING = "edit_performer_waiting";
export const EDIT_PERFORMER_SUCCESS = "edit_performer_success";
export const EDIT_PERFORMER_ERROR = "edit_performer_error";
export const EDIT_PERFORMER_RESET = "edit_performer_reset";

export const FETCH_PERFORMERS_PLAYER_WAITING =
  "fetch_performers_player_waiting";
export const FETCH_PERFORMERS_PLAYER_SUCCESS =
  "fetch_performers_player_success";
export const FETCH_PERFORMERS_PLAYER_ERROR = "fetch_performers_player_error";
export const FETCH_PERFORMERS_PLAYER_RESET = "fetch_performers_player_reset";

export const ADD_PERFORMERS_PLAYER_WAITING = "add_performers_player_waiting";
export const ADD_PERFORMERS_PLAYER_SUCCESS = "add_performers_player_success";
export const ADD_PERFORMERS_PLAYER_ERROR = "add_performers_palyer_error";
export const ADD_PERFORMERS_PLAYER_RESET = "add_performers_player_reset";

export const EDIT_PERFORMERS_PLAYER_WAITING = "edit_performers_player_waiting";
export const EDIT_PERFORMERS_PLAYER_SUCCESS = "edit_performers_player_success";
export const EDIT_PERFORMERS_PLAYER_ERROR = "edit_performers_player_error";
export const EDIT_PERFORMERS_PLAYER_RESET = "edit_performers_player_reset";

export const DELETE_PERFORMERS_PLAYER_WAITING =
  "delete_performers_player_waiting";
export const DELETE_PERFORMERS_PLAYER_SUCCESS =
  "delete_performers_player_success";
export const DELETE_PERFORMERS_PLAYER_ERROR = "delete_performers_player_error";
export const DELETE_PERFORMERS_PLAYER_RESET = "delete_performers_player_reset";

export const FETCH_PERFORMERS_PLAYERS_SUCCESS =
  "fetch_performers_players_success";
export const FETCH_PERFORMERS_PLAYERS_WAITING =
  "fetch_performers_players_waiting";
export const FETCH_PERFORMERS_PLAYERS_ERROR = "fetch_performers_players_error";
export const FETCH_PERFORMERS_PLAYERS_RESET = "fetch_performers_players_reset";

export const UPLOAD_PERFORMERS_PLAYER_SUCCESS =
  "upload_performers_player_success";
export const UPLOAD_PERFORMERS_PLAYER_WAITING =
  "upload_perforemrs_player_waiting";
export const UPLOAD_PERFORMERS_PLAYER_ERROR = "upload_performers_player_error";
export const UPLOAD_PERFORMERS_PLAYER_RESET = "upload_perforemrs_player_reset";

export const FETCH_PERFORMER_METADATA_KEYS_SUCCESS =
  "fetch_performer_metadata_keys_success";
export const FETCH_PERFORMER_METADATA_KEYS_ERROR =
  "fetch_performer_metadata_keys_error";
export const FETCH_PERFORMER_METADATA_KEYS_WAITING =
  "fetch_performer_metadata_keys_waiting";
export const FETCH_PERFORMER_METADATA_KEYS_RESET =
  "fetch_performer_metadata_keys_reset";

export const ADD_PERFORMER_METADATA_WAITING = "ADD_PERFORMER_METADATA_WAITING";
export const ADD_PERFORMER_METADATA_SUCCESS = "add_performer_metadata_success";
export const ADD_PERFORMER_METADATA_ERROR = "add_performer_metadata_error";
export const ADD_PERFORMER_METADATA_RESET = "add_performer_metadata_reset";

export const DELETE_PERFORMER_METADATA_WAITING =
  "delete_performer_metadata_waiting";
export const DELETE_PERFORMER_METADATA_SUCCESS =
  "delete_performer_metadata_success";
export const DELETE_PERFORMER_METADATA_ERROR =
  "delete_performer_metadata_error";
export const DELETE_PERFORMER_METADATA_RESET =
  "delete_performer_metadata_reset";

export const EDIT_PERFORMER_METADATA_WAITING =
  "edit_performer_metadata_waiting";
export const EDIT_PERFORMER_METADATA_SUCCESS =
  "edit_performer_metadata_success";
export const EDIT_PERFORMER_METADATA_ERROR = "edit_performer_metadata_error";
export const EDIT_PERFORMER_METADATA_RESET = "edit_performer_metadata_reset";

export const ADD_EVENT_TO_PERFORMER_WAITING = "add_event_to_performer_waiting";
export const ADD_EVENT_TO_PERFORMER_SUCCESS = "add_event_to_performer_success";
export const ADD_EVENT_TO_PERFORMER_ERROR = "add_event_to_performer_error";
export const ADD_EVENT_TO_PERFORMER_RESET = "add_event_to_performer_reset";

export const DELETE_PERFORMER_FROM_EVENT_SUCCESS =
  "delete_performer_from_event_success";
export const DELETE_PERFORMER_FROM_EVENT_ERROR =
  "delete_performer_from_event_error";
export const DELETE_PERFORMER_FROM_EVENT_WAITING =
  "delete_performer_from_event_waiting";
export const DELETE_PERFORMER_FROM_EVENT_RESET =
  "delete_performer_from_event_reset";

export const RESET_PERFOMRER_EVENT_ACTIONS = "reset_performer_event_actions";

export const FETCH_ALL_TEAMS_WAITING = "fetch_all_teams_waiting";
export const FETCH_ALL_TEAMS_SUCCESS = "fetch_all_teams_success";
export const FETCH_ALL_TEAMS_ERROR = "fetch_all_teams_error";
export const FETCH_ALL_TEAMS_RESET = "fetch_all_teams_reset";

export const FETCH_PERFORMER_TEAM_TRANSFER_LIST_WAITING =
  "fetch_performer_team_transfer_list_waiting";
export const FETCH_PERFORMER_TEAM_TRANSFER_LIST_SUCCESS =
  "fetch_performer_team_transfer_list_success";
export const FETCH_PERFORMER_TEAM_TRANSFER_LIST_ERROR =
  "fetch_performer_team_transfer_list_error";
export const FETCH_PERFORMER_TEAM_TRANSFER_LIST_RESET =
  "fetch_performer_team_transfer_list_reset";

export const FETCH_PERFORMER_PLAYER_TRANSFER_LIST_WAITING =
  "fetch_performer_player_transfer_list_waiting";
export const FETCH_PERFORMER_PLAYER_TRANSFER_LIST_SUCCESS =
  "fetch_performer_player_transfer_list_success";
export const FETCH_PERFORMER_PLAYER_TRANSFER_LIST_ERROR =
  "fetch_performer_player_transfer_list_error";
export const FETCH_PERFORMER_PLAYER_TRANSFER_LIST_RESET =
  "fetch_performer_player_transfer_list_reset";

export const TRANSFER_PLAYER_WAITING = "transfer_player_waiting";
export const TRANSFER_PLAYER_SUCCESS = "transfer_player_success";
export const TRANSFER_PLAYER_ERROR = "transfer_player_error";
export const TRANSER_PLAYER_RESET = "transfer_player_reset";

export const EDIT_TRANSFER_PLAYER_WAITING = "edit_transfer_player_waiting";
export const EDIT_TRANSFER_PLAYER_SUCCESS = "edit_transfer_player_success";
export const EDIT_TRANSFER_PLAYER_ERROR = "edit_transfer_player_success";
export const EDIT_TRANSFER_PLAYER_RESET = "edit_transfer_player_reset";

export const DELETE_TRANSFER_CONNECTION_WAITING =
  "delete_transfer_connection_waiting";
export const DELETE_TRANSFER_CONNECTION_SUCCESS =
  "delete_transfer_connection_success";
export const DELETE_TRANSFER_CONNECTION_ERROR =
  "delete_transfer_connection_error";
export const RESET_DELETE_TRANSFER_CONNECTION =
  "reset_delete_transfer_connection";

export const ADD_PERFORMER_CMS_CONTENT_WAITING =
  "add_performer_cms_content_waiting";
export const ADD_PERFORMER_CMS_CONTENT_SUCCESS =
  "add_performer_cms_content_success";
export const ADD_PERFORMER_CMS_CONTENT_ERROR =
  "add_performer_cms_content_error";
export const ADD_PERFORMER_CMS_CONTENT_RESET =
  "add_performer_cms_content_reset";

export const EDIT_PERFORMER_CMS_CONTENT_WAITING =
  "edit_performer_cms_content_waiting";
export const EDIT_PERFORMER_CMS_CONTENT_SUCCESS =
  "edit_performer_cms_content_success";
export const EDIT_PERFORMER_CMS_CONTENT_ERROR =
  "edit_performer_cms_content_error";
export const EDIT_PERFORMER_CMS_CONTENT_RESET =
  "edit_performer_cms_content_reset";

export const DELETE_PERFORMER_CMS_CONTENT_SUCCESS =
  "delete_performer_cms_content_success";
export const DELETE_PERFORMER_CMS_CONTENT_ERROR =
  "delete_performer_cms_content_error";
export const DELETE_PERFORMER_CMS_CONTENT_WAITING =
  "delete_performer_cms_content_waiting";
export const DELETE_PERFORMER_CMS_CONTENT_RESET =
  "delete_performer_cms_content_reset";

export const FETCH_PERFORMER_CMS_CONTENTS_WAITING =
  "fetch_performer_cms_contents_waiting";
export const FETCH_PERFORMER_CMS_CONTENTS_SUCCESS =
  "fetch_performer_cms_contents_success";
export const FETCH_PERFORMER_CMS_CONTENTS_ERROR =
  "fetch_performer_cms_contents_error";
export const FETCH_PERFORMER_CMS_CONTENTS_RESET =
  "reset_fetch_performer_cms_content";

export const FETCH_CMS_CONTENT_TYPES_WAITING =
  "fetch_cms_content_types_waiting";
export const FETCH_CMS_CONTENT_TYPES_SUCCESS =
  "fetch_cms_content_types_success";
export const FETCH_CMS_CONTENT_TYPES_ERROR = "fetch_cms_content_types_error";
export const FETCH_CMS_CONTENT_TYPES_RESET = "fetch_cms_content_types_reset";

export const FETCH_PERFORMER_CMS_CONTENT_SUCCESS =
  "fetch_performer_cms_content_success";
export const FETCH_PERFORMER_CMS_CONTENT_ERROR =
  "fetch_performer_cms_content_error";
export const FETCH_PERFORMER_CMS_CONTENT_WAITING =
  "fetch_performer_cms_content_waiting";
export const FETCH_PERFORMER_CMS_CONTENT_RESET =
  "fetch_performer_cms_content_reset";

export const FETCH_CMS_CHILD_CONTENT_SUCCESS =
  "fetch_cms_child_content_success";
export const FETCH_CMS_CHILD_CONTENT_ERROR = "fetch_cms_child_content_error";
export const FETCH_CMS_CHILD_CONTENT_WAITING =
  "fetch_cms_child_content_waiting";
export const FETCH_CMS_CHILD_CONTENT_RESET = "fetch_cms_child_content_reset";

export const FETCH_CMS_CHILD_CONTENT_LIST_WAITING =
  "fetch_cms_child_list_waiting";
export const FETCH_CMS_CHILD_CONTENT_LIST_SUCCESS =
  "fetch_cms_child_list_success";
export const FETCH_CMS_CHILD_CONTENT_LIST_ERROR = "fetch_cms_child_list_error";
export const FETCH_CMS_CHILD_CONTENT_LIST_RESET =
  "fetch_cms_child_content_list_reset";

export const FETCH_PERFORMER_PLAYER_METADATA_SUCCESS =
  "fetch_performer_player_metadata_success";
export const FETCH_PERFORMER_PLAYER_METADATA_ERROR =
  "fetch_performer_player_metadata_error";

export const DELETE_PERFORMER_TEAM_IMAGE_WAITING =
  "delete_performer_team_image_waiting";
export const DELETE_PERFORMER_TEAM_IMAGE_SUCCESS =
  "delete_performer_team_image_success";
export const DELETE_PERFORMER_TEAM_IMAGE_ERROR =
  "delete_performer_team_image_error";
export const DELETE_PERFORMER_TEAM_IMAGE_RESET =
  "delete_performer_team_image_reset";

export const DELETE_PERFORMER_PLAYER_IMAGE_WAITING =
  "delete_performer_player_image_waiting";
export const DELETE_PERFORMER_PLAYER_IMAGE_SUCCESS =
  "delete_performer_player_image_success";
export const DELETE_PERFORMER_PLAYER_IMAGE_ERROR =
  "delete_performer_player_image_error";
export const DELETE_PERFORMER_PLAYER_IMAGE_RESET =
  "delete_performer_player_image_reset";

export const UPLOAD_PERFORMER_TEAM_IMAGE_SUCCESS =
  "upload_performer_team_image_success";
export const UPLOAD_PERFORMER_TEAM_IMAGE_WAITING =
  "upload_performer_team_image_waiting";
export const UPLOAD_PERFORMER_TEAM_IMAGE_ERROR =
  "upload_performer_team_image_error";
export const UPLOAD_PERFORMER_TEAM_IMAGE_RESET =
  "upload_performer_team_image_reset";

export const UPLOAD_PLAYER_TRANSFER_IMAGE_SUCCESS =
  "upload_player_transfer_image_success";
export const UPLOAD_PLAYER_TRANSFER_IMAGE_WAITING =
  "upload_player_transfer_image_waiting";
export const UPLOAD_PLAYER_TRANSFER_IMAGE_ERROR =
  "upload_player_transfer_image_error";
export const UPLOAD_PLAYER_TRANSFER_IMAGE_RESET =
  "upload_player_tranfer_image_reset";

export const FETCH_PLAYERS_LIST_FOR_LINEUP_SUCCESS =
  "fetch_players_list_for_lineup_success";
export const FETCH_PLAYERS_LIST_FOR_LINEUP_WAITING =
  "fetch_players_list_for_lineup_waiting";
export const FETCH_PLAYERS_LIST_FOR_LINEUP_ERROR =
  "fetch_players_list_for_lineup_error";
export const FETCH_PLAYERS_LIST_FOR_LINEUP_RESET =
  "fetch_players_list_for_lineup_reset";

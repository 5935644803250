import React, { Component } from "react";
import PropTypes from "prop-types";
import { T } from "../Common/Helpers";

class AppName extends Component {
  constructor(props) {
    super(props);
    // console.log('AppName props', this.props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      ap_name: this.props.appName,
      toggle: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.toggle !== prevState.toggle) {
      this.props.onComponentValueUpdate("ap_name", this.state.ap_name);
    }
  }

  onValidSubmit(event) {
    event.preventDefault();
    this.setState({ ...this.state, toggle: !this.state.toggle });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.setState({ ...this.state, toggle: !this.state.toggle });
    }
  }

  handleChange(event) {
    this.setState({ ...this.state, ap_name: event.target.value });
  }

  render() {
    // console.log('App name state', this.state);
    return (
      <div>
        <form
          onChange={this.handleChange}
          ref={(ref) => (this.form = ref)}
          style={{
            width: "100%",
            alignItems: "center",
            textAlign: "left",
            display: "block",
          }}
        >
          <p
            style={{
              fontFamily: "Nunito",
              fontWeight: "400px",
              fontSize: "16px",
            }}
          >
            {T("App name")}
          </p>
          <input
            required
            id="app-name"
            name="username"
            type="text"
            className="loginInput wizardInput"
            autoComplete="off"
            placeholder={T("App name")}
            style={{
              width: "500px",
              height: "35px",
              paddingLeft: "5px",
              borderWidth: "0px 0px 2px 0px",
            }}
            value={this.state.ap_name}
            onChange={this.handleChange}
            onBlur={this.onValidSubmit.bind(this)}
            onKeyPress={this.handleKeyPress.bind(this)}
          />
        </form>
      </div>
    );
  }
}

AppName.propTypes = {
  appName: PropTypes.string,
  onComponentValueUpdate: PropTypes.func,
};

export default AppName;

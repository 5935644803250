import _ from 'lodash';
import {
  FETCH_PERMISSIONS_WAITING,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_ERROR,
  FETCH_PERMISSIONS_RESET,
  FETCH_PERMISSION_WAITING,
  FETCH_PERMISSION_SUCCESS,
  FETCH_PERMISSION_ERROR,
  FETCH_PERMISSION_RESET,
  ADD_PERMISSION_WAITING,
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_ERROR,
  ADD_PERMISSION_RESET,
  EDIT_PERMISSION_WAITING,
  EDIT_PERMISSION_SUCCESS,
  EDIT_PERMISSION_ERROR,
  EDIT_PERMISSION_RESET,
  DELETE_PERMISSION_WAITING,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_ERROR,
  DELETE_PERMISSION_RESET,
  FETCH_ROLES_WAITING,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_ERROR,
  FETCH_ROLES_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  operationState: {
    add: 'init',
    delete: 'init',
    update: 'init',
    fetch: 'init',
    fetchAll: 'init',
    fetchRoles: 'init',
  },
  permissions: {},
  permission: {},
  roles: [],
  noderoles: {},
};
export default function permissionReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_PERMISSIONS_WAITING: {
      const operationState = { ...state.operationState, fetchAll: 'waiting' };
      return { ...state, operationState };
    }
    case FETCH_PERMISSIONS_SUCCESS: {
      const permissions = _.mapKeys(action.payload.data, 'permission_id');
      const noderoles = _.groupBy(action.payload.data, 'cpm_node_collection');
      const operationState = { ...state.operationState, fetchAll: 'success' };
      return { ...state, operationState, permissions, noderoles };
    }
    case FETCH_PERMISSIONS_ERROR: {
      const operationState = { ...state.operationState, fetchAll: 'error' };
      return {
        ...state,
        operationState,
        permissions: {},
        error: action.payload.error,
      };
    }
    case FETCH_PERMISSIONS_RESET: {
      const operationState = { ...state.operationState, fetchAll: 'init' };
      return { ...state, operationState, permissions: {} };
    }

    case FETCH_PERMISSION_WAITING: {
      const operationState = { ...state.operationState, fetch: 'waiting' };
      return { ...state, operationState, permission: {} };
    }
    case FETCH_PERMISSION_SUCCESS: {
      const operationState = { ...state.operationState, fetch: 'success' };
      return { ...state, operationState, permission: action.payload.data };
    }
    case FETCH_PERMISSION_ERROR: {
      const operationState = { ...state.operationState, fetch: 'error' };
      return {
        ...state,
        operationState,
        permission: {},
        error: action.payload.error,
      };
    }
    case FETCH_PERMISSION_RESET: {
      const operationState = { ...state.operationState, fetch: 'init' };
      return { ...state, operationState, permission: {} };
    }

    case ADD_PERMISSION_WAITING: {
      const operationState = { ...state.operationState, add: 'waiting' };
      return { ...state, operationState, permission: {} };
    }
    case ADD_PERMISSION_SUCCESS: {
      const operationState = { ...state.operationState, add: 'success' };
      // console.log('Befor adding to noderoles: ', state.noderoles);
      const existingRoles =
        Object.keys(state.noderoles).length > 0
          ? state.noderoles[action.payload.data.cpm_node_collection]
          : {};
      const updatedNodeRole = {
        [action.payload.data.cpm_node_collection]: [
          ...existingRoles,
          action.payload.data,
        ],
      };
      const permissions = {
        ...state.permissions,
        [action.payload.data.permission_id]: action.payload.data,
      };
      // console.log('State in Permission: ', permissions, updatedNodeRole);
      return {
        ...state,
        operationState,
        permissions,
        permission: action.payload.data,
        noderoles: { ...state.noderoles, ...updatedNodeRole },
      };
    }
    case ADD_PERMISSION_ERROR: {
      const operationState = { ...state.operationState, add: 'error' };
      return {
        ...state,
        operationState,
        permission: {},
        error: action.payload.error,
      };
    }
    case ADD_PERMISSION_RESET: {
      const operationState = { ...state.operationState, add: 'init' };
      return { ...state, operationState, permission: {} };
    }

    case EDIT_PERMISSION_WAITING: {
      const operationState = { ...state.operationState, update: 'waiting' };
      return { ...state, operationState, permission: {} };
    }
    case EDIT_PERMISSION_SUCCESS: {
      const operationState = { ...state.operationState, update: 'success' };
      const updatedNodeRole = {
        ...state.noderoles[action.payload.data.cpm_node_collection],
        [action.payload.data.cpm_node_collection]: action.payload.data,
      };
      const permissions = {
        ...state.permissions,
        [action.payload.data.permission_id]: action.payload.data,
      };
      return {
        ...state,
        operationState,
        permissions,
        permission: action.payload.data,
        noderoles: { ...state.noderoles, updatedNodeRole },
      };
    }
    case EDIT_PERMISSION_ERROR: {
      const operationState = { ...state.operationState, update: 'error' };
      return {
        ...state,
        operationState,
        permission: {},
        error: action.payload.error,
      };
    }
    case EDIT_PERMISSION_RESET: {
      const operationState = { ...state.operationState, update: 'init' };
      return { ...state, operationState, permission: {} };
    }

    case DELETE_PERMISSION_WAITING: {
      const operationState = { ...state.operationState, delete: 'waiting' };
      return { ...state, operationState };
    }
    case DELETE_PERMISSION_SUCCESS: {
      const operationState = { ...state.operationState, delete: 'success' };
      const updatedNodeRole = {
        [action.payload.data.cpm_node_collection]: _.filter(
          state.noderoles[action.payload.data.cpm_node_collection],
          permission => permission.cpm_role !== action.payload.data.cpm_role
        ),
      };
      const permissions = _.omit(
        state.permissions,
        action.payload.data.permission_id
      );
      return {
        ...state,
        operationState,
        permissions,
        noderoles: { ...state.noderoles, ...updatedNodeRole },
      };
    }
    case DELETE_PERMISSION_ERROR: {
      const operationState = { ...state.operationState, delete: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case DELETE_PERMISSION_RESET: {
      const operationState = { ...state.operationState, delete: 'init' };
      return { ...state, operationState };
    }

    case FETCH_ROLES_WAITING: {
      const operationState = { ...state.operationState, fetchRoles: 'waiting' };
      return { ...state, operationState };
    }
    case FETCH_ROLES_SUCCESS: {
      const roles = action.payload.data;
      const operationState = { ...state.operationState, fetchRoles: 'success' };
      return { ...state, operationState, roles };
    }
    case FETCH_ROLES_ERROR: {
      const operationState = { ...state.operationState, fetchRoles: 'error' };
      return {
        ...state,
        operationState,
        roles: {},
        error: action.payload.error,
      };
    }
    case FETCH_ROLES_RESET: {
      const operationState = { ...state.operationState, fetchRoles: 'init' };
      return { ...state, operationState, roles: {} };
    }
    default:
      return state;
  }
}

import React, { Component } from 'react';
import { Menu, Input, Popup } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { toast } from 'react-semantic-toasts';
import { connect } from 'react-redux';
import _ from 'lodash';
// import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import PlayerEdit from '../PerformerPlayers/PlayerEdit';
import PerformerTeamDetailsPage from './PerformerTeamDetailsPage';
import PlayersList from '../PerformerPlayers/PlayersList';
import PlayerAdd from '../PerformerPlayers/PlayerAdd';
import { T, getErrorMessage } from '../Common/Helpers';
import PlayerMetadata from '../PerformerPlayers/PlayerMetadata';
import PlayerTransferList from '../PerformerPlayers/PlayerTransferList';
import PlayerCMSContnentList from '../PerformerPlayers/PlayerCMSContentList';
import Sponsor from '../PerformerPlayers/Sponsor';
// import defaultImage from '../../layout/images/images.png';
import {
  fetchPerformerPlayer,
  fetchPerformer,
  fetchPerformerPlayersList,
  resetFetchPerformerPlayer,
  editPerformerPlayer,
  resetEditPerformerPlayer,
  // uploadPerformerImage,
  fetchPerformerMetadataKeys,
  deletePerformerPlayer,
  fetchPerformerCMSContent,
  resetUploadPlayerImage,
  fetchPerformerTeamTransferList,
  fetchPerformerPlayerTransferList,
  resetDeletePerformerTeamImage,
  resetUploadPerformerTeamImage,
  resetFetchPerformer,
  resetDeletePerformerPlayerImage,
  resetdeletePerformerMetadata,
  fetchPerformerPlayerMetadata,
} from '../../Redux/actions';

class PerformerTeamHomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePlayer: '',
      activeItem: '',
      searchterm: '',
    };

    this.handleSearch = _.debounce(this.handleSearch, 500);
    this.renderPlayerComponents = this.renderPlayerComponents.bind(this);
    this.onChangeActivePlayer = this.onChangeActivePlayer.bind(this);
    this.onChangeAddPlayer = this.onChangeAddPlayer.bind(this);
    this.onResetEditAndAddPlayer = this.onResetEditAndAddPlayer.bind(this);
    this.getMenuBar = this.getMenuBar.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.setStateAfterAdd = this.setStateAfterAdd.bind(this);
    this.setStateForTeamTransfer = this.setStateForTeamTransfer.bind(this);
    // this.renderPlayerComponents = this.renderPlayerComponents.bind(this);
  }

  componentDidMount() {
    // console.log('HOME PAGE', this.props);
    // console.log('');
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem('x-auth-key');

    if (!this.props.metadatakeys) {
      this.props.fetchPerformerMetadataKeys(authKey, ws_id);
    }

    this.props.fetchPerformer(
      authKey,
      this.props.match.params.performer_id,
      ws_id
    );
    this.props.fetchPerformerTeamTransferList(
      this.props.match.params.performer_id,
      ws_id,
      authKey
    );

    if (!this.props.player && this.state.activePlayer) {
      this.props.fetchPerformerPlayer(authKey, this.state.activePlayer, ws_id);
      this.props.fetchPerformerPlayerTransferList(
        this.state.activePlayer,
        authKey,
        ws_id
      );
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // console.log('WILL UPDATE: ', this.props, nextProps);
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem('x-auth-key');
    if (
      this.state.activePlayer !== nextState.activePlayer &&
      nextState.activePlayer
    ) {
      this.props.fetchPerformerPlayer(authKey, nextState.activePlayer, ws_id);
      this.props.fetchPerformerPlayerMetadata(nextState.activePlayer, authKey);
      this.props.fetchPerformerPlayerTransferList(
        nextState.activePlayer,
        authKey,
        ws_id
      );
      window.scrollTo(0, document.body.scrollHeight);
    } else if (
      this.props.match.params.performer_id !==
      nextProps.match.params.performer_id
    ) {
      this.props.fetchPerformer(
        authKey,
        nextProps.match.params.performer_id,
        ws_id
      );
      this.props.fetchPerformerPlayerTransferList(
        nextProps.match.params.performer_id,
        authKey,
        ws_id
      );
    } else if (
      this.props.uploadTeamImageStatus !== 'success' &&
      nextProps.uploadTeamImageStatus === 'success'
    ) {
      this.props.fetchPerformer(
        authKey,
        nextProps.match.params.performer_id,
        ws_id
      );
      this.props.resetUploadPerformerTeamImage();
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Performer Image successfully updated'),
        time: 5000,
      });
      window.scroll(50, 100);
    } else if (
      this.props.uploadTeamImageStatus !== 'error' &&
      nextProps.uploadTeamImageStatus === 'error'
    ) {
      this.props.fetchPerformer(
        authKey,
        nextProps.match.params.performer_id,
        ws_id
      );
      this.props.resetUploadPerformerTeamImage();
      const errorObj = getErrorMessage(
        nextProps.performerError,
        'Error in updating Team Image'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      window.scroll(50, 100);
    } else if (
      this.props.uploadStatus !== 'success' &&
      nextProps.uploadStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Performer Image successfully updated'),
        time: 5000,
      });
      window.scroll(50, 100);
      const { headers } = this.props;
      this.props.fetchPerformerPlayersList(
        authKey,
        ws_id,
        this.props.match.params.performer_id,
        headers ? headers.current_page : 1,
        headers ? headers.searchterm : null,
        headers && headers.col ? headers.col : 'pf_first_name',
        headers && headers.col ? null : 'asc'
      );
      this.props.fetchPerformerPlayer(authKey, nextState.activePlayer, ws_id);
      this.props.resetUploadPlayerImage();
      // goToAnchor('playerDashboard', false);
    } else if (
      this.props.uploadStatus !== 'error' &&
      nextProps.uploadStatus === 'error'
    ) {
      const errorObj = getErrorMessage(nextProps.playerError);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.fetchPerformerPlayer(authKey, nextState.activePlayer, ws_id);
      this.props.resetEditPerformerPlayer();
      this.props.resetUploadPlayerImage();
      // goToAnchor('playerDashboard', false);
    } else if (
      this.props.playerImageDeleteStatus !== 'error' &&
      nextProps.playerImageDeleteStatus === 'error'
    ) {
      const errorObj = getErrorMessage(nextProps.playerError);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.fetchPerformerPlayer(authKey, nextState.activePlayer, ws_id);
      this.props.resetEditPerformerPlayer();
      this.props.resetUploadPlayerImage();
      // goToAnchor('playerDashboard', false);
    } else if (
      this.props.teamImageDeleteStatus !== 'error' &&
      nextProps.teamImageDeleteStatus === 'error'
    ) {
      const errorObj = getErrorMessage(nextProps.performerError);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.fetchPerformerPlayer(authKey, nextState.activePlayer, ws_id);
      this.props.resetEditPerformerPlayer();
      this.props.resetUploadPlayerImage();
      // goToAnchor('playerDashboard', false);
    } else if (
      this.props.teamImageDeleteStatus !== 'success' &&
      nextProps.teamImageDeleteStatus === 'success'
    ) {
      this.props.resetFetchPerformer();
      this.props.fetchPerformer(
        authKey,
        nextProps.match.params.performer_id,
        ws_id
      );
      this.props.resetDeletePerformerTeamImage();
      // goToAnchor('playerDashboard', false);
    } else if (
      this.props.playerImageDeleteStatus !== 'success' &&
      nextProps.playerImageDeleteStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Player successfully updated'),
        time: 5000,
      });
      window.scroll(50, 100);
      this.props.resetFetchPerformerPlayer();
      const { headers } = this.props;
      this.props.fetchPerformerPlayersList(
        authKey,
        ws_id,
        this.props.match.params.performer_id,
        headers ? headers.current_page : 1,
        headers ? headers.searchterm : null,
        headers && headers.col ? headers.col : 'pf_first_name',
        headers && headers.col ? null : 'asc'
      );
      this.props.fetchPerformerPlayer(authKey, nextState.activePlayer, ws_id);
      this.props.resetDeletePerformerPlayerImage();
    } else if (nextProps.metadataDelete === 'success') {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Performer Metadata successfully updated'),
        time: 5000,
      });
      // window.scroll(250, 100);
      this.props.fetchPerformerPlayerMetadata(
        nextState.activePlayer,
        authKey,
        ws_id
      );
      // this.props.resetUploadPerformerImage();
      this.props.resetdeletePerformerMetadata();
    }
  }

  onResetEditAndAddPlayer() {
    this.setState({ activePlayer: '', activeItem: '' });
  }

  onChangeSearchTerm(e) {
    this.setState({ search: e.target.value });
    this.handleSearch(e.target.value);
  }

  onChangeActivePlayer(playerId) {
    const ws_id = this.props.workspace.activeWorkspace.id;
    this.setState({ activeItem: 'generalInformation', activePlayer: playerId });
    const authKey = localStorage.getItem('x-auth-key');
    this.props.fetchPerformerPlayerTransferList(playerId, authKey, ws_id);
    // goToAnchor('playerDashboard', false);
  }

  onChangeAddPlayer() {
    this.setState({ activePlayer: '', activeItem: 'playerAdd' });
    // goToAnchor('playerDashboard', false);
    // document.getElementById('playerDashboardAnchor').scrollIntoView();
  }

  setStateAfterAdd(playerId) {
    this.setState({ activePlayer: playerId, activeItem: 'generalInformation' });
    // goToAnchor('playerDashboard', false);
  }

  setStateForTeamTransfer() {
    this.setState({ activeItem: 'teamTransfer' });
    // goToAnchor('playerDashboard', false);
  }

  getMenuBar(player) {
    const { activeItem } = this.state;
    return (
      <div style={{ display: 'block' }} className="primary-header">
        <Menu stackable pointing>
          <Popup
            header={T('General Information')}
            content={T(
              'In this section, General information and player image can be entered'
            )}
            trigger={
              <Menu.Item
                name="generalInformation"
                to="/app/dashboard"
                active={activeItem === 'generalInformation'}
                onClick={this.handleItemClick}
              >
                {T('General Information')}
              </Menu.Item>
            }
          />
          <Menu.Item
            name="attributes"
            active={activeItem === 'attributes'}
            onClick={this.handleItemClick}
          >
            {T('Attributes')}
          </Menu.Item>
          <Menu.Item
            name="sponsor"
            active={activeItem === 'sponsor'}
            onClick={this.handleItemClick}
          >
            {T('Sponsor')}
          </Menu.Item>
          <Menu.Item
            name="playerTransfer"
            to="/app/events/list"
            active={activeItem === 'playerTransfer'}
            onClick={this.handleItemClick}
          >
            {T('Transfer(s)')}
          </Menu.Item>
        </Menu>
        <p>
          {/* <strong> Player Id: </strong> <span>{player ? player.performer_id : ''}</span> <br /> */}
          <strong>
            {T('Name')}:
            <span style={{ color: 'steelblue' }}>
              {player ? player.pf_name : ''}
            </span>
          </strong>
        </p>
      </div>
    );
  }

  handleItemClick(e, { name }) {
    // console.log('BEFORE Handling Click');
    this.setState({ activeItem: name });
  }

  handleSearch(e) {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    this.props.fetchPerformerPlayersList(
      authKey,
      ws_id,
      this.props.match.params.performer_id,
      false,
      e,
      'pf_last_name',
      'asc'
    );
    if (this.state.activePlayer) {
      this.onResetEditAndAddPlayer();
    }
  }

  renderPlayerComponents() {
    const performerId = this.props.match.params.performer_id;
    const performer = this.props.performers[performerId];
    const playerEdit = () => (
      <PlayerEdit
        parentId={performerId}
        player={this.props.player}
        performerId={this.state.activePlayer}
        match={this.props.match}
        history={this.props.history}
        onResetEditAndAddPlayer={this.onResetEditAndAddPlayer}
        resetFetchPerformerPlayer={this.props.resetFetchPerformerPlayer}
        // metadataId={this.getPlayerImage(this.props.player).metadataId}
        // showDeleteButton={this.getPlayerImage(this.props.player).showDeleteButton}
        // playerImage={}
        // setActivePlayer={this.onChangeActivePlayer}
      />
    );

    switch (this.state.activeItem) {
      case 'teamTransfer':
        return (
          <div>
            <p>
              <strong> {T('Team Name')}: </strong>
              <span style={{ color: 'steelblue' }}>{performer.pf_name}</span>
            </p>
            <PlayerTransferList
              parentId={performer.pf_parent}
              currentTeamId={performer.performer_id}
              // players={this.props.players}
              isTeam
              playerId={performer.performer_id}
              onResetEditAndAddPlayer={this.onResetEditAndAddPlayer}
              headers={this.props.headers}
              transfers={this.props.teamTransfer}
            />
          </div>
        );
      case 'playerAdd':
        return (
          <div>
            <PlayerAdd
              performerId={performerId}
              match={this.props.match}
              history={this.props.history}
              onResetEditAndAddPlayer={this.onResetEditAndAddPlayer}
              setStateAfterAdd={this.setStateAfterAdd}
            />
          </div>
        );
      case 'freeFormContent': {
        return (
          <div>
            {this.getMenuBar(this.props.player)}
            <PlayerCMSContnentList
              onResetEditAndAddPlayer={this.onResetEditAndAddPlayer}
              performerId={this.state.activePlayer}
            />
          </div>
        );
      }
      case 'attributes':
        return (
          <div>
            {this.getMenuBar(this.props.player)}
            <PlayerMetadata
              performer={this.props.player}
              performerMetadata={this.props.performerMetadata}
              metadatakeys={this.props.metadatakeys}
              onResetEditAndAddPlayer={this.onResetEditAndAddPlayer}
              playerId={this.props.player.performer_id}
            />
          </div>
        );
      case 'sponsor':
        return (
          <div>
            {this.getMenuBar(this.props.player)}
            <Sponsor
              player={this.props.player}
              onResetEditAndAddPlayer={this.onResetEditAndAddPlayer}
            />
          </div>
        );
      case 'playerTransfer':
        return (
          <div>
            {this.getMenuBar(this.props.player)}
            <PlayerTransferList
              parentId={performer.pf_parent}
              currentTeamId={performer.performer_id}
              playerId={this.props.player.performer_id}
              onResetEditAndAddPlayer={this.onResetEditAndAddPlayer}
              headers={this.props.headers}
              transfers={this.props.playerTransfer}
              isPlayer
            />
          </div>
        );
      case 'generalInformation': {
        const div = document.querySelector('#playerDashboard');
        return (
          <div>
            {this.getMenuBar(this.props.player)}
            {playerEdit()}
            {div.scrollIntoView({ behavior: 'smooth' })}
          </div>
        );
      }
      default:
        return <div />;
    }
  }

  render() {
    const performerId = this.props.match.params.performer_id;
    // const { players, metadatakeys } = this.props;
    const performer = this.props.performers[performerId];
    if (
      !performer ||
      this.props.selectStatus === 'waiting' ||
      this.props.selectStatus === 'error'
    ) {
      return <div />;
    }
    return (
      <div style={{ marginBottom: '200px' }}>
        <PerformerTeamDetailsPage
          performer={performer}
          performerId={performerId}
          match={this.props.match}
          history={this.props.history}
          displayTeamTransferList={this.setStateForTeamTransfer}
          onResetEditAndAddPlayer={this.onResetEditAndAddPlayer}
          uploadStatus={this.props.uploadTeamImageStatus}
        />
        <div>
          <Input
            icon="search"
            placeholder={T('Search player...')}
            onChange={(e, props) => this.onChangeSearchTerm(e, props)}
          />
        </div>
        <PlayersList
          onChangeActivePlayer={this.onChangeActivePlayer}
          onChangeAddPlayer={this.onChangeAddPlayer}
          onResetEditAndAddPlayer={this.onResetEditAndAddPlayer}
          performerId={performerId}
          match={this.props.match}
          history={this.props.history}
        />
        <div id="playerDashboard">{this.renderPlayerComponents()}</div>
      </div>
    );
  }
}

function mapStateToProps({ performerPlayers, performers, workspace }) {
  return {
    selectStatus: performers.selectStatus,
    // players: performerPlayers.players,
    uploadStatus: performerPlayers.uploadStatus,
    player: performerPlayers.player,
    headers: performerPlayers.headers,
    metadatakeys: performerPlayers.metadatakeys,
    performerMetadata: performerPlayers.metadata,
    uploadTeamImageStatus: performers.uploadTeamImageStatus,
    season: performers.seasons,
    teamTransfer: performers.transfers,
    playerTransfer: performerPlayers.transfers,
    playerImageDeleteStatus: performerPlayers.deleteImageStatus,
    teamImageDeleteStatus: performers.imageDeleteStatus,
    metadataDelete: performerPlayers.metadataDelete,
    performerError: performers.error,
    playerError: performerPlayers.error,
    performers,
    workspace,
    // cms: performerPlayers.cms,
  };
}

export default connect(mapStateToProps, {
  fetchPerformerPlayer,
  fetchPerformer,
  fetchPerformerPlayersList,
  resetFetchPerformerPlayer,
  editPerformerPlayer,
  resetEditPerformerPlayer,
  // uploadPerformerImage,
  fetchPerformerMetadataKeys,
  deletePerformerPlayer,
  fetchPerformerCMSContent,
  resetUploadPlayerImage,
  fetchPerformerTeamTransferList,
  fetchPerformerPlayerTransferList,
  resetDeletePerformerTeamImage,
  resetUploadPerformerTeamImage,
  resetFetchPerformer,
  resetDeletePerformerPlayerImage,
  resetdeletePerformerMetadata,
  fetchPerformerPlayerMetadata,
})(withRouter(PerformerTeamHomePage));

import React, { Component } from 'react';
import { Segment, List, Header } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  fetchSubVenues,
  selectedEditSubVenueLocation,
  selectedAddSubVenueLocation,
} from '../../Redux/actions';
import { T } from '../Common/Helpers';
import ModalWindow, { ModalContext } from '../Common/ModalWindow';
import { Spinner } from '../Common/Spinner';
import SubVenueAdd from './SubVenueAdd';
import SubVenueEdit from './SubVenueEdit';

class SubVenues extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedSubVenue: this.props.parentVenue };
  }

  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    const { place_id } = this.props.parentVenue;
    this.props.fetchSubVenues(place_id, ws_id, authKey, true);
  }

  onSelectedSubvenueChanged(venue) {
    this.setState({ selectedSubVenue: venue });
  }

  createListView(resultJSON) {
    return _.map(resultJSON, venue => {
      if (venue.subvenues && venue.subvenues.length > 0) {
        return (
          <List.Item key={venue.place_id}>
            {/* <List.Icon name="warehouse" /> */}
            <List.Icon>
              <ModalWindow
                buttonColor="grey"
                iconName="edit"
                modalSize="tiny"
                header={T('Edit Subvenue')}
              >
                <ModalContext.Consumer>
                  {({ closeModal }) => (
                    <SubVenueEdit
                      venueId={venue.place_id}
                      closeModal={closeModal}
                      setLocation={this.props.selectedEditSubVenueLocation}
                    />
                  )}
                </ModalContext.Consumer>
              </ModalWindow>
            </List.Icon>
            <List.Content>
              {/* <Radio
                           name="subVenue"
                           onChange={()=>this.onSelectedSubvenueChanged(venue)}
                           checked={venue.place_id === this.state.selectedSubVenue.place_id} /> */}
              <ModalWindow
                buttonColor="grey"
                buttonSize="small"
                iconName="plus circle"
                header={T('Add Subvenue')}
                modalSize="mini"
              >
                <ModalContext.Consumer>
                  {({ closeModal }) => (
                    <SubVenueAdd parentVenue={venue} closeModal={closeModal} />
                  )}
                </ModalContext.Consumer>
              </ModalWindow>
              {`${venue.pl_name}`}
              <List.List>{this.createListView(venue.subvenues)}</List.List>
            </List.Content>
          </List.Item>
        );
      }
      return (
        <List.Item key={venue.place_id}>
          {/*<List.Icon name="warehouse" />*/}
          <List.Icon>
            <ModalWindow
              buttonColor="grey"
              iconName="edit"
              modalSize="tiny"
              header={T('Edit Subvenue')}
            >
              <ModalContext.Consumer>
                {({ closeModal }) => (
                  <SubVenueEdit
                    venueId={venue.place_id}
                    closeModal={closeModal}
                    setLocation={this.props.selectedEditSubVenueLocation}
                  />
                )}
              </ModalContext.Consumer>
            </ModalWindow>
          </List.Icon>
          <List.Content>
            <ModalWindow
              buttonColor="grey"
              buttonSize="small"
              iconName="plus circle"
              header={T('Add Subvenue')}
              modalSize="mini"
            >
              <ModalContext.Consumer>
                {({ closeModal }) => (
                  <SubVenueAdd parentVenue={venue} closeModal={closeModal} />
                )}
              </ModalContext.Consumer>
            </ModalWindow>
            {/* <Radio name="subVenue"
                             onChange={()=>this.onSelectedSubvenueChanged(venue)}
                             checked={venue.place_id === this.state.selectedSubVenue.place_id}
                        /> */}
            {`${venue.pl_name}`}
          </List.Content>
        </List.Item>
      );
    });
  }

  render() {
    if (this.props.status !== 'success') {
      return <Spinner />;
    }
    return (
      <Segment>
        <div
          style={{
            marginBottom: '50px',
          }}
        >
          <Header as="h2" content={T('Subvenues')} textAlign="center" />
          <div style={{ minHeight: '350px', marginBottom: '20px' }}>
            <List
              relaxed
              verticalAlign="middle"
              style={{
                // color: 'steelblue',
                lineHeight: '140%',
                fontSize: '1.2rem',
              }}
            >
              {this.props.subvenues.length === 0
                ? T('No subvenues')
                : this.createListView(this.props.subvenues)}
            </List>
          </div>
          <ModalWindow
            buttonColor="green"
            buttonSize="small"
            buttonLabel={T('Add Subvenue')}
            header={T('Add Subvenue')}
            modalSize="mini"
          >
            <ModalContext.Consumer>
              {({ closeModal }) => (
                <SubVenueAdd
                  parentVenue={this.props.parentVenue}
                  closeModal={closeModal}
                />
              )}
            </ModalContext.Consumer>
          </ModalWindow>
        </div>
      </Segment>
    );
  }
}
function mapStateToProps({ venues, workspace }) {
  return {
    subvenues: venues.venues,
    header: venues.headerSubVenues,
    status: venues.subVenuesFetch,
    workspace,
  };
}

export default connect(mapStateToProps, {
  fetchSubVenues,
  selectedEditSubVenueLocation,
  selectedAddSubVenueLocation,
})(SubVenues);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import ProductAttributes from './ProductAttributes';
import { Spinner } from '../Common/Spinner';
import { T } from '../Common/Helpers';
import {
  fetchProductAttributesList,
  resetFetchProductAttributesList,
  fetchProduct,
} from '../../Redux/actions';

class ProductAttributesContainer extends Component {
  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.ws_id;
    this.props.fetchProductAttributesList(authKey, ws_id);
  }

  render() {
    if (this.props.status === 'init' || this.props.status === 'waiting') {
      return <Spinner />;
    } else if (this.props.status === 'error') {
      return (
        <Message error>
          <Message.Header>{T('Error')}</Message.Header>
          <p>{T('Error has occured, while fetching data')}</p>
        </Message>
      );
    }
    return (
      <ProductAttributes
        data={this.props.data}
        attributes={this.props.attributes}
        productId={this.props.productId}
        productName={this.props.productName}
      />
    );
  }
}

function mapStateToProps({ productAttribute, workspace }) {
  return {
    attributes: productAttribute.attributes,
    status: productAttribute.fetchAll,
    ws_id: workspace.ws_id,
  };
}
export default connect(mapStateToProps, {
  fetchProductAttributesList,
  resetFetchProductAttributesList,
  fetchProduct,
})(ProductAttributesContainer);

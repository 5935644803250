import React, { Component } from 'react';
import { Button, Segment, Confirm, Checkbox } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
//import momentTimezone from 'moment-timezone';
import { T } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';
import { PopupContent } from '../Common/PopupContent';
import {
  addNotification,
  resetAddNotification,
  fetchNotificationCategories,
  resetFetchNotificationCategories,
} from '../../Redux/actions';

const errorStyle = {
  backgroundColor: '#FFF6F6',
  border: '1px solid #E0B4B4',
  color: '#9F3A38',
  WebkitBoxShadow: 'none',
  boxShadow: 'none',
};

//const timezone = jstz.determine().name();
const DATE_TIME_FORMAT = 'l HH:mm';

class AddNotification extends Component {
  constructor(props) {
    super(props);
    const notificationDate = moment().toISOString();
    //notificationDate = moment(notificationDate).add(1, 'hours').format('ddd, DD MMM YYYY HH:mm');
    this.state = {
      selectedCategory: '',
      me_send_now: false,
      confirmSend: false,
      categoryStyle: {},
      dateStyle: {},
      notificationDate,
      notification: {},
      me_priority: false,
    };
    this.onChangeCategory = this.onChangeCategory.bind(this);
    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChangeScheduleDate = this.onChangeScheduleDate.bind(this);
    // this.onChange = this.onChange.bind(this);
    this.onCancelSend = this.onCancelSend.bind(this);
    this.onConfirmSend = this.onConfirmSend.bind(this);
  }

  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const { activeWorkspace } = this.props.workspace;
    if (activeWorkspace && activeWorkspace.id) {
      this.props.fetchNotificationCategories({
        authKey,
        ws_id: activeWorkspace.id,
        sortByColumn: null,
        direction: null,
        application_id: activeWorkspace.cnc_application,
      });
    }
  }

  onCancel() {
    // this.props.history.push('/app/notifications/list');
    const { closeModal } = this.props;
    if (closeModal) {
      this.props.closeModal();
    } else {
      this.props.history.push('/app/advertising/list');
    }
  }

  onValidSubmit(formData) {
    if (this.state.selectedCategory === '') {
      this.setState({ categoryStyle: errorStyle });
      return false;
    }

    const notification = {
      me_schedule_time: this.state.notificationDate,
      ...formData,
      me_category_id: this.state.selectedCategory,
      me_send_now: this.state.me_send_now,
      me_priority: this.state.me_priority,
    };

    if (this.state.me_send_now) {
      this.setState({ confirmSend: true, notification });
    } else {
      this.sendMessage(notification);
    }
  }

  onCancelSend() {
    this.setState({ confirmSend: false });
  }

  onConfirmSend() {
    const { notification } = this.state;
    this.setState({ confirmSend: false });
    this.sendMessage(notification);
  }

  onChangeCategory(e, { value }) {
    this.setState({ selectedCategory: value, categoryStyle: {} });
  }

  onChangeScheduleDate(event, { value }) {
    const dateTime = moment(value, DATE_TIME_FORMAT);
    const now = moment();
    if (dateTime && now.isBefore(dateTime)) {
      this.setState({
        notificationDate: dateTime.toISOString(),
        me_send_now: false,
        dateStyle: {},
      });
    } else {
      this.setState({ dateStyle: errorStyle });
    }
  }

  handleInputChange = (name, value) => {
    if (name === 'me_send_now' && value) {
      this.setState({ notificationDate: moment(new Date()).toISOString() });
    }
    this.setState({ [name]: value });
  };

  sendMessage(notification) {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    this.props.addNotification({ notification, authKey, ws_id });
    const { closeModal } = this.props;
    if (closeModal) {
      this.props.closeModal();
      this.props.sendNow(notification.me_send_now);
    } else {
      this.props.history.push('/app/advertising/list/false');
    }
  }

  handleDismiss = () => this.setState({ showError: false });

  renderCategories(categoriesList) {
    const categoriesResult = _.map(categoriesList, category => ({
      text: category.mc_name,
      value: category.message_category_id,
    }));
    categoriesResult.unshift({ value: '', text: T('Select a Category') });
    return categoriesResult;
  }

  render() {
    const popupParams = this.props.popupParams;
    if (this.props.fetchAllCat !== 'success') {
      return <Spinner />;
    }
    return (
      <Segment>
        <Form
          onValidSubmit={this.onValidSubmit.bind(this)}
          ref={ref => (this.form = ref)}
        >
          <Form.Group widths="equal">
            <Form.Select
              required
              label={T('Category')}
              name="me_category_id"
              options={this.renderCategories(this.props.categories)}
              onChange={this.onChangeCategory}
              style={this.state.categoryStyle}
              // placeholder={T('Roles')}
            />
            <Form.Input required label={T('Subject')} name="me_subject" />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.TextArea
              maxLength="200"
              required
              label={T('Message')}
              name="me_message"
              style={{ minHeight: '200px' }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            {!this.state.me_send_now && (
              <DateTimeInput
                required
                name="cm_valid_from"
                label={T('Sending Time')}
                closable
                onChange={this.onChangeScheduleDate}
                value={moment(this.state.notificationDate).format(
                  DATE_TIME_FORMAT
                )}
                dateTimeFormat={DATE_TIME_FORMAT}
                style={{ minWidth: '225px', ...this.state.dateStyle }}
                minDate={moment(new Date()).format(DATE_TIME_FORMAT)}
                animation="off"
              />
            )}
          </Form.Group>
          <Form.Group widths="equal">
            <Checkbox
              toggle
              name="me_send_now"
              checked={this.state.me_send_now}
              onChange={(e, { name, checked }) =>
                this.handleInputChange(name, checked)
              }
              label={T('Send immediately')}
            />
            <Checkbox
              toggle
              name="me_priority"
              label={T('High priority')}
              onChange={(e, { name, checked }) =>
                this.handleInputChange(name, checked)
              }
            />
            <PopupContent
              wide={popupParams.wide}
              content={popupParams.content}
              size={popupParams.size}
              color={popupParams.color}
            />
          </Form.Group>

          <Button
            content={this.state.me_send_now ? T('Send') : T('Save')}
            color="green"
          />
          <Button
            type="button"
            color="black"
            content={T('Cancel')}
            onClick={() => this.onCancel()}
          />
        </Form>
        <Confirm
          header={T('Send message')}
          content={T('Are you sure you want to send the message immediately?')}
          confirmButton={T('Send')}
          cancelButton={T('Cancel')}
          open={this.state.confirmSend}
          onCancel={this.onCancelSend}
          onConfirm={this.onConfirmSend}
          size="tiny"
        />
      </Segment>
    );
  }
}

function mapStateToProps({ notifications, auth, workspace }) {
  return {
    categories: notifications.categories,
    fetchAllCat: notifications.operationState.fetchAllCat,
    auth,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    error: notifications.error,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    addNotification,
    resetAddNotification,
    fetchNotificationCategories,
    resetFetchNotificationCategories,
  })(AddNotification)
);

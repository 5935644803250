import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Nav from "../layout/Nav";

import ManageCompany from "../components/Companies/ManageCompany";

const CompanyRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: "150px" }}>
      <Nav />
      <Switch>
        <Route path={`${path}/manage`} exact>
          <ManageCompany />
        </Route>
      </Switch>
    </Container>
  );
};

export default CompanyRoute;

export const FETCH_CMS_CONTENT_SUCCESS = "fetch_cms_content_success";
export const FETCH_CMS_CONTENT_WAITING = "fetch_cms_content_waiting";
export const FETCH_CMS_CONTENT_ERROR = "fetch_cms_content_error";
export const FETCH_CMS_CONTENT_RESET = "fetch_cms_content_reset";

export const EDIT_CMS_CONTENT_WAITING = "edit_cms_content_waiting";
export const EDIT_CMS_CONTENT_SUCCESS = "edit_cms_content_success";
export const EDIT_CMS_CONTENT_ERROR = "edit_cms_content_error";
export const EDIT_CMS_CONTENT_RESET = "edit_cms_content_reset";

export const ADD_CMS_CONTENT_WAITING = "add_cms_content_waiting";
export const ADD_CMS_CONTENT_SUCCESS = "add_cms_content_success";
export const ADD_CMS_CONTENT_ERROR = "add_cms_content_error";
export const ADD_CMS_CONTENT_RESET = "add_cms_content_reset";

export const CMS_CONTENT_TYPES_WAITING = "cms_content_types_waiting";
export const CMS_CONTENT_TYPES_SUCCESS = "cms_content_types_success";
export const CMS_CONTENT_TYPES_ERROR = "cms_content_types_error";
export const CMS_CONTENT_TYPES_RESET = "cms_content_types_reset";

export const FETCH_CMS_CONTENT_CHILDREN_WAITING =
  "fetch_cms_content_children_waiting";
export const FETCH_CMS_CONTENT_CHILDREN_SUCCESS =
  "fetch_cms_content_children_success";
export const FETCH_CMS_CONTENT_CHILDREN_ERROR =
  "fetch_cms_content_children_error";
export const FETCH_CMS_CONTENT_CHILDREN_RESET =
  "fetch_cms_content_children_reset";

export const DELETE_CMS_CONTENT_WAITING = "delete_cms_content_waiting";
export const DELETE_CMS_CONTENT_SUCCESS = "delete_cms_content_success";
export const DELETE_CMS_CONTENT_ERROR = "delete_cms_content_error";
export const DELETE_CMS_CONTENT_RESET = "delete_cms_content_reset";

export const SAVE_CMS_CONTENTS_WAITING = "save_cms_contents_waiting";
export const SAVE_CMS_CONTENTS_SUCCESS = "save_cms_contents_success";
export const SAVE_CMS_CONTENTS_ERROR = "save_cms_contents_error";
export const SAVE_CMS_CONTENTS_RESET = "save_cms_contents_reset";

export const DELETE_HAND_PROGRAM_WAITING = "delete_hand_program_waiting";
export const DELETE_HAND_PROGRAM_SUCCESS = "delete_hand_program_success";
export const DELETE_HAND_PROGRAM_ERROR = "delete_hand_program_error";
export const DELETE_HAND_PROGRAM_RESET = "delete_hand_program_reset";

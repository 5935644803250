import React, { Component } from "react";
import QRCode from "qrcode.react";
import PropTypes from "prop-types";
import { T } from "../Common/Helpers";

class AppQRCode extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    // console.log('QR Code: component Download');
    return (
      <div style={{ marginBottom: "100px" }}>
        <div style={{ marginBottom: "30px", fontSize: "16px", width: "100%" }}>
          {T("View your App")}
        </div>
        <div style={{ display: "inline-block", width: "200px" }}>
          <QRCode value={this.props.value} />
        </div>
        <p
          style={{
            display: "inline-block",
            width: "80%",
            height: "100px",
            verticalAlign: "top",
          }}
        >
          {T(
            "Download Prometheus One preview app from App Store or Google Play and preview your app on your phone. "
          )}
          {T(
            "Follow the instructions to read the QR code with your phone camera how your app looks like."
          )}
        </p>
        <p>{this.props.value}</p>
      </div>
    );
  }
}

AppQRCode.propTypes = {
  value: PropTypes.string,
};

export default AppQRCode;

import _ from 'lodash';
import React, { Component } from 'react';
import {
  Button,
  Segment,
  Confirm,
  Header,
  Label,
  Dropdown,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Select } from 'formsy-semantic-ui-react';
import {
  T,
  getErrorMessage,
  localDate,
  validateEmail,
} from '../Common/Helpers';
import { getMultiLineText } from '../Common/Texts';
import {
  addCardInstance,
  editCardInstance,
  resetAddCardInstance,
  resetEditCardInstance,
  fetchUsers,
} from '../../Redux/actions';

//const SAVE_CARD_CONFIRM_HEADER = 'Save Card Instance';
const SAVE_CARD_CONFIRM_HEADER = 'Save Data';
//const SAVE_CARD_CONFIRM_TEXT = 'Do you want to save the card instance?';
const SAVE_CARD_CONFIRM_TEXT = 'Do you want to save the data';
const INVALID_EMAIL = 'Invalid email';
//const CARD_INSTANCE_ADD_SUCCESS = 'Card instance added successfully';
const CARD_INSTANCE_ADD_SUCCESS = 'Data added successfully';
//const CARD_INSTANCE_EDIT_SUCCESS = 'Card instance edited successfully';
const CARD_INSTANCE_EDIT_SUCCESS = 'Data updated successfully';
const SEARCH_KEY_PHONE_EMAIL = 'us_phone|us_email';
const SEARCH_KEY_EMAIL = 'us_email';
const P_EMAIL = 'Input email address or select user from the user list';
const P_FIRSNAME = 'Input firstname';
const P_LASTNAME = 'Input lastname';
const P_PHONE = 'Input phone number';
const P_ADDRESS = 'Input street address';
const P_POSTAL = 'Input postal code';
const P_CITY = 'Input city';
const P_SERIALNUMBER = 'Input Serial Number';

class CardInstance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardinstance: this.props.cardinstance,
      //ci_card: this.props.cardinstance.ci_card,
      //ci_card: this.props.cardinstance.card ? this.props.cardinstance.card.card_id : null,
      //ci_card: this.setDefaultCardId(),
      ci_card: this.setDefaultCardId(),
      card_instance_id: this.props.cardinstance.card_instance_id,
      showConfirm: false,
      formValues: {},
      ci_phone: this.props.cardinstance.ci_phone,
      ci_email: this.props.cardinstance.ci_email,
      showUserList: false,
      validEmail: true,
      user_id: null,
    };

    //this.onHandleSubmit = this.onHandleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onSelectUser = this.onSelectUser.bind(this);
    this.onCancelSave = this.onCancelSave.bind(this);
    this.onConfirmSave = this.onConfirmSave.bind(this);
    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.authKey = localStorage.getItem('x-auth-key');
    this.wsId = this.props.params.wsId;
    this.applicationId = this.props.params.applicationId;
    this.cardType = this.props.params.cardType;
    this.moduleId = this.props.params.moduleId;
  }

  componentDidUpdate(prevProps) {
    const prevAddSuccess = prevProps.cardinstances.addCardInstanceStatus;
    const addSuccess = this.props.cardinstances.addCardInstanceStatus;
    const prevEditSuccess = prevProps.cardinstances.editCardInstanceStatus;
    const editSuccess = this.props.cardinstances.editCardInstanceStatus;

    if (prevAddSuccess !== 'success' && addSuccess === 'success') {
      toast({
        type: 'success',
        title: T('Success'),
        description: T(CARD_INSTANCE_ADD_SUCCESS),
        time: 5000,
      });
      this.props.resetAddCardInstance();
      this.props.history.push(this.props.params.returnURL);
    } else if (prevEditSuccess !== 'success' && editSuccess === 'success') {
      toast({
        type: 'success',
        title: T('Success'),
        description: T(CARD_INSTANCE_EDIT_SUCCESS),
        time: 5000,
      });
      this.props.resetEditCardInstance();
      this.props.history.push(this.props.params.returnURL);
    } else if (prevAddSuccess !== 'error' && addSuccess === 'error') {
      this.props.resetAddCardInstance();
      const errorObj = getErrorMessage(
        this.props.error,
        this.props.cardinstances.error.response.data.message
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (prevEditSuccess !== 'error' && editSuccess === 'error') {
      this.props.resetEditCardInstance();
      const errorObj = getErrorMessage(
        this.props.error,
        this.props.cardinstances.error.response.data.message
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  onCancel = () => {
    this.props.history.push(this.props.params.returnURL);
  };

  onCancelSave() {
    this.setState({ showConfirm: false });
  }

  onConfirmSave() {
    const authKey = localStorage.getItem('x-auth-key');
    const formValues = this.state.formValues;
    this.setState({ showConfirm: false });
    this.saveCardInstance(formValues, authKey);
  }

  onSelectCard(e, { value }) {
    this.setState({ ci_card: value });
  }

  onSearchUsers(e, { value }) {
    const status = this.props.users.status;
    const searhKey = SEARCH_KEY_EMAIL;
    this.setState({ selectValue: false });
    if (status === 'init' || status === 'success') {
      this.setState({ selectValue: false });
      this.props.fetchUsers(
        this.authKey,
        this.wsId,
        this.applicationId,
        1,
        searhKey,
        value
      );
    }
  }

  onSelectUser(e, { value }) {
    const result = this.props.users.users.filter(
      user => user.user_id === value
    );
    const userObject = result[0];
    const user_id = userObject ? userObject.user_id : null;
    const cardinstance = this.getCardInstanceObject(userObject);
    this.setState({ cardinstance, showUserList: false, user_id });
  }

  onValidSubmit(formData) {
    const validEmail = validateEmail(formData.ci_email);

    if (validEmail) {
      this.setState({
        showConfirm: true,
        confirmText: `${T(SAVE_CARD_CONFIRM_TEXT)} ${formData.ci_email}...?`,
      });
      const formValues = {
        ...formData,
      };

      if (this.state.user_id) {
        formValues.user_id = this.state.user_id;
      }

      this.setState({ formValues });
    } else {
      this.setState({ validEmail });
    }
  }

  getCardInstanceObject(userObject) {
    if (userObject) {
      if (
        this.state.card_instance_id &&
        userObject.user_id !== this.props.cardinstance.user_id
      ) {
        const tmp = this.state.cardinstance;
        tmp.ci_email = userObject.us_email;
        return tmp;
      }
      return {
        ci_address1: userObject.us_address1,
        ci_city: userObject.us_city,
        ci_countrycode: userObject.us_country,
        ci_email: userObject.us_email,
        ci_firstname: userObject.us_firstname,
        ci_lastname: userObject.us_lastname,
        ci_phone: userObject.us_phone,
        ci_postalcode: userObject.us_postal_code,
        ci_state: userObject.us_state,
      };
    }
    return {};
  }

  setDefaultCardId() {
    if (this.props.cardinstance.card) {
      return this.props.cardinstance.card.card_id;
    }
    if (this.props.cards.length === 1) {
      return this.props.cards[0].card_id;
    }
    return null;
  }

  setInfoText() {
    if (this.props.infoText) {
      const content = getMultiLineText(this.props.infoText);
      return <Segment>{content}</Segment>;
    }
  }

  handleFieldChange = (e, { name, value }) => {
    this.setState({ name: value });
    if (name === 'ci_email') {
      if (value.length > 2) {
        this.searchUsers(value);
      }
    }
  };

  searchUsers(searchTerm) {
    const status = this.props.users.status;
    const searhKey =
      this.state.ci_phone && this.state.ci_phone.length > 3
        ? SEARCH_KEY_PHONE_EMAIL
        : SEARCH_KEY_EMAIL;
    if (status === 'init' || status === 'success') {
      this.props.fetchUsers(
        this.authKey,
        this.wsId,
        this.applicationId,
        1,
        searhKey,
        searchTerm
      );
    }
    this.setState({ showUserList: true });
  }

  saveCardInstance(formData) {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    const application_id = this.props.activeWorkspace.cnc_application;
    // console.log('saveCardInstance', {
    //   formData,
    //   props: this.props,
    //   application_id,
    // });
    if (this.props.cardinstance.card_instance_id) {
      this.props.editCardInstance({
        moduleId: this.moduleId,
        formData,
        authKey,
        ws_id,
        application_id,
        card_instance_id: this.props.cardinstance.card_instance_id,
      });
    } else {
      this.props.addCardInstance({
        module_id: this.moduleId,
        formData,
        authKey,
        ws_id,
        application_id,
      });
    }
  }

  listCards() {
    return this.props.cards.map(card => ({
      key: card.card_id,
      text: `${card.cd_name}: ${localDate(
        card.cd_validfrom,
        'fi'
      )} - ${localDate(card.cd_validto, 'fi')}`,
      value: card.card_id,
    }));
  }

  listUsers(userList) {
    return _.map(userList, user => ({
      key: user.user_id,
      text: `${user.us_firstname} ${user.us_lastname} ${user.us_email} ${user.us_phone}`,
      value: user.user_id,
    }));
  }

  render() {
    // console.log('cardinstance this.props', this.props);
    const { cardinstance, ci_card } = this.state;
    const { selectCardLabel, selectCardPlaceholder } = this.props.params;
    const userList = this.props.users.users ? this.props.users.users : [];
    const errorLabel = <Label color="red" pointing />;
    const hasUsers =
      this.props.users.status === 'success' && userList.length > 0;
    /*
    <Form.Field
      placeholder={T('Select a Card')}
      control={Select}
      required
      label={T('Select Card')}
      name='ci_card'
      options={this.listCards()}
      onChange={this.onSelectCard.bind(this)}
      value={ci_card}
    />
    */
    return (
      <Segment>
        <Header as="h3">{T(this.props.headerLabel)}</Header>
        {this.setInfoText()}
        <Segment>
          <Form
            //onSubmit={this.onHandleSubmit.bind(this)}
            onValidSubmit={this.onValidSubmit.bind(this)}
            ref={ref => (this.form = ref)}
          >
            {cardinstance.card_instance_id ? (
              <Header as="h3">{cardinstance.card.cd_name}</Header>
            ) : (
              <Form.Group widths={'equal'}>
                <Form.Field
                  placeholder={T(selectCardPlaceholder || 'Select a Card')}
                  control={Select}
                  required
                  label={T(selectCardLabel || 'Card')}
                  name="ci_card"
                  options={this.listCards()}
                  onChange={this.onSelectCard.bind(this)}
                  value={ci_card}
                />
                <Form.Field />
              </Form.Group>
            )}
            <Form.Group widths={'equal'}>
              {this.state.validEmail ? (
                <Form.Input
                  required
                  placeholder={T(P_EMAIL)}
                  label={T('Email')}
                  name="ci_email"
                  value={cardinstance.ci_email}
                  onChange={this.handleFieldChange}
                />
              ) : (
                <Form.Input
                  required
                  placeholder={T(P_EMAIL)}
                  label={T('Email')}
                  name="ci_email"
                  validations="isEmail"
                  validationErrors={{ isEmail: T(INVALID_EMAIL) }}
                  errorLabel={errorLabel}
                  value={cardinstance.ci_email}
                  onChange={this.handleFieldChange}
                />
              )}
              <Form.Input
                required
                placeholder={T(P_PHONE)}
                label={T('Phone')}
                name="ci_phone"
                value={cardinstance.ci_phone}
                //onChange={this.handleFieldChange}
              />
            </Form.Group>
            {hasUsers && this.state.showUserList ? (
              <Form.Group widths={'4'}>
                <Form.Field />
                <Dropdown
                  //style={{ margin: '10px' }}
                  label={T('Select User')}
                  placeholder={T('Select a user from the list')}
                  name="user"
                  fluid
                  selection
                  options={this.listUsers(this.props.users.users)}
                  scrolling
                  clearable
                  onChange={this.onSelectUser}
                  value={cardinstance.ci_email}
                  open
                />
                <Form.Field />
              </Form.Group>
            ) : (
              <Form.Field />
            )}
            <Form.Group widths={'equal'}>
              <Form.Input
                required
                placeholder={T(P_FIRSNAME)}
                label={T('Firstname')}
                name="ci_firstname"
                value={cardinstance.ci_firstname}
              />
              <Form.Input
                required
                placeholder={T(P_LASTNAME)}
                label={T('Lastname')}
                name="ci_lastname"
                value={cardinstance.ci_lastname}
              />
            </Form.Group>
            <Form.Group widths={'equal'}>
              <Form.Input
                placeholder={T(P_ADDRESS)}
                label={T('Street Address')}
                name="ci_address1"
                value={cardinstance.ci_address1}
              />
              <Form.Input
                placeholder={T(P_POSTAL)}
                label={T('Postal Code')}
                name="ci_postalcode"
                value={cardinstance.ci_postalcode}
              />
              <Form.Input
                placeholder={T(P_CITY)}
                label={T('City')}
                name="ci_city"
                value={cardinstance.ci_city}
              />
            </Form.Group>
            {this.props.params.cardType === 'registrationcard' ? (
              <Form.Group widths="3">
                <Form.Input
                  placeholder={T(P_SERIALNUMBER)}
                  label={T('Serial Number')}
                  name="ci_licencecode"
                  value={cardinstance.ci_licencecode}
                />
              </Form.Group>
            ) : null}
            <Button
              type="button"
              color="black"
              content={T('Cancel')}
              onClick={e => this.onCancel(e)}
            />
            <Button content={T('Save')} type="submit" color="green" />
          </Form>
          <Confirm
            header={T(SAVE_CARD_CONFIRM_HEADER)}
            //content={T(SAVE_CARD_CONFIRM_TEXT)}
            content={this.state.confirmText}
            cancelButton={T('Cancel')}
            confirmButton={T('Save')}
            open={this.state.showConfirm}
            onCancel={this.onCancelSave}
            onConfirm={this.onConfirmSave}
            size="tiny"
          />
        </Segment>
      </Segment>
    );
  }
}

function mapStateToProps({ cardinstances, workspace, users }) {
  return {
    cardinstances,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    users,
  };
}

export default connect(mapStateToProps, {
  addCardInstance,
  editCardInstance,
  resetAddCardInstance,
  resetEditCardInstance,
  fetchUsers,
})(withRouter(CardInstance));

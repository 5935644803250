import React, { Component } from 'react';
import { Form, Segment, Button, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { addPerformerToEvent, fetchPerformer } from '../../Redux/actions';

import { T } from '../Common/Helpers';

class PerformerTeamSeries extends Component {
  constructor(props) {
    super(props);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleModalClose() {
    this.props.closeModal();
  }

  handleFormSubmit() {
    const seasonId = this.props.seasonValue;
    const categoryId = this.props.categoryValue;
    if (!seasonId && !categoryId) {
      this.props.setErrorStyle();
    } else if (!seasonId) {
      this.props.setSeasonErrorStyle();
    } else if (!categoryId) {
      this.props.setCategoryErrorStyle();
    } else {
      const authKey = localStorage.getItem('x-auth-key');
      this.props.addPerformerToEvent(
        seasonId,
        this.props.performerId,
        authKey,
        this.props.ws_id
      );
      this.handleModalClose();
    }
  }

  render() {
    return (
      <Segment.Group>
        <Form onSubmit={this.handleFormSubmit}>
          <Segment>
            <Form.Field>
              <Form.Select
                required
                label={T('Category')}
                name="ev_eventcategory"
                options={this.props.categoryList}
                value={this.props.categoryValue}
                onChange={this.props.onChangeCategory}
                style={{ ...this.props.categoryStyle, width: '50px' }}
              />
              <Form.Select
                required
                label={T('Season')}
                name="ev_parent"
                options={this.props.seasonList}
                value={this.props.seasonValue}
                onChange={this.props.onChangeSeason}
                style={{ ...this.props.seasonStyle, width: '50px' }}
              />
            </Form.Field>
          </Segment>
          <Segment textAlign="center">
            <Form.Field>
              <Button color="green" type="submit">
                <Icon name="save" /> {T('Save')}
              </Button>
              <Button
                color="black"
                type="button"
                onClick={this.handleModalClose}
              >
                <Icon name="ban" /> {T('Cancel')}
              </Button>
            </Form.Field>
          </Segment>
        </Form>
      </Segment.Group>
    );
  }
}

function mapStateToProps({ workspace }) {
  return { ws_id: workspace.ws_id };
}
export default connect(mapStateToProps, {
  addPerformerToEvent,
  fetchPerformer,
})(PerformerTeamSeries);

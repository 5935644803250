import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Spinner } from "../Common/Spinner";
import { fetchCardInstance, fetchCards } from "../../Redux/actions";

import CardInstance from "../CardInstance/CardInstance";

const CARD_TYPE = "seasoncard";
const HEADER_LABEL = "Edit Season Card Holder";
const INFO_TEXT = [
  "Here you can edit the Season Cardholder data.",
  "Email address combines the season card into mobile app user.",
  "Season card appears into app when the app user logs in.",
];

const RETURN_URL = "/app/ticketing/seasoncard/list";
const MODULE_ID = 37;

class SeasonCardEdit extends Component {
  componentDidMount() {
    const authKey = localStorage.getItem("x-auth-key");
    const ws_id = this.props.ws_id;
    const application_id = this.props.activeWorkspace.cnc_application;
    const card_instance_id = this.props.match.params.card_instance_id;

    if (this.props.cardinstances.editCardInstanceStatus === "init") {
      this.props.fetchCardInstance({
        moduleId: MODULE_ID,
        authKey,
        ws_id,
        application_id,
        card_instance_id,
      });
    }

    if (
      this.props.card.status === "init" ||
      this.props.card.status === "success"
    ) {
      this.props.fetchCards(
        MODULE_ID,
        authKey,
        ws_id,
        application_id,
        CARD_TYPE,
        false
      );
    }
  }

  render() {
    if (this.props.cardinstances.cardInstanceStatus !== "success") {
      return <Spinner />;
    }

    const params = {
      wsId: this.props.ws_id,
      applicationId: this.props.activeWorkspace.cnc_application,
      cardType: CARD_TYPE,
      returnURL: RETURN_URL,
      moduleId: MODULE_ID,
    };
    if (
      this.props.cardinstances.cardInstanceStatus === "success" &&
      this.props.card.status === "success"
    ) {
      return (
        <CardInstance
          cardinstance={this.props.cardinstances.cardinstance}
          cards={this.props.card.data}
          headerLabel={HEADER_LABEL}
          params={params}
          {...this.props}
          infoText={INFO_TEXT}
        />
      );
    }
  }
}

function mapStateToProps({ workspace, cardinstances, card }) {
  return {
    workspace,
    cardinstances,
    ws_id: workspace.ws_id,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    card,
  };
}

export default connect(mapStateToProps, { fetchCardInstance, fetchCards })(
  withRouter(SeasonCardEdit)
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Dropdown, Header } from 'semantic-ui-react';
import {
  fetchShopSettings,
  fetchShopSettingsKeywords,
  fetchApplications,
} from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import { T } from '../Common/Helpers';
import AppShopSettingsContainer from './AppShopSettingsContainer';

class AppShopSettingsHome extends Component {
  constructor(props) {
    super(props);
    this.state = { apiFailed: false };
  }

  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    this.props.fetchApplications(authKey, ws_id);
    this.timeOut = setTimeout(this.checkWaitingTimeout, 3500);
  }

  getApplicationsList = () =>
    _.map(this.props.applications, application => ({
      text: application.ap_name,
      value: application.application_id,
    }));

  fetchApplication = (e, { value }) => {
    this.setState({ id: value });
  };

  checkWaitingTimeout = () => {
    if (
      this.props.fetchAll !== 'success' ||
      this.props.fetchApp !== 'success'
    ) {
      this.setState({ apiFailed: true });
    }
  };

  render() {
    const { fetchAll, applications } = this.props;
    if (fetchAll !== 'success' && this.state.apiFailed === false) {
      return <Spinner />;
    } else if (this.state.apiFailed === true) {
      return (
        <div style={{ color: 'red' }}>
          Error in fetching application settings, please refresh the page
        </div>
      );
    }
    clearTimeout(this.timeOut);
    const id = this.state.id || applications[0].application_id;
    // console.log('Id: ', id, '\nProps: ', this.props);
    return (
      <React.Fragment>
        <Header as="h3">
          {T('App settings page, please select an app name from the dropdown')}
        </Header>
        <div style={{ margin: '10px 0 0 20px', width: '300px' }}>
          <Dropdown
            options={this.getApplicationsList()}
            value={id}
            onChange={this.fetchApplication}
            placeholder={T('Select Application')}
            selection
            fluid
            labeled
          />
        </div>
        <div style={{ margin: '50px 20px 0 20px' }}>
          <AppShopSettingsContainer appId={id} />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ shop, workspace, applications }) {
  return {
    shop,
    ws_id: workspace.activeWorkspace.id,
    fetchAll: applications.operationState.fetchAll,
    fetchApp: applications.operationState.fetchApp,
    applications: applications.applications,
  };
}
export default connect(mapStateToProps, {
  fetchShopSettings,
  fetchShopSettingsKeywords,
  fetchApplications,
})(AppShopSettingsHome);

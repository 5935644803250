import React, { Component } from 'react';
import {
  Table,
  Segment,
  Button,
  Header,
  Pagination,
  Icon,
  Confirm,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
import jstz from 'jstz';
import momentTimezone from 'moment-timezone';
import PlayerTransferControl from './PlayerTransferControl';
import PlayerTransferApproval from './PlayerTransferApproval';
import ModalWindow, { ModalContext } from '../Common/ModalWindow';
import { T, getErrorMessage } from '../Common/Helpers';
import {
  deleteTransferConnection,
  fetchPerformerPlayersList,
  resetDeleteTransferConnection,
  fetchPerformerPlayerTransferList,
  fetchPerformerTeamTransferList,
} from '../../Redux/actions';

const timezone = jstz.determine().name();
class PlayerTransferList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopUp: false,
      playerId: '',
      openDeleteConfirmation: false,
      connnectionId: '',
    };
    this.getTransferList = this.getTransferList.bind(this);
    this.onDeleteTransferConnection =
      this.onDeleteTransferConnection.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    if (
      this.props.deleteStatus !== 'success' &&
      nextProps.deleteStatus === 'success'
    ) {
      this.props.resetDeleteTransferConnection();
      //this.props.onResetEditAndAddPlayer();
      this.props.fetchPerformerPlayersList(
        authKey,
        ws_id,
        this.props.currentTeamId,
        this.props.headers.current_page
      );
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Transfer deleted successfully'),
        time: 5000,
      });
      const activePlayerPage = this.props.playerHeader
        ? this.props.playerHeader.current_page
        : 1;
      const activeTeamPage = this.props.teamHeader
        ? this.props.teamHeader.current_page
        : 1;
      // this.props.fetchPerformerTeamTransferList(this.props.currentTeamId, authKey);
      // this.props.fetchPerformerPlayerTransferList(nextState.playerId, authKey);
      this.props.fetchPerformerPlayerTransferList(
        nextState.playerId,
        authKey,
        ws_id,
        false,
        activePlayerPage
      );
      this.props.fetchPerformerTeamTransferList(
        nextProps.currentTeamId,
        ws_id,
        authKey,
        true,
        activeTeamPage
      );
    } else if (
      this.props.deleteStatus !== 'errror' &&
      nextProps.deleteStatus === 'error'
    ) {
      const errorObj = getErrorMessage(nextProps.error);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetDeleteTransferConnection();
    }
  }

  onDeleteTransferConnection(performerId, connectionId) {
    this.setState({
      openDeleteConfirmation: true,
      playerId: performerId,
      connectionId,
    });
  }
  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }
  onConfirmDelete() {
    const authKey = localStorage.getItem('x-auth-key');
    this.props.deleteTransferConnection(
      this.state.playerId,
      this.state.connectionId,
      authKey
    );
    this.setState({ openDeleteConfirmation: false });
  }

  getTransferList(transfers) {
    // const filteredPlayers = this.props.players.filter(
    //   player => player.performer_connection.length !== 0);
    const filteredPlayers = transfers;
    if (!filteredPlayers || filteredPlayers.length === 0) {
      return (
        <Table.Row>
          <Table.Cell>-</Table.Cell>
          <Table.Cell>-</Table.Cell>
          <Table.Cell>-</Table.Cell>
          <Table.Cell>-</Table.Cell>
          <Table.Cell>-</Table.Cell>
          <Table.Cell>-</Table.Cell>
          <Table.Cell>-</Table.Cell>
          <Table.Cell>-</Table.Cell>
        </Table.Row>
      );
    }
    return filteredPlayers.map(connection => {
      let validFrom;
      let validTo;
      let transferStatus = '';
      validFrom = momentTimezone
        .tz(connection.pc_valid_from, timezone)
        .format();
      validFrom = moment(validFrom).format('l HH:mm');
      if (connection.pc_valid_to) {
        validTo = momentTimezone.tz(connection.pc_valid_to, timezone).format();
        validTo = moment(validTo).format('l HH:mm');
      } else {
        validTo = T('Permanent');
      }
      if (connection.pc_accepted === null && connection.pc_rejected === null) {
        transferStatus = 'Pending';
      } else if (connection.pc_accepted !== null) {
        transferStatus = 'Accepted';
      } else {
        transferStatus = 'Rejected';
      }
      return (
        <Table.Row key={connection.performer_connection_id}>
          <Table.Cell>{connection.pf_name}</Table.Cell>
          <Table.Cell>{connection.pf_team_name}</Table.Cell>
          <Table.Cell>{validFrom}</Table.Cell>
          <Table.Cell>{validTo}</Table.Cell>
          <Table.Cell>{connection.transfer}</Table.Cell>
          <Table.Cell>
            {connection.pc_parent === this.props.currentTeamId &&
            connection.pc_accepted === null &&
            connection.pc_rejected === null ? (
              <ModalWindow
                header={T('Approve Transfer')}
                modalSize="large"
                buttonIcon="check square"
                buttonColor="green"
              >
                <ModalContext.Consumer>
                  {({ closeModal }) => (
                    <PlayerTransferApproval
                      currentTeamId={this.props.currentTeamId}
                      onResetEditAndAddPlayer={
                        this.props.onResetEditAndAddPlayer
                      }
                      connection={connection}
                      playerId={connection.performer_id}
                      closeModal={closeModal}
                      // metadata={this.props.performer_metadata}
                    />
                  )}
                </ModalContext.Consumer>
              </ModalWindow>
            ) : (
              T(transferStatus)
            )}
          </Table.Cell>
          <Table.Cell>
            {connection.transfer === 'in' ? (
              ''
            ) : (
              <ModalWindow
                headerContent={T('Transfer Player to Another Team')}
                modalSize="small"
                buttonIcon="edit"
                buttonColor="blue"
              >
                <ModalContext.Consumer>
                  {({ closeModal }) => (
                    <PlayerTransferControl
                      currentTeamId={this.props.currentTeamId}
                      playerId={connection.performer_id}
                      parentId={this.props.parentId}
                      onResetEditAndAddPlayer={
                        this.props.onResetEditAndAddPlayer
                      }
                      closeModal={closeModal}
                      connection={connection}
                    />
                  )}
                </ModalContext.Consumer>
              </ModalWindow>
            )}
          </Table.Cell>
          <Table.Cell>
            <Button
              icon="trash"
              color="red"
              onClick={() =>
                this.onDeleteTransferConnection(
                  connection.performer_id,
                  connection.performer_connection_id
                )
              }
            />
            <Confirm
              header={T('Delete Transfer')}
              content={T('Are you sure you want to delete the transfer?')}
              confirmButton={T('Delete')}
              open={this.state.openDeleteConfirmation}
              onCancel={this.oncancelDeleteConfirmation}
              onConfirm={this.onConfirmDelete}
              size="tiny"
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  }
  handlePaginationChange(e, { activePage }) {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    if (this.props.isPlayer) {
      this.props.fetchPerformerPlayerTransferList(
        this.props.playerId,
        authKey,
        ws_id,
        false,
        activePage
      );
    } else {
      this.props.fetchPerformerTeamTransferList(
        this.props.currentTeamId,
        ws_id,
        authKey,
        true,
        activePage
      );
    }
  }
  render() {
    const noBorder = { border: 'none', boxShadow: 'none' };
    const header = this.props.isPlayer
      ? this.props.playerHeader
      : this.props.teamHeader;
    return (
      <Segment>
        <Header
          as="h3"
          content={
            this.props.isPlayer ? T('Transfer Player') : 'Team Tranfer List'
          }
        />
        <Segment style={{ ...noBorder }}>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{T('Player')}</Table.HeaderCell>
                <Table.HeaderCell>{T('Team')}</Table.HeaderCell>
                <Table.HeaderCell>{T('Starting')}</Table.HeaderCell>
                <Table.HeaderCell>{T('Coming Back')}</Table.HeaderCell>
                <Table.HeaderCell>{T('Transfer')}</Table.HeaderCell>
                <Table.HeaderCell>{T('Approve/Reject')}</Table.HeaderCell>
                <Table.HeaderCell> {T('Edit')} </Table.HeaderCell>
                <Table.HeaderCell>{T('Delete')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.getTransferList(this.props.transfers)}
            </Table.Body>
          </Table>
          {this.props.isPlayer && (
            <ModalWindow
              header={T('Transfer Player to Another Team')}
              buttonLabel={T('Transfer Player to Another Team')}
              modalSize="tiny"
            >
              <ModalContext.Consumer>
                {({ closeModal }) => (
                  <PlayerTransferControl
                    currentTeamId={this.props.currentTeamId}
                    playerId={this.props.playerId}
                    parentId={this.props.parentId}
                    onResetEditAndAddPlayer={this.props.onResetEditAndAddPlayer}
                    closeModal={closeModal}
                  />
                )}
              </ModalContext.Consumer>
            </ModalWindow>
          )}
        </Segment>
        <Segment textAlign="right" style={{ ...noBorder }}>
          <Pagination
            onPageChange={this.handlePaginationChange}
            defaultActivePage={header ? header.current_page : 1}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={header ? header.total_pages : 1}
          />
        </Segment>
      </Segment>
    );
  }
}
function mapStateToProps({ performerPlayers, performers, workspace }) {
  return {
    deleteStatus: performerPlayers.deleteTransferConnectionStatus,
    playerHeader: performerPlayers.transferHeader,
    teamHeader: performers.teamHeader,
    error: performers.error,
    workspace,
  };
}

export default connect(mapStateToProps, {
  deleteTransferConnection,
  fetchPerformerPlayersList,
  resetDeleteTransferConnection,
  fetchPerformerPlayerTransferList,
  fetchPerformerTeamTransferList,
})(PlayerTransferList);

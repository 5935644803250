import React, { Component } from 'react';
import {
  Form,
  Button,
  Confirm,
  Header,
  Segment,
  Loader,
  Menu,
  Grid,
  Dropdown,
  Label,
} from 'semantic-ui-react';
import { withRouter } from 'react-router';
import moment from 'moment';
import 'moment/locale/fi';
import _ from 'lodash';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { Prompt } from 'react-router';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../Style/wysiwyg.css';
import {
  addLottery,
  editLottery,
  fetchLottery,
  resetAddLottery,
  resetEditLottery,
  resetFetchLottery,
  resetDeleteLottery,
  addMorePrize,
  editPrizesInit,
  resetPrizes,
  deleteLotteryPrize,
  updatePrizeImage,
  fetchMessageTemplates,
  fetchVotings,
  fetchLotteries,
} from '../../Redux/actions';
import LotteryRegion from './LotteryRegion';
import PrizeContainer from './PrizeContainer';
import {
  T,
  getErrorMessage,
  getLanguageObject,
  DEFAULT_TOOLBAR_OPTIONS,
  handlePastedText,
} from '../Common/Helpers';
import { getPopupContent } from '../Common/PopupContent';
const TOAST_DELAY = 5000;

//const MODULE_ID = 26;
const MODULE_ID = 901;
const VOTING_MODULE_ID = 901;
const TEMPLATE_MODULE_ID = 13;
const PRIMARY_LANGUAGE = 15; // Finnish and can be change later according to user pref
//const DATE_TIME_FORMAT = 'l HH:mm';
const VOTINGS_ALL = true;
const VOTING_PAGING = false;
const VOTING_DETAILS = false;
const VOTING_COLUMN = 'ga_name';
const VOTING_SORT_ORDER = 'asc';
const GAME_TYPE = 'lottery';
const GAME_TYPE_LOTTERY_AND_VOTING = 'lotteryAndVoting';
const LOTTERYLIST = true;

const POPUP_ICON_SIZE = 'big';
const POPUP_COLOR = 'grey';
const POPUP_CONTENT_1 =
  'If voting is required to participate the lottery, select the voting';

class ManageLottery extends Component {
  constructor(props) {
    super(props);
    const primary_language_object = getLanguageObject(
      props.languages,
      props.auth.apps[0]
        ? props.auth.apps[0].ap_primary_language
        : PRIMARY_LANGUAGE
    );
    this.primary_language_id = primary_language_object.language_id;
    //this.primary_alpha_2 = primary_language_object.ln_alpha_2;
    this.defaultPrize = {
      name: '',
      gp_number: 1, // init winner number
      gp_order: 1, // init prize sequence
      image: null,
    };
    this.state = {
      editorState: EditorState.createEmpty(),
      showConfirm: false,
      confirmType: 'Draw',
      sameAsPush: false,
      drawImmediately: false,
      lo_language: this.primary_language_id,
      is_dirty: false,
      editorContentCache: [],
      lo_name: '',
      lo_title_app: '', // in app Title lt_app_title
      lo_title_winner: '', // push message Title lt_push_title
      lo_message_app: '', //in app message lt_app_message
      lo_message_winner: '', // push message lt_push_message
      //lo_draw: moment().format('l HH:mm'), // lt_draw_date
      lo_draw: null,
      lo_latitude: '',
      lo_longitude: '',
      lo_point: null,
      lottery_id: null,
      lo_radius: 1,
      lo_unit: 'km',
      r: 1000,
      lo_registration: false, // lt_registered_only
      lo_status: '', // draft
      lo_first_login_starting: null,
      lo_first_login_ending: null,
      games: [],
      //primary_language: this.props.auth.apps[0] ? this.props.auth.apps[0].ap_primary_language : PRIMARY_LANGUAGE,
      //primary_language: this.primary_language_id,
      game_id: null,
      game_type: GAME_TYPE,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const all = VOTINGS_ALL;
    const paging = VOTING_PAGING;
    const details = VOTING_DETAILS;
    const lang = this.props.auth.lang;
    const column = VOTING_COLUMN;
    const sortOrder = VOTING_SORT_ORDER;
    const lottery_list = LOTTERYLIST;

    this.initializeState(id);
    if (id) {
      const authKey = localStorage.getItem('x-auth-key');
      const ws_id = this.props.ws_id;
      this.props.fetchLottery(id, authKey, ws_id);
    } else {
      this.onAddPrize(true);
    }
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.ws_id;
    const message_type = 'private_message';
    const lottery_id = id;

    let application_id;
    try {
      application_id =
        this.props.workspace.workspaces[this.props.ws_id].cnc_application; //get from ws
    } catch {
      console.err('Could not find application id');
    }
    this.props.fetchMessageTemplates(
      authKey,
      ws_id,
      MODULE_ID,
      message_type,
      application_id
    );
    this.props.fetchVotings({
      authKey,
      module_id: VOTING_MODULE_ID,
      ws_id,
      start: 1,
      lang,
      all,
      paging,
      details,
      column,
      sortOrder,
      lottery_id,
      lottery_list,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.lottery.status.fetch !== 'success' &&
      this.props.lottery.status.fetch === 'success'
    ) {
      this.props.resetFetchLottery();
      this.initializeState(this.props.match.params.id);
    }

    // after fetching votings
    /*if (prevProps.voting.status.fetchAll !== 'success' && this.props.voting.status.fetchAll === 'success') {
      this.initEditor();
    }*/

    // after fetching the message
    if (
      prevProps.template.status !== 'success' &&
      this.props.template.status === 'success' &&
      !this.props.match.params.id
    ) {
      this.initEditor();
    }
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.ws_id;
    if (
      prevProps.lottery.status.add !== 'success' &&
      this.props.lottery.status.add === 'success'
    ) {
      this.props.resetAddLottery();
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Lottery Saved successfully'),
        time: TOAST_DELAY,
      });
      const { lottery_id } = this.props.lottery.lottery;
      this.props.history.push(`/app/lottery/manage/${lottery_id}`);
    }

    if (
      prevProps.lottery.status.add !== 'error' &&
      this.props.lottery.status.add === 'error'
    ) {
      this.props.resetAddLottery();
      const errorObj = getErrorMessage(
        this.props.lottery.error,
        'Error adding lottery'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }

    if (
      prevProps.lottery.status.edit !== 'success' &&
      this.props.lottery.status.edit === 'success'
    ) {
      this.props.resetEditLottery();

      toast({
        type: 'success',
        title: T('Success'),
        description: T('Lottery Edited successfully'),
        time: TOAST_DELAY,
      });
      const { id } = this.props.match.params;

      this.props.fetchLottery(id, authKey, ws_id);
    }

    if (
      prevProps.lottery.status.edit !== 'error' &&
      this.props.lottery.status.edit === 'error'
    ) {
      this.props.resetEditLottery();
      const errorObj = getErrorMessage(
        this.props.lottery.error,
        'Error editing lottery'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }

    if (
      prevProps.lottery.status.delete !== 'error' &&
      this.props.lottery.status.delete === 'error'
    ) {
      this.props.resetDeleteLottery();
      const errorObj = getErrorMessage(
        this.props.lottery.error,
        'Error deleting lottery'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  onClick = () => {
    // Todo: goBack may result in bad user experience, should be changed at other places
    // this.props.history.goBack();
    this.props.history.push({ pathname: '/app/lottery/list' });
  };

  onSchedule = e => {
    const formIsValid = this.validateInputs();
    if (formIsValid) {
      e.stopPropagation();
      const confirmType = this.state.drawImmediately ? 'Draw' : 'Save';
      this.setState({ showConfirm: true, confirmType });
    }
  };

  onScheduleConfirm = () => {
    this.setState({ showConfirm: false }, () => this.postData());
  };

  onAddPrize(reset = false) {
    const defaultPrize = {
      ...this.defaultPrize,
      key: Math.random(),
      gp_order:
        this.props.prizes && this.props.prizes.length
          ? this.props.prizes.length + 1
          : 1,
    };
    this.props.addMorePrize(defaultPrize, reset);
  }

  onRemove = (key, id) => {
    this.setState({
      showConfirm: true,
      confirmType: 'Delete',
      selectedPrizeId: id,
      selectedPrizeKey: key,
    });
  };

  onDeleteConfirm = () => {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.ws_id;
    let lottery_id;
    try {
      lottery_id = this.props.lottery.lottery[0].lottery_id;
    } catch (e) {
      console.error('Failed to get lottery id ', e);
    }
    const { selectedPrizeId, selectedPrizeKey } = this.state;
    this.setState({ showConfirm: false }, () =>
      this.props.deleteLotteryPrize(
        authKey,
        lottery_id,
        selectedPrizeId,
        ws_id,
        selectedPrizeKey
      )
    );
  };

  onUpdatePrize(key, name, val) {
    const updated = this.props.prizes.map(prize => {
      if (prize.key === key) {
        const newVal =
          name === 'gp_number' || name === 'gp_order' ? parseInt(val, 10) : val;
        return { ...prize, [name]: newVal };
      }
      return prize;
    });
    this.props.editPrizesInit(updated);
  }

  onCancelConfirm = () => {
    this.setState({ showConfirm: false });
  };

  onConfirm = () => {
    const deletePrize = this.state.confirmType === 'Delete' || false;
    if (deletePrize) {
      this.onDeleteConfirm();
    } else {
      this.onScheduleConfirm();
    }
  };

  onResetPrizes() {
    this.props.resetPrizes();
  }

  /** editor state **/
  onEditorStateChange(editorState) {
    let rawContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const { lo_language, editorContentCache, sameAsPush } = this.state;
    const index = _.findIndex(
      editorContentCache,
      item => item.lo_language === lo_language
    );
    // update state
    if (index !== -1) {
      const {
        lo_title_winner,
        lo_message_winner,
        lo_inapp_template_id,
        lo_message_template_id,
      } = editorContentCache[index];
      let { lo_title_app } = editorContentCache[index];
      if (sameAsPush) {
        lo_title_app = lo_title_winner;
        const contentBlock = htmlToDraft(lo_message_winner);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        rawContent = EditorState.createWithContent(contentState);
      }
      let newCache = {
        lo_language,
        lo_message_app: rawContent,
        lo_title_app,
        lo_title_winner,
        lo_message_winner,
      };
      if (lo_inapp_template_id) {
        newCache = { ...newCache, lo_inapp_template_id };
      }
      if (lo_message_template_id) {
        newCache = { ...newCache, lo_message_template_id };
      }
      editorContentCache.splice(index, 1, newCache);
      this.setState({
        editorContentCache,
        editorState,
        lo_message_app: rawContent,
      });
    } else {
      //create new element in state array
      this.setState({
        editorContentCache: editorContentCache.concat({
          lo_language,
          lo_message_app: rawContent,
          lo_title_app: this.state.lo_title_app,
          lo_message_winner: '',
          lo_title_winner: '',
        }),
        editorState,
      });
    }
  }

  onContentLanguageChange(e, { value }) {
    const { editorContentCache } = this.state;
    const index = _.findIndex(
      editorContentCache,
      item => item.lo_language === value
    );
    if (index !== -1) {
      const {
        lo_title_winner,
        lo_message_winner,
        lo_message_app,
        lo_title_app,
        lo_inapp_template_id,
        lo_message_template_id,
      } = editorContentCache[index];
      const contentBlock = htmlToDraft(lo_message_app);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      let newCache = {
        lo_message_app,
        lo_language: value,
        lo_title_app,
        lo_title_winner,
        lo_message_winner,
      };
      if (lo_inapp_template_id) {
        newCache = { ...newCache, lo_inapp_template_id };
      }
      if (lo_message_template_id) {
        newCache = { ...newCache, lo_message_template_id };
      }
      editorContentCache.splice(index, 1, newCache);
      this.setState({
        editorState,
        editorContentCache,
        lo_message_app,
        lo_language: value,
        lo_title_app,
        lo_title_winner,
        lo_message_winner,
      });
    } else {
      this.setState({
        editorState: EditorState.createEmpty(),
        editorContentCache: editorContentCache.concat({
          lo_language: value,
          lo_title_app: '',
          lo_message_app: '',
          lo_title_winner: '',
          lo_message_winner: '',
        }),
        lo_language: value,
        lo_title_app: '',
        lo_message_app: '',
        lo_title_winner: '',
        lo_message_winner: '',
      });
    }
  }

  onGameChange(e, { value }) {
    this.setState({
      game_id: value ? value : null,
      game_type: value ? GAME_TYPE_LOTTERY_AND_VOTING : GAME_TYPE,
    });
    if (this.state.lo_draw === null) {
      this.setDefaultDrawDate(value);
    }
  }

  setDefaultDrawDate(game_id) {
    const gameObject = this.props.voting.data.find(
      game => game.game_id === game_id
    );
    this.setState({ lo_draw: moment(gameObject.ga_game_stop).toISOString() });
  }

  getLanguageList = () =>
    _.map(this.props.languages, language => ({
      text: T(language.ln_name),
      value: language.language_id,
    }));

  setLocation = (lat, lng, radius, unit, r) => {
    this.setState({
      lo_latitude: lat,
      lo_longitude: lng,
      lo_radius: radius,
      lo_unit: unit,
      r,
    });
  };

  /** editor state **/
  // Todo: improve flow
  setPushMessageSameAsInApp = (name, same) => {
    const { lo_language } = this.state;
    let { editorContentCache, editorState } = this.state;
    editorState = same ? EditorState.createEmpty() : editorState;
    const index = _.findIndex(
      editorContentCache,
      item => item.lo_language === lo_language
    );
    editorContentCache = editorContentCache.map(ed => {
      const newMsgTitle = ed.lo_title_winner || '';
      const newMsgBody = ed.lo_message_winner || '';
      const lo_inapp_template_id = ed.lo_inapp_template_id || null;
      const lo_message_template_id = ed.lo_message_template_id || null;
      let newCache = {
        ...ed,
        lo_title_app: newMsgTitle,
        lo_message_app: newMsgBody,
        lo_title_winner: newMsgTitle,
        lo_message_winner: newMsgBody,
      };
      if (lo_inapp_template_id) {
        newCache = { ...newCache, lo_inapp_template_id };
      }
      if (lo_message_template_id) {
        newCache = { ...newCache, lo_message_template_id };
      }
      return newCache;
    });
    if (index !== -1) {
      const { lo_message_winner } = editorContentCache[index];
      if (lo_message_winner) {
        const contentBlock = htmlToDraft(lo_message_winner);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        editorState = EditorState.createWithContent(contentState);
      }
    }
    this.setState({
      editorState,
      editorContentCache,
      lo_title_app: this.state.lo_title_winner,
      lo_message_app: this.state.lo_message_winner,
      [name]: same,
    });
  };

  list_votings = () =>
    _.map(this.props.voting.data, voting => ({
      text: T(voting.ga_name),
      value: voting.game_id,
    }));

  list_votings_sorted = () =>
    /*const sorted = this.props.voting.data.sort((a, b) => ((a.ga_name > b.ga_name) ? 1 : -1));
    return sorted.map((voting) => (({ text: T(voting.ga_name), value: voting.game_id })));*/
    _.map(
      this.props.voting.data.sort((a, b) => (a.ga_name > b.ga_name ? 1 : -1)),
      voting => ({ text: T(voting.ga_name), value: voting.game_id })
    );

  /** handle messages, a rough flow  **/
  // Todo: improve the flow and split it
  handleMessages = (name, value) => {
    let editorState = EditorState.createEmpty();
    const { lo_language, editorContentCache, sameAsPush } = this.state;
    const index = _.findIndex(
      editorContentCache,
      item => item.lo_language === lo_language
    );
    if (index !== -1) {
      let { lo_title_app, lo_message_app, lo_title_winner, lo_message_winner } =
        editorContentCache[index];
      if (sameAsPush) {
        if (name === 'lo_title_winner') {
          lo_title_app = value;
          lo_title_winner = value;
        }
        if (name === 'lo_message_winner') {
          lo_message_app = value;
          lo_message_winner = value;
        }
      } else {
        lo_title_app = name === 'lo_title_app' ? value : lo_title_app;
        lo_message_app = name === 'lo_message_app' ? value : lo_message_app;
        lo_title_winner = name === 'lo_title_winner' ? value : lo_title_winner;
        lo_message_winner =
          name === 'lo_message_winner' ? value : lo_message_winner;
      }
      if (lo_message_app) {
        const contentBlock = htmlToDraft(lo_message_app);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        editorState = EditorState.createWithContent(contentState);
      }
      editorContentCache.splice(index, 1, {
        ...editorContentCache[index],
        lo_title_app,
        lo_message_app,
        lo_title_winner,
        lo_message_winner,
      });
      this.setState({
        editorState,
        editorContentCache,
        lo_language,
        lo_title_app,
        lo_message_app,
        lo_title_winner,
        lo_message_winner,
      });
    } else {
      this.setState({
        editorState,
        editorContentCache: editorContentCache.concat({
          //lo_language: this.props.primary_language,
          lo_language: this.primary_language_id,
          [name]: value,
        }),
        [name]: value,
      });
    }
  };

  validateInputs = () => {
    const requiredFields = [
      'lo_name',
      'lo_title_winner',
      'lo_title_app',
      'lo_message_winner',
      'lo_message_app',
    ];
    const invalidFields = _.filter(
      requiredFields,
      field => this.state[field].trim() === ''
    );
    if (invalidFields.length) {
      const errorFields = _.reduce(
        invalidFields,
        (acc, field) => ({ ...acc, [`${field}Error`]: true }),
        {}
      );
      this.setState({ ...errorFields });
      return false;
    }
    return true;
  };

  postData = status => {
    const formIsValid = this.validateInputs();
    if (formIsValid) {
      this.setState({ is_dirty: false });
      const { id } = this.props.match.params;
      let game = null;
      let game_object = null;

      if (id) {
        // this is en existing lottery
        game = this.state.games[0];
        if (game.ga_type === GAME_TYPE_LOTTERY_AND_VOTING) {
          // this is a voting included into lottery
          if (this.state.game_id === game.game_id) {
            // voto has not been changed
            game_object = game;
          } else {
            game_object = {
              ga_user_max_stakes: 1, // TODO is it allways 1
              ga_type: this.state.game_type,
              game_id: this.state.game_id,
              prizes: [],
            };
          }
        }
      }
      if (game_object === null) {
        game_object = {
          ga_user_max_stakes: 1,
          ga_type: this.state.game_type,
          game_id: this.state.game_id,
          prizes: [],
        };
        if (this.state.game_type === GAME_TYPE) {
          game_object.ga_game_stop = this.state.lo_draw;
        }
      }

      const games = { games: [game_object] };
      let game_id;
      let updated;
      if (id) {
        try {
          game_id =
            this.state.game_id !== null
              ? this.state.game_id
              : this.props.lottery.lottery[0].games[0].game_id;
          updated = { ...games.games[0], prizes: this.props.prizes, game_id };
        } catch (e) {
          updated = { ...games.games[0], prizes: this.props.prizes };
          console.error('Error retrieving game_id', e);
        }
      } else {
        // mutate
        updated = { ...games.games[0], prizes: this.props.prizes };
      }

      const lottery =
        status === 'draft'
          ? { ...this.state, games: [updated], lo_status: 'draft' }
          : { ...this.state, games: [updated], lo_status: 'scheduled' };

      const authKey = localStorage.getItem('x-auth-key');
      const ws_id = this.props.ws_id;
      const merchant_id =
        this.props.workspace.workspaces[this.props.ws_id].cnc_company;
      const messages = this.state.editorContentCache;
      const lotteryMessages = {
        ...lottery,
        lo_latitude: lottery.lo_latitude || null,
        lo_longitude: lottery.lo_longitude || null,
        messages,
      };
      const prunedLottery = _.omit(lotteryMessages, [
        'editorState',
        'editorContentCache',
        'showConfirm',
        'confirmType',
        'sameAsPush',
        'drawImmediately',
        'is_dirty',
        'lo_language',
        'lo_title_app',
        'lo_title_winner',
        'lo_message_app',
        'lo_message_winner',
      ]); //prune json
      if (id) {
        // console.log('lottery data for update', JSON.stringify(prunedLottery));
        //update lottery
        this.props.editLottery(authKey, id, prunedLottery, ws_id, merchant_id);
      } else {
        const lo_application =
          this.props.workspace.workspaces[this.props.ws_id].cnc_application;
        const updatedLottery = { ...prunedLottery, lo_application, messages };
        // console.log('lottery data for add ', JSON.stringify(updatedLottery));
        // add lottery
        this.props.addLottery(authKey, updatedLottery, ws_id, merchant_id);
      }
    }
  };

  initializeState = edit => {
    let lottery;
    const { id } = this.props.match.params;
    try {
      if (this.props.lottery.lottery !== undefined) {
        lottery = this.props.lottery.lottery[0];
      }
    } catch (e) {
      console.error('Fetch lottery in progress', e);
    }
    let primary_language = this.primary_language_id;
    if (edit && lottery && parseInt(id) === lottery.lottery_id) {
      let editorState = EditorState.createEmpty();
      // 1. matching content for the switch
      let sameAsPush = false;
      let lo_title_app = '';
      let lo_title_winner = '';
      let lo_message_app = '';
      let lo_message_winner = '';
      const game = lottery.games[0];

      const game_id =
        game.ga_type === GAME_TYPE_LOTTERY_AND_VOTING ? game.game_id : null;
      const lo_draw = lottery.lo_draw
        ? moment(lottery.lo_draw).toISOString()
        : null;
      try {
        if (lottery.messages.length > 0) {
          const defaultMessage = lottery.messages[0];
          if (
            defaultMessage.lo_message_app ===
              defaultMessage.lo_message_winner &&
            defaultMessage.lo_title_app === defaultMessage.lo_title_winner
          ) {
            sameAsPush = true;
          }
        }
      } catch {
        console.log('Could not compare messages in the lottery', lottery);
      }
      // 2. calculate radius and distance
      const lo_unit = lottery.lo_unit ? lottery.lo_unit : 'km';
      let r;
      if (lottery.lo_radius) {
        r =
          lo_unit === 'km'
            ? lottery.lo_radius * 1000
            : lottery.lo_radius * 1609.34;
      }
      const lo_radius = lottery.lo_radius ? lottery.lo_radius : 1;
      const lo_latitude = lottery.lo_latitude ? lottery.lo_latitude : '';
      const lo_longitude = lottery.lo_longitude ? lottery.lo_longitude : '';
      // init prizes
      let prizes = [];
      try {
        const prizes_unsorted = lottery.games[0].prizes;
        const prizes_sorted = prizes_unsorted.sort(
          (a, b) => a.gp_order - b.gp_order
        );
        prizes = prizes_sorted.map(prize =>
          Object.assign(prize, { key: Math.random() })
        );
      } catch (e) {
        console.error('Error ', e);
      }
      this.props.editPrizesInit(prizes);
      // cms content
      let editorContentCache = [];

      try {
        const contentCache = lottery.messages;
        let contentObject = contentCache.find(
          content => content.lo_language === primary_language
        );
        if (!contentObject) {
          contentObject = contentCache[0];
        }
        primary_language = contentObject.lo_language
          ? contentObject.lo_language
          : primary_language;

        if (contentObject.lo_message_app) {
          lo_message_app = contentObject.lo_message_app;
          const contentBlock = htmlToDraft(lo_message_app);
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          editorState = EditorState.createWithContent(contentState);
        }
        lo_title_app = contentObject.lo_title_app
          ? contentObject.lo_title_app
          : '';
        lo_title_winner = contentObject.lo_title_winner
          ? contentObject.lo_title_winner
          : '';
        lo_message_winner = contentObject.lo_message_winner
          ? contentObject.lo_message_winner
          : '';

        /*primary_language = contentCache[0] && contentCache[0].lo_language ? contentCache[0].lo_language : primary_language;
        if (contentCache[0] && contentCache[0].lo_message_app) {
          lo_message_app = contentCache[0].lo_message_app;
          const contentBlock = htmlToDraft(lo_message_app);
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          editorState = EditorState.createWithContent(contentState);
        }
        lo_title_app = contentCache[0] && contentCache[0].lo_title_app ? contentCache[0].lo_title_app : '';
        lo_title_winner = contentCache[0] && contentCache[0].lo_title_winner ? contentCache[0].lo_title_winner : '';
        lo_message_winner = contentCache[0] && contentCache[0].lo_message_winner ? contentCache[0].lo_message_winner : '';*/
        editorContentCache = contentCache || [];
      } catch (e) {
        console.error('Error ', e);
      }

      this.setState({
        ...this.state,
        ...lottery,
        lo_longitude,
        lo_latitude,
        r,
        lo_radius,
        lo_unit,
        sameAsPush,
        editorState,
        editorContentCache,
        lo_language: primary_language,
        lo_title_app,
        lo_message_app,
        lo_title_winner,
        lo_message_winner,
        lo_draw,
        game_id,
      });
    }
  };

  //init it with the pre-filled templates matching languages in the list of languages
  initEditor() {
    const { data } = this.props.template;
    let editorState = EditorState.createEmpty();
    const editorContentCache = [];
    let default_app_title = '';
    let default_app_message = '';
    //const primary_language = data[0] && data[0].mt_language ? data[0].mt_language : this.primary_language_id;
    const primary_language = this.primary_language_id;
    data.forEach(dt => {
      const lo_language = dt.mt_language;
      const lo_message_app = dt.mt_body;
      const lo_title_app = dt.mt_subject;
      if (lo_language === primary_language) {
        default_app_title = lo_title_app;
        default_app_message = lo_message_app;
      }
      if (lo_message_app) {
        const contentBlock = htmlToDraft(lo_message_app);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        editorState = EditorState.createWithContent(contentState);
      }
      if (lo_language) {
        editorContentCache.push({
          lo_language,
          lo_message_app,
          lo_title_app,
          lo_title_winner: '',
          lo_message_winner: '',
        });
      }
    });

    this.setState({
      editorContentCache,
      lo_title_app: default_app_title,
      lo_message_app: default_app_message,
      editorState,
      lo_language: primary_language,
    });
  }

  handleInputChange = (name, value, type) => {
    if (
      this.state.sameAsPush &&
      (name === 'lo_title_winner' || name === 'lo_message_winner')
    ) {
      this.setState({ [`lo_${type}_app`]: value, [name]: value });
    } else if (name === 'drawImmediately') {
      if (value) {
        this.setState({ saved_lo_time: this.state.lo_draw });
      }
      this.setState({
        [name]: value,
        lo_draw: value ? moment().toISOString() : this.state.saved_lo_time,
      });
    } else if (
      name === 'lo_draw' ||
      name === 'lo_first_login_starting' ||
      name === 'lo_first_login_ending'
    ) {
      this.setState({
        [name]: value ? moment(value, 'l HH:mm').toISOString() : null,
      });
    } else {
      this.setState({ [name]: value });
    }
  };

  saveLotteryDraft = () => {
    const status = 'draft';
    this.postData(status);
  };

  dialogueText = type => {
    switch (type) {
      case 'Draw':
        return T(
          'The prizes will be drawn and winners will be notified immediately! NB, The processing may take a few minutes to complete.'
        );
      case 'Save':
        return `${T(
          'The prizes will be drawn and winners will be notified at'
        )} ${moment(this.state.lo_draw).format('l HH:mm')}`;
      default:
        return T('Are you sure you want to delete the selected prize?');
    }
  };

  imageUpdate(url, urlLarge, key) {
    this.setState({ is_dirty: true }, () =>
      this.props.updatePrizeImage({ url, key })
    );
  }

  render() {
    const { drawImmediately, sameAsPush, lo_status } = this.state;
    const confirmationText = this.dialogueText(this.state.confirmType);
    const { id } = this.props.match.params;
    const isEditable = lo_status !== 'finished';
    const { template, lottery } = this.props;
    if (
      (id && !this.state.lottery_id) ||
      lottery.status.fetch === 'waiting' ||
      template.status !== 'success'
    ) {
      return <Loader active />;
    }
    return (
      <>
        <Header attached="top" inverted>
          {id ? T('Edit Lottery') : T('Add Lottery')}
        </Header>
        <Segment attached="bottom">
          <Prompt
            when={this.state.is_dirty}
            message={T(
              'You have unsaved changes, are you sure you want to leave this page?'
            )}
          />
          <Form>
            <Segment>
              <Label attached="top">{T('Arvonnan perustiedot')}</Label>
              <Form.Group>
                <Form.Input
                  width={8}
                  disabled={!isEditable}
                  required
                  name="lo_name"
                  label={T('Name')}
                  placeholder={T(
                    'Syötä tähän nimi, jolla tunnistat arvonnan hallinnassa'
                  )}
                  value={this.state.lo_name}
                  onChange={(e, { name, value }) =>
                    this.handleInputChange(name, value)
                  }
                />
              </Form.Group>
              <Form.Group>
                {!this.state.drawImmediately && (
                  <DateTimeInput
                    closable
                    name="lo_draw"
                    disabled={this.state.drawImmediately || !isEditable}
                    dateTimeFormat="l HH:mm"
                    label={T('Arvonta-ajankohta (päivä ja kellonaika)...')}
                    value={
                      this.state.lo_draw
                        ? moment(this.state.lo_draw).format('l HH:mm')
                        : ''
                    }
                    iconPosition="left"
                    onChange={(e, { name, value }) =>
                      this.handleInputChange(name, value)
                    }
                    timeFormat="24"
                    autoComplete="off"
                    history="off"
                    animation="off"
                    required
                  />
                )}
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Checkbox
                  toggle
                  disabled={!isEditable}
                  name="drawImmediately"
                  label={T(
                    '...tai suorita arvonta välittömästi, kun arvonta on tallennettu'
                  )}
                  checked={this.state.drawImmediately}
                  onChange={(e, { name, checked }) =>
                    this.handleInputChange(name, checked)
                  }
                />
              </Form.Group>
            </Segment>
            <Menu attached="top" color="grey" size="mini" inverted secondary>
              <Menu.Item header>{T('Voittoviestien tekstisisällöt')}</Menu.Item>
              <Menu.Menu position="right">
                <Menu.Item header>{T('Language')}:</Menu.Item>
                <Dropdown
                  item
                  disabled={!isEditable}
                  required
                  selection
                  search
                  name="lo_language"
                  label={T('Language')}
                  value={this.state.lo_language}
                  options={this.getLanguageList()}
                  // onChange={(e, { name, value }) => this.handleInputChange(name, value)}
                  onChange={this.onContentLanguageChange.bind(this)}
                />
              </Menu.Menu>
            </Menu>
            <Segment attached="bottom">
              <Grid columns="equal" stackable>
                <Grid.Row stretched>
                  <Grid.Column verticalAlign="top">
                    <Segment>
                      <Label attached="top">
                        {T('Voittajalle lähtevä push-ilmoitus')}
                      </Label>
                      <Form.Input
                        required
                        disabled={!isEditable}
                        name="lo_title_winner"
                        label={T('Ilmoituksen otsikko')}
                        value={this.state.lo_title_winner}
                        onChange={(e, { name, value }) =>
                          this.handleMessages(name, value)
                        }
                        // onChange={(e, { name, value }) => this.handleInputChange(name, value, 'title')}
                      />
                      <Form.TextArea
                        required
                        disabled={!isEditable}
                        rows={5}
                        name="lo_message_winner"
                        label={T('Ilmoituksen leipäteksti')}
                        value={this.state.lo_message_winner}
                        onChange={(e, { name, value }) =>
                          this.handleMessages(name, value)
                        }
                        // onChange={(e, { name, value }) => this.handleInputChange(name, value, 'message')}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column verticalAlign="top">
                    <Segment>
                      <Label attached="top">
                        {T(
                          'Voittajalle ilmestyvä ponnahdusikkuna sovelluksessa'
                        )}
                      </Label>
                      <Form.Input
                        floated="right"
                        required
                        disabled={this.state.sameAsPush || !isEditable}
                        name="lo_title_app"
                        label={T('Ponnahdusikkunan tekstin otsikko')}
                        value={this.state.lo_title_app}
                        onChange={(e, { name, value }) =>
                          this.handleMessages(name, value)
                        }
                        // onChange={(e, { name, value }) => this.handleInputChange(name, value)}
                      />
                      <Form.Group widths="equal">
                        <Form.Field required>
                          <label style={{ opacity: sameAsPush ? 0.2 : 1 }}>
                            {T('Ponnahdusikkunan leipäteksti')}
                          </label>
                          <Editor
                            editorStyle={{ opacity: sameAsPush ? 0.2 : 1 }}
                            toolbarStyle={{ opacity: sameAsPush ? 0.2 : 1 }}
                            toolbar={DEFAULT_TOOLBAR_OPTIONS}
                            readOnly={this.state.sameAsPush || !isEditable}
                            editorState={this.state.editorState}
                            onEditorStateChange={this.onEditorStateChange.bind(
                              this
                            )}
                            required
                            handlePastedText={handlePastedText}
                            name="lo_message_app"
                            label={T('Winner In-App Message')}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Checkbox
                        toggle
                        name="sameAsPush"
                        disabled={!isEditable}
                        label={T('Kopioi teksti push-ilmoituksesta')}
                        checked={this.state.sameAsPush}
                        onChange={(e, { name, checked }) =>
                          this.setPushMessageSameAsInApp(name, checked)
                        }
                      />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <Segment secondary>
              <Label attached="top">
                {T('Rajaa arvonnassa mukana olevia')}
              </Label>

              <Grid columns="equal" stackable>
                <Grid.Row stretched>
                  <Grid.Column verticalAlign="top">
                    <Form.Group widths="equal">
                      <Form.Select
                        search
                        disabled={!isEditable}
                        name="game_id"
                        loading={
                          this.props.voting.status.fetchAll === 'waiting'
                        }
                        //label={T('Select Voting')}
                        label={
                          <Header as="label">
                            {T('Vain tiettyyn äänestykseen osallistuneet')}
                            {getPopupContent(
                              POPUP_CONTENT_1,
                              POPUP_ICON_SIZE,
                              POPUP_COLOR
                            )}
                          </Header>
                        }
                        placeholder={T('Select Voting')}
                        value={this.state.game_id}
                        options={this.list_votings()}
                        onChange={this.onGameChange.bind(this)}
                        clearable
                      />
                    </Form.Group>
                    <Segment>
                      <Label attached="top">
                        {T('Käyttäjä avannut sovelluksen ensimmäistä kertaa')}
                      </Label>
                      <Form.Group>
                        <DateTimeInput
                          closable
                          disabled={!isEditable}
                          name="lo_first_login_starting"
                          dateTimeFormat="l HH:mm"
                          label={T('Alkaen')}
                          value={
                            this.state.lo_first_login_starting
                              ? moment(
                                  this.state.lo_first_login_starting
                                ).format('l HH:mm')
                              : ''
                          }
                          iconPosition="left"
                          onChange={(e, { name, value }) =>
                            this.handleInputChange(name, value)
                          }
                          timeFormat="24"
                          autoComplete="off"
                          history="off"
                          animation="off"
                        />
                        <DateTimeInput
                          closable
                          name="lo_first_login_ending"
                          disabled={
                            this.state.lo_first_login_starting == '' ||
                            !isEditable
                          }
                          dateTimeFormat="l HH:mm"
                          placeholder={T('arvontahetki')}
                          label={T('Päättyen')}
                          value={
                            this.state.lo_first_login_ending
                              ? moment(this.state.lo_first_login_ending).format(
                                  'l HH:mm'
                                )
                              : ''
                          }
                          iconPosition="left"
                          onChange={(e, { name, value }) =>
                            this.handleInputChange(name, value)
                          }
                          timeFormat="24"
                          autoComplete="off"
                          history="off"
                          animation="off"
                        />
                      </Form.Group>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column verticalAlign="top">
                    <Form.Group widths="equal">
                      <Form.Checkbox
                        toggle
                        disabled={!isEditable}
                        checked={this.state.lo_registration}
                        name="lo_registration"
                        label={T('Registered users only')}
                        onChange={(e, { name, checked }) =>
                          this.handleInputChange(name, checked)
                        }
                      />
                    </Form.Group>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid columns="equal" stackable>
                <Grid.Row stretched>
                  <Grid.Column verticalAlign="top">
                    <LotteryRegion
                      setLocation={this.setLocation.bind(this)}
                      isActive={
                        !isNaN(parseFloat(this.state.lo_latitude)) &&
                        !isNaN(parseFloat(this.state.lo_longitude))
                      }
                      isEditable={isEditable}
                      region={{
                        lat: this.state.lo_latitude,
                        lng: this.state.lo_longitude,
                        r: this.state.r,
                        radius: this.state.lo_radius,
                        unit: this.state.lo_unit,
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>

            <Form.Field>
              <PrizeContainer
                isEditable={isEditable}
                onSavePrize={this.postData.bind(this)}
                ws_id={this.props.ws_id}
                workspace={this.props.workspace}
                onAddPrize={this.onAddPrize.bind(this)}
                onRemove={this.onRemove.bind(this)}
                onUpdatePrize={this.onUpdatePrize.bind(this)}
                prizes={this.props.prizes}
                imageUpdate={this.imageUpdate.bind(this)}
                module_id={MODULE_ID}
              />
            </Form.Field>
            <Button.Group>
              {isEditable && (
                <Button
                  primary
                  icon="clock"
                  content={drawImmediately ? T('Draw Now') : T('Save')}
                  onClick={this.onSchedule}
                />
              )}
              {isEditable && (
                <Button
                  color="teal"
                  content={T('Save Draft')}
                  icon="save"
                  onClick={this.saveLotteryDraft}
                />
              )}
              <Button
                secondary
                icon="undo"
                content={T('Cancel')}
                onClick={this.onClick}
              />
            </Button.Group>
            <Confirm
              header={T('Are you sure?')}
              content={confirmationText}
              cancelButton={T('Cancel')}
              confirmButton={T(this.state.confirmType)}
              open={this.state.showConfirm}
              onCancel={this.onCancelConfirm}
              onConfirm={this.onConfirm}
              size="tiny"
            />
          </Form>
        </Segment>
      </>
    );
  }
}

function mapStateToProps({
  auth,
  workspace,
  languages,
  lottery,
  messageTemplate,
  voting,
}) {
  return {
    auth,
    ws_id: workspace.ws_id,
    workspace,
    languages: languages.languages,
    lottery,
    prizes: lottery.prizes,
    template: messageTemplate,
    voting,
  };
}
export default connect(mapStateToProps, {
  addLottery,
  editLottery,
  fetchLottery,
  resetAddLottery,
  resetEditLottery,
  resetFetchLottery,
  resetDeleteLottery,
  addMorePrize,
  editPrizesInit,
  resetPrizes,
  deleteLotteryPrize,
  updatePrizeImage,
  fetchMessageTemplates,
  fetchVotings,
  fetchLotteries,
})(withRouter(ManageLottery));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import {
  fetchLocations,
  fetchPriceListProducts,
  fetchPriceList,
} from '../../Redux/actions';
import ManagePriceList from './ManagePriceList';
import PriceListTickets from './PriceListTickets';
import { Spinner } from '../Common/Spinner';
import { T } from '../Common/Helpers';

class PriceListContainer extends Component {
  componentDidMount() {
    //const pp_price_list = this.props.location.state.price_list_id;
    const pp_price_list = this.props.match.params.priceListId;
    // const pr_type = 'entry';
    const { ws_id } = this.props.workspace;
    const authKey = localStorage.getItem('x-auth-key');
    // this.props.fetchLocations(authKey, 'pickup_points');
    this.props.fetchPriceList({ authKey, ws_id, pp_price_list });
    this.props.fetchPriceListProducts({ authKey, pp_price_list, ws_id });
  }
  containerFetchPriceListProducts = params => {
    this.props.fetchPriceListProducts(params);
  };

  containerFetchPriceList = params => {
    this.props.fetchPriceList(params);
  };

  render() {
    if (this.props.fetchAll === 'waiting') {
      return <Spinner />;
    } else if (this.props.fetchAll === 'error') {
      return (
        <Message
          header={T('Error')}
          error
          icon="exclamation triangle"
          content={T('Could not list products')}
        />
      );
    }
    // console.log('PROPS: LOCATION STATE: ', this.props.location.state);

    //const { priceList, pp_price_list, price_list_id } = this.props.location.state;

    return (
      <div>
        <ManagePriceList
          {...this.props}
          //priceList={this.priceList}
          priceList={this.containerFetchPriceList}
          //pp_price_list={this.pp_price_list}
          //price_list_id={this.price_list_id}
        />
        <PriceListTickets
          {...this.props}
          productsList={this.props.product.products}
          getListOfProducts={this.containerFetchPriceListProducts}
        />
      </div>
    );
  }
}

function mapStateToProps({ tickets, workspace, product }) {
  return {
    tickets,
    workspace,
    fetchAll: product.fetchStatus,
    product,
  };
}

export default connect(mapStateToProps, {
  fetchLocations,
  fetchPriceListProducts,
  fetchPriceList,
})(withRouter(PriceListContainer));

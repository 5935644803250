import React from "react";
import { Container } from "semantic-ui-react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Nav from "../layout/Nav";

import PriceLists from "../components/Pricelist/PriceLists";
import ManagePriceList from "../components/Pricelist/ManagePriceList";
import PriceListContainer from "../components/Pricelist/PriceListContainer";

const PriceListRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: "150px" }}>
      <Nav />
      <Switch>
        <Route path={`${path}/list`} exact>
          <PriceLists />
        </Route>
        <Route path={`${path}/manage`} exact>
          <ManagePriceList />
        </Route>
        <Route path={`${path}/manage/:priceListId`} exact>
          <PriceListContainer />
        </Route>
      </Switch>
    </Container>
  );
};

export default PriceListRoute;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Radio } from "semantic-ui-react";
import { T } from "../Common/Helpers";
// import src from '../../layout/images/layout.png';

class WizardTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: parseInt(this.props.apTemplate, 10) || null,
    };
    this.onTemplateChecked = this.onTemplateChecked.bind(this);
  }

  componentDidMount() {
    //update template default props
    // const authKey = localStorage.getItem('x-auth-key');
    // this.props.editApplication(authKey, this.props.application_id, 'ap_template', this.props.selectedTemplate, (this.props.onChange));
  }
  //
  // componentDidUpdate(prevProps) {
  //   if (prevProps.applications.operationState.edit === 'waiting' && this.props.applications.operationState.edit === 'success') {
  //     //onChange={(status, keyword, value, refresh) => {
  //     this.props.onChange(true, null, null, false);
  //   }
  // }

  onTemplateChecked(e) {
    this.setState({ selectedTemplate: parseInt(e.target.id, 10) });
    this.props.onComponentValueUpdate("ap_template", e.target.id);
    // this.setState({ selectedTemplate: e.target.id });
  }

  //
  // UNSAFE_componentWillUpdate(nextProps, nextState) {
  //     const authKey = localStorage.getItem('x-auth-key');
  //   if (this.state !== nextState) {
  //     nextProps.editApplication(authKey, nextProps.application_id, 'ap_template', nextState.selectedTemplate, (nextProps.onChange));
  //   }
  // }

  renderTemplates = () => {
    const { templates } = this.props;
    return templates.map((template) => (
      // console.log('Checked: ', this.state.selectedTemplate, template.application_template_id);
      <div
        key={template.application_template_id}
        style={{
          display: "inline-block",
          marginRight: "50px",
          textAlign: "left",
          marginBottom: "50px",
        }}
      >
        <img
          style={{
            width: "207px",
            height: "368px",
          }}
          src={template.at_preview_image_url}
          alt="template"
        />
        <div>
          {" "}
          {T(template.at_name)}
          <Radio
            style={{ marginLeft: 10 }}
            type="radio"
            name="template"
            id={template.application_template_id}
            // onChange={() => this.props.onSelectedTemplateChange({ ap_template: template.application_template_id })}
            onChange={this.onTemplateChecked}
            checked={
              this.state.selectedTemplate ===
              parseInt(template.application_template_id, 10)
            }
          />
        </div>
      </div>
    ));
  };

  render() {
    // console.log('WIZARD Template state: ', this.state);
    return (
      <div style={{ fontSize: "16px" }}>
        <div style={{ marginBottom: "30px" }}>{T("Select template")}</div>
        <div>
          {this.renderTemplates()}
          {/*<div style={{display: 'inline-block', marginRight: '50px', textAlign: 'left'}}>
            <img style={{width: '207px', height: '368px'}} src={src} />
            <p> Carousel <input type="radio" name="template" id="carousel" onClick={this.onTemplateChecked}/></p>
          </div>
          <div style={{display: 'inline-block', marginRight: '50px', textAlign: 'left'}}>
            <img style={{width: '207px', height: '368px'}} src={src} />
            <p> Stack <input type="radio" name="template" id="stack" onClick={this.onTemplateChecked}/></p>
          </div>
          <div style={{display: 'inline-block', marginRight: '50px', textAlign: 'left'}}>
            <img style={{width: '207px', height: '368px'}} src={src} />
            <p> Multi <input type="radio" name="template" id="multi" onClick={this.onTemplateChecked}/></p>
          </div>*/}
        </div>
      </div>
    );
  }
}

WizardTemplate.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.object),
  apTemplate: PropTypes.string,
  onComponentValueUpdate: PropTypes.func,
};

function mapStateToProps({ applications }) {
  return { applications };
}

export default connect(mapStateToProps, {})(WizardTemplate);

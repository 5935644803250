import React, { Component } from 'react';
import { Header, Accordion, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
// import { toast } from 'react-semantic-toasts';
import ProductGeneralFields from './ProductGeneralFields';
import { T } from '../Common/Helpers';

class SubProductAlternativesController extends Component {
  state = { activeIndex: -1 };
  // componentDidUpdate(prevProps) {
  //   if (prevProps.attributeStatus !== 'success' && this.props.attributeStatus === 'success') {
  //     toast({ type: 'success', title: T('Success'), description: T('product attributes updated successfully') });
  //   } else if (prevProps.attributeStatus !== 'error' && this.props.attributeStatus === 'error') {
  //     toast({ type: 'error', title: T('Error'), description: T('Error has occurred during product attribute update') });
  //   }
  // }
  handleClick = i => {
    const index = i;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  render() {
    const { activeIndex } = this.state;
    // console.log('Alt products: ', this.props);
    return (
      <div>
        <Header> {T('Alternative Products')} </Header>
        <Accordion>
          {_.map(this.props.productList, (prod, i) => (
            <React.Fragment key={`alt-container-${i}`}>
              <Accordion.Title
                active={activeIndex === i}
                index={i}
                onClick={() => this.handleClick(i)}
                key={`title-${i}`}
              >
                <Icon name="dropdown" />
                {prod.productName}
              </Accordion.Title>
              <Accordion.Content
                key={`content-${i}`}
                active={activeIndex === i}
              >
                <ProductGeneralFields
                  key={`alt-product-${i}`}
                  product={prod.product}
                  parentId={this.props.parentId}
                  productTypes={this.props.productTypes}
                  onValidSubmit={this.props.onValidSubmit}
                  history={this.props.history}
                  taxList={this.props.taxList}
                />
              </Accordion.Content>
            </React.Fragment>
          ))}
        </Accordion>
      </div>
    );
  }
}

function mapStateToProps({ product, workspace }) {
  return {
    merchantId: workspace.workspaces[workspace.ws_id].cnc_company,
    attributeStatus: product.attributeStatus,
    ws_id: workspace.ws_id,
  };
}
export default connect(mapStateToProps, {})(SubProductAlternativesController);

import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Menu, Icon, Button } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import moment from 'moment';
import { T } from '../../Common/Helpers';

const styles = {
  angleArrow: {
    height: 'inherit',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: '40px',
  },
};

const DATE_FORMAT = 'DD-MM-YYYY';
const MONTH = 'month';
const IS_WEEK = 'isoweek';
const CUSTOM = 'custom';

class ReportDateRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubItem: this.props.activeSubItem
        ? this.props.activeSubItem
        : MONTH,
      startError: {},
      endError: {},
      reportRange: {
        day: {
          start:
            this.props.activeSubItem === 'day' && this.props.start
              ? this.props.start
              : moment().format(DATE_FORMAT),
          end:
            this.props.activeSubItem === 'day' && this.props.end
              ? this.props.end
              : moment().format(DATE_FORMAT),
        },
        month: {
          start:
            this.props.activeSubItem === MONTH && this.props.start
              ? this.props.start
              : moment().startOf(MONTH).format(DATE_FORMAT),
          end:
            this.props.activeSubItem === MONTH && this.props.end
              ? this.props.end
              : moment().endOf(MONTH).format(DATE_FORMAT),
        },
        isoweek: {
          start:
            this.props.activeSubItem === IS_WEEK && this.props.start
              ? this.props.start
              : moment().startOf(IS_WEEK).format(DATE_FORMAT),
          end:
            this.props.activeSubItem === IS_WEEK && this.props.end
              ? this.props.end
              : moment().endOf(IS_WEEK).format(DATE_FORMAT),
        },
        custom: {
          start:
            this.props.activeSubItem === CUSTOM && this.props.start
              ? this.props.start
              : moment().subtract(1, 'years').format(DATE_FORMAT),
          end:
            this.props.activeSubItem === CUSTOM && this.props.end
              ? this.props.end
              : moment().format(DATE_FORMAT),
        },
      },
    };
  }

  setActiveMenu = (key, activeItem) => this.setState({ [key]: activeItem });

  handleDateChange = (name, value) => {
    const { activeSubItem } = this.state;
    const reportRange = _.cloneDeep(this.state.reportRange);
    reportRange[activeSubItem][name] = value;
    if (
      moment(reportRange[activeSubItem].start, DATE_FORMAT) <=
      moment(reportRange[activeSubItem].end, DATE_FORMAT)
    ) {
      this.setState({ [`${name}Error`]: {}, reportRange });
    } else {
      this.setState({ [`${name}Error`]: styles.errorStyle });
    }
  };

  changeRange = (op, rangeType) => {
    let diff = 'days';
    if (rangeType === MONTH) {
      diff = 'months';
    } else if (rangeType === IS_WEEK) {
      diff = 'weeks';
    }

    const range = _.cloneDeep(this.state.reportRange); // deep copy needed for the nested object
    if (op === 'add') {
      range[rangeType].start = moment(range[rangeType].start, DATE_FORMAT)
        .add(1, diff)
        .format(DATE_FORMAT);
      range[rangeType].end = moment(range[rangeType].start, DATE_FORMAT)
        .endOf(rangeType)
        .format(DATE_FORMAT);
    } else {
      range[rangeType].start = moment(range[rangeType].start, DATE_FORMAT)
        .subtract(1, diff)
        .format(DATE_FORMAT);
      range[rangeType].end = moment(range[rangeType].start, DATE_FORMAT)
        .endOf(rangeType)
        .format(DATE_FORMAT);
    }
    if (moment(range[rangeType].end, DATE_FORMAT) <= new Date()) {
      this.setState({ reportRange: range, startError: {}, endError: {} });
    }
  };

  onSetDateRange() {
    const reportRange = _.cloneDeep(this.state.reportRange);
    const { start, end } = reportRange[this.state.activeSubItem];
    this.props.onSendDateRange(start, end, this.state.activeSubItem);
  }

  render() {
    //const { activeSubItem } = this.props;
    const { activeSubItem } = this.state;
    return (
      <>
        <Form>
          <Form.Group>
            <Menu compact style={{ marginLeft: '5px' }}>
              <Menu.Item
                name="Month"
                content={T('Month')}
                active={activeSubItem === MONTH}
                onClick={() => this.setActiveMenu('activeSubItem', MONTH)}
              />
              <Menu.Item
                name="Week"
                content={T('Week')}
                active={activeSubItem === IS_WEEK}
                onClick={() => this.setActiveMenu('activeSubItem', IS_WEEK)}
              />
              <Menu.Item
                name="Day"
                content={T('Day')}
                active={activeSubItem === 'day'}
                onClick={() => this.setActiveMenu('activeSubItem', 'day')}
              />
              <Menu.Item
                name="Custom"
                content={T('Custom')}
                active={activeSubItem === CUSTOM}
                onClick={() => this.setActiveMenu('activeSubItem', CUSTOM)}
              />
            </Menu>
            <Icon
              style={styles.angleArrow}
              size="large"
              name="angle left"
              onClick={() => this.changeRange('subtract', activeSubItem)}
            />
            <DateInput
              disabled={activeSubItem !== CUSTOM}
              style={{ minWidth: '180px', ...this.state.startError }}
              fluid
              closable
              animation="off"
              name="start"
              label={T('Statistic from')}
              onChange={(e, { name, value }) =>
                this.handleDateChange(name, value)
              }
              value={this.state.reportRange[activeSubItem].start}
              dateFormat={DATE_FORMAT}
              maxDate={moment().format(DATE_FORMAT)}
            />
            <DateInput
              disabled={activeSubItem !== CUSTOM}
              style={{ minWidth: '180px', ...this.state.endError }}
              fluid
              closable
              animation="off"
              name="end"
              label={T('Statistic to')}
              onChange={(e, { name, value }) =>
                this.handleDateChange(name, value)
              }
              value={this.state.reportRange[activeSubItem].end}
              dateFormat={DATE_FORMAT}
              maxDate={moment().format(DATE_FORMAT)}
            />
            <Icon
              style={styles.angleArrow}
              size="large"
              name="angle right"
              onClick={() => this.changeRange('add', activeSubItem)}
            />
          </Form.Group>
        </Form>
        <div style={styles.contentItem}>
          <Button
            primary
            content={T('Display Report')}
            onClick={() => this.onSetDateRange()}
          />
        </div>
      </>
    );
  }
}

export default ReportDateRange;

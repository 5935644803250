import React, { Component } from 'react';
import { Segment, Label, Divider } from 'semantic-ui-react';
import { T } from '../Common/Helpers';
import PrizeItemManager from './PrizeItemManager';

class PrizeContainer extends Component {
  render() {
    return (
      <>
        <Segment>
          <Label attached="top"> {T('Prizes')}</Label>
          <PrizeItemManager
            isEditable={this.props.isEditable}
            prizes={this.props.prizes}
            onSavePrize={this.props.onSavePrize}
            onAddPrize={this.props.onAddPrize}
            onRemove={this.props.onRemove}
            onUpdatePrize={this.props.onUpdatePrize}
            imageUpdate={this.props.imageUpdate}
            workspace={this.props.workspace}
            ws_id={this.props.ws_id}
            module_id={this.props.module_id}
          />
        </Segment>
      </>
    );
  }
}

export default PrizeContainer;

import _ from 'lodash';
import {
  FETCH_LOTTERIES_WAITING,
  FETCH_LOTTERIES_SUCCESS,
  FETCH_LOTTERIES_ERROR,
  FETCH_LOTTERIES_RESET,
  ADD_LOTTERY_WAITING,
  ADD_LOTTERY_SUCCESS,
  ADD_LOTTERY_ERROR,
  ADD_LOTTERY_RESET,
  FETCH_LOTTERY_WAITING,
  FETCH_LOTTERY_SUCCESS,
  FETCH_LOTTERY_ERROR,
  FETCH_LOTTERY_RESET,
  EDIT_LOTTERY_WAITING,
  EDIT_LOTTERY_SUCCESS,
  EDIT_LOTTERY_ERROR,
  EDIT_LOTTERY_RESET,
  CANCEL_LOTTERY_WAITING,
  CANCEL_LOTTERY_SUCCESS,
  CANCEL_LOTTERY_ERROR,
  CANCEL_LOTTERY_RESET,
  DELETE_LOTTERY_WAITING,
  DELETE_LOTTERY_SUCCESS,
  DELETE_LOTTERY_ERROR,
  DELETE_LOTTERY_RESET,
  FETCH_LOTTERIES_REPORT_WAITING,
  FETCH_LOTTERIES_REPORT_SUCCESS,
  FETCH_LOTTERIES_REPORT_ERROR,
  FETCH_LOTTERIES_REPORT_RESET,
  DELETE_LOTTERY_PRIZE_WAITING,
  DELETE_LOTTERY_PRIZE_SUCCESS,
  DELETE_LOTTERY_PRIZE_ERROR,
  DELETE_LOTTERY_PRIZE_RESET,
  ADD_MORE_PRIZE,
  ADD_MORE_PRIZE_RESET,
  UPDATE_PRIZE_IMAGE,
  RESET_ALL_PRIZES,
  EDIT_PRIZES_INIT,
  FETCH_LOTTERY_CONFIG_WAITING,
  FETCH_LOTTERY_CONFIG_SUCCESS,
  FETCH_LOTTERY_CONFIG_ERROR,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  status: {
    add: 'init',
    edit: 'init',
    cancel: 'init',
    fetch: 'init',
    fetchAll: 'init',
    report: 'init',
    delete: 'init',
    config: 'init',
  },
  error: undefined,
  prizeError: undefined,
  prizes: [],
  data: [],
  config: [],
};

export default function lotteryReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_LOTTERIES_WAITING: {
      const status = { ...state.status };
      status.fetchAll = 'waiting';
      return { ...state, status };
    }
    case FETCH_LOTTERIES_SUCCESS: {
      const status = { ...state.status };
      status.fetchAll = 'success';
      return {
        ...state,
        data: action.payload.data,
        headers: action.payload.headers,
        status,
      };
    }
    case FETCH_LOTTERIES_ERROR: {
      const status = { ...state.status };
      status.fetchAll = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case FETCH_LOTTERIES_RESET: {
      const status = { ...state.status };
      status.fetchAll = 'init';
      return { ...state, status, error: undefined };
    }

    case ADD_LOTTERY_WAITING: {
      const status = { ...state.status };
      status.add = 'waiting';
      return { ...state, status };
    }
    case ADD_LOTTERY_SUCCESS: {
      const status = { ...state.status };
      status.add = 'success';
      return { ...state, status, lottery: action.payload.data };
    }
    case ADD_LOTTERY_ERROR: {
      const status = { ...state.status };
      status.add = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case ADD_LOTTERY_RESET: {
      const status = { ...state.status };
      status.add = 'init';
      return { ...state, status, error: undefined };
    }

    case ADD_MORE_PRIZE:
      return { ...state, prizes: [...state.prizes, action.payload] };

    case ADD_MORE_PRIZE_RESET:
      return { ...state, prizes: [action.payload] };

    case EDIT_PRIZES_INIT:
      return { ...state, prizes: action.payload };

    case UPDATE_PRIZE_IMAGE:
      return {
        ...state,
        prizes: state.prizes.map(prize =>
          prize.key === action.payload.data.key
            ? { ...prize, image: action.payload.data.url }
            : prize
        ),
      };

    case RESET_ALL_PRIZES:
      return { ...state, prizes: [] };
    case FETCH_LOTTERY_WAITING: {
      const status = { ...state.status };
      status.fetch = 'waiting';
      return { ...state, status };
    }
    case FETCH_LOTTERY_SUCCESS: {
      const status = { ...state.status };
      status.fetch = 'success';
      return { ...state, status, lottery: action.payload.data };
    }
    case FETCH_LOTTERY_ERROR: {
      const status = { ...state.status };
      status.fetch = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case FETCH_LOTTERY_RESET: {
      const status = { ...state.status };
      status.fetch = 'init';
      return { ...state, status, error: undefined };
    }

    case EDIT_LOTTERY_WAITING: {
      const status = { ...state.status };
      status.edit = 'waiting';
      return { ...state, status };
    }
    case EDIT_LOTTERY_SUCCESS: {
      const status = { ...state.status };
      status.edit = 'success';
      return { ...state, status, lottery: action.payload.data };
    }
    case EDIT_LOTTERY_ERROR: {
      const status = { ...state.status };
      status.edit = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case EDIT_LOTTERY_RESET: {
      const status = { ...state.status };
      status.edit = 'init';
      return { ...state, status, error: undefined };
    }

    case DELETE_LOTTERY_WAITING: {
      const status = { ...state.status };
      status.delete = 'waiting';
      return { ...state, status };
    }
    case DELETE_LOTTERY_SUCCESS: {
      const { id } = action;
      const status = { ...state.status };
      status.delete = 'success';
      const updatedData = state.data.filter(item => item.lottery_id !== id);
      return { ...state, data: updatedData, status };
    }
    case DELETE_LOTTERY_ERROR: {
      const status = { ...state.status };
      status.delete = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case DELETE_LOTTERY_RESET: {
      const status = { ...state.status };
      status.delete = 'init';
      return { ...state, status, error: undefined };
    }

    case DELETE_LOTTERY_PRIZE_WAITING: {
      const status = { ...state.status };
      status.deletePrize = 'waiting';
      return { ...state, status };
    }
    case DELETE_LOTTERY_PRIZE_SUCCESS: {
      const status = { ...state.status };
      status.deletePrize = 'success';
      return {
        ...state,
        prizes: _.filter(state.prizes, prize => prize.key !== action.key),
        status,
      };
    }
    case DELETE_LOTTERY_PRIZE_ERROR: {
      const status = { ...state.status };
      return { ...state, status, prizeError: action.payload.error };
    }
    case DELETE_LOTTERY_PRIZE_RESET: {
      const status = { ...state.status };
      status.deletePrize = 'init';
      return { ...state, status, prizeError: undefined };
    }

    case CANCEL_LOTTERY_WAITING: {
      const status = { ...state.status };
      status.cancel = 'waiting';
      return { ...state, status };
    }
    case CANCEL_LOTTERY_SUCCESS: {
      const status = { ...state.status };
      const { lottery_id, lo_status } = action.payload.data;
      status.cancel = 'success';
      return {
        ...state,
        data: _.map(state.data, l =>
          l.lottery_id === lottery_id ? { ...l, lo_status } : l
        ),
        status,
      };
    }
    case CANCEL_LOTTERY_ERROR: {
      const status = { ...state.status };
      status.cancel = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case CANCEL_LOTTERY_RESET: {
      const status = { ...state.status };
      status.cancel = 'init';
      return { ...state, status, error: undefined };
    }

    case FETCH_LOTTERIES_REPORT_WAITING: {
      const status = { ...state.status };
      status.report = 'waiting';
      return { ...state, status };
    }
    case FETCH_LOTTERIES_REPORT_SUCCESS: {
      const status = { ...state.status };
      status.report = 'success';
      return { ...state, status, lotteries: action.payload.data };
    }
    case FETCH_LOTTERIES_REPORT_ERROR: {
      const status = { ...state.status };
      status.report = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case FETCH_LOTTERIES_REPORT_RESET: {
      const status = { ...state.status };
      status.report = 'init';
      return { ...state, status, error: undefined };
    }

    case FETCH_LOTTERY_CONFIG_WAITING: {
      const status = { ...state.status };
      status.config = 'waiting';
      return { ...state, status };
    }
    case FETCH_LOTTERY_CONFIG_SUCCESS: {
      const status = { ...state.status };
      status.config = 'success';
      return { ...state, status, config: action.payload.data };
    }
    case FETCH_LOTTERY_CONFIG_ERROR: {
      const status = { ...state.status };
      status.config = 'error';
      return { ...state, status, error: action.payload.error };
    }
    default:
      return state;
  }
}

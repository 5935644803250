import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import UserReducer from './UserReducer';
import AuthReducer from './AuthReducer';
import ParentEventReducer from './ParentEventReducer';
import PerformerReducer from './PerformerReducer';
import EventReducer from './EventReducer';
import CampaignReducer from './CampaignReducer';
import CountryReducer from './CountryReducer';
import CategoryReducer from './CategoryReducer';
import TranslationReducer from './TranslationReducer';
import VenueReducer from './VenueReducer';
import LocationReducer from './LocationReducer';
import ForgetPasswordReducer from './ForgetPasswordReducer';
import ResetPasswordReducer from './ResetPasswordReducer';
import PerformerPlayerReducer from './PerformerPlayerReducer';
import SubVenueReducer from './SubVenueReducer';
import CompanyReducer from './CompanyReducer';
import NodeCollectionsReducer from './NodeCollectionsReducer';
import PermissionReducer from './PermissionReducer';
import PersonReducer from './PersonReducer';
import LanguageReducer from './LanguageReducer';
import NotificationReducer from './NotificationReducer';
import LineupReducer from './LineupReducer';
import WorkspaceReducer from './WorkspaceReducer';
import ApplicationReducer from './ApplicationReducer';
import WizardReducer from './WizardReducer';
import PrometheusAppReducer from './PrometheusAppReducer';
import ApplicationTemplateReducer from './ApplicationTemplateReducer';
import ApplicationModulesReducer from './ApplicationModulesReducer';
import CmsContentReducer from './CmsContentReducer';
import ShopReducer from './ShopReducer';
import TaxReducer from './TaxReducer';
import FileUploadReducer from './FileUploadReducer';
import CurrencyReducer from './CurrencyReducer';
import DefaultTickets from './DefaultTicketSettingsReducer';
import GlobalStatesReducer from './GlobalStatesReducer';
import ProductAttributes from './ProductAttributesReducer';
import ProductStock from './ProductStockReducer';
import SalesReportReducer from './SalesReportReducer';
import ProductReducer from './ProductReducer';
import PricelistReducer from './PricelistReducer';
import LotteryReducer from './LotteryReducer';
import LotteryPrizeReducer from './LotteryPrizeReducer';
import MessageTemplateReducer from './MessageTemplateReducer';
import LotteryPreviewReducer from './LotteryPreviewReducer';
import VotingReducer from './VotingReducer';
import EventReportReducer from './EventReportReducer';
import KeyValueSettingReducer from './KeyValueSettingReducer';
import CardReducer from './CardReducer';
import CardInstaceReducer from './CardInstanceReducer';
import StatisticReducer from './StatisticReducer';
import TicketingReducer from './TicketingReducer';

import {
  AUTH_RESET,
  AUTH_CHECK_ERROR,
  ERROR_GATEWAY_TIMEOUT,
  AUTH_SUCCESS,
} from '../Types';

const appReducer = combineReducers({
  applicationTemplate: ApplicationTemplateReducer,
  applicationModules: ApplicationModulesReducer,
  applications: ApplicationReducer,
  auth: AuthReducer,
  campaign: CampaignReducer,
  cmsContent: CmsContentReducer,
  companies: CompanyReducer,
  countries: CountryReducer,
  currencies: CurrencyReducer,
  eventcategories: CategoryReducer,
  defaultTickets: DefaultTickets,
  events: EventReducer,
  file: FileUploadReducer,
  forget: ForgetPasswordReducer,
  form: formReducer,
  globalStates: GlobalStatesReducer,
  languages: LanguageReducer,
  lineup: LineupReducer,
  locations: LocationReducer,
  lottery: LotteryReducer,
  lotteryPreview: LotteryPreviewReducer,
  lotteryPrize: LotteryPrizeReducer,
  messageTemplate: MessageTemplateReducer,
  nodes: NodeCollectionsReducer,
  notifications: NotificationReducer,
  parents: ParentEventReducer,
  performers: PerformerReducer,
  performerPlayers: PerformerPlayerReducer,
  permissions: PermissionReducer,
  persons: PersonReducer,
  pricelist: PricelistReducer,
  product: ProductReducer,
  prometheusApp: PrometheusAppReducer,
  users: UserReducer,
  reset: ResetPasswordReducer,
  sales: SalesReportReducer,
  shop: ShopReducer,
  stock: ProductStock,
  subvenues: SubVenueReducer,
  tax: TaxReducer,
  translations: TranslationReducer,
  venues: VenueReducer,
  appConfig: WizardReducer,
  workspace: WorkspaceReducer,
  productAttribute: ProductAttributes,
  voting: VotingReducer,
  eventReport: EventReportReducer,
  keyValueSettings: KeyValueSettingReducer,
  card: CardReducer,
  cardinstances: CardInstaceReducer,
  statistics: StatisticReducer,
  ticketing: TicketingReducer,
});

const rootReducer = (state, action) => {
  let newState = { ...state };
  let newAction = { ...action };
  const prevAuthStatus = state && state.auth ? state.auth.status : undefined;
  const loggedIn = state && state.auth ? state.auth.loggedIn : false;
  if (
    loggedIn &&
    prevAuthStatus === 'timeout' &&
    action.type === ERROR_GATEWAY_TIMEOUT
  ) {
    newAction = {}; // to avoid repeated timeout toast
  }
  if (
    action.type === AUTH_RESET ||
    action.type === AUTH_CHECK_ERROR ||
    action.type === ERROR_GATEWAY_TIMEOUT
  ) {
    newState = undefined;
  } else if (action.type === AUTH_SUCCESS) {
    const translations = { ...state.translations, status: 'init' };
    newState = { ...state, translations };
  }
  return appReducer(newState, newAction);
};

export default rootReducer;

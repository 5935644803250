import React, { Component } from "react";
import { Button, Segment, Header, Confirm, Form } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { toast } from "react-semantic-toasts";
import { connect } from "react-redux";
import _ from "lodash";
import {
  fetchPerson,
  resetFetchPerson,
  editPerson,
  resetEditPerson,
  deletePerson,
  resetDeletePerson,
  setLanguage,
  checkAuthKey,
} from "../../Redux/actions";
import { Spinner } from "../Common/Spinner";
import { T, getErrorMessage } from "../Common/Helpers";

const isEmail = (email = null) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

class PersonAdd extends Component {
  constructor(props) {
    super(props);
    // console.log('PERSON props: ', this.props);
    const person = this.props.person;
    const { pe_name, pe_email, pe_language } = person || {
      pe_name: "",
      email: "",
    };
    this.state = {
      openDeleteConfirmation: false,
      pe_name,
      pe_email,
      pe_language: pe_language || 1,
      pe_password: "",
      confirm_password: "",
      nameError: false,
      emailError: false,
      passwordError: false,
      confirmPasswordError: false,
      isFormValid: false,
    };
    this.onCancel = this.onCancel.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.validate = this.validate.bind(this);
  }
  //
  // componentDidMount() {
  //    const personId = this.props.personId;
  //    const authKey = localStorage.getItem('x-auth-key');
  //    this.props.fetchPerson(personId, authKey);
  // }

  componentDidUpdate(prevProps) {
    if (
      prevProps.editStatus !== "success" &&
      this.props.editStatus === "success"
    ) {
      toast({
        type: "success",
        title: T("Success"),
        description: T("Person updated successfully"),
        time: 5000,
      });
      this.props.resetEditPerson();
      const authKey = localStorage.getItem("x-auth-key");
      if (prevProps.person.pe_language !== this.props.person.pe_language) {
        this.props.checkAuthKey(authKey); //reload language
      }
      if (this.props.auth.isSuperAdmin) {
        this.props.history.push("/app/persons/list");
      } else {
        this.props.history.push("/app/dashboard");
      }
    } else if (
      prevProps.editStatus !== "error" &&
      this.props.editStatus === "error"
    ) {
      const errorObj = getErrorMessage(this.props.error);
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
      // this.props.resetEditPerson();
    }
  }

  onConfirmDelete() {
    const personId = this.props.personId;
    const authKey = localStorage.getItem("x-auth-key");
    this.props.deletePerson(personId, authKey);
    this.setState({ openDeleteConfirmation: false });
    // console.log('delete confirmed');
  }

  onDeleteClick() {
    this.setState({ openDeleteConfirmation: true });
  }

  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }

  onCancel() {
    if (this.props.auth.isSuperAdmin) {
      this.props.history.push("/app/persons/list");
    } else {
      this.props.history.push("/app/dashboard");
    }
  }

  onChangeDefaultApplication() {
    console.log("Default application changed");
  }

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value }, this.validate(name, value));

  handleSubmit = () => {
    const { pe_name, pe_email, pe_password, confirm_password, pe_language } =
      this.state;
    let error = {};
    const data = {
      pe_name,
      pe_email,
      pe_login: pe_email,
      pe_password,
      pe_language,
    };
    const nameError = pe_name === "" || pe_name.length < 6;
    if (nameError) {
      error = { nameError };
    }
    const emailError = pe_email === "" || !isEmail(pe_email);
    if (emailError) {
      error = { ...error, emailError };
    }
    const passwordError =
      pe_password.trim() !== ""
        ? pe_password.length < 6 || pe_password !== confirm_password
        : false;
    if (passwordError) {
      error = { ...error, passwordError };
    }
    // const confirmPasswordError = confirm_password === '' || confirm_password !== pe_password;
    const isFormValid =
      nameError === false && emailError === false && passwordError === false;
    if (pe_password.trim() !== confirm_password.trim()) {
      this.setState({ passwordError: true, confirmPasswordError: true });
      return;
    } else if (isFormValid && pe_password.trim() === "") {
      delete data.pe_password;
    }
    if (isFormValid) {
      const authKey = localStorage.getItem("x-auth-key");
      const personId = this.props.personId;
      this.props.editPerson(personId, data, authKey);
      if (
        this.props.person.pe_language !== this.state.pe_language &&
        this.props.person.pe_name === this.props.auth.username
      ) {
        let langTwoAlpha = _.filter(
          this.props.languages.languages,
          (language) => language.language_id === this.state.pe_language
        );
        langTwoAlpha = langTwoAlpha[0].ln_alpha_2;
        this.props.setLanguage({ lang: langTwoAlpha });
      }
    }
    this.setState({
      ...error,
      passwordError: false,
      confirmPasswordError: false,
      isFormValid,
    });
  };

  validate(n, v) {
    switch (n) {
      case "pe_name":
        if (v.length < 6) {
          this.setState({ ...this.state, nameError: true });
        } else {
          this.setState({ ...this.state, nameError: false });
        }
        break;
      case "pe_email":
        if (!isEmail(v)) {
          this.setState({ ...this.state, emailError: true });
        } else {
          this.setState({ ...this.state, emailError: false });
        }
        break;
      case "pe_password":
        if (v !== this.state.confirm_password || v.length < 6) {
          this.setState({
            ...this.state,
            passwordError: true,
            confirmPasswordError: true,
          });
        } else {
          this.setState({
            ...this.state,
            passwordError: false,
            confirmPasswordError: false,
          });
        }
        break;
      case "confirm_password":
        if (v !== this.state.pe_password || v.length < 6) {
          this.setState({
            ...this.state,
            confirmPasswordError: true,
            passwordError: true,
          });
        } else {
          this.setState({
            ...this.state,
            confirmPasswordError: false,
            passwordError: false,
          });
        }
        break;
      default:
      //do nothing
    }
  }

  renderLangSelector = () => {
    const options = [
      { key: "fi", text: T("Finnish"), value: 15 },
      { key: "en", text: T("English"), value: 1 },
    ];
    return (
      <Form.Dropdown
        name="pe_language"
        required
        // onChange={(e, { value }) => this.props.setLanguage({ lang: value })}
        onChange={(e, { value }) => this.setState({ pe_language: value })}
        options={options}
        label={T("Language")}
        placeholder={T("Language")}
        selection
        value={this.state.pe_language}
        width="6"
      />
    );
  };

  render() {
    // if (this.props.fetchStatus !== 'success') {
    //   return <Spinner />;
    // }
    if (this.props.editStatus === "waiting") {
      return <Spinner />;
    }
    const { pe_name, pe_email, pe_password, confirm_password } = this.state;
    return (
      <Segment>
        <Header as="h3">{T("Edit")}</Header>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.Input
              required
              label={T("Name")}
              name="pe_name"
              value={pe_name}
              onChange={this.handleChange}
              error={this.state.nameError}
              width="6"
            />
            <Form.Input
              required
              autoComplete="off"
              type="email"
              name="pe_email"
              value={pe_email}
              label={T("Email")}
              onChange={this.handleChange}
              error={this.state.emailError}
              width="6"
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              autoComplete="new-password"
              type="password"
              name="pe_password"
              label={T("Change password")}
              value={pe_password}
              onChange={this.handleChange}
              error={this.state.passwordError}
              width="6"
            />
            <Form.Input
              autoComplete="new-password"
              type="password"
              name="confirm_password"
              label={T("Confirm password")}
              value={confirm_password}
              onChange={this.handleChange}
              error={this.state.confirmPasswordError}
              width="6"
            />
          </Form.Group>
          <Form.Group>{this.renderLangSelector()}</Form.Group>
          <Button icon="save" content={T("Save")} color="green" />
          <Button
            icon="trash"
            type="button"
            content={T("Delete")}
            color="red"
            onClick={this.onDeleteClick}
          />
          <Button
            icon="ban"
            type="button"
            secondary
            content={T("Cancel")}
            onClick={() => this.onCancel()}
          />
          <Confirm
            header={T("Delete Person")}
            content={T("Are you sure you want to delete your account?")}
            confirmButton={T("Delete")}
            cancelButton={T("Cancel")}
            open={this.state.openDeleteConfirmation}
            onCancel={this.oncancelDeleteConfirmation}
            onConfirm={this.onConfirmDelete}
            size="tiny"
          />
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({ persons, auth, languages }) {
  return {
    // fetchStatus: persons.operationState.fetch,
    languages,
    editStatus: persons.operationState.update,
    person: persons.person,
    error: persons.error,
    auth,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    checkAuthKey,
    fetchPerson,
    resetFetchPerson,
    editPerson,
    resetEditPerson,
    deletePerson,
    resetDeletePerson,
    setLanguage,
  })(PersonAdd)
);

export const FETCH_SHOP_PRODUCTS_WAITING = 'fetch_shop_products_waiting';
export const FETCH_SHOP_PRODUCTS_SUCCESS = 'fetch_shop_products_success';
export const FETCH_SHOP_PRODUCTS_ERROR = 'fetch_shop_products_error';
export const FETCH_SHOP_PRODUCTS_RESET = 'fetch_shop_products_reset';

export const FETCH_SHOPS_WAITING = 'fetch_shops_waiting';
export const FETCH_SHOPS_SUCCESS = 'fetch_shops_success';
export const FETCH_SHOPS_ERROR = 'fetch_shops_error';
export const FETCH_SHOPS_RESET = 'fetch_shops_reset';

export const FETCH_SHOP_WAITING = 'fetch_shop_waiting';
export const FETCH_SHOP_SUCCESS = 'fetch_shop_success';
export const FETCH_SHOP_ERROR = 'fetch_shop_error';
export const FETCH_SHOP_RESET = 'fetch_shop_reset';

export const FETCH_SALES_WAITING = 'fetch_sales_waiting';
export const FETCH_SALES_SUCCESS = 'fetch_sales_success';
export const FETCH_SALES_ERROR = 'fetch_sales_error';
export const FETCH_SALES_RESET = 'fetch_sales_reset';

export const FETCH_APP_SHOPS_WAITING = 'fetch_app_shops_waiting';
export const FETCH_APP_SHOPS_SUCCESS = 'fetch_app_shops_success';
export const FETCH_APP_SHOPS_ERROR = 'fetch_app_shops_error';
export const FETCH_APP_SHOPS_RESET = 'fetch_app_shops_reset';

export const ADD_SHOP_WAITING = 'add_shop_waiting';
export const ADD_SHOP_SUCCESS = 'add_shop_success';
export const ADD_SHOP_ERROR = 'add_shop_error';
export const ADD_SHOP_RESET = 'add_shop_reset';

export const EDIT_SHOP_WAITING = 'edit_shop_waiting';
export const EDIT_SHOP_SUCCESS = 'edit_shop_success';
export const EDIT_SHOP_ERROR = 'edit_shop_error';
export const EDIT_SHOP_RESET = 'edit_shop_reset';

export const DELETE_SHOP_WAITING = 'delete_shop_waiting';
export const DELETE_SHOP_SUCCESS = 'delete_shop_success';
export const DELETE_SHOP_ERROR = 'delete_shop_error';
export const DELETE_SHOP_RESET = 'delete_shop_reset';

export const FETCH_PRICE_LIST_PRODUCTS_WAITING =
  'fetch_price_list_products_waiting';
export const FETCH_PRICE_LIST_PRODUCTS_SUCCESS =
  'fetch_price_list_products_success';
export const FETCH_PRICE_LIST_PRODUCTS_ERROR =
  'fetch_price_list_products_error';
export const FETCH_PRICE_LIST_PRODUCTS_RESET =
  'fetch_price_list_products_reset';

export const FETCH_SHOP_PRODUCT_WAITING = 'fetch_shop_product_waiting';
export const FETCH_SHOP_PRODUCT_SUCCESS = 'fetch_shop_product_success';
export const FETCH_SHOP_PRODUCT_ERROR = 'fetch_shop_product_error';
export const FETCH_SHOP_PRODUCT_RESET = 'fetch_shop_product_reset';

export const ADD_SHOP_PRODUCT_WAITING = 'add_shop_product_waiting';
export const ADD_SHOP_PRODUCT_SUCCESS = 'add_shop_product_success';
export const ADD_SHOP_PRODUCT_ERROR = 'add_shop_product_error';
export const ADD_SHOP_PRODUCT_RESET = 'add_shop_product_reset';

export const EDIT_SHOP_PRODUCT_WAITING = 'edit_shop_product_waiting';
export const EDIT_SHOP_PRODUCT_SUCCESS = 'edit_shop_product_success';
export const EDIT_SHOP_PRODUCT_ERROR = 'edit_shop_product_error';
export const EDIT_SHOP_PRODUCT_RESET = 'edit_shop_product_reset';

export const DELETE_SHOP_PRODUCT_WAITING = 'delete_shop_product_waiting';
export const DELETE_SHOP_PRODUCT_SUCCESS = 'delete_shop_product_success';
export const DELETE_SHOP_PRODUCT_ERROR = 'delete_shop_product_error';
export const DELETE_SHOP_PRODUCT_RESET = 'delete_shop_product_reset';

export const FETCH_SHOP_PRICELIST_WAITING = 'fetch_shop_pricelist_waiting';
export const FETCH_SHOP_PRICELIST_SUCCESS = 'fetch_shop_pricelist_success';
export const FETCH_SHOP_PRICELIST_ERROR = 'fetch_shop_pricelist_error';
export const FETCH_SHOP_PRICELIST_RESET = 'fetch_shop_pricelist_reset';

export const FETCH_PRICELIST_WAITING = 'fetch_pricelist_waiting';
export const FETCH_PRICELIST_SUCCESS = 'fetch_pricelist_success';
export const FETCH_PRICELIST_ERROR = 'fetch_pricelist_error';
export const FETCH_PRICELIST_RESET = 'fetch_pricelist_reset';

export const FETCH_SHOP_PRODUCT_UNIT_WAITING =
  'fetch_shop_product_unit_waiting';
export const FETCH_SHOP_PRODUCT_UNIT_SUCCESS =
  'fetch_shop_product_unit_success';
export const FETCH_SHOP_PRODUCT_UNIT_ERROR = 'fetch_shop_product_unit_error';
export const FETCH_SHOP_PRODUCT_UNIT_RESET = 'fetch_shop_product_unit_reset';

export const ADD_SHOP_PRICELIST_WAITING = 'add_shop_pricelist_waiting';
export const ADD_SHOP_PRICELIST_SUCCESS = 'add_shop_pricelist_success';
export const ADD_SHOP_PRICELIST_ERROR = 'add_shop_pricelist_error';
export const ADD_SHOP_PRICELIST_RESET = 'add_shop_pricelist_reset';

export const EDIT_SHOP_PRICELIST_WAITING = 'edit_shop_pricelist_waiting';
export const EDIT_SHOP_PRICELIST_SUCCESS = 'edit_shop_pricelist_success';
export const EDIT_SHOP_PRICELIST_ERROR = 'edit_shop_pricelist_error';
export const EDIT_SHOP_PRICELIST_RESET = 'edit_shop_pricelist_reset';

export const DELETE_SHOP_PRICELIST_WAITING = 'delete_shop_pricelist_waiting';
export const DELETE_SHOP_PRICELIST_SUCCESS = 'delete_shop_pricelist_success';
export const DELETE_SHOP_PRICELIST_ERROR = 'delete_shop_pricelist_error';
export const DELETE_SHOP_PRICELIST_RESET = 'delete_shop_pricelist_reset';

export const DELETE_PRODUCT_PRICELIST_WAITING =
  'delete_product_pricelist_waiting';
export const DELETE_PRODUCT_PRICELIST_SUCCESS =
  'delete_product_pricelist_success';
export const DELETE_PRODUCT_PRICELIST_ERROR = 'delete_product_pricelist_error';
export const DELETE_PRODUCT_PRICELIST_RESET = 'delete_product_pricelist_reset';

export const ADD_SHOP_PRODUCT_PRICE_WAITING = 'add_shop_product_price_waiting';
export const ADD_SHOP_PRODUCT_PRICE_SUCCESS = 'add_shop_product_price_success';
export const ADD_SHOP_PRODUCT_PRICE_ERROR = 'add_shop_product_price_error';
export const ADD_SHOP_PRODUCT_PRICE_RESET = 'add_shop_product_price_reset';

export const EDIT_SHOP_PRODUCT_PRICE_WAITING =
  'edit_shop_product_price_waiting';
export const EDIT_SHOP_PRODUCT_PRICE_SUCCESS =
  'edit_shop_product_price_success';
export const EDIT_SHOP_PRODUCT_PRICE_ERROR = 'edit_shop_product_price_error';
export const EDIT_SHOP_PRODUCT_PRICE_RESET = 'edit_shop_product_price_reset';

export const EDIT_SHOP_SETTINGS_WAITING = 'edit_shop_settings_waiting';
export const EDIT_SHOP_SETTINGS_SUCCESS = 'edit_shop_settings_success';
export const EDIT_SHOP_SETTINGS_ERROR = 'edit_shop_settings_error';
export const EDIT_SHOP_SETTINGS_RESET = 'edit_shop_settings_reset';

export const FETCH_SHOP_SETTINGS_WAITING = 'fetch_shop_settings_waiting';
export const FETCH_SHOP_SETTINGS_SUCCESS = 'fetch_shop_settings_success';
export const FETCH_SHOP_SETTINGS_ERROR = 'fetch_shop_settings_error';
export const FETCH_SHOP_SETTINGS_RESET = 'fetch_shop_settings_reset';

export const FETCH_SHOP_SETTINGS_KEYWORDS_WAITING =
  'fetch_shop_settings_keywords_waiting';
export const FETCH_SHOP_SETTINGS_KEYWORDS_SUCCESS =
  'fetch_shop_settings_keywords_success';
export const FETCH_SHOP_SETTINGS_KEYWORDS_ERROR =
  'fetch_shop_settings_keywords_error';
export const FETCH_SHOP_SETTINGS_KEYWORDS_RESET =
  'fetch_shop_settings_keywords_reset';

export const FETCH_PRODUCT_TYPES_WAITING = 'fetch_product_types_waiting';
export const FETCH_PRODUCT_TYPES_SUCCESS = 'fetch_product_types_success';
export const FETCH_PRODUCT_TYPES_ERROR = 'fetch_product_types_error';
export const FETCH_PRODUCT_TYPES_RESET = 'fetch_product_types_reset';

export const EDIT_MULTIPLE_PRODUCT_PRICES_WAITING =
  'edit_multiple_product_prices_waiting';
export const EDIT_MULTIPLE_PRODUCT_PRICES_SUCCESS =
  'edit_multiple_product_prices_success';
export const EDIT_MULTIPLE_PRODUCT_PRICES_ERROR =
  'edit_multiple_product_prices_error';
export const EDIT_MULTIPLE_PRODUCT_PRICES_RESET =
  'edit_multiple_product_prices_reset';

export const IS_OPEN_SHOP_NOW_WAITING = 'is_open_shop_now_waiting';
export const IS_OPEN_SHOP_NOW_SUCCESS = 'is_open_shop_now_success';
export const IS_OPEN_SHOP_NOW_ERROR = 'is_open_shop_now_error';
export const IS_OPEN_SHOP_NOW_RESET = 'is_open_shop_now_reset';

export const SHOP_CONFIG_LIST_KEYWORKDS_WAITING =
  'shop_config_list_keywords_waiting';
export const SHOP_CONFIG_LIST_KEYWORKDS_SUCCESS =
  'shop_config_list_keywords_success';
export const SHOP_CONFIG_LIST_KEYWORKDS_ERROR =
  'shop_config_list_keywords_error';
export const SHOP_CONFIG_LIST_KEYWORKDS_RESET =
  'shop_config_list_keywords_reset';

export const SHOP_CONFIG_MANAGE_WAITING = 'shop_config_manage_waiting';
export const SHOP_CONFIG_MANAGE_SUCCESS = 'shop_config_manage_success';
export const SHOP_CONFIG_MANAGE_ERROR = 'shop_config_manage_error';
export const SHOP_CONFIG_MANAGE_RESET = 'shop_config_manage_reset';

export const SHOP_CONFIG_LIST_WAITING = 'shop_config_list_waiting';
export const SHOP_CONFIG_LIST_SUCCESS = 'shop_config_list_success';
export const SHOP_CONFIG_LIST_ERROR = 'shop_config_list_error';
export const SHOP_CONFIG_LIST_RESET = 'shop_config_list_reset';

export const EDIT_SHOP_PRODUCT_PRICELISTS_WAITING =
  'edit_shop_product_pricelist_waiting';
export const EDIT_SHOP_PRODUCT_PRICELISTS_SUCCESS =
  'edit_shop_product_pricelist_success';
export const EDIT_SHOP_PRODUCT_PRICELISTS_ERROR =
  'edit_shop_product_pricelist_error';
export const EDIT_SHOP_PRODUCT_PRICELISTS_RESET =
  'edit_shop_product_pricelist_reset';

export const FETCH_OUT_OF_STOCK_MESSAGE_ERROR =
  'fetch_out_of_stock_message_error';
export const FETCH_OUT_OF_STOCK_MESSAGE_SUCCESS =
  'fetch_out_of_stock_message_success';
export const FETCH_OUT_OF_STOCK_MESSAGE_WAITING =
  'fetch_out_of_stock_message_waiting';
export const FETCH_OUT_OF_STOCK_MESSAGE_RESET =
  'fetch_out_of_stock_message_reset';

export const UPDATE_SHOP_IMAGE = 'update_shop_image';
export const DELETE_SHOP_IMAGE = 'delete_shop_image';

export const EDIT_PRICELIST_PRODUCT_ORDER_WAITING =
  'edit_pricelist_product_order_waiting';
export const EDIT_PRICELIST_PRODUCT_ORDER_SUCCESS =
  'edit_pricelist_product_order_success';
export const EDIT_PRICELIST_PRODUCT_ORDER_ERROR =
  'edit_pricelist_product_order_error';
export const EDIT_PRICELIST_PRODUCT_ORDER_RESET =
  'edit_pricelist_product_order_reset';

// export const CLOSE_SHOP_NOW_WAITING = 'close_shop_now_waiting';
// export const CLOSE_SHOP_NOW_SUCCESS = 'close_shop_now_success';
// export const CLOSE_SHOP_NOW_ERROR = 'close_shop_now_error';
// export const CLOSE_SHOP_NOW_RESET = 'close_shop_now_reset';

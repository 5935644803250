import axios from 'axios';

import config from '../../Config/AEConfig';
import { FORGET_SUCCESS, FORGET_ERROR, FORGET_WAITING } from '../Types';
import ErrorAction from './ErrorAction';

export function forgotPassword(value) {
  return function (dispatch) {
    dispatch({ type: FORGET_WAITING });
    return axios
      .post(`${config.BASE_URL}/auth/password`, {
        email: value.target.email.value,
      })
      .then(data => {
        dispatch({ type: FORGET_SUCCESS, payload: data });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FORGET_ERROR)
          : FORGET_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { T } from './Helpers';

export const PopupContent = props => (
  <Popup
    content={T(props.content)}
    trigger={
      <Icon
        name="question circle"
        color={props.color}
        size={props.size}
        wide={props.wide}
        style={props.style}
      />
    }
  />
);

export const getPopupContent = (content, size, color) => (
  <Popup
    content={T(content)}
    trigger={
      <Icon
        name="question circle"
        size={size}
        style={{ marginLeft: '.1em', marginTop: '-.1em', color: color }}
      />
    }
  />
);

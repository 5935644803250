import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import { Form, Icon, Button, Confirm } from 'semantic-ui-react';
import { Form as Formsy } from 'formsy-semantic-ui-react';
import draftToHtml from 'draftjs-to-html';
import { toast } from 'react-semantic-toasts';
import { connect } from 'react-redux';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  addPerformerCMSContent,
  editPerformerCMSContent,
  deletePerformerCMSContent,
  resetPerformerCMSContent,
  fetchPerformerCMSChildContentList,
} from '../../Redux/actions';
import {
  T,
  getErrorMessage,
  handlePastedText,
  DEFAULT_TOOLBAR_OPTIONS,
} from '../Common/Helpers';

const errorStyle = {
  backgroundColor: '#FFF6F6',
  border: ' solid 1px #E0B4B4',
  color: '#9F3A38',
  WebkitBoxShadow: 'none',
  boxShadow: 'none',
};
function getChildContentFromList(cmsList, language) {
  if (cmsList === null || cmsList.length === 0) {
    return null;
  }

  const childCms = cmsList.filter(elt => elt.cm_language === language);
  return childCms[0];
}
function setCMSValue(childCMS) {
  let state = '';
  if (childCMS && childCMS.cm_content) {
    const blocksFromHTML = convertFromHTML(childCMS.cm_content);
    state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(state);
  }
  return EditorState.createEmpty();
}
class PlayerCMSContentEditor extends Component {
  constructor(props) {
    super(props);
    const { language, childCmsList } = this.props;
    const childCms = getChildContentFromList(childCmsList, language);
    this.state = {
      editorState: setCMSValue(childCms),
      editorStyle: '',
      cms: childCms,
      title: childCms ? childCms.cm_short_header : '',
      openDeleteConfirmation: false,
    };
    this.onCancel = this.onCancel.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleCMSDelete = this.handleCMSDelete.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }
  componentDidMount() {
    this.props.resetPerformerCMSContent();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.language !== nextProps.language) {
      const childCms = getChildContentFromList(
        nextProps.childCmsList,
        nextProps.language
      );
      this.setState({
        editorState: setCMSValue(childCms),
        cms: childCms,
        title: childCms ? childCms.cm_short_header : '',
      });
    }
    if (
      this.props.cmsStatus !== 'success' &&
      nextProps.cmsStatus === 'success'
    ) {
      window.scroll(50, 50);
      // const ws_id = this.props.workspace.ws_id;
      toast({
        type: 'success',
        title: T('Success'),
        description: T('CMS content updated successfully'),
        time: 5000,
      });
      // const authKey = localStorage.getItem('x-auth-key');
      // this.props.fetchPerformerCMSChildContentList(this.props.parentId, authKey, ws_id);
      // this.props.resetPerformerCMSContent();
      this.props.closeModal();
    } else if (
      this.props.cmsStatus !== 'error' &&
      nextProps.cmsStatus === 'error'
    ) {
      window.scroll(50, 50);
      const errorObj = getErrorMessage(nextProps.error);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetPerformerCMSContent();
    }
  }
  onEditorStateChange(editorState) {
    const healthyState = this.state.editorState;
    try {
      this.setState({
        editorState,
        editorStyle: '',
      });
    } catch (ex) {
      this.setState({ editorState: healthyState, editorStyle: errorStyle });
    }
  }

  onCancel() {
    this.props.closeModal();
  }

  onTitleChange({ value }) {
    this.setState({ title: value });
  }

  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }

  onConfirmDelete() {
    if (this.state.cms) {
      const authKey = localStorage.getItem('x-auth-key');
      const ws_id = this.props.workspace.ws_id;
      this.props.deletePerformerCMSContent(
        this.state.cms.cms_content_id,
        authKey,
        ws_id
      );
      this.props.closeModal();
    }
    this.setState({ openDeleteConfirmation: false });
  }

  handleCMSDelete() {
    this.setState({ openDeleteConfirmation: true });
  }

  editChildCMSContent(parentId, content) {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.ws_id;
    this.props.editPerformerCMSContent(parentId, content, authKey, ws_id);
  }

  addNewCMSChildContent(inputValues) {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.ws_id;
    this.props.addPerformerCMSContent(inputValues, authKey, ws_id);
  }

  handleValidSubmit(formData) {
    let inputValues = {};
    const content = this.state.editorState.getCurrentContent();
    const isEditorEmpty = !content.hasText();
    const currentPlainText = content.getPlainText();
    const lengthOfEditorContent = currentPlainText.trim().length;
    if (isEditorEmpty || lengthOfEditorContent === 0) {
      this.setState({ editorStyle: errorStyle });
      return false;
    }
    inputValues = {
      ...inputValues,
      cm_content: draftToHtml(convertToRaw(content)),
      cm_language: this.props.language,
      cm_short_header: formData.cm_short_header,
      cm_parent: this.props.parentId,
    };
    if (this.state.cms) {
      this.editChildCMSContent(this.state.cms.cms_content_id, inputValues);
    } else {
      // Adding new
      this.addNewCMSChildContent(inputValues);
    }
    this.props.closeModal();
  }

  editParentCMSContent(contentId, inputValues) {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.ws_id;
    this.props.editPerformerCMSContent(contentId, inputValues, authKey, ws_id);
  }

  render() {
    const { editorState } = this.state;
    return (
      <div>
        <Formsy onValidSubmit={this.handleValidSubmit.bind(this)}>
          <Form.Field>
            <label htmlFor="cm_short_header"> {T('Title')} </label>
            <Formsy.Input
              type="text"
              name="cm_short_header"
              value={this.state.title}
              // defaultValue={this.state.cms ? this.state.cms.cm_short_header : ''}
              required
            />
          </Form.Field>
          <Editor
            editorState={editorState}
            wrapperStyle={{}}
            required
            stripPastedStyles
            toolbar={DEFAULT_TOOLBAR_OPTIONS}
            editorStyle={{
              minHeight: '400px',
              maxHeight: '700px',
              overflow: 'scroll',
              border: '1px solid grey',
              paddingLeft: '3px',
              ...this.state.editorStyle,
            }}
            onEditorStateChange={this.onEditorStateChange}
            handlePastedText={handlePastedText}
          />
          <Form.Field>
            <Button color="green" type="submit">
              <Icon name="save" /> {T('Save')}
            </Button>
            <Button color="black" type="button" onClick={this.onCancel}>
              <Icon name="ban" /> {T('Cancel')}
            </Button>
            {this.state.cms && (
              <Button color="red" type="button" onClick={this.handleCMSDelete}>
                <Icon name="delete" /> {T('Delete')}
              </Button>
            )}
            <Confirm
              header={T('Delete CMS')}
              content={T('Are you sure you want to delete the CMS?')}
              confirmButton={T('Delete')}
              open={this.state.openDeleteConfirmation}
              onCancel={this.oncancelDeleteConfirmation}
              onConfirm={this.onConfirmDelete}
              size="tiny"
            />
          </Form.Field>
        </Formsy>
      </div>
    );
  }
}

function mapStateToProps({ performerPlayers, workspace }) {
  return {
    cmsStatus: performerPlayers.cmsStatus,
    cmsTypes: performerPlayers.cmsTypes,
    childCmsStatus: performerPlayers.childCmsStatus,
    error: performerPlayers.error,
    workspace,
  };
}
export default connect(mapStateToProps, {
  editPerformerCMSContent,
  deletePerformerCMSContent,
  resetPerformerCMSContent,
  addPerformerCMSContent,
  fetchPerformerCMSChildContentList,
})(PlayerCMSContentEditor);

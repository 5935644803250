/* eslint-env browser */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { Message, Button } from 'semantic-ui-react';
import {
  addWorkspace,
  resetAddWorkspace,
  createPrometheusApp,
  resetCreatePrometheusApp,
  // fetchCountries,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.createDefaultApp = this.createDefaultApp.bind(this);
  }

  // ***** DO NOT DELETE FETCH COUNTRIES, IT'S USED IN OTHER COMPONENTS  *****//
  componentDidMount() {
    // localStorage.setItem('route', this.props.history.location.pathname);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (
      this.props.addWorkspaceStatus !== 'success' &&
      nextProps.addWorkspaceStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Workspace created successfully'),
        time: 5000,
      });
      this.props.resetAddWorkspace();
    } else if (
      this.props.addWorkspaceStatus !== 'error' &&
      nextProps.addWorkspaceStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        nextProps.error,
        'Error has occurred, while creating workspace'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetAddWorkspace();
    } else if (
      this.props.createAppStatus !== 'success' &&
      nextProps.createAppStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Default App created successfully'),
        time: 5000,
      });
      this.props.resetCreatePrometheusApp();
    } else if (
      this.props.createAppStatus !== 'error' &&
      nextProps.createAppStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        nextProps.createError,
        'Error has occurred, while creating default app'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetCreatePrometheusApp();
    }
  }

  createDefaultApp() {
    const authKey = localStorage.getItem('x-auth-key');
    const { companyName, companyId } = this.props.location.state;
    const inputValues = { cnc_name: companyName, cnc_company: companyId };
    this.props.addWorkspace(inputValues, authKey);
    const appInputData = { company_id: this.props.location.state.companyId };
    setTimeout(
      () => this.props.createPrometheusApp(appInputData, authKey),
      1000
    );
  }

  gotoWizard(selectedApp) {
    this.props.history.push({
      pathname: `/app/prometheusone/wizard/${selectedApp.ap_key}`,
    });
  }

  RenderWelcome() {
    const authKey = localStorage.getItem('x-auth-key');
    if (authKey !== null) {
      return (
        <div>
          <div className="loginSubTitle">
            {`${T('Welcome to Prometheus one!')}`}
          </div>
          {/*}<h1>{T('Dashboard, coming soon!')}</h1>*/}
        </div>
      );
    }
    return null;
  }

  render() {
    if (
      this.props.addWorkspaceStatus === 'waiting' ||
      this.props.createAppStatus === 'waiting'
    ) {
      return <Spinner />;
    }
    const apps = this.props.auth.apps;
    const { workspaces, ws_id } = this.props.workspace;
    const currentApp = apps.filter(
      app => app.application_id === workspaces[ws_id].cnc_application
    )[0];
    if (!currentApp) {
      return (
        <Message
          negative
          icon="exclamation triangle"
          header={T('Error')}
          content={T('Workspace has no application')}
        />
      );
    }
    return (
      <div className="sub-layout">
        <div>
          <Button
            style={{ ...buttonStyle }}
            content={T('Start application wizard')}
            onClick={this.gotoWizard.bind(this, currentApp)}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ workspace, prometheusApp, auth }) {
  return {
    addWorkspaceStatus: workspace.operationState.add,
    createAppStatus: prometheusApp.operationState.add,
    createError: prometheusApp.error,
    error: workspace.error,
    auth,
    workspace,
  };
}

const buttonStyle = {
  padding: '15px',
  cursor: 'pointer',
  textAlign: 'center',
  backgroundColor: '#4B4B4B',
  fontSize: '16px',
  fontWeight: 400,
  color: 'white',
  border: '1px solid grey',
  outline: 'none',
};

export default connect(mapStateToProps, {
  addWorkspace,
  resetAddWorkspace,
  createPrometheusApp,
  resetCreatePrometheusApp,
})(Welcome);

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReactCrop from 'react-image-crop';
import {
  Button,
  Confirm,
  Label,
  Segment,
  // Input,
  Icon,
} from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import config from '../../Config/AEConfig';
//import logoPlaceHolder from '../../layout/images/logo_placeholder.png';
import { T } from '../Common/Helpers';

class ImageCrop extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openDeleteConfirmation: false,
      src: null,
      uploadStatus: null,
      crop: {
        unit: '%',
        x: 0,
        y: 0,
        aspect: 1,
        width: 100,
      },
      imgContainerWidth: '250',
      imgContainerHeight: '250',
    };
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  onSelectFile = files => {
    if (files && files.length > 0) {
      // const crop = { x: 0, y: 0, width: 90, height: 90 };
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({ src: reader.result });
      });
      const img = new Image();
      img.onload = () => {
        // console.log("Img Dimensions: ", this.height, this.width);
        this.setState({
          imgContainerWidth: this.width,
          imgContainerHeight: this.height,
        });
      };
      this.imageFileName = files[0].name;
      img.src = window.URL.createObjectURL(files[0]);
      reader.readAsDataURL(files[0]);
    }
  };

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
    // Make the library regenerate aspect crops if loading new images.
    const { crop } = this.state;
    if (crop.aspect && crop.height && crop.width) {
      this.setState({
        crop: { ...crop, height: '100%' },
      });
    } else {
      this.makeClientCrop(crop, pixelCrop);
    }
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }
  onConfirmDelete() {
    this.props.onDeleteImage(this.props.performerId);
    this.setState({ openDeleteConfirmation: false });
  }
  onCancel() {
    this.props.onResetEditAndAddPlayer();
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL("image/jpeg");
    // return base64Image;

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          const url = URL.createObjectURL(blob);
          blob.name = fileName;
          resolve({ url, blob });
        },
        'image/png',
        1
      );
    });
  }

  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      // console.log('makeClientCrop', {
      //   imageRef: this.imageRef,
      //   crop,
      //   pixelCrop,
      // });
      const { url, blob } = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.png'
      );
      // console.log('cropped url', url);
      this.setState({ croppedImageUrl: url, blob });
    }
  }

  uploadImage = blob => {
    const authKey = localStorage.getItem('x-auth-key');
    //console.log("Id: ", performerId, "\nImage: ", imageUrl, "\nBlob: ", blob);
    let inputValues = {};
    if (this.props.transferId) {
      inputValues = {
        id: this.props.tranferId,
        target: 'performer_connection',
      };
    } else if (this.props.sponsorImage) {
      inputValues = {
        id: this.props.performerId,
        target: 'performer',
        pm_key: 'sponsorImage',
        // small: this.props.small
      };
    } else {
      inputValues = { id: this.props.performerId, target: 'performer' };
    }
    const inputForm = new FormData();
    Object.keys(inputValues).forEach(key => {
      inputForm.append(key, inputValues[key]);
    });
    // const file = new File([blob], 'image.png');
    const file = new File([blob], this.imageFileName);
    // console.log('Image file name: ', this.imageFileName);
    inputForm.append('file', file);
    //inputForm.append('value', file);
    if (this.props.sponsorImage) {
      this.props.uploadPerformerImage({
        formData: inputForm,
        authKey,
        module_id: 4,
      });
    } else {
      this.props.uploadPerformerImage(inputForm, authKey);
    }
    this.setState({
      src: null,
      croppedImageUrl: null,
      uploadStatus: 'waiting',
    });
  };

  isValidURL(string) {
    if (string.startsWith('data:')) {
      return true;
    }
    // const validUrl = String.raw`(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)`;
    // const res = string.match(`/${validUrl}/g`);
    const res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    );
    if (res) {
      return true;
    }
    return false;
  }

  imageExists(image_url) {
    const http = new XMLHttpRequest();
    http.open('HEAD', image_url, false);
    http.send();
    return http.status !== 404;
  }
  deleteImage() {
    this.setState({ openDeleteConfirmation: true });
  }

  render() {
    if (this.props.uploadStatus === 'waiting') {
      return (
        <div
          style={{
            height: '50px',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon
            name="circle notched"
            size="big"
            loading
            style={{ backgroundColor: 'transparent' }}
          />
        </div>
      );
    }
    const { croppedImageUrl, blob } = this.state;
    //console.log(this.props.src);
    return (
      <div>
        {/*style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}*/}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            minHeight: '200px',
            aspect: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!this.state.src && (
            <div
              style={{
                display: 'flex',
                width: '180px',
                height: '180px',
                marginBottom: '10px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {this.props.playerImg && (
                <img
                  style={{
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '180px',
                    maxHeight: '180px',
                  }}
                  src={
                    this.isValidURL(this.props.playerImg)
                      ? this.props.playerImg
                      : `${config.IMAGE_BASE_URL}${this.props.playerImg}`
                  }
                  alt={this.props.altText || T('No Image')}
                />
              )}
            </div>
          )}
          {this.state.src && (
            <Segment>
              <Label attached="top">{T('Crop Image Here')}</Label>
              <ReactCrop
                style={{ border: '1px #CBA84A' }}
                src={this.state.src}
                crop={this.state.crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </Segment>
          )}
          {this.state.src && !croppedImageUrl ? (
            <p
              style={{
                display: 'inline-block',
                backgroundColor: '#CBA84A',
                color: 'white',
              }}
            >
              {T('click on the logo to crop')}
            </p>
          ) : (
            ''
          )}
          {croppedImageUrl && (
            <Segment>
              <Label attached="top">{T('Preview')}</Label>
              <img
                style={{
                  //width: '200px',
                  width: '200px',
                  aspectRatio: 1,
                  border: '1px #CBA84A',
                  display: 'inline-flex',
                  marginLeft: '10px',
                }}
                alt="Crop"
                src={croppedImageUrl}
              />
            </Segment>
          )}

          <div>
            <Dropzone
              multiple={false}
              accept="image/png"
              style={{ marginBottom: '20px' }}
              onDrop={this.onSelectFile.bind(this)}
              // style={{ display: 'flex', justifyContent: 'center' }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  style={{
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input {...getInputProps()} />
                  {!croppedImageUrl && (
                    <Button content={T('Upload Image')} primary />
                  )}
                  <Label>{this.imageFileName || ''}</Label>
                  {/** bugfix in the package dropzone otherwise
                     ** use this otherwise
                     <Label size='large' color='blue' width={6} style={{ fontSize: 16, letterSpacing: 3 }}>
                       <Icon name='upload' />{T('Select image')}
                     </Label>  png, jpg, jpeg
                    */}
                </div>
              )}
            </Dropzone>
            {/*<input
                   className="wizardInput" style={{ width: '200px', overflow: 'ellipsis' }}
                   type="file" accept="image/png" onChange={this.onSelectFile.bind(this)}
            />*/}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {croppedImageUrl && (
            <Button
              icon="upload"
              primary
              onClick={() => this.uploadImage(blob)}
              content={T('CONFIRM UPLOAD')}
            />
          )}
          {!this.props.hideCancelButton && (
            <Button
              icon="cancel"
              secondary
              content={T('Cancel')}
              onClick={() => this.onCancel()}
            />
          )}
          {this.props.showDeleteButton === true && (
            <Button
              icon="delete"
              negative
              content={T('Delete')}
              onClick={this.deleteImage}
            />
          )}
          <Confirm
            header={T('Delete Image')}
            content={T('Are you sure you want to delete the image?')}
            confirmButton={T('Delete')}
            cancelButton={T('Cancel')}
            open={this.state.openDeleteConfirmation}
            onCancel={this.oncancelDeleteConfirmation}
            onConfirm={this.onConfirmDelete}
            size="tiny"
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ appConfig }) {
  return { appConfig };
}

export default connect(mapStateToProps, {})(ImageCrop);

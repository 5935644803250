import _ from 'lodash';
import {
  FETCH_VENUES_WAITING,
  FETCH_VENUES_SUCCESS,
  FETCH_VENUES_ERROR,
  FETCH_VENUES_RESET,
  FETCH_SUB_VENUES_WAITING,
  FETCH_SUB_VENUES_SUCCESS,
  FETCH_SUB_VENUES_ERROR,
  FETCH_SUB_VENUES_RESET,
  FETCH_VENUE_WAITING,
  FETCH_VENUE_SUCCESS,
  FETCH_VENUE_ERROR,
  FETCH_VENUE_RESET,
  EDIT_VENUE_WAITING,
  EDIT_VENUE_SUCCESS,
  EDIT_VENUE_ERROR,
  EDIT_VENUE_RESET,
  ADD_VENUE_WAITING,
  ADD_VENUE_SUCCESS,
  ADD_VENUE_ERROR,
  ADD_VENUE_RESET,
  DELETE_VENUE_WAITING,
  DELETE_VENUE_SUCCESS,
  DELETE_VENUE_ERROR,
  DELETE_VENUE_RESET,
  FETCH_SUB_VENUE_WAITING,
  FETCH_SUB_VENUE_SUCCESS,
  FETCH_SUB_VENUE_ERROR,
  FETCH_SUB_VENUE_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  fetchAll: 'init',
  operationState: {
    add: 'init',
    delete: 'init',
    update: 'init',
    fetch: 'init',
    subVenuesFetch: 'init',
  },
  venues: [],
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_VENUES_WAITING:
      return { ...state, fetchAll: 'waiting' };
    case FETCH_VENUES_SUCCESS: {
      const locationList = _.mapKeys(action.payload.data, 'place_id');
      return {
        ...state,
        venues: locationList,
        fetchAll: 'success',
        header: action.payload.headers,
      };
    }
    case FETCH_VENUES_ERROR:
      return { ...state, fetchAll: 'error', error: action.payload.error };
    case FETCH_VENUES_RESET:
      return { ...state, fetchAll: 'init' };

    case FETCH_SUB_VENUES_WAITING: {
      const operationState = {
        ...state.operationState,
        subVenuesFetch: 'waiting',
      };
      return { ...state, ...operationState };
    }
    case FETCH_SUB_VENUES_SUCCESS: {
      // const subVenues = _.mapKeys(action.payload.data, 'place_id');
      const operationState = {
        ...state.operationState,
        subVenuesFetch: 'success',
      };
      return {
        ...state,
        venues: [...action.payload.data],
        ...operationState,
        headerSubVenues: action.payload.headers,
      };
    }
    case FETCH_SUB_VENUES_ERROR: {
      const operationState = {
        ...state.operationState,
        subVenuesFetch: 'error',
      };
      return { ...state, ...operationState, error: action.payload.error };
    }
    case FETCH_SUB_VENUES_RESET: {
      const operationState = {
        ...state.operationState,
        subVenuesFetch: 'init',
      };
      return { ...state, ...operationState };
    }

    case FETCH_VENUE_WAITING: {
      const operationState = { ...state.operationState, fetch: 'waiting' };
      return { ...state, operationState };
    }
    case FETCH_VENUE_SUCCESS: {
      const operationState = { ...state.operationState, fetch: 'success' };
      return { ...state, venue: action.payload.data, operationState };
    }
    case FETCH_VENUE_ERROR: {
      const operationState = { ...state.operationState, fetch: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case FETCH_VENUE_RESET: {
      const operationState = { ...state.operationState, fetch: 'init' };
      return { ...state, operationState };
    }

    case FETCH_SUB_VENUE_WAITING: {
      const operationState = {
        ...state.operationState,
        fetchSubvenue: 'waiting',
      };
      return { ...state, operationState, subvenue: null };
    }
    case FETCH_SUB_VENUE_SUCCESS: {
      const operationState = {
        ...state.operationState,
        fetchSubvenue: 'success',
      };
      return { ...state, subvenue: action.payload.data, operationState };
    }
    case FETCH_SUB_VENUE_ERROR: {
      const operationState = {
        ...state.operationState,
        fetchSubvenue: 'error',
      };
      return {
        ...state,
        operationState,
        subvenue: null,
        error: action.payload.error,
      };
    }
    case FETCH_SUB_VENUE_RESET: {
      const operationState = { ...state.operationState, fetchSubvenue: 'init' };
      return { ...state, operationState, subvenue: null };
    }

    case ADD_VENUE_SUCCESS: {
      const operationState = { ...state.operationState, add: 'success' };
      return {
        ...state,
        venues: {
          ...state.venues,
          [action.payload.data.place_id]: action.payload.data,
        },
        venue: action.payload.data,
        operationState,
      };
    }
    case ADD_VENUE_WAITING: {
      const operationState = { ...state.operationState, add: 'waiting' };
      return { ...state, operationState };
    }
    case ADD_VENUE_ERROR: {
      const operationState = { ...state.operationState, add: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case ADD_VENUE_RESET: {
      const operationState = { ...state.operationState, add: 'init' };
      return { ...state, operationState };
    }

    case EDIT_VENUE_SUCCESS: {
      const operationState = { ...state.operationState, update: 'success' };
      return {
        ...state,
        venues: {
          ...state.venues,
          [action.payload.data.place_id]: action.payload.data,
        },
        venue: action.payload.data,
        operationState,
      };
    }
    case EDIT_VENUE_WAITING: {
      const operationState = { ...state.operationState, update: 'waiting' };
      return { ...state, operationState };
    }
    case EDIT_VENUE_ERROR: {
      const operationState = { ...state.operationState, update: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case EDIT_VENUE_RESET: {
      const operationState = { ...state.operationState, update: 'init' };
      return { ...state, operationState };
    }

    case DELETE_VENUE_WAITING: {
      const operationState = { ...state.operationState, delete: 'waiting' };
      return { ...state, operationState };
    }

    case DELETE_VENUE_SUCCESS: {
      const updatedLocationList = _.omit(state.venues, action.payload.place_id);
      const operationState = { ...state.operationState, delete: 'success' };
      return { ...state, venues: updatedLocationList, operationState };
    }
    case DELETE_VENUE_ERROR: {
      const operationState = { ...state.operationState, delete: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case DELETE_VENUE_RESET: {
      const operationState = { ...state.operationState, delete: 'init' };
      return { ...state, operationState };
    }
    default:
      return state;
  }
}

import _ from 'lodash';
import {
  FETCH_PERSONS_WAITING,
  FETCH_PERSONS_SUCCESS,
  FETCH_PERSONS_ERROR,
  FETCH_PERSONS_RESET,
  FETCH_PERSON_WAITING,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSON_ERROR,
  FETCH_PERSON_RESET,
  CREATE_PERSON_WAITING,
  CREATE_PERSON_SUCCESS,
  CREATE_PERSON_ERROR,
  CREATE_PERSON_RESET,
  DELETE_PERSON_WAITING,
  DELETE_PERSON_SUCCESS,
  DELETE_PERSON_ERROR,
  DELETE_PERSON_RESET,
  EDIT_PERSON_WAITING,
  EDIT_PERSON_SUCCESS,
  EDIT_PERSON_ERROR,
  EDIT_PERSON_RESET,
  CONFIRM_PERSON_REGISTRATION_WAITING,
  CONFIRM_PERSON_REGISTRATION_SUCCESS,
  CONFIRM_PERSON_REGISTRATION_ERROR,
  CONFIRM_PERSON_REGISTRATION_RESET,
  PERSON_CONFIRM_EMAIL_WAITING,
  PERSON_CONFIRM_EMAIL_SUCCESS,
  PERSON_CONFIRM_EMAIL_ERROR,
  PERSON_CONFIRM_EMAIL_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  operationState: {
    add: 'init',
    delete: 'init',
    update: 'init',
    fetch: 'init',
    fetchAll: 'init',
    emailStatus: 'init',
  },
  persons: {},
  person: {},
  error: '',
  resetKey: '',
};
export default function personReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_PERSONS_WAITING: {
      const operationState = { ...state.operationState, fetchAll: 'waiting' };
      return { ...state, operationState };
    }
    case FETCH_PERSONS_SUCCESS: {
      const persons = _.mapKeys(action.payload.data, 'pe_public_id');
      const operationState = { ...state.operationState, fetchAll: 'success' };
      const headers = action.payload.headers;
      return { ...state, operationState, headers, persons };
    }
    case FETCH_PERSONS_ERROR: {
      const operationState = { ...state.operationState, fetchAll: 'error' };
      return {
        ...state,
        operationState,
        persons: {},
        error: action.payload.error,
      };
    }
    case FETCH_PERSONS_RESET: {
      const operationState = { ...state.operationState, fetchAll: 'init' };
      return { ...state, operationState, persons: {} };
    }

    case FETCH_PERSON_WAITING: {
      const operationState = { ...state.operationState, fetch: 'waiting' };
      return { ...state, operationState, person: {} };
    }
    case FETCH_PERSON_SUCCESS: {
      const operationState = { ...state.operationState, fetch: 'success' };
      return { ...state, operationState, person: action.payload.data };
    }
    case FETCH_PERSON_ERROR: {
      const operationState = { ...state.operationState, fetch: 'error' };
      return {
        ...state,
        operationState,
        error: action.payload.error,
        person: {},
      };
    }
    case FETCH_PERSON_RESET: {
      const operationState = { ...state.operationState, fetch: 'init' };
      return { ...state, operationState, person: {} };
    }

    case CREATE_PERSON_WAITING: {
      const operationState = {
        ...state.operationState,
        add: 'waiting',
        error: '',
      };
      return { ...state, operationState, person: {} };
    }
    case CREATE_PERSON_SUCCESS: {
      const operationState = { ...state.operationState, add: 'success' };
      const persons = {
        ...state.persons,
        [action.payload.data.pe_public_id]: action.payload.data,
      };
      return { ...state, operationState, persons, person: action.payload.data };
    }
    case CREATE_PERSON_ERROR: {
      const operationState = { ...state.operationState, add: 'error' };
      // console.log("Registration error: ", action.payload.data.message);
      return {
        ...state,
        operationState,
        person: {},
        error: action.payload.error,
      };
    }
    case CREATE_PERSON_RESET: {
      const operationState = { ...state.operationState, add: 'init' };
      return { ...state, operationState, person: {} };
    }

    case EDIT_PERSON_WAITING: {
      const operationState = { ...state.operationState, update: 'waiting' };
      return { ...state, operationState, person: {} };
    }
    case EDIT_PERSON_SUCCESS: {
      const operationState = { ...state.operationState, update: 'success' };
      const persons = {
        ...state.persons,
        [action.payload.data.pe_public_id]: action.payload.data,
      };
      return { ...state, operationState, persons, person: action.payload.data };
    }
    case EDIT_PERSON_ERROR: {
      const operationState = { ...state.operationState, update: 'error' };
      return {
        ...state,
        operationState,
        person: {},
        error: action.payload.error,
      };
    }
    case EDIT_PERSON_RESET: {
      const operationState = { ...state.operationState, update: 'init' };
      return { ...state, operationState, person: {} };
    }

    case DELETE_PERSON_WAITING: {
      const operationState = { ...state.operationState, delete: 'waiting' };
      return { ...state, operationState };
    }
    case DELETE_PERSON_SUCCESS: {
      const operationState = { ...state.operationState, delete: 'success' };
      const persons = _.omit(state.persons, action.payload.data.pe_public_id);
      return { ...state, operationState, persons };
    }
    case DELETE_PERSON_ERROR: {
      const operationState = { ...state.operationState, delete: 'error' };
      return { ...state, error: action.payload.error, operationState };
    }
    case DELETE_PERSON_RESET: {
      const operationState = { ...state.operationState, delete: 'init' };
      return { ...state, operationState };
    }

    case CONFIRM_PERSON_REGISTRATION_WAITING: {
      const operationState = {
        ...state.operationState,
        confirmStatus: 'waiting',
      };
      return { ...state, operationState };
    }
    case CONFIRM_PERSON_REGISTRATION_SUCCESS: {
      //console.log(action.payload.data);
      const operationState = {
        ...state.operationState,
        confirmStatus: 'success',
      };
      return { ...state, operationState, ...action.payload.data };
    }
    case CONFIRM_PERSON_REGISTRATION_ERROR: {
      const operationState = {
        ...state.operationState,
        confirmStatus: 'error',
      };
      return { ...state, error: action.payload.error, operationState };
    }
    case CONFIRM_PERSON_REGISTRATION_RESET: {
      const operationState = {
        ...state.operationState,
        confirmStatus: 'reset',
      };
      return { ...state, operationState };
    }

    case PERSON_CONFIRM_EMAIL_WAITING: {
      const operationState = {
        ...state.operationState,
        emailStatus: 'waiting',
      };
      return { ...state, operationState };
    }
    case PERSON_CONFIRM_EMAIL_SUCCESS: {
      //console.log(action.payload);
      const operationState = {
        ...state.operationState,
        emailStatus: 'success',
      };
      return { ...state, operationState };
    }
    case PERSON_CONFIRM_EMAIL_ERROR: {
      const operationState = { ...state.operationState, emailStatus: 'error' };
      return { ...state, error: action.payload.error, operationState };
    }
    case PERSON_CONFIRM_EMAIL_RESET: {
      const operationState = { ...state.operationState, emailStatus: 'reset' };
      return { ...state, operationState };
    }
    default:
      return state;
  }
}

import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import Nav from '../layout/Nav';
import WorkCaseLogging from '../components/WorkCaseLogging/WorkCaseLogging';

const WorkCaseLoggingRoute = () => {
  const now = Date.now();
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: '150px' }}>
      <Nav />
      <Switch>
        <Route path={`${path}/list`}>
          <WorkCaseLogging key={`wcl_${now}`} list={true} />
        </Route>
        <Route path={`${path}/edit/:eventId`}>
          <WorkCaseLogging key={`wce_${now}`} />
        </Route>
      </Switch>
    </Container>
  );
};

export default WorkCaseLoggingRoute;

import React, { Component } from 'react';
import { Form, Grid, Segment, Label } from 'semantic-ui-react';
import { T } from '../Common/Helpers';
import GoogleMap from './GoogleMap';

export const getRadiusInMeters = (r, unit) => {
  let error = false;
  let val;
  if (unit === 'km') {
    if (r > 6371 * 2) {
      // Radus of earth = 6371 km
      error = false;
    } else {
      val = r * 1000;
    }
  } else if (unit === 'miles') {
    if (r > 3958.756 * 2) {
      error = true;
    } else {
      val = r * 1609.34;
    }
  }
  return { error, val };
};

class LotteryRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radiusError: false,
      regionActive: props.isActive,
      defaultReqion: false,
    };
  }

  // componentDidMount() {
  //   // Try HTML5 geolocation and set user's current location if allowed
  //   if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition((position) => {
  //         this.setState({
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude
  //         },
  //         this.props.setLocation(
  //           position.coords.latitude,
  //           position.coords.longitude,
  //           this.state.radius,
  //           this.state.unit));
  //       });
  //     }
  // }

  onInputChange = (name, value) => {
    let r;
    let unit;
    if (name === 'radius') {
      r = value;
      unit = this.props.region.unit;
    } else {
      r = this.props.region.radius;
      unit = value;
    }
    const response = getRadiusInMeters(r, unit);
    if (response.error) {
      this.setState({ radiusError: true });
    } else {
      this.props.setLocation(
        this.props.region.lat,
        this.props.region.lng,
        name === 'radius' ? value : this.props.region.radius,
        name === 'unit' ? value : this.props.region.unit,
        response.val
      );
    }
  };

  setLocation = ({ latLng }) => {
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.dispatchLocation(lat, lng);
  };

  dispatchLocation(lat, lng) {
    const response = getRadiusInMeters(
      this.props.region.radius,
      this.props.region.unit
    );
    if (response.error) {
      this.setState({ radiusError: true });
    } else {
      this.setState(
        { lat, lng },
        this.props.setLocation(
          lat,
          lng,
          this.props.region.radius,
          this.props.region.unit,
          response.val
        )
      );
    }
  }

  handleReqionDefaultValues = (name, value) => {
    if (name === 'regionDefaultValues' && value === true) {
      this.props.region.lat = '';
      this.props.region.lng = '';
      this.onInputChange('unit', 'km');
      this.onInputChange('radius', 1);
    }
  };

  render() {
    const { regionActive } = this.state;
    const { isEditable } = this.props;
    const { lat, lng, r } = this.props.region;
    return (
      <>
        <Form.Group widths="equal">
          <Form.Checkbox
            toggle
            disabled={!isEditable}
            name="regionActive"
            label={T('Puhelimen lokaatiotiedon mukaan rajaaminen')}
            checked={regionActive}
            onChange={(e, { name, checked }) =>
              this.setState({ regionActive: checked })
            }
          />
        </Form.Group>
        {regionActive && (
          <Segment>
            <Label attached="top">
              {T(
                'Valitse alue, jolla sovelluksen täytyy olla ollut viimeiseksi ennen arvontaa aktiivinen'
              )}
            </Label>
            <Grid columns="equal" stackable>
              <Grid.Row>
                <Grid.Column></Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Group widths="equal">
                    <Form.Input
                      name="latitude"
                      disabled={!isEditable}
                      label={T('Latitude')}
                      type="number"
                      step={0.0001}
                      value={this.props.region.lat}
                      onChange={(_, { value }) => {
                        this.dispatchLocation(value, this.props.region.lng);
                      }}
                    />
                    <Form.Input
                      name="longitude"
                      disabled={!isEditable}
                      label={T('Longitude')}
                      type="number"
                      step={0.0001}
                      value={this.props.region.lng}
                      onChange={(_, { value }) => {
                        this.dispatchLocation(this.props.region.lat, value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      error={this.state.radiusError}
                      disabled={!isEditable}
                      name="radius"
                      label={T('Radius')}
                      type="number"
                      min={0.1}
                      step={0.1}
                      value={this.props.region.radius}
                      onChange={(e, { name, value }) =>
                        this.onInputChange(name, value)
                      }
                    />
                    <Form.Select
                      name="unit"
                      label={T('Unit')}
                      disabled={!isEditable}
                      value={this.props.region.unit}
                      options={[
                        { text: 'km', value: 'km' },
                        { text: 'miles', value: 'miles' },
                      ]}
                      onChange={(e, { name, value }) =>
                        this.onInputChange(name, value)
                      }
                    />
                  </Form.Group>
                </Grid.Column>
                <Grid.Column>
                  <GoogleMap
                    lat={lat}
                    lng={lng}
                    radius={parseInt(r, 10)}
                    setLocation={isEditable ? this.setLocation : undefined}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        )}
      </>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    flexBasis: '100%',
    marginLeft: '10px',
    marginBottom: '20px',
  },
  formInput: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flexBasis: '300px',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  map: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flexBasis: '600px',
    alignItems: 'center',
  },
  inputItem: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
};

export default LotteryRegion;

export const FETCH_WORKSPACES_SUCCESS = "fetch-workspaces-success";
export const FETCH_WORKSPACES_WAITING = "fetch-workspaces-waiting";
export const FETCH_WORKSPACES_ERROR = "fetch-workspaces-error";
export const FETCH_WORKSPACES_RESET = "fetch-workspaces-reset";

export const ADD_WORKSPACES_SUCCESS = "add-workspaces-success";
export const ADD_WORKSPACES_WAITING = "add-workspaces-waiting";
export const ADD_WORKSPACES_ERROR = "add-workspaces-error";
export const ADD_WORKSPACES_RESET = "add-workspaces-reset";

export const FETCH_WORKSPACE_MODULES_WAITING =
  "fetch_workspace_modules_waiting";
export const FETCH_WORKSPACE_MODULES_SUCCESS =
  "fetch_workspace_modules_success";
export const FETCH_WORKSPACE_MODULES_ERROR = "fetch_workspace_modules_error";
export const FETCH_WORKSPACE_MODULES_RESET = "fetch_workspace_modules_reset";

export const SET_ACTIVE_WORKSPACE = "set_active_workspace";

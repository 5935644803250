import React, { Component } from 'react';
import { Segment, Header, Button, Confirm } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PerformerTeamSeriesControl from './PerformerTeamSeriesControl';
import { removePerformerFromEvent, fetchPerformer } from '../../Redux/actions';
import { T } from '../Common/Helpers';

class PerformerTeamSeriesList extends Component {
  constructor(props) {
    super(props);
    this.state = { openDeleteConfirmation: false, eventId: '' };
    this.handleSeasonDelete = this.handleSeasonDelete.bind(this);
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }

  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }
  onConfirmDelete() {
    const authKey = localStorage.getItem('x-auth-key');
    this.props.removePerformerFromEvent(
      this.props.performerId,
      this.state.eventId,
      authKey,
      this.props.ws_id
    );
    this.setState({ openDeleteConfirmation: false });
  }

  handleSeasonDelete(event, { value }) {
    this.setState({ openDeleteConfirmation: true, eventId: value });
    // this.props.fetchPerformer(authKey, this.props.performerId);
  }
  renderSeriesList(seasonList) {
    return _.map(
      seasonList,
      season =>
        season.event_id && (
          <p key={`${season.event_id}-${season.event_name}`}>
            <span style={{ color: 'steelblue', fontWeight: 'bold' }}>
              {season.ev_name || season.event_name}
            </span>
            <Button
              key={`btn-delete${season.event_id}`}
              name="delete"
              color="red"
              size="mini"
              value={season.event_id}
              icon="trash"
              floated="right"
              onClick={this.handleSeasonDelete}
            />
          </p>
        )
    );
  }
  render() {
    return (
      <div style={{ marginTop: '10px' }}>
        <Header as="h3" content={T('Seasons')} textAlign="left" />
        <Segment style={{ height: '200px', overflow: 'scroll' }}>
          {this.renderSeriesList(this.props.season)}
        </Segment>
        <Confirm
          header={T('Remove Season')}
          content={T('Are you sure you want to remove the season?')}
          confirmButton={T('Delete')}
          open={this.state.openDeleteConfirmation}
          onCancel={this.oncancelDeleteConfirmation}
          onConfirm={this.onConfirmDelete}
          size="tiny"
        />
        <PerformerTeamSeriesControl
          performerId={this.props.performerId}
          season={this.props.season}
        />
      </div>
    );
  }
}

function mapStateToProps({ workspace }) {
  return { ws_id: workspace.ws_id };
}

export default connect(mapStateToProps, {
  removePerformerFromEvent,
  fetchPerformer,
})(PerformerTeamSeriesList);

import axios from 'axios';
import config from '../../Config/AEConfig';
import {
  MODIFY_PRODUCT_STOCK_WAITING,
  MODIFY_PRODUCT_STOCK_SUCCESS,
  MODIFY_PRODUCT_STOCK_ERROR,
  MODIFY_PRODUCT_STOCK_RESET,
} from '../Types';
import ErrorAction from './ErrorAction';

const MODULE_ID = 18;
export const modifyProductStock =
  (authKey, productId, data, ws_id) => dispatch => {
    dispatch({ type: MODIFY_PRODUCT_STOCK_WAITING });
    // const data = { stock, pr_sales_unit, pr_unit, pr_max_purchase_qty, pr_max_person_qty, stock_clear };
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .put(
        `${config.BASE_URL}/product/${productId}/stock?ws_id=${ws_id}&module_id=${MODULE_ID}`,
        data
      )
      .then(response =>
        dispatch({ type: MODIFY_PRODUCT_STOCK_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, MODIFY_PRODUCT_STOCK_ERROR)
          : MODIFY_PRODUCT_STOCK_ERROR;
        // console.log('Type: ', type, '\nError: ', error);
        dispatch({ type, payload: error });
      });
  };

export const resetModifyProductStock = () => ({
  type: MODIFY_PRODUCT_STOCK_RESET,
});

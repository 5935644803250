import React, { Component } from 'react';
import { Form as SemanticForm, Button, Icon } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import _ from 'lodash';
import moment from 'moment';
import { T } from '../Common/Helpers';

// const formatDate = (d, today) => {
//   const temp = d.toString().split(' ');
//   if (today) {
//     return `${temp[1]} ${temp[2]} ${temp[3]}`;
//   }
//   return `${temp[2]} ${temp[1]} ${temp[3]}`;
// };
class PlayerMetadataFormInput extends Component {
  constructor(props) {
    super(props);
    this.state = { dob: '', selectedListValue: '' };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.onMetadataSelectedChange = this.onMetadataSelectedChange.bind(this);
    this.renderMetadataInputControls =
      this.renderMetadataInputControls.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSelectedChange = this.onSelectedChange.bind(this);
    this.onGenderSelectedChange = this.onGenderSelectedChange.bind(this);
  }
  componentDidMount() {
    if (this.props.performerMetadataList && !this.state.dob) {
      const dob =
        this.getPlayerMetadataValue(this.props.performerMetadataList, 'dob') ||
        '';
      this.setState({ dob });
    }
  }
  onCancel() {
    this.props.onResetEditAndAddPlayer();
  }
  onMetadataSelectedChange(event, { value }) {
    this.props.selectedValueChanged(value);
  }
  onSelectedChange(e, { value }) {
    this.setState({ selectedListValue: value });
  }

  onGenderSelectedChange(e, { value }) {
    this.setState({ selectedGender: value });
  }
  getPlayerMetadataValue(metadata, metadataKey) {
    const res = _.filter(
      metadata,
      e => e.pm_key.toLowerCase() === metadataKey.toLowerCase()
    );
    if (res.length > 0) {
      return res[0].pm_value;
    }
    return null;
  }

  getMetadataDataType(metadata, metadataKey) {
    const res = _.filter(
      metadata,
      e => e.dk_key.toLowerCase() === metadataKey.toLowerCase()
    );
    if (res.length > 0) {
      return res[0].dk_data_type;
    }
    return 'string';
  }

  getLabelForSelectedField(metadatalist, selectedValue) {
    const selectedLabel = _.filter(
      metadatalist,
      metadata => metadata.dk_key.toLowerCase() === selectedValue.toLowerCase()
    );
    return selectedLabel.length ? selectedLabel[0].dk_description : null;
  }
  getMetadataPossibleOutcomes(keyName) {
    const keys = this.props.metadataKeys;
    const fieldRoleValues = _.filter(keys, key => key.dk_key === keyName);
    const filedRoleOptions = _.map(fieldRoleValues[0].dk_values, role => ({
      text: role,
      value: role,
    }));
    return filedRoleOptions;
  }

  handleFormSubmit(formdata) {
    this.props.handleFormSubmit(
      formdata,
      this.state.dob,
      this.state.selectedRole,
      this.state.selectedListValue
    );
  }

  loadPerformerMetaDataItems(keys) {
    const options = _.map(keys, option => ({
      text: option.dk_description,
      value: option.dk_key,
    }));
    return (
      <Form.Field>
        <label htmlFor="metadataSelect"> {T('Attributes')} </label>
        <Form.Select
          required
          search
          name="selectedMetadata"
          onChange={this.onMetadataSelectedChange}
          value={this.props.selectedValue}
          style={{ height: '30px', width: '200px' }}
          options={options}
        />
      </Form.Field>
    );
  }

  handleDateChange(e, { value }) {
    this.setState({ dob: moment(value, 'l').format('DD.MM.YYYY') });
  }

  renderMetadataInputControls() {
    const selectedKey = this.props.selectedValue || 'dob';
    const value = this.getPlayerMetadataValue(
      this.props.performerMetadataList,
      selectedKey
    );
    const selectedDataType = this.getMetadataDataType(
      this.props.metadataKeys,
      selectedKey
    );
    const maxDate = moment().format('l');
    const minDate = moment(new Date(new Date().getYear() - 100, 0, 0)).format(
      'l'
    );
    switch (selectedDataType) {
      case 'date':
        return (
          <DateInput
            required
            name={selectedKey}
            label={selectedKey}
            startMode="year"
            animation="off"
            onChange={this.handleDateChange}
            dateFormat="l"
            closable
            value={
              this.state.dob
                ? moment(this.state.dob, 'DD.MM.YYYY').format('l')
                : ''
            }
            maxDate={maxDate}
            minDate={minDate}
            style={{ marginBottom: '20px', height: '30px', width: '200px' }}
          />
        );
      case 'list':
        return (
          <Form.Select
            required
            search
            label={selectedKey}
            name={selectedKey}
            onChange={this.onSelectedChange}
            value={value}
            style={{ height: '30px', width: '200px' }}
            options={this.getMetadataPossibleOutcomes(selectedKey)}
          />
        );
      case 'int':
      case 'float':
        return (
          <Form.Input
            required
            type="number"
            name={selectedKey}
            label={selectedKey}
            value={value || undefined}
            style={{ marginBottom: '20px', height: '30px', width: '200px' }}
          />
        );
      case 'string':
        return (
          <Form.Input
            required
            type="text"
            name={selectedKey}
            label={selectedKey}
            value={value}
            style={{ marginBottom: '20px', height: '30px', width: '300px' }}
          />
        );
      default:
        return (
          <Form.Input
            required
            type="text"
            name={selectedKey}
            label={selectedKey}
            value={selectedKey}
            style={{ marginBottom: '20px', height: '30px', width: '300px' }}
          />
        );
    }
  }
  render() {
    return (
      <Form
        style={{ padding: '20px' }}
        onValidSubmit={this.handleFormSubmit.bind(this)}
        ref={ref => (this.form = ref)}
      >
        {this.loadPerformerMetaDataItems(this.props.metadataKeys)}
        {this.renderMetadataInputControls()}
        <SemanticForm.Field>
          <Button color="green" type="submit" style={{ marginBottom: '15px' }}>
            <Icon name="save" /> {T('Save')}
          </Button>
          <Button
            style={{ marginBottom: '15px' }}
            color="black"
            type="button"
            onClick={this.onCancel}
          >
            <Icon name="ban" /> {T('Cancel')}
          </Button>
        </SemanticForm.Field>
      </Form>
    );
  }
}

export default PlayerMetadataFormInput;

import React, { Component } from "react";
import {
  Form,
  Input,
  Radio,
  Table,
  Button,
  Segment,
  Icon,
  Menu,
  Pagination,
} from "semantic-ui-react";
// import { Link } from 'react-router-dom';
import _ from "lodash";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import { T, getErrorMessage } from "../../Common/Helpers";
import { Spinner } from "../../Common/Spinner";
// import { toast } from 'react-semantic-toasts';
import ModalWindow, { ModalContext } from "../../Common/ModalWindow";
// import LocationAdd from './LocationAdd';
import LocationManager from "./LocationManager";
import {
  fetchLocations,
  resetFetchLocations,
  resetEditLocation,
  resetAddLocation,
} from "../../../Redux/actions";

class LocationList extends Component {
  constructor(props) {
    //console.log('Location list CONSTRUCTOR');
    super(props);
    this.searchLocations = _.debounce(this.searchLocations.bind(this), 500);
    this.state = {
      selectedLocation: "",
      selectedLocationId: -1,
      disableSelectedButton: true,
      sortByColumn: "location_id",
      direction: "asc",
    };
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleSelectedLocation = this.handleSelectedLocation.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.sortLocationsByColumnName = this.sortLocationsByColumnName.bind(this);
  }
  componentDidMount() {
    if (
      this.props.fetchStatus !== "success" ||
      this.props.fetchStatus !== "waiting"
    ) {
      const ws_id = this.props.workspace.activeWorkspace.id;
      const authKey = localStorage.getItem("x-auth-key");
      this.props.fetchLocations(
        authKey,
        ws_id,
        true,
        1,
        null,
        "location_id",
        "asc"
      );
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (
      this.props.editStatus !== "success" &&
      nextProps.editStatus === "success"
    ) {
      toast({
        type: "success",
        title: T("Success"),
        description: T("Location edited successfully"),
        time: 5000,
      });
      // this.props.history.push('/app/venues/list');
      this.props.resetEditLocation();
      // this.props.closeModal();
    } else if (
      this.props.editStatus !== "error" &&
      nextProps.editStatus === "error"
    ) {
      const errorObj = getErrorMessage(nextProps.locations.error);
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetEditLocation();
      // this.props.closeModal();
    } else if (
      this.props.addStatus !== "success" &&
      nextProps.addStatus === "success"
    ) {
      this.props.resetAddLocation();
      toast({
        type: "success",
        title: T("Success"),
        description: T("Location added successfully"),
        time: 5000,
      });
      // this.props.closeModal();
    } else if (
      this.props.addStatus !== "error" &&
      nextProps.addStatus === "error"
    ) {
      const errorObj = getErrorMessage(nextProps.locations.error);
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetAddLocation();
    }
  }
  componentWillUnmount() {
    this.props.resetFetchLocations();
  }

  getDirection(dirn) {
    if (dirn === "asc") {
      return "ascending";
    }
    return "descending";
  }

  searchLocations(e, { value }) {
    const ws_id = this.props.workspace.activeWorkspace.id;
    if (value) {
      const authKey = localStorage.getItem("x-auth-key");
      this.props.fetchLocations(
        authKey,
        ws_id,
        true,
        1,
        value,
        "location_id",
        "asc"
      );
    }
  }

  handleLocationChange(e, location) {
    this.setState({
      selectedLocationId: location.location_id,
      selectedLocation: location,
      disableSelectedButton: false,
    });
  }

  handleSelectedLocation(location) {
    this.props.setLocation(location);
    this.props.closeModal();
  }
  handlePaginationChange(e, { activePage }) {
    const authKey = localStorage.getItem("x-auth-key");
    const ws_id = this.props.workspace.activeWorkspace.id;
    const { headers } = this.props;
    this.props.fetchLocations(
      authKey,
      ws_id,
      true,
      activePage,
      headers.searchterm,
      headers.sort
    );
  }

  sortLocationsByColumnName(columnName) {
    const { direction, sortByColumn } = this.state;
    const { headers } = this.props;
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem("x-auth-key");
    if (sortByColumn === columnName) {
      const modifiedDirn = direction === "asc" ? "desc" : "asc";
      this.props.fetchLocations(
        authKey,
        ws_id,
        true,
        1,
        headers.searchterm,
        columnName,
        modifiedDirn
      );
      this.setState({ direction: modifiedDirn });
    } else {
      const modifiedDirn = "asc";
      this.props.fetchLocations(
        authKey,
        ws_id,
        true,
        1,
        headers.searchterm,
        columnName,
        modifiedDirn
      );
      this.setState({ sortByColumn: columnName, direction: modifiedDirn });
    }
  }

  renderLocations() {
    const locations = _.omit(this.props.locations.locations, ["status"]);
    if (!locations) return <div />;
    return _.map(
      locations,
      (location) =>
        location &&
        location.location_id && (
          <Table.Row
            key={location.location_id}
            onClick={(e) => this.handleLocationChange(e, location)}
          >
            <Table.Cell key={`${location.location_id}-id`}>
              {/* <Link
                    // to={`/app/performers/${performer.performer_id}`}
                    to={`/app/venues/${location.location_id}/edit`}
                > */}
              <Form.Field>
                <Radio
                  label={location.location_id}
                  name="radioGroup"
                  value={location.location_id}
                  checked={
                    this.state.selectedLocationId === location.location_id
                  }
                  onChange={(e) => this.handleLocationChange(e, location)}
                />
              </Form.Field>

              {/* </Link> */}
            </Table.Cell>
            <Table.Cell key={`${location.location_id}-name`}>
              {location.lo_name}
            </Table.Cell>
            <Table.Cell key={`${location.location_id}-latitude`}>
              {location.lo_latitude}
            </Table.Cell>
            <Table.Cell key={`${location.location_id}-Longitude`}>
              {location.lo_longitude}
            </Table.Cell>
            <Table.Cell
              key={`${location.location_id}-btn`}
              textAlign="right"
              onClick={(e) => e.stopPropagation()}
            >
              {/* <Link
                   to={{pathname: `/app/venues/Locations/${location.location_id}/edit`, location }}
               > */}
              <ModalWindow
                // buttonColor="#e0e1e2"
                buttonSize="tiny"
                buttonLabel={T("Edit")}
                header={T("Edit Location")}
                modalSize="large"
              >
                <ModalContext.Consumer>
                  {({ closeModal }) => (
                    <LocationManager
                      closeModal={closeModal}
                      location={location}
                    />
                  )}
                </ModalContext.Consumer>
              </ModalWindow>
              {/* </Link> */}
            </Table.Cell>
          </Table.Row>
        )
    );
  }

  render() {
    //console.log('Location list render method');
    if (this.props.fetchStatus !== "success") {
      return <Spinner />;
    }

    const column = this.state.sortByColumn;
    const dirn = this.state.direction;

    return (
      <Segment>
        <Input
          onChange={this.searchLocations}
          placeholder={T("search location")}
        />
        <Table compact celled padded selectable striped sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  column === "location_id" ? this.getDirection(dirn) : null
                }
                onClick={() => this.sortLocationsByColumnName("location_id")}
              >
                {T("ID")}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "lo_name" ? this.getDirection(dirn) : null}
                onClick={() => this.sortLocationsByColumnName("lo_name")}
              >
                {T("Name")}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  column === "lo_latitude" ? this.getDirection(dirn) : null
                }
                onClick={() => this.sortLocationsByColumnName("lo_latitude")}
              >
                {T("Latitude")}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  column === "lo_longitude" ? this.getDirection(dirn) : null
                }
                onClick={() => this.sortLocationsByColumnName("lo_longitude")}
              >
                {T("Longitude")}
              </Table.HeaderCell>
              <Table.HeaderCell />
              {/* <Table.HeaderCell /> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderLocations()}</Table.Body>
          {/* <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan='4'>
              <Link to="/app/venues/locations/add"><Button color="blue" primary content='Add Location' /></Link>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Button color="black" content="Cancel" onClick={this.props.closeModal} />
              <Button color="green" content="Select Location" onClick={()=>this.handleSelectedLocation(this.state.selectedLocation)} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer> */}
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="5">
                <ModalWindow
                  buttonColor="blue"
                  buttonSize="small"
                  modalSize="large"
                  buttonLabel={T("Add Location")}
                  header={T("Add Location")}
                >
                  <ModalContext.Consumer>
                    {({ closeModal }) => (
                      <LocationManager closeModal={closeModal} />
                    )}
                  </ModalContext.Consumer>
                </ModalWindow>
                <Button
                  color="green"
                  content={T("Select Location")}
                  onClick={() =>
                    this.handleSelectedLocation(this.state.selectedLocation)
                  }
                  style={{ marginLeft: "10px" }}
                />
                <Button
                  color="black"
                  content={T("Cancel")}
                  onClick={this.props.closeModal}
                  style={{ marginLeft: "10px" }}
                />
                <Menu floated="right" pagination style={{ marginTop: "20px" }}>
                  <Pagination
                    onPageChange={this.handlePaginationChange}
                    defaultActivePage={
                      this.props.headers ? this.props.headers.current_page : 1
                    }
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={
                      this.props.headers ? this.props.headers.total_pages : 1
                    }
                  />
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
    );
  }
}

function mapStateToProps({ locations, workspace }) {
  return {
    locations,
    fetchStatus: locations.operationState.locationsFetch,
    headers: locations.headers,
    editStatus: locations.operationState.update,
    addStatus: locations.operationState.add,
    workspace,
  };
}
export default connect(mapStateToProps, {
  fetchLocations,
  resetFetchLocations,
  resetEditLocation,
  resetAddLocation,
})(LocationList);

export const AUTH_WAITING = "auth_waiting";
export const AUTH_SUCCESS = "auth_success";
export const AUTH_ERROR = "auth_error";
export const AUTH_RESET = "auth_reset";

export const AUTH_CHECK_WAITING = "auth_check_waiting";
export const AUTH_CHECK_SUCCESS = "auth_check_success";
export const AUTH_CHECK_ERROR = "auth_check_error";
export const AUTH_CHECK_RESET = "auth_check_reset";

export const FORGET_SUCCESS = "forget-success";
export const FORGET_ERROR = "forget-error";
export const FORGET_WAITING = "forget-waiting";

export const RESET_SUCCESS = "reset-password";
export const RESET_ERROR = "reset-error";
export const RESET_WAITING = "reset-waiting";

export const ERROR = "error";

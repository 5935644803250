/* eslint-env browser */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import thunk from 'redux-thunk';
import Favicon from 'react-favicon';
import { SemanticToastContainer } from 'react-semantic-toasts';
import reducers from './Redux/reducers';
import Protected from './components/Home/Protected';
import Public from './components/Home/Public';
import App from './components/Home/App';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import 'react-image-crop/dist/ReactCrop.css';
import './Style/App.css';
import Logo from './layout/images/keino_favicon.png';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composeEnhancers(applyMiddleware(thunk));

export const store = createStore(reducers, /* preloadedState, */ enhancers);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Favicon url={Logo} />
    <Router>
      <Switch>
        <Route path="/auth">
          <Public />
        </Route>
        <Route path="/app">
          <Protected component={App} />
        </Route>
        <Route exact path="/">
          <Redirect to={{ pathname: '/app/dashboard' }} />
        </Route>
      </Switch>
    </Router>
    <SemanticToastContainer position="top-center" />
  </Provider>
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Accordion, Menu, List, Icon } from 'semantic-ui-react';
import { Spinner } from '../Common/Spinner';
import EventSideMenu from './EventSideMenu';
import { fetchSeasons } from '../../Redux/actions';

class EventSeriesList extends Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    this.props.fetchSeasons(authKey, this.props.eventId, this.props.ws_id);
  }

  toggleMenu() {
    this.setState({ expand: !this.state.expand });
  }

  editSelectedSeries = (e, eventId) => {
    e.stopPropagation();
    this.props.closeModal();
    this.props.history.push(`/app/events/season/edit/${eventId}`);
  };

  render() {
    const { eventId } = this.props;

    if (
      !this.props.parents[eventId] ||
      this.props.parents[eventId].statusSeasons !== 'success'
    ) {
      return <Spinner />;
    }

    const seasons = _.omit(this.props.parents[eventId], 'statusSeasons');

    return (
      <Menu.Item icon={false}>
        <Accordion.Title
          active={this.props.activeIndex === this.props.index}
          style={{ fontSize: '16px', fontWeight: '200px' }}
          index={this.props.index}
          onClick={this.props.handleClick}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'no-wrap',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Icon
                name={
                  this.props.activeIndex === this.props.index
                    ? 'caret square down'
                    : 'caret square right'
                }
              />
              <EventSideMenu
                eventId={this.props.eventId}
                menuItem={this.props.menuItem}
                toggleSeasons={this.toggleMenu}
                eventType={this.props.eventType}
                history={this.props.history}
                closeModal={this.props.closeModal}
              />
            </div>
            <Icon
              name="edit"
              style={{
                fontSize: '16px',
                fontWeight: '200px',
                textAlign: 'right',
              }}
              onClick={e => this.editSelectedSeries(e, this.props.eventId)}
            />
          </div>
        </Accordion.Title>
        <Accordion.Content
          active={this.props.activeIndex === this.props.index}
          style={{ fontSize: '13px', color: 'grey' }}
          content={_.map(seasons, season => (
            <List key={season.event_id} link>
              <EventSideMenu
                key={season.event_id}
                eventId={season.event_id}
                menuItem={season}
                eventType="season"
                history={this.props.history}
                closeModal={this.props.closeModal}
              />
            </List>
          ))}
        />
      </Menu.Item>
    );
  }
}

function mapStateToProps({ parents, events }) {
  return { parents, events };
}

export default connect(mapStateToProps, {
  fetchSeasons,
})(EventSeriesList);

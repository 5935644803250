import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { fetchCompany, resetFetchCompany } from '../../Redux/actions';
import EditCompany from './EditCompany';
import { Spinner } from '../Common/Spinner';
import { T } from '../Common/Helpers';

class ManageCompany extends Component {
  componentDidMount() {
    // localStorage.setItem('route', this.props.history.location.pathname);
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    const companyId = this.props.workspace.workspaces[ws_id].cnc_company;
    this.props.fetchCompany(companyId, authKey, ws_id);
  }
  componentWillUnmount() {
    this.props.resetFetchCompany();
  }
  render() {
    // console.log('COMPANY PROPS: ', this.props);
    if (
      this.props.fetchStatus === 'init' ||
      this.props.fetchStatus === 'waiting'
    ) {
      return <Spinner />;
    } else if (this.props.fetchStatus === 'error') {
      return (
        <Message
          error
          header={T('Error')}
          list={[T('Error while trying to fetch company')]}
        />
      );
    }
    return <EditCompany {...this.props} company={this.props.company} />;
  }
}

function mapStateToProps({ companies, workspace }) {
  return {
    company: companies.company,
    fetchStatus: companies.operationState.fetch,
    workspace,
  };
}

export default connect(mapStateToProps, { fetchCompany, resetFetchCompany })(
  ManageCompany
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner } from '../Common/Spinner';
import { fetchCards } from '../../Redux/actions';

import CardInstance from '../CardInstance/CardInstance';

const CARD_TYPE = 'membership';
const HEADER_LABEL = 'Add Membership';
const INFO_TEXT = [
  'Here you can add a membership registration to app user.',
  'Email address combines the membership card into mobile app user.',
  'When the user logs in into mobile app and pays a membership fee a membership card appears into app.',
  'You can also add a membership card into user by selecting option Add Membership Card from membership registration list.',
];
const SELECT_CARD_LABEL = 'Register Name';
const SELECT_CARD_PLACEHOLDER = 'Select a Register';
const RETURN_URL = '/app/membership/list';
const MODULE_ID = 39;

class MembershipCardAdd extends Component {
  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const wsId = this.props.ws_id;
    const applicationId = this.props.activeWorkspace.cnc_application;
    this.params = { wsId, applicationId, cardType: CARD_TYPE };
    this.cardInstanceObject = {
      card_instance_id: undefined,
      ci_account: undefined,
      ci_address1: undefined,
      ci_birthdate: undefined,
      ci_card: undefined,
      ci_city: undefined,
      ci_countrycode: undefined,
      ci_email: undefined,
      ci_first_sales_row: undefined,
      ci_firstname: undefined,
      ci_gender: undefined,
      ci_lastname: undefined,
      ci_licencecode: undefined,
      ci_member_id: undefined,
      ci_phone: undefined,
      ci_postalcode: undefined,
      ci_society: undefined,
      ci_state: undefined,
    };

    if (
      this.props.card.status === 'init' ||
      this.props.card.status === 'success'
    ) {
      this.props.fetchCards(
        MODULE_ID,
        authKey,
        wsId,
        applicationId,
        CARD_TYPE,
        false
      );
    }
  }

  render() {
    const params = {
      wsId: this.props.ws_id,
      applicationId: this.props.activeWorkspace.cnc_application,
      cardType: CARD_TYPE,
      selectCardLabel: SELECT_CARD_LABEL,
      selectCardPlaceholder: SELECT_CARD_PLACEHOLDER,
      returnURL: RETURN_URL,
      moduleId: MODULE_ID,
    };

    if (this.props.card.status !== 'success') {
      return <Spinner />;
    }

    if (this.props.card.status === 'success') {
      return (
        <CardInstance
          cardinstance={{ ...this.cardInstanceObject }}
          cards={this.props.card.data}
          headerLabel={HEADER_LABEL}
          params={params}
          {...this.props}
          infoText={INFO_TEXT}
        />
      );
    }
  }
}

function mapStateToProps({ workspace, card }) {
  return {
    workspace,
    ws_id: workspace.ws_id,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    card,
  };
}

export default connect(mapStateToProps, { fetchCards })(MembershipCardAdd);

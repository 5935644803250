import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-semantic-toasts";
import { Table, Confirm, Message, Icon } from "semantic-ui-react";
import {
  fetchNotificationCategories,
  resetFetchNotificationCategories,
  resetEditNotificationCategory,
  deleteNotificationCategory,
  resetDeleteNotificationCategory,
  fetchApplications,
} from "../../Redux/actions";
import EditNotificationCategory from "./EditNotificationCategory";
import ModalWindow, { ModalContext } from "../Common/ModalWindow";
import { Spinner } from "../Common/Spinner";
import { T, getErrorMessage } from "../Common/Helpers";

class ListNotificationCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByColumn: "message_category_id",
      direction: "asc",
      openDeleteConfirmation: false,
      deleteNotificationCategoryId: "",
    };
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.sortVenuesByColumnName = this.sortVenuesByColumnName.bind(this);
  }

  componentDidMount() {
    const authKey = localStorage.getItem("x-auth-key");
    const { sortByColumn, direction } = this.state;
    const { activeWorkspace } = this.props.workspace;
    if (activeWorkspace && activeWorkspace.id) {
      this.props.fetchNotificationCategories({
        authKey,
        ws_id: activeWorkspace.id,
        sortByColumn,
        dirn: direction,
        application_id: activeWorkspace.cnc_application,
      });
    }
    // if (this.props.auth.apps.length > 0) {
    //   this.props.fetchNotificationCategories({
    //     authKey,
    //     sortByColumn,
    //     dirn: direction,
    //     appId: this.props.auth.apps[0].application_id
    //   });
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeWorkspace } = this.props;
    const prevActiveWorkspace = prevProps.activeWorkspace;

    const ws_id = activeWorkspace && activeWorkspace.id;
    const prevWsId = prevActiveWorkspace && prevActiveWorkspace.id;

    if (prevWsId !== ws_id) {
      const authKey = localStorage.getItem("x-auth-key");
      const { sortByColumn, direction } = this.state;
      this.props.fetchNotificationCategories({
        authKey,
        ws_id: activeWorkspace.id,
        sortByColumn,
        dirn: direction,
        application_id: activeWorkspace.cnc_application,
      });
    }
    if (
      prevProps.editStatus !== "success" &&
      this.props.editStatus === "success"
    ) {
      toast({
        type: "success",
        title: T("Success"),
        description: T("Category updated successfully"),
        time: 5000,
      });
      prevProps.resetEditNotificationCategory();
    } else if (this.props.editStatus === "error") {
      const errorObj = getErrorMessage(this.props.error);
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
      prevProps.resetEditNotificationCategory();
    } else if (
      prevProps.deleteStatus !== "success" &&
      this.props.deleteStatus === "success"
    ) {
      prevProps.resetDeleteNotificationCategory();
      toast({
        type: "success",
        title: T("Success"),
        description: T("Category deleted successfully"),
        time: 5000,
      });
    } else if (
      prevProps.deleteStatus !== "error" &&
      this.props.deleteStatus === "error"
    ) {
      const errorObj = getErrorMessage(this.props.error);
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
      prevProps.resetDeleteNotificationCategory();
    }
  }

  onConfirmDelete() {
    const authKey = localStorage.getItem("x-auth-key");
    const ws_id = this.props.workspace.activeWorkspace.id;
    const categoryId = this.state.deleteNotificationCategoryId;
    this.props.deleteNotificationCategory({ categoryId, authKey, ws_id });
    this.setState({ openDeleteConfirmation: false });
  }
  onDeleteClick(e, categoryId) {
    e.stopPropagation();
    this.setState({
      openDeleteConfirmation: true,
      deleteNotificationCategoryId: categoryId,
    });
  }
  oncancelDeleteConfirmation() {
    this.setState({
      openDeleteConfirmation: false,
      deleteNotificationCategoryId: "",
    });
  }

  getDirection(dirn) {
    if (dirn === "asc") {
      return "ascending";
    }
    return "descending";
  }

  getApplicationsList = () =>
    this.props.auth.apps.map((app) => ({
      text: app.ap_name,
      value: app.application_id,
    }));

  sortVenuesByColumnName(columnName) {
    const { direction, sortByColumn } = this.state;
    const authKey = localStorage.getItem("x-auth-key");
    let modifiedDirn = direction === "asc" ? "desc" : "asc";
    const ws_id = this.props.workspace.activeWorkspace.id;
    if (sortByColumn === columnName) {
      this.setState({ direction: modifiedDirn });
    } else {
      modifiedDirn = "asc";
      this.setState({ sortByColumn: columnName, direction: modifiedDirn });
    }

    this.props.fetchNotificationCategories({
      authKey,
      ws_id,
      sortByColumn: columnName,
      direction: modifiedDirn,
      application_id: this.props.activeWorkspace.cnc_application,
    });
  }

  handlePaginationChange(e, { activePage }) {
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem("x-auth-key");
    this.props.fetchNotificationCategories({
      authKey,
      ws_id,
      activePage,
      application_id: this.props.workspace.activeWorkspace.cnc_application,
    });
  }

  renderNotificationCategories(categoriesList) {
    const column = this.state.sortByColumn;
    const dirn = this.state.direction;
    const result = (
      <div>
        <Table celled padded sortable stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  column === "message_category_id"
                    ? this.getDirection(dirn)
                    : null
                }
                onClick={() =>
                  this.sortVenuesByColumnName("message_category_id")
                }
                width="1"
              >
                {T("Id")}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "mc_name" ? this.getDirection(dirn) : null}
                onClick={() => this.sortVenuesByColumnName("mc_name")}
                width="4"
              >
                {" "}
                {T("Category name")}
              </Table.HeaderCell>
              <Table.HeaderCell width="1" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(categoriesList, (category) => {
              const columnItems = [];
              columnItems.push(category.message_category_id);
              columnItems.push(category.mc_name);
              if (category.mc_type !== "all") {
                columnItems.push(
                  <Icon
                    name="trash alternate"
                    color="red"
                    corner="bottom left"
                    size="large"
                    key={`button-${category.message_category_id}`}
                    //style={{ marginLeft: 2 }}
                    //className="ui red button"
                    //type="button" content={T('Delete')}
                    onClick={(e) =>
                      this.onDeleteClick(e, category.message_category_id)
                    }
                  />
                );
              } else {
                columnItems.push(
                  <div key={`button-${category.message_category_id}`} />
                );
              }
              // console.log('Ids: ',category.message_category_id );
              return (
                <ModalWindow
                  key={`modal-${category.message_category_id}`}
                  modalSize="tiny"
                  header={T("Edit Notification Category")}
                  tableRowTrigger={columnItems}
                >
                  <ModalContext.Consumer>
                    {({ closeModal }) => (
                      <EditNotificationCategory
                        key={`editNot-${category.message_category_id}`}
                        closeModal={closeModal}
                        categoryId={category.message_category_id}
                        category={category}
                      />
                    )}
                  </ModalContext.Consumer>
                </ModalWindow>
              );
            })}
          </Table.Body>
        </Table>
        <Confirm
          header={T("Delete Notification Category")}
          content={T(
            "Are you sure you want to delete the notification category?"
          )}
          confirmButton={T("Delete")}
          cancelButton={T("Cancel")}
          open={this.state.openDeleteConfirmation}
          onCancel={this.oncancelDeleteConfirmation}
          onConfirm={this.onConfirmDelete}
          size="tiny"
        />
      </div>
    );
    return result;
  }

  render() {
    // console.log('render ListNotificationCategories this.props', this.props);
    if (this.props.fetchAllCat === "error") {
      const errorObj = getErrorMessage(this.props.error);
      return (
        <Message
          negative
          icon="exclamation triangle"
          header={T("Error")}
          content={`${T("Status")}: ${errorObj.status}, ${T("Message")}: ${T(
            errorObj.message
          )}`}
        />
      );
    } else if (this.props.fetchAllCat !== "success") {
      return <Spinner />;
    }
    return this.renderNotificationCategories(this.props.categories);
  }
}

function mapStateToProps({ workspace, notifications, auth }) {
  return {
    categories: notifications.categories,
    fetchAllCat: notifications.operationState.fetchAllCat,
    error: notifications.error,
    header: notifications.header,
    editStatus: notifications.operationState.editCat,
    deleteStatus: notifications.operationState.deleteCat,
    auth,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
  };
}
export default connect(mapStateToProps, {
  fetchNotificationCategories,
  resetFetchNotificationCategories,
  resetEditNotificationCategory,
  deleteNotificationCategory,
  resetDeleteNotificationCategory,
  fetchApplications,
})(ListNotificationCategories);

import React, { Component } from 'react';
import _ from 'lodash';
// import UIfx from 'uifx';
import {
  Dropdown,
  Dimmer,
  Loader,
  Message,
  Segment,
  Divider,
  Card,
  Button,
  Icon,
  Transition,
  Confirm,
  Label,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Spinner } from '../Common/Spinner';
import config from '../../Config/AEConfig';
import { fetchShops, fetchSales } from '../../Redux/actions';
import { T } from '../Common/Helpers';
class Sales extends Component {
  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');

    //      ({ authKey, shopId, module_id = SALES_MODULE_ID, ws_id }) =>

    this.props.fetchSales({ authKey, shopId, ws_id });
  }
  render() {
    return <Label>Foo</Label>;
  }
}

function mapStateToProps({ auth, shop, workspace }) {
  return { auth, shop, workspace };
}

export default connect(mapStateToProps, { fetchShops, fetchSales })(Sales);

import {
  AUTH_RESET,
  FETCH_TICKETING_STATUS_WAITING,
  FETCH_TICKETING_STATUS_SUCCESS,
  FETCH_TICKETING_STATUS_ERROR,
  FETCH_TICKETING_STATUS_RESET,
} from '../Types';

const INIT_STATE = {
  ticketingStatus: [],
  ticketingStatusStatus: 'init',
  error: '',
};

export default function ticketingReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_TICKETING_STATUS_WAITING: {
      return { ...state, error: '', ticketingStatusStatus: 'waiting' };
    }
    case FETCH_TICKETING_STATUS_SUCCESS: {
      return {
        ...state,
        ticketingStatusStatus: 'success',
        ticketingStatus: action.payload.data,
      };
    }
    case FETCH_TICKETING_STATUS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        ticketingStatusStatus: 'error',
      };
    }
    case FETCH_TICKETING_STATUS_RESET: {
      return { ...state, ticketingStatusStatus: 'init', error: '' };
    }

    default:
      return state;
  }
}

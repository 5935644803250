//console.log/* eslint-env browser */
import React, { Component } from 'react';
import _ from 'lodash';
import {
  Form,
  Button,
  Dropdown,
  Segment,
  Menu,
  Message,
  Label,
} from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { toast } from 'react-semantic-toasts';
import { Spinner } from '../Common/Spinner';
import '../../Style/wysiwyg.css';
import {
  addCmsContent,
  editCmsContent,
  addCmsContentReset,
  editCmsContentReset,
  uploadFile,
  resetUploadFile,
} from '../../Redux/actions';
import {
  T,
  getErrorMessage,
  cleanHtml,
  DEFAULT_TOOLBAR_OPTIONS,
  handlePastedText,
} from '../Common/Helpers';
//import ListKeyValue from '../KeyValue/ListKeyValue';

const TRANSLATION_ERROR_MESSAGE =
  'App primary language translation is missing. Primary language is ';
const HTTP_MESSAGE = 'In content all URL links has to be HTTPS URLs.';

class ManageCmsContent extends Component {
  constructor(props) {
    super(props);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.onContentLanguageChange = this.onContentLanguageChange.bind(this);
    this.handleCommonFieldsChange = this.handleCommonFieldsChange.bind(this);
    this.setCmsCache = this.setCmsCache.bind(this);
    this.state = {};
  }

  componentDidMount() {
    let tempState = {
      errorObj: null,
    };
    const { primaryLanguage } = this.props.languages;
    const { ln_alpha_2 } = primaryLanguage;
    const primary_language_code = ln_alpha_2 || 'fi';
    let cm_language = primary_language_code;

    if (this.props.content) {
      // edit content
      const {
        cm_content_type,
        cm_valid_from,
        cm_valid_to,
        cm_main_image,
        cm_children,
      } = this.props.content;
      const parentId = this.props.content.cms_content_id;
      /* Setting the default values for the form fields. */
      let cm_content;
      let cm_short_header;
      let cm_url;
      let editorState = EditorState.createEmpty();
      let main_image = cm_main_image;

      const default_content =
        cm_children.find(c => c.cm_language === ln_alpha_2) ||
        (cm_children.length > 0 && cm_children[0]);
      if (default_content) {
        cm_language = default_content.cm_language;
        cm_content = default_content.cm_content;
        cm_short_header = default_content.cm_short_header;
        cm_url = default_content.cm_url;
        //const contentBlock = htmlToDraft(cm_content);
        //const contentState = ContentState.createFromBlockArray(
        //  contentBlock.contentBlocks
        //);
        /* Converting the HTML content into a DraftJS editor state. */
        const clean = cleanHtml(cm_content);
        const blocksFromHTML = convertFromHTML(clean);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        editorState = EditorState.createWithContent(contentState);
        main_image = default_content.cm_main_image;
      }
      tempState = {
        cm_image_updated: false,
        cm_content_type,
        cm_valid_from: cm_valid_from
          ? moment(cm_valid_from).format('l HH:mm')
          : moment().format('l HH:mm'),
        cm_valid_to: cm_valid_to ? moment(cm_valid_to).format('l HH:mm') : '',
        files: [],
        cm_main_image: main_image,
        cm_parent: parentId,
        editorContentCache: cm_children,
        editorState,
        cm_language,
        cm_short_header,
        cm_url,
        cm_content,
      };
      // setup cms content children files urls

      /**
       * Create an array of images with the following properties:
       * name: the name of the image
       * preview: the url of the image
       * lang: the language of the image
       * uploaded: true
       * key: a random number
       */
      const cImages = [];
      cm_children.forEach(c => {
        if (c.cm_main_image) {
          const name = c.cm_main_image.split('/').pop();
          cImages.push({
            name,
            preview: c.cm_main_image,
            lang: c.cm_language,
            updloaded: true,
            key: Math.random(),
          });
        }
      });
      tempState = { ...tempState, files: cImages };
    } else {
      // no content given in props
      tempState = {
        cm_image_updated: false,
        cm_url: '',
        editorState: EditorState.createEmpty(),
        cm_language,
        cm_short_header: '',
        contentSelectStyle: {},
        editorContentCache: [],
        cm_valid_from: moment().format('l HH:mm'),
        cm_valid_to: '',
        files: [],
        error: false,
        errorMessage: undefined,
        //cm_content_type: this.props.default_content_type
        cm_content_type: this.props.cm_content_type,
      };
    }
    tempState.formSettings = this.setSettings(tempState);
    this.setState(tempState);
  }
  /** NOTE: Don't fetch in componentDidMount IF it changed cmscontent state,
   * doing so will update cmscontentlist and cause infinite loop
   **/
  componentDidUpdate(prevProps) {
    //console.log(':::: cmscontent componentDidUpdate ::::', prevProps.cmsContent, this.props.cmsContent);
    //upload image success
    if (
      prevProps.file.uploadStatus !== 'success' &&
      this.props.file.uploadStatus === 'success'
    ) {
      // handleFileURLs will also take care of post data
      this.handleFileURLs(this.props.file);
      this.props.resetUploadFile();
    }

    // add success
    if (
      prevProps.cmsContent.addStatus !== 'success' &&
      this.props.cmsContent.addStatus === 'success'
    ) {
      this.props.addCmsContentReset();
      this.props.closeModal();
    }

    // edit success
    if (
      prevProps.cmsContent.editStatus !== 'success' &&
      this.props.cmsContent.editStatus === 'success'
    ) {
      this.props.editCmsContentReset();
      this.props.closeModal();
    }

    // upload file error
    if (
      prevProps.file.uploadStatus !== 'error' &&
      this.props.file.uploadStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.file.error,
        'Could not upload image'
      );
      this.props.resetUploadFile();
      this.displayError(errorObj);
    }

    // Error adding cms content ...
    if (
      prevProps.cmsContent.addStatus !== 'error' &&
      this.props.cmsContent.addStatus === 'error'
    ) {
      this.props.resetCmsContents();
      const errorObj = getErrorMessage(
        this.props.cmsContent.error,
        'Failed to add cms content'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }

    // Error editing cms content ...
    if (
      prevProps.cmsContent.editStatus !== 'error' &&
      this.props.cmsContent.editStatus === 'error'
    ) {
      this.props.resetCmsContents();
      const errorObj = getErrorMessage(
        this.props.cmsContent.error,
        'Failed to update cms content'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  /**
   * Takes the current DraftJS editorState, converts it to HTML, and then stores it in the state
   * @param editorState - The current state of the editor.
   */
  onEditorStateChange(editorState) {
    const { parentId } = this.props.match.params;
    const rawContent = cleanHtml(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    const { cm_language, editorContentCache, cm_short_header } = this.state;
    const index = _.findIndex(
      editorContentCache,
      item => item.cm_language === cm_language
    );
    if (index !== -1) {
      const { cms_content_id } = editorContentCache[index];
      let newEditorContentCache = [...editorContentCache];
      newEditorContentCache[index] = {
        cms_content_id,
        cm_language,
        cm_content: rawContent,
        cm_parent: parentId,
        cm_short_header,
      };
      this.setState({ editorContentCache: newEditorContentCache, editorState });
    } else {
      this.setState({
        editorContentCache: editorContentCache.concat({
          cms_content_id: null,
          cm_language,
          cm_content: rawContent,
          cm_parent: null,
          cm_short_header,
        }),
        editorState,
      });
    }
  }

  /**
   * Takes the value of the language dropdown and checks if the language is already in the
   * editorContentCache. If it is, it sets the editorState to the content of that language. If it isn't,
   * it sets the editorState to an empty editor
   * @param e - event
   */
  onContentLanguageChange(e, { value }) {
    const { parentId } = this.props.match.params;
    const { editorContentCache, files } = this.state;
    const index = _.findIndex(
      editorContentCache,
      item => item.cm_language === value
    );
    if (index !== -1) {
      const {
        cm_short_header,
        cm_content,
        cms_content_id,
        cm_url,
        cm_main_image,
      } = editorContentCache[index];
      const clean = cleanHtml(cm_content);
      const blocksFromHTML = convertFromHTML(clean);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      editorContentCache.splice(index, 1, {
        cms_content_id,
        cm_language: value,
        cm_content,
        cm_parent: parentId,
        cm_short_header,
        cm_url,
        files,
        cm_main_image,
      });
      this.setState({
        editorState,
        editorContentCache,
        cm_language: value,
        cm_short_header,
        cm_url,
        files,
      });
    } else {
      this.setState({
        editorState: EditorState.createEmpty(),
        cm_language: value,
        cm_short_header: '',
        cm_url: '',
        files,
        cm_main_image: null,
      });
    }
  }

  /* Go back to the previous page. */
  onCancel() {
    window.history.back();
  }

  /**
   * `onUpdateState` is a function that takes two arguments, `key` and `value`, and sets the state of the
   * component to the value of the `key` argument
   * @param key - The key of the state object you want to update.
   * @param value - The value of the input field.
   */
  onUpdateState(key, value) {
    this.setState({ [key]: value });
  }

  /**
   * Takes a dropped file as an argument and updates the state of the component
   * @param f - The file object that was dropped.
   */
  onDrop(f) {
    const { files, cm_language } = this.state;
    const updated = f.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        key: Math.random(),
        lang: cm_language,
        uploaded: false,
      })
    );
    const index = _.findIndex(files, item => item.lang === cm_language);
    if (index !== -1) {
      files.splice(index, 1, updated[0]);
    } else {
      files.push(updated[0]);
    }
    this.setState({
      files,
      cm_image_updated: true, // used to check if any changes in images
    });
  }

  /**
   * It sets the state of the update property to the value of the updateStatus parameter.
   * @param [updateStatus] - boolean, default = false
   */
  setUpdated(updateStatus = false) {
    this.setState({ update: updateStatus });
  }

  /**
   * Returns an object with the settings for the modal based on the type of content that is being
   * edited
   * @param tempState - the state of the component
   * @returns An object with the following properties:
   *   type: 'eventdescription',
   *   header: 'Manage Event Description',
   *   url: false,
   *   shortHeader: 'Short header',
   */
  setSettings(tempState) {
    switch (tempState.cm_content_type) {
      case 'eventdescription':
        return {
          type: 'eventdescription',
          header: 'Manage Event Description',
          url: false,
          shortHeader: 'Short header',
        };
      case 'news':
        return {
          type: 'new',
          header: 'Manage News Content',
          url: false,
          shortHeader: 'Short header',
        };
      case 'ticket_shop_url':
        return {
          type: 'ticket_shop',
          header: 'Manage Ticket Shop Link',
          url: true,
          shortHeader: 'Ticket Shop Link Name In Mobile App',
          urlLabel: 'Ticket Shop Link URL',
        };
      default:
        return {
          header: 'Manage Content',
          shortHeader: 'Short Header',
          url: false,
        };
    }
  }

  getContentTypeOptionsList() {
    return _.map(this.props.contenttypes, contenttype => ({
      text: T(contenttype.dk_values),
      value: contenttype.dk_key,
    }));
  }

  getLanguageList() {
    return _.map(this.props.languages.languages, language => ({
      text: T(language.ln_name),
      value: language.ln_alpha_2,
    }));
  }

  getContentTypeValues() {
    switch (this.props.cm_content_type) {
      case 'eventdescription':
        return 'jotain';
      default:
        return 'jotain muta';
    }
  }

  setCmsCache(
    editorState,
    cm_language,
    cm_short_header,
    cm_url,
    cm_main_image,
    files
  ) {
    //init editor and set content cache
    this.setState({
      editorState,
      editorContentCache: this.props.cmsContent.contentChildren,
      cm_language,
      cm_short_header,
      cm_url,
      cm_main_image,
      files,
    });
  }

  validateInputs() {
    const requiredFields = [
      'cm_language',
      'cm_short_header',
      'cm_content_type',
    ];
    const invalidFields = _.filter(
      requiredFields,
      field => this.state[field].trim() === ''
    );
    if (invalidFields.length) {
      const errorFields = _.reduce(
        invalidFields,
        (acc, field) => ({ ...acc, [`${field}Error`]: true }),
        {}
      );
      this.setState({ ...errorFields });
      return false;
    }
    return true;
  }

  hasPrimaryLanguage() {
    const error = T(TRANSLATION_ERROR_MESSAGE);
    const primary = this.state.editorContentCache.find(
      child =>
        child.cm_language === this.props.languages.primaryLanguage.ln_alpha_2
    );
    if (primary === undefined) {
      const errorObj = getErrorMessage(
        this.props.file.error,
        `${error}${this.props.languages.primaryLanguage.ln_name}`
      );
      this.displayError(errorObj);
    }
    return primary !== undefined;
  }

  /* The function that is called when the user clicks the save button. */
  postData() {
    const formIsValid = this.validateInputs();
    const hasPrimary = this.hasPrimaryLanguage();
    if (formIsValid && hasPrimary) {
      const {
        cm_content_type,
        cm_language,
        cm_short_header,
        cm_url,
        cm_valid_from,
        cm_valid_to,
        cm_main_image,
        cm_file_id,
        editorContentCache,
        cm_parent,
      } = this.state;
      const data = {
        cm_content_type,
        cm_language,
        cm_short_header,
        cm_url,
        [`cm_${this.props.config.content_category}`]:
          this.props.config.category_id,
        cm_main_image,
        cm_file_id,
        cm_parent,
        cm_children: editorContentCache,
      };
      if (cm_valid_from) {
        data.cm_valid_from = moment(cm_valid_from, 'l HH:mm').toISOString();
      }
      if (cm_valid_to) {
        data.cm_valid_to = moment(cm_valid_to, 'l HH:mm').toISOString();
      }

      if (
        cm_parent &&
        cm_language !== this.props.languages.primaryLanguage.ln_alpha_2
      ) {
        data.cm_short_header = undefined;
      }

      const authKey = localStorage.getItem('x-auth-key');
      let updatedData = { ...data };
      if (this.props.file.data && this.state.cm_image_updated) {
        updatedData = {
          ...data,
          cm_main_image: this.props.file.data.urlLarge,
          cm_file_id: this.props.file.data.file_id,
        };
      }

      if (cm_parent) {
        this.props.editCmsContent(
          cm_parent,
          updatedData,
          authKey,
          this.props.ws_id
        );
      } else {
        this.props.addCmsContent(updatedData, authKey, this.props.ws_id);
      }

      const { closeModal } = this.props;
      if (closeModal) {
        this.props.closeModal();
      } else {
        this.props.history.push('/app/cmscontent/news/list');
      }
    }
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  handleFileURLs(file) {
    const { editorContentCache, files } = this.state;
    const { ref, data } = file;
    if (ref) {
      const index = _.findIndex(
        editorContentCache,
        item => item.cm_language === ref
      );
      if (index !== -1) {
        // update cms_children
        editorContentCache[index] = {
          ...editorContentCache[index],
          cm_main_image: data.urlLarge,
        };
        // update file upload status
        const findex = _.findIndex(files, f => f.lang === ref);
        if (findex !== -1) {
          files[findex] = { ...files[findex], uploaded: true };
        }
        this.setState({ editorContentCache, files }, () => this.initPostdata());
      }
    }
  }

  handleCommonFieldsChange(e, { name, value }) {
    const { cm_language, files, editorContentCache } = this.state;
    const index = _.findIndex(
      editorContentCache,
      item => item.cm_language === cm_language
    );
    if (index !== -1) {
      editorContentCache[index] = {
        ...editorContentCache[index],
        [name]: value,
      };
      this.setState({ editorContentCache, [name]: value });
    } else {
      this.setState({
        editorContentCache: editorContentCache.concat({
          cms_content_id: null,
          cm_language,
          cm_content: '',
          cm_parent: null,
          [name]: value,
        }),
        [name]: value,
        files,
        editorState: EditorState.createEmpty(),
      });
    }
  }

  handleDismiss() {
    this.setState({ errorObj: null });
  }

  handleFormSubmit() {
    const { files, cm_image_updated } = this.state;
    const cm_content_type = this.state.cm_content_type
      ? this.state.cm_content_type
      : this.props.cm_content_type;
    const category_id = this.props.config.category_id;
    const target = this.props.target;
    const { ws_id } = this.props;
    if (files.length > 0 && cm_image_updated) {
      // const { ws_id, workspace } = this.props; // not required anymore
      //upload files and then post form data in componentDidUpdate
      files.forEach(file => {
        // upload only changed files
        if (file.uploaded === false) {
          const reader = new FileReader();
          reader.onload = () => {
            const formData = new FormData();
            formData.append('target', target);
            // change this line
            const inputValues = {
              id: category_id,
              content_type: cm_content_type,
            };
            Object.keys(inputValues).forEach(key => {
              formData.append(key, inputValues[key]);
            });
            formData.append('file', file);
            const authKey = localStorage.getItem('x-auth-key');
            this.props.uploadFile({
              authKey,
              formData,
              module_id: 15,
              ws_id,
              ref: file.lang,
            });
          };
          reader.onabort = () => console.log('file reading was aborted');
          reader.onerror = () => console.log('file reading has failed');
          reader.readAsBinaryString(file);
        }
      });
    } else {
      this.postData();
    }
  }

  displayError(errorObj) {
    this.setState({ errorObj });
  }

  initPostdata() {
    const { files } = this.state;
    let shouldPostData = true;
    files.forEach(file => {
      shouldPostData = file.uploaded !== false;
    });
    if (shouldPostData === true) {
      this.postData();
    }
  }

  render() {
    const { files, cm_language, errorObj, formSettings } = this.state;
    if (
      this.props.file.uploadStatus === 'waiting' ||
      this.props.cmsContent.editStatus === 'waiting' ||
      this.props.cmsContent.addStatus === 'waiting' ||
      !files
    ) {
      return <Spinner />;
    }
    const thumbs = files.map(file => {
      if (file.lang === cm_language) {
        return (
          <div style={thumb} key={file.key}>
            <div style={thumbInner}>
              <img src={file.preview} style={img} alt={file.name} />
            </div>
          </div>
        );
      }
      return null;
    });
    return (
      /*
      <ListKeyValue
        list_type={this.props.content_type}
        cm_content_type={this.state.cm_content_type}
        onChange={this.handleChange.bind(this)}
        onUpdateState={this.onUpdateState.bind(this)}
        list={this.getContentTypeOptionsList()}
        field_name={FIELD_NAME}
        label={LABEL}
        multiple_options={this.props.multiple_options}
      />*/
      <>
        <Menu
          attached="top"
          color="grey"
          secondary
          style={{ backgroundColor: '#e8e8e8' }}
        >
          <Menu.Item as={Label}>{T(formSettings.header)}</Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item as={Label}>{T('Language')}:</Menu.Item>
            <Dropdown
              required
              search
              name="cm_language"
              label={T('Language')}
              placeholder={T('Language')}
              selection
              selectOnNavigation
              noResultsMessage={T('No result')}
              style={this.state.contentSelectStyle}
              onChange={this.onContentLanguageChange}
              options={this.getLanguageList()}
              value={this.state.cm_language}
            />
          </Menu.Menu>
        </Menu>
        <Segment attached="bottom">
          <Form ref={ref => (this.form = ref)}>
            {errorObj ? (
              <Message
                onDismiss={this.handleDismiss}
                error
                header={T(errorObj.error)}
                list={[T(errorObj.message)]}
              />
            ) : null}
            <Form.Group widths="equal">
              <Form.Input
                required
                name="cm_short_header"
                label={`${T(formSettings.shortHeader)} [${
                  this.state.cm_language
                }]`}
                value={this.state.cm_short_header || ''}
                onChange={this.handleCommonFieldsChange}
              />
            </Form.Group>
            {formSettings.url ? (
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Input
                    fluid
                    name="cm_url"
                    label={T(formSettings.urlLabel)}
                    onChange={this.handleCommonFieldsChange}
                    value={this.state.cm_url || ''}
                  />
                </Form.Field>
              </Form.Group>
            ) : (
              <>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>{T('Image')}</label>
                    <Dropzone
                      multiple={false}
                      accept="image/jpeg, image/png"
                      onDrop={this.onDrop.bind(this)}
                      name="cm_main_image"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <Button
                            icon="upload"
                            label={T('Select image')}
                            primary
                            onClick={e => e.preventDefault()}
                          />
                          png, jpg, jpeg
                        </div>
                      )}
                    </Dropzone>
                    <aside style={thumbsContainer}>{thumbs}</aside>
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <label>{T('Content')}</label>
                    <label style={{ color: 'red' }}>{T(HTTP_MESSAGE)}</label>
                    <Editor
                      editorState={this.state.editorState}
                      onEditorStateChange={this.onEditorStateChange}
                      required
                      toolbar={DEFAULT_TOOLBAR_OPTIONS}
                      handlePastedText={(...props) =>
                        handlePastedText(...props)
                      }
                      label={T('Content')}
                    />
                  </Form.Field>
                </Form.Group>
              </>
            )}
            <Form.Group widths="equal">
              <Form.Field>
                <DateTimeInput
                  required
                  closable
                  name="cm_valid_from"
                  label={T('Valid from')}
                  onChange={this.handleChange}
                  value={this.state.cm_valid_from}
                  dateTimeFormat="l HH:mm"
                  minDate={this.state.cm_valid_from}
                  animation="off"
                />
              </Form.Field>
              <Form.Field>
                <DateTimeInput
                  // required
                  closable
                  name="cm_valid_to"
                  label={T('Valid to')}
                  onChange={this.handleChange}
                  value={this.state.cm_valid_to}
                  dateTimeFormat="l HH:mm"
                  animation="off"
                  minDate={this.state.cm_valid_from}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Button
                content={T('Save')}
                icon="save"
                color="green"
                onClick={this.handleFormSubmit.bind(this)}
              />
              <Button
                type="button"
                icon="cancel"
                secondary
                content={T('Cancel')}
                onClick={e => {
                  this.state = {};
                  this.props.closeModal(e);
                }}
              />
            </Form.Group>
          </Form>
        </Segment>
      </>
    );
  }
}

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

function mapStateToProps({ cmsContent, languages, file, auth, workspace }) {
  return {
    cmsContent,
    languages,
    auth,
    file,
    workspace,
    ws_id: workspace.ws_id,
  };
}

export default connect(mapStateToProps, {
  addCmsContent,
  addCmsContentReset,
  editCmsContentReset,
  editCmsContent,
  uploadFile,
  resetUploadFile,
})(withRouter(ManageCmsContent));

import axios from 'axios';
import config from '../../Config/AEConfig';
import ErrorAction from './ErrorAction';
import {
  FETCH_PERSONS_WAITING,
  FETCH_PERSONS_SUCCESS,
  FETCH_PERSONS_ERROR,
  FETCH_PERSONS_RESET,
  FETCH_PERSON_WAITING,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSON_ERROR,
  FETCH_PERSON_RESET,
  CREATE_PERSON_WAITING,
  CREATE_PERSON_SUCCESS,
  CREATE_PERSON_ERROR,
  CREATE_PERSON_RESET,
  EDIT_PERSON_WAITING,
  EDIT_PERSON_SUCCESS,
  EDIT_PERSON_ERROR,
  EDIT_PERSON_RESET,
  DELETE_PERSON_WAITING,
  DELETE_PERSON_SUCCESS,
  DELETE_PERSON_ERROR,
  DELETE_PERSON_RESET,
  CONFIRM_PERSON_REGISTRATION_WAITING,
  CONFIRM_PERSON_REGISTRATION_SUCCESS,
  CONFIRM_PERSON_REGISTRATION_ERROR,
  CONFIRM_PERSON_REGISTRATION_RESET,
  PERSON_CONFIRM_EMAIL_WAITING,
  PERSON_CONFIRM_EMAIL_SUCCESS,
  PERSON_CONFIRM_EMAIL_ERROR,
  PERSON_CONFIRM_EMAIL_RESET,
} from '../Types';

const MODULE_ID = 5;
const EDIT_PROFILE_MODULE_ID = 10;

export function fetchPersons(
  authKey,
  ws_id,
  startPage,
  searchterm,
  sortByColumn,
  sortDirection
) {
  return dispatch => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_PERSONS_WAITING });
    let url = `${config.BASE_URL}/persons?module_id=${MODULE_ID}&start=${startPage}&ws_id=${ws_id}`;
    if (searchterm) {
      url += `&searchterm=${searchterm}`;
    }
    if (sortByColumn && sortDirection) {
      url += `&sort=${sortByColumn}:${sortDirection}`;
    } else if (sortByColumn) {
      url += `&sort=${sortByColumn}`;
    }
    axios
      .get(url)
      .then(response =>
        dispatch({ type: FETCH_PERSONS_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_PERSONS_ERROR)
          : FETCH_PERSONS_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetFetchPersons() {
  return { type: FETCH_PERSONS_RESET };
}

export function fetchPerson(personId, authKey, ws_id) {
  return dispatch => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_PERSON_WAITING });
    axios
      .get(
        `${config.BASE_URL}/person/${personId}?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then(response =>
        dispatch({ type: FETCH_PERSON_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_PERSON_ERROR)
          : FETCH_PERSON_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetFetchPerson() {
  return { type: FETCH_PERSON_RESET };
}

export const addPerson = (data, ws_id) => dispatch => {
  // console.log(data);
  dispatch({ type: CREATE_PERSON_WAITING });
  // axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .post(
      `${config.BASE_URL}/person?module_id=${MODULE_ID}&ws_id=${ws_id}`,
      data
    )
    .then(response =>
      dispatch({ type: CREATE_PERSON_SUCCESS, payload: response })
    )
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, CREATE_PERSON_ERROR)
        : CREATE_PERSON_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetAddPerson = () => ({ type: CREATE_PERSON_RESET });

export const editPerson = (personId, data, authKey, ws_id) => dispatch => {
  dispatch({ type: EDIT_PERSON_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .put(
      `${config.BASE_URL}/person/${personId}?module_id=${EDIT_PROFILE_MODULE_ID}&ws_id=${ws_id}`,
      data
    )
    .then(response =>
      dispatch({ type: EDIT_PERSON_SUCCESS, payload: response })
    )
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, EDIT_PERSON_ERROR)
        : EDIT_PERSON_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetEditPerson = () => ({ type: EDIT_PERSON_RESET });

export const deletePerson = (id, authKey, ws_id) => dispatch => {
  dispatch({ type: DELETE_PERSON_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .delete(
      `${config.BASE_URL}/person/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`
    )
    .then(response =>
      dispatch({ type: DELETE_PERSON_SUCCESS, payload: response })
    )
    .catch(error => {
      const type = error.response
        ? ErrorAction(error.response.status, DELETE_PERSON_ERROR)
        : DELETE_PERSON_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetDeletePerson = () => ({ type: DELETE_PERSON_RESET });

export function confirmPersonRegistration(email, key, expire) {
  return dispatch => {
    delete axios.defaults.headers.common['x-auth-key'];
    dispatch({ type: CONFIRM_PERSON_REGISTRATION_WAITING });
    axios
      .get(
        `${config.BASE_URL}/person/activate/${email}?key=${key}&expy=${expire}`
      )
      .then(response =>
        dispatch({
          type: CONFIRM_PERSON_REGISTRATION_SUCCESS,
          payload: response,
        })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              CONFIRM_PERSON_REGISTRATION_ERROR
            )
          : CONFIRM_PERSON_REGISTRATION_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetPersonRegistration() {
  return { type: CONFIRM_PERSON_REGISTRATION_RESET };
}

export const sendConfirmationEmail =
  ({ pe_public_id }) =>
  dispatch => {
    dispatch({ type: PERSON_CONFIRM_EMAIL_WAITING });
    const url = `${config.BASE_URL}/person/application/email?module_id=${MODULE_ID}`;
    axios
      .post(url, { pe_public_id })
      .then(response =>
        dispatch({ type: PERSON_CONFIRM_EMAIL_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, PERSON_CONFIRM_EMAIL_ERROR)
          : PERSON_CONFIRM_EMAIL_ERROR;
        dispatch({ type, payload: error });
      });
  };

export function reseConfirmationEmail() {
  return { type: PERSON_CONFIRM_EMAIL_RESET };
}

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import NotificationNav from "../layout/NotificationNav";

import ListNotification from "../components/Notifications/ListNotification";
import EditNotification from "../components/Notifications/EditNotification";
import AddNotification from "../components/Notifications/AddNotification";

import ListNotificationCategories from "../components/Notifications/ListNotificationCategories";
import AddNotificationCategory from "../components/Notifications/AddNotificationCategory";

const NotificationRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: "150px" }}>
      <NotificationNav />
      <Switch>
        <Route path={`${path}/list/:archive`} exact>
          <ListNotification />
        </Route>
        <Route path={`${path}/add`} exact>
          <AddNotification />
        </Route>
        <Route path={`${path}/:notificationId/edit`} exact>
          <EditNotification />
        </Route>
        <Route path={`${path}/categories/list`} exact>
          <ListNotificationCategories />
        </Route>
        <Route path={`${path}/categories/add`} exact>
          <AddNotificationCategory />
        </Route>
      </Switch>
    </Container>
  );
};

export default NotificationRoute;

import React, { Component } from "react";
import _ from "lodash";
import { Button } from "semantic-ui-react";
import PollOptionItem from "./PollOptionItem";
import { T } from "../Common/Helpers";

class PollOptionItemManager extends Component {
  onAddPollOption = () => {
    this.props.onAddPollOption(false);
  };

  renderPollOptions = (polloptions) =>
    _.map(polloptions, (polloption, index) => (
      <div key={polloption.key}>
        <PollOptionItem
          po_key={polloption.key}
          index={index}
          polloption={polloption}
          workspace={this.props.workspace}
          ws_id={this.props.ws_id}
          onUpdatePollOption={this.props.onUpdatePollOption}
          imageUpdate={this.props.imageUpdate}
          onRemove={this.props.onRemove}
          module_id={this.props.module_id}
          game_id={this.props.game_id}
        />
      </div>
    ));

  render() {
    return (
      <>
        {this.renderPollOptions(this.props.polloptions)}
        <Button
          icon="plus"
          onClick={this.onAddPollOption.bind(this)}
          content={T("Add another option")}
          color="green"
        />
      </>
    );
  }
}

export default PollOptionItemManager;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchApplicationTemplates,
  resetFetchApplicationTemplates,
  modifyConfig,
} from "../../Redux/actions";
import WizardTemplate from "./WizardTemplate";
import { Spinner } from "../Common/Spinner";

class WizardTemplateContainer extends Component {
  componentDidMount() {
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem("x-auth-key");
    const CONTEXT_ID = 102;
    this.props.fetchApplicationTemplates(CONTEXT_ID, authKey, ws_id);
  }

  render() {
    // console.log('Wizard Templates props: ', this.props);
    const { templates } = this.props;
    if (this.props.fetchAll !== "success") {
      return <Spinner />;
    }
    return <WizardTemplate {...this.props} templates={templates} />;
  }
}

WizardTemplateContainer.propTypes = {
  apTemplate: PropTypes.string,
  onComponentValueUpdate: PropTypes.func,
};

function mapStateToProps({ applicationTemplate, workspace }) {
  return {
    fetchAll: applicationTemplate.operationState.fetchAll,
    templates: applicationTemplate.templates,
    workspace,
  };
}
export default connect(mapStateToProps, {
  fetchApplicationTemplates,
  resetFetchApplicationTemplates,
  modifyConfig,
})(WizardTemplateContainer);

import React, { Component } from 'react';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';
import PrizeItem from './PrizeItem';
import { T } from '../Common/Helpers';

class PrizeItemManager extends Component {
  onAddPrize = e => {
    e.preventDefault();
    this.props.onAddPrize(false);
  };

  renderPrizes = (prizes, isEditable) =>
    _.map(prizes, (prize, index) => (
      <PrizeItem
        isEditable={isEditable}
        key={prize.key}
        pi_key={prize.key}
        index={index}
        prize={prize}
        workspace={this.props.workspace}
        ws_id={this.props.ws_id}
        onUpdatePrize={this.props.onUpdatePrize}
        imageUpdate={this.props.imageUpdate}
        onRemove={this.props.onRemove}
        module_id={this.props.module_id}
      />
    ));

  render() {
    const { isEditable } = this.props;
    return (
      <>
        {this.renderPrizes(this.props.prizes, isEditable)}
        {isEditable && (
          <Button
            secondary
            icon="plus"
            onClick={this.onAddPrize.bind(this)}
            content={T('Add Another Prize')}
          />
        )}
      </>
    );
  }
}

export default PrizeItemManager;

import React, { Component } from 'react';
import { Header, Accordion, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { fetchProduct } from '../../Redux/actions';
import ProductAttributesContainer from './ProductAttributesContainer';
import { T } from '../Common/Helpers';

class SubProductAttributesController extends Component {
  state = { activeIndex: -1 };
  componentDidUpdate(prevProps) {
    if (
      prevProps.attributeStatus !== 'success' &&
      this.props.attributeStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Product attributes updated successfully'),
      });
      const authKey = localStorage.getItem('x-auth-key');
      const { productId, ws_id } = this.props;
      this.props.fetchProduct({ id: productId, authKey, ws_id });
    } else if (
      prevProps.attributeStatus !== 'error' &&
      this.props.attributeStatus === 'error'
    ) {
      toast({
        type: 'error',
        title: T('Error'),
        description: T('Error has occurred during product attribute update'),
      });
    }
  }
  handleClick = i => {
    const index = i;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  render() {
    const { activeIndex } = this.state;
    return (
      <div>
        <Header> {T('Product Attributes ')} </Header>
        <Accordion>
          {_.map(this.props.attList, (att, i) => (
            <React.Fragment key={`prod-att-container-${i}`}>
              <Accordion.Title
                active={activeIndex === i}
                index={i}
                onClick={() => this.handleClick(i)}
                key={`title-${i}`}
              >
                <Icon name="dropdown" />
                {att.pr_name}
              </Accordion.Title>
              <Accordion.Content
                key={`content-${i}`}
                active={activeIndex === i}
              >
                <ProductAttributesContainer
                  key={`attribute-${i}`}
                  productId={att.productId}
                  productName={att.pr_name}
                  data={att.data}
                />
              </Accordion.Content>
            </React.Fragment>
          ))}
        </Accordion>
      </div>
    );
  }
}

function mapStateToProps({ product, workspace }) {
  return {
    merchantId: workspace.workspaces[workspace.ws_id].cnc_company,
    attributeStatus: product.attributeStatus,
    ws_id: workspace.ws_id,
  };
}
export default connect(mapStateToProps, { fetchProduct })(
  SubProductAttributesController
);

/* eslint-env browser */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  checkAuthKey,
  authReset,
  fetchTranslations,
  fetchCountries,
  fetchLanguages,
  fetchWorkspaces,
  fetchWorkspaceModules,
  setActiveWorkspace,
  fetchCmsContentTypes,
  setLastActiveEventId,
} from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';

class Protected extends React.Component {
  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');

    // check auth key upon launch
    if (authKey) {
      this.props.checkAuthKey(authKey);
    } else {
      // go to login page if auth key not set at all
      this.props.history.push('/auth/login');
    }
  }

  componentDidUpdate(prevProps) {
    const authKey = localStorage.getItem('x-auth-key');

    if (
      (prevProps.auth.status !== 'error' &&
        this.props.auth.status === 'error') ||
      (prevProps.auth.status !== 'timeout' &&
        this.props.auth.status === 'timeout')
    ) {
      localStorage.clear();
      this.props.history.push('/auth/login');
    }

    if (
      prevProps.auth.status !== 'success' &&
      this.props.auth.status === 'success'
    ) {
      // fetch workspaces upon successful auth check, otherwise go to login page
      if (this.props.loggedIn) {
        this.props.fetchWorkspaces(authKey);
      } else {
        localStorage.clear();
        this.props.history.push('/auth/login');
      }
    }

    // when auth reset, go to login page
    if (prevProps.auth.status !== 'init' && this.props.auth.status === 'init') {
      localStorage.clear();
      this.props.history.push('/auth/login');
    } else if (this.props.loggedIn) {
      // if inital workspace refresh, or currently selected workspace different from
      // what's in redux store (also if no previously selected workspace), reload data and reset stuff
      if (
        this.props.loggedIn &&
        ((prevProps.fetchAll !== 'success' &&
          this.props.fetchAll === 'success') ||
          prevProps.workspace.ws_id !== this.props.workspace.ws_id)
      ) {
        const { ws_id, activeWorkspace } = this.props.workspace;
        this.props.setLastActiveEventId(null);
        this.props.fetchCountries(authKey, ws_id);
        this.props.fetchLanguages(authKey, ws_id);
        this.props.fetchTranslations(
          activeWorkspace.contextId,
          this.props.auth.lang
        );
        this.props.fetchWorkspaceModules({ authKey, id: ws_id });
        const category = 'application'; //Default content category type, should be modified in future
        this.props.fetchCmsContentTypes(authKey, category, ws_id);

        // push to dashboard upon workspace change (= there was a previously
        // selected workspace)
        if (prevProps.workspace.ws_id) {
          this.props.history.push('/app/dashboard');
        }
      }
    }
  }

  render() {
    const {
      component: Component,
      status,
      fetchAll,
      langStatus,
      ctypeStatus,
      translationStatus,
    } = this.props;

    // wait until everything's loaded
    if (
      status !== 'success' ||
      ctypeStatus !== 'success' ||
      fetchAll !== 'success' ||
      langStatus !== 'success' ||
      translationStatus !== 'success'
    ) {
      return <Spinner />;
    }

    // load the protected component (usually App.js which loads the routes
    // and the navigation
    return <Component {...this.props} />;
  }
}

const stateToProps = ({
  auth,
  languages,
  translations,
  workspace,
  cmsContent,
}) => ({
  auth,
  langStatus: languages.status,
  status: auth.status,
  loggedIn: auth.loggedIn,
  translations,
  translationStatus: translations.status,
  workspace,
  ws_id: workspace.ws_id,
  fetchAll: workspace.operationState.fetchAll,
  ctypeStatus: cmsContent.ctypeStatus,
});

export default connect(stateToProps, {
  checkAuthKey,
  authReset,
  fetchTranslations,
  fetchCountries,
  fetchLanguages,
  fetchWorkspaces,
  fetchWorkspaceModules,
  setActiveWorkspace,
  fetchCmsContentTypes,
  setLastActiveEventId,
})(withRouter(Protected));

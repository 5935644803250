import React, { Component } from 'react';
import {
  Message,
  Table,
  Icon,
  Pagination,
  Button,
  Menu,
  Dropdown,
} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/fi';
import { connect } from 'react-redux';
import { fetchSalesReport, downloadSalesReport } from '../../../Redux/actions';
import { T, convertPriceWithSymbol } from '../../Common/Helpers';
import { Spinner } from '../../Common/Spinner';

class SalesGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByColumn: this.props.headers.sort.split(':')[0],
      direction: this.getSortDirection(this.props.headers.sort.split(':')[1]),
      lang: this.props.primaryLanguage.ln_alpha_2,
    };
  }

  getSortDirection = dirn => (dirn === 'asc' ? 'ascending' : 'descending');

  getLanguageList = () =>
    _.map(this.props.languages, language => ({
      text: T(language.ln_name),
      value: language.ln_alpha_2,
    }));

  handlePaginationChange = (e, { activePage }) => {
    const ws_id = this.props.ws_id;
    const authKey = localStorage.getItem('x-auth-key');
    const { headers } = this.props;
    const { companyId, all, sl_price_list, reportRange, sl_status } =
      this.props.searchParams;
    this.props.fetchSalesReport({
      authKey,
      start: activePage,
      companyId,
      range: reportRange,
      all,
      sl_price_list,
      searchterm: headers.searchterm,
      sort: headers.sort,
      ws_id,
      status: sl_status,
    });
  };

  handleSortingReport = (columnName, dirn) => {
    const sortByColumn = columnName;
    let direction = 'ascending';
    if (this.state.sortByColumn === columnName) {
      direction = dirn === 'ascending' ? 'descending' : 'ascending';
    }
    const formatedDirn = direction === 'ascending' ? 'asc' : 'desc';
    const sort = `${columnName}:${formatedDirn}`;
    const { headers } = this.props;
    const { ws_id, companyId, all, sl_price_list, reportRange, sl_status } =
      this.props.searchParams;
    const start = 1; // always sort starts from first page
    const authKey = localStorage.getItem('x-auth-key');
    this.props.fetchSalesReport({
      authKey,
      start,
      companyId,
      range: reportRange,
      all,
      sl_price_list,
      searchterm: headers.searchterm,
      sort,
      ws_id,
      status: sl_status,
    });
    this.setState({ sortByColumn, direction });
  };

  downloadReport = format => {
    const {
      ws_id,
      companyId,
      all,
      sl_price_list,
      sort,
      reportRange,
      searchterm,
      sl_status,
    } = this.props.searchParams;
    const { lang } = this.state;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.downloadSalesReport({
      authKey,
      companyId,
      lang,
      fileFormat: format,
      range: reportRange,
      all,
      sl_price_list,
      searchterm,
      sort,
      ws_id,
      status: sl_status,
    });
  };

  renderGrid = (sales, status) =>
    _.map(sales, (sale, i) => (
      <Table.Row key={`row-${i}`}>
        <Table.Cell key={`id-${i}`}> {sale.sales_id} </Table.Cell>
        <Table.Cell key={`status-${i}`}>
          {sale.sl_status ? status[sale.sl_status] : ''}
        </Table.Cell>
        <Table.Cell key={`validated-${i}`}>
          {sale.validated_tickets ? sale.validated_tickets : ''}
        </Table.Cell>
        <Table.Cell key={`timestamp-${i}`}>
          {sale.sl_order_date &&
            moment(sale.sl_order_date).format('l HH:mm:ss')}
        </Table.Cell>
        <Table.Cell key={`name-${i}`}>
          {sale.sl_user_lastname || sale.sl_user_firstname
            ? `${sale.sl_user_lastname} ${sale.sl_user_firstname}`
            : ''}
        </Table.Cell>
        <Table.Cell key={`ex-vat-${i}`}>
          {convertPriceWithSymbol(
            sale.sl_currency,
            sale.sl_total_price_without_tax
          )}
        </Table.Cell>
        <Table.Cell key={`inc-tax-${i}`}>
          {convertPriceWithSymbol(sale.sl_currency, sale.sl_total_price)}
        </Table.Cell>
        <Table.Cell key={`pyment-commi-${i}`}>
          {convertPriceWithSymbol(
            sale.sl_currency,
            sale.sl_payment_processor_comission || 0
          )}
        </Table.Cell>
        <Table.Cell key={`engage-commi-${i}`}>
          {convertPriceWithSymbol(
            sale.sl_currency,
            sale.sl_engage_comission || 0
          )}
        </Table.Cell>
      </Table.Row>
    ));

  render() {
    const { sales } = this.props.summary;
    const headers = this.props.headers;
    if (this.props.status === 'waiting') {
      return <Spinner />;
    } else if (this.props.status === 'error') {
      return (
        <Message error>
          <Message.Title> {T('Error')} </Message.Title>
          <Message.Content>
            {T('Error occured while fetching sales report')}
          </Message.Content>
        </Message>
      );
    }
    const { sortByColumn, direction } = this.state;
    const statusText = {
      1: T('Open'),
      2: T('Paid'),
      3: T('Picked up'),
      5: T('Delivered'),
      9: T('Canceled'),
    };

    return (
      <>
        <Menu text size="small">
          <Menu.Menu>
            <Menu.Item header>{T('Sales Report')}</Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right">
            <Dropdown
              required
              search
              item
              name="lang"
              label={T('Language')}
              value={this.state.lang}
              options={this.getLanguageList()}
              onChange={(e, { name, value }) =>
                this.setState({ [name]: value })
              }
            />
            <Menu.Item>
              <Button.Group>
                <Button
                  icon="file pdf"
                  label={T('PDF')}
                  onClick={() => this.downloadReport('pdf')}
                />
                <Button
                  icon="file excel"
                  label={T('CSV')}
                  onClick={() => this.downloadReport('csv')}
                />
                <Button
                  icon="file excel"
                  label={T('XLS')}
                  onClick={() => this.downloadReport('xls')}
                />
              </Button.Group>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Table sortable celled textAlign="left" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={sortByColumn === 'sales_id' ? direction : null}
                onClick={() => this.handleSortingReport('sales_id', direction)}
              >
                {T('Id')}
              </Table.HeaderCell>
              <Table.HeaderCell> {T('Status')} </Table.HeaderCell>
              <Table.HeaderCell> {T('Validated Tickets')} </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortByColumn === 'sl_order_date' ? direction : null}
                onClick={() =>
                  this.handleSortingReport('sl_order_date', direction)
                }
              >
                {T('Timestamp')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortByColumn === 'sl_user_lastname' ? direction : null}
                onClick={() =>
                  this.handleSortingReport('sl_user_lastname', direction)
                }
              >
                {T('Name')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  sortByColumn === 'sl_total_price_without_tax'
                    ? direction
                    : null
                }
                onClick={() =>
                  this.handleSortingReport(
                    'sl_total_price_without_tax',
                    direction
                  )
                }
              >
                {T('Ex. VAT')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortByColumn === 'sl_total_price' ? direction : null}
                onClick={() =>
                  this.handleSortingReport('sl_total_price', direction)
                }
              >
                {T('Inc. VAT')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {T('Payment Processor Commission')}
              </Table.HeaderCell>
              <Table.HeaderCell> {T('Engage Commission')} </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderGrid(sales, statusText)}</Table.Body>
          {headers && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="9" textAlign="right">
                  <Pagination
                    onPageChange={this.handlePaginationChange}
                    defaultActivePage={headers ? headers.current_page : 1}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={headers ? headers.total_pages : 1}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      </>
    );
  }
}
function mapStateToProps({ workspace, sales, languages }) {
  return {
    ws_id: workspace.ws_id,
    summary: sales.summary.sales,
    status: sales.status.sales,
    headers: sales.headers.sales,
    primaryLanguage: languages.primaryLanguage,
  };
}
export default connect(mapStateToProps, {
  fetchSalesReport,
  downloadSalesReport,
})(SalesGrid);

import axios from 'axios';
import config from '../../../Config/AEConfig';
import {
  CREATE_EVENT_WAITING,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_ERROR,
  CREATE_SERIES_WAITING,
  CREATE_SERIES_SUCCESS,
  CREATE_SERIES_ERROR,
  CREATE_SEASON_WAITING,
  CREATE_SEASON_SUCCESS,
  CREATE_SEASON_ERROR,
  EDIT_EVENT_WAITING,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_ERROR,
  EDIT_SERIES_WAITING,
  EDIT_SERIES_SUCCESS,
  EDIT_SERIES_ERROR,
  EDIT_SEASON_WAITING,
  EDIT_SEASON_SUCCESS,
  EDIT_SEASON_ERROR,
  DELETE_EVENT_WAITING,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  DELETE_SERIES_WAITING,
  DELETE_SERIES_SUCCESS,
  DELETE_SERIES_ERROR,
  DELETE_SEASON_WAITING,
  DELETE_SEASON_SUCCESS,
  DELETE_SEASON_ERROR,
} from '../../Types';

const MODULE_ID = 3;

export const createEventByEventType =
  (values, authKey, wsId, eventType = 'match') =>
  dispatch => {
    let waitingActionType = CREATE_EVENT_WAITING;
    let successActionType = CREATE_EVENT_SUCCESS;
    let errorActionType = CREATE_EVENT_ERROR;
    if (eventType === 'series') {
      waitingActionType = CREATE_SERIES_WAITING;
      successActionType = CREATE_SERIES_SUCCESS;
      errorActionType = CREATE_SERIES_ERROR;
    } else if (eventType === 'season') {
      waitingActionType = CREATE_SEASON_WAITING;
      successActionType = CREATE_SEASON_SUCCESS;
      errorActionType = CREATE_SEASON_ERROR;
    }
    dispatch({ type: waitingActionType });
    const requiredDefaultValues = {
      ev_event_start: null,
      ev_event_stop: null,
      ev_name: null,
      ev_public: false,
    };
    const inputValues = { ...requiredDefaultValues, ...values };
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .post(
        `${config.BASE_URL}/event?module_id=${MODULE_ID}&ws_id=${wsId}`,
        inputValues
      )
      .then(response => {
        // console.log('Create Event By Type success: ', successActionType, '\n Payload: ', response);
        dispatch({ type: successActionType, payload: response });
      })
      .catch(error => dispatch({ type: errorActionType, payload: error }));
  };

export function editEventByType(id, authKey, wsId, values, eventType) {
  let waitingActionType = EDIT_EVENT_WAITING;
  let successActionType = EDIT_EVENT_SUCCESS;
  let errorActionType = EDIT_EVENT_ERROR;
  if (eventType === 'series') {
    waitingActionType = EDIT_SERIES_WAITING;
    successActionType = EDIT_SERIES_SUCCESS;
    errorActionType = EDIT_SERIES_ERROR;
  } else if (eventType === 'season') {
    waitingActionType = EDIT_SEASON_WAITING;
    successActionType = EDIT_SEASON_SUCCESS;
    errorActionType = EDIT_SEASON_ERROR;
  }
  return dispatch => {
    dispatch({ type: waitingActionType });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .put(
        `${config.BASE_URL}/event/${id}?module_id=${MODULE_ID}&ws_id=${wsId}`,
        values
      )
      .then(response =>
        dispatch({
          type: successActionType,
          payload: response,
        })
      )
      .catch(error => dispatch({ type: errorActionType, payload: error }));
  };
}

export function deleteEventByType(
  id,
  authKey,
  eventType,
  parentId = null,
  ws_id
) {
  let waitingActionType = DELETE_EVENT_WAITING;
  let successActionType = DELETE_EVENT_SUCCESS;
  let errorActionType = DELETE_EVENT_ERROR;
  if (eventType === 'series') {
    waitingActionType = DELETE_SERIES_WAITING;
    successActionType = DELETE_SERIES_SUCCESS;
    errorActionType = DELETE_SERIES_ERROR;
  } else if (eventType === 'season') {
    waitingActionType = DELETE_SEASON_WAITING;
    successActionType = DELETE_SEASON_SUCCESS;
    errorActionType = DELETE_SEASON_ERROR;
  }

  return dispatch => {
    dispatch({ type: waitingActionType });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .delete(`${config.BASE_URL}/event/${id}?module_id=3&ws_id=${ws_id}`)
      .then(response =>
        dispatch({
          type: successActionType,
          payload: { ...response, parentId },
        })
      )
      .catch(error => dispatch({ type: errorActionType, payload: error }));
  };
}

import {
  FETCH_LOTTERY_PRIZES_SUCCESS,
  FETCH_LOTTERY_PRIZES_WAITING,
  FETCH_LOTTERY_PRIZES_ERROR,
  FETCH_LOTTERY_PRIZES_RESET,
  FETCH_LOTTERY_PRIZE_SUCCESS,
  FETCH_LOTTERY_PRIZE_WAITING,
  FETCH_LOTTERY_PRIZE_ERROR,
  FETCH_LOTTERY_PRIZE_RESET,
  ADD_LOTTERY_PRIZE_SUCCESS,
  ADD_LOTTERY_PRIZE_WAITING,
  ADD_LOTTERY_PRIZE_ERROR,
  ADD_LOTTERY_PRIZE_RESET,
  EDIT_LOTTERY_PRIZE_SUCCESS,
  EDIT_LOTTERY_PRIZE_WAITING,
  EDIT_LOTTERY_PRIZE_ERROR,
  EDIT_LOTTERY_PRIZE_RESET,
  DELETE_LOTTERY_PRIZE_SUCCESS,
  DELETE_LOTTERY_PRIZE_WAITING,
  DELETE_LOTTERY_PRIZE_ERROR,
  DELETE_LOTTERY_PRIZE_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  status: {
    fetchAll: 'init',
    fetch: 'init',
    edit: '',
    delete: '',
    add: '',
  },
};

const lotteryPrizeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_LOTTERY_PRIZES_SUCCESS: {
      const status = { ...state.status };
      status.fetchAll = 'success';
      return { ...state, status };
    }
    case FETCH_LOTTERY_PRIZES_WAITING: {
      const status = { ...state.status };
      status.fetchAll = 'waiting';
      return { ...state, status };
    }
    case FETCH_LOTTERY_PRIZES_ERROR: {
      const status = { ...state.status };
      status.fetchAll = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case FETCH_LOTTERY_PRIZES_RESET: {
      const status = { ...state.status };
      status.fetchAll = 'init';
      return { ...state, status, error: undefined };
    }

    case FETCH_LOTTERY_PRIZE_SUCCESS: {
      const status = { ...state.status };
      status.fetch = 'success';
      return { ...state, status };
    }
    case FETCH_LOTTERY_PRIZE_WAITING: {
      const status = { ...state.status };
      status.fetch = 'waiting';
      return { ...state, status };
    }
    case FETCH_LOTTERY_PRIZE_ERROR: {
      const status = { ...state.status };
      status.fetch = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case FETCH_LOTTERY_PRIZE_RESET: {
      const status = { ...state.status };
      status.fetch = 'init';
      return { ...state, status, error: undefined };
    }

    case ADD_LOTTERY_PRIZE_SUCCESS: {
      const status = { ...state.status };
      status.add = 'success';
      return { ...state, status };
    }
    case ADD_LOTTERY_PRIZE_WAITING: {
      const status = { ...state.status };
      status.add = 'waiting';
      return { ...state, status };
    }
    case ADD_LOTTERY_PRIZE_ERROR: {
      const status = { ...state.status };
      status.add = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case ADD_LOTTERY_PRIZE_RESET: {
      const status = { ...state.status };
      status.add = 'init';
      return { ...state, status, error: undefined };
    }

    case EDIT_LOTTERY_PRIZE_SUCCESS: {
      const status = { ...state.status };
      status.edit = 'success';
      return { ...state, status };
    }
    case EDIT_LOTTERY_PRIZE_WAITING: {
      const status = { ...state.status };
      status.edit = 'waiting';
      return { ...state, status };
    }
    case EDIT_LOTTERY_PRIZE_ERROR: {
      const status = { ...state.status };
      status.edit = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case EDIT_LOTTERY_PRIZE_RESET: {
      const status = { ...state.status };
      status.edit = 'init';
      return { ...state, status, error: undefined };
    }

    case DELETE_LOTTERY_PRIZE_SUCCESS: {
      const status = { ...state.status };
      status.delete = 'success';
      return { ...state, status };
    }
    case DELETE_LOTTERY_PRIZE_WAITING: {
      const status = { ...state.status };
      status.delete = 'waiting';
      return { ...state, status };
    }
    case DELETE_LOTTERY_PRIZE_ERROR: {
      const status = { ...state.status };
      status.delete = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case DELETE_LOTTERY_PRIZE_RESET: {
      const status = { ...state.status };
      status.delete = 'init';
      return { ...state, status, error: undefined };
    }
    default:
      return state;
  }
};

export default lotteryPrizeReducer;

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Message, Form, Icon, Container } from 'semantic-ui-react';
import { previewLottery } from '../../Redux/actions';
import { T, getErrorMessage, getLanguageObject } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';

//const DEFAULT_LANG = 15; // Finnish and can be change later according to user pref
const PRIMARY_LANGUAGE = 15; // Finnish and can be change later according to user pref

class LotteryPreview extends Component {
  constructor(props) {
    super(props);
    const primary_language_object = getLanguageObject(
      props.languages,
      props.auth.apps[0]
        ? props.auth.apps[0].ap_primary_language
        : PRIMARY_LANGUAGE
    );
    this.primary_language_id = primary_language_object.language_id;
    this.state = {
      lp_language: this.primary_language_id,
      lp_title_content: '',
      lp_message_content: '',
      contentList: [],
    };
  }

  componentDidMount() {
    const { lottery_id, ws_id } = this.props;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.previewLottery(authKey, lottery_id, ws_id);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.lotteryPreview.status !== 'success' &&
      this.props.lotteryPreview.status === 'success'
    ) {
      this.initState(
        this.props.lotteryPreview.data.preview,
        this.props.lotteryPreview.data.application_configs
      );
    }
  }

  onChange(e, { value }) {
    const { contentList } = this.state;
    let { lp_title_content, lp_message_content } = this.state;
    const index = _.findIndex(contentList, item => item.lo_language === value);
    if (index !== -1) {
      lp_title_content = contentList[index].lo_title_app;
      lp_message_content = contentList[index].lo_message_app;
    } else {
      lp_title_content = '';
      lp_message_content = '';
    }
    this.setState({
      lp_language: value,
      lp_title_content,
      lp_message_content,
    });
  }

  getLanguageList = () =>
    _.map(this.props.languages, language => ({
      text: T(language.ln_name),
      value: language.language_id,
    }));

  initState(data, application_configs) {
    // console.log('initState ', data);
    const dataObject = data.find(
      tmp => tmp.lo_language === this.state.lp_language
    );
    // console.log('dataObject ', dataObject);
    if (data && dataObject !== undefined) {
      this.setState({
        lp_language: dataObject.lo_language,
        lp_title_content: dataObject.lo_title_app,
        lp_message_content: dataObject.lo_message_app,
        contentList: data,
        backgroundColor: application_configs[0].ac_value,
        htmlHeader: application_configs[1].ac_value,
      });
    }
  }

  render() {
    if (this.props.lotteryPreview.status === 'error') {
      const errorObj = getErrorMessage(this.props.lotteryPreview);
      return (
        <Message
          negative
          icon="exclamation triangle"
          header={T('Error')}
          content={`${T('Status')}: ${errorObj.status}, ${T('Message')}: ${T(
            errorObj.message
          )}`}
        />
      );
    }

    if (this.props.lotteryPreview.status !== 'success') {
      return <Spinner />;
    }

    return (
      <Form>
        <Form.Field>
          <Form.Select
            required
            search
            name="lp_language"
            label={T('Language')}
            value={this.state.lp_language}
            options={this.getLanguageList()}
            onChange={this.onChange.bind(this)}
          />
        </Form.Field>
        <Container textAlign="center">
          <Icon disabled name="close" style={style.closeBtn} />
          <Card
            style={{
              ...style.cardStyle,
              backgroundColor: this.state.backgroundColor,
              htmlHeader: this.state.htmlHeader,
            }}
          >
            <Form.Field>
              {this.state.lp_title_content ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.lp_title_content,
                  }}
                  style={{ marginTop: 10 }}
                />
              ) : null}
            </Form.Field>
            <Form.Field>
              {this.state.lp_title_content ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.lp_message_content,
                  }}
                />
              ) : null}
            </Form.Field>
          </Card>
        </Container>
      </Form>
    );
  }
}

const style = {
  cardStyle: {
    width: 320,
    minHeight: 480,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#efefef',
    htmlHeader: '',
    borderRadius: 16,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 5,
    shadowOpacity: 0.3,
  },
  closeBtn: {
    align: 'right',
  },
};

function mapStateToProps({ auth, lotteryPreview, languages }) {
  return {
    auth,
    lotteryPreview,
    languages: languages.languages,
  };
}

export default connect(mapStateToProps, { previewLottery })(LotteryPreview);

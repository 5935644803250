import { SET_LAST_ACTIVE_EVENT_ID, AUTH_RESET } from '../Types';

const INIT_STATE = { lastActiveEventId: null };
export default function setLastActiveEventId(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case SET_LAST_ACTIVE_EVENT_ID:
      return { ...state, lastActiveEventId: action.payload };
    default:
      return state;
  }
}

import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  FETCH_EVENT_CONTENT_TYPE_WAITING,
  FETCH_EVENT_CONTENT_TYPE_SUCCESS,
  FETCH_EVENT_CONTENT_TYPE_ERROR,
  FETCH_EVENT_CONTENT_TYPE_RESET,
  FETCH_KEY_VALUES_WAITING,
  FETCH_KEY_VALUES_SUCCESS,
  FETCH_KEY_VALUES_ERROR,
} from '../Types';
import ErrorAction from './ErrorAction';

export function fetchEventContentTypes(authKey) {
  // console.log("fetchEventContentTypes");
  return dispatch => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_EVENT_CONTENT_TYPE_WAITING });
    return axios
      .get(`${config.BASE_URL}/config/event`)
      .then(response =>
        dispatch({ type: FETCH_EVENT_CONTENT_TYPE_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_EVENT_CONTENT_TYPE_ERROR)
          : FETCH_EVENT_CONTENT_TYPE_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

//TODO contenttypes as an parameter
export function fetchCCDataKeyValues(authKey) {
  return dispatch => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_KEY_VALUES_WAITING });
    return axios
      .get(`${config.BASE_URL}/config/contenttypes`)
      .then(response =>
        dispatch({ type: FETCH_KEY_VALUES_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_KEY_VALUES_ERROR)
          : FETCH_KEY_VALUES_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetFetchEventContentTypes() {
  return { type: FETCH_EVENT_CONTENT_TYPE_RESET };
}

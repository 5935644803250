export const FETCH_VENUES_WAITING = "fetch-Venues-waiting";
export const FETCH_VENUES_SUCCESS = "fetch-Venues-success";
export const FETCH_VENUES_ERROR = "fetch-Venues-error";
export const FETCH_VENUES_RESET = "fetch-Venues-reset";

export const FETCH_SUB_VENUES_WAITING = "fetch-sub-venues-waiting";
export const FETCH_SUB_VENUES_SUCCESS = "fetch-sub-venues-success";
export const FETCH_SUB_VENUES_ERROR = "fetch-sub-venues-error";
export const FETCH_SUB_VENUES_RESET = "fetch-sub-venues-reset";

export const FETCH_VENUE_WAITING = "fetch-venue-waiting";
export const FETCH_VENUE_SUCCESS = "fetch-venue-success";
export const FETCH_VENUE_ERROR = "fetch-venue-error";
export const FETCH_VENUE_RESET = "fetch-venue-reset";

export const EDIT_VENUE_WAITING = "edit-venue-waiting";
export const EDIT_VENUE_SUCCESS = "edit-venue-success";
export const EDIT_VENUE_ERROR = "edit-venue-error";
export const EDIT_VENUE_RESET = "edit-venue-reset";

export const ADD_VENUE_WAITING = "add-venue-waiting";
export const ADD_VENUE_SUCCESS = "add-venue-success";
export const ADD_VENUE_ERROR = "add-venue-error";
export const ADD_VENUE_RESET = "add-venue-reset";

export const DELETE_VENUE_WAITING = "delete-venue-waiting";
export const DELETE_VENUE_SUCCESS = "delete-venue-success";
export const DELETE_VENUE_ERROR = "delete-venue-error";
export const DELETE_VENUE_RESET = "delete-venue-reset";

export const FETCH_SUB_VENUE_WAITING = "fetch-sub-venue-waiting";
export const FETCH_SUB_VENUE_SUCCESS = "fetch-sub-venue-success";
export const FETCH_SUB_VENUE_ERROR = "fetch-sub-venue-error";
export const FETCH_SUB_VENUE_RESET = "fetch-sub-venue-reset";

export const EDIT_SUB_VENUE_WAITING = "edit-sub-venue-waiting";
export const EDIT_SUB_VENUE_SUCCESS = "edit-sub-venue-success";
export const EDIT_SUB_VENUE_ERROR = "edit-sub-venue-error";
export const EDIT_SUB_VENUE_RESET = "edit-sub-venue-reset";

export const ADD_SUB_VENUE_WAITING = "add-sub-venue-waiting";
export const ADD_SUB_VENUE_SUCCESS = "add-sub-venue-success";
export const ADD_SUB_VENUE_ERROR = "add-sub-venue-error";
export const ADD_SUB_VENUE_RESET = "add-sub-venue-reset";

export const DELETE_SUB_VENUE_WAITING = "delete-sub-venue-waiting";
export const DELETE_SUB_VENUE_SUCCESS = "delete-sub-venue-success";
export const DELETE_SUB_VENUE_ERROR = "delete-sub-venue-error";
export const DELETE_SUB_VENUE_RESET = "delete-sub-venue-reset";

export const FETCH_PERSONS_WAITING = "fetch_persons_waiting";
export const FETCH_PERSONS_SUCCESS = "fetch_persons_success";
export const FETCH_PERSONS_ERROR = "fetch_persons_error";
export const FETCH_PERSONS_RESET = "fetch_persons_reset";

export const FETCH_PERSON_WAITING = "fetch_person_waiting";
export const FETCH_PERSON_SUCCESS = "fetch_person_success";
export const FETCH_PERSON_ERROR = "fetch_person_error";
export const FETCH_PERSON_RESET = "fetch_person_reset";

export const CREATE_PERSON_WAITING = "create_person_waiting";
export const CREATE_PERSON_SUCCESS = "create_person_success";
export const CREATE_PERSON_ERROR = "create_person_error";
export const CREATE_PERSON_RESET = "create_person_reset";

export const EDIT_PERSON_WAITING = "edit_person_waiting";
export const EDIT_PERSON_SUCCESS = "edit_person_success";
export const EDIT_PERSON_ERROR = "edit_person_error";
export const EDIT_PERSON_RESET = "edit_person_reset";

export const DELETE_PERSON_WAITING = "delete_person_waiting";
export const DELETE_PERSON_SUCCESS = "delete_person_success";
export const DELETE_PERSON_ERROR = "delete_person_error";
export const DELETE_PERSON_RESET = "delete_person_reset";

export const CONFIRM_PERSON_REGISTRATION_WAITING =
  "confirm_person_registration_waiting";
export const CONFIRM_PERSON_REGISTRATION_SUCCESS =
  "confirm_person_registration_success";
export const CONFIRM_PERSON_REGISTRATION_ERROR =
  "confirm_person_registration_error";
export const CONFIRM_PERSON_REGISTRATION_RESET =
  "confirm_person_registration_reset";

export const PERSON_CONFIRM_EMAIL_WAITING = "PERSON_CONFIRM_EMAIL_WAITING";
export const PERSON_CONFIRM_EMAIL_SUCCESS = "PERSON_CONFIRM_EMAIL_SUCCESS";
export const PERSON_CONFIRM_EMAIL_ERROR = "PERSON_CONFIRM_EMAIL_ERROR";
export const PERSON_CONFIRM_EMAIL_RESET = "PERSON_CONFIRM_EMAIL_RESET";

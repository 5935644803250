/* eslint-env browser */
import React, { Component } from 'react';
import { Form } from 'formsy-semantic-ui-react';
import {
  Form as semanticForm,
  Confirm,
  Grid,
  Button,
  Segment,
  Label,
  // Input,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-semantic-toasts';
import { T, getErrorMessage } from '../Common/Helpers';
import ImageCrop from '../PerformerPlayers/ImageCrop';
import {
  fetchPerformer,
  deletePerformer,
  editPerformer,
  // fetchCountries,
  fetchVenues,
  resetPerformerUpdate,
  resetPerformerDelete,
  uploadPerformerTeamImage,
  resetUploadPerformerTeamImage,
  resetFetchPerformers,
  resetPerformerEventActions,
  deletePerformerTeamImage,
  resetdeletePerformerMetadata,
  deletePerformerPlayerImage,
  fetchPerformerPlayer,
  // resetDeletePerformerTeamImage,
  fetchPerformerPlayersList,
  // resetDeletePerformerTeamImage
} from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import PerformerTeamSeriesList from './PerformerTeamSeriesList';
import PlaceHolderImage from '../../layout/images/images.png';
// import { T } from './Common/Helpers';
const errorStyle = {
  backgroundColor: '#FFF6F6',
  borderColor: '#E0B4B4',
  color: '#9F3A38',
  WebkitBoxShadow: 'none',
  boxShadow: 'none',
};

class TeamDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: 'init',
      selectedLocation: 'init',
      countryError: {},
      locationError: {},
      openDeleteConfirmation: false,
    };
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeHomePlace = this.onChangeHomePlace.bind(this);
    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.deletePerformerImage = this.deletePerformerImage.bind(this);
  }

  componentDidMount() {
    // const performerId = this.props.performerId || this.props.match.params.performer_id;
    // localStorage.setItem('route', this.props.history.location.pathname);
    // const authKey = localStorage.getItem('x-auth-key');
    // if (!this.props.performer) {
    //   this.props.fetchPerformer(authKey, performerId);
    // } else
    const authKey = localStorage.getItem('x-auth-key');
    const paging = false;
    const startPage = 1; //no paging
    const searchterm = null;
    const sortField = 'pl_name';
    const ws_id = this.props.workspace.ws_id;
    const asc = true;
    // this.props.fetchCountries(authKey, ws_id);

    if (!this.props.venues.fetchAll !== 'success') {
      this.props.fetchVenues(
        authKey,
        ws_id,
        paging,
        startPage,
        searchterm,
        sortField,
        asc
      );
    }
    if (!this.props.seasons) {
      this.props.fetchPerformer(authKey, this.props.performerId, ws_id);
    }
    if (this.props.performer) {
      const { selectedLocation, selectedCountry } = this.state;
      const { performer } = this.props;
      if (selectedCountry === 'init') {
        const country = performer.pf_ctry ? performer.pf_ctry : '';
        this.setState({ selectedCountry: country });
      }
      if (selectedLocation === 'init') {
        const location = performer.pf_home_place ? performer.pf_home_place : '';
        this.setState({ selectedLocation: location });
      }
    }
  }
  UNSAFE_componentWillUpdate(nextProps) {
    const performerId = this.props.performerId;
    const authKey = localStorage.getItem('x-auth-key');
    // if (!this.props.seasons) {
    //   this.props.fetchPerformer(authKey, nextProps.performerId);
    // }
    if (nextProps.seasonStatus === 'add success') {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Season added successfully'),
        time: 5000,
      });
      this.props.resetPerformerEventActions();
      // this.props.fetchPerformer(authKey, nextProps.performerId);
    } else if (nextProps.seasonStatus === 'delete success') {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Season deleted successfully.'),
        time: 5000,
      });
      this.props.resetPerformerEventActions();
      // this.props.fetchPerformer(authKey, nextProps.performerId);
    } else if (nextProps.seasonStatus === 'add error') {
      const errorObj = getErrorMessage(
        nextProps.error,
        'Error in adding a season'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetPerformerEventActions();
    } else if (nextProps.seasonStatus === 'delete error') {
      const errorObj = getErrorMessage(
        nextProps.error,
        'Error in deleting a season'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetPerformerEventActions();
    } else if (
      this.props.status !== 'success' &&
      nextProps.status === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Team updated successfully'),
        time: 5000,
      });
      this.props.resetPerformerUpdate();
      const ws_id = this.props.workspace.activeWorkspace.id;
      this.props.fetchPerformer(authKey, performerId, ws_id);
    } else if (
      this.props.deleteStatus !== 'success' &&
      nextProps.deleteStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Team deleted successfully'),
        time: 5000,
      });
      this.props.resetPerformerDelete();
      // this.props.fetchPerformer(authKey, performerId);
      this.props.history.push('/app/performers/list');
    } else if (
      this.props.deleteStatus !== 'error' &&
      nextProps.deleteStatus === 'error'
    ) {
      const errorObj = getErrorMessage(nextProps.error);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetPerformerDelete();
    } else if (nextProps.status === 'error') {
      const errorObj = getErrorMessage(nextProps.error);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetPerformerUpdate();
      // this.props.fetchPerformer(authKey, performerId);
    }
  }
  onValidSubmit(formData) {
    // console.log('States: ', this.state);
    let validDropDowns = true;
    if (Boolean(this.state.selectedCountry) === false) {
      this.setState({ countryError: errorStyle });
      validDropDowns = false;
    }
    if (!validDropDowns) return false;
    let inputValues = {
      ...formData,
      pf_type: 'team',
      pf_ctry: this.state.selectedCountry,
    };
    if (this.state.selectedLocation) {
      inputValues = {
        ...inputValues,
        pf_home_place: this.state.selectedLocation,
      };
    }
    const performerId = this.props.performerId;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.editPerformer(
      performerId,
      authKey,
      inputValues,
      this.props.workspace.ws_id
    );
  }
  onConfirmDelete() {
    const performerId = this.props.performerId;
    const authKey = localStorage.getItem('x-auth-key');
    this.setState({ openDeleteConfirmation: false });
    this.props.resetPerformerDelete();
    this.props.deletePerformer(
      performerId,
      authKey,
      this.props.workspace.ws_id
    );
    // console.log('delete confirmed');
  }
  onDeleteClick() {
    this.setState({ openDeleteConfirmation: true });
  }
  onCancel() {
    this.props.resetFetchPerformers();
    this.props.history.push('/app/performers/list');
  }
  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }
  onChangeCountry(event) {
    this.setState({ selectedCountry: event.target.value, countryError: {} });
  }
  onChangeHomePlace(event) {
    this.setState({ selectedLocation: event.target.value, locationError: {} });
  }
  getPlayerImage() {
    const metadata = this.props.performer.performer_metadata;
    let imageSrc = '';
    let metadataId = null;
    if (metadata && metadata.length > 0) {
      const pictMeta = metadata.filter(meta => meta.pm_key === 'imageurl');
      if (pictMeta[0] && pictMeta[0].length !== 0) {
        imageSrc = pictMeta[0].pm_value;
        metadataId = pictMeta[0].performer_metadata_id;
      }
    }
    return { url: imageSrc, metadataId };
  }
  deletePerformerImage(performerId) {
    const authKey = localStorage.getItem('x-auth-key');
    const imageUrl = this.getPlayerImage();

    this.props.deletePerformerTeamImage(
      performerId,
      imageUrl.url,
      authKey,
      this.props.workspace.ws_id
    );
  }

  renderOptionsLocationModified(opt, label) {
    const venues = {
      label: { place_id: '', pl_name: T('Select a Home Venue') },
      ...opt,
    };
    return _.map(venues, option => (
      <option key={option.place_id} value={option.place_id}>
        {option[label]}
      </option>
    ));
  }

  renderOptionsCountryModified(opt, label) {
    const countries = opt.slice();
    countries.unshift({ id: '', country_name: T('Select a Country') });
    return _.map(countries, option => (
      <option key={option.id} value={option.id}>
        {option[label]}
      </option>
    ));
  }

  render() {
    // console.log('TEAM DETAIL PROPS: ', this.props);
    // const errorLabel = <Label color="red" pointing />;
    const { performer } = this.props;
    const inputStyle = { minWidth: '250px' };
    if (!this.props.seasons || this.props.venues.fetchAll !== 'success') {
      return <Spinner />;
    }
    const imageObj = this.getPlayerImage();
    return (
      <Segment piled={false}>
        <Grid columns="3" stackable doubling>
          <Grid.Column
            // largeScreen="3"
            // tablet="6"
            // computer="6"
            // mobile="16"
            width="4"
            style={{ minWidth: '280px', maxWidth: '350px' }}
            textAlign="center"
            stretched
          >
            <Segment textAlign="center">
              {/* <Image style={{ margin: '20px auto' }}
            src={this.getPlayerImage()} size="small" /> */}
              <ImageCrop
                performerId={this.props.performerId}
                onResetEditAndAddPlayer={this.props.onResetEditAndAddPlayer}
                hideCancelButton
                metadataId={imageObj.metadataId}
                showDeleteButton={imageObj.metadataId}
                onDeleteImage={this.deletePerformerImage}
                uploadPerformerImage={this.props.uploadPerformerTeamImage}
                playerImg={imageObj.url || PlaceHolderImage}
                titleHeader={T('Add/Edit Team Logo')}
                centeredImage
                uploadStatus={this.props.uploadStatus}
                altText={T('Team Logo')}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column
            // mobile="16"
            // tablet="10"
            // computer="10"
            // largeScreen="9"
            style={{ minWidth: '370px', width: '80%' }}
            width="7"
            stretched
          >
            <Segment>
              <h3>{T('Edit Team')}</h3>
              <Form
                onValidSubmit={this.onValidSubmit.bind(this)}
                ref={ref => (this.form = ref)}
              >
                <Form.Group>
                  <Label content={`${T('ID')}: ${this.props.performerId}`} />
                </Form.Group>
                <Form.Group>
                  <Form.Input
                    required
                    value={performer.pf_name}
                    name="pf_name"
                    label={T('Name')}
                    width={6}
                    style={{ ...inputStyle }}
                    // validationErrors={{ isDefaultRequiredValue: nameRequired }}
                    // errorLabel={errorLabel}
                  />
                </Form.Group>
                <Form.Group>
                  <semanticForm.Field width="6" required>
                    <label name="country_label" htmlFor="pf_ctry">
                      {/* {this.state.countryError} */}
                      {T('Country')}
                    </label>
                    <select
                      required
                      onChange={this.onChangeCountry}
                      name="pf_ctry"
                      value={this.state.selectedCountry}
                      style={{
                        height: '40px',
                        marginBottom: '10px',
                        ...this.state.countryError,
                        ...inputStyle,
                      }}
                    >
                      {this.renderOptionsCountryModified(
                        this.props.countries.countries,
                        'country_name'
                      )}
                    </select>
                  </semanticForm.Field>
                </Form.Group>
                <Form.Group widths="16">
                  <semanticForm.Field width="6">
                    <label name="location_label" htmlFor="pf_home_place">
                      {/* {this.state.locationError} */}
                      {T('Home Venue')}
                    </label>
                    <select
                      onChange={this.onChangeHomePlace}
                      style={{
                        height: '40px',
                        marginBottom: '10px',
                        ...this.state.locationError,
                        ...inputStyle,
                      }}
                      name="pf_home_place"
                      value={this.state.selectedLocation}
                    >
                      {this.renderOptionsLocationModified(
                        this.props.venues.venues,
                        'pl_name'
                      )}
                    </select>
                  </semanticForm.Field>
                </Form.Group>
                <Form.Group widths="16">
                  {performer.pf_remote_id !== null ? (
                    <Form.Input
                      value={String(performer.pf_remote_id)}
                      disabled
                      name="pf_remote_id"
                      label={T('Remote Id')}
                      width={6}
                      style={{ ...inputStyle }}
                      type="text"
                      readOnly
                    />
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group widths="16">
                  <Form.Checkbox
                    toggle
                    name="pf_public"
                    label={T('Public')}
                    defaultChecked={performer.pf_public}
                  />
                  {/* {console.log('IS ACTIVE:', performer.pf_is_active, ' Public: ', performer.pf_public)} */}
                </Form.Group>
                <Button content={T('Save')} color="green" />
                <Button
                  type="button"
                  color="red"
                  content={T('Delete Team')}
                  onClick={this.onDeleteClick.bind(this)}
                />
                <Confirm
                  header={T('Delete Team')}
                  content={T('Are you sure you want to delete the team?')}
                  confirmButton={T('Delete')}
                  cancelButton={T('Cancel')}
                  open={this.state.openDeleteConfirmation}
                  onCancel={this.oncancelDeleteConfirmation}
                  onConfirm={this.onConfirmDelete}
                  size="tiny"
                />
                <Button
                  type="button"
                  color="black"
                  content={T('Cancel')}
                  onClick={() => this.onCancel()}
                />
              </Form>
            </Segment>
          </Grid.Column>
          <Grid.Column
            // mobile="10"
            // tablet="8"
            // computer="8"
            width="5"
            // largeScreen="4"
            style={{ minWidth: '250px', width: '80%' }}
            stretched
          >
            {/* <Segment> */}
            <PerformerTeamSeriesList
              performerId={this.props.performerId}
              season={this.props.seasons}
            />
            <Segment textAlign="left">
              <Button
                color="green"
                onClick={() => this.props.displayTeamTransferList()}
              >
                {T('Team Transfers')}
              </Button>
            </Segment>
            {/* </Segment> */}
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

function mapStateToProps({ performers, countries, venues, workspace }) {
  //ownProps
  return {
    // performer: performers[ownProps.performerId],
    seasons: performers.seasons,
    countries,
    venues,
    status: performers.updateStatus,
    deleteStatus: performers.deleteStatus,
    // uploadStatus: performerPlayers.uploadStatus,
    // headers: performerPlayers.headers,
    seasonStatus: performers.seasonStatus,
    workspace,
    error: performers.error,
    // metadataDelete: performerPlayers.metadataDelete,
    // deleteImageStatus: performerPlayers.deleteImageStatus,
  };
}

export default connect(mapStateToProps, {
  fetchPerformer,
  editPerformer,
  deletePerformer,
  resetPerformerDelete,
  // fetchCountries,
  fetchVenues,
  fetchPerformerPlayersList,
  resetPerformerUpdate,
  uploadPerformerTeamImage,
  resetUploadPerformerTeamImage,
  resetFetchPerformers,
  resetPerformerEventActions,
  deletePerformerTeamImage,
  resetdeletePerformerMetadata,
  deletePerformerPlayerImage,
  fetchPerformerPlayer,
  // resetDeletePerformerTeamImage
  // resetDeletePerformerTeamImage,
})(TeamDetailsPage);

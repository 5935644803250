import React, { Component } from 'react';
import { Form, Button, Header } from 'semantic-ui-react';
import _ from 'lodash';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import { T } from '../Common/Helpers';

class ShopConfig extends Component {
  constructor(props) {
    super(props);
    const objs = this.initializeConfig();
    this.state = { ...objs };
    // console.log('Shop Config Props: ', this.props);
  }
  initializeConfig = () => {
    const obj = {};
    _.map(
      this.props.configKeywords,
      key =>
        (obj[key.sc_keyword] = {
          ...key,
          sc_value: '',
          ...this.props.shopConfigs[key.sc_keyword],
        })
    );
    return obj;
  };
  handleChange = (name, value) => {
    const obj = this.state[name] || {};
    obj.sc_value = value;
    this.setState({ [name]: obj });
  };
  submitShopConfig = () => {
    const validConfigs = _.filter(
      this.state,
      key => key.sc_value.trim() !== ''
    );
    this.props.submitShopConfig(validConfigs);
  };

  renderShopConfig = keys => {
    const groupedKeys = _.reduce(
      keys,
      (acc, ele) => {
        const l = acc.length;
        if (l === 0) {
          acc.push([ele]);
        } else if (acc[l - 1].length !== 3) {
          // form goup will have 3 form fields
          const lastItem = acc.pop(); // work around to avoid linter complaining
          acc.push([...lastItem, ele]);
        } else {
          acc.push([ele]);
        }
        return acc;
      },
      []
    );
    return _.map(groupedKeys, (keywords, i) => (
      <Form.Group key={`form-group-${i}`}>
        {_.map(keywords, k => {
          const dataType = k.dk_data_type;
          let type = '';
          if (dataType === 'text' || dataType === 'string') {
            type = 'text';
          } else if (dataType === 'float' || dataType === 'int') {
            type = 'number';
          }
          if (
            dataType === 'string' ||
            dataType === 'text' ||
            dataType === 'float' ||
            dataType === 'int'
          ) {
            return (
              <Form.Input
                key={k.sc_keyword}
                width={6}
                label={T(k.dk_description)}
                placeholder={T(k.dk_description)}
                name={k.sc_keyword}
                type={type}
                value={this.state[k.sc_keyword].sc_value}
                onChange={(e, { name, value }) =>
                  this.handleChange(name, value, k.dk_data_type)
                }
              />
            );
          } else if (dataType === 'date') {
            return (
              <DateInput
                key={k.sc_keyword}
                animation="off"
                width={6}
                label={T(k.dk_description)}
                placeholder={T(k.dk_description)}
                name={k.sc_keyword}
                value={this.state[k.sc_keyword].sc_value}
                format="l"
                onChange={(e, { name, value }) =>
                  this.handleChange(name, value, k.dk_data_type)
                }
              />
            );
          } else if (dataType === 'list') {
            const values = k.dk_values
              .split('|')
              .map(ele => ({ text: ele, value: ele }));
            return (
              <Form.Select
                key={k.sc_keyword}
                name={k.sc_keyword}
                width={6}
                label={T(k.dk_description)}
                placeholder={T(k.dk_description)}
                value={this.state[k.sc_keyword].sc_value}
                onChange={(e, { name, value }) =>
                  this.handleChange(name, value, k.dk_data_type)
                }
                options={values}
              />
            );
          }
        })}
      </Form.Group>
    ));
  };

  render() {
    if (this.props.editShop === false) {
      return T('Shop has not been created yet');
    }
    //console.log('Shop Config Props: ', this.props, '\nState: ', this.state);
    return (
      <Form>
        <Header> {T('Shop configs')} </Header>
        {this.renderShopConfig(this.props.configKeywords)}
        <Button.Group>
          <Button primary content={T('Save')} onClick={this.submitShopConfig} />
          <Button
            secondary
            content={T('Cancel')}
            onClick={() => this.props.history.push('/app/shop/list')}
          />
        </Button.Group>
      </Form>
    );
  }
}

export default ShopConfig;

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Nav from "../layout/Nav";

import ProductRegistrationList from "../components/Product/Registration/ProductRegistrationList";
import ProductRegistrationAdd from "../components/Product/Registration//ProductRegistrationAdd";
import ProductRegistrationEdit from "../components/Product/Registration/ProductRegistrationEdit";

const ProductRegistrationRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: "150px" }}>
      <Nav />
      <Switch>
        <Route path={`${path}/list`} exact>
          <ProductRegistrationList />
        </Route>
        <Route path={`${path}/add`} exact>
          <ProductRegistrationAdd />
        </Route>
        <Route path={`${path}/edit/:card_instance_id`} exact>
          <ProductRegistrationEdit />
        </Route>
      </Switch>
    </Container>
  );
};

export default ProductRegistrationRoute;

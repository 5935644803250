import React, { Component } from 'react';
import {} from 'semantic-ui-react';
import { connect } from 'react-redux';
import AppStatisticManager from './AppStatisticManager';

class AppStatisticContainer extends Component {
  render() {
    return <AppStatisticManager />;
  }
}

function mapStateToProps({ workspace, languages }) {
  return {
    ws_id: workspace.ws_id,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    langStatus: languages.status,
    languages: languages.languages,
  };
}
export default connect(mapStateToProps, {})(AppStatisticContainer);

export const FETCH_APPLICATIONS_SUCCESS = "fetch_applications_success";
export const FETCH_APPLICATIONS_WAITING = "fetch_applications_waiting";
export const FETCH_APPLICATIONS_ERROR = "fetch_applications_error";
export const FETCH_APPLICATIONS_RESET = "fetch_applications_reset";

export const FETCH_APPLICATIONS_CONTEXTS_WAITING =
  "fetch_applications_contexts_waiting";
export const FETCH_APPLICATIONS_CONTEXTS_SUCCESS =
  "fetch_applications_contexts_success";
export const FETCH_APPLICATIONS_CONTEXTS_ERROR =
  "fetch_applications_contexts_error";

export const SET_SELECTED_APPLICATION_SUCCESS =
  "set_selected_application_success";
export const SET_SELECTED_APPLICATION_ERROR = "set_selected_application_error";

export const FETCH_APPLICATION_WAITING = "fetch_application_waiting";
export const FETCH_APPLICATION_SUCCESS = "fetch_application_success";
export const FETCH_APPLICATION_ERROR = "fetch_application_error";

export const ADD_SHOP_TO_APPLICATION_WAITING =
  "add_shop_to_application_waiting";
export const ADD_SHOP_TO_APPLICATION_SUCCESS =
  "add_shop_to_application_success";
export const ADD_SHOP_TO_APPLICATION_ERROR = "add_shop_to_application_error";
export const ADD_SHOP_TO_APPLICATION_RESET = "add_shop_to_application_reset";

export const REMOVE_SHOP_FROM_APPLICATION_WAITING =
  "remove_shop_from_application_waiting";
export const REMOVE_SHOP_FROM_APPLICATION_SUCCESS =
  "remove_shop_from_application_success";
export const REMOVE_SHOP_FROM_APPLICATION_ERROR =
  "remove_shop_from_application_error";
export const REMOVE_SHOP_FROM_APPLICATION_RESET =
  "remove_shop_from_application_reset";

export const EDIT_APPLICATION_WAITING = "edit_application_waiting";
export const EDIT_APPLICATION_SUCCESS = "edit_application_success";
export const EDIT_APPLICATION_ERROR = "edit_application_error";

export const FETCH_COMPANIES_WAITING = "fetch_companies_waiting";
export const FETCH_COMPANIES_SUCCESS = "fetch_companies_success";
export const FETCH_COMPANIES_ERROR = "fetch_companies_error";
export const FETCH_COMPANIES_RESET = "fetch_companies_reset";

export const FETCH_COMPANY_WAITING = "fetch_company_waiting";
export const FETCH_COMPANY_SUCCESS = "fetch_company_success";
export const FETCH_COMPANY_ERROR = "fetch_company_error";
export const FETCH_COMPANY_RESET = "fetch_company_reset";

export const ADD_COMPANY_WAITING = "add_company_waiting";
export const ADD_COMPANY_SUCCESS = "add_company_success";
export const ADD_COMPANY_ERROR = "add_company_error";
export const ADD_COMPANY_RESET = "add_company_reset";

export const EDIT_COMPANY_WAITING = "edit_company_waiting";
export const EDIT_COMPANY_SUCCESS = "edit_company_success";
export const EDIT_COMPANY_ERROR = "edit_company_error";
export const EDIT_COMPANY_RESET = "edit_company_reset";

export const DELETE_COMPANY_WAITING = "delete_company_waiting";
export const DELETE_COMPANY_SUCCESS = "delete_company_success";
export const DELETE_COMPANY_ERROR = "delete_company_error";
export const DELETE_COMPANY_RESET = "delete_company_reset";

export const UPLOAD_COMPANY_LOGO_WAITING = "upload_company_logo_waiting";
export const UPLOAD_COMPANY_LOGO_SUCCESS = "upload_company_logo_success";
export const UPLOAD_COMPANY_LOGO_ERROR = "upload_company_logo_error";
export const UPLOAD_COMPANY_LOGO_RESET = "upload_company_logo_reset";

export const DELETE_COMPANY_LOGO_WAITING = "delete_company_logo_waiting";
export const DELETE_COMPANY_LOGO_SUCCESS = "delete_company_logo_success";
export const DELETE_COMPANY_LOGO_ERROR = "delete_company_logo_error";
export const DELETE_COMPANY_LOGO_RESET = "delete_company_logo_reset";

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Wizard from "../components/Wizard/Wizard";

const PrometheusRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: "150px" }}>
      <Switch>
        <Route path={`${path}/wizard/:ap_key`} exact>
          <Wizard />
        </Route>
        <Route path={`${path}/applications/:ap_key/edit`} exact>
          <Wizard />
        </Route>
      </Switch>
    </Container>
  );
};

export default PrometheusRoute;

/* eslint-env browser */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Container, Header, Label, List } from 'semantic-ui-react';
import { T } from '../Common/Helpers';

class Dashboard extends Component {
  render() {
    return (
      <Segment padded="very">
        <Container text>
          <Header as="h2">{`${T('Tervetuloa')}, ${
            this.props.username
          }`}</Header>
          <Segment padded>
            <Label attached="top">{T('Asiakaspalvelu')}</Label>
            <p>{T('Arkisin')} 9-16</p>
            <List>
              <List.Item>
                <List.Icon name="phone" />
                <List.Content>+358 40 820 4112</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="mail" />
                <List.Content>
                  <a href="mailto:support@keino.io">asiakaspalvelu@keino.io</a>
                </List.Content>
              </List.Item>
            </List>
          </Segment>
        </Container>
      </Segment>
    );
  }
}

function mapStateToProps({ auth, workspace }) {
  return {
    username: auth.username,
    workspace,
  };
}

export default connect(mapStateToProps, {})(Dashboard);

import React, { Component } from 'react';
import { Header, Divider } from 'semantic-ui-react';
import { T } from '../Common/Helpers';
import PollOptionItemManager from './PollOptionItemManager';
import { PopupContent } from '../Common/PopupContent';
class PollOptionContainer extends Component {
  render() {
    return (
      <PollOptionItemManager
        language={this.props.language}
        polloptions={this.props.polloptions}
        onSaveOption={this.props.onSaveOption}
        onAddPollOption={this.props.onAddPollOption}
        onRemove={this.props.onRemove}
        onUpdatePollOption={this.props.onUpdatePollOption}
        imageUpdate={this.props.imageUpdate}
        workspace={this.props.workspace}
        ws_id={this.props.ws_id}
        module_id={this.props.module_id}
        game_id={this.props.game_id}
      />
    );
  }
}

export default PollOptionContainer;

import React, { Component } from "react";
import _ from "lodash";
import TranslationItem from "./TranslationItem";

class TranslationItemManager extends Component {
  renderTranslationItems = (translationitems) =>
    _.map(translationitems, (translation, index) => (
      <div language={translation.gtr_language}>
        <TranslationItem
          index={index}
          translation={translation}
          workspace={this.props.workspace}
          ws_id={this.props.ws_id}
          onRemove={this.props.onRemove}
          module_id={this.props.module_id}
        />
      </div>
    ));

  render() {
    return <>{this.renderTranslationItems(this.props.translationitems)}</>;
  }
}

export default TranslationItemManager;

/* eslint-env browser */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Segment, Dropdown } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import { toast } from 'react-semantic-toasts';
import { withRouter } from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import { fetchAllTeams, createSeries, createSeason } from '../../Redux/actions';
import { T } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';

const DATE_TIME_FORMAT = 'l HH:mm';

class EventAddSeason extends Component {
  constructor(props) {
    super(props);
    this.buildCategories = this.buildCategories.bind(this);
    this.getSeriesList = this.getSeriesList.bind(this);
    this.getAroudToFetchThisProps = this.getAroudToFetchThisProps.bind(this);
    this.state = {
      ev_event_start: moment().format(DATE_TIME_FORMAT),
      //ev_event_start: moment()({DATE_TIME_FORMAT}).toString(),
      //moment(cm_valid_to, 'l HH:mm').toISOString()
      ev_event_stop: moment().add(1, 'days').format(DATE_TIME_FORMAT),
    };
  }

  componentDidMount() {
    const ws_id = this.props.workspace.activeWorkspace.id;
    const key = localStorage.getItem('x-auth-key');
    this.props.fetchAllTeams(key, ws_id);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (
      this.props.parents.createSeriesStatus !== 'success' &&
      nextProps.parents.createSeriesStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Series created successfully'),
        time: 5000,
      });
      this.props.history.push('/app/events/season/home/');
    } else if (
      this.props.parents.createSeriesStatus !== 'error' &&
      nextProps.parents.createSeriesStatus === 'error'
    ) {
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T('Could not create series'),
        time: 5000,
      });
    } else if (
      this.props.parents.createSeasonStatus !== 'success' &&
      nextProps.parents.createSeasonStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Season created successfully'),
        time: 5000,
      });
      this.props.history.push('/app/events/season/home/');
    } else if (
      this.props.parents.createSeasonStatus !== 'error' &&
      nextProps.parents.createSeasonStatus === 'error'
    ) {
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T('Could not create season'),
        time: 5000,
      });
    }
  }

  onChange = values => {
    this.setState({ ev_eventcategory: values.ev_eventcategory, ev_parent: '' });
  };

  onSubmit = values => {
    const key = localStorage.getItem('x-auth-key');
    const locationProp = this.props.location.state;
    const eventType = locationProp.series ? 'series' : 'season';
    const wsId = this.props.workspace.activeWorkspace.id;
    let inputValues = {
      ...values,
      ev_type: eventType,
      ev_event_start: moment(
        this.state.ev_event_start,
        DATE_TIME_FORMAT
      ).toISOString(),
      ev_event_stop: moment(
        this.state.ev_event_stop,
        DATE_TIME_FORMAT
      ).toISOString(),
      ev_public: values.ev_public || false,
    };
    if (locationProp.season) {
      inputValues = {
        ...inputValues,
        ev_eventcategory: values.ev_eventcategory,
        ev_parent: values.parent_id,
      };
      this.props.createSeason(inputValues, key, wsId);
    } else if (locationProp.series) {
      let parent_id = null;
      const { workspaces } = this.props.workspace;
      if (Array.isArray(workspaces) && workspaces.length > 0) {
        parent_id = workspaces[0].cnc_event;
      }
      inputValues = { ...inputValues, ev_parent: parent_id };
      this.props.createSeries(inputValues, key, wsId);
    }
  };

  onCancel() {
    this.props.history.push('/app/events/season/home');
  }

  getAroudToFetchThisProps() {
    return this.props;
  }

  getSeriesList() {
    const seriesList = _.map(this.props.parents.seriesList, item => ({
      text: item.ev_name,
      value: item.event_id,
    }));
    // seriesList.unshift({ text: 'series', value: null });
    return seriesList;
  }

  buildTeams(teams) {
    return _.map(teams, team => ({
      key: team.performer_id,
      text: team.pf_name,
      value: team.performer_id,
    }));
  }

  buildCategories() {
    const filterText = this.props.location.state.season ? 'season' : 'series';
    const seriesAndSeasonCategories = _.filter(
      this.props.eventcategories.eventcategories,
      category => category.ec_type === filterText
    );
    return (
      _.map(seriesAndSeasonCategories, list => ({
        text: list.ec_name,
        value: list.eventcategory_id,
      })) || { text: 'No category', value: null }
    );
  }

  handleChange = (event, { name = undefined, value }) => {
    const date = moment(value, 'l', true);
    const time = moment(value, DATE_TIME_FORMAT, true);
    const val = new Date(value);
    let eVal = value;
    const start = new Date(this.state.ev_event_start);
    const stop = new Date(this.state.ev_event_stop);
    if (!(date.isValid() || time.isValid())) {
      console.error('inVALID');
      return;
    }
    switch (name) {
      case 'ev_event_start':
        //if (value.length < 18) { eVal = `${value} 00:00`; }
        if (val > stop) {
          console.error('Start time greater than stop');
        } else {
          this.setState({ ev_event_start: eVal });
        }
        break;
      case 'ev_event_stop':
        //if (value.length < 18) { eVal = `${value} 23:59`; }
        if (val < start) {
          // console.log('Time machine needed: ends before starts!');
        } else {
          this.setState({ ev_event_stop: eVal });
        }
        break;
      default:
        break;
    }
  };

  render() {
    if (
      this.props.parents.statusSeries !== 'success' ||
      this.props.performers.fetchTeamsStatus !== 'success'
    ) {
      return <Spinner />;
    }
    const locationProp = this.props.location.state;
    const { ev_event_stop, ev_event_start, ev_public } = this.state;
    return (
      <Segment style={{ marginBottom: `${this.treeLength * 24}px` }}>
        <h3> {locationProp.season ? T('Add Season') : T('Add Series')} </h3>
        <Form name="addform" onValidSubmit={this.onSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              label={T('Name')}
              name="ev_name"
              placeholder={T('Name')}
              required
            />
          </Form.Group>
          <Form.Select
            label={T('Category')}
            name="ev_eventcategory"
            options={this.buildCategories()}
            search
            required
          />
          {locationProp.season && (
            <Form.Select
              label={T('Series')}
              name="parent_id"
              options={this.getSeriesList()}
              search
              required
            />
          )}
          <DateTimeInput
            closable
            //initialDate={moment(DATE_TIME_FORMAT)}
            //placeholder={moment(DATE_TIME_FORMAT)}
            name="ev_event_start"
            label={T('Begins')}
            value={ev_event_start}
            iconPosition="left"
            onChange={this.handleChange}
            dateTimeFormat={DATE_TIME_FORMAT}
            required
            autoComplete="off"
            history="off"
            animation="off"
          />
          <DateTimeInput
            closable
            //initialDate={moment()}
            //placeholder={moment(DATE_TIME_FORMAT)}
            name="ev_event_stop"
            label={T('Ends')}
            value={ev_event_stop}
            iconPosition="left"
            onChange={this.handleChange}
            dateTimeFormat={DATE_TIME_FORMAT}
            autoComplete="off"
            history="off"
            animation="off"
            required
          />
          <Form.Group>
            <Form.Checkbox
              label={T('Public')}
              name="ev_public"
              value={ev_public}
              className="ui toggle checkbox"
              style={{ margin: '10px' }}
            />
          </Form.Group>
          <Button.Group>
            <Button
              className="ui green button"
              type="submit"
              role="button"
              content={T('Save')}
            />
            <Button
              type="button"
              secondary
              onClick={() => this.onCancel()}
              content={T('Cancel')}
            />
          </Button.Group>
          {locationProp.season && (
            <Dropdown
              style={{ margin: '10px' }}
              placeholder={T('Select Teams')}
              fluid
              selection
              options={this.buildTeams(this.props.performers.teams)}
              multiple
              scrolling
              search
            />
          )}
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({
  eventcategories,
  parents,
  performers,
  events,
  workspace,
}) {
  return { eventcategories, parents, performers, events, workspace };
}

export default connect(mapStateToProps, {
  createSeries,
  createSeason,
  fetchAllTeams,
})(withRouter(EventAddSeason));

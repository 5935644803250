import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import Nav from '../layout/Nav';

import ListTickets from '../components/Ticketing/ListTickets';
import TicketStatus from '../components/Ticketing/TicketStatus';
import ManageTicket from '../components/Ticketing/ManageTicket';
import DefaultTicketSettingsContainer from '../components/Ticketing/DefaultTicketSettingsContainer';
import DefaultTicketSettingsManager from '../components/Ticketing/DefaultTicketSettingsManager';
import SeasonCardList from '../components/Ticketing/SeasonCardList';
import SeasonCardEdit from '../components/Ticketing/SeasonCardEdit';
import SeasonCardAdd from '../components/Ticketing/SeasonCardAdd';

const TicketingRoute = () => {
  let { path } = useRouteMatch();
  return (
    <Container fluid style={{ marginBottom: '150px' }}>
      <Nav />
      <Switch>
        <Route path={`${path}/tickets/list`} exact>
          <ListTickets />
        </Route>
        <Route path={`${path}/tickets/status`} exact>
          <TicketStatus />
        </Route>{' '}
        <Route path={`${path}/tickets/manage`} exact>
          <ManageTicket />
        </Route>
        <Route path={`${path}/tickets/manage/:ticketId`} exact>
          <ManageTicket />
        </Route>
        <Route path={`${path}/default/list`} exact>
          <DefaultTicketSettingsContainer />
        </Route>
        <Route path={`${path}/default/manage`} exact>
          <DefaultTicketSettingsManager />
        </Route>
        <Route path={`${path}/default/manage/:ticketId`} exact>
          <DefaultTicketSettingsManager />
        </Route>
        <Route path={`${path}/seasoncard/list`} exact>
          <SeasonCardList />
        </Route>
        <Route path={`${path}/seasoncard/add`} exact>
          <SeasonCardAdd />
        </Route>
        <Route path={`${path}/seasoncard/edit/:card_instance_id`} exact>
          <SeasonCardEdit />
        </Route>
      </Switch>
    </Container>
  );
};

export default TicketingRoute;

import React, { Component } from 'react';
import { Button, Segment, Icon, Popup } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-semantic-toasts';
import { T, getErrorMessage } from '../Common/Helpers';
import {
  addPerformerPlayer,
  resetAddPerformerPlayer,
  fetchPerformerPlayersList,
  resetFetchPerformerPlayersList,
} from '../../Redux/actions';

class PlayerAdd extends Component {
  UNSAFE_componentWillUpdate(nextProps) {
    // console.log('Props:', this.props);
    const performerId = this.props.match.params.performer_id;

    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem('x-auth-key');
    if (
      this.props.createStatus !== 'success' &&
      nextProps.createStatus === 'success'
    ) {
      this.props.onResetEditAndAddPlayer();
      this.props.fetchPerformerPlayersList(
        authKey,
        ws_id,
        performerId,
        this.props.headers.current_page
      );
      this.props.resetAddPerformerPlayer();
      this.props.setStateAfterAdd(nextProps.player.performer_id);
    }
    if (
      this.props.createStatus !== 'error' &&
      nextProps.createStatus === 'error'
    ) {
      const errorObj = getErrorMessage(nextProps.error);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetAddPerformerPlayer();
    }
  }

  onValidSubmit(formData) {
    const performerId = parseInt(this.props.match.params.performer_id, 10);
    const ws_id = this.props.workspace.activeWorkspace.id;
    const inputValues = {
      ...formData,
      pf_parent: performerId,
      pf_type: 'player',
      pf_public: formData.pf_public !== undefined,
      pf_name: `${formData.pf_first_name}  ${formData.pf_last_name}`,
    };
    const keys = Object.keys(inputValues);
    keys.forEach(k => {
      if (k !== 'pf_public' && !inputValues[k]) {
        delete inputValues[k];
      }
    });
    const authKey = localStorage.getItem('x-auth-key');
    // console.log('Submitted Data: ', inputValues);
    this.props.addPerformerPlayer(inputValues, authKey, ws_id);
  }

  onCancel() {
    this.props.onResetEditAndAddPlayer();
  }

  addBasicPerformerData() {
    // const parentId = this.props.match.params.performer_id;
    return (
      <Segment>
        <h3>
          {T('Add Player')}
          <span style={{ marginLeft: '20px' }}>
            <Popup
              header="Info"
              content={T(
                'After creating a player, additional data attributes i.e Player Image,' +
                  'date of birth, height  ...  can be added in the player dashboard section.'
              )}
              trigger={
                <Icon circular bordered size="small" name="info" color="blue" />
              }
            />
          </span>
        </h3>
        <Form
          onValidSubmit={this.onValidSubmit.bind(this)}
          ref={ref => (this.form = ref)}
        >
          <Form.Group widths="equal">
            <Form.Input
              autoFocus
              required
              name="pf_first_name"
              label={T('First Name')}
              placeholder={T('First Name')}
              width={6}
            />
            <Form.Input
              required
              name="pf_last_name"
              label={T('Last Name')}
              placeholder={T('Last Name')}
              width={6}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="pf_license_code"
              label={T('Licence Code')}
              width={6}
              placeholder={T('Licence Code')}
              type="number"
            />
            {/* <Form.Input
                 name="pf_remote_id"
                 label={T('Remote Id')}
                 placeholder={T('Remote Id')}
                 type="number"
                 width={6}
             />
             </Form.Group>
             <Form.Group widths="equal"> */}
            <Form.Checkbox
              toggle
              name="pf_public"
              label={T('Public')}
              width={6}
              style={{ marginTop: '25px' }}
            />
          </Form.Group>
          <Button content={T('Save')} color="green" />
          <Button
            type="button"
            color="black"
            content={T('Cancel')}
            onClick={() => this.onCancel()}
          />
        </Form>
      </Segment>
    );
  }
  render() {
    return (
      <React.Fragment>
        <h3>
          {T('Add Player')}
          <span style={{ marginLeft: '20px' }}>
            <Popup
              header="Info"
              content={T(
                'After creating a player, additional data attributes i.e Player Image,' +
                  'date of birth, height  ...  can be added in the player dashboard section.'
              )}
              trigger={
                <Icon circular bordered size="small" name="info" color="blue" />
              }
            />
          </span>
        </h3>
        <Form
          onValidSubmit={this.onValidSubmit.bind(this)}
          ref={ref => (this.form = ref)}
        >
          <Form.Group widths="equal">
            <Form.Input
              autoFocus
              required
              name="pf_first_name"
              label={T('First Name')}
              placeholder={T('First Name')}
              width={6}
            />
            <Form.Input
              required
              name="pf_last_name"
              label={T('Last Name')}
              placeholder={T('Last Name')}
              width={6}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="pf_license_code"
              label={T('Licence Code')}
              width={6}
              placeholder={T('Licence Code')}
              type="number"
            />
            {/* <Form.Input
                         name="pf_remote_id"
                         label={T('Remote Id')}
                         placeholder={T('Remote Id')}
                         type="number"
                         width={6}
                     />
                     </Form.Group>
                     <Form.Group widths="equal"> */}
            <Form.Checkbox
              toggle
              name="pf_public"
              label={T('Public')}
              width={6}
              style={{ marginTop: '25px' }}
            />
          </Form.Group>
          <Button content={T('Save')} color="green" />
          <Button
            type="button"
            color="black"
            content={T('Cancel')}
            onClick={() => this.onCancel()}
          />
        </Form>
      </React.Fragment>
    );
  }
}
function mapStateToProps({ performerPlayers, workspace }) {
  return {
    performer: performerPlayers.players,
    headers: performerPlayers.headers,
    createStatus: performerPlayers.createStatus,
    selectStatus: performerPlayers.selectStatus,
    player: performerPlayers.player,
    error: performerPlayers.error,
    workspace,
  };
}

export default connect(mapStateToProps, {
  addPerformerPlayer,
  resetAddPerformerPlayer,
  fetchPerformerPlayersList,
  resetFetchPerformerPlayersList,
})(withRouter(PlayerAdd));

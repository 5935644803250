import React, { Component } from 'react';
import {
  Segment,
  Label,
  Message,
  Grid,
  Button,
  Icon,
  Form,
} from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fi';
import {
  fetchEventsNew,
  fetchMomentsOptions,
  fetchCategoriesNew,
} from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import WorkCaseList from './WorkCaseList';
import WorkCaseEdit from './WorkCaseEdit';
import { T } from '../Common/Helpers';

const MODULE_ID = 41;

class WorkCaseLogging extends Component {
  state = {
    selectedEvent: null,
    addNewMode: false,
    place_ids: [],
    triage_ids: [],
    startPage: null,
    startdate: new moment().subtract(1, 'week').startOf('week').format('l'),
    enddate: new moment().endOf('day').format('l'),
    showRunning: true,
    showOnBreak: true,
    showArchived: false,
  };
  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    //const { cnc_event } = this.props.activeWorkspace;
    // console.log(this.props)
    this.props.fetchMomentsOptions(authKey, ws_id, '', MODULE_ID);
    this.props.fetchCategoriesNew({ authKey, ws_id, module_id: MODULE_ID });
    this.reloadEvents();
    const { match } = this.props;
    const { params } = match;
    const eventId = params.eventId && parseInt(params.eventId, 10);
    console.log('match', match);
    console.log('props', this.props);
    if (eventId) {
      this.setState({
        selectedEvent: eventId,
        addNewMode: false,
      });
    }
    if ('add' == params.eventId) {
      this.setState({
        selectedEvent: null,
        addNewMode: true,
      });
    } else if (this.props.list) {
      this.setState({
        selectedEvent: null,
        addNewMode: false,
      });
    }
  }

  reloadEvents() {
    const { place_ids, triage_ids, startPage, startdate, enddate } = this.state;
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    const { cnc_event } = this.props.activeWorkspace;
    const startDateFmt = new moment(startdate, 'l').format('YYYY-MM-DD');
    const endDateFmt = enddate
      ? new moment(enddate, 'l').format('YYYY-MM-DD')
      : null;
    const { showRunning, showOnBreak, showArchived } = this.state;
    this.props.fetchEventsNew({
      authKey,
      place_ids,
      startPage,
      limit: 100,
      startdate: startDateFmt,
      enddate: endDateFmt,
      triage_ids,
      parent: cnc_event,
      ws_id,
      showAll: true,
      sortByColumn: 'event_id',
      sortDirection: 'desc',
      module_id: MODULE_ID,
      moments: true,
      performer: true,
      showRunning,
      showOnBreak,
      showArchived,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      place_ids,
      triage_ids,
      startPage,
      startdate,
      enddate,
      showRunning,
      showOnBreak,
      showArchived,
    } = this.state;
    if (
      JSON.stringify(prevState.place_ids) !== JSON.stringify(place_ids) ||
      JSON.stringify(prevState.triage_ids) !== JSON.stringify(triage_ids) ||
      prevState.startdate !== startdate ||
      prevState.enddate !== enddate ||
      prevState.startPage !== startPage ||
      prevState.showRunning !== showRunning ||
      prevState.showOnBreak !== showOnBreak ||
      prevState.showArchived !== showArchived
    ) {
      this.reloadEvents();
    }
  }
  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handlePaginationChange = (e, { activePage }) => {
    // console.log("activePage", activePage);
    const { total_pages } = this.props.eventHeaders;
    if (activePage - 1 > total_pages) {
      return;
    }
    this.setState({ startPage: activePage });
    //this.fetchReport(activePage);
  };

  render() {
    if (
      this.props.momentOptions.status !== 'success' ||
      this.props.eventcategories.status === 'waiting'
    ) {
      return <Spinner />;
    }
    // show error if event fetch fails
    if (this.props.fetchesStatus === 'error') {
      return (
        <Message negative>
          <Message.Header>{T('Unable to fetch events')}</Message.Header>
          <p>
            {T('Error message:')} {this.props.eventsError.message}
          </p>
        </Message>
      );
    }
    const event_place_options = this.props.momentOptions.event_places.map(
      ep => {
        return {
          key: `ep${ep.place_id}`,
          text: ep.pl_name,
          value: ep.place_id,
        };
      }
    );
    const triage_options = this.props.momentOptions.triages.map(tr => {
      return { key: `tr${tr.id}`, text: tr.triage, value: tr.id };
    });

    const { showRunning, showOnBreak, showArchived, selectedEvent } =
      this.state;
    // otherwise render editor
    return (
      <>
        <Grid stackable columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Button.Group floated="right">
                <Button
                  disabled={this.state.addNewMode}
                  primary
                  onClick={() => {
                    this.props.history.push({
                      pathname: `/app/workcaselogging/edit/add`,
                    });
                  }}
                >
                  <Icon name="plus" />
                  {T('Lisää uusi')}
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment>
                <Label attached="top">{T('Rajaa')}</Label>
                <Form>
                  <Form.Group widths="equal">
                    <Form.Dropdown
                      clearable
                      multiple
                      search
                      fluid
                      selection
                      name="place_ids"
                      placeholder={T('All Places')}
                      label={T('Place')}
                      options={event_place_options}
                      onChange={(e, { name, value }) =>
                        this.handleChange(name, value)
                      }
                      value={this.state.place_ids || []}
                    />
                    <Form.Dropdown
                      clearable
                      multiple
                      search
                      fluid
                      selection
                      name="triage_ids"
                      placeholder={T('Kaikki triaget')}
                      label={T('Triage')}
                      options={triage_options}
                      onChange={(e, { name, value }) =>
                        this.handleChange(name, value)
                      }
                      value={this.state.triage_ids || []}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <DateInput
                      fluid
                      closable
                      name="startdate"
                      label={T('Event from')}
                      onChange={(e, { name, value }) =>
                        this.handleChange(name, value)
                      }
                      value={this.state.startdate}
                      dateFormat="l"
                      maxDate={new moment().format('l')}
                    />
                    <DateInput
                      fluid
                      closable
                      name="enddate"
                      label={T('Event to')}
                      onChange={(e, { name, value }) =>
                        this.handleChange(name, value)
                      }
                      value={this.state.enddate || ''}
                      dateFormat="l"
                      maxDate={new moment().format('l')}
                    />
                  </Form.Group>
                  <Form.Group widths="equal" label="foo">
                    <Button
                      fluid
                      toggle
                      color="red"
                      onClick={() =>
                        this.setState({ showRunning: !showRunning })
                      }
                    >
                      {showRunning ? (
                        <Icon name="check square" />
                      ) : (
                        <Icon name="square" />
                      )}
                      {T('Käynnissä olevat')}
                    </Button>
                    <Button
                      fluid
                      toggle
                      color="yellow"
                      onClick={() =>
                        this.setState({ showOnBreak: !showOnBreak })
                      }
                    >
                      {showOnBreak ? (
                        <Icon name="check square" />
                      ) : (
                        <Icon name="square" />
                      )}
                      {T('Tauolla olevat')}
                    </Button>
                    <Button
                      fluid
                      toggle
                      color="grey"
                      onClick={() =>
                        this.setState({ showArchived: !showArchived })
                      }
                    >
                      {showArchived ? (
                        <Icon name="check square" />
                      ) : (
                        <Icon name="square" />
                      )}
                      {T('Arkistoidut')}
                    </Button>
                  </Form.Group>
                </Form>
              </Segment>
              <WorkCaseList
                selectedEvent={selectedEvent}
                placeIds={this.state.plce_ids}
                handlePaginationChange={this.handlePaginationChange}
                onSelectChange={newSelectedEvent => {
                  this.props.history.push({
                    pathname: `/app/workcaselogging/edit/${newSelectedEvent}`,
                  });
                  this.setState({
                    selectedEvent: newSelectedEvent,
                    addNewMode: false,
                  });
                }}
              />
            </Grid.Column>
            <Grid.Column>
              {selectedEvent || this.state.addNewMode ? (
                <WorkCaseEdit
                  key={selectedEvent ? `edit${selectedEvent}` : `editNew`}
                  resetLoggerMode={() => {
                    this.setState({ selectedEvent: null, addNewMode: false });
                    this.reloadEvents();
                  }}
                  reloadEvents={() => this.reloadEvents()}
                  onSaveNewSuccess={newEventId => {
                    this.setState({
                      selectedEvent: newEventId,
                      addNewMode: false,
                    });
                    this.reloadEvents();
                  }}
                  selectedEvent={selectedEvent}
                />
              ) : (
                <Message>
                  <Message.Header>{T('No selected item')}</Message.Header>
                  <p>{T('Please select an item from the left to edit.')}</p>
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

function mapStateToProps({
  workspace,
  languages,
  events,
  eventReport,
  eventcategories,
}) {
  return {
    ws_id: workspace.ws_id,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    langStatus: languages.status,
    languages: languages.languages,
    fetchesStatus: events.fetchesStatus,
    eventHeaders: events.headers,
    eventsError: events.error,
    momentOptions: eventReport.momentOptions,
    eventcategories,
  };
}
export default connect(mapStateToProps, {
  fetchEventsNew,
  fetchMomentsOptions,
  fetchCategoriesNew,
})(withRouter(WorkCaseLogging));

import _ from 'lodash';
import {
  FETCH_NOTIFICATIONS_WAITING,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  FETCH_NOTIFICATIONS_RESET,
  FETCH_NOTIFICATION_WAITING,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_ERROR,
  FETCH_NOTIFICATION_RESET,
  ADD_NOTIFICATION_WAITING,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_ERROR,
  ADD_NOTIFICATION_RESET,
  EDIT_NOTIFICATION_WAITING,
  EDIT_NOTIFICATION_SUCCESS,
  EDIT_NOTIFICATION_ERROR,
  EDIT_NOTIFICATION_RESET,
  DELETE_NOTIFICATION_WAITING,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_ERROR,
  DELETE_NOTIFICATION_RESET,
  FETCH_NOTIFICATION_CATEGORIES_WAITING,
  FETCH_NOTIFICATION_CATEGORIES_SUCCESS,
  FETCH_NOTIFICATION_CATEGORIES_ERROR,
  FETCH_NOTIFICATION_CATEGORIES_RESET,
  ADD_NOTIFICATION_CATEGORY_WAITING,
  ADD_NOTIFICATION_CATEGORY_SUCCESS,
  ADD_NOTIFICATION_CATEGORY_ERROR,
  ADD_NOTIFICATION_CATEGORY_RESET,
  EDIT_NOTIFICATION_CATEGORY_WAITING,
  EDIT_NOTIFICATION_CATEGORY_SUCCESS,
  EDIT_NOTIFICATION_CATEGORY_ERROR,
  EDIT_NOTIFICATION_CATEGORY_RESET,
  DELETE_NOTIFICATION_CATEGORY_WAITING,
  DELETE_NOTIFICATION_CATEGORY_SUCCESS,
  DELETE_NOTIFICATION_CATEGORY_ERROR,
  DELETE_NOTIFICATION_CATEGORY_RESET,
  FETCH_NOTIFICATION_CATEGORY_TYPES_WAITING,
  FETCH_NOTIFICATION_CATEGORY_TYPES_SUCCESS,
  FETCH_NOTIFICATION_CATEGORY_TYPES_ERROR,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  operationState: {
    fetchAll: 'init',
    fetch: 'init',
    add: 'init',
    edit: 'init',
    delete: 'init',
    fetchAllCat: 'init',
    addCat: 'init',
    editCat: 'init',
    deleteCat: 'init',
  },
  notifications: '',
  notification: {},
  categories: {},
};
export default function notificationReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_NOTIFICATIONS_WAITING: {
      const operationState = { ...state.operationState, fetchAll: 'waiting' };
      return { ...state, operationState };
    }
    case FETCH_NOTIFICATIONS_SUCCESS: {
      const operationState = { ...state.operationState, fetchAll: 'success' };
      const header = action.payload.headers;
      // const data = _.mapKeys(action.payload.data, 'message_id');
      return {
        ...state,
        operationState,
        notifications: action.payload.data,
        header,
      };
    }
    case FETCH_NOTIFICATIONS_ERROR: {
      const operationState = { ...state.operationState, fetchAll: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case FETCH_NOTIFICATIONS_RESET: {
      const operationState = { ...state.operationState, fetchAll: 'init' };
      return { ...state, operationState };
    }
    case FETCH_NOTIFICATION_WAITING: {
      const operationState = { ...state.operationState, fetch: 'init' };
      return { ...state, operationState };
    }
    case FETCH_NOTIFICATION_SUCCESS: {
      const operationState = { ...state.operationState, fetch: 'success' };
      const notification = action.payload.data;
      return { ...state, operationState, notification };
    }
    case FETCH_NOTIFICATION_ERROR: {
      const operationState = { ...state.operationState, fetch: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case FETCH_NOTIFICATION_RESET: {
      const operationState = { ...state.operationState, fetch: 'init' };
      return { ...state, operationState };
    }

    case ADD_NOTIFICATION_WAITING: {
      const operationState = { ...state.operationState, add: 'init' };
      return { ...state, operationState };
    }
    case ADD_NOTIFICATION_SUCCESS: {
      const operationState = { ...state.operationState, add: 'success' };
      const notifications = {
        ...state.notifications,
        [action.payload.data.message_id]: action.payload.data,
      };
      return { ...state, notifications, operationState };
    }
    case ADD_NOTIFICATION_ERROR: {
      const operationState = { ...state.operationState, add: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case ADD_NOTIFICATION_RESET: {
      const operationState = { ...state.operationState, add: 'init' };
      return { ...state, operationState };
    }

    case EDIT_NOTIFICATION_WAITING: {
      const operationState = { ...state.operationState, edit: 'init' };
      return { ...state, operationState };
    }
    case EDIT_NOTIFICATION_SUCCESS: {
      const operationState = { ...state.operationState, edit: 'success' };
      const notifications = {
        ...state.notifications,
        [action.payload.data.message_id]: action.payload.data,
      };
      return { ...state, operationState, notifications };
    }
    case EDIT_NOTIFICATION_ERROR: {
      const operationState = { ...state.operationState, edit: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case EDIT_NOTIFICATION_RESET: {
      const operationState = { ...state.operationState, edit: 'init' };
      return { ...state, operationState };
    }

    case DELETE_NOTIFICATION_WAITING: {
      const operationState = { ...state.operationState, delete: 'waiting' };
      return { ...state, operationState };
    }
    case DELETE_NOTIFICATION_SUCCESS: {
      const operationState = { ...state.operationState, delete: 'success' };
      const notifications = _.omit(
        state.notifications,
        action.payload.data.message_id
      );
      return { ...state, operationState, notifications };
    }
    case DELETE_NOTIFICATION_ERROR: {
      const operationState = { ...state.operationState, delete: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case DELETE_NOTIFICATION_RESET: {
      const operationState = { ...state.operationState, delete: 'init' };
      return { ...state, operationState };
    }

    case FETCH_NOTIFICATION_CATEGORIES_WAITING: {
      const operationState = { ...state.operationState, fetchAllCat: 'init' };
      return { ...state, operationState };
    }
    case FETCH_NOTIFICATION_CATEGORIES_SUCCESS: {
      const operationState = {
        ...state.operationState,
        fetchAllCat: 'success',
      };
      const categories = _.mapKeys(action.payload.data, 'message_category_id');
      return { ...state, operationState, categories };
    }
    case FETCH_NOTIFICATION_CATEGORIES_ERROR: {
      const operationState = { ...state.operationState, fetchAllCat: 'error' };
      return { ...state, error: action.payload.error, operationState };
    }
    case FETCH_NOTIFICATION_CATEGORIES_RESET: {
      const operationState = { ...state.operationState, fetchAllCat: 'init' };
      return { ...state, operationState };
    }

    case ADD_NOTIFICATION_CATEGORY_WAITING: {
      const operationState = { ...state.operationState, addCat: 'init' };
      return { ...state, operationState };
    }
    case ADD_NOTIFICATION_CATEGORY_SUCCESS: {
      const operationState = { ...state.operationState, addCat: 'success' };
      const categories = {
        ...state.categories,
        [action.payload.data.message_category_id]: action.payload.data,
      };
      return { ...state, operationState, categories };
    }
    case ADD_NOTIFICATION_CATEGORY_ERROR: {
      const operationState = { ...state.operationState, addCat: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case ADD_NOTIFICATION_CATEGORY_RESET: {
      const operationState = { ...state.operationState, addCat: 'init' };
      return { ...state, operationState };
    }

    case EDIT_NOTIFICATION_CATEGORY_WAITING: {
      const operationState = { ...state.operationState, editCat: 'init' };
      return { ...state, operationState };
    }
    case EDIT_NOTIFICATION_CATEGORY_SUCCESS: {
      const operationState = { ...state.operationState, editCat: 'success' };
      const categories = {
        ...state.categories,
        [action.payload.data.message_category_id]: action.payload.data,
      };
      return { ...state, operationState, categories };
    }
    case EDIT_NOTIFICATION_CATEGORY_ERROR: {
      const operationState = { ...state.operationState, editCat: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case EDIT_NOTIFICATION_CATEGORY_RESET: {
      const operationState = { ...state.operationState, editCat: 'init' };
      return { ...state, operationState };
    }

    case DELETE_NOTIFICATION_CATEGORY_WAITING: {
      const operationState = { ...state.operationState, deleteCat: 'init' };
      return { ...state, operationState };
    }
    case DELETE_NOTIFICATION_CATEGORY_SUCCESS: {
      const operationState = { ...state.operationState, deleteCat: 'success' };
      const categories = _.omit(
        state.categories,
        action.payload.data.category_id
      );
      return { ...state, operationState, categories };
    }
    case DELETE_NOTIFICATION_CATEGORY_ERROR: {
      const operationState = { ...state.operationState, deleteCat: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case DELETE_NOTIFICATION_CATEGORY_RESET: {
      const operationState = { ...state.operationState, deleteCat: 'init' };
      return { ...state, operationState };
    }

    case FETCH_NOTIFICATION_CATEGORY_TYPES_WAITING: {
      return { ...state, categoryTypesStatus: 'waiting' };
    }

    case FETCH_NOTIFICATION_CATEGORY_TYPES_SUCCESS: {
      return {
        ...state,
        categoryTypesStatus: 'success',
        categoryTypes: action.payload.data,
      };
    }

    case FETCH_NOTIFICATION_CATEGORY_TYPES_ERROR: {
      return {
        ...state,
        categoryTypesStatus: 'error',
        error: action.payload.error,
      };
    }

    default:
      return state;
  }
}

export const FETCH_USERS_RESET = "fetch_users_reset";
export const FETCH_USERS_ERROR = "fetch_users_error";
export const FETCH_USERS_WAITING = "fetch_users_waiting";
export const FETCH_USERS_SUCCESS = "fetch_users_success";

export const FETCH_USER_WAITING = "fetch_user_waiting";
export const FETCH_USER_SUCCESS = "fetch_user_success";
export const FETCH_USER_ERROR = "fetch_user_error";
export const FETCH_USER_RESET = "fetch_user_reset";

export const CREATE_USER_WAITING = "create_user_waiting";
export const CREATE_USER_SUCCESS = "create_user_success";
export const CREATE_USER_ERROR = "create_user_error";
export const CREATE_USER_RESET = "create_user_reset";

export const DELETE_USER_WAITING = "delete_user_waiting";
export const DELETE_USER_SUCCESS = "delete_user_success";
export const DELETE_USER_ERROR = "delete_user_error";
export const DELETE_USER_RESET = "delete_user_reset";

export const EDIT_USER_WAITING = "edit_user_waiting";
export const EDIT_USER_SUCCESS = "edit_user_success";
export const EDIT_USER_ERROR = "edit_user_error";
export const EDIT_USER_RESET = "edit_user_reset";

import axios from 'axios';

import config from '../../Config/AEConfig';
import { RESET_SUCCESS, RESET_ERROR, RESET_WAITING } from '../Types';
import ErrorAction from './ErrorAction';

export function resetPassword(values) {
  // console.log(values);
  return function (dispatch) {
    dispatch({ type: RESET_WAITING });
    return axios
      .post(`${config.BASE_URL}${values.path}`, { password: values.password })
      .then(data => dispatch({ type: RESET_SUCCESS, payload: data }))
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, RESET_ERROR)
          : RESET_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

import React, { Component } from "react";
import {
  Table,
  Button,
  Icon,
  Menu,
  Pagination,
  Confirm,
  Header,
  Container,
  Segment,
  Message,
} from "semantic-ui-react";
import _ from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-semantic-toasts";
import { T, getErrorMessage } from "../Common/Helpers";
import {
  fetchVenues,
  deleteVenue,
  resetDeleteVenue,
} from "../../Redux/actions";
import { Spinner } from "../Common/Spinner";

class VenueList extends Component {
  constructor(props) {
    super(props);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.renderVenues = this.renderVenues.bind(this);
    this.state = {
      sortByColumn: "pl_name",
      direction: "asc",
      openDeleteConfirmation: false,
      selectedVenue: "",
    };
    this.sortVenuesByColumnName = this.sortVenuesByColumnName.bind(this);
  }
  componentDidMount() {
    // localStorage.setItem('route', this.props.history.location.pathname);
    if (!this.props.workspace.activeWorkspace.cnc_place) {
      return;
    }

    const ws_id = this.props.workspace.activeWorkspace.id;
    const { header } = this.props;
    const authKey = localStorage.getItem("x-auth-key");
    if (header && header.current_page) {
      this.props.fetchVenues(
        authKey,
        ws_id,
        true,
        header.current_page,
        header.searchterm,
        "pl_name",
        "asc"
      );
    } else {
      this.props.fetchVenues(authKey, ws_id, true, 1, null, "pl_name", "asc");
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.venues !== nextState.venues;
  }

  componentDidUpdate() {
    if (this.props.deleteStatus === "success") {
      toast({
        type: "success",
        title: T("Success"),
        description: T("Location deleted successfully"),
        time: 5000,
      });
      this.props.resetDeleteVenue();
      // this.props.history.push('/app/venues/list');
    } else if (this.props.deleteStatus === "error") {
      const errorObj = getErrorMessage(this.props.error);
      toast({
        type: "error",
        title: T("Error"),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetDeleteVenue();
    }
  }
  onDeleteClick = (e, selectedVenue) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ openDeleteConfirmation: true, selectedVenue });
  };

  oncancelDeleteConfirmation = () => {
    this.setState({ openDeleteConfirmation: false, selectedVenue: "" });
  };

  onConfirmDelete = () => {
    const authKey = localStorage.getItem("x-auth-key");
    const ws_id = this.props.workspace.activeWorkspace.id;
    this.props.deleteVenue(this.state.selectedVenue, authKey, ws_id);
    this.setState({ openDeleteConfirmation: false });
  };

  getDirection(dirn) {
    if (dirn === "asc") {
      return "ascending";
    }
    return "descending";
  }

  navigateToLink = (venue) => {
    this.props.history.push({
      pathname: `/app/venues/${venue.place_id}/edit`,
      state: { venue },
    });
  };

  sortVenuesByColumnName(columnName) {
    const { direction, sortByColumn } = this.state;
    const authKey = localStorage.getItem("x-auth-key");
    const ws_id = this.props.workspace.activeWorkspace.id;
    if (sortByColumn === columnName) {
      const modifiedDirn = direction === "asc" ? "desc" : "asc";
      this.props.fetchVenues(
        authKey,
        ws_id,
        true,
        1,
        this.props.header.searchterm,
        columnName,
        modifiedDirn
      );
      this.setState({ direction: modifiedDirn });
    } else {
      const modifiedDirn = "asc";
      this.props.fetchVenues(
        authKey,
        ws_id,
        true,
        1,
        this.props.header.searchterm,
        columnName,
        modifiedDirn
      );
      this.setState({ sortByColumn: columnName, direction: modifiedDirn });
    }
  }

  handlePaginationChange(e, { activePage }) {
    const authKey = localStorage.getItem("x-auth-key");
    const ws_id = this.props.workspace.activeWorkspace.id;
    const { header } = this.props;
    this.props.fetchVenues(
      authKey,
      ws_id,
      true,
      activePage,
      header ? header.searchterm : "",
      header ? header.sort : "",
      header ? null : this.state.direction
    );
  }

  renderVenues() {
    const venues = _.omit(this.props.venues.venues, ["status"]);
    if (!venues) return <div />;
    return _.map(
      venues,
      (venue) =>
        venue &&
        venue.place_id && (
          <Table.Row
            key={venue.place_id}
            onClick={() => this.navigateToLink(venue)}
            style={{ cursor: "pointer" }}
            className="tablerow"
          >
            <Table.Cell key={`${venue.place_id}-name`}>
              {venue.pl_name}
            </Table.Cell>
            <Table.Cell key={`${venue.place_id}-address`}>
              {venue.pl_address}
            </Table.Cell>
            <Table.Cell key={`${venue.place_id}-city`}>
              {venue.pl_city}
            </Table.Cell>
            <Table.Cell key={`${venue.pl_city}-zip`}>{venue.pl_zip}</Table.Cell>
            <Table.Cell
              style={{ cursor: "default" }}
              onClick={(e) => e.stopPropagation()}
            >
              <Button
                type="button"
                color="red"
                icon="trash"
                content={T("Delete")}
                onClick={(e) => this.onDeleteClick(e, venue.place_id)}
              />
            </Table.Cell>
          </Table.Row>
        )
    );
  }

  render() {
    if (!this.props.workspace.activeWorkspace.cnc_place) {
      return (
        <Container fluid style={{ marginBottom: "150px" }}>
          <Segment>
            <Message negative>
              <Message.Header>{T("Workspace Misconfiguration")}</Message.Header>
              <p>{T("No root venue found.")}</p>
            </Message>
          </Segment>
        </Container>
      );
    }

    if (this.props.status !== "success") {
      return <Spinner />;
    }
    const column = this.state.sortByColumn;
    const dirn = this.state.direction;
    const { header } = this.props;
    return (
      <React.Fragment>
        <Header> {T("Venues list")} </Header>
        <Table sortable compact celled padded stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === "pl_name" ? this.getDirection(dirn) : null}
                onClick={() => this.sortVenuesByColumnName("pl_name")}
              >
                {T("Venue Name")}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  column === "pl_address" ? this.getDirection(dirn) : null
                }
                onClick={() => this.sortVenuesByColumnName("pl_address")}
              >
                {T("Address")}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "pl_city" ? this.getDirection(dirn) : null}
                onClick={() => this.sortVenuesByColumnName("pl_city")}
              >
                {T("City")}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "pl_zip" ? this.getDirection(dirn) : null}
                onClick={() => this.sortVenuesByColumnName("pl_zip")}
              >
                {T("Zip")}
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderVenues()}</Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="5">
                <Link to="/app/venues/add">
                  <Button
                    icon="plus"
                    color="blue"
                    primary
                    content={T("Add Venue")}
                  />
                </Link>
                <Menu floated="right" pagination>
                  <Pagination
                    onPageChange={this.handlePaginationChange}
                    defaultActivePage={
                      header && header.current_page ? header.current_page : 1
                    }
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={
                      header && header.total_pages ? header.total_pages : 1
                    }
                  />
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
          <Confirm
            header={T("Delete Venue")}
            content={T("Are you sure you want to delete the venue?")}
            confirmButton={T("Delete")}
            cancelButton={T("Cancel")}
            open={this.state.openDeleteConfirmation}
            onCancel={this.oncancelDeleteConfirmation}
            onConfirm={this.onConfirmDelete}
            size="tiny"
          />
        </Table>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ venues, workspace }) {
  return {
    venues,
    header: venues.header,
    status: venues.fetchAll,
    deleteStatus: venues.operationState.delete,
    workspace,
  };
}

export default connect(mapStateToProps, {
  fetchVenues,
  deleteVenue,
  resetDeleteVenue,
})(withRouter(VenueList));

import _ from 'lodash';
import {
  // this reducer should be splitted in to three reducers: shop, product & pricelist

  FETCH_SHOPS_WAITING,
  FETCH_SHOPS_SUCCESS,
  FETCH_SHOPS_ERROR,
  FETCH_SHOPS_RESET,
  FETCH_APP_SHOPS_WAITING,
  FETCH_APP_SHOPS_SUCCESS,
  FETCH_APP_SHOPS_ERROR,
  FETCH_APP_SHOPS_RESET,
  FETCH_SHOP_WAITING,
  FETCH_SHOP_SUCCESS,
  FETCH_SHOP_ERROR,
  FETCH_SHOP_RESET,
  FETCH_SALES_WAITING,
  FETCH_SALES_SUCCESS,
  FETCH_SALES_ERROR,
  FETCH_SALES_RESET,
  ADD_SHOP_WAITING,
  ADD_SHOP_SUCCESS,
  ADD_SHOP_ERROR,
  ADD_SHOP_RESET,
  EDIT_SHOP_WAITING,
  EDIT_SHOP_SUCCESS,
  EDIT_SHOP_ERROR,
  EDIT_SHOP_RESET,
  DELETE_SHOP_WAITING,
  DELETE_SHOP_SUCCESS,
  DELETE_SHOP_ERROR,
  DELETE_SHOP_RESET,
  EDIT_SHOP_SETTINGS_WAITING,
  EDIT_SHOP_SETTINGS_SUCCESS,
  EDIT_SHOP_SETTINGS_ERROR,
  EDIT_SHOP_SETTINGS_RESET,
  FETCH_SHOP_SETTINGS_WAITING,
  FETCH_SHOP_SETTINGS_SUCCESS,
  FETCH_SHOP_SETTINGS_ERROR,
  FETCH_SHOP_SETTINGS_RESET,
  FETCH_SHOP_SETTINGS_KEYWORDS_WAITING,
  FETCH_SHOP_SETTINGS_KEYWORDS_SUCCESS,
  FETCH_SHOP_SETTINGS_KEYWORDS_ERROR,
  FETCH_SHOP_SETTINGS_KEYWORDS_RESET,
  SHOP_CONFIG_LIST_KEYWORKDS_WAITING,
  SHOP_CONFIG_LIST_KEYWORKDS_SUCCESS,
  SHOP_CONFIG_LIST_KEYWORKDS_ERROR,
  SHOP_CONFIG_LIST_KEYWORKDS_RESET,
  SHOP_CONFIG_MANAGE_WAITING,
  SHOP_CONFIG_MANAGE_SUCCESS,
  SHOP_CONFIG_MANAGE_ERROR,
  SHOP_CONFIG_MANAGE_RESET,
  SHOP_CONFIG_LIST_WAITING,
  SHOP_CONFIG_LIST_SUCCESS,
  SHOP_CONFIG_LIST_ERROR,
  SHOP_CONFIG_LIST_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  editShopNameStatus: 'init',
  shopsFetchStatus: 'init',
  shopsAppFetch: 'init',
  configKeysStatus: 'init',
  configStatus: 'init',
  configListStatus: 'init',
  salesFetchStatus: 'init',
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_SHOPS_WAITING:
      return { ...state, shops: null, shopsFetchStatus: 'waiting' };
    case FETCH_SHOPS_SUCCESS: {
      const shops = action.noLodash
        ? action.payload.data
        : _.mapKeys(action.payload.data, 'shop_id');
      return {
        ...state,
        shops,
        shopsFetchStatus: 'success',
        headers: action.payload.headers,
      };
    }
    case FETCH_SHOPS_ERROR:
      return {
        ...state,
        shops: null,
        error: action.payload.error,
        shopsFetchStatus: 'error',
      };
    case FETCH_SHOPS_RESET:
      return { ...state, shops: null, shopsFetchStatus: 'init' };

    case FETCH_APP_SHOPS_WAITING:
      return { ...state, shopsAppFetch: 'waiting' };
    case FETCH_APP_SHOPS_SUCCESS: {
      const appShops = _.mapKeys(action.payload.data, 'shop_id');
      return { ...state, shopsAppFetch: 'success', appShops };
    }
    case FETCH_APP_SHOPS_ERROR:
      return {
        ...state,
        appShops: [],
        error: action.payload.error,
        shopsAppFetch: 'error',
      };
    case FETCH_APP_SHOPS_RESET:
      return { ...state, shopsAppFetch: 'init' };

    case FETCH_SHOP_WAITING:
      return { ...state, shop: null, shopFetchStatus: 'waiting' };
    case FETCH_SHOP_SUCCESS: {
      const shop = action.payload.data;
      return { ...state, shop, shopFetchStatus: 'success' };
    }
    case FETCH_SHOP_ERROR:
      return {
        ...state,
        shop: null,
        error: action.payload.error,
        shopFetchStatus: 'error',
      };
    case FETCH_SHOP_RESET:
      return { ...state, shop: null, shopFetchStatus: 'init' };

    case ADD_SHOP_WAITING:
      return { ...state, shop: null, shopAddStatus: 'waiting' };
    case ADD_SHOP_SUCCESS: {
      const shop = { ...action.payload.data };
      const shops = { ...state.shops, [shop.shop_id]: shop };
      return { ...state, shops, shop, shopAddStatus: 'success' };
    }
    case ADD_SHOP_ERROR:
      return {
        ...state,
        shop: null,
        error: action.payload.error,
        shopAddStatus: 'error',
      };
    case ADD_SHOP_RESET:
      return { ...state, shop: null, shopAddStatus: 'init' };

    case EDIT_SHOP_WAITING:
      return { ...state, shop: null, shopEditStatus: 'waiting' };
    case EDIT_SHOP_SUCCESS: {
      // sender -> if edit caused by save button click or open/close now.
      const shop = { ...action.payload.response.data };
      const shops = { ...state.shops, [shop.shop_id]: shop };
      return {
        ...state,
        shops,
        shop,
        shopEditStatus: 'success',
        sender: action.payload.sender,
      };
    }
    case EDIT_SHOP_ERROR:
      return {
        ...state,
        shop: null,
        error: action.payload.error,
        shopEditStatus: 'error',
      };
    case EDIT_SHOP_RESET:
      return { ...state, shop: null, shopEditStatus: 'init' };

    case DELETE_SHOP_WAITING:
      return { ...state, shop: null, shopDeleteStatus: 'waiting' };
    case DELETE_SHOP_SUCCESS: {
      const shops = _.omit({ ...state.shops }, action.payload.shopId);
      return { ...state, shops, shop: null, shopDeleteStatus: 'success' };
    }
    case DELETE_SHOP_ERROR:
      return {
        ...state,
        shop: null,
        error: action.payload.error,
        shopDeleteStatus: 'error',
      };
    case DELETE_SHOP_RESET:
      return { ...state, shop: null, shopDeleteStatus: 'init' };

    case EDIT_SHOP_SETTINGS_WAITING:
      return { ...state, editShopSettingsStatus: 'waiting' };
    case EDIT_SHOP_SETTINGS_SUCCESS: {
      const shopSettings = {
        ...state.shopSettings,
        [action.payload.data.bc_keyword]: action.payload.data,
      };
      return { ...state, shopSettings, editShopSettingsStatus: 'success' };
    }
    case EDIT_SHOP_SETTINGS_ERROR:
      return {
        ...state,
        editShopSettingsStatus: 'error',
        error: action.payload.error,
      };
    case EDIT_SHOP_SETTINGS_RESET:
      return { ...state, editShopSettingsStatus: 'init' };

    case FETCH_SHOP_SETTINGS_WAITING:
      return { ...state, fetchSettingsStatus: 'waiting' };
    case FETCH_SHOP_SETTINGS_SUCCESS: {
      const shopSettings = _.mapKeys(action.payload.data, 'bc_keyword');
      return { ...state, shopSettings, fetchSettingsStatus: 'success' };
    }
    case FETCH_SHOP_SETTINGS_ERROR:
      return {
        ...state,
        fetchSettingsStatus: 'error',
        error: action.payload.error,
      };
    case FETCH_SHOP_SETTINGS_RESET:
      return { ...state, fetchSettingsStatus: 'init' };

    case FETCH_SHOP_SETTINGS_KEYWORDS_WAITING:
      return { ...state, fetchKeywordsStatus: 'waiting' };
    case FETCH_SHOP_SETTINGS_KEYWORDS_SUCCESS: {
      const shopKeywords = _.mapKeys(action.payload.data, 'dk_key');
      return { ...state, shopKeywords, fetchKeywordsStatus: 'success' };
    }
    case FETCH_SHOP_SETTINGS_KEYWORDS_ERROR:
      return {
        ...state,
        fetchKeywordsStatus: 'error',
        error: action.payload.error,
      };
    case FETCH_SHOP_SETTINGS_KEYWORDS_RESET:
      return { ...state, fetchKeywordsStatus: 'init' };

    case SHOP_CONFIG_LIST_KEYWORKDS_WAITING:
      return { ...state, configKeysStatus: 'waiting' };
    case SHOP_CONFIG_LIST_KEYWORKDS_SUCCESS:
      return {
        ...state,
        configKeysStatus: 'success',
        configKeys: action.payload.data.items,
      };
    case SHOP_CONFIG_LIST_KEYWORKDS_ERROR:
      return {
        ...state,
        configKeysStatus: 'error',
        error: action.payload.error,
      };
    case SHOP_CONFIG_LIST_KEYWORKDS_RESET:
      return { ...state, configKeysStatus: 'init' };

    case SHOP_CONFIG_MANAGE_WAITING:
      return { ...state, configStatus: 'waiting' };
    case SHOP_CONFIG_MANAGE_SUCCESS:
      return { ...state, configStatus: 'success', config: action.payload.data };
    case SHOP_CONFIG_MANAGE_ERROR:
      return { ...state, configStatus: 'error', error: action.payload.error };
    case SHOP_CONFIG_MANAGE_RESET:
      return { ...state, configStatus: 'init' };

    case SHOP_CONFIG_LIST_WAITING:
      return { ...state, configListStatus: 'waiting' };
    case SHOP_CONFIG_LIST_SUCCESS: {
      const configList = _.mapKeys(action.payload.data.items, 'sc_keyword');
      return { ...state, configListStatus: 'success', configList };
    }
    case SHOP_CONFIG_LIST_ERROR:
      return {
        ...state,
        configListStatus: 'error',
        error: action.payload.eror,
      };
    case SHOP_CONFIG_LIST_RESET:
      return { ...state, configListStatus: 'init' };

    /*case UPDATE_SHOP_IMAGE:
    return { ...state, image_object: action.payload.data };*/

    default:
      return state;
  }
}

export const CREATE_SERIES_WAITING = "create_series_waiting";
export const CREATE_SERIES_SUCCESS = "create_series_success";
export const CREATE_SERIES_ERROR = "create_series_error";

export const FETCH_SERIES_WAITING = "fetch-series-waiting";
export const FETCH_SERIES_SUCCESS = "fetch-series-success";
export const FETCH_SERIES_RESET = "fetch-series-reset";
export const FETCH_SERIES_ERROR = "fetch-series-error";

export const EDIT_SERIES_WAITING = "edit_series_waiting";
export const EDIT_SERIES_SUCCESS = "edit_series_success";
export const EDIT_SERIES_ERROR = "edit_series_error";

export const DELETE_SERIES_WAITING = "delete_series_waiting";
export const DELETE_SERIES_SUCCESS = "delete_series_success";
export const DELETE_SERIES_ERROR = "delete_series_error";

export const CREATE_SEASON_WAITING = "create_season_waiting";
export const CREATE_SEASON_SUCCESS = "create_season_success";
export const CREATE_SEASON_ERROR = "create_season_error";

export const FETCH_SEASONS_WAITING = "fetch_seasons_waiting";
export const FETCH_SEASONS_SUCCESS = "fetch_seasons_success";
export const FETCH_SEASONS_RESET = "fetch_seasons_reset";
export const FETCH_SEASONS_ERROR = "fetch_seasons_error";

export const EDIT_SEASON_WAITING = "edit_season_waiting";
export const EDIT_SEASON_SUCCESS = "edit_season_success";
export const EDIT_SEASON_ERROR = "edit_season_error";

export const DELETE_SEASON_WAITING = "delete_season_waiting";
export const DELETE_SEASON_SUCCESS = "delete_season_success";
export const DELETE_SEASON_ERROR = "delete_season_error";

export const UPDATE_PARENTS = "update_parents";

import axios from 'axios';
import FileSaver from 'file-saver';
import config from '../../Config/AEConfig';

import {
  FETCH_CARD_INSTANCES_WAITING,
  FETCH_CARD_INSTANCES_SUCCESS,
  FETCH_CARD_INSTANCES_ERROR,
  FETCH_CARD_INSTANCES_RESET,
  FETCH_CARD_INSTANCE_WAITING,
  FETCH_CARD_INSTANCE_SUCCESS,
  FETCH_CARD_INSTANCE_ERROR,
  ADD_CARD_INSTANCE_WAITING,
  ADD_CARD_INSTANCE_SUCCESS,
  ADD_CARD_INSTANCE_ERROR,
  ADD_CARD_INSTANCE_RESET,
  EDIT_CARD_INSTANCE_WAITING,
  EDIT_CARD_INSTANCE_SUCCESS,
  EDIT_CARD_INSTANCE_ERROR,
  EDIT_CARD_INSTANCE_RESET,
  DELETE_CARD_INSTANCE_WAITING,
  DELETE_CARD_INSTANCE_SUCCESS,
  DELETE_CARD_INSTANCE_ERROR,
  DELETE_CARD_INSTANCE_RESET,
  FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_WAITING,
  FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_SUCCESS,
  FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_ERROR,
  FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_RESET,
} from '../Types';

import ErrorAction from './ErrorAction';

//const MODULE_ID = 37;

export const fetchCardInstances =
  ({ moduleId = 15, authKey, ws_id, application_id, cardType, searchterm }) =>
  dispatch => {
    dispatch({ type: FETCH_CARD_INSTANCES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url = `${config.BASE_URL}/cardinstances?module_id=${moduleId}&ws_id=${ws_id}&application_id=${application_id}`;
    if (searchterm) {
      url += `&searchterm=${searchterm}`;
    }

    if (cardType) {
      url += `&cd_type=${cardType}`;
    }
    axios
      .get(url)
      .then(response => {
        // console.log('actions fetch success');

        dispatch({ type: FETCH_CARD_INSTANCES_SUCCESS, payload: response });
      })
      .catch(error => {
        // console.log(error);
        const payloadType = error.response
          ? ErrorAction(error.response.status, FETCH_CARD_INSTANCES_ERROR)
          : FETCH_CARD_INSTANCES_ERROR;
        dispatch({ type: payloadType, payload: error });
      });
  };

export const fetchCardInstance =
  ({ moduleId = 15, authKey, ws_id, application_id, card_instance_id }) =>
  dispatch => {
    dispatch({ type: FETCH_CARD_INSTANCE_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/cardinstances/${card_instance_id}?module_id=${moduleId}&ws_id=${ws_id}&application_id=${application_id}`;

    axios
      .get(url)
      .then(response => {
        dispatch({ type: FETCH_CARD_INSTANCE_SUCCESS, payload: response });
      })
      .catch(error => {
        const payloadType = error.response
          ? ErrorAction(error.response.status, FETCH_CARD_INSTANCE_ERROR)
          : FETCH_CARD_INSTANCE_ERROR;
        dispatch({ type: payloadType, payload: error });
      });
  };

export const addCardInstance =
  ({ moduleId = 15, formData, authKey, ws_id, application_id }) =>
  dispatch => {
    dispatch({ type: ADD_CARD_INSTANCE_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/cardinstances?module_id=${moduleId}&ws_id=${ws_id}&application_id=${application_id}`;
    axios
      .post(url, formData)
      .then(response =>
        dispatch({ type: ADD_CARD_INSTANCE_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_CARD_INSTANCE_ERROR)
          : ADD_CARD_INSTANCE_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const editCardInstance =
  ({
    moduleId = 15,
    formData,
    authKey,
    ws_id,
    application_id,
    card_instance_id,
  }) =>
  dispatch => {
    dispatch({ type: EDIT_CARD_INSTANCE_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/cardinstances/${card_instance_id}?module_id=${moduleId}&ws_id=${ws_id}&application_id=${application_id}`;
    axios
      .put(url, formData)
      .then(response =>
        dispatch({ type: EDIT_CARD_INSTANCE_SUCCESS, payload: response })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_CARD_INSTANCE_ERROR)
          : EDIT_CARD_INSTANCE_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const deleteCardInstance =
  ({ moduleId = 15, authKey, ws_id, application_id, card_instance_id }) =>
  dispatch => {
    dispatch({ type: DELETE_CARD_INSTANCE_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/cardinstances/${card_instance_id}?module_id=${moduleId}&ws_id=${ws_id}&application_id=${application_id}`;
    axios
      .delete(url)
      .then(response =>
        dispatch({
          type: DELETE_CARD_INSTANCE_SUCCESS,
          payload: response,
          cardInstanceId: card_instance_id,
        })
      )
      .catch(error => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_CARD_INSTANCE_ERROR)
          : DELETE_CARD_INSTANCE_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const downloadCardInstancesReport =
  (
    moduleId = 15,
    authKey,
    wsId,
    applicationId,
    cardType,
    reportType,
    searchTerm
  ) =>
  dispatch => {
    dispatch({ type: FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url = `${config.BASE_URL}/cardinstances?module_id=${moduleId}&ws_id=${wsId}&application_id=${applicationId}&report_type=${reportType}`;
    if (cardType) {
      url += `&cd_type=${cardType}`;
    }

    if (searchTerm) {
      url += `&searchterm=${searchTerm}`;
    }

    axios
      .get(url, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        let fileName;
        try {
          fileName = response.headers['content-disposition']
            .split(';')[1]
            .split('=')[1];
        } catch {
          fileName = `card_instance_${new Date().getTime()}.${reportType}`;
        }
        FileSaver(blob, fileName);
        dispatch({
          type: FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_SUCCESS,
          payload: response,
        });
      })
      .catch(error => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_ERROR
            )
          : FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetFetchCardInstances = () => ({
  type: FETCH_CARD_INSTANCES_RESET,
});
export const resetAddCardInstance = () => ({ type: ADD_CARD_INSTANCE_RESET });
export const resetEditCardInstance = () => ({ type: EDIT_CARD_INSTANCE_RESET });
export const resetDeleteCardInstance = () => ({
  type: DELETE_CARD_INSTANCE_RESET,
});
export const resetCardInstanceReportDownload = () => ({
  type: FETCH_CARD_INSTANCES_REPORT_DOWNLOAD_RESET,
});

export const FETCH_NOTIFICATIONS_WAITING = "fetch_notifications_waiting";
export const FETCH_NOTIFICATIONS_SUCCESS = "fetch-notifications-success";
export const FETCH_NOTIFICATIONS_ERROR = "fetch-notifications-error";
export const FETCH_NOTIFICATIONS_RESET = "fetch-notifications-reset";

export const FETCH_NOTIFICATION_WAITING = "fetch-notification-waiting";
export const FETCH_NOTIFICATION_SUCCESS = "fetch-notification-success";
export const FETCH_NOTIFICATION_ERROR = "fetch-notification-error";
export const FETCH_NOTIFICATION_RESET = "fetch-notification-reset";

export const ADD_NOTIFICATION_WAITING = "add-notification-waiting";
export const ADD_NOTIFICATION_SUCCESS = "add-notification-success";
export const ADD_NOTIFICATION_ERROR = "add-notification-error";
export const ADD_NOTIFICATION_RESET = "add-notification-reset";

export const EDIT_NOTIFICATION_WAITING = "edit-notification-waiting";
export const EDIT_NOTIFICATION_SUCCESS = "edit-notification-success";
export const EDIT_NOTIFICATION_ERROR = "edit-notification-error";
export const EDIT_NOTIFICATION_RESET = "edit-notification-reset";

export const DELETE_NOTIFICATION_WAITING = "delete-notification-waiting";
export const DELETE_NOTIFICATION_SUCCESS = "delete-notification-success";
export const DELETE_NOTIFICATION_ERROR = "delete-notification-error";
export const DELETE_NOTIFICATION_RESET = "delete-notification-reset";

export const FETCH_NOTIFICATION_CATEGORIES_WAITING =
  "fetch-notification-categories-waiting";
export const FETCH_NOTIFICATION_CATEGORIES_SUCCESS =
  "fetch-notification-categories-success";
export const FETCH_NOTIFICATION_CATEGORIES_ERROR =
  "fetch-notification-categories-error";
export const FETCH_NOTIFICATION_CATEGORIES_RESET =
  "fetch-notification-categories-reset";

export const ADD_NOTIFICATION_CATEGORY_WAITING =
  "add-notification-category-waiting";
export const ADD_NOTIFICATION_CATEGORY_SUCCESS =
  "add-notification-category-success";
export const ADD_NOTIFICATION_CATEGORY_ERROR =
  "add_notification_category_error";
export const ADD_NOTIFICATION_CATEGORY_RESET =
  "add-notification-category-reset";

export const EDIT_NOTIFICATION_CATEGORY_WAITING =
  "edit-notification-category-waiting";
export const EDIT_NOTIFICATION_CATEGORY_SUCCESS =
  "edit-notification-category-success";
export const EDIT_NOTIFICATION_CATEGORY_ERROR =
  "edit_notification_category_error";
export const EDIT_NOTIFICATION_CATEGORY_RESET =
  "edit-notification-category-reset";

export const DELETE_NOTIFICATION_CATEGORY_WAITING =
  "delete-notification-category-waiting";
export const DELETE_NOTIFICATION_CATEGORY_SUCCESS =
  "delete-notification-category-success";
export const DELETE_NOTIFICATION_CATEGORY_ERROR =
  "delete_notification_category_error";
export const DELETE_NOTIFICATION_CATEGORY_RESET =
  "delete-notification-category-reset";

export const FETCH_NOTIFICATION_CATEGORY_TYPES_WAITING =
  "fetch_notirication_category_types-waiting";
export const FETCH_NOTIFICATION_CATEGORY_TYPES_SUCCESS =
  "fetch_notirication_category_types-success";
export const FETCH_NOTIFICATION_CATEGORY_TYPES_ERROR =
  "fetch_notirication_category_types-error";

import {
  FETCH_LANGUAGES_WAITING,
  FETCH_LANGUAGES_SUCCESS,
  FETCH_LANGUAGES_ERROR,
  FETCH_LANGUAGES_RESET,
  SET_PRIMARY_LANGUAGE_SUCCESS,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  status: 'init',
  selectStatus: 'init',
};
// Fetch languages, only called once to avoid cyclic component updates
export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_LANGUAGES_WAITING:
      return { ...state, status: 'waiting' };
    case FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.payload.data.items,
        status: 'success',
      };
    case FETCH_LANGUAGES_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    case FETCH_LANGUAGES_RESET:
      return { ...state, status: 'init' };
    case SET_PRIMARY_LANGUAGE_SUCCESS:
      return {
        ...state,
        primaryLanguage: action.payload,
        selectStatus: 'success',
      };
    default:
      return state;
  }
}

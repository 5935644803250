import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import {
  fetchDefaultTicketSettings,
  fetchCurrencies,
  fetchPriceLists,
  fetchTaxes,
  fetchCards,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';
import DefaultTicketSettingsList from './DefaultTicketSettingsList';
import { Spinner } from '../Common/Spinner';

const MODULE_ID = 32;
const ALL_ITEMS = true;
const CARD_TYPE = 'membercard';

class DefaultTicketSettingsContainer extends Component {
  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.ws_id;
    const appId = this.props.workspace.workspaces[ws_id].cnc_application;
    const merchantId = this.props.workspace.workspaces[ws_id].cnc_company;
    const all_items = ALL_ITEMS;

    this.props.fetchDefaultTicketSettings(
      authKey,
      appId,
      merchantId,
      ws_id,
      all_items
    );
    this.props.fetchPriceLists({ authKey, ws_id, module_id: MODULE_ID });
    this.props.fetchTaxes({ authKey, company_id: merchantId, ws_id });
    this.props.fetchCards(MODULE_ID, authKey, ws_id, appId, CARD_TYPE, false);
    if (this.props.currencies.status !== 'success') {
      this.props.fetchCurrencies({ authKey, ws_id });
    }
  }
  componentDidUpdate(prevState) {
    if (
      prevState.deleteStatus !== 'success' &&
      this.props.deleteStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Default Ticket deleted successfully'),
        time: 5000,
      });
    } else if (
      prevState.deleteStatus !== 'error' &&
      this.props.deleteStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.error,
        'Error, deleting default ticket'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  submitDefaultTickets = () => {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.ws_id;
    this.props.addDefaultTicketSettings(authKey, { ...this.state }, ws_id);
  };
  render() {
    //console.log('Props: ', this.props);
    if (
      this.props.fetchAll === 'waiting' ||
      this.props.currencies.status === 'waiting' ||
      this.props.pricelist.plStatus === 'waiting' ||
      this.props.tax.fetchStatus === 'waiting'
    ) {
      return <Spinner />;
    } else if (
      this.props.fetchAll === 'error' ||
      this.props.currencies.status === 'error' ||
      this.props.pricelist.plStatus === 'error' ||
      this.props.tax.fetchStatus === 'error'
    ) {
      return (
        <Message error>
          <Message.Header>{T('Error')}</Message.Header>
          <p>{T('Error has occured, while fetching data')}</p>
        </Message>
      );
    }
    //console.log('Container Props: ', this.props);

    return (
      <DefaultTicketSettingsList
        history={this.props.history}
        defaultSettings={this.props.defaultSettings}
        languages={this.props.languages}
        pricelists={this.props.pricelist.pricelists}
        currencies={this.props.currencies.data}
        taxList={this.props.tax.data}
        cards={this.props.cards}
      />
    );
  }
}

function mapStateToProps({
  workspace,
  defaultTickets,
  languages,
  pricelist,
  currencies,
  tax,
  card,
}) {
  return {
    workspace,
    fetchAll: defaultTickets.opStatus.fetchAll,
    defaultSettings: defaultTickets.settings,
    languages: languages.languages,
    currencies,
    pricelist,
    tax,
    cards: card.data,
    deleteStatus: defaultTickets.opStatus.delete,
    error: defaultTickets.error,
  };
}
export default connect(mapStateToProps, {
  fetchDefaultTicketSettings,
  fetchCurrencies,
  fetchPriceLists,
  fetchTaxes,
  fetchCards,
})(DefaultTicketSettingsContainer);

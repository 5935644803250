import React, { Component } from 'react';
import { Table, Button, Confirm } from 'semantic-ui-react';
import _ from 'lodash';
import { T } from '../Common/Helpers';

class PlayerMetadataList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDeleteConfirmation: false,
      metadataId: null,
    };
    this.handleMetadataDelete = this.handleMetadataDelete.bind(this);
    this.handleMetadataEdit = this.handleMetadataEdit.bind(this);
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }
  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }
  onConfirmDelete() {
    this.props.handleMetadataDelete(this.state.metadataId);
    this.setState({ openDeleteConfirmation: false });
  }
  getMetaDataDescription(key) {
    const find = this.props.metadataKeys.filter(dk => dk.dk_key === key);
    return find.length > 0 ? find[0].dk_description : key;
  }
  handleMetadataEdit(event, { value }) {
    this.props.handleMetadataEdit(value);
  }
  handleMetadataDelete(event, { value }) {
    this.setState({ openDeleteConfirmation: true, metadataId: value });
  }
  renderPlayerMetaData(metadata) {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <h4>{T('Attribute name')}</h4>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <h4>{T('value')}</h4>
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(
            metadata,
            data =>
              data.pm_key && (
                <Table.Row key={data.pm_key}>
                  {/* {console.log('data: ', data) } */}
                  <Table.Cell key={`col-${data.pm_key}`}>
                    <strong>{this.getMetaDataDescription(data.pm_key)} </strong>
                  </Table.Cell>
                  <Table.Cell key={`val-${data.pm_value}`}>
                    <span style={{ color: 'steelblue' }}>{data.pm_value}</span>
                  </Table.Cell>
                  <Table.Cell key={`btn-${data.pm_key}`}>
                    <Button
                      name="Edit"
                      color="blue"
                      key={`btn-edit-${data.pm_key}`}
                      size="mini"
                      value={data.pm_key}
                      icon="edit"
                      onClick={this.handleMetadataEdit}
                    />
                    <Button
                      name="delete"
                      key={`btn-delete-${data.pm_key}`}
                      size="mini"
                      color="red"
                      value={data.performer_metadata_id}
                      icon="trash"
                      onClick={this.handleMetadataDelete}
                    />
                    <Confirm
                      header={T('Delete Attribute')}
                      content={T(
                        'Are you sure you want to delete the attribute?'
                      )}
                      confirmButton={T('Delete')}
                      open={this.state.openDeleteConfirmation}
                      onCancel={this.oncancelDeleteConfirmation}
                      onConfirm={this.onConfirmDelete}
                      size="tiny"
                    />
                  </Table.Cell>
                </Table.Row>
              )
          )}
        </Table.Body>
      </Table>
    );
  }
  render() {
    return this.renderPlayerMetaData(this.props.performerMetadata);
  }
}

export default PlayerMetadataList;

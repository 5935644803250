import {
  RESET_SUCCESS,
  RESET_ERROR,
  RESET_WAITING,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = { status: 'init', res: null };

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case RESET_SUCCESS:
      return { ...state, res: action.payload.data, status: 'success' };
    case RESET_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    case RESET_WAITING:
      return { ...state, status: 'waiting' };
    default:
      return state;
  }
}

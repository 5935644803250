import {
  FETCH_NODE_COLLECTIONS_WAITING,
  FETCH_NODE_COLLECTIONS_SUCCESS,
  FETCH_NODE_COLLECTIONS_ERROR,
  FETCH_NODE_COLLECTIONS_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = { fetchAll: 'init' };
export default function nodeCollectionsReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_NODE_COLLECTIONS_WAITING:
      return { ...state, fetchAll: 'waiting' };
    case FETCH_NODE_COLLECTIONS_SUCCESS:
      return { ...state, fetchAll: 'success', nodes: action.payload.data };
    case FETCH_NODE_COLLECTIONS_ERROR:
      return { ...state, fetchAll: 'error', error: action.payload.error };
    case FETCH_NODE_COLLECTIONS_RESET:
      return { ...state, fetchAll: 'init' };
    default:
      return state;
  }
}

export const FETCH_LOCATIONS_WAITING = "fetch-locations-waiting";
export const FETCH_LOCATIONS_SUCCESS = "fetch-locations-success";
export const FETCH_LOCATIONS_ERROR = "fetch-locations-error";
export const FETCH_LOCATIONS_RESET = "fetch-locations-reset";

export const FETCH_LOCATION_WAITING = "fetch-location-waiting";
export const FETCH_LOCATION_SUCCESS = "fetch-location-success";
export const FETCH_LOCATION_ERROR = "fetch-location-error";
export const FETCH_LOCATION_RESET = "fetch-location-reset";

export const EDIT_LOCATION_WAITING = "edit-location-waiting";
export const EDIT_LOCATION_SUCCESS = "edit-location-success";
export const EDIT_LOCATION_ERROR = "edit-location-error";
export const EDIT_LOCATION_RESET = "edit-location-reset";

export const ADD_LOCATION_WAITING = "add-location-waiting";
export const ADD_LOCATION_SUCCESS = "add-location-success";
export const ADD_LOCATION_ERROR = "add-location-error";
export const ADD_LOCATION_RESET = "add-location-reset";

export const DELETE_LOCATION_WAITING = "delete-location-waiting";
export const DELETE_LOCATION_SUCCESS = "delete-location-success";
export const DELETE_LOCATION_ERROR = "delete-location-error";
export const DELETE_LOCATION_RESET = "delete-location-reset";

export const SELECTED_EDIT_VENUE_LOCATION = "selected-edit-venue-location";
export const SELECTED_ADD_VENUE_LOCATION = "selected-add-venue-location";
export const SELECTED_EDIT_SUB_VENUE_LOCATION =
  "selected-edit-sub-venue-location";
export const SELECTED_ADD_SUB_VENUE_LOCATION =
  "selected-add-sub-venue-location";

export const RESET_SELECTED_EDIT_VENUE_LOCATION =
  "reset-selected-edit-venue-location";
export const RESET_SELECTED_ADD_VENUE_LOCATION =
  "reset-selected-add-venue-location";
export const RESET_SELECTED_EDIT_SUB_VENUE_LOCATION =
  "reset-selected-edit-sub-venue-location";
export const RESET_SELECTED_ADD_SUB_VENUE_LOCATION =
  "reset-selected-add-sub-venue-location";

export const FETCH_PICKUP_POINTS_WAITING = "fetch-pickup_points-waiting";
export const FETCH_PICKUP_POINTS_SUCCESS = "fetch-pickup_points-success";
export const FETCH_PICKUP_POINTS_ERROR = "fetch-pickup_points-error";
export const FETCH_PICKUP_POINTS_RESET = "fetch-pickup_points-reset";

export const ADD_PICKUP_POINT_WAITING = "add-pickup-point-waiting";
export const ADD_PICKUP_POINT_SUCCESS = "add-pickup-point-success";
export const ADD_PICKUP_POINT_ERROR = "add-pickup-point-error";
export const ADD_PICKUP_POINT_RESET = "add-pickup-point-reset";

import _ from 'lodash';
import {
  FETCH_SALES_REPORT_WAITING,
  FETCH_SALES_REPORT_SUCCESS,
  FETCH_SALES_REPORT_ERROR,
  FETCH_SALES_REPORT_RESET,
  FETCH_SALES_REPORT_DOWNLOAD_WAITING,
  FETCH_SALES_REPORT_DOWNLOAD_SUCCESS,
  FETCH_SALES_REPORT_DOWNLOAD_ERROR,
  FETCH_SALES_REPORT_DOWNLOAD_RESET,
  FETCH_SOLD_ITEMS_REPORT_WAITING,
  FETCH_SOLD_ITEMS_REPORT_SUCCESS,
  FETCH_SOLD_ITEMS_REPORT_ERROR,
  FETCH_SOLD_ITEMS_REPORT_RESET,
  FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_WAITING,
  FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_SUCCESS,
  FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_ERROR,
  FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  status: {
    sales: 'init',
    soldItems: 'init',
    salesDownload: 'init',
    soldItemsDownload: 'init',
  },
  summary: {
    sales: { sales: [], totals: {} },
    soldItems: { sales: [], totals: {} },
  },
  headers: { sales: undefined, soldItems: undefined },
};

const salesReportReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_SALES_REPORT_WAITING: {
      const status = { ...state.status };
      status.sales = 'waiting';
      const summary = _.cloneDeep(state.summary);
      summary.sales.totals = {};
      summary.sales.sales = [];
      return { ...state, status, summary };
    }
    case FETCH_SALES_REPORT_SUCCESS: {
      const status = { ...state.status };
      status.sales = 'success';
      const headers = { ...state.headers };
      headers.sales = action.payload.headers;
      const summary = _.cloneDeep(state.summary);
      summary.sales.totals = action.payload.data.total;
      summary.sales.sales = action.payload.data.items;
      // console.log('Status: ', status, '\nSummary: ', summary);
      return { ...state, status, headers, summary };
    }
    case FETCH_SALES_REPORT_ERROR: {
      const status = { ...state.status };
      status.sales = 'error';
      const summary = _.cloneDeep(state.summary);
      summary.sales.totals = {};
      summary.sales.sales = [];
      return { ...state, status, summary, error: action.payload.error };
    }
    case FETCH_SALES_REPORT_RESET: {
      const status = { ...state.status };
      status.sales = 'init';
      const summary = _.cloneDeep(state.summary);
      summary.sales.totals = {};
      summary.sales.sales = [];
      return { ...state, status, summary, error: undefined };
    }

    case FETCH_SALES_REPORT_DOWNLOAD_WAITING: {
      const status = { ...state.status };
      status.salesDownload = 'waiting';
      return { ...state, status };
    }
    case FETCH_SALES_REPORT_DOWNLOAD_SUCCESS: {
      const status = { ...state.status };
      status.salesDownload = 'success';
      return { ...state, status };
    }
    case FETCH_SALES_REPORT_DOWNLOAD_ERROR: {
      const status = { ...state.status };
      status.salesDownload = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case FETCH_SALES_REPORT_DOWNLOAD_RESET: {
      const status = { ...state.status };
      status.salesDownload = 'init';
      return { ...state, status, error: undefined };
    }

    case FETCH_SOLD_ITEMS_REPORT_WAITING: {
      const status = { ...state.status };
      status.soldItems = 'waiting';
      const summary = _.cloneDeep(state.summary);
      summary.soldItems.totals = {};
      summary.soldItems.sales = [];
      return { ...state, status, summary };
    }
    case FETCH_SOLD_ITEMS_REPORT_SUCCESS: {
      const status = { ...state.status };
      status.soldItems = 'success';
      const headers = { ...state.headers };
      headers.soldItems = action.payload.headers;
      const summary = _.cloneDeep(state.summary);
      summary.soldItems.totals = action.payload.data.total;
      summary.soldItems.sales = action.payload.data.items;
      // console.log('Status: ', status, '\nSummary: ', summary);
      return { ...state, status, headers, summary };
    }
    case FETCH_SOLD_ITEMS_REPORT_ERROR: {
      const status = { ...state.status };
      status.soldItems = 'error';
      const summary = _.cloneDeep(state.summary);
      summary.soldItems.totals = {};
      summary.soldItems.sales = [];
      return { ...state, status, summary, error: action.payload.error };
    }
    case FETCH_SOLD_ITEMS_REPORT_RESET: {
      const status = { ...state.status };
      status.soldItems = 'init';
      const summary = _.cloneDeep(state.summary);
      summary.soldItems.totals = {};
      summary.soldItems.sales = [];
      return { ...state, status, summary, error: undefined };
    }

    case FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_WAITING: {
      const status = { ...state.status };
      status.soldItemsDownload = 'waiting';
      return { ...state, status };
    }
    case FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_SUCCESS: {
      const status = { ...state.status };
      status.soldItemsDownload = 'success';
      return { ...state, status };
    }
    case FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_ERROR: {
      const status = { ...state.status };
      status.soldItemsDownload = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case FETCH_SOLD_ITEMS_REPORT_DOWNLOAD_RESET: {
      const status = { ...state.status };
      status.soldItemsDownload = 'init';
      return { ...state, status, error: undefined };
    }

    default:
      return state;
  }
};

export default salesReportReducer;

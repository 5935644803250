import _ from 'lodash';
import {
  FETCH_PRODUCT_ATTRIBUTES_LIST_WAITING,
  FETCH_PRODUCT_ATTRIBUTES_LIST_SUCCESS,
  FETCH_PRODUCT_ATTRIBUTES_LIST_ERROR,
  FETCH_PRODUCT_ATTRIBUTES_LIST_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  fetchAll: 'init',
};

const productAttributesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_PRODUCT_ATTRIBUTES_LIST_WAITING:
      return { ...state, fetchAll: 'waiting' };
    case FETCH_PRODUCT_ATTRIBUTES_LIST_SUCCESS: {
      const attributes = _.reduce(
        action.payload.data.items,
        (acc, ele) => {
          const tempObj = { ...acc };
          tempObj[ele.att_name] = [...(tempObj[ele.att_name] || []), ele];
          return tempObj;
        },
        {}
      );
      return { ...state, fetchAll: 'success', attributes };
    }
    case FETCH_PRODUCT_ATTRIBUTES_LIST_ERROR:
      return { ...state, fetchAll: 'error', error: action.payload };
    case FETCH_PRODUCT_ATTRIBUTES_LIST_RESET:
      return { ...state, fetchAll: 'init' };
    default:
      return state;
  }
};

export default productAttributesReducer;

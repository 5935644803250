import React, { Component } from 'react';
import _ from 'lodash';
import { Grid, Checkbox, Button, Header, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { T } from '../Common/Helpers';
import {
  addShopToApplication,
  removeShopFromApplication,
} from '../../Redux/actions';

class ManageAppShop extends Component {
  state = { shops: [] };
  handleChange = (id, status) => {
    const shops = this.state.shops.slice();
    if (status) {
      shops.push(id);
    } else {
      const index = shops.indexOf(id);
      if (index !== -1) {
        shops.splice(index, 1);
      }
    }
    this.setState({ shops });
  };
  addShopsToApp = () => {
    if (this.state.shops.length > 0) {
      const data = _.map(this.state.shops, shopId => ({ as_shop: shopId }));
      const authKey = localStorage.getItem('x-auth-key');
      const ws_id = this.props.workspace.ws_id;
      const appId = this.props.workspace.workspaces[ws_id].cnc_application;
      this.props.addShopToApplication({ authKey, appId, data, ws_id });
    }
  };
  renderTobeAddedShops = () =>
    _.map(this.props.allShops, shop => (
      <Table.Row key={shop.shop_id}>
        <Table.Cell> {`${shop.shop_id}: ${shop.sh_name}`} </Table.Cell>
        <Table.Cell>
          <Checkbox
            name="sh_shop"
            label={T('Select Shop')}
            key={shop.shop_id}
            checked={this.state.shops.indexOf(shop.shop_id) !== -1}
            onChange={(e, { checked }) =>
              this.handleChange(shop.shop_id, checked)
            }
          />
        </Table.Cell>
      </Table.Row>
    ));
  render() {
    // console.log('States: ', this.state, '\nProps: ', this.props);
    const isAllShops = Object.keys(this.props.allShops).length > 0;
    const isAppShops = Object.keys(this.props.appShops).length > 0;
    const checkedShops = this.state.shops.length > 0;
    return (
      <Grid stackable>
        <Grid.Row columns="1">
          <Grid.Column>
            <Header> {T('Manage Application Shops')} </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="2">
          <Grid.Column>
            <Header> {T('Shops in the current application')} </Header>
          </Grid.Column>
          <Grid.Column>
            <Header> {T('Shops not in the current application')} </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="2">
          <Grid.Column>
            {isAppShops ? (
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{T('Shop name')}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(this.props.appShops, shop => (
                    <Table.Row key={shop.shop_id}>
                      <Table.Cell>
                        {`${shop.shop_id} : ${shop.sh_name}`}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              T('No shops listed')
            )}
          </Grid.Column>
          <Grid.Column>
            {isAllShops ? (
              <Table striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{T('Shop')}</Table.HeaderCell>
                    <Table.HeaderCell>
                      {T('Select shop to add to App')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderTobeAddedShops()}</Table.Body>
              </Table>
            ) : (
              T('No shop listed')
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="1" textAlign="right">
          <Grid.Column>
            <Button
              disabled={!isAllShops || !checkedShops}
              content={T('Add Shop(s) to App')}
              primary
              onClick={this.addShopsToApp}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
function mapStateToProps({ workspace }) {
  return { workspace };
}
export default connect(mapStateToProps, {
  addShopToApplication,
  removeShopFromApplication,
})(ManageAppShop);

export const FETCH_LOTTERIES_WAITING = "fetch_lotteries_waiting";
export const FETCH_LOTTERIES_SUCCESS = "fetch_lotteries_success";
export const FETCH_LOTTERIES_ERROR = "fetch_lotteries_error";
export const FETCH_LOTTERIES_RESET = "fetch_lotteries_reset";

export const FETCH_LOTTERY_WAITING = "fetch_lottery_waiting";
export const FETCH_LOTTERY_SUCCESS = "fetch_lottery_success";
export const FETCH_LOTTERY_ERROR = "fetch_lottery_error";
export const FETCH_LOTTERY_RESET = "fetch_lottery_reset";

export const ADD_LOTTERY_WAITING = "add_lottery_waiting";
export const ADD_LOTTERY_SUCCESS = "add_lottery_success";
export const ADD_LOTTERY_ERROR = "add_lottery_error";
export const ADD_LOTTERY_RESET = "add_lottery_reset";

export const EDIT_LOTTERY_WAITING = "edit_lottery_waiting";
export const EDIT_LOTTERY_SUCCESS = "edit_lottery_success";
export const EDIT_LOTTERY_ERROR = "edit_lottery_error";
export const EDIT_LOTTERY_RESET = "edit_lottery_reset";

export const DELETE_LOTTERY_WAITING = "delete_lottery_waiting";
export const DELETE_LOTTERY_SUCCESS = "delete_lottery_success";
export const DELETE_LOTTERY_ERROR = "delete_lottery_error";
export const DELETE_LOTTERY_RESET = "delete_lottery_reset";

export const FETCH_LOTTERIES_REPORT_WAITING = "fetch_lotteries_report_waiting";
export const FETCH_LOTTERIES_REPORT_SUCCESS = "fetch_lotteries_report_success";
export const FETCH_LOTTERIES_REPORT_ERROR = "fetch_lotteries_report_error";
export const FETCH_LOTTERIES_REPORT_RESET = "fetch_lotteries_report_reset";

export const DELETE_LOTTERY_PRIZE_WAITING = "delete_lottery_prize_waiting";
export const DELETE_LOTTERY_PRIZE_SUCCESS = "delete_lottery_prize_success";
export const DELETE_LOTTERY_PRIZE_ERROR = "delete_lottery_prize_error";
export const DELETE_LOTTERY_PRIZE_RESET = "delete_lottery_prize_reset";

export const ADD_MORE_PRIZE = "add_more_prize";
export const ADD_MORE_PRIZE_RESET = "add_more_prize_reset";
export const UPDATE_PRIZE_IMAGE = "update_prize_image";
export const RESET_ALL_PRIZES = "reset_all_prizes";
export const EDIT_PRIZES_INIT = "edit_prizes_init";

export const CANCEL_LOTTERY_WAITING = "cancel_lottery_waiting";
export const CANCEL_LOTTERY_SUCCESS = "cancel_lottery_success";
export const CANCEL_LOTTERY_ERROR = "cancel_lottery_error";
export const CANCEL_LOTTERY_RESET = "cancel_lottery_reset";

export const FETCH_LOTTERY_CONFIG_WAITING = "fetch_lottery_config_waiting";
export const FETCH_LOTTERY_CONFIG_SUCCESS = "fetch_lottery_config_success";
export const FETCH_LOTTERY_CONFIG_ERROR = "fetch_lottery_config_error";

export const PREVIEW_LOTTERY_WAITING = "preview_lottery_waiting";
export const PREVIEW_LOTTERY_SUCCESS = "preview_lottery_success";
export const PREVIEW_LOTTERY_ERROR = "preview_lottery_error";

export const ESTIMATION_LOTTERY_WAITING = "estimation_lottery_waiting";
export const ESTIMATION_LOTTERY_SUCCESS = "estimation_lottery_success";
export const ESTIMATION_LOTTERY_ERROR = "estimation_lottery_error";

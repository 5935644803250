/* eslint-env browser */
import React, { Component } from 'react';
import { Confirm, Button, Segment, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Form } from 'formsy-semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import { map, filter } from 'lodash';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
import {
  deleteEvent,
  deleteSeason,
  deleteSeries,
  editSeason,
  editSeries,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';
import EventList from './EventList.js';

//const timezone = jstz.determine().name();
const DATE_TIME_FORMAT = 'l HH:mm';
const TOAST_DELAY = 5000;

class EventEdit extends Component {
  constructor(props) {
    super(props);
    this.setEventState = this.setEventState.bind(this);
    this.getEventData = this.getEventData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.buildCategories = this.buildCategories.bind(this);
    this.getSeriesList = this.getSeriesList.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.state = {
      ...this.getEventData(this.props),
    };
  }
  componentDidMount() {
    // localStorage.setItem('route', this.props.history.location.pathname);
    this.buildCategories();
  }

  componentDidUpdate(prevProps) {
    if (this.props.eventId && prevProps.eventId !== this.props.eventId) {
      this.setEventState(this.props);
    }
    if (
      prevProps.parents.editSeasonStatus !== 'success' &&
      this.props.parents.editSeasonStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Event Edited successfully'),
        time: TOAST_DELAY,
      });
    } else if (
      prevProps.parents.editSeasonStatus !== 'error' &&
      this.props.parents.editSeasonStatus === 'error'
    ) {
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T('Could not edit the event'),
        time: TOAST_DELAY,
      });
    } else if (
      prevProps.parents.editSeriesStatus !== 'success' &&
      this.props.parents.editSeriesStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Event Edited successfully'),
        time: TOAST_DELAY,
      });
    } else if (
      prevProps.parents.editSeriesStatus !== 'error' &&
      this.props.parents.editSeriesStatus === 'error'
    ) {
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T('Could not edit the event'),
        time: TOAST_DELAY,
      });
    } else if (
      prevProps.parents.deleteSeriesStatus !== 'success' &&
      this.props.parents.deleteSeriesStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('The series has been deleted'),
        time: TOAST_DELAY,
      });
      prevProps.history.push('/app/events/season/home');
    } else if (
      prevProps.parents.deleteSeriesStatus !== 'error' &&
      this.props.parents.deleteSeriesStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.parents.error,
        'The series must be empty, before deleting'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: TOAST_DELAY,
      });
    } else if (
      prevProps.parents.deleteSeasonStatus !== 'success' &&
      this.props.parents.deleteSeasonStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('The season has been deleted'),
        time: TOAST_DELAY,
      });
      prevProps.history.push('/app/events/season/home'); //undefined history
    } else if (
      prevProps.parents.deleteSeasonStatus !== 'error' &&
      this.props.parents.deleteSeasonStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.parents.error,
        'The season must be empty, before deleting'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: TOAST_DELAY,
      });
    }
  }

  onDeleteClick() {
    this.setState({ openDeleteConfirmation: true });
  }

  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }

  onConfirmDelete() {
    const authKey = localStorage.getItem('x-auth-key');
    if (this.props.event.ev_type === 'series') {
      this.props.deleteSeries(
        this.props.event.event_id,
        authKey,
        this.props.ws_id
      );
    } else if (this.props.event.ev_type === 'season') {
      this.props.deleteSeason(
        this.props.event.event_id,
        authKey,
        this.props.event.ev_parent,
        this.props.ws_id
      );
    }
    this.setState({ openDeleteConfirmation: false });
  }

  onChange(values) {
    this.setState({ ev_eventcategory: values.ev_eventcategory });
  }

  onSubmit(values) {
    const start = this.state.startTime ? moment(this.state.startTime) : null;
    const stop = this.state.endTime ? moment(this.state.endTime) : null;
    if (start && stop && start.isAfter(stop)) {
      toast({
        type: 'error',
        title: T('Error'),
        description: T('Ending time cannot precede start time!'),
        time: 5000,
      });
      return;
    }
    const inputValues = {
      ...values,
      ev_event_start: this.state.startTime,
      ev_event_stop: this.state.endTime,
    };
    const { event_id } = this.props.event;
    const authKey = localStorage.getItem('x-auth-key');
    const wsId = this.props.workspace.activeWorkspace.id;
    if (this.props.event.ev_type === 'series') {
      this.props.editSeries(event_id, authKey, wsId, inputValues);
    } else {
      this.props.editSeason(event_id, authKey, wsId, inputValues);
    }
  }

  onCancel() {
    this.props.history.push('/app/events/season/home');
  }

  getEventData(props) {
    const {
      ev_event_start,
      ev_event_stop,
      ev_name,
      ev_remote_id,
      ev_eventcategory,
      event_id,
      ev_parent,
    } = props.event;
    return {
      startTime: ev_event_start,
      endTime: ev_event_stop,
      eventName: ev_name,
      remoteId: ev_remote_id,
      categoryId: ev_eventcategory,
      eventId: event_id,
      parentId: ev_parent,
    };
  }

  // getVenue() {
  //   const { ev_place } = this.eventdata;
  //   const find = this.props.venues.venues.venues.find(v => v.place_id === ev_place);
  //   return find ? ev_place : 0;
  // }

  setEventState(props) {
    this.setState(this.getEventData(props));
  }

  getSeriesList() {
    console.log('this.props.series', this.props.series);
    const seriesList = this.props.series
      ? map(this.props.series, item => ({
          text: item.ev_name,
          value: item.event_id,
          ev_type: item.ev_type,
        })).filter(s => s.ev_type === 'series')
      : [];
    // const seriesList = map(this.props.series, item => ({
    //   text: item.ev_name,
    //   value: item.event_id,
    // }));
    // seriesList.unshift({ text: 'series', value: null });
    return seriesList;
  }

  handleChange(event, { name, value }) {
    const start = moment(this.state.startTime);
    const stop = moment(this.state.endTime);
    const dateTime = moment(value, DATE_TIME_FORMAT);
    switch (name) {
      case 'ev_event_start': {
        if (dateTime.isAfter(stop)) {
          console.warn('Wrong end time, \nStart: ', start, '\nStop: ', stop);
          return;
        }
        this.setState({ startTime: dateTime.toISOString() });
        break;
      }
      case 'ev_event_stop': {
        if (dateTime.isBefore(start)) {
          console.warn('Wrong end time, \nStart: ', start, '\nStop: ', stop);
          return;
        }
        this.setState({ endTime: dateTime.toISOString() });
        break;
      }
      default:
        break;
    }
  }

  buildCategories(category_type) {
    const seriesAndSeasonCategories = filter(
      this.props.categories,
      c => c.ec_type === category_type
    );

    return (
      map(seriesAndSeasonCategories, list => ({
        text: T(list.ec_name),
        value: list.eventcategory_id,
      })) || { text: T('No category'), value: null }
    );
  }

  renderGamesForSeason() {
    /*
     *****************************
     *  Renders season specific  *
     *       GameEvents          *
     ***************************** */
    if (this.props.event.ev_type !== 'season') {
      return;
    }
    return (
      <EventList
        {...this.props}
        Parent={this.props.event.event_id}
        parentName={this.state.eventName}
        ws_id={this.props.ws_id}
      />
    );
  }

  render() {
    const seriesList = this.getSeriesList();
    return (
      <>
        <Segment>
          <Label attached="top">
            {this.props.event.ev_type === 'series'
              ? T('Edit Series')
              : T('Edit Season')}
          </Label>
          <Form name="editform" onValidSubmit={this.onSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                label={T('Name')}
                name="ev_name"
                value={this.state.eventName}
                placeholder={T('Name of Event')}
                required
              />
              <Form.Select
                label={T('Category')}
                name="ev_eventcategory"
                options={this.buildCategories(this.props.event.ev_type)}
                onChange={() => this.onChange.bind(this)}
                value={this.state.categoryId}
                required
              />
              {this.props.event.ev_type === 'season' &&
                seriesList.length > 0 && (
                  <Form.Select
                    label={T('Series')}
                    name="ev_parent"
                    options={seriesList}
                    value={this.state.parentId}
                  />
                )}
              {false && (
                <Form.Input
                  label={T('Remote Id')}
                  name="ev_remote_id"
                  value={this.state.remoteId}
                  disabled
                />
              )}
            </Form.Group>
            <Form.Group widths="equal">
              <DateTimeInput
                style={{ minWidth: '400px' }}
                closable
                clearable
                name="ev_event_start"
                label={T('Begins')}
                value={
                  this.state.startTime
                    ? moment(this.state.startTime).format(DATE_TIME_FORMAT)
                    : ''
                }
                onChange={this.handleChange}
                dateTimeFormat={DATE_TIME_FORMAT}
                autoComplete="off"
                history="off"
                maxDate={moment().format(DATE_TIME_FORMAT)}
              />
              <DateTimeInput
                style={{ minWidth: '200px' }}
                closable
                clearable
                name="ev_event_stop"
                label={T('Ends')}
                value={
                  this.state.endTime
                    ? moment(this.state.endTime).format(DATE_TIME_FORMAT)
                    : ''
                }
                onChange={this.handleChange}
                dateTimeFormat={DATE_TIME_FORMAT}
                autoComplete="off"
                history="off"
                minDate={
                  this.state.startTime
                    ? moment(this.state.startTime).format(DATE_TIME_FORMAT)
                    : ''
                }
              />
            </Form.Group>
            {false && (
              <Form.Group>
                <Form.Input width={4} label={T('Visibility')} name="visibility">
                  <Form.Checkbox
                    label={T('Public')}
                    name="ev_public"
                    defaultChecked={this.props.event.ev_public}
                    className="ui toggle checkbox"
                  />
                </Form.Input>
              </Form.Group>
            )}{' '}
            <Form.Group widths="equal">
              <Button positive icon="save" type="submit" content={T('Save')} />
              <Button
                negative
                icon="trash"
                content={T('Delete')}
                onClick={this.onDeleteClick.bind(this)}
              />
              <Button
                secondary
                icon="ban"
                content={T('Cancel')}
                onClick={() => this.onCancel()}
              />
            </Form.Group>
          </Form>
        </Segment>
        {this.props.event.ev_type === 'season' && this.renderGamesForSeason()}
        <Confirm
          header={T('Delete Event')}
          content={T('Are you sure you want to delete this event?')}
          confirmButton={T('Delete')}
          cancelButton={T('Cancel')}
          open={this.state.openDeleteConfirmation}
          onCancel={this.oncancelDeleteConfirmation}
          onConfirm={this.onConfirmDelete}
          size="tiny"
        />
      </>
    );
  }
}

function mapStateToProps({
  performers,
  parents,
  auth,
  venues,
  events,
  languages,
  workspace,
}) {
  return {
    performers,
    venues,
    languages,
    events,
    parents,
    auth,
    workspace,
  };
}

export default connect(mapStateToProps, {
  deleteSeason,
  deleteSeries,
  deleteEvent,
  editSeason,
  editSeries,
})(EventEdit);
